import authApi from "../../../app/services/public/auth";
import { useEffect, useRef } from "react";
import { pushCrispEvent, pushGAEvent } from "../../../app/events";
import { StorageManager } from "../../../app/storage";
import Urls from "../../../assets/js/Urls";
import { CredentialResponse, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleIsRegisterPage } from "../../../app/slices/login";
import { AppPublicActionsProps, AppPublicProps } from "./AppPublic";
import { getAppMode } from "../../../app/slices/app";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface useAppPublicProps extends Pick<AppPublicProps, "page">, AppPublicActionsProps {
}

export const useAppPublic = ({ page, params }: useAppPublicProps) => {

  const resellerCode = params.get("code") || "";

  const [log, logRes] = authApi.useLoginMutation();
  const [logWithGoogle, logWithGoogleRes] = authApi.useLoginWithGoogleMutation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (logRes.isSuccess) {
      if (logRes.data.is_new) {
        pushGAEvent("signup");
        pushCrispEvent("user:auth:signup", {}, "pink");
      }
      StorageManager.set(logRes.data.access_token);
      window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.roundabout;
    }
  }, [logRes]);

  const onGoogleOAuth = async (credentialResponse: CredentialResponse) => {
    const baseObj: {
      google_token?: string,
      registration_code?: string,
    } = { google_token: credentialResponse.credential };

    if (resellerCode.length) {
      baseObj.registration_code = resellerCode;
    }
    if (executeRecaptcha) {
      const token = await executeRecaptcha();
      logWithGoogle(baseObj);
    }
  }

  const onGoogleLoginSuccess = (token: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {

    const baseObj: {
      google_token?: string,
      registration_code?: string,
    } = { google_token: token.access_token };

    if (resellerCode.length) {
      baseObj.registration_code = resellerCode;
    }

    log(baseObj);
  }

  const onOAuthError = () => {
    console.error("Google login failed");
  }

  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => onGoogleLoginSuccess(tokenResponse)
  });

  const mode = useAppSelector(getAppMode);

  return {
    mode,
    onGoogleOAuth,
    onOAuthError,
    lessThanSmall,
    // recaptchaRef
  }
}
