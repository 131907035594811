import { CompaniesAuth } from "../types/feed";
import { Company } from "../types/campaign";
import { Product, ProductData } from "../types/products";
import { Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { Facebook, Google, Microsoft } from "@mui/icons-material";
import { useAppSelector } from "../hooks";
import { getProductStatus, ProductStatusString } from "../utils/productStatusUtils";

const useProductUtils = (p?: Product) => {

  const activeChannels = useAppSelector(state => state.shop.accounts.hasChannelActive);

  const ChannelComponents = {
    google: Google,
    meta: Facebook,
    microsoft: Microsoft,
  };

  const getStatusDetails = (status: ProductStatusString[], activeChannels: CompaniesAuth, company: Company) => {
    if (!activeChannels[company]) {
      return { color: "disabled", tooltip: `channel-disabled` };
    }
    if (status.includes("MANUALLY_CORRECTED")) {
      return { color: "info", tooltip: "pending-new-validation" }
    }
    if (status.includes("ERROR")) {
      return { color: "error", tooltip: "error-detected" };
    }
    if (status.includes("WARNING")) {
      return { color: "warning", tooltip: "warning-issued" };
    }
    if (status.includes("ONLINE")) {
      return { color: "success", tooltip: "product-online" };
    }
    return { color: "info", tooltip: "pending-validation" };
  };

  const getChannelStatus = (p: Product, c: Company) => {
    const statusNode = p[`status_${c}`];
    const status = getProductStatus(statusNode);
    const { color, tooltip } = getStatusDetails(status, activeChannels, c);

    const ChannelComponent = ChannelComponents[c];
    return ChannelComponent
      ? (
        <Tooltip title={<FormattedMessage id={tooltip}/>} placement={"top"} arrow>
          <ChannelComponent color={color as any}/>
        </Tooltip>)
      : null;
  }

  const mergeProductData = ({ data, custom_data }: Product) => {
    const custom: Partial<ProductData> = custom_data ?? {};
    return Object.keys(custom).reduce((acc, key) => ({ ...acc, [key]: custom[key as keyof ProductData] }), data);
  }

  return {
    mergeProductData,
    getChannelStatus,
  }
}

export default useProductUtils;
