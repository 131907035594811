import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getSelectedProducts, SelectedProduct, setSelectedProducts } from "../../../../app/slices/app";
import { modalShowLabel, modalShowProductMultipleEdit } from "../../../../app/slices/modal";
import { Button, Fade } from "@mui/material";
import { Edit, TagSharp } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export const ProductListToolbar = () => {

  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const [ showLabelButton, setShowLabelButton ] = useState(false);
  const selected = apiRef.current.getSelectedRows();
  const currentRows = apiRef.current.getAllRowIds();
  const selectedMemo = useMemo(() => selected, [ selected ]);

  const alreadySelectedProducts = useAppSelector(getSelectedProducts);

  const makeSelectedProducts = useCallback(() => {
    const selectedProducts: SelectedProduct[] = [ ...alreadySelectedProducts ].filter(s => currentRows.findIndex(c => c === s.ref) < 0);
    if (selectedMemo.size) {
      selectedMemo.forEach((s) => s && selectedProducts.push({ id: s.f_id, ref: s.id }));
    }
    return selectedProducts;
  }, [ selectedMemo.size ]);

  useEffect(() => {
    setShowLabelButton(selectedMemo.size > 0);
    const selectedProducts = makeSelectedProducts();
    dispatch(setSelectedProducts(selectedProducts));
  }, [ selectedMemo.size ]);

  const showModalLabel = () => dispatch(modalShowLabel({}));
  const showModalEdit = () => dispatch(modalShowProductMultipleEdit());

  return <GridToolbarContainer>
    <GridToolbarColumnsButton/>
    <GridToolbarDensitySelector/>
    <Fade in={showLabelButton}>
      <Button
        size={"small"}
        startIcon={<TagSharp/>}
        onClick={showModalLabel}
      >
        <FormattedMessage id={"handle-dynamic-label"}/>
      </Button>
    </Fade>
    <Fade in={showLabelButton} timeout={300}>
      <Button
        size={"small"}
        startIcon={<Edit/>}
        onClick={showModalEdit}
      >
        <FormattedMessage id={"edit-multiple-products"}/>
      </Button>
    </Fade>
  </GridToolbarContainer>
}
