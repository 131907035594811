import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  useTheme
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import { AutoAwesome } from "@mui/icons-material";
import { Packs, SelectedPack } from "../constants";
import { SubInterface } from "../Subscription";
import { SubFormProps } from "../SubForm";
import { useSubscription } from "../hooks/useSubscription";

interface SubAIProps extends SubInterface, SelectedPack, Pick<SubFormProps, "onAiChange"> {
}

const PACKS_AI_CREDITS: Record<"free" | Packs, number> = {
  "free": 50,
  [Packs.Autopilot]: 500,
  [Packs.Premium]: 5000,
}

const SubAI = ({ currSub, selectedPack, onAiChange }: SubAIProps) => {

  const [ sub ] = useSubscription(currSub.feed);

  const [ checked, setChecked ] = useState(false);

  const creditsForSelectedPlan = PACKS_AI_CREDITS[selectedPack ?? "free"];

  const priceNode = sub.data?.prices.find(s => s.code === "opt_ai")?.id ?? 0;

  const handleCheck = (checked: boolean) => {
    onAiChange(checked ? priceNode : 0);
  }

  useEffect(() => {
    handleCheck(checked);
  }, [ checked ]);

  const theme = useTheme();
  const paperBg = theme.palette.background.paper;

  useEffect(() => {
    if (currSub.ai) {
      setChecked(true);
    }
  }, []);

  return (
    <Stack spacing={0.5}>
      <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
        <Typography color={"ai.main"} lineHeight={1}>
          <FormattedMessage id={"sub-ai"}/>
        </Typography>
        <AutoAwesome fontSize={"small"} sx={{ color: "ai.main" }}/>
      </Box>
      <Box>
        <Typography variant={"body2"}>
          <FormattedMessage id={'included-in-your-plan'} values={{ nb: creditsForSelectedPlan }}/>
        </Typography>
      </Box>
      <Box>
        <Paper
          elevation={2}
          sx={{
            borderRadius: 1,
            p: 2,
            width: '100%',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: !checked ? paperBg : theme.palette.ai.main,
            transition: "background 0.5s",
          }}
        >
          <Box>
            <Typography>
              <FormattedMessage id={'sub-ai'} />
            </Typography>
            <Typography fontWeight={500}>
              <FormattedMessage id={'unlimited'} />
            </Typography>
          </Box>
          <Box>
            <Typography variant={"h5"} lineHeight={1}>
              +20€
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              <FormattedMessage id={'ht-month'} />
            </Typography>
          </Box>
          <Box>
            <Switch
              checked={checked}
              onChange={(e, checked) => setChecked(checked)}
            />
          </Box>
        </Paper>
      </Box>
    </Stack>
  )
}

export default SubAI;
