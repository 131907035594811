import React, { useEffect, useState } from "react";
import { getNotificationDetail } from "./NotificationDetail";
import { Box, Collapse, IconButton, ListItem, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Error, VisibilityOff } from "@mui/icons-material";
import { AppNotification, removeAppNotif } from "../../../../../app/slices/app_notifications";
import { useAppDispatch } from "../../../../../app/hooks";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import { DateFromTimestamp } from "../../../Blocks/Misc";
import logsApi from "../../../../../app/services/secured/logs";
import { redirect } from "../../../../../app/slices/app";
import { Link } from "react-router-dom";

export const Notification = (p: AppNotification) => {

  const dispatch = useAppDispatch();
  const [ hovered, setHovered ] = useState(false);
  const [ collapse, setCollapse ] = useState(true);

  const { icon, detail, redirection, severity, type } = getNotificationDetail(p);

  const hasLink = (redirection.length || p.data?.link?.length);
  const showViewButton = (!p.isViewed);

  const [ clear, clearRes ] = logsApi.useClearMessageMutation();

  const clearNotification = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapse(false);
    setTimeout(() => {
      dispatch(removeAppNotif(p.id));
      clear(p.id);
    }, 500);
  };

  useEffect(() => {
    if (clearRes.isSuccess) {
      dispatch(NewSuccessNotification("success-clear-message"));
    }
  }, [ clearRes ]);

  let bg = "";
  switch (severity) {
    case "info":
      bg = "rgba(79,195,247,0.15)";
      break;
    case "warning":
      bg = "rgba(255,183,77,0.15)";
      break;
    case "error":
      bg = "rgba(229,115,115,0.15)";
      break;
    default:
      break;
  }

  const handleClick = () => {
    if (hasLink) {
      dispatch(redirect(redirection));
      // TODO close drawer if already on the page
    }
  }

  return (
    <Collapse in={collapse} mountOnEnter>
      <ListItem
        divider
        disablePadding
      >
        <Tooltip
          placement={"left"}
          arrow
          title={
            hasLink ? (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Typography variant={"caption"}>
                  <FormattedMessage id={'click-for-more'} />
                </Typography>
              </Box>
            ) : null
          }
        >
          <ListItemButton
            sx={{
              alignItems: "center", minWidth: '400px',
              cursor: (redirection || p.data?.link?.length) ? "pointer" : "default",
              px: 4,
              transition: "all 0.2s ease-in-out",
              backgroundColor: severity === "error" ? bg : undefined,
              "&:hover": {
                backgroundColor: bg,
              }
            }}
            component={Link}
            to={redirection}
            // onClick={handleClick}
            disableRipple={!redirection}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <ListItemText sx={{ flex: 8 }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {icon}
                <Typography variant={"subtitle1"} sx={{ fontWeight: 500 }}>
                  <FormattedMessage id={`logtype-${p.type}`} />
                </Typography>
                {
                  severity === "error" && (
                    <Error color={"error"} />
                  )
                }
              </Box>
              <Box>
                {detail}
              </Box>
              <Typography
                variant={"caption"}
                color={"text.disabled"}
              >{DateFromTimestamp(p.date, true, true)}</Typography>
            </ListItemText>
            <Collapse in={hovered} mountOnEnter orientation={"horizontal"}>
              {showViewButton && (
                <Box sx={{ flex: 1, pl: 2 }}>
                  <IconButton
                    onClick={clearNotification}
                  >
                    <VisibilityOff/>
                  </IconButton>
                </Box>
              )}
            </Collapse>
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </Collapse>
  );
};
