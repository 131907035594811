import { CompanyLogo, SkeletonLoader } from "../../../../App/Blocks/Misc";
import { capitalizeFirstLetter } from "../../../../../app/utils";
import React from "react";
import { ChannelWrapperProps } from "../Channels";
import { Box, Typography } from "@mui/material";
import { modalShowEnableChannel, modalShowUpsell } from "../../../../../app/slices/modal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCurrentFeed, isLoadingData } from "../../../../../app/slices/app";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import useFreeDiffusion from "../../../Diffusion/FreeDiffusion/useFreeDiffusion";
import { ChannelEnable } from "./ChannelEnable";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormattedMessage } from "react-intl";
import useChannelStatus, { ChannelColor } from "../useChannelStatus";

export interface CompanyHeaderProps extends ChannelWrapperProps {
}

export const ChannelHeader = (p: CompanyHeaderProps) => {

  const dispatch = useAppDispatch();
  const { company } = p;

  const currFeed = useAppSelector(getCurrentFeed);
  const loading = useAppSelector(isLoadingData);
  const canAddChannels = useAppSelector(state => state.shop.platforms.remaining > 0);

  const { enabled, channelState } = useChannelStatus({ company });
  const { toggleFeedcastMarketplace } = useFreeDiffusion();

  const isFeedcastDisabled = company === "feedcast" && !((currFeed?.last_stat?.imported || 0) > 0);

  const onEnable = () => {
    if (company !== "feedcast") {
      if (canAddChannels || enabled) {
        dispatch(modalShowEnableChannel({
          company,
          provider: adsCompanies[company].provider,
          toBeEnabled: !enabled,
        }));
      } else {
        dispatch(modalShowUpsell())
      }
    } else {
      toggleFeedcastMarketplace();
    }
  }

  return (
    <Grid2 container spacing={3} sx={{ p: 3 }}>
      <Grid2 xs={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CompanyLogo size={"sm"} company={company}/>
          <Box ml={1}>
            <Typography variant={"body2"} color={"text.secondary"} lineHeight={1.2}>Canal</Typography>
            <Typography variant={"h6"} lineHeight={1}>
              {company !== "feedcast" ? (capitalizeFirstLetter(company)) : "Marketplace"}
            </Typography>
          </Box>
        </Box>
      </Grid2>
      <Grid2 xs={3}>
        <Box>
          <Typography variant={"body2"} lineHeight={1.2} color={"text.secondary"}>
            <FormattedMessage id={'state'}/>
          </Typography>
          <SkeletonLoader load={loading}>
            <Typography variant={"h6"} lineHeight={1.2} color={ChannelColor[channelState]}>
              <FormattedMessage id={channelState}/>
            </Typography>
          </SkeletonLoader>
        </Box>
      </Grid2>
      <Grid2 xs={6}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box>
            <SkeletonLoader load={loading}>
              <ChannelEnable
                {...p}
                enabled={enabled}
                onEnable={onEnable}
                disabled={isFeedcastDisabled}
              />
            </SkeletonLoader>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  )
}
