import { AssetsTexts, PerfMaxAssetsWarning } from "../../../../../../../app/types/campaign";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { getAssetsText, getPerfMaxData } from "../../../../../../../app/slices/campaign/getters";
import React, { useEffect, useRef, useState } from "react";
import { Box, debounce, FormControl, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { setAssetsTexts } from "../../../../../../../app/slices/campaign/campaign";
import { InputsProps } from "./InputsHolder";

type InputProps = {
  i: number,
  disabled?: boolean,
  type: AssetsTexts,
  isRequired: boolean,
} & Pick<InputsProps, 'size' | 'placeholder' | 'length'>;
export const Input = function Input({ size, placeholder, i, disabled, length, type, isRequired }: InputProps) {

  const intl = useIntl();
  const val = useAppSelector(getAssetsText(type, i));
  let ref = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  // Validation & focus
  const [ valid, setValid ] = useState<"valid" | "invalid" | "empty">("empty");
  const [ focus, setFocus ] = useState(false);

  // Warnings
  const [ hasWarning, setHasWarning ] = useState(false);
  const [ warning, setWarning ] = useState<PerfMaxAssetsWarning | null>(null);
  const initialWarning = (useAppSelector(getPerfMaxData).warnings ?? []).find(w => w.value === val);

  const validContent = () => {
    const val = ref.current?.value;
    if (val && val.length < (length.min || 0) || val && val.length > length.max) {
      setValid("invalid");
    } else {
      setValid("valid");
    }
  }

  const debouncedDispatch = debounce((value: string) => {
    dispatch(setAssetsTexts({ type, index: i, value }));
  }, 0);

  useEffect(() => {
    val && validContent();
  }, []);

  useEffect(() => {
    if (initialWarning) {
      setHasWarning(true);
      setWarning(initialWarning);
    }
  }, [ initialWarning ]);

  const hasMin = (length?.min && (ref?.current?.value?.length || 0) < length.min);

  return (
    <FormControl fullWidth>
      <TextField
        inputRef={ref}
        margin={"dense"}
        size={type !== "descriptions" ? 'small' : "medium"}
        defaultValue={val}
        error={valid === "invalid" || hasWarning}
        helperText={
          (hasWarning) ? (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography variant={"body2"} fontWeight={600}>
                <FormattedMessage id={warning?.blocking ? "blocking" : "non-blocking"}/>
              </Typography>
              <Box>
                |
              </Box>
              <Typography variant={"body2"}>
                <FormattedMessage id={warning?.error_code}/>
              </Typography>
            </Box>
          ) : null
        }
        onChange={(e) => {
          debouncedDispatch(e.target.value);
          validContent();
        }}
        label={`${intl.formatMessage({ id: placeholder })} ${i + 1}`}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false)
        }}
        required={isRequired}
        inputProps={{
          minLength: length.min ?? 0,
          maxLength: length.max ?? undefined,
        }}
        InputProps={{
          endAdornment: <InputAdornment position={"end"} sx={{ "& *": { color: "text.secondary" } }}>
            {focus ?
              (
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography variant={"caption"}>
                    {hasMin && (`Minimum : ${length.min}`)}
                  </Typography>
                  {hasMin && <Typography variant={"caption"}>|</Typography>}
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography variant={"caption"}>
                      {length?.max - (ref?.current?.value?.length || 0)}
                    </Typography>
                    <Typography variant={"caption"} ml={0.5}>
                      <FormattedMessage id={"chars-left"}/>
                    </Typography>
                  </Box>
                </Stack>
              ) : null}
          </InputAdornment>
        }}
      />
    </FormControl>
  )
}
