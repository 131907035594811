import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCurrentFeedHasLoaded, setCurrentFeedLoaded } from "../../../app/slices/app";
import React, { useEffect } from "react";
import { Backdrop, Box, CircularProgress, Container, Fade } from "@mui/material";
import Navigation from "../Nav/AppNav";
import { AppLoader } from "./AppLoader";
import { AppSecuredProps } from "./AppSecured";
import { AlertBanner } from "./AlertBanner";

export const FeedcastApp = ({ children, lockNav }: AppSecuredProps) => {

  const dispatch = useAppDispatch();
  const hasLoaded = useAppSelector(getCurrentFeedHasLoaded);

  useEffect(() => {
    if (!hasLoaded) {
      setTimeout(() => {
        dispatch(setCurrentFeedLoaded(true));
      }, 2000);
    }
  }, [ hasLoaded ]);

  const showBackdrop = useAppSelector(state => state.app.showBackdrop);

  return (
    <Box sx={{ position: "relative" }}>
      {!hasLoaded && (<AppLoader/>)}
      <Backdrop
        open={showBackdrop}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Box display={"flex"}>
        <Box id={"navbar-wrapper"}>
          <Navigation
            lockNav={lockNav}
          />
        </Box>
        <Box id={"content-wrapper"} sx={{ flexGrow: 1 }}>
          <Box sx={{ marginTop: "64px" }}>
            <AlertBanner lockNav={lockNav ?? false}/>
            <Container maxWidth={'xl'} sx={{ pt: 2 }}>
              <Fade in={true} timeout={350}>
                <Box>
                  {children}
                </Box>
              </Fade>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

