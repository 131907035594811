import { GetCurrentFeedId } from "../../../app/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeModal, getModalData, ModalLabelProps } from "../../../app/slices/modal";
import feedApi from "../../../app/services/secured/feed";
import React, { useCallback, useEffect, useState } from "react";
import { NewSuccessNotification } from "../../../app/slices/notifications";
import { setSelectedProducts } from "../../../app/slices/app";
import SimpleForm, { SimpleFormData } from "../../App/Blocks/SimpleForm";
import { Box } from "@mui/material";
import Rules from "./Rules";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DynamicLabel } from "../../../app/types/feed";
import { LabelActions, LabelKeySelector, LabelValueInput } from "./LabelComponents";

export interface IDefaultValueProps {
  defaultValue: string;
}

export const Label: React.FC = () => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const opt = useAppSelector<ModalLabelProps | null>(getModalData);
  const allLabels = useAppSelector(state => state.shop.labels);
  const [ label, setLabel ] = useState<DynamicLabel>();
  const isNewLabel = !((opt?.label?.uuid.length || 0) > 0);

  const [ setLabelsApi, setLabelsApiRes ] = feedApi.useSetDynamicLabelsMutation();

  useEffect(() => {
    if (opt && opt.label) {
      setLabel(opt.label);
    } else {
      setLabel({
        uuid: Math.random().toString(),
        label_key: opt?.labelGroup ? opt.labelGroup : "custom_label_0_k",
        label_value: "",
        condition_any: false,
        overwrite: true,
        conditions: [],
      })
    }

  }, [ opt?.label ]);

  const deleteLabel = useCallback(() => {
    if (allLabels.length) {
      const ls = [ ...allLabels ];
      const index = ls.findIndex(l => l.uuid === label?.uuid);
      if (index >= 0) {
        ls.splice(index, 1);
        setLabelsApi({ feed, labels: ls })
      }
    }
  }, [ allLabels, label ]);

  const updateLabels = useCallback((s: SimpleFormData) => {
    const newL = { ...s, overwrite: true } as DynamicLabel;
    if (label) {
      newL.condition_any = label?.condition_any;

      if (allLabels) {
        const ls = [ ...allLabels ];
        const index = ls.findIndex(l => l.uuid === newL.uuid);

        -1 === index
          ? ls.push(newL)
          : ls.splice(index, 1, newL)

        setLabelsApi({
          feed,
          labels: ls
        });
      }
    }
  }, [ label, allLabels ]);

  useEffect(() => {
    if (setLabelsApiRes.isSuccess) {
      dispatch(NewSuccessNotification("success-dynamic-label"));
      dispatch(closeModal());
      dispatch(setSelectedProducts([]));
    }
  }, [ setLabelsApiRes.isSuccess ]);

  return (
    <Box>
      <SimpleForm onSubmit={updateLabels}>
        {label && (
          <React.Fragment>
            <input type="hidden" name="uuid" key={`label-${label?.uuid}`} value={label?.uuid}/>
            <Grid2 container spacing={2} mt={1}>
              <LabelValueInput defaultValue={label.label_value}/>
              <LabelKeySelector defaultValue={label.label_key}/>
            </Grid2>
            <Rules/>
            <LabelActions
              onDelete={deleteLabel}
              apiRes={setLabelsApiRes}
              canDelete={opt?.canDelete || !isNewLabel}
            />
          </React.Fragment>
        )}
      </SimpleForm>
    </Box>
  );
};
