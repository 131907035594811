import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, getModalData, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";
import { FormattedMessage } from "react-intl";
import { Button, List, ListItem, ListItemAvatar, ListItemText, Switch } from "@mui/material";
import { AvailableCompany } from "../../../../../app/types/campaign";
import { getShop } from "../../../../../app/slices/shop";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import DialogActions from "@mui/material/DialogActions";
import { capitalizeFirstLetter } from "../../../../../app/utils";
import SimpleForm, { SimpleFormData } from "../../../../App/Blocks/SimpleForm";
import { useHandleSubscription, useHandleSubscriptionProps } from "../hooks/useHandleSubscription";
import { setShowBackdrop } from "../../../../../app/slices/app";

const defaultData = {
  currSub: {
    pack: 0,
    limit: 0,
    feed: 0,
  },
  selectedProducts: []
};

const DialogPlatforms = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.ForceDisableChannel));
  const data = useAppSelector<useHandleSubscriptionProps>(getModalData);

  const [ subscription, setSubscription ] = useState<useHandleSubscriptionProps>(defaultData);
  const [ { submit } ] = useHandleSubscription(subscription);

  useEffect(() => {
    if (data) {
      setSubscription(data);
    } else {
      setSubscription(defaultData);
    }
  }, [ data ]);

  const onSubmit = async (s: SimpleFormData) => {
    dispatch(setShowBackdrop(true));
    onClose();
    const formData = s as Record<AvailableCompany, boolean>;
    const channelsToRemove = Object.keys(formData).filter((k => !formData[k as AvailableCompany]));
    const providersToRemove = channelsToRemove.map(c => adsCompanies[c as AvailableCompany].provider) ?? [];
    try {
      submit({
        callback: () => {
          dispatch(setShowBackdrop(false));
        },
        hasDisabledChannels: true,
        platforms_to_remove: providersToRemove,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => dispatch(closeModal());

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth>
      <SimpleForm onSubmit={onSubmit}>
        <DialogTitle color={"error"}>
          <FormattedMessage id={"additional-platforms"}/>
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id={"too-many-platforms"}/>
          <PlatformsList/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            <FormattedMessage id={"cancel"}/>
          </Button>
          <Button
            color={"secondary"}
            variant={"contained"}
            type={"submit"}
          >
            <FormattedMessage id={"continue"}/>
          </Button>
        </DialogActions>
      </SimpleForm>
    </Dialog>
  )
}

const PlatformsList = () => {
  const channels: AvailableCompany[] = [ "google", "meta", "microsoft" ];
  return (
    <List>
      {channels.map(c => <Platform key={`channel-${c}`} channel={c}/>)}
    </List>
  )
}

const Platform = (p: { channel: AvailableCompany }) => {
  const { channel } = p;
  const shop = useAppSelector(getShop);
  const defaultChecked = shop.platforms.enabled[channel];
  const [ checked, setChecked ] = useState(false);

  useEffect(() => {
    if (defaultChecked) {
      setChecked(true);
    }
  }, [ defaultChecked ]);

  return (
    <ListItem>
      <ListItemAvatar>
        <img src={adsCompanies[channel].image} height={"20px"}/>
      </ListItemAvatar>
      <ListItemText>
        {capitalizeFirstLetter(channel)}
      </ListItemText>
      <Switch
        name={channel}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        color={shop.platforms.enabled[channel] ? "secondary" : undefined}
      />
    </ListItem>
  )
}

export default DialogPlatforms;
