import { Box, Grid, TextField } from "@mui/material";
import { motion } from "framer-motion";

import { FormattedMessage } from "react-intl";
import { LoadingButton } from "../../../../components/App/Blocks/Misc";
import { containerAnimation, fieldAnimation } from "../../utils";

interface SignupFormProps {
   formValues: any;
   handleChange: (name: string, value: any) => void;
   errors: any;
   registerRes: any;
}

const SignupForm = ({ formValues, handleChange, errors, registerRes }: SignupFormProps) => {
   return (
      <Grid item xs={12} sm={12}>
         <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <motion.div {...containerAnimation} style={{ width: 250 }}>
               <motion.div
                  {...fieldAnimation}
                  transition={{ ...fieldAnimation.transition, delay: 0.4 }}
               >
                  <TextField
                     fullWidth
                     label={<FormattedMessage id="email" />}
                     type="email"
                     name="email"
                     size="small"
                     margin='dense'
                     value={formValues.email || ''}
                     onChange={(e) => handleChange('email', e.target.value)}
                     error={!!errors.email}
                     helperText={errors.email}
                     required
                  />
               </motion.div>
               <motion.div
                  {...fieldAnimation}
                  transition={{ ...fieldAnimation.transition, delay: 0.45 }}
               >
                  <TextField
                     fullWidth
                     size="small"
                     margin='dense'
                     label={<FormattedMessage id="password" />}
                     type="password"
                     name="password"
                     value={formValues.password || ''}
                     onChange={(e) => handleChange('password', e.target.value)}
                     error={!!errors.password}
                     helperText={errors.password}
                     required
                  />
               </motion.div>
               <motion.div
                  {...fieldAnimation}
                  transition={{ ...fieldAnimation.transition, delay: 0.5 }}
               >
                  <TextField
                     fullWidth
                     size="small"
                     margin='dense'
                     label={<FormattedMessage id="confirm-password" />}
                     type="password"
                     name="password_confirm"
                     value={formValues.password_confirm || ''}
                     onChange={(e) => handleChange('password_confirm', e.target.value)}
                     error={!!errors.password_confirm}
                     helperText={errors.password_confirm}
                     required
                  />
               </motion.div>
               <motion.div
                  {...fieldAnimation}
                  transition={{ ...fieldAnimation.transition, delay: 0.6 }}
               >
                  <LoadingButton
                     fullWidth
                     variant="outlined"
                     color="primary"
                     type="submit"
                     sx={{ mt: 1 }}
                     data={registerRes}
                  >
                     <FormattedMessage id="register" />
                  </LoadingButton>
               </motion.div>
            </motion.div>
         </Box>
      </Grid>
   )
}

export default SignupForm;