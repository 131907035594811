import { useAppSelector } from "../../../../../app/hooks";
import { getAppMode } from "../../../../../app/slices/app";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress, Typography } from "@mui/material";
import React from "react";
import { NoDataBg } from "../../../../App/Blocks/Misc";
import spaceship from "../../../../../assets/img/icons/spaceship.svg";
import { FormattedMessage } from "react-intl";

interface CampaignTableProps {
  columns: any,
  loading: boolean,
  rows: any,
  processRowUpdate: any,
  handleProcessRowUpdateError: any,
}

const NoCampaignsData = () => {
  return (
    <NoDataBg
      variant={"campaigns"}
      position={"center"}
      className={"border"}
    >
      <img src={spaceship}/>
      <Typography variant={"h3"}><FormattedMessage id={"no-campaign"}/></Typography>
    </NoDataBg>
  )
}


const CampaignTable = (p: CampaignTableProps) => {
  const mode = useAppSelector(getAppMode);
  return (
    <DataGrid
      density={"comfortable"}
      sx={{
        background: mode === "light" ? "#fff" : "", "& .MuiDataGrid-virtualScroller": { overflow: "hidden" },
      }}
      columns={p.columns}
      loading={p.loading}
      rows={p.rows}
      disableRowSelectionOnClick
      pageSizeOptions={[ 5, 10 ]}
      processRowUpdate={p.processRowUpdate}
      onProcessRowUpdateError={p.handleProcessRowUpdateError}
      slots={{
        noRowsOverlay: NoCampaignsData,
        loadingOverlay: LinearProgress,
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          }
        },
      }}
    />
  )
}

export default React.memo(CampaignTable);
