import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import React from "react";
import { Checkbox, ImageListItem, ImageListItemBar } from "@mui/material";
import { handlePerfMaxAssets } from "../../../../../../app/slices/campaign/campaign";
import { AssetType } from "../../../../../../app/types/feed";
import { getImageFormat } from "../../../../../../app/utils";
import { FormattedMessage } from "react-intl";
import { AssetsProps, getModalData } from "../../../../../../app/slices/modal";
import { motion } from "framer-motion";

const Asset = (asset: AssetType & { selected: boolean }) => {

  const dispatch = useAppDispatch();
  const url = asset.url + "?d=" + (new Date().getTime().toString());
  const format = getImageFormat(asset);

  const targetNode = useAppSelector<AssetsProps | undefined>(getModalData)?.node;

  const handleAsset = () => dispatch(handlePerfMaxAssets({
    targetNode,
    image: asset,
  }));

  return (
    <ImageListItem
      className={"cursor-pointer"}
      onClick={handleAsset}
    >
      <img src={url}/>
      <ImageListItemBar
        title={<FormattedMessage id={format}/>}
        subtitle={`${asset.width}x${asset.height}`}
        actionIcon={(
          <Checkbox
            style={{ color: "white" }}
            checked={asset.selected}
          />
        )}
      />
    </ImageListItem>
  )
}

export default Asset;
