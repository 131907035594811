import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {isRegisterPage: boolean} = {
  isRegisterPage: false,
}

const loginSlice = createSlice({
  name:"loginSlice",
  initialState,
  reducers: {
    toggleIsRegisterPage: (state, action: PayloadAction<boolean>) => {
      state.isRegisterPage =  action.payload;
    }
  }
})

export const {
  toggleIsRegisterPage
} = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
