import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem, Popover,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Bookmarks, Delete } from "@mui/icons-material";
import { IDefaultValueProps } from "./Label";
import { ReduxRtkApiResult } from "../../../app/types";
import { LoadingButton } from "../../App/Blocks/Misc";
import { useAppSelector } from "../../../app/hooks";
import { getModalData, ModalLabelProps } from "../../../app/slices/modal";
import useAnchorEl from "../../../app/hooks/useAnchorEl";

export const LabelKeySelector: React.FC<IDefaultValueProps> = ({ defaultValue }) => {
  const dialogOptions = useAppSelector<ModalLabelProps | null>(getModalData);
  return (
    <Grid2 xs={4}>
      <FormControl fullWidth>
        {dialogOptions?.canChangeGroup === false && <input type={"hidden"} name="label_key" value={defaultValue} />}
        <TextField
          disabled={dialogOptions?.canChangeGroup === false}
          select
          label={<FormattedMessage id="custom-label"/>}
          name="label_key"
          defaultValue={defaultValue}
          key={`label-${defaultValue}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Bookmarks/>
              </InputAdornment>
            )
          }}
        >
          <MenuItem value="custom_label_0_k"><FormattedMessage id="custom_label_0_k"/></MenuItem>
          <MenuItem value="custom_label_1_k"><FormattedMessage id="custom_label_1_k"/></MenuItem>
          <MenuItem value="custom_label_2_k"><FormattedMessage id="custom_label_2_k"/></MenuItem>
          <MenuItem value="custom_label_3_k"><FormattedMessage id="custom_label_3_k"/></MenuItem>
          <MenuItem value="custom_label_4_k"><FormattedMessage id="custom_label_4_k"/></MenuItem>
        </TextField>
      </FormControl>
    </Grid2>
  );
};

interface ILabelActionsProps {
  onDelete: () => void;
  apiRes: ReduxRtkApiResult;
  canDelete?: boolean,
}

export const LabelActions: React.FC<ILabelActionsProps> = ({ onDelete, apiRes, canDelete = true }) => {
  const {open, handleClose, handleClick, anchorEl} = useAnchorEl();
  return (
    <Stack direction="row" spacing={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <Button
        color="error"
        startIcon={<Delete/>}
        onClick={handleClick}
        disabled={!canDelete}
      >
        <FormattedMessage id="delete-label"/>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Stack spacing={1} p={2}>
          <Typography>
            <FormattedMessage id={'are-you-sure'} />
          </Typography>
          <Button
            variant={"contained"}
            color={"error"}
            startIcon={<Delete />}
            onClick={onDelete}
          >
            <FormattedMessage id={'yes'} />
          </Button>
        </Stack>

      </Popover>
      <LoadingButton
        type="submit"
        variant="contained"
        data={apiRes}
        sx={{ px: 5 }}
      >
        <FormattedMessage id="save"/>
      </LoadingButton>
    </Stack>
  );
};

interface ILabelConditionToggleGroupProps {
  conditionAny: boolean;
  setCondition: (value: boolean) => void;
}

export const LabelConditionToggleGroup: React.FC<ILabelConditionToggleGroupProps> = ({
  conditionAny, setCondition
}) => {
  return (
    <Grid2 xs={7}>
      <Stack spacing={0}>
        <Typography>
          <FormattedMessage id="apply-label-if"/>
        </Typography>
        <ButtonGroup size="small">
          <Button
            sx={{ flex: "none" }}
            variant={!conditionAny ? "contained" : "outlined"}
            onClick={() => setCondition(false)}
          >
            <FormattedMessage id="all-conditions-met"/>
          </Button>
          <Button
            sx={{ flex: "none" }}
            variant={!conditionAny ? "outlined" : "contained"}
            onClick={() => setCondition(true)}
          >
            <FormattedMessage id="one-condition-met"/>
          </Button>
        </ButtonGroup>
      </Stack>
    </Grid2>
  );
};

interface IOverwriteSwitchProps {
  defaultValue: boolean;
}

export const LabelOverwriteSwitch: React.FC<IOverwriteSwitchProps> = ({ defaultValue }) => {
  return (
    <Grid2 xs>
      <FormControlLabel
        control={
          <Switch
            defaultChecked={defaultValue}
            name="overwrite"
          />
        }
        label={<FormattedMessage id="overwrite-value"/>}
      />
    </Grid2>
  );
};
export const LabelValueInput: React.FC<IDefaultValueProps> = ({ defaultValue }) => {
  return (
    <Grid2 xs={8}>
      <FormControl fullWidth>
        <TextField
          label={<FormattedMessage id="label-value"/>}
          name="label_value"
          key={defaultValue.length}
          defaultValue={defaultValue}
          required
        />
      </FormControl>
    </Grid2>
  );
};
