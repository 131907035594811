import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { removeYoutubeVideo, setYoutubeIds } from "../../../../../../app/slices/campaign/campaign";
import React from "react";
import {
  Alert, AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Delete, InsertPhoto, OndemandVideo, OpenInNew, VideoCameraBack, YouTube } from "@mui/icons-material";
import { getPerfMaxData } from "../../../../../../app/slices/campaign/getters";
import { FormattedMessage } from "react-intl";
import FCard from "../../../../../App/Blocks/Card";
import CampaignCard from "../../CampaignCard";
import { PlusIcon } from "lucide-react";

const Videos = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getPerfMaxData);

  const addYoutubeRow = () => {
    const yids = [ ...data.youtube_ids ?? [] ];
    yids.push("");
    dispatch(setYoutubeIds(yids));
  }

  const removeYoutubeRow = (i: number) => {
    dispatch(removeYoutubeVideo(i));
  }

  return (
    <CampaignCard icon={<OndemandVideo/>} title={"perf-max-videos"}>
      <Box>
        <Stack spacing={1}>
          <Alert severity={"info"}>
            <AlertTitle>
              <FormattedMessage id={'how-to-add-perf-max-video'}/>
            </AlertTitle>
            <FormattedMessage
              id={'how-to-add-perf-max-video-desc'}
              values={{
                br: <br/>,
                b: (txt) => <b>{txt}</b>,
              }}
            />
          </Alert>

          <Box>
            {data.youtube_ids?.map((y, i) =>
              <Box
                key={`youtube-${i}`}
                className={"d-flex mb-1"}
                sx={{ display: "flex", gap: 1, alignItems: "center" }}
              >
                <Video
                  index={i}
                  url={y}
                  onChange={(url) => {
                    const value = url.replace("https://www.youtube.com/watch?v=", "");
                    const yids = [ ...data.youtube_ids ?? [] ];
                    yids[i] = value;
                    dispatch(setYoutubeIds(yids));
                  }}
                />
                <IconButton onClick={() => removeYoutubeRow(i)}>
                  <Delete/>
                </IconButton>
              </Box>)}
          </Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Button
              size={"small"}
              variant={"contained"}
              startIcon={<PlusIcon/>}
              onClick={addYoutubeRow}
              color={"secondary"}
            >
              <FormattedMessage id={'add-video'}/>
            </Button>
            {/*<Box>*/}
            {/*  <Typography color={"text.secondary"}>*/}
            {/*    youtube.com/watch?v=<b>VIDEO_ID</b>*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
          </Box>
        </Stack>
      </Box>
    </CampaignCard>
  )
}

type VideoProps = {
  index: number,
  url: string,
  onChange: (url: string) => void,
}

const Video = (p: VideoProps) => {
  return (
    <FormControl margin={"dense"} fullWidth>
      <TextField
        label={`Video ID ${p.index + 1}`}
        value={p.url}
        onChange={(e) => p.onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <IconButton
                href={`https://www.youtube.com/watch?v=${p.url}`}
                target={'_blank'}
                disabled={!p.url.length}
              >
                <OpenInNew/>
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  )
}

export default Videos;
