import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

export interface HasLoading {
  isLoading: boolean,
}

interface PayTodayProps extends HasLoading {
  toPayToday: number,
  show: boolean
}

export const PayToday = (p: PayTodayProps) => {
  const { toPayToday, show, isLoading } = p;
  return (
    <Fade
      in={show}
      unmountOnExit
      timeout={{
        enter: 500,
        exit: 0
      }}
    >
      <Box>
        <Typography color={"text.secondary"} lineHeight={1}>
          <FormattedMessage id={"pay-today"}/>
        </Typography>
        <Box mt={1}>
          {!isLoading
            ? <Typography variant={"h4"} lineHeight={1}>{toPayToday?.toFixed(2)} €</Typography>
            : <Box><CircularProgress size={"1.3rem"}/></Box>}
          <Typography color={"text.secondary"} variant={"caption"}><FormattedMessage
            id={"ht-month"}/></Typography>
        </Box>
      </Box>
    </Fade>
  )
}
