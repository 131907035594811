import { CreateMicrosoftCenterProps } from "./CreateMicrosoftCenter";
import { UserAdsAccount } from "../../../../../../app/types/feed";
import { GetCurrentFeedId } from "../../../../../../app/utils";
import { useAppSelector } from "../../../../../../app/hooks";
import campaignApi from "../../../../../../app/services/secured/campaign";
import React, { useEffect } from "react";
import { getAppMode } from "../../../../../../app/slices/app";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography
} from "@mui/material";
import { FormattedMessage } from "react-intl";

interface LinkMicrosoftCenterProps extends CreateMicrosoftCenterProps {
  accounts: Array<Pick<UserAdsAccount, "id" | "name"> & { url: string }>,
}

export const LinkMicrosoftCenter = (p: LinkMicrosoftCenterProps) => {

  const feed = GetCurrentFeedId();
  const currentFeedDomain = useAppSelector(state => state.app.currentFeed?.name);
  const [ linkAccount, linkAccountRes ] = campaignApi.useLinkMicrosoftCenterMutation();

  const onClick = (account: number) => linkAccount({ feed, account });

  useEffect(() => {
    if (linkAccountRes.isSuccess) p.onSuccess();
  }, [ linkAccountRes.isSuccess ]);

  const mode = useAppSelector(getAppMode);
  const background = mode === "light" ? "rgba(255, 255, 255, 0.5)" : "";

  const isNotSameDomain = (txt: string) => currentFeedDomain !== txt;

  return (
    <Fade in={true}>
      <Box sx={{ position: "relative" }}>
        <Backdrop
          open={linkAccountRes.isLoading}
          sx={{
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: background,
          }}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>

        <List>
          <ListSubheader>
            <FormattedMessage id={"link-existing-merchant-center"}/>
          </ListSubheader>
          {p.accounts.map(a => <ListItem
            key={`account-${a.id}`}
            disablePadding
            secondaryAction={
              isNotSameDomain(a.url)
                ? (
                  <Typography color={"warning.main"}>
                    Not the same domain
                  </Typography>
                )
                : <React.Fragment/>
            }
          >
            <ListItemButton
              // disabled={isNotSameDomain(a.url)}
              onClick={() => onClick(a.id)}
            >
              <ListItemAvatar>
                <Avatar content={a.name}/>
              </ListItemAvatar>
              <ListItemText primary={a.name} secondary={a.url}/>
            </ListItemButton>
          </ListItem>)}
        </List>
        <Box mt={2} display={"flex"} justifyContent="end">
          <Button onClick={p.onBack}>
            <FormattedMessage id={"back"}/>
          </Button>
        </Box>
      </Box>
    </Fade>
  )
}
