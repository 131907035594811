import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import React from "react";
import { ReduxRtkApiResult } from "../../../../../app/types";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import { useAppSelector } from "../../../../../app/hooks";
import { getUser } from "../../../../../app/slices/app";

interface ConfirmSubscriptionProps {
  apiRes: ReduxRtkApiResult,
  isCurrentSub: boolean,
  currSubStatus: string,
  selectedPack: string | null,
  hasPromotionCode?: boolean,
}

export const ConfirmSubscription = (p: ConfirmSubscriptionProps) => {
  const notLinked = !(useAppSelector(getUser)?.is_linked);
  return (
    <Box mt={1}>
      <LoadingButton
        size={'large'}
        data={p.apiRes}
        className={'no-shadow'}
        type={"submit"}
        variant={"contained"}
        disabled={p.isCurrentSub || notLinked}
        color={p.currSubStatus !== "free" && !p.selectedPack ? "error" : "primary"}
        reset
        sx={{
          whiteSpace: "nowrap"
        }}
      >
        <RenderMessage {...p} />
      </LoadingButton>
    </Box>
  )
}

const RenderMessage = (p: ConfirmSubscriptionProps) => {
  if (p.hasPromotionCode) {
    return <FormattedMessage id={"get-my-offer"}/>
  }

  if (p.currSubStatus === "free") {
    return <FormattedMessage id={"start-sub"}/>
  }

  if ([ "active", "trial", "starter", "premium" ].includes(p.currSubStatus) && p.selectedPack) {
    return <FormattedMessage id={"edit-sub"}/>;
  }

  if (p.currSubStatus !== "free") {
    return <FormattedMessage id={"cancel-sub-btn"}/>;
  }

  return <Box/>
}
