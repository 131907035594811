import { ReduxRtkApiResult } from "../../../../../app/types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import React from "react";

import { ConfirmEnableDialogProps } from "../hooks/useCampaignMutation";

type ConfirmDialogProps = {
  open: ConfirmEnableDialogProps,
  onValid: (campaignId: number) => void,
  onClose: () => void,
  loading: ReduxRtkApiResult,
}
export const DialogConfirmEnable = (p: ConfirmDialogProps) => {
  const handleValid = () => {
    if (p.open.id) {
      p.onValid(p.open.id);
    }
  }
  return (
    <Box>
      <Dialog
        open={p.open.show}
        onClose={p.onClose}
      >
        <DialogTitle>
          <FormattedMessage id={"are-you-sure"}/>
        </DialogTitle>
        <DialogContent dividers>
          <FormattedMessage id={`campaign-${p.open.active ? 'disable' : 'enable'}-confirm`}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={p.onClose}><FormattedMessage id={'cancel'}/></Button>
          <LoadingButton
            data={p.loading}
            onClick={handleValid}
            reset
          >
            <FormattedMessage id={"validate"}/>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
