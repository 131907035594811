import React, { useEffect, useMemo, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { enhanceReporting } from "../../../../app/slices/campaigns";
import Filters from "./components/Filters";
import NewCampaign from "./NewCampaign/NewCampaign";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import CampaignColumns from "./components/CampaignColumns";
import { TabPanel } from "../../../App/Blocks/AppTabs";
import { ReportingData } from "../../../../app/types/feed";
import AppSecured from "../../../App/AppSecured/AppSecured";
import { adsCampaigns } from "../../../../app/slices/campaign/constants";
import { AdsProvider, CampaignInterface } from "../../../../app/types/campaign";
import { useCampaignData } from "./hooks/useCampaignData";
import { useCampaignMutation } from "./hooks/useCampaignMutation";
import useRowMenu, { CampaignRowMenu } from "./hooks/useRowMenu";
import DatePicker from "../../../App/Blocks/Datepicker";
import CampaignTable from "./components/CampaignTable";
import { Log } from "../../../../app/types/profile";
import { CampaignsNotificationsData } from "../../../../app/slices/app_notifications";

export type CampaignRowType = CampaignInterface & Partial<ReportingData>;

export interface RowFocus {
  provider: AdsProvider,
  campaign: string,
  is_feedcast: boolean
}

const findWarnings = (campaign: CampaignRowType, warnings: Log<CampaignsNotificationsData>[]) => {
  return warnings.filter(w => w.data.campaigns.includes(campaign.id?.toString() ?? "null"));
}

const calculateRows = (list: CampaignRowType[], warnings: Log<CampaignsNotificationsData>[]) => {
  return list.map(l => {
    const calculated = enhanceReporting({
      spent: l.spent ?? 0,
      impressions: l.impressions ?? 0,
      conversions: l.conversions ?? 0,
      conversionValue: l.conversionValue ?? 0,
      clicks: l.clicks ?? 0
    });

    const campaignWarnings = findWarnings(l, warnings) ?? [];

    const restrictions = [ ...l.restrictions ?? [] ];
    if (campaignWarnings?.length) {
      campaignWarnings.forEach(w => !restrictions.includes(`logtype-${w.log_type}`) && restrictions.push(`logtype-${w.log_type}`))
    }

    return {
      ...l,
      ...calculated,
      restrictions,
      actions: {
        id: l.id,
        enabled: l.enabled,
        provider: adsCampaigns[l.type]?.provider ?? "go",
        is_feedcast: l.is_feedcast,
      }
    };
  });
};

const CampaignList = () => {
  const [ newC, setNewC ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  // Data
  const { list, setList, reporting, fetchLoading, warnings } = useCampaignData();

  // Processing
  const {
    processRowUpdate, handleProcessRowUpdateError, setConfirmEnable, DConfirmBudget, DConfirmEnable,
  } = useCampaignMutation({
    list, setList, setLoading
  });

  // Row Menu
  const { anchorEl, handleClick, handleClose } = useRowMenu();

  // Columns & focused Row for dropdown
  const { columns, rowFocus } = useMemo(() => CampaignColumns({
    handleEnableCampaign: (params) => setConfirmEnable(params),
    handleClick,
  }), [ setConfirmEnable, handleClick ]);

  const rows = useMemo(() => calculateRows(list, warnings), [ list, reporting ]);

  useEffect(() => setLoading(fetchLoading), [ fetchLoading ]);

  return (
    <AppSecured>
      <TabPanel index={!newC ? "1" : "2"} value={"1"} removePadding>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <DatePicker margin={false}/>
          <Filters toggleNew={() => setNewC(o => !o)}/>
        </Box>
        <Box>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <Box sx={{ mt: 2, ".MuiDataGrid-virtualScroller": { minHeight: '40vh' } }}>
                <CampaignTable
                  columns={columns}
                  rows={rows}
                  loading={loading}
                  processRowUpdate={processRowUpdate}
                  handleProcessRowUpdateError={handleProcessRowUpdateError}
                />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </TabPanel>
      <TabPanel index={!newC ? "1" : "2"} value={"2"} removePadding>
        <NewCampaign toggleNew={() => setNewC(o => !o)}/>
      </TabPanel>

      {/** Dialogs & Menu **/}
      {DConfirmBudget()}
      {DConfirmEnable}
      <CampaignRowMenu anchorEl={anchorEl} handleClose={handleClose} rowFocus={rowFocus}/>
    </AppSecured>
  )
}

export default CampaignList;
