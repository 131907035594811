import { ProductErrorEnhanced, ProductErrors } from "../types/products";
import googleErrors, { googleDoc } from "../../assets/resources/googleErrors";

const makeHeaderTitle = (s: string) => s.toLowerCase().replace(/^./, (firstLetter) => firstLetter.toUpperCase()).replace(/_/g, ' ');

export const enhanceProductErrors = (errors: ProductErrors[]) => {
  return errors.map(errorSource => enhanceErrorSource(errorSource));
};

const enhanceErrorSource = (source: ProductErrors) => {
  const enhancedErrors = source.errors.map(error => enhanceError(error, source.source));
  return { ...source, errors: enhancedErrors };
};

const enhanceError = (error: ProductErrorEnhanced, source: string): ProductErrorEnhanced => {
  switch (source) {
    case 'Google':
      return enhanceGoogleError(error);
    case 'Bing':
      return enhanceBingError(error);
    case 'Meta':
      return enhanceMetaError(error);
    default:
      return enhanceGenericError(error);
  }
};

const enhanceGoogleError = (error: ProductErrorEnhanced): ProductErrorEnhanced => {
  const { attribute, code, description } = error;

  let field = attribute ?? null; // Concerns specific attribute (gtin, shpping weight, etc)
  let help = googleDoc[code ?? 'missing_item_attribute_for_product_type'];

  let header = "";
  let body = "";

  if (help?.EN?.length) {
    header = code ?? 'header';
    body = code ? `${code}-desc` : description;
  } else {
    header = makeHeaderTitle(code ?? "");
    body = description;
  }

  return { ...error, field, help, header, body };
};

const enhanceMetaError = (error: ProductErrorEnhanced): ProductErrorEnhanced => {
  const { code, description } = error;
  const headerTitle = makeHeaderTitle(error.code ?? "");
  return { ...error, header: headerTitle, body: description };
}

const enhanceBingError = (error: ProductErrorEnhanced): ProductErrorEnhanced => {
  const { code, description } = error;
  return { ...error, header: description, body: description };
};

const enhanceGenericError = (error: ProductErrorEnhanced): ProductErrorEnhanced => {
  const [ title, body ] = error.description.split(':');
  const nodeError = googleErrors[title];
  let field: string | null = null;
  if (error.description.includes('[')) {
    field = error.description.split('[')[1].split(']')[0];
  }
  const header = nodeError ?? title;
  const enhancedBody = nodeError ? `${nodeError}-todo` : body;

  return { ...error, field, header, body: enhancedBody };
};

