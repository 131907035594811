import { FormattedMessage, IntlShape } from "react-intl";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { ImageLoader } from "../../../App/Blocks/Misc";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { HideImageOutlined, Preview } from "@mui/icons-material";
import React from "react";
import { AppDispatch } from "../../../../app/store";
import { setProductPreview } from "../../../../app/slices/product";

export const CreateReportingColumns = (intl: IntlShape, dispatch: AppDispatch): GridColDef[] => {
  return ([
    {
      field: "id",
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return (
          params.value
            ? <ImageLoader src={params.value}/>
            : <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
              <HideImageOutlined color={"disabled"}/>
            </Box>
        )
      }
    },
    {
      field: "reference",
      headerName: "Reference",
    },
    {
      field: "title",
      headerName: intl.formatMessage({ id: "title" }),
      flex: 3,
      renderCell: (params) => {
        const labels = params.row.labels;
        return <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
          <Typography
            variant={"body2"}
            sx={{
              whiteSpace: "no-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: '90%',
            }}
          >
            {params.value}
          </Typography>
          {labels.component}
        </Box>
      }
    },
    {
      field: "price",
      headerName: intl.formatMessage({ id: "price" }),
      flex: 1,
      type: "string",
      align: "right"
    },
    {
      field: "impressions",
      headerName: "Impressions",
      flex: 1,
      type: "number",
    },
    {
      field: "clicks",
      headerName: intl.formatMessage({ id: "clicks" }),
      flex: 1,
      type: "number",
    },
    {
      field: "cpc",
      headerName: "CPC",
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value === null) {
          return '';
        }
        return `${intl.formatNumber(params.value, {
          style: "currency",
          currency: "EUR"
        })}`
      }
    },
    {
      field: "clickRate",
      headerName: intl.formatMessage({ id: "clickRate" }),
      type: "number",
      flex: 1,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return params.value * 100;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    },
    {
      field: "conversions",
      headerName: intl.formatMessage({ id: "conversions" }),
      flex: 1,
      type: "number",
    },
    {
      field: "spent",
      headerName: intl.formatMessage({ id: "spent" }),
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value === null) {
          return '';
        }
        return `${intl.formatNumber(params.value, {
          style: "currency",
          currency: "EUR"
        })}`
      }
    },
    {
      field: "conversionValue",
      headerName: intl.formatMessage({ id: "conversionValue" }),
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value === null) {
          return '';
        }
        return `${intl.formatNumber(params.value, {
          style: "currency",
          currency: "EUR"
        })}`
      }
    },
    {
      field: "conversionCost",
      headerName: intl.formatMessage({ id: "conversionCost" }),
      type: "number",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value === null) {
          return '';
        }
        return `${intl.formatNumber(params.value, {
          style: "currency",
          currency: "EUR"
        })}`
      }
    },
    {
      field: "roi",
      headerName: "ROI",
      type: "number",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "end", width: '100%' }}>
            <Tooltip title={<FormattedMessage id={'preview-product'}/>}>
              <IconButton
                onClick={() => dispatch(setProductPreview(row.product_id))}
                disabled={!row.product_id}
              >
                <Preview/>
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ])
};
