import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setCurrentFeed } from "./app";
import { Paginated, SearchCriteria } from "../../../types";
import { Product } from "../types/products";
import productsApi from "../services/secured/products";

export const PRODUCTS_PER_PAGE = 10;

const initialState: ProductsSlice = {
  params: {
    feed: -1,
    page: 1,
    page_per: PRODUCTS_PER_PAGE,
  },
  list: {
    productIds: [],
    total: 0,
    products: [],
    serverPage: 1,
  },
  edit: null
}

const productsSlice = createSlice({
  initialState,
  name: "products",
  reducers: {
    setProduct: (state, action: PayloadAction<Product>) => {
      state.edit = action.payload;
    },
    setProductsParams: (state, action: PayloadAction<PParams>) => {
      state.params = action.payload;
      if (action.payload.q?.length || action.payload.status) {
        state.list = initialState.list;
      }
    },
    setProductsPage: (state, action: PayloadAction<number>) => {
      state.params.page = action.payload;
    },
    handleProductList: (state, action: PayloadAction<Paginated<Product>>) => {
      const { productsIds, total } = setProductsSlice(state, action);
      state.list.productIds = productsIds;
      state.list.total = total;
      state.list.products = action.payload.list;
      state.list.serverPage = action.payload.page;
    }
  },
  extraReducers: builder => {
    builder
    .addMatcher(setCurrentFeed.match, (state, action) => {
      state.params.feed = action.payload.id;
    })
  }
});

const setProductsSlice = (state: any, action: PayloadAction<Paginated<Product>>) => {
  const current = [ ...state.list.productIds ].filter(p => p.page !== action.payload.page);
  action.payload.list.forEach((k: any) => current.push({ id: k.id, page: action.payload.page }));
  return {
    productsIds: current.sort((a, b) => a.page - b.page),
    total: action.payload.found,
  }
}

export const productsReducer = productsSlice.reducer;
export const
  {
    setProduct,
    setProductsParams,
    setProductsPage,
    handleProductList,
  } = productsSlice.actions;

//============
// Getters
//============

export const getProductsParams = (state: RootState) => state.products.params;
export const getProductsList = (state: RootState) => state.products.list;

export const getProductPosition = (state: RootState) => {
  if (state.products.edit) {

    const page = state.products.params.page;
    const currPage = state.products.list.productIds.filter(p => p.page === page);
    const index = currPage.findIndex(p => p.id === state.products.edit?.id);

    if (index >= 0) {
      const realPos = (page * PRODUCTS_PER_PAGE) - (PRODUCTS_PER_PAGE - index) + 1;
      return {
        pos: realPos,
        total: state.products.list.total
      }
    }
  }
};
export const getProductNav = (state: RootState) => {
  if (state.products.edit) {
    const index = state.products.list.productIds.findIndex(p => p.id === state.products.edit?.id);
    if (index >= 0) {
      const tabLength = state.products.list.productIds.length;
      return {
        current: state.products.list.productIds[index],
        prev: index - 1 >= 0 ? state.products.list.productIds[index - 1].id : 0,
        next: index + 1 <= tabLength - 1 ? state.products.list.productIds[index + 1].id : -1
      }
    }
  }
}

//============
// Types
//============

export type ProductsSlice = {
  params: PParams,
  list: {
    productIds: {
      id: number,
      page: number,
    }[],
    total: number,
    products: Product[],
    serverPage: number,
  },
  edit: Product | null,
}

export type PParams = {
  feed: number,
} & Pick<SearchCriteria, 'page' | 'page_per'> & Partial<Pick<SearchCriteria, 'q' | 'status' | 'error_code'>>
