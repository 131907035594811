import ChannelErrors from "./Batch/ChannelErrors";
import CategoriesMapping from "./CategoriesMapping";
import React, { useState } from "react";
import AppSecured from "../../App/AppSecured/AppSecured";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../App/Blocks/AppTabs";
import { FormattedMessage } from "react-intl";
import { Category, ProductionQuantityLimits } from "@mui/icons-material";
import PageInfo from "../../App/Helpdesk/PageInfo";

const Optimisation = () => {
  const [ tab, setTab ] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newTab: number) => setTab(newTab);
  return (
    <AppSecured>
      <Box mb={1}>
        <PageInfo page={"optimisation"} />
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label={(
            <Box sx={{display:"flex", gap:1, }}>
              <ProductionQuantityLimits />
              <FormattedMessage id={'catalog-errors-recap'} />
            </Box>
          )} value={0}/>
          <Tab label={(
            <Box sx={{display:"flex", gap:1, }}>
              <Category />
              <FormattedMessage id={'categories'} />
            </Box>
          )} value={1}/>
        </Tabs>
      </Box>
      <TabPanel index={tab} value={0}>
        <ChannelErrors/>
      </TabPanel>
      <TabPanel index={tab} value={1}>
        <CategoriesMapping/>
      </TabPanel>
    </AppSecured>
  )
}

export default Optimisation;
