import projectsApi from "../../../app/services/secured/projects";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useHover } from "../../../app/custom_hooks";
import { useDrag } from "react-dnd";
import { getFeedSubStatus, niceUrl } from "../../../app/utils";
import { NavLink } from "react-router-dom";
import { setCurrentFeedLoaded, setShowBackdrop } from "../../../app/slices/app";
import Urls from "../../../assets/js/Urls";
import { Avatar, Badge, Box, Chip, Fade, Link, Paper, Stack, Typography, useTheme } from "@mui/material";
import Locale from "../../App/Blocks/Locale";

import { FormattedMessage } from "react-intl";
import React, { useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ArrowCircleRightOutlined, OpenWith, PanTool, ShoppingBagOutlined } from "@mui/icons-material";
import { feedSources } from "../../../app/slices/shop";
import { FeedSourceEnum, UserFeed } from "../../../app/types/feed";

import google from "../../../assets/img/google-ads.svg";
import merchant from "../../../assets/img/merchant_center.svg";
import meta from "../../../assets/img/meta.svg";
import microsoft from "../../../assets/img/microsoft.svg";
import feedApi from "../../../app/services/secured/feed";
import { addNotification } from "../../../app/slices/notifications";
import { motion } from "framer-motion";

export const FeedBlock = (feed: UserFeed) => {

  const dispatch = useAppDispatch();

  const [ ref, hover ] = useHover();
  const [ editProject, editProjectRes ] = projectsApi.useEditFeedProjectMutation();

  const fStatus = getFeedSubStatus(feed);
  const theme = useTheme();

  const bgColor = (fStatus === "active" || fStatus === "premium" || fStatus === "starter")
    ? theme.palette.success.main
    : fStatus === "trial"
      ? theme.palette.info.main
      : fStatus === "free"
        ? theme.palette.warning.main
        : theme.palette.error.main;

  const [ { isDragging, handlerId }, drag ] = useDrag(() => ({
    type: "feed",
    item: {
      id: feed.id,
      currProject: feed.project?.id,
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult() as any;

      if (dropResult) {
        if (dropResult.project) {
          if (item && dropResult && dropResult.project && dropResult.project !== feed.project?.id) {
            dispatch(setShowBackdrop(true));
            editProject({ feed: feed.id, project: dropResult.project });
          }
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    })
  }));

  useEffect(() => {
    if (editProjectRes.isSuccess || editProjectRes.isError) {
      dispatch(setShowBackdrop(false));
    }
  }, [ editProjectRes ]);

  const noAccounts = (!feed.platforms?.ads && !feed.platforms?.merchant && !feed.platforms?.meta && !feed.platforms?.microsoft);

  // const hasAlerts = useAppSelector(state => state.app.userAlerts.filter(a => a.feed.id === feed.id));
  // const googleAlert = hasAlerts.findIndex(a => a.account?.id === feed.platforms?.ads?.id) > -1;
  // const metaAlert = hasAlerts.findIndex(a => a.account?.id === feed.platforms?.meta?.id) > -1;
  // const microsoftAlert = hasAlerts.findIndex(a => a.account?.id === feed.platforms?.microsoft?.id) > -1;

  const hasAlerts = [];
  const googleAlert = false;
  const metaAlert = false;
  const microsoftAlert = false;

  // const theme = useTheme();

  const node = (
    <motion.div
      initial={{
        transform: "translateX(0%)",
        // background: "transparent",
      }}
      whileHover={{
        // transform:"scale(1.05)",
        // background: theme.palette.gradient.main,
        transform: "translateX(10px)",
      }}
    >
      <Paper
        className={"feed-block-project-wrapper project-shop"}
        sx={{
          p: 2,
          position: "relative",
          height: '100%',
        }}
      >
        <Link
          component={NavLink}
          className={"feed-block-project"}
          onClick={() => dispatch(setCurrentFeedLoaded(false))}
          to={Urls.dash(feed.id)}
          sx={{
            textDecoration: "none",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Stack
                direction={"row"}
                alignItems={"center"}
                mb={1}
                width={'100%'}
                color={"text.primary"}
              >
                <Avatar sx={{ bgcolor: bgColor, width: 18, height: 18, }}><></>
                </Avatar>
                <Typography variant={"h6"} mx={1}>{niceUrl(feed.name)}</Typography>
                <Locale locale={feed.locale}/>
                {feed.last_stat?.imported && (
                  <Typography alignItems={"center"} display={"flex"} ml={1}>
                    {feed?.last_stat?.imported}
                    <ShoppingBagOutlined/>
                  </Typography>)}
              </Stack>

              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                {feed.source && (
                  <img src={feedSources[feed.source ?? FeedSourceEnum.Manual]?.logo}
                       style={{ height: '20px', opacity: 0.7 }}/>
                )}
                {feed.platforms?.ads && <AccountBadge img={google} txt={"Google Ads"} alert={googleAlert}/>}
                {feed.platforms?.meta && <AccountBadge img={meta} txt={"Meta"} alert={metaAlert}/>}
                {feed.platforms?.microsoft && <AccountBadge img={microsoft} txt={"Microsoft"} alert={microsoftAlert}/>}
                {noAccounts && (<>
                  <Chip
                    color={"warning"}
                    size={"small"}
                    variant={"outlined"}
                    label={<FormattedMessage id={"no-accounts-linked"}/>}
                  />
                </>)}
              </Stack>
            </Box>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <ArrowCircleRightOutlined fontSize={"large"}/>
            </Stack>
          </Box>
        </Link>
      </Paper>
    </motion.div>
  )

  return <Grid2 xs={12}>
    <Box ref={ref}>
      {
        //@ts-ignore
        isDragging
          ? <Box sx={{ opacity: 0.3 }}>
            {node}
          </Box>
          : <Box
            ref={drag}
            className={'h-100'}
          >
            {node}
          </Box>
      }
    </Box>
  </Grid2>
}

const AccountBadge = ({ img, txt, alert }: { img: string, txt: string, alert?: boolean }) => {
  return (
    <Box>
      <Chip
        size={"small"}
        avatar={<Avatar src={img}/>}
        label={txt}
        color={alert ? "error" : undefined}
      />
    </Box>
  )
}
