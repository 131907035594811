import { GetCurrentFeedId } from "../../../../../../app/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../../../../app/hooks";
import { getUser } from "../../../../../../app/slices/app";
import campaignApi, { CreateMicrosoftCenterPayload } from "../../../../../../app/services/secured/campaign";
import SimpleForm, { SimpleFormData } from "../../../../../App/Blocks/SimpleForm";
import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Fade,
  FormControl,
  InputAdornment,
  Link,
  Stack,
  TextField
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Email, Microsoft, Public } from "@mui/icons-material";
import { LoadingButton } from "../../../../../App/Blocks/Misc";

export interface CreateMicrosoftCenterProps {
  onSuccess: () => void,
  onBack: () => void,
}

export const CreateMicrosoftCenter = (p: CreateMicrosoftCenterProps) => {

  const feed = GetCurrentFeedId();
  const intl = useIntl();
  const user = useAppSelector(getUser);
  const [ create, createRes ] = campaignApi.useCreateMicrosoftCenterMutation();
  const submit = (s: SimpleFormData) => {
    create({
      feed,
      ...s
    } as CreateMicrosoftCenterPayload);
  }

  useEffect(() => {
    if (createRes.isSuccess) {
      p.onSuccess();
    }
  }, [ createRes.isSuccess ]);

  const [ domain, setDomain ] = useState("");

  useEffect(() => {
    if (domain.includes("https://")) {
      setDomain(domain.replace("https://", ""));
    }
  }, [domain]);

  return <Fade in={true}>
    <Box>
      <Stack spacing={3}>
        <Alert severity={"info"} sx={{ display: "flex", alignItems: "center" }}>
          <AlertTitle sx={{ mb: 0 }}>
            <FormattedMessage id={"be-sure-checked"}/>
          </AlertTitle>
        </Alert>
        <SimpleForm onSubmit={submit}>
          <Grid2 container spacing={3}>
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  name={"email"}
                  required
                  label={<FormattedMessage id={"email"}/>}
                  defaultValue={user?.email}
                  InputProps={{
                    startAdornment: <InputAdornment position={"start"}>
                      <Email/>
                    </InputAdornment>
                  }}
                />
              </FormControl>
            </Grid2>
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  name={"name"}
                  required
                  placeholder={intl.formatMessage({ id: "microsoft-center-name" })}
                  label={<FormattedMessage id={"name"}/>}
                  InputProps={{
                    startAdornment: <InputAdornment position={"start"}>
                      <Microsoft/>
                    </InputAdornment>
                  }}
                  helperText={<FormattedMessage id={'free-field'} />}
                />
              </FormControl>
            </Grid2>
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  name={"url"}
                  required
                  placeholder={intl.formatMessage({ id: "your-domain-name" })}
                  label={<FormattedMessage id={"domain-name"}/>}
                  onChange={(e) => setDomain(e.target.value)}
                  value={domain}
                  InputProps={{
                    startAdornment: (
                      <React.Fragment>
                        <InputAdornment position={"start"}>
                          <Public/>
                        </InputAdornment>
                        <InputAdornment position={"start"}>
                          https://
                        </InputAdornment>
                      </React.Fragment>
                    )
                  }}
                  helperText={<FormattedMessage id={'helper-microsoft-center-domain'}/>}
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <Box display={"flex"} justifyContent={"end"} gap={1} mt={2}>
            <Button onClick={p.onBack}>
              <FormattedMessage id={"back"}/>
            </Button>
            <LoadingButton
              type={"submit"}
              variant={"contained"}
              data={createRes}
            >
              <FormattedMessage id={"create"}/>
            </LoadingButton>
          </Box>
        </SimpleForm>
      </Stack>
    </Box>
  </Fade>
}
