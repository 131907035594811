import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCampaign, getCampaignApi } from "../../../../../app/slices/campaign/getters";
import { useState } from "react";
import { addNotification } from "../../../../../app/slices/notifications";
import campaignApi from "../../../../../app/services/secured/campaign";
import { resetCampaign } from "../../../../../app/slices/campaign/campaign";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { useParams } from "react-router-dom";
import { PageParams } from "../_Campaign";
import { CampaignTypeIdentifier } from "../../../../../app/types/campaign";

interface UseDraftProps {
  campaignId?: number | string | null,
}

type GetCampaignDraftNodeIdProps = {
  feed: number,
  type?: CampaignTypeIdentifier,
  campaignId?: string | number | null
}

export const GetCampaignDraftNodeId = (props: GetCampaignDraftNodeIdProps) => {
  const { feed, type, campaignId } = props;
  const isNew = (campaignId === undefined || campaignId === null);
  return `campaign-draft-${feed}-${isNew ? `${type}-new` : campaignId}`;
}

export const useDraftFunctions = ({ campaignId }: UseDraftProps) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const { type } = useParams<PageParams>();
  const draftNodeId = GetCampaignDraftNodeId({ feed, type, campaignId });
  const campaignData = useAppSelector(getCampaignApi);

  //@ts-ignore
  // console.log(JSON.parse(window.localStorage.getItem(draftNodeId)));

  const saveDraft = (notification?: boolean) => {
    window.localStorage.setItem(draftNodeId, JSON.stringify(campaignData));
    if (notification) {
      dispatch(addNotification({ type: "info", message: "success-draft" }));
    }
  }

  const clearDraft = (notification?: boolean) => {
    window.localStorage.removeItem(draftNodeId);
    if (notification) {
      dispatch(addNotification({ type: "info", message: "success-clear-draft" }));
    }
  }

  return {
    saveDraft,
    clearDraft,
    draftNodeId
  }
}

const useCampaignDraft = () => {

  const dispatch = useAppDispatch();

  const [ draftLoad, setDraftLoad ] = useState(false);

  const campaign = useAppSelector(getCampaign);

  const { saveDraft, clearDraft, draftNodeId } = useDraftFunctions({ campaignId: campaign.id });

  const draft = window.localStorage.getItem(draftNodeId) ?? "";
  const draftJson = draft.length ? JSON.parse(draft) : null;
  const hasDraft = ((draft?.length || 0) > 0 && draftJson?.type === campaign.type);

  const saveDraftFakeLoading = () => {
    setDraftLoad(true);
    setTimeout(() => {
      saveDraft(true);
      setDraftLoad(false);
    }, 1500);
  }

  const handleDraftDelete = () => {
    if (draft) {
      setDraftLoad(true);
      setTimeout(() => {
        setDraftLoad(false);
        clearDraft(true);
        dispatch(campaignApi.util.invalidateTags([ "Campaign" ]));
        dispatch(resetCampaign());
      }, 1500);
    }
  }

  return {
    hasDraft,
    draft,
    deleteDraft: handleDraftDelete,
    saveDraft: saveDraftFakeLoading,
    isLoading: draftLoad,
    isNew: campaign?.id ? false : true,
  }
}

export default useCampaignDraft;
