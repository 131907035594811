import Urls from "../assets/js/Urls";
import { AppTourPage } from "../components/App/AppTour";

const key = "fc_access_token";
const keyRedirectAfterLogin = "fc_redirect_after_login";
const customCode = "fc_custom_code";

const productsCorrectionKey = "products_correction";

export const StorageManager = {
  get: (): string => window.localStorage.getItem(key) as string,
  set: (token: string): void => {
    window.localStorage.setItem(key, token);
  },
  setRedirectAfterLogin: (url: string) => {
    if (null === window.localStorage.getItem(keyRedirectAfterLogin)) {
      window.localStorage.setItem(keyRedirectAfterLogin, url);
    }
  },
  clear: (): void => {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(keyRedirectAfterLogin);
  },

  setLang: (lang: "fr"|"en") => {
    window.localStorage.setItem("lang", lang);
  },

  setMode: (mode: "light"|"dark") => {
    window.localStorage.setItem("mode", mode);
  },

  getAppTour: (page: AppTourPage) => {
    return window.localStorage.getItem(`tour-${page}`);
  },

  setAppTour: (page: AppTourPage) => {
    window.localStorage.setItem(`tour-${page}`, "1");
  },

  setHasSeenNews: () => {
    window.localStorage.setItem(`has-seen-news`, '1');
  },

  getHasSeenNews: () => Boolean(window.localStorage.getItem(`has-seen-news`)),
  clearHasSeenNews: () => window.localStorage.removeItem(`has-seen-news`),

  resetAppTour: (page: AppTourPage) => {
    return window.localStorage.removeItem(`tour-${page}`);
  },

  setIsDemo: (isDemo: boolean) => {
    window.localStorage.setItem("isDemo", isDemo ? "1" : "0");
  },
  getIsDemo:  () => {
    return Boolean(parseInt(window.localStorage.getItem("isDemo") ?? "0"));
  },

  getRedirectAfterLogin: (fallbackUrl: string): string => {
    const final = window.localStorage.getItem(keyRedirectAfterLogin) || fallbackUrl;
    window.localStorage.removeItem(keyRedirectAfterLogin);
    return final === process.env.REACT_APP_APP_DOMAIN + '/' ? process.env.REACT_APP_APP_DOMAIN + Urls.projects.list : final;
  },

  setRegistrationCode: (code: string | null) => {
    if (null !== code) {
      window.localStorage.setItem(customCode, code);
    } else {
      window.localStorage.removeItem(customCode);
    }
  },
  getRegistrationCode: (): string | null => window.localStorage.getItem(customCode),
}
