import { useAppSelector } from "../../../../../../../app/hooks";
import { getPerfMaxData } from "../../../../../../../app/slices/campaign/getters";
import { Alert, AlertTitle, Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NoPaddingTooltip } from "../../../../../../styledComponents";
import React from "react";

export const PicturesWarnings = () => {
  const picturesWarnings = (useAppSelector(getPerfMaxData).warnings ?? []).filter(w => w.value.startsWith("http"));
  const hasWarnings = (picturesWarnings.length || 0) > 0
  const hasAtLeastOneBlocking = picturesWarnings.filter(w => w.blocking).length > 0;
  return (
    hasWarnings ? (
      <Alert severity={hasAtLeastOneBlocking ? "error" : "warning"}>
        <AlertTitle>
          <FormattedMessage id={'assets-pictures-warnings'}/>
        </AlertTitle>
        <FormattedMessage id={'assets-pictures-warnings-desc'}/>
        <List disablePadding>
          {picturesWarnings.map((p, i) => (
            <ListItem key={`pic-issue-${i}`}>
              <NoPaddingTooltip
                title={(
                  <Box>
                    <img src={p.value} width={200} style={{ borderRadius: 8 }}/>
                  </Box>
                )}>
                <ListItemIcon sx={{mr: 1}}>
                  <Box
                    component={'a'} href={p.value}
                    sx={{ display: "flex", justifyContent: "center", width: '100%' }}
                    target={'_blank'}
                  >
                    <img src={p.value} height={40} style={{ borderRadius: 8 }}/>
                  </Box>
                </ListItemIcon>
              </NoPaddingTooltip>
              <ListItemText
                primary={(
                  <FormattedMessage id={p.error_code}/>
                )}
                secondary={(
                  <Box>
                    <FormattedMessage id={p.blocking ? "blocking" : "non-blocking"}/>
                  </Box>
                )}
              />
            </ListItem>
          ))}
        </List>
      </Alert>
    ) : null
  )
}
