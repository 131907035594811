import React from "react";
import { FormattedMessage } from "react-intl";
import { setNewFeedSource } from "../../app/slices/newfeed";
import { useAppDispatch } from "../../app/hooks";
import FCard from "../App/Blocks/Card";
import { UnavailableBlur } from "../App/Blocks/Misc";
import { Box, Button } from "@mui/material";
import { NewFeedDesign } from "./FeedImportMethod";
import Grid2 from "@mui/material/Unstable_Grid2";
import { feedSources } from "../../app/slices/shop";
import { FeedSourceEnum } from "../../app/types/feed";
import { motion } from "framer-motion";

type SourceProps = {
  type: "from-project" | "from-app",
} & NewFeedDesign;

const Source = ({ type, fullWidth, actions }: SourceProps) => {
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Grid2 container spacing={1}>
        <Shops
          fullWidth={fullWidth}
          shops={Object.keys(feedSources)
          .filter(s => s !== "fictif")
          .map(s => {
            return {
              value: parseInt(s) as FeedSourceEnum,
              image: feedSources[parseInt(s) as FeedSourceEnum].image,
              name: FeedSourceEnum[parseInt(s) as FeedSourceEnum],
              available: feedSources[parseInt(s) as FeedSourceEnum].available
            }
          })}
        />
      </Grid2>

      <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
        {type === "from-project" && (
          <Button
            variant={'contained'}
            onClick={() => dispatch(setNewFeedSource("fictif"))}
          >
            <FormattedMessage id={"pass-step"}/>
          </Button>
        )}
        {actions}
      </Box>
    </Box>
  )
}

const Shops = ({ shops, fullWidth }: { shops: Array<ShopProps> } & NewFeedDesign) => {
  return <React.Fragment>
    {shops.map(s =>
      <Grid2 xs={fullWidth ? 6 : 4} key={`shop-${s.name}`}>
        <Shop key={s.name} {...s} />
      </Grid2>
    )}
  </React.Fragment>;
}

const Shop = ({ image, available = false, name, value }: ShopProps) => {
  const dispatch = useAppDispatch();
  return (
    <motion.div
      initial={{
        transform: "scale(1)",
      }}
      whileHover={{
        transform: "scale(1.02)",
      }}
    >
      <Box height={'100%'}>
        <Box height={'100%'} position={"relative"}>
          <FCard
            sx={{ height: '100%', cursor: "pointer", background: '#fff' }}
            onClick={() => dispatch(setNewFeedSource(value))}
            clickable
          >
            <Box sx={{
              height: '100%',
              width: '100%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <img
                src={image}
                style={{ maxHeight: '40px', width: '100%' }}
              />
            </Box>
          </FCard>
          {!available && <UnavailableBlur/>}
        </Box>
      </Box>
    </motion.div>
  )
}

export default Source;

type ShopProps = {
  value: FeedSourceEnum,
  image: string,
  name: string,
  available?: boolean,
}
