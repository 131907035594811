import { AllProductStatus, Product, ProductData } from "../../../../app/types/products";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { GetCurrentFeedId } from "../../../../app/utils";
import { StorageManager } from "../../../../app/storage";
import productsApi from "../../../../app/services/secured/products";
import { Alert, AlertTitle, Box, Button, ButtonGroup, IconButton, Paper, Stack, Typography } from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import ErrorRow from "./ErrorRow";
import { useAppDispatch } from "../../../../app/hooks";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import { GoogleErrorModalData } from "../../../../app/slices/modal";
import { getProductStatus } from "../../../../app/utils/productStatusUtils";

interface ProductCorrectionProps extends Product, Pick<GoogleErrorModalData, "company"> {
  field: keyof ProductData | null;
  onSuccess: () => void;
  errorId: number;
  changePage: (newPage: number) => void;
  currentPage: number;
  maxPage: number;
}

const ProductCorrection = (p: ProductCorrectionProps) => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const [ newVal, setNewVal ] = useState<string | boolean | number>();

  // Default value
  useEffect(() => {
    if (p.field && p.custom_data && p.custom_data[p.field]) {
      setNewVal(p.custom_data[p.field]);
    }
  }, []);

  const [ update, updateRes ] = productsApi.useBatchUpdateMutation();

  const updateProduct = () => {

    let customData = {
      [`${p.field}`]: newVal
    };

    // Added logic for GTIN
    if (p.field === "gtin" && typeof newVal === "string" && newVal.length) {
      customData = { ...customData, identifier_exists: true }
    }

    update({
      feed,
      data: [ {
        id: p.id,
        custom_data: customData
      } ]
    })
  };

  useEffect(() => {
    if (updateRes.isSuccess) {
      p.onSuccess();
      dispatch(NewSuccessNotification("success-batch-edit"));
    }
  }, [ updateRes ]);


  const onInputChange = (val: string | boolean | number) => {
    setNewVal(val);
  }

  return <Box>
    <AlertPendingValidation status={p[`status_${p.company}`]}/>
    <ErrorRow
      {...p}
      field={p.field}
      onChange={onInputChange}
      isCorrected={false}
    />
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"end"}
      sx={{ mt: 3 }}
    >
      <Nav changePage={p.changePage} currentPage={p.currentPage} maxPage={p.maxPage}/>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
      >
        <Button
          variant={"contained"}
          onClick={updateProduct}
          sx={{ px: 5, }}
          disabled={!p.field}
        >
          <FormattedMessage id={"validate"}/>
        </Button>
      </Stack>
    </Box>
  </Box>
}

interface AlertPendingValidationProps {
  status: AllProductStatus,
}

const AlertPendingValidation = ({ status }: AlertPendingValidationProps) => {
  const statuses = useMemo(() => getProductStatus(status), [status]);
  return statuses.includes("MANUALLY_CORRECTED") ? (
    <Alert severity={"info"} sx={{ mb: 2 }}>
      <AlertTitle>
        <FormattedMessage id={'pending-new-validation'}/>
      </AlertTitle>
      <FormattedMessage id={'batch-pending-validation-desc'}/>
    </Alert>
  ) : null;
}

interface NavProps extends Pick<ProductCorrectionProps, 'currentPage' | 'changePage' | 'maxPage'> {
}

const Nav = (p: NavProps) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
    >
      <ButtonGroup>
        <IconButton
          disabled={p.currentPage === 0}
          onClick={() => p.changePage(p.currentPage - 1)}
        >
          <ArrowCircleLeft/>
        </IconButton>
        <IconButton
          disabled={p.currentPage === p.maxPage}
          onClick={() => p.changePage(p.currentPage + 1)}
        >
          <ArrowCircleRight/>
        </IconButton>
      </ButtonGroup>
      <Typography variant={"body2"}>
        {p.currentPage + 1} / {p.maxPage + 1}
      </Typography>
    </Stack>
  )
}

export default ProductCorrection;
