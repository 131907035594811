import { useAppDispatch } from "../../../../app/hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectedProduct, setSelectedProducts } from "../../../../app/slices/app";
import { modalShowLabel } from "../../../../app/slices/modal";
import { Button, Fade } from "@mui/material";
import { TagSharp } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export const CustomToolbar = () => {
  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const [ showLabelButton, setShowLabelButton ] = useState(false);
  const selected = apiRef.current.getSelectedRows();
  const selectedMemo = useMemo(() => selected, [ selected.size ]);

  const makeSelectedProducts = useCallback(() => {
    const selectedProducts: SelectedProduct[] = [];
    if (selectedMemo.size) {
      selectedMemo.forEach((s) => selectedProducts.push({ id: s.id, ref: s.reference }));
    }
    return selectedProducts;
  }, [ selectedMemo.size ]);

  useEffect(() => {
    setShowLabelButton(selectedMemo.size > 0);
    const selectedProducts = makeSelectedProducts();
    dispatch(setSelectedProducts(selectedProducts));
  }, [ selectedMemo.size ]);

  const showModalLabel = () => dispatch(modalShowLabel({}));
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton className={"product-reporting-filter"}/>
      <GridToolbarDensitySelector/>
      <GridToolbarExport/>
      <Fade in={showLabelButton}>
        <Button
          size={"small"}
          startIcon={<TagSharp/>}
          onClick={showModalLabel}
        >
          <FormattedMessage id={"handle-dynamic-label"}/>
        </Button>
      </Fade>
    </GridToolbarContainer>
  )
}

