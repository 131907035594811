import { InputGroupIcon, LoadingButton, Subtitle } from "../../../App/Blocks/Misc";
import { FormattedMessage, useIntl } from "react-intl";
import { Passwords } from "../../../App/AppPublic/Auth/Register";
import SimpleForm from "../../../App/Blocks/SimpleForm";
import profileApi from "../../../../app/services/secured/profile";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import FCard from "../../../App/Blocks/Card";
import { Box, Button, Collapse, FormControl, InputAdornment, Stack, TextField } from "@mui/material";
import { LockOpen } from "@mui/icons-material";
import { NewPasswordParams } from "../../../../app/types/profile";

const Security = () => {

  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [ showPassEdit, setShowPassEdit ] = useState(false);

  const [ newPass, newPassRes ] = profileApi.useSetNewPasswordMutation();

  useEffect(() => {
    if (newPassRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "new-password-success" })));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [ newPassRes ]);

  return (
    <Stack spacing={1}>
      <Box>
        <Button
          variant={"contained"}
          onClick={() => setShowPassEdit(v => !v)}
        >
          <FormattedMessage id={'edit-password'}/>
        </Button>
      </Box>
      <Collapse in={showPassEdit}>
        <SimpleForm onSubmit={s => newPass(s as NewPasswordParams)}>
          <FCard>
            <FormControl fullWidth sx={{mb: 2}}>
              <TextField
                name={"current"}
                type={"password"}
                required
                margin={"dense"}
                label={<FormattedMessage id={"current-password"}/>}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <LockOpen/>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <Passwords large={true}/>
            <Box display={"flex"} justifyContent={"end"} mt={3}>
              <LoadingButton
                data={newPassRes}
                type={"submit"}
                variant={"contained"}
              >
                <FormattedMessage id={"confirm-new-password"}/>
              </LoadingButton>
            </Box>
          </FCard>
        </SimpleForm>
      </Collapse>
    </Stack>


  )
}

export default Security;
