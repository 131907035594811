import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { GetCurrentFeedId, getImageFormat, ImageFormat } from "../../../../../../../app/utils";
import campaignApi from "../../../../../../../app/services/secured/campaign";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { Box, FormControl, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import AssetsZone from "../AssetZone";
import { setCroppedImage } from "../../../../../../../app/slices/app";
import { UploadSlider } from "./UploadSlider";
import { DropAreaHeader } from "./DropAreaHeader";
import { FormattedMessage } from "react-intl";
import DropAreaFilters from "./DropAreaFilters";
import { AssetsProps, getModalData } from "../../../../../../../app/slices/modal";

type Upload = {
  name: string,
  requestId: string,
  format: ImageFormat,
  url: string
}

type Call = {
  requestId: string,
  status: string,
  data: string
}

export interface AssetsFilters {
  formatFilter: ImageFormat | "all";
  nbColumns: number;
}

const areAllCallsCompleted = (calls: Call[]) => {
  return calls.every(call => call.status === 'fulfilled' || call.status === 'rejected');
};


const DropArea = () => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const drawerData:AssetsProps|undefined = useAppSelector(getModalData);
  const hasDrawerFilter = typeof drawerData === "object";

  const [ images, setImages ] = useState<Array<File>>([]);
  const [ showUploads, setShowUploads ] = useState<boolean>(false);
  const [ allUploads, setAllUploads ] = useState<Upload[]>([]);
  const [ allCalls, setAllCalls ] = useState<Call[]>([]);
  const [ formatFilter, setFormatFilter ] = useState<ImageFormat | "all">("all");
  const [ nbColumns, setNbColumns ] = useState<number>(3);

  const croppedI = useAppSelector(state => state.app.croppedImage);

  const form = useRef<HTMLFormElement>(null);
  const inputF = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLDivElement>(null);

  const [ setAsset, setAssetRes ] = campaignApi.useSetAssetsMutation();

  const [ { canDrop, isOver }, drop ] = useDrop(() => ({
    accept: [ NativeTypes.FILE ],
    drop(item: { files: File[] }) {
      setImages(item.files);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  useEffect(() => {
    if (drawerData?.ratio) {
      setFormatFilter(drawerData?.ratio);
    }
  }, [drawerData?.ratio]);

  // Upload logic
  useEffect(() => {
    if (images.length > 0) {
      const firstNode = images[0];
      const list = new DataTransfer();
      list.items.add(firstNode);

      const url = URL.createObjectURL(firstNode);
      const img = new Image();

      img.onload = function () {
        if (inputF.current) {
          inputF.current.files = list.files;
          const data = new FormData(form.current as HTMLFormElement);
          const call = setAsset({ feed, data });

          const a = [ ...allUploads ];
          a.push({
            name: firstNode.name,
            requestId: call.requestId,
            url: url,
            format: getImageFormat({ width: img.width, height: img.height })
          });
          setAllUploads(a);
        }
        // URL.revokeObjectURL(img.src);
      }
      img.src = url;
    }
  }, [ images ]);

  // Upload cropped image
  useEffect(() => {
    if (croppedI) {
      setImages([ croppedI ]);
      dispatch(setCroppedImage(null));
    }
  }, [ croppedI ]);

  // Calls handling
  useEffect(() => {
    if (setAssetRes.isSuccess || setAssetRes.isError) {
      const i = [ ...images ];
      i.shift();
      setImages(i);
    }

    if (setAssetRes) {
      const c = [ ...allCalls ].filter(cc => cc.requestId !== setAssetRes?.requestId);
      if (setAssetRes.requestId) {
        c.push({
          requestId: setAssetRes.requestId,
          status: setAssetRes.status,
          //@ts-ignore
          data: setAssetRes.isError ? setAssetRes?.error.data?.error : "",
        })
        setAllCalls(c);
      }
    }
  }, [ setAssetRes ]);

  // Only keep max. 5 calls in the feedback Slider
  useLayoutEffect(() => {
    if (allUploads.length > 0) {
      setShowUploads(true);
    }

    if (allUploads.length > 5) {
      const u = [ ...allUploads ];
      u.shift();
      setAllUploads(u);

      const c = [ ...allCalls ];
      c.shift();
      setAllCalls(c);
    }
  }, [ allUploads ]);

  // Invalidate cache
  useEffect(() => {
    if (allCalls.length && areAllCallsCompleted(allCalls)) {
      setTimeout(() => {
        dispatch(campaignApi.util.invalidateTags([ "Asset" ]));
      }, 1000);
    }
  }, [ allCalls ]);


  return (
    <Box height={'100%'} position={"relative"}>
      <Box ref={descRef} id={"dropzone"} width={'100%'} height={'100%'}>
        <form ref={form} style={{ width: '100%', height: '100%', overflow: "hidden" }}>
          <Paper
            ref={drop}
            className={(canDrop || isOver) ? "animate-border" : ""}
            sx={{
              boxShadow: "none",
              p: 2,
              width: '100%',
              borderRadius: '8px',
              height: '100%',
            }}
          >
            <Stack spacing={1}>
              <DropAreaHeader inputF={inputF}/>
              <DropAreaFilters
                disabled={hasDrawerFilter}
                formatFilter={formatFilter}
                setFormatFilter={setFormatFilter}
                nbColumns={nbColumns}
                setNbColumns={setNbColumns}
              />
              <AssetsZone
                formatFilter={formatFilter}
                nbColumns={nbColumns}
              />
            </Stack>
          </Paper>
          <Box display={"none"}>
            <input
              id={"upload-file"}
              ref={inputF}
              multiple
              type={"file"}
              name={"files"}
              onChange={(e) => e.target.files && setImages(e.target.files as any)}
            />
          </Box>
        </form>
        <UploadSlider
          allUploads={allUploads}
          allCalls={allCalls}
          showUploads={showUploads}
          setShowUploads={setShowUploads}
        />
      </Box>
    </Box>
  )
}

export default DropArea;
