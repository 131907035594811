import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Notification, removeNotification } from "../../app/slices/notifications";
import { Alert, CircularProgress, Slide, Snackbar, SnackbarContent, Stack } from "@mui/material";
import { CheckCircle, CheckCircleOutlined } from "@mui/icons-material";

const CustomToast = (toast: Notification) => {
  const dispatch = useAppDispatch();
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    if (toast?.id) {
      setShow(true);
    }
  }, [ toast?.id ]);

  const onClose = () => {
    setShow(false);
    setTimeout(() => dispatch(removeNotification(toast?.id)), 500);
  };

  return <>
    {toast && (
      <Slide in={show} direction={"up"}>
        <Snackbar
          open={show}
          autoHideDuration={!toast.infinite ? 3000 : undefined}
          onClose={onClose}
          ClickAwayListenerProps={toast.loader ? { onClickAway: () => null } : {}}
        >
          {
            toast.type !== "error"
              ? <SnackbarContent
                message={<FormattedMessage id={toast.message}/>}
                action={
                  toast.loader
                    ? <CircularProgress size={"1rem"} sx={{ color: "#fff" }}/>
                    : toast.type === "success"
                      ? <CheckCircleOutlined />
                      : undefined
                }
              />
              : <Alert onClose={onClose} severity={toast?.type as any ?? "danger"} sx={{
                maxWidth: '35vw',
                boxShadow: "none",
                wordWrap: "break-word",
              }}>
                <FormattedMessage id={toast.message}/>
              </Alert>
          }
        </Snackbar>
      </Slide>
    )}
  </>
}

const AppToast = () => {
  const toasts: Array<Notification> = useAppSelector(state => state.notification);
  return <>
    {toasts.map(t => <CustomToast key={t.id} {...t} />)}
  </>
};

export default AppToast;
