import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Check, Troubleshoot, WarningAmberRounded } from "@mui/icons-material";
import { AdsProvider, AvailableCompany, Company } from "../../../../../app/types/campaign";
import { SkeletonLoader } from "../../../../App/Blocks/Misc";
import channelsApi, { Pixel } from "../../../../../app/services/secured/channels";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { useAppDispatch } from "../../../../../app/hooks";
import { FormattedMessage } from "react-intl";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import FCard from "../../../../App/Blocks/Card";
import { ModalTypes, openModal } from "../../../../../app/slices/modal";

interface TrackingProps {
  company: AvailableCompany,
}

const Tracking = ({ company }: TrackingProps) => {

  const feed = GetCurrentFeedId();
  const node = adsCompanies[company].provider as Extract<AdsProvider, "go" | "fb" | "bg">;

  const trackingQuery = channelsApi.useGetTrackingQuery({ feed });
  const tracking = trackingQuery.data;
  const channelTracking = tracking ? tracking[node] : null;

  const hasPixels = tracking ? (channelTracking?.length || 0) > 0 : false;
  const hasPixelsWithConversion = (channelTracking?.filter(t => t.has_conversions).length || 0) > 0;

  return (
    <FCard sx={{ height: '100%' }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
        <Stack spacing={1}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Troubleshoot/>
              <Typography variant={"subtitle2"}>
                Tracking
              </Typography>
            </Box>
            {!hasPixels && (
              <WarningAmberRounded color={"warning"}/>
            )}
          </Box>
          {
            hasPixels ? (
              <>
                <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                  <Check color={"success"} fontSize={"small"}/>
                  <Typography color={"text.secondary"} variant={"body2"}>
                    <FormattedMessage id={'pixels-detected'}/>
                  </Typography>
                </Box>
                {hasPixelsWithConversion ? (
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Check color={"success"}/>
                    <Typography variant={"body2"} color={"text.secondary"}>
                      <FormattedMessage id={'conversions-detected'}/>
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <WarningAmberRounded color={"warning"}/>
                    <Typography variant={"body2"}>
                      <FormattedMessage id={'no-conversions-detected'}/>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Typography variant={"body2"} color={"text.secondary"}>
                <FormattedMessage id={'no-pixels-detected'}/>
              </Typography>
            )
          }
        </Stack>

        <SkeletonLoader load={trackingQuery.isLoading}>
          {
            //@ts-ignore
            tracking && <TrackingCard company={company} pixels={tracking[node]}/>
          }
        </SkeletonLoader>
      </Box>
    </FCard>
  )
}

interface TrackingCardProps {
  company: Company,
  pixels?: Pixel[],
}

const TrackingCard = ({ pixels }: TrackingCardProps) => {
  const dispatch = useAppDispatch();
  const hasPixels = (pixels?.length || 0) > 0;
  const openDrawer = () => dispatch(openModal({ type: ModalTypes.DrawerTracking, data: pixels ?? [] }));
  return (
    <Box>
      {hasPixels && (
        <Button
          fullWidth
          onClick={openDrawer}
        >
          <FormattedMessage id={'pixel-list'}/>
        </Button>
      )}
    </Box>
  )
}

export default Tracking;
