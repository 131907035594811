import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { closeModal, getModalData, isModalOpened, ModalTypes } from "../../../../app/slices/modal";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect } from "react";
import { AvailableCompany } from "../../../../app/types/campaign";
import { CloudSync } from "@mui/icons-material";
import { capitalizeFirstLetter, GetCurrentFeedId } from "../../../../app/utils";
import channelsApi from "../../../../app/services/secured/channels";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import { LoadingButton } from "../../../App/Blocks/Misc";

export default () => {
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.SyncFeed));
  const data = useAppSelector<{ company: AvailableCompany } | null>(getModalData);
  const close = () => dispatch(closeModal());

  const [ googleSync, googleSyncRes ] = channelsApi.useSyncImportFeedMutation();
  const mutation = data?.company === "google" ? googleSync : null;

  const sync = () => {
    if (mutation) {
      mutation({ feed });
    }
  }

  useEffect(() => {
    if (googleSyncRes.isSuccess) {
      dispatch(NewSuccessNotification("success-sync-feed"));
      close();
    }
  }, [ googleSyncRes.isSuccess ]);

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogTitle sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <CloudSync/>
        <FormattedMessage id={'sync-feed'}/>
        <Box>-</Box>
        {data && capitalizeFirstLetter(data?.company)}
      </DialogTitle>
      <DialogContent dividers>
        <FormattedMessage
          id={'are-you-sure-sync-feed'}
          values={{
            b: (txt) => <b>{txt}</b>,
            br: <br/>,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={"outlined"}
          onClick={close}
        >
          <FormattedMessage id={'close'}/>
        </Button>
        <LoadingButton
          data={googleSyncRes}
          variant={"contained"}
          color={"secondary"}
          onClick={sync}
          reset
        >
          <FormattedMessage id={'confirm'}/>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}


