import feedApi from "../../../../../app/services/secured/feed";
import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter, GetCurrentFeedId } from "../../../../../app/utils";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Urls from "../../../../../assets/js/Urls";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import { FeedSubscriptionData, Price } from "../../../../../app/types/feed";
import { providerToCompany } from "../../../../../app/slices/campaign/constants";
import { AdsProvider } from "../../../../../app/types/campaign";

type DialogCancelUpdatesProps = {
  prices?: Array<Price>,
  isShown: boolean,
} & Partial<Pick<FeedSubscriptionData, "next_removed_platforms" | "next_periods">>

const DialogCancelUpdates = (p: DialogCancelUpdatesProps) => {

  const feed = GetCurrentFeedId();
  const [ clear, clearRes ] = feedApi.useClearSubscriptionUpdatesMutation();

  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (clearRes.isSuccess) {
      window.location.reload();
    }
  }, [ clearRes.isSuccess ]);

  useEffect(() => {
    if ((p.next_periods?.length || p.next_removed_platforms?.length) && p.isShown) {
      setOpen(true);
    }
  }, [ p ]);

  const onClose = () => setOpen(false);

  const hasDisabledPlatforms = (p.next_removed_platforms?.length || 0) > 0;

  return <React.Fragment>
    <Dialog
      open={open}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle color={"warning.main"}>
        <FormattedMessage id={"scheduled-edits"}/>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id={"next-edits"}/>
          {p.next_periods?.map((u, i) => <ul key={`u-${i}`}>
            {u.map((l, j) => {
              const priceNode = p.prices?.find(p => p.code === l);
              const label = priceNode?.type === "pack"
                ? <span>Plan <b><FormattedMessage id={l}/></b></span>
                :
                <span><FormattedMessage id={"limit"}/> <b>{priceNode?.max_products} <FormattedMessage id={"products"}/></b></span>
              return <li key={`l-${j}`}>
                {label}
              </li>
            })}
          </ul>)}
          {
            hasDisabledPlatforms && (
              <Box>
                <Divider sx={{ mb: 1 }}/>
                <FormattedMessage
                  id={"next-removed-platforms-desc"}
                  values={{
                    b: (txt) => <b>{txt}</b>
                  }}
                />
                <ul>
                  {p.next_removed_platforms?.map(p => (
                    <li key={`platform-${p}`}>
                      {capitalizeFirstLetter(providerToCompany[p as AdsProvider])}
                    </li>
                  ))}
                </ul>

              </Box>
            )
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          to={Urls.settings.subList}
          onClick={onClose}
        >
          <FormattedMessage id={"back"}/>
        </Button>
        <LoadingButton
          onClick={() => clear({ feed })}
          data={clearRes}
          color={"warning"}
          variant={"contained"}
        >
          <FormattedMessage id={"cancel-edits"}/>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}

export default DialogCancelUpdates;
