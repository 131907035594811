import { getFeedSubStatus } from "../../../../../app/utils";
import React from "react";
import { SubscriptionState } from "../Subscription";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useSubscription } from "../hooks/useSubscription";
import { SubCard } from "./SubCard";
import { FeedcastPlan } from "../constants";

type SubCardsProps = {
  active: FeedcastPlan,
  onPackChange: (packId: number) => void,
  currSub: SubscriptionState
}

export const TRIAL_VAL_HT = 149;

const SubPlans = ({ onPackChange, active, currSub }: SubCardsProps) => {

  const [sub, feedData] = useSubscription(currSub.feed);
  const currSubStatus = feedData.data ? getFeedSubStatus(feedData.data) : "free";
  const packs = sub.data?.prices.filter(p => p.type === "pack");
  const isNewSub = sub.data?.is_new ?? false;

  const onClick = (pack: FeedcastPlan) => {
    const packId = sub.data?.prices.find(p => p.code === pack);
    onPackChange(packId?.id ?? 0);
  }

  return <Box mt={1}>
    <Grid2 container spacing={2}>
      <Grid2 xs>
        <SubCard
          pack={{
            code: "free",
            id: 0,
            type: "pack",
            price: 0,
            selected: currSubStatus === "free",
            max_products: 1000,
            additional_platforms: null,
          }}
          active={active === "free"}
          onClick={() => onClick("free")}
        />
      </Grid2>
      <Grid2 xs>
        <SubCard
          pack={packs?.find(p => p.code === "starter")}
          active={active === "starter"}
          onClick={() => onClick("starter")}
        />
      </Grid2>
      <Grid2 xs>
        <SubCard
          pack={packs?.find(p => p.code === "premium")}
          active={active === "premium"}
          onClick={() => onClick("premium")}
          trial={isNewSub}
        />
      </Grid2>
    </Grid2>
  </Box>
}

export default SubPlans;
