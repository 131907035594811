import { Typography } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import authApi from '../../../app/services/public/auth';
import { StorageManager } from '../../../app/storage';
import Urls from '../../../assets/js/Urls';
import SimpleForm from '../../../components/App/Blocks/SimpleForm';
import AuthWrapper from '../AuthWrapper';
import { containerAnimation, formatRegistrationData } from '../utils';
import RegisterSuccess from './RegisterSuccess';
import RegistrationForm from './RegisterWrapper';
import useRegisterForm from './useRegisterForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Register = () => {

   const params = new URLSearchParams(window.location.search);
   const resellerCode = params.get("code") || "";

   const {
      formValues,
      setFormValues,
      errors,
      handleSubmit,
      registerRes
   } = useRegisterForm();

   const [registrationComplete, setRegistrationComplete] = useState(false);
   const [registerWithGoogle, registerGoogleRes] = authApi.useLoginWithGoogleMutation();

   const onGoogleOAuth = useCallback(async (credentialResponse: CredentialResponse) => {
      const formattedData = formatRegistrationData(formValues, credentialResponse.credential, resellerCode);
      registerWithGoogle(formattedData);
   }, [formValues, registerWithGoogle, resellerCode]);

   const onOAuthError = () => {
      console.error("Google OAuth Error");
   };

   useEffect(() => {
      if (registerGoogleRes.isSuccess) {
         const accessToken = registerGoogleRes.data.access_token;
         StorageManager.set(accessToken);
         window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.roundabout;
         cleanCookies();
      }
   }, [registerGoogleRes.isSuccess]);

   useEffect(() => {
      if (registerRes.isSuccess) {
         setRegistrationComplete(true);
         cleanCookies();
      }
   }, [registerRes.isSuccess]);

   if (registrationComplete) {
      return <RegisterSuccess email={formValues.email} />;
   }

   const cleanCookies = () => {
      Cookies.remove('email');
      Cookies.remove('domain');
      Cookies.remove('feed_url');
      Cookies.remove('country');
      Cookies.remove('language');
   };

   return (
      <AuthWrapper>
         <SimpleForm onSubmit={handleSubmit}>
            <motion.div {...containerAnimation}>
               <Typography variant="h4" mb={2} textAlign="center">
                  <FormattedMessage id="register" />
               </Typography>
               <RegistrationForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                  errors={errors}
                  onGoogleOAuth={onGoogleOAuth}
                  onOAuthError={onOAuthError}
                  registerRes={registerRes}
               />
            </motion.div>
         </SimpleForm>
      </AuthWrapper>
   );
};

export default Register;
