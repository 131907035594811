import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, MenuItem, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PAge, PAgeObject, PGender, PGenderObject, PSizeObject } from "../../../../../app/types/products";
import React from "react";
import { ProductAttributesGroupProps } from "./ProductAttributes";
import { useAppDispatch } from "../../../../../app/hooks";
import { setCustomData } from "../../../../../app/slices/product";

export const Detail = ({
  productData, viewPage, disableInputs, customData
}: ProductAttributesGroupProps) => {
  const dispatch = useAppDispatch();
  return (
    <>
      <Grid2 xs={12}>
        <Typography>
          <FormattedMessage id={'product-detail'} />
        </Typography>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"color"}/>}
            size={'small'}
            disabled={disableInputs}
            defaultValue={productData?.color_k}
            onChange={(e) => dispatch(setCustomData({
              ...customData,
              color_k: e.target.value,
            }))}
          />
        </FormControl>
      </Grid2>

      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"material"}/>}
            size={'small'}
            disabled={disableInputs}
            defaultValue={productData?.material_k}
            onChange={(e) => dispatch(setCustomData({
              ...customData, material_k: e.target.value
            }))}
          />
        </FormControl>
      </Grid2>

      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            select
            disabled={disableInputs}
            label={<FormattedMessage id={"gender"}/>}
            size={'small'}
            onChange={(e) => dispatch(setCustomData({
              ...customData,
              gender_k: e.target.value as PGender
            }))}
            defaultValue={productData?.gender_k}
          >
            <MenuItem>-</MenuItem>
            {PGenderObject.map(p => <MenuItem key={`gender-${p}`} value={p}>
              <FormattedMessage id={p}/></MenuItem>)}
          </TextField>
        </FormControl>
      </Grid2>

      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"age-group"}/>}
            size={'small'}
            select
            disabled={disableInputs}
            onChange={(e) => dispatch(setCustomData({
              ...customData,
              age_group_k: e.target.value as PAge
            }))}
            defaultValue={productData?.age_group_k}
          >
            <MenuItem>-</MenuItem>
            {PAgeObject.map(p => <MenuItem key={`age-group-${p}`} value={p}>
              <FormattedMessage id={p}/></MenuItem>)}
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            select
            size={'small'}
            label={<FormattedMessage id={"sizes"}/>}
            defaultValue={productData?.size_k ? productData?.size_k.split("/").map(s => s) : []}
            disabled={disableInputs}
            onChange={(s) => {
              const val = s.target.value as unknown as string[];
              if (val.length) {
                dispatch(setCustomData({ ...customData, size_k: val.join("/") }))
              }
            }}
            SelectProps={{
              multiple: true,
            }}
          >
            {PSizeObject.map(p => <MenuItem value={p}>{p}</MenuItem>)}
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        {/*<Divider/>*/}
      </Grid2>
    </>
  )
}
