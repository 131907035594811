import useValueWithUnitManager from "../hooks/useValueWithUnitManager";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { ProductAttributesGroupProps, WeightUnit } from "./ProductAttributes";
import { useAppDispatch } from "../../../../../app/hooks";
import { setCustomData } from "../../../../../app/slices/product";

export const Misc = ({
  productData, viewPage, disableInputs, customData
}: ProductAttributesGroupProps) => {

  const dispatch = useAppDispatch();

  // Product Weight
  const updateProductWeight = (weight: string) => {
    dispatch(setCustomData({
      ...customData,
      product_weight: weight
    }));
  };

  const {
    valueRef: productWeightRef,
    currentUnit: productUnit,
    setValueWithUnit: setProductWeight,
    defaultValue: defaultProductWeight
  } = useValueWithUnitManager(productData?.product_weight, "kg", updateProductWeight);


  // Shipping Weight
  const updateShippingWeight = (weight: string) => {
    dispatch(setCustomData({
      ...customData,
      shipping_weight: weight,
    }))
  }
  const {
    valueRef: shippingWeightRef,
    currentUnit: shippingUnit,
    setValueWithUnit: setShippingWeight,
    defaultValue: defaultValueShippingWeight
  } = useValueWithUnitManager(productData?.shipping_weight, "kg", updateShippingWeight);

  return (
    <>
      <Grid2 xs={12}>
        <Typography>
          Misc.
        </Typography>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            inputRef={productWeightRef}
            disabled={disableInputs}
            label={<FormattedMessage id={"product-weight"}/>}
            size={'small'}
            onChange={() => setProductWeight()}
            defaultValue={defaultProductWeight}
            type={"number"}
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              endAdornment: <Select
                disabled={disableInputs}
                size={"small"}
                sx={{ "& *": { border: "none" } }}
                onChange={(e) => setProductWeight(e.target.value as WeightUnit)}
                defaultValue={productUnit}
              >
                <MenuItem value={'kg'}>kg</MenuItem>
                <MenuItem value={'g'}>g</MenuItem>
                <MenuItem value={'lb'}>lb</MenuItem>
                <MenuItem value={'oz'}>oz</MenuItem>
              </Select>
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            inputRef={shippingWeightRef}
            disabled={disableInputs}
            label={<FormattedMessage id={"shipping-weight"}/>}
            size={'small'}
            onChange={() => setShippingWeight()}
            defaultValue={defaultValueShippingWeight}
            type={"number"}
            inputProps={{
              step: 0.01,
            }}
            InputProps={{
              endAdornment: <Select
                disabled={disableInputs}
                size={"small"}
                sx={{ "& *": { border: "none" } }}
                onChange={(e) => setShippingWeight(e.target.value as WeightUnit)}
                defaultValue={shippingUnit}
              >
                <MenuItem value={'kg'}>kg</MenuItem>
                <MenuItem value={'g'}>g</MenuItem>
                <MenuItem value={'lb'}>lb</MenuItem>
                <MenuItem value={'oz'}>oz</MenuItem>
              </Select>
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={6} py={viewPage ? undefined : 0}>
        <Box>
          <FormControlLabel
            control={<Checkbox
              defaultChecked={productData?.is_bundle ?? false}
              onChange={(e) => setCustomData({ ...customData, is_bundle: e.target.checked })}
            />}
            label={(
              <Typography color={"text.secondary"}>
                <FormattedMessage id={'adult_k'}/>
              </Typography>
            )}
          />
          <FormControlLabel
            control={<Checkbox
              defaultChecked={productData?.adult_k ?? false}
              onChange={(e) => setCustomData({ ...customData, adult_k: e.target.checked })}
            />}
            label={(
              <Typography color={"text.secondary"}>
                <FormattedMessage id={'is_bundle'}/>
              </Typography>
            )}
          />
        </Box>
      </Grid2>
    </>
  )
}
