import useCatalogErrors from "../../../../../app/hooks/useCatalogErrors";
import { CompanyStatus, getChannelStatusDisplay } from "../../../../../app/utils/productStatusUtils";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { CompanyLogo } from "../../../../App/Blocks/Misc";
import React from "react";
import { ChannelsIds } from "../../../../../app/types/feed";
import google_shopping_icon from "../../../../../assets/img/icons/google-shopping.svg";
import { useAppSelector } from "../../../../../app/hooks";
import { getProduct } from "../../../../../app/slices/product";

export const CardStatuses = () => {
  const product = useAppSelector(getProduct);
  const [ { allStatuses } ] = useCatalogErrors(product);
  return (
    <>
      <Divider/>
      <Box sx={{ display: 'flex', alignItems: "center", flexDirection: "column", gap: 2, px: 2 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          {allStatuses.map(companyStatus => (
            <ProductStatus
              key={`company-status-${companyStatus.source_id}`}
              channelStatus={companyStatus}
            />
          ))}
        </Stack>
      </Box>
    </>
  )
}

interface ProductStatusProps {
  channelStatus: CompanyStatus
}

const ProductStatus = ({ channelStatus }: ProductStatusProps) => {

  const {
    isChannelInactive, channelName, display
  } = getChannelStatusDisplay(channelStatus);

  return (
    <Box sx={{
      display: "flex", justifyContent: "space-between", alignItems: "center",
      filter: isChannelInactive ? "grayscale(1)" : undefined,
    }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {channelStatus.source_id !== ChannelsIds.FreeListing ? (
          <CompanyLogo company={channelStatus.company} size={"icon"}/>) : (
          <img src={google_shopping_icon} width={"15px"}/>
        )}
        <Typography color={`text.${isChannelInactive ? "disabled" : "primary"}`}>
          {channelName}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 0.5 }}>
        {display.map(d => (
          <Box key={`status-${d?.message}`} sx={{ display: "flex", gap: 0.5 }}>
            <Typography
              color={!isChannelInactive ? `${d?.color}.main` : 'text.disabled'}
              variant={"caption"}
            >
              {d?.message}
            </Typography>
            {d?.icon}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
