import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../app/slices/modal";
import { Close } from "@mui/icons-material";
import profileApi from "../../../app/services/secured/profile";
import { GetCurrentFeedId } from "../../../app/utils";
import { FormattedMessage } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import { getCurrentFeed } from "../../../app/slices/app";
import { FeedcastPlan } from "../../Global/Settings/Subscription/constants";
import { Infinity } from "lucide-react";
import Urls from "../../../assets/js/Urls";
import { Link } from "react-router-dom";
import { AiHero } from "../../Pages/Dashboard/Dashboard";

export const AI_CREDITS_PER_PLAN: Record<FeedcastPlan, number> = {
  "free": 50,
  "starter": 500,
  "premium": 5000,
}

const DialogAI = () => {

  const feed = GetCurrentFeedId();
  const currFeed = useAppSelector(getCurrentFeed);
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.FeedcastAI))
  const onClose = () => dispatch(closeModal());

  const [ getCredits, creditsQuery ] = profileApi.useLazyGetCreditsQuery();

  const plan: FeedcastPlan = currFeed?.current_subscription?.plan?.plan ?? "free";
  const maxCredits: number = AI_CREDITS_PER_PLAN[plan];
  const nbCredits = creditsQuery.data?.limit !== null ? (creditsQuery.data?.limit ?? 0) : null;
  const subLink: string = Urls.settings.subEdit(feed);

  useEffect(() => {
    if (feed > 0) {
      getCredits({
        feed
      }, true);
    }
  }, [ feed ]);

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box/>
          <Box>
            <IconButton onClick={onClose}>
              <Close/>
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <Stack spacing={2}>
            <AiHero hideable={false} />
            <Box>
              <Typography variant={"subtitle1"}>
                <FormattedMessage id={'your-plan'}/>
              </Typography>
              <Typography variant={"h4"}>
                <FormattedMessage id={plan}/>
              </Typography>
            </Box>
            <Divider/>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {
                  nbCredits !== null ? (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Typography variant={"h4"} fontWeight={500}>
                          {nbCredits}
                        </Typography>
                        <Typography variant={"h4"}>
                          /
                        </Typography>
                      </Box>
                      <Typography variant={"h4"} fontWeight={500}>
                        {maxCredits}
                      </Typography>
                    </>
                  ) : (
                    <Infinity />
                  )
                }

                <Box>
                  <Typography lineHeight={1}>
                    <FormattedMessage id={'ai-credits'}/>
                  </Typography>
                  <Typography variant={"body2"} color={"text.secondary"}>
                    <FormattedMessage id={'monthly-recurring'}/>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          {
            nbCredits !== null && (
              <Button
                size={"large"}
                variant={"contained"}
                endIcon={<Infinity/>}
                component={Link}
                to={subLink}
                onClick={onClose}
                sx={{
                  background: (theme) => theme.palette.gradient.secondary,
                }}
              >
                <FormattedMessage id={'upgrade-to-unlimited'}/>
              </Button>
            )
          }

        </DialogActions>
      </DialogTitle>
    </Dialog>
  )
}

export default DialogAI;
