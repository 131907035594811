import { GetCurrentFeedId } from "../../../../../app/utils";
import campaignApi from "../../../../../app/services/secured/campaign";
import { Autocomplete, Box, debounce, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setMetaBehaviours,
  setMetaInterests
} from "../../../../../app/slices/campaign/campaign";
import { getMetaBehaviours, getMetaInterests } from "../../../../../app/slices/campaign/getters";
import { Behaviour, Interest } from "../../../../../app/types/campaign";
import Grid2 from "@mui/material/Unstable_Grid2";

/**
 * MetaClassicTargets.tsx
 * Targets behaviours and interests, fetched from API
 */

export const MetaClassicTargets = () => {
  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const [ qInterests, setQInterests ] = useState("");

  const behavioursQuery = campaignApi.useGetMetaBehavioursQuery(feed);
  const behavioursData = behavioursQuery.data ?? [];
  const interestsQuery = campaignApi.useGetMetaInterestsQuery({ feed, q: qInterests });
  const interestsData = interestsQuery.data ?? [];

  const behaviours = useAppSelector(getMetaBehaviours);
  const interests = useAppSelector(getMetaInterests);

  const makeOptions = (options: Behaviour[]) => options?.map(o => {
    return { label: o.name, value: o.id }
  }) ?? [];

  const handleBehaviours = (values: Behaviour[]) => dispatch(setMetaBehaviours(values));
  const handleInterests = (values: Interest[]) => dispatch(setMetaInterests(values));

  const searchInterests = debounce((value: string) => setQInterests(value), 300);

  return (
    <Stack spacing={1}>
      <Typography variant={"subtitle2"}>
        Cibles
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Autocomplete
            size={"small"}
            multiple
            options={makeOptions(behavioursData)}
            value={makeOptions(behaviours)}
            onChange={(r, values) => handleBehaviours(values.map(v => {
              return { id: v.value, name: v.label }
            }))}
            renderInput={(params) =>
              <TextField {...params} label={<FormattedMessage id={"behaviours"}/>}/>}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Autocomplete
            size={"small"}
            multiple
            options={makeOptions(interestsData)}
            value={makeOptions(interests)}
            onChange={(r, values) => handleInterests(values.map(v => {
              return { id: v.value, name: v.label }
            }))}
            renderInput={(p) => {
              const { inputProps, ...params } = p;
              if (!inputProps.value && qInterests.length) {
                inputProps.value = qInterests;
              }
              return <TextField
                value={params.InputProps.ref}
                onChange={(e) => searchInterests(e.target.value)}
                inputProps={inputProps}
                {...params}
                placeholder={"Search for an interest..."}
                label={<FormattedMessage id={"interests"}/>}
                onKeyDown={(event: any) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />
            }}
            filterOptions={(x) => x}
          />
        </Grid2>
      </Grid2>
    </Stack>
  )
}
