import { Box, Checkbox, FormControlLabel, Link, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

export const CGV = () => {
  return (
    <FormControlLabel
      sx={{
        width: '100%'
      }}
      control={<Checkbox required/>}
      label={(
        <Box sx={{ display: "flex", gap: 0.1, alignItems: "start", flexDirection:"column" }}>
          <Typography variant={"caption"} lineHeight={1}>
            <FormattedMessage id={"accept-the"}/>
          </Typography>
          <Typography component={Link} variant={"caption"} target={'_blank'} href={"/files/feedcast-cga.pdf"}>
            <FormattedMessage id={"sales-condition"}/>
          </Typography>
        </Box>
      )}
    />
  )
}
