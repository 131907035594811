
//==================
// Enums
//==================

export type FeedcastPlan = "free" | "starter" | "premium";

export enum Packs {
  Autopilot = "starter",
  Premium = "premium"
}

export enum MinProducts {
  Autopilot = 1000,
  Premium = 10000,
}

//====================
// Types & Interfaces
//====================

export interface SelectedPack {
  selectedPack: Packs | null,
}
