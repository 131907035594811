import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Information } from "./Information";
import { Detail } from "./Detail";
import { Misc } from "./Misc";
import { useAppSelector } from "../../../../../app/hooks";
import {
  getProductCustomData,
  getProductData,
  getProductDataSource,
  getProductView
} from "../../../../../app/slices/product";
import { ProductData } from "../../../../../app/types/products";

export type WeightUnit = "kg" | "g" | "lb" | "oz";

interface ProductAttributesProps {
  customData: Partial<ProductData>;
  productData: Partial<ProductData>;
}

export interface ProductAttributesGroupProps extends Pick<ProductAttributesProps, "productData" | "customData"> {
  viewPage: boolean;
  disableInputs: boolean;
}

export const ProductAttributes = () => {

  const disableInputs = useAppSelector(getProductDataSource) === "origin";
  const view = useAppSelector(getProductView);
  const viewPage = view === "page";

  const productData = useAppSelector(getProductData);
  const customData = useAppSelector(getProductCustomData);

  const props = {
    productData,
    customData,
    viewPage,
    disableInputs
  }

  return (
    <React.Fragment>
      <Grid2 container spacing={2} xs={12}>
        <Information {...props}/>
        <Detail {...props}/>
        <Misc {...props}/>
      </Grid2>
    </React.Fragment>
  )
}

