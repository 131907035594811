import { Box, Divider, Link, TextField, Typography } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import authApi from '../../app/services/public/auth';
import { StorageManager } from '../../app/storage';
import Urls from '../../assets/js/Urls';
import { LoadingButton } from '../../components/App/Blocks/Misc';
import SimpleForm, { SimpleFormData } from '../../components/App/Blocks/SimpleForm';
import AuthWrapper from './AuthWrapper';
import { containerAnimation, fieldAnimation } from './utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface LoginFormData {
   email: string;
   password: string;
}

interface FormErrors {
   [key: string]: string | React.ReactNode | null;
}

interface InputFieldConfig {
   label: string | React.ReactNode;
   delay: number;
   type: string;
   name: keyof LoginFormData;
}

const inputFields: InputFieldConfig[] = [
   { label: <FormattedMessage id="email" />, type: "text", delay: 0.3, name: "email" },
   { label: <FormattedMessage id="password" />, type: "password", delay: 0.4, name: "password" },
];

const FormField = ({ field, error }: { field: InputFieldConfig, error?: string | React.ReactNode | null }) => (
   <motion.div
      {...fieldAnimation}
      transition={{ ...fieldAnimation.transition, delay: field.delay }}
   >
      <TextField
         fullWidth
         label={field.label}
         type={field.type}
         name={field.name}
         variant="outlined"
         margin="dense"
         size="small"
         error={!!error}
         helperText={error}
      />
   </motion.div>
);

const Login = () => {

   const [login, loginRes] = authApi.useLoginMutation();
   const [loginWithGoogle, loginWithGoogleRes] = authApi.useLoginWithGoogleMutation();
   const [errors, setErrors] = useState<FormErrors>({});
   // const { executeRecaptcha } = useGoogleReCaptcha();

   const validateForm = (data: SimpleFormData): boolean => {
      const newErrors: FormErrors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!data.email || !emailRegex.test(data.email)) {
         newErrors.email = <FormattedMessage id="validEmail" />;
      }
      if (!data.password?.trim()) {
         newErrors.password = <FormattedMessage id="passwordRequired" />;
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
   };

   // Event Handlers
   const handleSubmit = async (data: SimpleFormData) => {
      if (validateForm(data)) {
         login(data);
      }
   };

   const handleGoogleSuccess = async (credentialResponse: CredentialResponse) => {
      loginWithGoogle({ google_token: credentialResponse.credential });
   };

   const handleGoogleError = () => {
      console.error("Google OAuth Error");
   };

   useEffect(() => {
      if (loginRes.isSuccess) {
         StorageManager.set(loginRes.data.access_token);
         window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.roundabout;
      }
      if (loginWithGoogleRes.isSuccess) {
         StorageManager.set(loginWithGoogleRes.data.access_token);
         window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.roundabout;
      }
   }, [loginRes, loginWithGoogleRes]);

   return (
      <AuthWrapper>
         <motion.div {...containerAnimation}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
               <motion.div {...fieldAnimation} transition={{ ...fieldAnimation.transition, delay: 0.1 }}>
                  <Typography variant="h4">
                     <FormattedMessage id="login" />
                  </Typography>
               </motion.div>
               <motion.div {...fieldAnimation} transition={{ ...fieldAnimation.transition, delay: 0.2 }}>
                  <GoogleLogin
                     size="large"
                     width="100%"
                     onSuccess={handleGoogleSuccess}
                     onError={handleGoogleError}
                     useOneTap={true}
                  />
               </motion.div>
               <motion.div {...fieldAnimation} transition={{ ...fieldAnimation.transition, delay: 0.3 }}
                  style={{ width: "100%", maxWidth: 100 }}
               >
                  <Divider>
                     <FormattedMessage id="or" />
                  </Divider>
               </motion.div>
               <Box sx={{ width: "100%", maxWidth: 250 }}>
                  <SimpleForm onSubmit={handleSubmit}>
                     <motion.div {...containerAnimation}>
                        <Box sx={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           gap: 1,
                        }}>
                           <Box sx={{ width: "100%" }}>
                              {inputFields.map((field, index) => (
                                 <FormField
                                    key={index}
                                    field={field}
                                    error={errors[field.name]}
                                 />
                              ))}
                           </Box>
                           <motion.div
                              {...fieldAnimation}
                              transition={{ ...fieldAnimation.transition, delay: 0.4 }}
                              style={{ width: "100%" }}
                           >
                              <LoadingButton
                                 fullWidth
                                 variant="outlined"
                                 color="primary"
                                 type="submit"
                                 data={loginRes}
                              >
                                 <FormattedMessage id="login" />
                              </LoadingButton>
                           </motion.div>
                        </Box>

                     </motion.div>

                  </SimpleForm>
               </Box>
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                  <motion.div
                     {...fieldAnimation}
                     transition={{ ...fieldAnimation.transition, delay: 0.6 }}
                  >
                     <Typography variant="body2" color="text.secondary">
                        <FormattedMessage id="refresh" />{' '}
                        <Link component={RouterLink} to={Urls.auth.refresh}>
                           <FormattedMessage id="refresh-password" />
                        </Link>
                     </Typography>
                  </motion.div>
                  <motion.div
                     {...fieldAnimation}
                     transition={{ ...fieldAnimation.transition, delay: 0.65 }}
                  >
                     <Typography variant="body2" color="text.secondary">
                        <FormattedMessage id="new-here" />{' '}
                        <Link component={RouterLink} to={Urls.auth.register}>
                           <FormattedMessage id="register" />
                        </Link>
                     </Typography>
                  </motion.div>
               </Box>
            </Box>
         </motion.div>
      </AuthWrapper>
   );
};

export default Login;
