import React from "react";
import DatePicker from "../../App/Blocks/Datepicker";
import { Box, Chip, Stack } from "@mui/material";
import { Google } from "@mui/icons-material";
import AppSecured from "../../App/AppSecured/AppSecured";
import { ReportingTable } from "./ReportingTable";
import { GlobalFilters } from "./Filters/GlobalFilters";
import PageInfo from "../../App/Helpdesk/PageInfo";
import FCard from "../../App/Blocks/Card";

const _ProductsReporting = () => {
  return (
    <AppSecured>
      <Stack spacing={2}>
        <PageInfo page={'product-reporting'} />
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <DatePicker margin={false}/>
          <Chip variant={"outlined"} icon={<Google/>} label={"Source : Google"}/>
        </Box>
        <GlobalFilters/>
        <ReportingTable/>
      </Stack>
    </AppSecured>
  )
}

export default _ProductsReporting;
