import { GridColDef } from "@mui/x-data-grid";
import {
  Alert,
  Badge,
  Box, Chip, Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip, Typography,
  useTheme
} from "@mui/material";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Article, Delete, Edit, EditNote, MoreVert, WarningAmberRounded, WarningRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Urls from "../../../../../assets/js/Urls";
import React, { useEffect, useState } from "react";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { adsCampaigns } from "../../../../../app/slices/campaign/constants";
import { AdsProvider, CampaignType } from "../../../../../app/types/campaign";
import campaignApi from "../../../../../app/services/secured/campaign";
import { useAppDispatch } from "../../../../../app/hooks";
import { addNotification } from "../../../../../app/slices/notifications";
import { modalShowDeleteCampaign } from "../../../../../app/slices/modal";
import { ConfirmEnableDialogProps } from "../hooks/useCampaignMutation";
import { RowFocus } from "../CampaignList";
import { GetCampaignDraftNodeId } from "../../CampaignDetail/hooks/useCampaignDraft";

type CampaignColumnsProps = {
  handleEnableCampaign: (params: ConfirmEnableDialogProps) => void,
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const CampaignColumns = (p: CampaignColumnsProps) => {

  const intl = useIntl();
  const feed = GetCurrentFeedId();

  const [ rowFocus, setRowFocus ] = useState<RowFocus | null>(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>, provider: AdsProvider, campaign: string, is_feedcast: boolean) => {
    p.handleClick(event);
    setRowFocus({ provider, campaign, is_feedcast });
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "",
      flex: 4,
      renderCell: ({ row }) => {
        const hasDraft = window.localStorage.getItem(GetCampaignDraftNodeId({ feed, campaignId: row.id }));
        return (
          <Box sx={{display:"flex", gap: 2, alignItems:"center"}}>
            <Box>
              <CampaignImage
                src={adsCampaigns[row.type as CampaignType ?? 0].logo}
                show={true}
                restrictions={row.restrictions ?? []}
              />
            </Box>
            <Box sx={{display:"flex", flexDirection:"column", gap: 0.5}}>
              <Box>
                {row.name}
              </Box>
              <Box sx={{display:"flex", gap: 1, alignItems:"center"}}>
                {hasDraft && (
                  <Tooltip placement={"bottom"} arrow title={(
                    <Typography variant={"body2"}>
                      <FormattedMessage id={"unpublished-draft-warning"}/>
                    </Typography>
                  )}>
                    <Chip
                      size={"small"}
                      icon={<Article/>}
                      label={<FormattedMessage id={'draft'} />}
                      color={"warning"}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        )
      }
    },
    {
      field: "budget",
      headerName: intl.formatMessage({ id: "budget" }),
      flex: 1,
      editable: true,
      type: "number",
      renderCell: (params) => CampaignType.META_SHOPPING_ADVANTAGE !== params.row.type
        ? <FormattedNumber value={params.value} style={"currency"} currency={"eur"}/>
        : null
    },
    {
      field: "impressions",
      headerName: intl.formatMessage({ id: "impressions" }),
      flex: 1,
      type: "number",
    },
    {
      field: "clicks",
      headerName: intl.formatMessage({ id: "clicks" }),
      flex: 1,
      type: "number",
    },
    {
      field: "cpc",
      headerName: "CPC",
      flex: 1,
      type: "number",
      renderCell: (params) => <FormattedNumber value={params.value} style={"currency"} currency={"eur"}/>
    },
    {
      field: "clickRate",
      headerName: intl.formatMessage({ id: "clickRate" }),
      flex: 1,
      type: "number",
      renderCell: (params) => <FormattedNumber value={params.value} style={"percent"} minimumFractionDigits={2}/>
    },
    {
      field: "conversions",
      headerName: intl.formatMessage({ id: "conversions" }),
      flex: 1,
      type: "number",
    },
    {
      field: "spent",
      headerName: intl.formatMessage({ id: "spent" }),
      flex: 1,
      type: "number",
      renderCell: (params) => <FormattedNumber value={params.value} style={"currency"} currency={"eur"}/>
    },
    {
      field: "conversionValue",
      headerName: intl.formatMessage({ id: "conversionValue" }),
      flex: 1,
      type: "number",
      renderCell: (params) => <FormattedNumber value={params.value} style={"currency"} currency={"eur"}/>
    },
    {
      field: "conversionCost",
      headerName: intl.formatMessage({ id: "conversionCost" }),
      flex: 1,
      type: "number",
      renderCell: (params) => <FormattedNumber value={params.value} style={"currency"} currency={"eur"}/>
    },
    {
      field: "roi",
      headerName: "ROI",
      flex: 1,
      type: "number",
    },
    {
      field: "actions",
      flex: 2,
      headerName: "",
      renderCell: (params) => {
        return (
          <Box display={"flex"} justifyContent={"end"} width={'100%'}>
            <Switch
              onClick={() => p.handleEnableCampaign({
                id: params.value.id,
                active: params.value.enabled,
                show: true,
                provider: params.value.provider,
              })}
              checked={params.value.enabled}
              // color={params.value.enabled ? "secondary" : undefined}
            />
            <IconButton
              onClick={(e) => onMenuClick(e, params.value.provider, params.value.id, params.value.is_feedcast)}>
              <MoreVert/>
            </IconButton>
          </Box>
        )
      }
    }
  ]

  return {
    columns,
    rowFocus
  };
}

interface CampaignImageProps {
  src: string;
  show: boolean;
  restrictions: string[];
}

const CampaignImage = (p: CampaignImageProps) => {
  return (
    <Badge
      color={"warning"}
      badgeContent={p.restrictions?.length ? <CampaignWarnings restrictions={p.restrictions}/> : undefined}
    >
      <img
        src={p.src}
        style={{
          maxWidth: '30px'
        }}
      />
    </Badge>
  )
}

const CampaignWarnings = ({ restrictions }: { restrictions: string[] }) => {
  const theme = useTheme();
  return (
    <Tooltip
      placement={"top"}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[2],
          }
        }
      }}
      title={
        <Box>
          <List disablePadding>
            <Box component={Alert} severity={"warning"} sx={{ borderRadius: 0 }}>
              <Typography sx={{ m: 0 }}>
                <FormattedMessage id={'warnings'}/>
              </Typography>
            </Box>
            {restrictions.map((r, i) => (
              <React.Fragment>
                <ListItem
                  key={`restriction-${i}`}

                >
                  <ListItemText>
                    <FormattedMessage id={r}/>
                  </ListItemText>
                </ListItem>
                {(i < restrictions?.length - 1) && (
                  <Divider/>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      }
    >
      <WarningRounded sx={{ fontSize: "0.8rem" }}/>
    </Tooltip>
  )
}

export default CampaignColumns;
