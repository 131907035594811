import React from "react";
import { useAppDispatch } from "../../../app/hooks";

import cart from '../../../assets/img/icons/cart.svg';
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { HelpLink } from "../../App/Helpdesk/HelpLink";

const NoProductsList = () => {
  const hasParams = true;
  const dispatch = useAppDispatch();
  return <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
    style={{
      height: '40vh',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundSize: "100%",
      textAlign: "center",
    }}
  >
    <img src={cart}/>
    {!hasParams
      ? <Box>
        <Typography variant={"h5"}><FormattedMessage id={"no-product-data"}/></Typography>
        <HelpLink title={"how-to-connect-feed"} help={"connect-feed"}/>
      </Box>
      : <Box>
        <Typography variant={"h5"}>
          <FormattedMessage id={"no-product-data"}/>
        </Typography>
        <span><FormattedMessage id={"edit-filters"}/></span>
      </Box>}
  </Box>
}

export default React.memo(NoProductsList);
