import { Box, Button, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import Urls from '../../../assets/js/Urls';
import { containerAnimation, fieldAnimation } from '../utils';
import CountryLanguageSelector from './components/CountryLanguageSelector';
import DomainDisplay from './components/DomainDisplay';
import FeedPreview from './components/FeedPreview';
import SignupForm from './components/SignupForm';
import { useUrlOrCookieParams } from './useUrlOrCookieParams';

interface RegistrationFormProps {
   formValues: any;
   setFormValues: React.Dispatch<React.SetStateAction<any>>;
   errors: any;
   onGoogleOAuth: (response: any) => void;
   onOAuthError: () => void;
   registerRes: any;
}

const RegisterWrapper: React.FC<RegistrationFormProps> = ({
   formValues,
   setFormValues,
   errors,
   onGoogleOAuth,
   onOAuthError,
   registerRes
}) => {

   const feed_url = useUrlOrCookieParams().feed_url;
   const [step, setStep] = useState(1);

   const handleChange = (name: string, value: any) => {
      setFormValues((prev: any) => ({ ...prev, [name]: value }));
   };

   const isValidDomain = (domain: string) => {
      const domainPattern = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/;
      return domainPattern.test(domain);
   };

   const handleNextStep = () => {
      if (isValidDomain(formValues['feed[domain]'])) {
         setStep(2);
      }
   };

   const handleDomainChange = (value: string) => {
      const cleanedValue = value.replace(/^(https?:\/\/)?(www\.)?/, '').split(/[/?#]/)[0];
      handleChange('feed[domain]', cleanedValue);
   };

   const handleKeyDown = (event: React.KeyboardEvent) => {
      if (step === 1 && event.key === 'Enter') {
         handleNextStep();
      }
   };

   return (
      <motion.div {...containerAnimation}>
         <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            onKeyDown={handleKeyDown}
         >
            {step === 1 ? (
               <>
                  <motion.div
                     {...fieldAnimation}
                     transition={{ ...fieldAnimation.transition, delay: 0.2 }}
                  >
                     <TextField
                        fullWidth
                        label={<FormattedMessage id="domain" />}
                        name="feed[domain]"
                        value={formValues['feed[domain]'] || ''}
                        onChange={(e) => handleDomainChange(e.target.value)}

                        error={!!errors['feed[domain]']}
                        helperText={errors['feed[domain]']}
                        required
                        InputProps={{
                           startAdornment: <InputAdornment position="start">
                              https://
                           </InputAdornment>
                        }}
                     />
                  </motion.div>

                  <CountryLanguageSelector
                     formValues={formValues}
                     handleChange={handleChange}
                     errors={errors}
                  />

                  {feed_url && (
                     <FeedPreview
                        formValues={formValues}
                        handleChange={handleChange}
                        errors={errors}
                        feedUrl={feed_url}
                     />
                  )}

                  <motion.div
                     {...fieldAnimation}
                     transition={{ ...fieldAnimation.transition, delay: 0.6 }}
                  >
                     <Button
                        variant="contained"
                        onClick={handleNextStep}
                        disabled={!isValidDomain(formValues['feed[domain]'])}
                        fullWidth
                     >
                        <FormattedMessage id="continue" />
                     </Button>
                  </motion.div>
                  <ReturnToLogin />
               </>
            ) : (
               <>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                     <DomainDisplay
                        domain={formValues['feed[domain]']}
                        onBack={() => setStep(1)}
                     />
                     <motion.div
                        {...fieldAnimation}
                        transition={{ ...fieldAnimation.transition, delay: 0.1 }}
                     >
                        <Typography color="text.secondary" textAlign="center">
                           <FormattedMessage id="register-last-step" />
                        </Typography>
                     </motion.div>

                     <motion.div
                        {...fieldAnimation}
                        transition={{ ...fieldAnimation.transition, delay: 0.2 }}
                     >
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                           <GoogleLogin
                              size="large"
                              width="100%"
                              onSuccess={onGoogleOAuth}
                              onError={onOAuthError}
                              useOneTap={true}
                           />
                        </Box>
                     </motion.div>

                     <motion.div
                        {...fieldAnimation}
                        transition={{ ...fieldAnimation.transition, delay: 0.3 }}
                     >
                        <Typography color="text.secondary" textAlign="center">
                           <FormattedMessage id="or-provide-email" />
                        </Typography>
                     </motion.div>

                     <SignupForm
                        formValues={formValues}
                        handleChange={handleChange}
                        errors={errors}
                        registerRes={registerRes}
                     />
                  </Box>
                  <ReturnToLogin />
               </>
            )}
         </Box>
      </motion.div>

   );
};

const ReturnToLogin = () => {
   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
         <motion.div
            {...fieldAnimation}
            transition={{ ...fieldAnimation.transition, delay: 0.8 }}
         >
            <Typography variant="body2" color="text.secondary">
               <FormattedMessage id="already-have-account" />{' '}
               <Link component={RouterLink} to={Urls.auth.login}>
                  <FormattedMessage id="login" />
               </Link>
            </Typography>
         </motion.div>
      </Box>
   );
};

export default RegisterWrapper;
