import campaignApi from "../../../../../app/services/secured/campaign";
import { GetCurrentFeedId } from "../../../../../app/utils";
import FCard, { FCardHeader } from "../../../../App/Blocks/Card";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCurrentFeed } from "../../../../../app/slices/app";
import { FormattedMessage } from "react-intl";
import { setCreative, setMetaDsa } from "../../../../../app/slices/campaign/campaign";
import React, { useEffect } from "react";
import { Facebook, Storefront } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { getAdset, getMetaCreative } from "../../../../../app/slices/campaign/getters";

/**
 * Creative.tsx
 * Shop's URL & Facebook page linked to the campaign
 */

const Creative = () => {
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const currFeed = useAppSelector(getCurrentFeed);
  const creative = useAppSelector(getMetaCreative);
  const adset = useAppSelector(getAdset);
  const pages = campaignApi.useGetMetaPagesQuery({ feed });

  useEffect(() => {
    currFeed?.name && dispatch(setCreative({ ...creative, link: currFeed?.name }));
  }, [ currFeed ]);

  return (
    <Stack spacing={2}>
      <Typography variant={"subtitle2"}>
        Creative Configuration
      </Typography>

      <Grid2 container spacing={2} padding={0}>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={<FormattedMessage id={"website-url"}/>}
              onChange={(e) => dispatch(setCreative({ ...creative, link: e.target.value }))}
              value={creative.link}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}>
                  <Storefront/>
                </InputAdornment>
              }}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              required
              size={"small"}
              label={<FormattedMessage id={"facebook-page"}/>}
              value={creative.page}
              onChange={(e) => dispatch(setCreative({ ...creative, page: e.target.value }))}
              inputProps={{ "aria-required": true }}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}>
                  <Facebook/>
                </InputAdornment>
              }}
              select
            >
              {pages.data?.map(p => <MenuItem value={p.id}>{p.name}</MenuItem>)}
            </TextField>
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={<FormattedMessage id={"dsa-beneficiary"}/>}
              onChange={(e) => dispatch(setMetaDsa({ dsa: "beneficiary", value: e.target.value }))}
              value={adset.dsa_beneficiary}
              helperText={<FormattedMessage id={'meta-adv-benef'} />}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={<FormattedMessage id={"dsa-payor"}/>}
              onChange={(e) => dispatch(setMetaDsa({ dsa: "payor", value: e.target.value }))}
              value={adset.dsa_payor}
              helperText={<FormattedMessage id={'meta-adv-payor'} />}
            />
          </FormControl>
        </Grid2>
      </Grid2>

      {/*<Box sx={{ display: "flex", gap: 2 }}>*/}
      {/*  <Box sx={{ flexGrow: 1 }}>*/}
      {/*    */}
      {/*  </Box>*/}
      {/*  <Box sx={{ flexGrow: 1 }}>*/}
      {/*    */}
      {/*  </Box>*/}
      {/*</Box>*/}

      {/*<Box sx={{ display: "flex", gap: 2 }}>*/}
      {/*  <Box sx={{ flexGrow: 1 }}>*/}
      {/*    */}
      {/*  </Box>*/}
      {/*  <Box sx={{ flexGrow: 1 }}>*/}
      {/*    */}
      {/*  </Box>*/}
      {/*</Box>*/}
    </Stack>
  )
}

export default Creative;
