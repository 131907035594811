import { GetCurrentFeedId } from "../../../../app/utils";
import { useAppSelector } from "../../../../app/hooks";
import { getShop } from "../../../../app/slices/shop";
import { getCurrentFeed, getCurrentFeedSubStatus } from "../../../../app/slices/app";
import { BigDataCard } from "./BigDataCard";
import Urls from "../../../../assets/js/Urls";
import { Apps } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Link, Typography } from "@mui/material";
import { Link as LinkDOM } from "react-router-dom";

export const SubStatusCard = () => {
  const feed = GetCurrentFeedId();
  const shop = useAppSelector(getShop);
  const currFeed = useAppSelector(getCurrentFeed);
  const subStatus = useAppSelector(getCurrentFeedSubStatus);
  const subPlan = shop.subscription;

  const showPlan = subStatus === "canceled" || (subPlan && subPlan !== "free");

  return (
    <BigDataCard
      icon={<Apps fontSize={"large"}/>}
      primary={
        <React.Fragment>
          <FormattedMessage id={currFeed?.current_subscription?.plan.plan ?? "free"}/>
        </React.Fragment>
      }
      secondary={
        <Button size={"small"} variant={"contained"} component={LinkDOM} to={Urls.settings.subEdit(feed)}>
          <FormattedMessage id={'choose-plan'}/>
        </Button>
      }
    />
  )
}
