import { FormattedMessage } from "react-intl";
import React from "react";
import { Box, FormControlLabel, Radio, RadioGroup, styled, Typography } from "@mui/material";
import { AutoAwesome, EditLocationAlt, FilterList, FlagCircle, Public, SpeedSharp } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";

type SelectorProps = {
  all: boolean,
  setAll: (v: boolean) => void,
  type: "products" | "location",
}

const Selector = ({ all, setAll, type }: SelectorProps) => {
  return (
    <RadioGroup
      onChange={(e, value) => setAll(value !== "specific")}
      value={all ? "all" : "specific"}
    >
      <Grid2 container spacing={2}>
        <Grid2 xs>
          <StyledFormControlLabel
            value={"all"}
            className={`${all && "active"}`}
            control={<Radio/>}
            label={<Box display={"flex"} alignItems={"center"}>
              {type === "products" && <AutoAwesome/>}
              {type === "location" && <Public/>}
              <Box ml={1} lineHeight={1}>
                <Typography variant={'subtitle1'} m={0} lineHeight={1} sx={{ whiteSpace: "nowrap" }}>
                  <FormattedMessage id={type === "products" ? "all-campaign-products" : "all-locations"}/>
                </Typography>
              </Box>
            </Box>}
          />
        </Grid2>
        <Grid2 xs>
          <StyledFormControlLabel
            value={"specific"}
            className={`${!all && "active"}`}
            control={<Radio/>}
            label={<Box display={"flex"} alignItems={"center"}>
              {type === "products"
                ? <FilterList/>
                : <EditLocationAlt/>}
              <Box ml={1} lineHeight={1}>
                <Typography variant={'subtitle1'} m={0} lineHeight={1} sx={{ whiteSpace: "nowrap" }}>
                  <FormattedMessage id={type === "products" ? "specific-products" : "specific-locations"}/>
                </Typography>
              </Box>
            </Box>}
          />
        </Grid2>
      </Grid2>
    </RadioGroup>
  )
}

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&': {
    width: '100%',
    minWidth: '200px',
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    margin: 0,
    borderRadius: 12,
    padding: 2,
    paddingLeft: 15,
    height: '100%',
    "&.active": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "#fff",
    },
    "&:not(.active)": {
      border: "1px solid " + theme.palette.grey.A400
    },
    '& .Mui-checked': {
      color: `#fff !important`
    }
  },
}));

export default Selector;
