import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import React, { useEffect, useState } from "react";
import { getUser } from "../../../../../app/slices/app";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { FormattedMessage, useIntl } from "react-intl";
import campaignApi from "../../../../../app/services/secured/campaign";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import SimpleForm, { SimpleFormData } from "../../../../App/Blocks/SimpleForm";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import { LinkCompanyProps } from "./ModalLinkAccount";
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { Email, Google } from "@mui/icons-material";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import { CreateAdsAccountData } from "../../../../../app/types/campaign";
import { HelpLink } from "../../../../App/Helpdesk/HelpLink";

export const CreateAdsAccount = (props: Pick<LinkCompanyProps, 'company' | 'onSuccess' | 'onBack'>) => {
  const dispatch = useAppDispatch(),
    user = useAppSelector(getUser),
    feed = GetCurrentFeedId(),
    intl = useIntl()
  ;

  const [ createAcc, createAccRes ] = campaignApi.useCreateAdsAccountMutation();
  useEffect(() => {
    if (createAccRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "success-ads-account-create" })));
      props.onSuccess();
    }
  }, [ createAccRes.isSuccess ]);

  const [ currency, setCurrency ] = useState<"EUR" | "USD" | "GBP">("EUR");

  const submit = (s: SimpleFormData) => {
    const data = s as CreateAdsAccountData;
    createAcc({
      feed,
      provider: adsCompanies[props.company].provider,
      data,
    })
  }

  return <Box>
    <Alert variant={"outlined"} severity={"info"} sx={{my: 2}}>
      <AlertTitle><FormattedMessage id={"get-most-of-fc"} /></AlertTitle>
      <FormattedMessage id={"create-account-advantages"} />
      <br />
      <FormattedMessage id={"create-account-advantages-2"} />
      <HelpLink title={"helpdesk-article"} help={"google-advantages"} />
    </Alert>
    <SimpleForm onSubmit={submit}>
      <FormControl
        margin={"dense"}
        fullWidth>
        <TextField
          name={"email"}
          type={"email"}
          defaultValue={user?.email}
          required
          label={"Email"}
          InputProps={{
            startAdornment: <InputAdornment position={"start"}>
              <Email />
            </InputAdornment>
          }}
        />
      </FormControl>
      <FormControl
        margin={"normal"}
        fullWidth
      >
        <TextField
          name={"name"}
          required
          InputProps={{
            startAdornment: <InputAdornment position={"start"}>
              <Google />
            </InputAdornment>
          }}
          label={<FormattedMessage id={"account-name"}/>}
        />
      </FormControl>
      <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1}>
        <Typography variant={"body2"}>
          <FormattedMessage id={"currency"} />
        </Typography>
        <ToggleButtonGroup
          value={currency}
          exclusive
          onChange={(event, value) => setCurrency(value as any)}
        >
          <ToggleButton value={"EUR"}>EUR</ToggleButton>
          <ToggleButton value={"USD"}>USD</ToggleButton>
          <ToggleButton value={"GBP"}>GBP</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack alignItems={"end"}>
        <LoadingButton
          data={createAccRes}
          type={'submit'}
          variant={"contained"}
        >
          <FormattedMessage id={"create-ads-account"}/>
        </LoadingButton>
      </Stack>
    </SimpleForm>
  </Box>
}

export default CreateAdsAccount;
