import AppSecured from "../../App/AppSecured/AppSecured";
import { SkeletonLoader } from "../../App/Blocks/Misc";
import StartFeedcast from "./StartFeedcast";
import React, { useEffect, useState } from "react";
import { Box, Button, Collapse, IconButton, Paper, Stack, Typography } from "@mui/material";
import ScoreCard from "./Score/ScoreCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import PerfChart2 from "../Performances/PerfChart2";
import { SubStatusCard } from "./Cards/SubStatusCard";
import { CampaignsCard } from "./Cards/CampaignsCard";
import { ProductFeedCard } from "./Cards/ProductFeedCard";
import { useAppSelector } from "../../../app/hooks";
import { useAccounts } from "../../../app/hooks/useAccounts";
import AccountsMetrics from "../Performances/AccountsMetrics";
import { ChannelsCatalog } from "../CatalogStatus/CatalogStatus";
import useProductCompetitors from "../../../app/hooks/useProductCompetitors";
import { FormattedMessage } from "react-intl";
import { AutoAwesome, AutoGraph, Close, FindInPageOutlined, MoreHoriz, OpenInNew, PieChart } from "@mui/icons-material";
import persona_coffee_2 from "../../../assets/img/personas/persona-coffee-2.svg";
import { getLanguage } from "../../../app/slices/app";
import { helpdeskLinks } from "../../App/Helpdesk/helpdesk";
import { StorageManager } from "../../../app/storage";
import { FCardHeader } from "../../App/Blocks/Card";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { GetCurrentFeedId } from "../../../app/utils";
import DatePicker from "../../App/Blocks/Datepicker";

export default () => {

  const isOnboardingComplete = useAppSelector(state => state.shop.onboarding.complete);
  const [ , { loading } ] = useAccounts();

  const { competitors, popularProducts } = useProductCompetitors();

  return (
    <AppSecured>
      <Box>
        <Grid2 container spacing={2}>
          <AiHero/>
          <Grid2 xs={12} sx={{ p: isOnboardingComplete ? 0 : undefined }}>
            <Collapse in={!isOnboardingComplete} timeout={750} unmountOnExit>
              <SkeletonLoader load={loading}>
                <StartFeedcast/>
              </SkeletonLoader>
            </Collapse>
          </Grid2>
          <Grid2 xs={4}>
            <ProductFeedCard/>
          </Grid2>
          <Grid2 xs={4}>
            <CampaignsCard/>
          </Grid2>
          <Grid2 xs={4}>
            <SubStatusCard/>
          </Grid2>
          <Grid2 xs={7}>
            <Stack spacing={2}>
              <ScoreCard/>
              <PerformanceChartSummary />
            </Stack>
          </Grid2>
          <Grid2 xs={5}>
            <Stack spacing={2}>
              <AccountsMetrics view={"lg"}/>
              <CatalogStatusSummary/>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </AppSecured>
  )
}

const PerformanceChartSummary = () => {
  return (
    <Box>
      <FCardHeader
        title={"performances"}
        icon={<AutoGraph />}
        action={(
          <DatePicker margin={false} />
        )}
      />
      <PerfChart2/>
    </Box>
  )
}

const CatalogStatusSummary = () => {
  const feed = GetCurrentFeedId();
  return (
    <Box>
      <FCardHeader
        title={"catalog-status"}
        icon={<PieChart/>}
        action={(
          <IconButton
            component={Link}
            to={Urls.products.catalog(feed)}
          >
            <MoreHoriz />
          </IconButton>
        )}
      />
      <ChannelsCatalog page={"dashboard"}/>
    </Box>
  )
}

export const AiHero = ({ hideable = true }: { hideable?: boolean }) => {
  const lang = useAppSelector(getLanguage);
  const hasSeenNews = StorageManager.getHasSeenNews();

  const [ show, setShow ] = useState<boolean | null>(null);

  useEffect(() => {
    if (!hasSeenNews || !hideable) {
      setShow(true);
    }
  }, [ hasSeenNews ]);

  const hideHero = () => {
    setShow(false);
    StorageManager.setHasSeenNews();
  }

  return (
    <Grid2 xs={12} sx={{ p: !show ? 0 : undefined }}>
      <Collapse in={show !== null ? show : false} timeout={1000} enter={hideable} unmountOnExit>
        <Paper
          sx={{
            p: 4,
            py: 6,
            background: (theme) => `${theme.palette.gradient.main}, url(${persona_coffee_2})`,
            backgroundPosition: "right 0 top 30%",
            backgroundRepeat: 'no-repeat',
            position: "relative",
          }}
        >
          {hideable && (
            <Box sx={{
              position: "absolute",
              top: "10px",
              right: '10px'
            }}>
              <IconButton
                onClick={hideHero}
                size={"large"}
              >
                <Close fontSize={"large"}/>
              </IconButton>
            </Box>
          )}
          <Stack spacing={1} sx={{ maxWidth: '50%' }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant={"h3"}>
                <FormattedMessage id={'feedcast-ai'}/>
              </Typography>
              <AutoAwesome/>
            </Box>

            <Typography>
              <FormattedMessage id={'ai-hero-desc'}/>
            </Typography>
            <Box>
              <Button
                component={'a'}
                href={helpdeskLinks["ai-products"][lang]}
                endIcon={<OpenInNew/>}
                color={"secondary"}
              >
                <FormattedMessage id={'ai-cta'}/>
              </Button>
            </Box>
          </Stack>
        </Paper>
      </Collapse>
    </Grid2>
  )
}
