import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../app/utils";
import { resetCampaign } from "../../../../app/slices/campaign/campaign";
import { setNavWasDisabled } from "../../../../app/slices/app";
import { BackButton, MUIColor } from "../../../App/Blocks/Misc";
import Urls from "../../../../assets/js/Urls";
import React, { ReactNode } from "react";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { Article, Block, CheckCircle, WarningAmberRounded } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { adsCampaigns } from "../../../../app/slices/campaign/constants";
import { getCampaign, getPerfMaxData } from "../../../../app/slices/campaign/getters";
import useCampaignDraft from "./hooks/useCampaignDraft";

interface CampaignHeaderProps {
  actions: ReactNode,
}

export const CampaignHeader = (p: CampaignHeaderProps) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const campaign = useAppSelector(getCampaign);

  const { draft, hasDraft } = useCampaignDraft();

  const perfMaxWarnings = useAppSelector(getPerfMaxData).warnings ?? [];
  const hasBlockingErrors = perfMaxWarnings.findIndex(w => w.blocking) > -1;

  const back = () => {
    dispatch(resetCampaign());
    dispatch(setNavWasDisabled(true));
  }

  return (
    <Box mb={1}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <BackButton size={'lg'} onClick={back} href={Urls.campaigns.list2(feed)}/>
          <Box ml={2}>
            <Typography variant={"h5"} m={0}>
              {campaign.id ? campaign.name : <FormattedMessage id={"new-campaign"}/>}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Box sx={{ display: "flex", gap: 1, lineHeight: 1 }}>
                <img src={adsCampaigns[campaign.type].logo} height={'20px'}/>
              </Box>
              {chipConfigurations.map((config, index) => {
                if (config.condition(campaign.id, hasDraft)) {
                  return (
                    <Tooltip key={index} placement={"top"} title={<FormattedMessage
                      id={typeof config.tooltipId === 'function' ? config.tooltipId(campaign.enabled) : config.tooltipId}/>}>
                      <Chip
                        size={"small"}
                        icon={config.chipProps.icon instanceof Function ? config.chipProps.icon(campaign.enabled) : config.chipProps.icon}
                        color={(config.chipProps.color instanceof Function ? config.chipProps.color(campaign.enabled) : config.chipProps.color) as MUIColor}
                        label={<FormattedMessage
                          id={config.chipProps.labelId instanceof Function ? config.chipProps.labelId(campaign.enabled) : config.chipProps.labelId}/>}
                      />
                    </Tooltip>
                  );
                }
                return null;
              })}

              {((perfMaxWarnings ?? []).length || 0) > 0 && (
                <Tooltip
                  title={<FormattedMessage id={'assets-warnings-desc'}/>}
                  placement={"bottom"}
                  arrow
                >
                  <Box>
                    <Chip
                      size={"small"}
                      icon={hasBlockingErrors ? <Block/> : <WarningAmberRounded/>}
                      color={hasBlockingErrors ? "error" : "warning"}
                      label={<FormattedMessage id={'assets-issues'}/>}
                    />
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </Box>
        {p.actions}
      </Stack>
    </Box>
  )
}

interface ChipConfiguration {
  condition: (campaignId: number | null | string, hasDraft: boolean) => boolean;
  tooltipId: string | ((enabled: boolean) => string);
  chipProps: {
    icon: JSX.Element | ((enabled: boolean) => JSX.Element);
    labelId: string | ((enabled: boolean) => string);
    color: string | ((enabled: boolean) => string);
  };
}

const chipConfigurations: ChipConfiguration[] = [
  {
    condition: (campaignId, hasDraft) => hasDraft,
    tooltipId: "has-draft-tooltip",
    chipProps: {
      icon: <Article/>,
      labelId: "draft",
      color: "warning"
    }
  },
  {
    condition: (campaignId, hasDraft) => ((campaignId || 0) > 0) && !hasDraft,
    tooltipId: "saved-tooltip",
    chipProps: {
      icon: <CheckCircle/>,
      color: "success",
      labelId: "saved"
    }
  },
  // {
  //   condition: (campaignId, draft) => (campaignId || 0) > 0,
  //   tooltipId: enabled => enabled ? "active-campaign-tooltip" : "inactive-campaign-tooltip",
  //   chipProps: {
  //     icon: enabled => enabled ? <Visibility/> : <VisibilityOff/>,
  //     color: enabled => enabled ? "success" : "warning",
  //     labelId: enabled => enabled ? "active-campaign" : "inactive-campaign"
  //   }
  // }
];
