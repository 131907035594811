import { useAppSelector } from "../../../../app/hooks";
import {
  getAppMode,
  getCurrentFeed,
  getCurrentFeedHasLoaded,
  getCurrentFeedSubStatus
} from "../../../../app/slices/app";
import { getFeedSourceRecord } from "../../../../app/utils";
import { FormattedMessage } from "react-intl";
import { MUIColor, SkeletonLoader } from "../../Blocks/Misc";
import Urls from "../../../../assets/js/Urls";
import feedApi, { feedStatusClassesMUI } from "../../../../app/services/secured/feed";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, ListItemText, Menu, MenuItem, SxProps } from "@mui/material";
import { ExpandMore, Launch } from "@mui/icons-material";
import { FeedSourceEnum } from "../../../../app/types/feed";
import { ShopList } from "./ShopList";
import useAnchorEl from "../../../../app/hooks/useAnchorEl";
import { FeedBadge } from "./FeedBadge";
import { FeedDetails } from "./FeedDetails";

interface UserProjectsProps {
  showFeed: boolean,
}

const ShopSelector = (p: UserProjectsProps) => {

  const currentFeed = useAppSelector(getCurrentFeed);
  const status = useAppSelector(getCurrentFeedSubStatus);
  const hasLoaded = useAppSelector(getCurrentFeedHasLoaded);
  const mode = useAppSelector(getAppMode);
  const feeds = feedApi.useGetFeedsQuery();
  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();

  const source = useMemo(() => getFeedSourceRecord({
    source: currentFeed?.source ?? FeedSourceEnum.Manual,
    url: currentFeed?.url ?? ""
  }), [ currentFeed ]);

  const statusClass = useMemo(() => feedStatusClassesMUI[status] as MUIColor, [ status ]);
  // const buttonStyle = useMemo(() => getButtonStyle(mode), [ mode ]);

  useEffect(() => {
    if (!hasLoaded) {
      handleClose();
    }
  }, [ hasLoaded, handleClose ]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          textTransform: "none",
          minWidth: '300px',
          justifyContent: "start",
          border: mode === "light" ? "1px solid #cecece" : "1px solid #3B3B3B",
          color: (theme) => theme.palette.text.primary,
          minHeight:'35px',
        }}
        endIcon={<ExpandMore fontSize="large"/>}
      >
        <SkeletonLoader load={!hasLoaded}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" gap={1}>
              <FeedBadge currentFeed={currentFeed} source={source} statusClass={statusClass}/>
              <Box sx={{ lineHeight: 1, textAlign: "start" }}>
                <FeedDetails showFeed={p.showFeed} currentFeed={currentFeed}/>
              </Box>
            </Box>
          </Box>
        </SkeletonLoader>
      </Button>


      {feeds.data && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          sx={{ mt: 1 }}
        >
          <ShopList feeds={feeds.data}/>
          <Divider/>
          <MenuItem
            component={Link}
            to={Urls.projects.list}
          >
            <ListItemText>
              <FormattedMessage id="projects-handle"/>
            </ListItemText>
            <Launch/>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
}

// const getButtonStyle = (mode: string): SxProps => ({
//
//   // color: (theme) => theme.
// });

export default React.memo(ShopSelector);
