import React, { ReactNode } from "react";
import { LoadingAnimation } from "../../../App/Blocks/Misc";
import AppSecured from "../../../App/AppSecured/AppSecured";
import SimpleForm from "../../../App/Blocks/SimpleForm";
import { CampaignHeader } from "./CampaignHeader";
import { useInitCampaign } from "./hooks/useInitCampaign";
import { useHandleCampaign } from "./hooks/useHandleCampaign";
import { Backdrop, Box, Fade, LinearProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AdsProvider, CampaignTypeIdentifier } from "../../../../app/types/campaign";
import useCampaignActions from "./hooks/useCampaignActions";
import { useAppSelector } from "../../../../app/hooks";
import { getCampaign } from "../../../../app/slices/campaign/getters";
import { adsCampaigns } from "../../../../app/slices/campaign/constants";
import useGoogleAssets from "../../../../app/hooks/useGoogleAssets";

export type PageParams = { campaignId: string, provider: AdsProvider, type: CampaignTypeIdentifier };

const _Campaign = () => {

  const { api: fetchCampaign, workflow } = useInitCampaign();
  const [ apiRes, handleCampaign, saveDraft, loadingApi ] = useHandleCampaign();
  const actions = useCampaignActions({ isSaving: apiRes.isLoading });

  useGoogleAssets();

  return (
    <AppSecured lockNav={true}>
      <LoadingAnimation data={fetchCampaign}>
        <SimpleForm onSubmit={handleCampaign}>
          <CampaignHeader actions={actions}/>
          <Stack spacing={2} key={`campaign-${fetchCampaign}`}>
            {workflow?.map((s, i) =>
              <React.Fragment key={`components-${i}`}>
                {workflow[i]}
              </React.Fragment>)}
          </Stack>
          <CampaignFooter actions={actions}/>
        </SimpleForm>
      </LoadingAnimation>

      {/** Loaders **/}
      <InitLoader open={typeof fetchCampaign !== "boolean" && fetchCampaign.isFetching}/>
      <BackdropSaving open={loadingApi}/>
    </AppSecured>
  )
}

interface LoadingProps {
  open: boolean,
}

const InitLoader = (p: LoadingProps) => {
  return (
    <Fade in={p.open} unmountOnExit timeout={800}>
      <Box
        sx={{
          height: '80vh',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography mb={1}>
            <FormattedMessage id={"fetching-campaign"}/>
          </Typography>
          <LinearProgress color={"inherit"}/>
        </Box>
      </Box>
    </Fade>
  )
}

const BackdropSaving = (p: LoadingProps) => {
  return (
    <Backdrop
      open={p.open}
      sx={{
        position: "absolute",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: "#fff",
      }}
    >
      <Stack spacing={1}>
        <Typography>
          <FormattedMessage id={'saving-campaign-data'} />
        </Typography>
        <LinearProgress color={"inherit"}/>
      </Stack>
    </Backdrop>
  )
}

interface CampaignFooterProps {
  actions: ReactNode,
}

const CampaignFooter = (p: CampaignFooterProps) => {
  const campaign = useAppSelector(getCampaign);
  const adCampaign = adsCampaigns[campaign.type];
  return (
    <Box mt={3} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <img src={adCampaign.logo} height={'30px'}/>
        <Typography color={"text.secondary"}>
          {adCampaign.title}
        </Typography>
      </Box>
      {p.actions}
    </Box>
  )
}

export default _Campaign;
