import { IntlProvider } from "react-intl";
import fr from './assets/langs/fr';
import en from "./assets/langs/en";
import AppRedirect from "./components/App/AppRedirect";
import AppToast from "./components/App/AppToast";
import { LoadingScreen } from "./components/App/Blocks/Misc";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useAppSelector } from "./app/hooks";
import { getAppMode, getLanguage } from "./app/slices/app";
import 'intro.js/introjs.css';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { makeAppTheme } from "./appTheme";
import { Outlet } from "react-router-dom";

function App() {

  const language = useAppSelector(getLanguage);
  const mode = useAppSelector(getAppMode);
  const theme = makeAppTheme(language, mode);

  return (
    <Box>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? "NULL"}
          container={{
            element: "recaptcha",
            parameters: {
              badge: 'bottomleft',
              theme: "dark"
            }
          }}
        >
          <IntlProvider locale={language} messages={language === "fr" ? fr : en}>
            <ThemeProvider theme={theme}>
              <CssBaseline/>
              <DndProvider backend={HTML5Backend}>
                <LoadingScreen/>
                <AppRedirect/>
                <AppToast/>
                <Outlet/>
              </DndProvider>
            </ThemeProvider>
          </IntlProvider>
        </GoogleReCaptchaProvider>
      </GoogleOAuthProvider>
    </Box>
  );
}

export default App;
