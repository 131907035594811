import { FeedSourceEnum, UserFeed } from "../../../../app/types/feed";
import { useAppDispatch } from "../../../../app/hooks";
import { resetAppNotif } from "../../../../app/slices/app_notifications";
import { setCurrentFeedLoaded } from "../../../../app/slices/app";
import React from "react";
import { Badge, Box, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { getFeedSourceRecord, getFeedSubStatus, niceUrl } from "../../../../app/utils";
import { feedStatusClassesMUI } from "../../../../app/services/secured/feed";
import { MUIColor } from "../../Blocks/Misc";
import { NavLink } from "react-router-dom";
import Urls from "../../../../assets/js/Urls";
import { FormattedMessage } from "react-intl";
import Locale from "../../Blocks/Locale";

// Define a type for the feed menu items
type FeedMenuItem = UserFeed & {
  google: boolean;
  meta: boolean;
};

// FeedItem subcomponent
const FeedItem: React.FC<{ feed: FeedMenuItem }> = ({ feed }) => {

  const dispatch = useAppDispatch();

  const sourceRecord = getFeedSourceRecord({
    source: feed.source ? feed.source : FeedSourceEnum.Manual,
    url: feed.url ?? '',
  });
  const feedStatus = getFeedSubStatus(feed);
  const statusColor = feedStatusClassesMUI[feedStatus] as MUIColor;

  const resetOnShopSelection = () => {
    dispatch(resetAppNotif());
    dispatch(setCurrentFeedLoaded(false));
  }

  return (
    <MenuItem
      sx={{ minWidth: '300px' }}
      component={NavLink}
      to={Urls.dash(feed.id)}
      onClick={resetOnShopSelection}
    >
      <Badge
        variant="dot"
        overlap="circular"
        color={statusColor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <img style={{ maxWidth: '20px' }} src={sourceRecord.logo} alt="Feed Source Logo"/>
      </Badge>
      <Stack width={'100%'} spacing={1} ml={1} direction="row" alignItems="center" justifyContent={"space-between"}>
        <Box display={"flex"}  gap={1}>
          <Locale locale={feed.locale} style={{ fontSize: '0.54rem' }}/>
          <Typography lineHeight={1}>{niceUrl(feed.name)}</Typography>
        </Box>
        <Typography variant="caption" lineHeight={1} color={`${statusColor}.main`}>
          <FormattedMessage id={feedStatus}/>
        </Typography>
      </Stack>
    </MenuItem>
  );
};

type FeedMenu = Record<string, Array<FeedMenuItem>>;

export const ShopList: React.FC<{ feeds: UserFeed[] }> = ({ feeds }) => {

  const feedMenuItems: Record<string, FeedMenuItem[]> = feeds.reduce((menu: FeedMenu, feed) => {
    const projectName = feed.project?.name ?? 'Unknown';
    if (!menu[projectName]) {
      menu[projectName] = [];
    }
    menu[projectName].push({
      ...feed,
      google: !!feed.platforms?.ads,
      meta: !!feed.platforms?.meta,
    });
    return menu;
  }, {});

  return (
    <React.Fragment>
      {Object.entries(feedMenuItems).map(([ projectName, feeds ]) => (
        <React.Fragment key={`project-${projectName}`}>
          <MenuItem disabled>
            <ListItemText>{projectName}</ListItemText>
          </MenuItem>
          {feeds.map(feed => (
            <FeedItem key={`feed-${feed.id}`} feed={feed}/>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
