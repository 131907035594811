import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../../app/utils";
import productsApi from "../../../../../app/services/secured/products";
import { useEffect } from "react";
import {
  getProductData,
  initProductVersion,
  setProductOriginal,
  setProductSlice,
  setProductView
} from "../../../../../app/slices/product";
import { setNavWasDisabled } from "../../../../../app/slices/app";

export type ProductImageType = { original: string, newImage: string, };

interface useInitProductProps {
  pId: number | null;
  productPreviewId: number | undefined;
}

const useInitProduct = (p: useInitProductProps) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const { pId, productPreviewId } = p;

  const productData = useAppSelector(getProductData);

  // Request
  const [ getProduct, productQuery ] = productsApi.useLazyGetProductQuery();

  // Data
  useEffect(() => {
    if (feed && pId) {
      getProduct({ feed, product: pId }, true);
    }
  }, [ feed, pId ]);

  // View
  useEffect(() => {
    if (productPreviewId) {
      dispatch(setProductView("drawer"));
    } else {
      dispatch(setProductView("page"));
    }
  }, [ productPreviewId ]);

  /** custom data */
  useEffect(() => {
    if (productQuery.data) {
      dispatch(setProductSlice(productQuery.data));
    }
  }, [ productQuery ]);

  useEffect(() => {
    if (productData.description) {
      dispatch(setProductOriginal({ field: "description", original: productData.description }));
    }
  }, [ productData.description ]);

  useEffect(() => {
    dispatch(setNavWasDisabled(true));
    dispatch(initProductVersion({ field: "title" }));
    dispatch(initProductVersion({ field: "description" }));
  }, []);
}

export default useInitProduct;
