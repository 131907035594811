import AppPublic from "../AppPublic";
import FCard from "../../Blocks/Card";
import { FormattedMessage, useIntl } from "react-intl";
import SimpleForm from "../../Blocks/SimpleForm";
import { Passwords } from "./Register";
import { useParams } from "react-router-dom";
import authApi, { SignupPayload } from "../../../../app/services/public/auth";
import { LoadingButton } from "../../Blocks/Misc";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import { redirect } from "../../../../app/slices/app";
import Urls from "../../../../assets/js/Urls";

const NewPassword = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams<{ token: string }>();
  const [ newPassword, newPasswordRes ] = authApi.useRefreshPasswordMutation();
  const intl = useIntl();

  useEffect(() => {
    if (newPasswordRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "success-new-password" })));
      dispatch(redirect(Urls.auth.login));
    }
  }, [ newPasswordRes.isSuccess ]);

  return <AppPublic page={"password"}>
    {token && (
      <SimpleForm onSubmit={s => newPassword({
        token,
        passwords: s as Pick<SignupPayload, 'password' | 'password_confirm'>
      })}>
        <Passwords/>
        <LoadingButton
          data={newPasswordRes}
          className={'mt-3'}
          type={'submit'}
        >
          <FormattedMessage id={"change-password"}/>
        </LoadingButton>
      </SimpleForm>
    )}
  </AppPublic>
}

export default NewPassword;
