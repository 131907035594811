import { Box, Button, Chip, Link, Stack, Step, StepLabel, Stepper, styled, Theme, Typography } from "@mui/material";
import FCard, { FCardHeader } from "../../App/Blocks/Card";
import { ArrowForward, AutoAwesome, CheckCircleRounded, Surfing } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAccounts } from "../../../app/hooks/useAccounts";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCurrentFeed, redirect } from "../../../app/slices/app";
import { GetCurrentFeedId } from "../../../app/utils";
import Urls from "../../../assets/js/Urls";

import { FeedSourceEnum } from "../../../app/types/feed";
import { AppDispatch } from "../../../app/store";
import { OnboardingStepsI, setOnboardingStep } from "../../../app/slices/shop";

import persona_1 from "../../../assets/personas/persona_1.svg";

const onboardingSteps = (dispatch: AppDispatch, feedId: number) => ([
  {
    label: "ads",
    actionLabel: "add-ads-account",
    action: () => dispatch(redirect(Urls.configuration(feedId))),
  },
  {
    label: "feed",
    actionLabel: "link-product-feed",
    action: () => dispatch(redirect(Urls.configuration(feedId))),
    secondary: {
      label: "unlock-configuration-call",
      icon: <AutoAwesome/>
    },
  },
  {
    label: "free",
    actionLabel: "diffuse-my-products",
    action: () => dispatch(redirect(Urls.configuration(feedId))),
  },
  {
    label: "campaign",
    actionLabel: "launch-campaign",
    action: () => {
      dispatch(redirect(Urls.campaigns.list2(feedId)));
      // dispatch(redirectTab("campaigns"));
    },
    secondary: {
      label: "unlock-trial",
      icon: <AutoAwesome color={"action"}/>
    },
  }
]);

const StartFeedcast = () => {
  const dispatch = useAppDispatch();
  const feedId = GetCurrentFeedId();
  const currFeed = useAppSelector(getCurrentFeed);

  const [ accounts ] = useAccounts();
  const activeStep = useAppSelector(state => state.shop.onboarding.activeStep);
  const setActiveStep = (s: OnboardingStepsI) => dispatch(setOnboardingStep(s));

  const steps = onboardingSteps(dispatch, feedId);

  useEffect(() => {
    if (!accounts.hasLinkedAccount.google && !accounts.hasLinkedAccount.meta) {
      setActiveStep(OnboardingStepsI.ADS);
    } else if (currFeed && (!currFeed?.last_stat?.imported && !(currFeed?.url || currFeed?.source === FeedSourceEnum.Prestashop || currFeed?.source === FeedSourceEnum.Woocommerce))) {
      setActiveStep(OnboardingStepsI.FEED);
    } else if (!currFeed?.feed_option?.enable_free_listing) {
      setActiveStep(OnboardingStepsI.FREE)
    } else if (!accounts.hasCampaigns.google && !accounts.hasCampaigns.meta) {
      setActiveStep(OnboardingStepsI.CAMPAIGN);
    } else {
      setActiveStep(OnboardingStepsI.FINISHED);
    }
  }, [ accounts, currFeed ]);

  const currentStep = steps[activeStep] ?? null;
  const background = (theme: Theme) => `${theme.palette.gradient?.main} ,url(${persona_1})`;

  return (
    <Box>
      <FCard
        sx={{
          background,
          backgroundPosition: "right 0 top 20%",
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box sx={{
          maxWidth: '80%',
        }}>
          <Typography variant={"h6"}>
            <FormattedMessage id={'whats-next'}/>
          </Typography>
          <Typography variant={"h5"} mt={1}>
            <FormattedMessage id={currentStep?.label + "-onboarding"}/>
          </Typography>
          <Typography>
            <FormattedMessage id={currentStep?.label + "-onboarding-desc"}/>
          </Typography>

          <Button
            variant={"contained"}
            onClick={currentStep?.action}
            endIcon={<ArrowForward/>}
            sx={{
              mt: 2
            }}
          >
            <FormattedMessage id={'lets-go'}/>
          </Button>
        </Box>
      </FCard>
    </Box>
  )
}
export default StartFeedcast;
