import { GetCurrentFeedId } from "../../../../../app/utils";
import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { redirect } from "../../../../../app/slices/app";
import Urls from "../../../../../assets/js/Urls";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import feedApi from "../../../../../app/services/secured/feed";
import { useLoadingScreen } from "../../../../App/Blocks/Misc";
import { Box } from "@mui/material";
import { addDatalayerEvent } from "../../../../../app/events";

const SubCallback = () => {
  const dispatch = useAppDispatch(),
    feed = GetCurrentFeedId(),
    [ params ] = useSearchParams(),
    status = params.get("status"),
    session_id = params.get("session_id")
  ;

  const [ sync, syncRes ] = feedApi.useSyncSubscriptionMutation();
  let hasFired = false;

  useLoadingScreen(syncRes);

  useEffect(() => {
    if (status === "success") {
      if (session_id) {
        // Sync, send feed & session
        if (!hasFired) {
          sync({ feed, session_id });
          hasFired = true;
        }
      } else {
        alert("Missing session ID");
      }
    } else {
      alert("Stripe Error : Contact administrator");
    }
  }, [ status ]);

  useEffect(() => {
    if (syncRes.isSuccess) {
      addDatalayerEvent("subscribe_success");
      dispatch(NewSuccessNotification("subscription-success"));
      dispatch(redirect(Urls.settings.subEdit(feed)))
    }
  }, [ syncRes.isSuccess ])

  return <Box />
}

export default SubCallback;
