import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FullKpi,
  FullKpiFiltersData,
  getProductReportingFilters,
  resetFiltersRanges,
  setFiltersRanges
} from '../../../../app/slices/reporting';
import { Box, Slider, styled, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GridPanelContent, GridPanelWrapper } from "@mui/x-data-grid";

export const useCustomPanel = () => {
  return (
    <GridPanelWrapper sx={{
      overflow: "hidden",
      borderRadius: "12px",
    }}>
      <GridPanelContent>
        <ProductFilter/>
      </GridPanelContent>
    </GridPanelWrapper>
  )
}

interface FilterSliderProps {
  filterKey: FullKpi;
  filterValue: FullKpiFiltersData; // You would need to replace this type with whatever type your filter value is
}

const FilterSlider = React.memo(({ filterKey, filterValue }: FilterSliderProps) => {
  const dispatch = useAppDispatch();
  return (
    <Box textAlign={"center"}>
      <Typography variant={"caption"} lineHeight={1}>
        <FormattedMessage id={filterKey}/>
      </Typography>
      <IOSSlider
        min={0}
        max={filterValue.max}
        step={filterValue.max / 100}
        valueLabelDisplay="auto"
        defaultValue={[ filterValue.valMin, filterValue.valMax ]}
        marks={[ { value: 0, label: "0" }, { value: filterValue.max, label: filterValue.max } ]}
        onChangeCommitted={(event, values) => {
          const [ valMin, valMax ] = values as [ number, number ];
          const currentFilter = { ...filterValue };
          dispatch(setFiltersRanges({
            key: filterKey,
            data: {
              ...currentFilter,
              valMin,
              valMax,
            }
          }))
        }}
      />
    </Box>
  );
});


const ProductFilter = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getProductReportingFilters);
  const handleClearClick = () => {
    dispatch(resetFiltersRanges());
  }
  return (
    <Box py={1} px={3}>
      {Object.entries(filters).map(([ key, value ]) =>
        <FilterSlider filterKey={key as FullKpi} filterValue={value}/>
      )}
      {/*<Box>*/}
      {/*  <Button onClick={handleClearClick} fullWidth>*/}
      {/*    <FormattedMessage id="clear"/>*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Box>
  );
}

export default ProductFilter;

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#000000' : theme.palette.primary.main,
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 8,
    fontWeight: 'normal',
    top: 0,
    backgroundColor: 'unset',
    color: theme.palette.text.secondary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));
