import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, getModalData, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";
import { FormattedMessage } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import channelsApi, { ResetChannelParams } from "../../../../../app/services/secured/channels";
import { useEffect } from "react";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import { RestartAlt } from "@mui/icons-material";
import { GetCurrentFeedId } from "../../../../../app/utils";

export default () => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const open = useAppSelector(isModalOpened(ModalTypes.ResetChannel));
  const data = useAppSelector<Pick<ResetChannelParams, "provider"> | null>(getModalData);

  const [ resetChannel, resetChannelRes ] = channelsApi.useResetChannelMutation();

  const close = () => dispatch(closeModal());

  useEffect(() => {
    if (resetChannelRes.isSuccess) {
      close();
      dispatch(NewSuccessNotification("success-init-channel"));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [ resetChannelRes.isSuccess ]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        <FormattedMessage id={'init-channel'}/>
      </DialogTitle>
      <DialogContent dividers>
        <FormattedMessage id={'init-channel-desc'}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <FormattedMessage id={'close'}/>
        </Button>
        {data?.provider && (
          <Button
            variant={"contained"}
            color={"error"}
            startIcon={<RestartAlt/>}
            onClick={() => resetChannel({ feed, provider: data.provider })}
          >
            <FormattedMessage id={'confirm'}/>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
