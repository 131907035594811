import { ShopAccounts } from "../../../../../app/types/feed";
import Urls from "../../../../../assets/js/Urls";
import { C_Icons } from "../../../../../app/slices/app";
import { CampaignTypeIdentifier, Company } from "../../../../../app/types/campaign";

export type CampaignNodeProps = {
  id: CampaignTypeIdentifier,
  link: string,
  logos: string[],
  status: "recommended" | "advanced",
  available: boolean,
  company: Company,
};

export const getCampaignsTypes = (feed: string | number, accounts: ShopAccounts): CampaignNodeProps[] => [
  {
    id: "perf-max",
    link: Urls.campaigns.new(feed, "go", "perf-max"),
    logos: [ C_Icons.google, C_Icons.youtube, C_Icons.display, C_Icons.shopping, C_Icons.discover, C_Icons.gmail, C_Icons.maps ],
    status: "recommended",
    available: accounts.hasChannelActive.google,
    company: "google",
  },
  {
    id: "shopping",
    link: Urls.campaigns.new(feed, "go", "shopping"),
    logos: [ C_Icons.google, C_Icons.shopping ],
    status: "advanced",
    available: accounts.hasChannelActive.google,
    company: "google",
  },
  {
    id: "meta-advantage",
    link: Urls.campaigns.new(feed, "fb", "meta-advantage"),
    logos: [ C_Icons.facebook, C_Icons.instagram, C_Icons.whatsapp, C_Icons.messenger ],
    status: "recommended",
    available: accounts.hasChannelActive.meta,
    company: "meta",
  },

  {
    id: "meta-classic",
    link: Urls.campaigns.new(feed, "fb", "meta-classic"),
    logos: [ C_Icons.facebook, C_Icons.instagram, C_Icons.messenger ],
    status: "advanced",
    available: accounts.hasChannelActive.meta,
    company: "meta",
  },
  {
    id: "microsoft-shopping",
    link: Urls.campaigns.new(feed, "bg", "microsoft-shopping"),
    logos: [],
    status: "recommended",
    available: accounts.hasChannelActive.microsoft,
    company: "microsoft",
  }
]
