import { Box, Divider, Switch, Typography } from "@mui/material";
import { Bolt, RssFeed } from "@mui/icons-material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getProductDataSource, toggleDataSource } from "../../../../../app/slices/product";

export const SourceToggle = () => {

  const dispatch = useAppDispatch();
  const dataSource = useAppSelector(getProductDataSource);
  const toggle = () => dispatch(toggleDataSource());

  return (
    <>
      <Divider />
      <Box display={"flex"} gap={0.5} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} px={2}
           pb={2}>
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Box sx={{ display: "flex", gap: 0.3, alignItems: "center" }}>
            <RssFeed fontSize={"small"} color={"disabled"}/>
            <Typography color={"text.secondary"} variant={"body2"}>
              Original
            </Typography>
          </Box>
          <Switch
            size={"small"}
            onClick={toggle}
            color={dataSource === "feedcast" ? "secondary" : undefined}
            checked={dataSource === "feedcast"}
          />
          <Box sx={{ display: "flex", gap: 0.3, alignItems: "center" }}>
            <Typography color={"text.secondary"} variant={"body2"}>
              Feedcast
            </Typography>
            <Bolt fontSize={"small"} color={"disabled"}/>
          </Box>
        </Box>
      </Box>
    </>
  )
}
