import { useAppSelector } from "../../../../../../app/hooks";
import { getUserAssets } from "../../../../../../app/slices/app";
import React, { useEffect, useMemo, useState } from "react";
import Asset from "./Asset";
import { Alert, AlertTitle, Box, ImageList } from "@mui/material";
import { AssetType } from "../../../../../../app/types/feed";
import { getPerfMaxData, getPerfMaxImages } from "../../../../../../app/slices/campaign/getters";
import { FormattedMessage } from "react-intl";
import { getImageFormat } from "../../../../../../app/utils";
import { AssetsFilters } from "./DropArea/DropArea";
import { AspectRatio } from "./dimensions";
import { AssetsProps, getModalData } from "../../../../../../app/slices/modal";

const ratios = [ AspectRatio.Square, AspectRatio.Portrait, AspectRatio.Landscape ];

const AssetsZone = (p: AssetsFilters) => {

  const { formatFilter, nbColumns } = p;

  const currentNode = useAppSelector<AssetsProps|undefined>(getModalData)?.node;
  const perfMaxImages = useAppSelector(getPerfMaxData)[currentNode ?? "logos"];
  const assets = useAppSelector(getUserAssets);
  const assetsMemo = useMemo(() => assets, [ assets ]);

  const [ assetsArray, setAssetsArray ] = useState<AssetType[]>([]);

  useEffect(() => {
    setAssetsArray(assetsMemo);
  }, [ assetsMemo ]);

  const assetsList = useMemo(() => (
    assetsArray
    .filter(image => formatFilter !== "all" ? getImageFormat(image) === formatFilter : true)
    .filter(image => {
      const imgRatio = parseFloat((image.width / image.height).toFixed(2));
      return ratios.includes(imgRatio);
    })
  ), [ assetsArray, formatFilter, nbColumns ]);

  return (
    <Box>
      <Box
        className={"asset-container"}
        key={`assets-${assetsMemo.length}`}
        position={"relative"}
      >
        <ImageList cols={nbColumns} variant={"masonry"}>
          {assetsList.map((a, i) =>
            <Asset
              {...a}
              key={`asset-${i}`}
              selected={perfMaxImages.includes(a.id)}
            />
          )}
        </ImageList>
      </Box>
      {assetsList.length === 0 && (
        <Alert
          severity={"info"}
          sx={{
            width: '100%',
            overflow: "hidden !important",
          }}
        >
          <AlertTitle>
            <FormattedMessage id={'no-assets'}/>
          </AlertTitle>
          <FormattedMessage id={'no-assets-desc'}/>
        </Alert>
      )}
    </Box>
  )
}

export default AssetsZone;
