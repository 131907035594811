import { AssetType } from "../../../../../../../app/types/feed";
import { useAppDispatch } from "../../../../../../../app/hooks";
import React, { useState } from "react";
import { deleteAsset } from "../../../../../../../app/slices/campaign/campaign";
import { motion } from "framer-motion";
import { Box, Fade, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { PicturesHolderProps } from "./PicturesHolder";
import { picturesRequirements } from "./PicturesAssets";
import Grid2 from "@mui/material/Unstable_Grid2";

interface PictureProps extends PicturesHolderProps {
  i: AssetType;
}

export const Picture = ({ i, type }: PictureProps) => {

  const dispatch = useAppDispatch();
  const [ hover, setHover ] = useState<boolean>(false);
  const remove = (image: AssetType) => dispatch(deleteAsset(image));

  const isValidFormat = (i.width >= picturesRequirements[type].min[0]) && (i.height >= picturesRequirements[type].min[1]);

  return (
    <Grid2 xs={type !== "marketing_images" ? 4 : 6}>
      <motion.div
        style={{
          position: "relative"
        }}
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
      >
        <motion.div
          initial={{
            transform: "scale(1)",
          }}
          whileHover={{
            transform: "scale(1.1)",
          }}
        >
          <Box
            sx={{
              animation: "opacity 0.4s",
              opacity: hover ? 0.7 : 1,
              filter: !isValidFormat ? "sepia(1) saturate(500%) hue-rotate(-30deg)" : undefined,
            }}
          >
            <img
              src={i.url}
              width={'auto'}
              style={{
                objectFit: "cover",
                width:'100%',
                height:'auto',
              }}
            />
          </Box>
          <Fade in={hover} unmountOnExit>
            <Box sx={{ position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)" }}>
              <IconButton
                size={"small"}
                onClick={() => remove(i)}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              >
                <Delete/>
              </IconButton>
            </Box>
          </Fade>
        </motion.div>
      </motion.div>
    </Grid2>
  )
}
