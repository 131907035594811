import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useLabels from "../../../app/hooks/useLabels";
import React from "react";
import { setLabelSearch } from "../../../app/slices/reporting";
import { FormControl, InputAdornment, MenuItem, TextField } from "@mui/material";
import { LabelImportant } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export const LabelSelector = () => {

  const dispatch = useAppDispatch();
  const { labels } = useLabels();

  const value = useAppSelector(state => state.reporting.search.label?.uuid);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      const selectedLabel = labels.find(l => l.uuid === value);
      if (selectedLabel) {
        dispatch(setLabelSearch(selectedLabel));
      }
    } else {
      dispatch(setLabelSearch(null));
    }
  }

  return (
    <FormControl fullWidth>
      <TextField
        select
        label={"Label"}
        value={value ?? 0}
        onChange={onChange}
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <LabelImportant/>
          </InputAdornment>
        }}
      >
        <MenuItem value={0}>
          <FormattedMessage id={'all'}/>
        </MenuItem>
        {labels.map(l => (
          <MenuItem
            key={`label-${l.uuid}`}
            value={l.uuid}
          >
            {l.label_value}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}
