import { useAppDispatch, useAppSelector } from "../hooks";
import { getCurrentFeed } from "../slices/app";
import { GetCurrentFeedId } from "../utils";
import {
  getShopAccounts,
  setCanCreateCampaigns, setHasAccess,
  setHasCampaigns,
  setHasChannelActive, setHasErrorFetching,
  setHasLinkedAccount, setHasPayment, setHasTracking,
  setIsOauth
} from "../slices/shop";
import profileApi, { oauthRedirect } from "../services/secured/profile";
import campaignApi from "../services/secured/campaign";
import { useEffect, useMemo, useState } from "react";
import { CompaniesAuth } from "../types/feed";
import channelsApi from "../services/secured/channels";
import { addAppNotif } from "../slices/app_notifications";
// import { AlertTypes } from "../types/profile";

export const useAccounts = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const accounts = useAppSelector(getShopAccounts);

  const currFeed = useAppSelector(getCurrentFeed);
  const currFeedMemo = useMemo(() => currFeed, [ currFeed ]);

  // Fetch user's accounts
  const googleAccountsQuery = profileApi.useGetGoogleAccountsQuery({ redirect_url: oauthRedirect });
  const metaAccountsQuery = profileApi.useGetMetaAccountsQuery({ redirect_url: oauthRedirect });
  const microsoftAccountsQuery = profileApi.useGetMicrosoftAccountsQuery({ redirect_url: oauthRedirect });

  // Fetch tracking
  const [ getTracking, trackingQuery ] = channelsApi.useLazyGetTrackingQuery();
  const trackingData = trackingQuery.data;

  // Linked account - in current feed
  const hasGoAccountLinked = useMemo(() => currFeedMemo?.platforms?.ads !== null, [ currFeedMemo?.platforms?.ads ]);
  const hasMetaAccountLinked = useMemo(() => currFeedMemo?.platforms?.meta !== null, [ currFeedMemo?.platforms?.meta ]);
  const hasMicrosoftAccountLinked = useMemo(() =>
    currFeedMemo?.platforms?.microsoft !== null && (currFeedMemo?.platforms?.microsoft.code.length || 0) > 0, [ currFeedMemo?.platforms?.microsoft ]);

  // Linked Merchant Centers
  const hasGoMerchantCenterLinked = useMemo(() => currFeedMemo?.platforms?.merchant !== null, [ currFeedMemo?.platforms?.merchant ]);
  const hasMetaCatalogLinked = useMemo(() => (currFeedMemo?.platforms?.facebook_catalog_id?.length || 0) > 0, [ currFeedMemo?.platforms?.facebook_catalog_id ]);
  const hasMicrosoftCenterLinked = useMemo(() => currFeedMemo?.platforms?.microsoft_merchant !== null, [ currFeedMemo?.platforms?.microsoft_merchant ]);

  // Channels - is Oauth ?
  const isGoogleOauth = googleAccountsQuery.data?.grant_required === false;
  const isMetaOauth = (metaAccountsQuery.data?.grant_required === false);
  const isMicrosoftOauth = microsoftAccountsQuery.data?.grant_required === false;

  // Queries for advanced configuration
  const [ getMerchantCenter, goMerchantCenter ] = campaignApi.useLazyGetAccountStatusQuery();
  const [ getMetaCatalog, metaCatalog ] = campaignApi.useLazyGetMetaCatalogQuery();
  const [ getMicrosoftCenter, microsoftCenter ] = campaignApi.useLazyGetMicrosoftCentersQuery();

  // Campaign queries
  const [ getGoCampaigns, goCampaigns ] = campaignApi.useLazyGetCampaignsQuery();
  const [ getMetaCampaigns, metaCampaigns ] = campaignApi.useLazyGetCampaignsQuery();
  const [ getMicrosoftCampaigns, microsoftCampaigns ] = campaignApi.useLazyGetCampaignsQuery();

  // Ads accounts access
  const issuesWithAccounts = false;
  const hasGoogleIssues = false;
  const hasMetaIssues = false;
  const hasMicrosoftIssues = false;

  const hasErrorFetching = accounts.hasErrorFetching;

  // Fetch campaigns, if no access issues to linked accounts
  useEffect(() => {
    if (currFeedMemo) {
      if (hasGoAccountLinked && !hasGoogleIssues && !hasErrorFetching.google) {
        getGoCampaigns({ feed, provider: "go" }, true);
      }
      if (hasMetaAccountLinked && !hasMetaIssues && !hasErrorFetching.meta) {
        getMetaCampaigns({ feed, provider: "fb" }, true);
      }
      if (hasMicrosoftAccountLinked && !hasMicrosoftIssues && !hasErrorFetching.microsoft) {
        getMicrosoftCampaigns({ feed, provider: "bg" }, true);
      }

      if (feed > 0) {
        getTracking({ feed });
      }


      dispatch(setHasAccess({
        google: !hasGoogleIssues,
        meta: !hasMetaIssues,
        microsoft: !hasMicrosoftIssues,
      }))
    }
  }, [ issuesWithAccounts, hasGoogleIssues, hasMicrosoftIssues, hasMetaIssues, currFeedMemo, hasGoAccountLinked, hasMetaAccountLinked, hasMicrosoftAccountLinked, hasErrorFetching ]);

  useEffect(() => {
    const errorsFetching: CompaniesAuth = {
      google: false,
      meta: false,
      microsoft: false
    }
    if (goCampaigns.isError) {
      errorsFetching.google = true;
    }
    if (metaCampaigns.isError) {
      errorsFetching.meta = true;
    }
    if (microsoftCampaigns.isError) {
      errorsFetching.microsoft = true;
    }
    dispatch(setHasErrorFetching(errorsFetching));
  }, [
    goCampaigns.isError,
    metaCampaigns.isError,
    microsoftCampaigns.isError
  ]);


  // Global shop accounts' statuses - isOauth, hasCampaigns, hasLinkedAccount
  useEffect(() => {
    const condition = googleAccountsQuery.isSuccess
      && metaAccountsQuery.isSuccess
      && microsoftAccountsQuery.isSuccess
      && currFeedMemo
      && feed > 0
    ;
    if (condition) {

      const hasGoogleCampaigns = goCampaigns.isSuccess ? goCampaigns.data?.campaigns.filter(c => c.is_feedcast).length : 0;
      const hasMetaCampaigns = metaCampaigns.isSuccess ? metaCampaigns.data?.campaigns.filter(c => c.is_feedcast).length : 0;
      const hasMicrosoftCampaigns = microsoftCampaigns.isSuccess ? microsoftCampaigns.data?.campaigns.filter(c => c.is_feedcast).length : 0;

      dispatch(setIsOauth({ google: isGoogleOauth, meta: isMetaOauth, microsoft: isMicrosoftOauth }));

      dispatch(setHasLinkedAccount({
        google: hasGoAccountLinked, meta: hasMetaAccountLinked, microsoft: hasMicrosoftAccountLinked
      }));

      dispatch(setHasCampaigns({
        google: hasGoogleCampaigns > 0,
        meta: hasMetaCampaigns > 0,
        microsoft: hasMicrosoftCampaigns > 0,
        nb: hasGoogleCampaigns + hasMetaCampaigns + hasMicrosoftCampaigns,
      }));

      dispatch(
        setHasChannelActive({
          google: currFeedMemo?.platforms?.google_enabled ?? false,
          meta: currFeedMemo?.platforms?.meta_enabled ?? false,
          microsoft: currFeedMemo?.platforms?.microsoft_enabled ?? false
        })
      )
    }
  }, [
    currFeedMemo,
    goCampaigns,
    metaCampaigns,
    googleAccountsQuery,
    metaAccountsQuery,
    microsoftAccountsQuery,
    hasGoAccountLinked,
    hasMetaAccountLinked,
    hasMicrosoftAccountLinked
  ]);

  // Fetch advanced configuration - Google Merchant Center, Meta Catalog, Microsoft Merchant Center
  useEffect(() => {
    if (currFeedMemo) {
      if (hasGoAccountLinked && !goMerchantCenter.isError) {
        getMerchantCenter({ feed }, true);
      }
      if (hasMetaAccountLinked && isMetaOauth && !metaCatalog.isError) {
        getMetaCatalog({ feed }, true);
      }
      if (hasMicrosoftAccountLinked && isMicrosoftOauth && !microsoftCenter.isError) {
        getMicrosoftCenter(feed, true);
      }
    }
  }, [
    currFeedMemo,
    hasGoAccountLinked,
    hasMetaAccountLinked,
    hasMicrosoftAccountLinked,
    isMetaOauth,
    isMicrosoftOauth,
    goMerchantCenter,
    metaCatalog,
    microsoftCenter,
  ]);

  // Check advanced configuration response - Can user can create campaigns ?
  useEffect(() => {
    const mCenter = goMerchantCenter.data?.merchant;
    const canCreateGoogle = (
      (
        mCenter
        && mCenter.address.is_phone_validated
        && (mCenter.contact.email?.length || 0) > 0
        && (mCenter.address.address?.length || 0) > 0
      )
      || currFeedMemo?.platforms?.merchant?.is_child
    ) ?? false;

    const canCreateMeta = (
      metaCatalog.isSuccess
      && metaCatalog.data.catalog !== null
    );

    const canCreateMicrosoft = (
      microsoftCenter.isSuccess
      && ((currFeedMemo?.platforms?.microsoft_merchant?.id || 0) > 0)
    );

    // Can create campaigns ?
    dispatch(setCanCreateCampaigns({
      google: canCreateGoogle,
      meta: canCreateMeta,
      microsoft: canCreateMicrosoft
    }));

    // Has payment ?
    dispatch(setHasPayment({
      google: goMerchantCenter.data?.ads?.has_payment ?? false,
      meta: metaCatalog.data?.has_payment ?? false,
      microsoft: true,
    }))
  }, [ goMerchantCenter, metaCatalog, microsoftCenter, currFeedMemo ]);

  const isGoMerchantCenterError = goMerchantCenter.isError;
  const isMetaCatalogError = metaCatalog.isError;
  const isMicrosoftCenterError = microsoftCenter.isError;

  // Tracking - Is there tracking detected ?
  useEffect(() => {
    if (trackingData) {
      const hasGoogleTracking = (trackingData.go ?? []).length > 0;
      const hasMetaTracking = (trackingData.fb ?? []).length > 0;
      const hasMicrosoftTracking = (trackingData.bg ?? []).length > 0;

      dispatch(setHasTracking({
        google: hasGoogleTracking,
        meta: hasMetaTracking,
        microsoft: hasMicrosoftTracking
      }));
    }
  }, [ trackingData, ]);

  const loading = useMemo(() => {
    const isFetchingAccounts =
      googleAccountsQuery.isUninitialized ||
      googleAccountsQuery.isFetching ||
      metaAccountsQuery.isUninitialized ||
      metaAccountsQuery.isFetching ||
      microsoftAccountsQuery.isUninitialized ||
      microsoftAccountsQuery.isFetching;

    const isGoMerchantCenterUninitialized = goMerchantCenter.isUninitialized && hasGoAccountLinked;
    const isGoMerchantCenterFetching = goMerchantCenter.isFetching;

    const isMetaCatalogUninitialized = metaCatalog.isUninitialized && hasMetaAccountLinked && isMetaOauth;
    const isMetaCatalogFetching = metaCatalog.isFetching;

    const isMicrosoftCenterUninitialized = microsoftCenter.isUninitialized && hasMicrosoftAccountLinked && isMicrosoftOauth;
    const isMicrosoftCenterFetching = microsoftCenter.isFetching;

    const isConfiguringAdvanced =
      isGoMerchantCenterUninitialized ||
      isGoMerchantCenterFetching ||
      isGoMerchantCenterError ||
      isMetaCatalogUninitialized ||
      isMetaCatalogFetching ||
      isMetaCatalogError ||
      isMicrosoftCenterUninitialized ||
      isMicrosoftCenterFetching ||
      isMicrosoftCenterError
    ;

    const isCampaignsLoading =
      (goCampaigns.isUninitialized && hasGoAccountLinked && !hasGoogleIssues) ||
      goCampaigns.isFetching ||
      (metaCampaigns.isUninitialized && hasMetaAccountLinked && !hasMetaIssues) ||
      metaCampaigns.isFetching ||
      (microsoftCampaigns.isUninitialized && hasMicrosoftAccountLinked && !hasMicrosoftIssues) ||
      microsoftCampaigns.isFetching;

    return (isFetchingAccounts || isConfiguringAdvanced || isCampaignsLoading)
  }, [
    currFeedMemo,

    googleAccountsQuery,
    metaAccountsQuery,
    microsoftAccountsQuery,

    goMerchantCenter,
    metaCatalog,
    microsoftCenter,

    goCampaigns,
    metaCampaigns,
    microsoftCampaigns,

    hasGoAccountLinked,
    hasMetaAccountLinked,
    hasMicrosoftAccountLinked,

    hasGoogleIssues,
    hasMetaIssues,
    hasMicrosoftIssues,

    isGoogleOauth,
    isMetaOauth,
    isMicrosoftOauth,
    // isErrorState
  ]);

  return [
    accounts, {
      loading,
      oauth: {
        google: googleAccountsQuery,
        meta: metaAccountsQuery,
        microsoft: microsoftAccountsQuery,
      },
      services: {
        merchantCenter: goMerchantCenter,
        metaCatalog,
        microsoftCenter
      }
    },
  ] as const;
}
