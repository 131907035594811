import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setMetaClassicType } from "../../../../../app/slices/campaign/campaign";
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { getMetaClassicType } from "../../../../../app/slices/campaign/getters";
import { MetaClassicType as MetaType } from "../../../../../app/types/campaign";
import { FormattedMessage } from "react-intl";

export const MetaClassicType = () => {
  const dispatch = useAppDispatch();
  const campaignType = useAppSelector(getMetaClassicType);
  const initNewType = (t: MetaType) => dispatch(setMetaClassicType(t));

  return <Box>
    <Typography variant={"subtitle2"} mb={0.5}>
      <FormattedMessage id={"campaign-behaviour"} />
    </Typography>

    <ToggleButtonGroup
      exclusive
      size={"small"}
      value={campaignType}
      onChange={(e, value) => initNewType(value as MetaType)}
    >
      <ToggleButton value={"prospection"}>
        Prospection
      </ToggleButton>
      <ToggleButton value={"retargeting"}>
        Retargeting
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
}
