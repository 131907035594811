import { useAccounts } from "../../../../../../app/hooks/useAccounts";
import { Alert, AlertTitle, Box, Collapse, Divider, IconButton, Link, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { OpenInNew, SyncProblem } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import React from "react";

export const MerchantWarnings = () => {
  const [ , { services } ] = useAccounts();
  const warnings = services.merchantCenter.data?.merchant?.warnings ?? [];
  const isPendingInitialReview = warnings.length === 1 && warnings[0]?.title === "Pending initial review";
  return (
    <Collapse in={(warnings.length || 0) > 0}>
      <Alert severity={!isPendingInitialReview ? "warning" : "info"} sx={{ borderRadius: 0 }}>
        <AlertTitle>
          <FormattedMessage id={'alerts-merchant-center'}/>
        </AlertTitle>
        <Stack spacing={1}>
          {warnings.map(w => {
            return (
              <Box display={"flex"} alignItems="center" gap={1}>
                <Typography>
                  {w.title}
                </Typography>
                {w.link && (
                  <IconButton
                    component={'a'}
                    target={'_blank'}
                    href={w.link ?? ""}
                    size={"small"}
                  >
                    <OpenInNew fontSize={"small"}/>
                  </IconButton>
                )}
              </Box>
            )
          })}
        </Stack>
      </Alert>
    </Collapse>
  )
}
