import { Box, Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import AppPublic from '../AppPublic2';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
   return (
      <AppPublic>
         <div id={'recaptcha'} />
         <FlexContainer>
            <FlexItem>
               <StyledBox>
                  <Container maxWidth="xs">
                     {children}
                  </Container>
               </StyledBox>
            </FlexItem>
         </FlexContainer>
      </AppPublic>
   )
}

const FlexContainer = styled(Box)(({ theme }) => ({
   display: 'flex',
   flexDirection: 'row',
   flexWrap: 'wrap',
   height: '100vh',
   gap: theme.spacing(8),
}));

const FlexItem = styled(Box)(({ theme }) => ({
   flex: '1',
   minWidth: '300px',
   height: '100%',
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   // alignItems: "center"
}));

const StyledBox = styled(Box)(({ theme }) => ({
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   padding: theme.spacing(8),
}));

export default AuthWrapper;
