import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { AppLanguages, getAppMode, getLanguage, setAppMode, setLanguage } from "../../../app/slices/app";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import Locale from "../Blocks/Locale";
import { AppPublicActionsProps } from "./AppPublic";
import { FormattedMessage } from "react-intl";

export const AppPublicActions = ({ params }: AppPublicActionsProps) => {
  const dispatch = useAppDispatch();
  const [ showChildren, setShowChildren ] = useState(true);

  const currentLang = useAppSelector(getLanguage);

  useEffect(() => {
    const lang = params.get("lang") || "";
    if (lang && lang !== currentLang) {
      dispatch(setLanguage(lang as AppLanguages));
    }
  }, [ params ]);

  const mode = useAppSelector(getAppMode);

  const toggleLang = useCallback(() => {
    setShowChildren(false);
    setTimeout(() => {
      dispatch(setLanguage(currentLang === "fr" ? "en" : "fr"));
      setShowChildren(true);
    }, 1000);
  }, [ currentLang, dispatch ]);

  const toggleMode = useCallback(() => dispatch(setAppMode(mode === 'light' ? 'dark' : 'light')), [ dispatch, mode ]);

  return (
    <Box
      sx={{
        position: {
          xs: 'relative',
          md: "relative",
        },
        fontSize: "2rem",
        display: "flex",
        justifyContent: {
          xs: "center",
          md: null,
        },
        gap: 2,
      }}
    >
      <IconButton onClick={() => toggleMode()}>
        {mode === 'dark' ? <Brightness7/> : <Brightness4/>}
      </IconButton>

      <IconButton
        onClick={toggleLang}
      >
        {!showChildren
          ? <CircularProgress size={25}/>
          : <Locale locale={currentLang}/>}
      </IconButton>
      <Button
        variant={"outlined"}
        component={'a'}
        href={"https://feedcast.io"}
      >
        <FormattedMessage id={'back-to-website'} />
      </Button>
    </Box>
  )
}
