import React from "react";
import { RuleCommonProps, RuleWithStateProps } from "./Rules";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { DynamicLabelCriterias, DynamicLabelField } from "../../../app/types/feed";

export const RuleFieldSelector: React.FC<RuleWithStateProps> = ({ rule, setRule, r_index, view }) => (
  <Grid2 xs={12}>
    <FormControl>
      <TextField
        select
        size='small'
        variant={"standard"}
        InputProps={{
          disableUnderline: true,
        }}
        disabled={view === "tooltip"}
        onChange={(e) => setRule({ ...rule, field: e.target.value as DynamicLabelField })}
        name={`conditions[${r_index}].field`}
        value={rule.field}
        key={`field-${rule.field}-${r_index}`}
      >
        <MenuItem value={"brand_k"}><FormattedMessage id="brand"/></MenuItem>
        <MenuItem value={"condition_k"}><FormattedMessage id="condition"/></MenuItem>
        <MenuItem value={"id_k"}><FormattedMessage id="reference"/></MenuItem>
      </TextField>
    </FormControl>
  </Grid2>
);

export const RuleOperatorSelector: React.FC<RuleCommonProps> = ({ rule, r_index, view }) => (
  <input
    type={"hidden"} name={`conditions[${r_index}].operator`}
    value={DynamicLabelCriterias[rule.field].operators[0]}
  />
);
