import { useAppSelector } from "../../../../app/hooks";
import { getCurrentFeed } from "../../../../app/slices/app";
import Chart, { ChartDataset } from "chart.js/auto";
import style from "../../../../assets/scss/variables.module.scss";
import { useEffect } from "react";
import { Icon } from "../../../App/Blocks/Misc";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import { shopHasProducts } from "../../../../app/slices/shop";

const ScoreChart = () => {

  const id = "score-chart";

  const currFeed = useAppSelector(getCurrentFeed);
  const hasProducts = useAppSelector(shopHasProducts);
  const score = hasProducts ? ((
    currFeed?.last_score?.google
      ? currFeed?.last_score?.google
      : currFeed?.last_score?.feedcast
      || 0) / 10) : 8.3;

  const theme = useTheme();
  useEffect(() => {
    if (score !== null) {
      const cht = newScoreChart(id, scoreChartData(score, theme));
      return () => {
        cht.destroy();
      }
    }
  }, [ score ]);

  return <Box display={"flex"} justifyContent={"end"}>
    <Box position={"relative"}>
      <canvas
        id={id}
        style={{
          maxHeight: "150px",
          maxWidth: "150px",
        }}
      />
      <Box>
        <Typography
          color={"primary.main"}
          style={{
            position: "absolute",
            fontSize: "3rem",
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)",
          }}
        >
          {score && score < 10
            ? score?.toPrecision(2)
            : !score
              ? 0
              : <Icon icon={"check"} className={"text-success"}/>}
        </Typography>
      </Box>
    </Box>
  </Box>
}

const newScoreChart = (id: string, data: any) => {
  return new Chart(id, {
    type: "doughnut",
    data: data,
    options: {
      cutout: 60,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
        }
      }
    }
  });
}

const scoreChartData = (score: number, theme: Theme) => {

  const labels = [ "Score" ];
  const color = score < 5
    ? theme.palette.error.main
    : theme.palette.secondary.main;

  const datasets: ChartDataset<"doughnut", number[]>[] = [
    {
      label: '%',
      data: [ parseFloat(score.toPrecision(2)), 10 - score ],
      backgroundColor: [
        color,
        "#FFFFFF",
      ],
      borderWidth: 0,
    },
  ];

  return { labels, datasets }
}

export default ScoreChart;
