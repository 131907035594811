import { Autocomplete, Box, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';

import Locale from '../../../../components/App/Blocks/Locale';
import { countries, fieldAnimation, languages } from '../../utils';

interface CountryLanguageSelectorProps {
   formValues: any;
   handleChange: (name: string, value: any) => void;
   errors: any;
}

const CountryLanguageSelector = ({ formValues, handleChange, errors }: CountryLanguageSelectorProps) => {
   return (
      <Box sx={{ display: 'flex', gap: 2 }}>
         <motion.div
            {...fieldAnimation}
            transition={{ ...fieldAnimation.transition, delay: 0.3 }}
            style={{ flex: 1 }}
         >
            <Autocomplete
               options={countries}
               getOptionLabel={(option) => option.name}
               value={countries.find(c => c.code === formValues['feed[country]']) || null}
               onChange={(_, newValue) => handleChange('feed[country]', newValue?.code || '')}
               renderOption={(props, option) => (
                  <li {...props}>
                     <Locale locale={option.code.toLowerCase()} style={{ marginRight: 8 }} />
                     {option.name}
                  </li>
               )}
               renderInput={(params) => (
                  <TextField
                     {...params}
                     label={<FormattedMessage id="country" />}
                     error={!!errors['feed[country]']}
                     helperText={errors['feed[country]']}
                     required
                     InputProps={{
                        ...params.InputProps,
                        startAdornment: formValues['feed[country]'] && (
                           <Locale
                              locale={formValues['feed[country]'].toLowerCase()}
                              style={{ marginRight: 8 }}
                           />
                        ),
                     }}
                  />
               )}
            />
         </motion.div>
         <motion.div
            {...fieldAnimation}
            transition={{ ...fieldAnimation.transition, delay: 0.4 }}
            style={{ flex: 1 }}
         >
            <Autocomplete
               options={languages}
               getOptionLabel={(option) => option.name}
               value={languages.find(l => l.code === formValues['feed[language]']) || null}
               onChange={(_, newValue) => handleChange('feed[language]', newValue?.code || '')}
               renderOption={(props, option) => (
                  <li {...props}>
                     <Locale locale={option.code.toLowerCase()} style={{ marginRight: 8 }} />
                     {option.name}
                  </li>
               )}
               renderInput={(params) => (
                  <TextField
                     {...params}
                     label={<FormattedMessage id="language" />}
                     error={!!errors['feed[language]']}
                     helperText={errors['feed[language]']}
                     required
                     InputProps={{
                        ...params.InputProps,
                        startAdornment: formValues['feed[language]'] && (
                           <Locale
                              locale={formValues['feed[language]'].toLowerCase()}
                              style={{ marginRight: 8 }}
                           />
                        ),
                     }}
                  />
               )}
            />
         </motion.div>
      </Box>
   )
}

export default CountryLanguageSelector;