import { GetCurrentFeedId } from "../../../../app/utils";
import productsApi from "../../../../app/services/secured/products";
import { FormattedMessage } from "react-intl";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import { useAppDispatch } from "../../../../app/hooks";
import { modalShowProductCorrection } from "../../../../app/slices/modal";
import React, { useMemo, useState } from "react";
import { GoogleAttribute, ProductErrorEnhanced } from "../../../../app/types/products";
import { redirect, setProductErrorId } from "../../../../app/slices/app";
import ModalErrorCorrection from "./ModalErrorCorrection";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack, ToggleButton, ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import {
  BlockRounded,
  ExpandMore, Facebook, Google,
  Healing,
  HelpOutline, Microsoft,
  PriorityHigh,
  ProductionQuantityLimits,
  SellOutlined,
  TableView, WarningAmberRounded,
  WarningRounded
} from "@mui/icons-material";
import Urls from "../../../../assets/js/Urls";
import { ChannelsData } from "../../../../app/slices/campaign/constants";
import { ChannelsIds } from "../../../../app/types/feed";

const ChannelErrors = () => {
  const feed = GetCurrentFeedId();
  const rep = productsApi.useGetProductReportingQuery({ feed });
  const channelsErrors = rep.data?.errors ?? [];

  const hasErrors = Boolean(channelsErrors.length);

  const [ sourceId, setSourceId ] = useState<ChannelsIds | "all">("all");
  const handleSourceChange = (e: any, val: ChannelsIds | "all") => val !== null && setSourceId(val);

  const [ blocking, setBlocking ] = useState<"all" | 0 | 1>("all");
  const handleBlockingChange = (e: any, val: 'all' | 0 | 1) => val !== null && setBlocking(val);

  const component = useMemo(() => channelsErrors
  .filter(c => sourceId !== "all" ? c.source_id.toString() === sourceId.toString() : true)
  .map(channel => (
    <React.Fragment>
      {[ ...channel.errors ]
      .filter(error => blocking !== "all" ? error.is_blocking === Boolean(blocking) : true)
      .sort((a) => a.is_blocking ? -1 : 1)
      .map((e, i) => (
        <ErrorItem
          {...e as ProductErrorEnhanced}
          source={channel.source_id}
        />
      ))}
    </React.Fragment>
  )), [ channelsErrors, sourceId, blocking ]);

  return (
    <Stack spacing={2} className={"feed-errors"}>
      {/*<FCardHeader*/}
      {/*  margin={false}*/}
      {/*  icon={<ProductionQuantityLimits fontSize={"large"}/>}*/}
      {/*  title={"catalog-errors-recap"}*/}
      {/*  desc={"catalog-errors-recap-desc"}*/}
      {/*  action={(*/}
      {/*    */}
      {/*  )}*/}
      {/*/>*/}
      <Box sx={{ display: "flex", gap: 5 }}>
        <Box>
          <Typography variant={"overline"} color={"text.secondary"} display={"block"}>
            Source
          </Typography>
          <ToggleButtonGroup
            value={sourceId}
            onChange={handleSourceChange}
            exclusive
          >
            <ToggleButton value={"all"}>
              <FormattedMessage id={'all'}/>
            </ToggleButton>
            <ToggleButton value={ChannelsIds.Google}>
              <Google/>
            </ToggleButton>
            <ToggleButton value={ChannelsIds.Meta}>
              <Facebook/>
            </ToggleButton>
            <ToggleButton value={ChannelsIds.Microsoft}>
              <Microsoft/>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography variant={"overline"} color={"text.secondary"} display={"block"}>
            Type
          </Typography>
          <ToggleButtonGroup
            exclusive
            value={blocking}
            onChange={handleBlockingChange}
          >
            <ToggleButton value={"all"}>
              <FormattedMessage id={'all'}/>
            </ToggleButton>
            <ToggleButton value={0}>
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <WarningAmberRounded/>
                <FormattedMessage id={'warning'}/>
              </Box>
            </ToggleButton>
            <ToggleButton value={1}>
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <BlockRounded/>
                <FormattedMessage id={'error'}/>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box>
        {hasErrors ? component : <NoErrors/>}
      </Box>
      <ModalErrorCorrection/>
    </Stack>
  )
}

const NoErrors = () => (
  <FCard>
    <Typography gutterBottom>
      <FormattedMessage id={"no-feed-errors"}/>
    </Typography>
    <Typography variant={"body2"} color={"text.secondary"}>
      <FormattedMessage id={"no-feed-errors-desc"}/>
    </Typography>
    <Typography variant={"body2"} color={"text.secondary"}>
      <FormattedMessage id={"no-feed-errors-desc-2"}/>
    </Typography>
  </FCard>
)

const ErrorItem = (e: ProductErrorEnhanced & { source: ChannelsIds }) => {
  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const setStateError = () => {
    dispatch(setProductErrorId({ id: e.id, is_blocking: e.is_blocking }));
    dispatch(redirect(Urls.products.list(feed)));
  }

  const showModalCorrection = () => dispatch(modalShowProductCorrection({
    errorId: e.id,
    field: e.field as GoogleAttribute ?? null,
    header: e.header ?? "",
    desc: e.body ?? "",
    company: ChannelsData[e.source].company,
  }))

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore fontSize={"large"}/>}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={'100%'}>
          <Stack direction={"row"} alignItems={"center"} gap={2} lineHeight={1}>
            <Box>
              <img src={ChannelsData[e.source].image} height={'20px'}/>
            </Box>
            {e.is_blocking
              ? (<Tooltip arrow placement={"top"} title={<FormattedMessage id={'is-blocking'}/>}>
                <PriorityHigh color={"error"}/>
              </Tooltip>)
              : <WarningRounded color={"warning"}/>
            }
            <Typography>
              <FormattedMessage
                id={e.header ?? "header"}
                values={{ attribute: e.attribute ? <FormattedMessage id={e.attribute}/> : "" }}
              />
            </Typography>
          </Stack>
          <Box display={'flex'} gap={1} alignItems={"center"} mr={1}>
            <Typography variant={"body2"} color={"text.secondary"}>
              {e.nb}
            </Typography>
            <SellOutlined fontSize={"small"} color={"disabled"}/>
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          width={'100%'}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack alignItems={"center"} direction={"row"}>
            <FormattedMessage
              id={e.body ?? "body"}
              values={{ attribute: e.attribute ? <FormattedMessage id={e.attribute}/> : "" }}
            />
            <IconButton
              target={'_blank'}
              href={e.help?.FR ?? ""}
              size={'small'}
              disableRipple
            >
              <HelpOutline/>
            </IconButton>
          </Stack>
          <Box>
            {e.field
              ? <Button
                color={"warning"}
                onClick={showModalCorrection}
                startIcon={<Healing/>}
                variant={"contained"}
                disabled={e.source === ChannelsIds.Microsoft}
              >
                <FormattedMessage id={"correct"}/>
              </Button>
              : <Button
                color={"primary"}
                onClick={setStateError}
                startIcon={<TableView/>}
                variant={"contained"}
              >
                <FormattedMessage id={"show-concerned-products"}/>
              </Button>}
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default ChannelErrors;
