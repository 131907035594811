import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { resetCampaign } from "../../../../../app/slices/campaign/campaign";
import { useParams } from "react-router-dom";
import campaignApi from "../../../../../app/services/secured/campaign";
import { useEffect } from "react";
import { clearNotifications, NewSuccessNotification } from "../../../../../app/slices/notifications";
import { redirect, setLastEditedCampaign, setNavWasDisabled } from "../../../../../app/slices/app";
import Urls from "../../../../../assets/js/Urls";
import { PageParams } from "../_Campaign";
import { getCampaign, getCampaignApi } from "../../../../../app/slices/campaign/getters";
import { useDraftFunctions } from "./useCampaignDraft";

export const useHandleCampaign = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const { provider, campaignId } = useParams<PageParams>();

  const campaign = useAppSelector(getCampaign);
  const cApi = useAppSelector(getCampaignApi);

  const { saveDraft, clearDraft } = useDraftFunctions({ campaignId });

  const [ createCampaign, createCampaignRes ] = campaignApi.useCreateCampaignMutation();
  const [ updateCampaign, updateCampaignRes ] = campaignApi.useSetCampaignMutation();

  const handleData = { feed, provider: provider as string, data: cApi };

  const handleCampaign = () => {
    campaignId
      ? updateCampaign({ ...handleData, campaign: campaignId })
      : createCampaign(handleData)
    ;
  };

  useEffect(() => {
    if (createCampaignRes.isSuccess || updateCampaignRes.isSuccess) {
      dispatch(clearNotifications());
      const msg = createCampaignRes.isSuccess ? 'success-creation-campaign' : 'success-edition-campaign';
      dispatch(NewSuccessNotification(msg));
      onSuccess();
    }
  }, [ createCampaignRes.isSuccess, updateCampaignRes.isSuccess ]);

  const onSuccess = () => {
    dispatch(setNavWasDisabled(true));
    dispatch(redirect(Urls.campaigns.list2(feed)));
    clearDraft();
    dispatch(campaignApi.util.invalidateTags([ 'Campaigns' ]));
    dispatch(setLastEditedCampaign(campaign));
    setTimeout(() => dispatch(resetCampaign()), 700);
  };

  const loadingApi = createCampaignRes.isLoading || updateCampaignRes.isLoading;

  return [ createCampaignRes || updateCampaignRes, handleCampaign, saveDraft, loadingApi ] as const;
};
