import Grid2 from "@mui/material/Unstable_Grid2";
import img_fb from "../../../../../assets/img/icons/facebook.svg";
import img_meta from "../../../../../assets/img/icons/meta.svg";
import img_messenger from "../../../../../assets/img/icons/messenger.svg";
import img_instagram from "../../../../../assets/img/icons/instagram.svg";
import React from "react";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { setMetaPlatforms } from "../../../../../app/slices/campaign/campaign";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { FormattedMessage } from "react-intl";
import { getMetaPlatforms } from "../../../../../app/slices/campaign/getters";
import { MetaPlatform } from "../../../../../app/types/campaign";
import { getAppMode } from "../../../../../app/slices/app";

/**
 * MetaClassicPlatforms.tsx
 * Controls which platform to diffuse onto
 */

export const MetaClassicPlatforms = () => {
  const platforms = useAppSelector(getMetaPlatforms);
  const fb = platforms.includes("facebook"),
    audience = platforms.includes("audience_network"),
    messenger = platforms.includes("messenger"),
    instagram = platforms.includes("instagram");
  return (
    <Stack spacing={1}>
      <Typography variant={"subtitle2"}>
        <FormattedMessage id={'meta-classic-platforms'} />
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs>
          <PlatformLabel title={"facebook"} img={img_fb} active={fb}/>
        </Grid2>
        <Grid2 xs>
          <PlatformLabel title={"audience_network"} img={img_meta} active={audience}/>
        </Grid2>
        <Grid2 xs>
          <PlatformLabel title={"messenger"} img={img_messenger} active={messenger}/>
        </Grid2>
        <Grid2 xs>
          <PlatformLabel title={"instagram"} img={img_instagram} active={instagram}/>
        </Grid2>
      </Grid2>
    </Stack>
  )
}
type PlatformLabelProps = {
  title: MetaPlatform,
  img: string,
  active: boolean,
};
const PlatformLabel = (p: PlatformLabelProps) => {
  const dispatch = useAppDispatch();
  const togglePlatform = () => dispatch(setMetaPlatforms(p.title));
  const mode = useAppSelector(getAppMode);
  return <Box>
    <FormControlLabel
      className={"bg-gray border-full rounded"}
      sx={{
        width: '100%',
        p: 1,
        px: 2,
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        backgroundColor: (theme) => p.active ? mode === "light" ? 'primary.light' : theme.palette.background.paper : undefined
      }}
      control={<Checkbox
        onChange={togglePlatform}
        checked={p.active}
        color={p.active ? "secondary" : "primary"}
      />}
      label={<Box display={"flex"} alignItems={"center"}>
        <img style={{ width: '15px' }} src={p.img}/>
        <Typography variant={'subtitle1'} ml={1}>
          <FormattedMessage id={p.title}/>
        </Typography>
      </Box>}
      labelPlacement={"start"}
    />
  </Box>
}
