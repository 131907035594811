import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { ProjectType } from "../../types/feed";

const projectsApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getProjects: build.query<Array<ProjectType>, void>({
      query: () => ApiUrls.projects.list,
      providesTags: [ 'Projects' ],
    }),
    getProject: build.query<ProjectType, { id: number }>({
      query: (params) => ApiUrls.projects.edit(params.id)
    }),
    newProject: build.mutation<ProjectType, { name: string }>({
      query: (body) => ({
        url: ApiUrls.projects.list,
        body,
        method: 'POST',
      }),
      invalidatesTags: [ 'Projects' ],
    }),
    editProject: build.mutation<ProjectType, { project: number, name: string }>({
      query: (body) => ({
        url: ApiUrls.projects.edit(body.project),
        body: { name: body.name },
        method: 'POST',
      }),
      invalidatesTags: [ "Projects" ],
    }),
    editFeedProject: build.mutation<any, { feed: number, project: number }>({
      query: (body) => ({
        url: ApiUrls.feeds.info(body.feed),
        body: {
          project: body.project,
        },
        method: 'POST'
      }),
      invalidatesTags: [ 'Feeds' ],
    })
  })
});

export default projectsApi;
