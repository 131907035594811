import securedApi from "./securedApi";
import { ApiSuccess } from "../../../../types";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { AccountStatusData, AssetType, MerchantStatusData, UserAdsAccount } from "../../types/feed";
import {
  AdsAccountRes, AdsProvider,
  Behaviour, CampaignApi,
  CampaignsApi, CampaignType, CreateAdsAccountApi,
  Interest, LinkAdsAccountApi,
  MetaCatalog,
  SetCampaignParams
} from "../../types/campaign";
import { StorageManager } from "../../storage";
import genCampaigns from "../../demo/genCampaigns";

const campaignApi = securedApi.injectEndpoints({
  endpoints: build => ({
    linkAdsAccount: build.mutation<AdsAccountRes, LinkAdsAccountApi>({
      query: (data) => ({
        url: ApiUrls.feeds.channels.link(data.feed, data.provider, data.account_code),
        method: 'POST',
      }),
      invalidatesTags: [ "Feed", "AccountStatus", "Campaigns", "Campaign" ]
    }),
    createAdsAccount: build.mutation<AdsAccountRes, CreateAdsAccountApi>({
      query: (data) => ({
        url: ApiUrls.feeds.channels.create(data.feed, data.provider),
        method: 'POST',
        body: data.data,
      }),
      invalidatesTags: [ "Feed", "UserFeed" ]
    }),
    getCampaign: build.query<CampaignApi, { feed: number, provider: AdsProvider, campaign: string }>({
      query: (params) => ({
        url: ApiUrls.feeds.campaigns.edit(params.feed, params.provider, params.campaign),
      }),
      transformResponse(c: CampaignApi) {
        if (c.type === CampaignType.META_CLASSIC) {
          const adsets = [ ...c.adsets ] ?? [];
          adsets.forEach(a => a.ads.forEach(aa => aa.creative = {
            name: aa.creative.name, link: aa.creative.link, page: aa.creative.page
          }));
          c.adsets = adsets;
        }
        return c;
      },
      providesTags: (res, error, arg) =>
        res?.id ? [ { type: 'Campaign', id: res.id } ] : [ "Campaign" ],
    }),
    getCampaigns: build.query<CampaignsApi, { feed: number, provider: AdsProvider }>({
      query: (params) => ApiUrls.feeds.campaigns.list(params.feed, params.provider),
      transformResponse(res: CampaignsApi) {

        if (StorageManager.getIsDemo()) {
          return genCampaigns(20);
        }

        const sorted = { ...res };
        sorted.campaigns = (
          sorted.campaigns
          .sort((a, b) => parseInt(b.id as string) - parseInt(a.id as string))
          .filter(c => c.type > 0)
        );

        return sorted;
      },
      providesTags: [ "Campaigns" ],
    }),
    createCampaign: build.mutation<CampaignApi, Omit<SetCampaignParams, 'campaign'>>({
      query: (body) => ({
        url: ApiUrls.feeds.campaigns.list(body.feed, body.provider),
        method: 'POST',
        body: body.data,
      }),
      invalidatesTags: [ "Campaigns" ]
    }),
    setCampaign: build.mutation<CampaignApi, SetCampaignParams>({
      query: (params) => ({
        url: ApiUrls.feeds.campaigns.edit(params.feed, params.provider, params.campaign),
        method: 'POST',
        body: params.data,
      }),
      invalidatesTags: (res, error, arg) => error ? [] : [ { type: "Campaign", id: arg.campaign } ]
    }),
    setCampaignBudget: build.mutation<CampaignApi, SetCampaignParams>({
      query: (params) => ({
        url: ApiUrls.feeds.campaigns.edit(params.feed, params.provider, params.campaign),
        method: 'POST',
        body: params.data,
      }),
      invalidatesTags: (res, error, arg) => [ { type: "Campaign", id: arg.campaign } ]
    }),
    setCampaignActive: build.mutation<CampaignApi, SetCampaignParams>({
      query: (params) => ({
        url: ApiUrls.feeds.campaigns.edit(params.feed, params.provider, params.campaign),
        method: 'POST',
        body: params.data,
      }),
      invalidatesTags: (res, error, arg) => [ { type: "Campaign", id: arg.campaign } ]
    }),
    deleteCampaign: build.mutation<ApiSuccess, { feed: number, provider: AdsProvider, campaign: string }>({
      query: (params) => ({
        url: ApiUrls.feeds.campaigns.edit(params.feed, params.provider, params.campaign),
        method: 'DELETE',
      }),
      invalidatesTags: [ "Campaigns" ]
    }),
    getAccountStatus: build.query<AccountStatusData, { feed: number }>({
      query: (params) => ApiUrls.feeds.channels.status(params.feed),
      providesTags: [ "AccountStatus" ],
    }),
    getMerchantCenterPhoneValidation: build.mutation<{ request_id: string }, {
      feed: number,
      country: string,
      phone_number: string
    }>({
      query: (body) => ({
        url: ApiUrls.feeds.channels.phone(body.feed),
        method: 'POST',
        body,
      })
    }),
    setMerchantCenterPhoneValidation: build.mutation<AccountStatusData, {
      feed: number,
      request_id: string,
      code: string
    }>({
      query: (body) => ({
        url: ApiUrls.feeds.channels.phoneConfirm(body.feed),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "AccountStatus" ],
    }),
    setMerchantCenter: build.mutation<MerchantStatusData, { feed: number, data: Pick<AccountStatusData, 'merchant'> }>({
      query: (body) => ({
        url: ApiUrls.feeds.channels.merchant(body.feed),
        method: 'POST',
        body: body.data,
      }),
      invalidatesTags: [ "AccountStatus", "UserFeed" ],
    }),
    getAssets: build.query<Array<AssetType>, { feed: number }>({
      query: (params) => ApiUrls.feeds.assets(params.feed),
      providesTags: (res) =>
        res
          ? [ ...res.map(r => ({ type: "Asset" as const, id: r.id })), 'Asset' ]
          : [ "Asset" ]
    }),
    setAssets: build.mutation<Array<AssetType>, { feed: number, data: any }>({
      query: (body) => ({
        url: ApiUrls.feeds.assets(body.feed),
        method: 'POST',
        body: body.data,
      }),
    }),
    getMetaCatalog: build.query<MetaCatalog, { feed: number }>({
      query: (params) => ApiUrls.feeds.campaigns.metaData(params.feed),
      providesTags: [ "MetaCatalog" ],
    }),
    setMetaCatalog: build.mutation<ApiSuccess, { feed: number, body: { pixel_id: string, name: string } }>({
      query: (data) => ({
        url: ApiUrls.feeds.campaigns.metaData(data.feed),
        body: data.body,
        method: 'POST',
      }),
      invalidatesTags: [ "MetaCatalog", "Feed", "UserFeed" ],
    }),
    getMetaPages: build.query<{ id: string, name: string }[], { feed: number }>({
      query: (params) => ApiUrls.feeds.campaigns.metaPages(params.feed)
    }),
    getMetaBehaviours: build.query<Behaviour[], number>({
      query: (params) => ApiUrls.feeds.campaigns.metaBehaviours(params),
    }),
    getMetaInterests: build.query<Interest[], { feed: number, q: string }>({
      query: ({ feed, q }) => ({
        url: ApiUrls.feeds.campaigns.metaInterests(feed),
        params: { q: q.length ? q : " " }
      })
    }),
    getMicrosoftCenters: build.query<Array<Pick<UserAdsAccount, "id" | "name"> & { url: string }>, string | number>({
      query: (feed) => ApiUrls.feeds.channels.microsoftCenters(feed),
      providesTags: [ "MicrosoftMerchantCenter" ]
    }),
    createMicrosoftCenter: build.mutation<any, CreateMicrosoftCenterPayload>({
      query: ({ feed, ...body }) => ({
        url: ApiUrls.feeds.channels.microsoftCenters(feed),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "MicrosoftMerchantCenter" ],
    }),
    linkMicrosoftCenter: build.mutation<ApiSuccess, LinkMicrosoftCenterPayload>({
      query: (params) => ({
        url: ApiUrls.feeds.channels.linkMicrosoftCenter(params.feed, params.account),
        method: 'POST',
      }),
      invalidatesTags: [ "Feed", "AccountStatus", "Campaigns", "Campaign", "MicrosoftMerchantCenter" ]
    }),
    enableChannel: build.mutation<any, { feed: number, provider: AdsProvider, enable: boolean }>({
      query: (params) => ({
        url: ApiUrls.feeds.channels.enableChannel(params.feed, params.provider),
        method: params.enable ? "POST" : "DELETE",
      }),
      invalidatesTags: [ "Feed", "AccountStatus", "Campaigns", "Campaign", "MicrosoftMerchantCenter", "MetaCatalog", "UserFeed" ]
    }),
  }),
});

export default campaignApi;

export type CreateMicrosoftCenterPayload = {
  feed: string | number,
  name: string,
  url: string,
  email: string,
}

export type LinkMicrosoftCenterPayload = {
  feed: string | number,
  account: string | number,
}
