import { useAppSelector } from "../../../../../app/hooks";
import { getUser } from "../../../../../app/slices/app";
import feedApi from "../../../../../app/services/secured/feed";
import { useEffect } from "react";

export const useSubscription = (feedId: number) => {

  const me = useAppSelector(getUser);

  const [ getFeedApi, getFeed ] = feedApi.useLazyGetFeedQuery();
  useEffect(() => {
    if (feedId && feedId > 0) {
      getFeedApi(feedId, true);
    }
  }, [ feedId ]);

  const [ getSub, sub ] = feedApi.useLazyGetSubscriptionQuery();

  useEffect(() => {
    if (me?.can_subscribe && feedId > 0) {
      getSub(feedId, true);
    }
  }, [ me, feedId ]);

  return [
    sub,
    getFeed
  ] as const;
}
