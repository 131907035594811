import securedApi from "./securedApi";
import { Log } from "../../types/profile";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import dayjs from "dayjs";
import { ApiSuccess } from "../../../../types";
import { CampaignsNotificationsData, LogType } from "../../slices/app_notifications";

const logsApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getLogs: build.query<Log[], void>({
      query: () => ApiUrls.user.logs,
    }),
    getServerMessages: build.query<any, void>({
      query: () => ApiUrls.user.messages,
    }),
    clearMessage: build.mutation<ApiSuccess, string | number>({
      query: (params) => ({
        url: ApiUrls.user.clearMessage(params),
        method: 'POST',
      }),
      invalidatesTags: [ "NotificationsHistory" ],
    }),
    getNotificationsHistory: build.query<Log[], void>({
      query: () => ({
        url: ApiUrls.user.logs,
        params: {
          status: "1",
          from: dayjs().subtract(3, 'weeks').toDate().toISOString().split("T")[0],
        }
      }),
      providesTags: [ "NotificationsHistory" ],
    }),
    getInAppIssues: build.query<Log[], void>({
      query: () => ({
        url: ApiUrls.user.logs,
        params: {
          from: dayjs().toISOString().split("T")[0],
          type_id: `${LogType.FEED_DOWNLOAD_ERROR},${LogType.MERCHANT_CENTER}`,
        }
      })
    }),
    getCampaignsLogs: build.query<Log<CampaignsNotificationsData>[], { feed: number }>({
      query: ({ feed }) => ({
        url: ApiUrls.user.logs,
        params: {
          type_id: `${LogType.CAMPAIGN_NO_CONVERSIONS}, ${LogType.CAMPAIGN_LOW_ROI}`,
          feed_ids: feed,
        }
      })
    })
  })
})

export default logsApi;
