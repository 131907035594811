import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setAge, setMetaGenders } from "../../../../../app/slices/campaign/campaign";
import { Box, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { getMetaAge, getMetaGender } from "../../../../../app/slices/campaign/getters";

/**
 * MetaClassicData.tsx
 * Age range, genre
 * TODO : Slider age range ?
 */

export const MetaClassicData = () => {
  const dispatch = useAppDispatch();
  const age = useAppSelector(getMetaAge);
  const gender = useAppSelector(getMetaGender) ?? [];
  const handleGender = (genders: number[]) => dispatch(setMetaGenders(genders));
  return (
    <Stack spacing={1}>
      <Typography variant={"subtitle2"}>
        Rules
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 xs={4}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={"Age min."}
              required
              value={age.age_min}
              type={"number"}
              onChange={(e) => dispatch(setAge({ ...age, age_min: parseInt(e.target.value) }))}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}>
                  <PersonRemove/>
                </InputAdornment>
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={4}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={"Age max."}
              required
              value={age.age_max}
              type={"number"}
              onChange={(e) => dispatch(setAge({ ...age, age_max: parseInt(e.target.value) }))}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}>
                  <PersonAdd/>
                </InputAdornment>
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={4}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              label={<FormattedMessage id={"gender"}/>}
              select
              required
              value={gender ?? [ 0 ]}
              onChange={(e) => handleGender(e.target.value as unknown as number[])}
              SelectProps={{
                multiple: true,
              }}
            >
              <MenuItem value={1}><FormattedMessage id={"male"}/></MenuItem>
              <MenuItem value={2}><FormattedMessage id={"female"}/></MenuItem>
            </TextField>
          </FormControl>
        </Grid2>
      </Grid2>
    </Stack>
  )
}
