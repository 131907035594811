import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useIntl } from "react-intl";
import { getReportingPeriod, ReportingPeriod, ReportingPeriodString, setPeriod } from "../../../app/slices/reporting";
import { Litepicker } from "litepicker";
import "litepicker/dist/plugins/ranges";
import { Box, Button, Paper } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { getLanguage } from "../../../app/slices/app";

const DatePicker = ({ margin = true }: { margin?: boolean }) => {
  const dispatch = useAppDispatch(),
    period = useAppSelector(getReportingPeriod),
    intl = useIntl()
  ;

  const language = useAppSelector(getLanguage);

  let init = false;
  const customRanges = {
    [intl.formatMessage({ id: "datepicker-today" })]: DatePeriod.Today,
    [intl.formatMessage({ id: "datepicker-yesterday" })]: DatePeriod.Yesterday,
    [intl.formatMessage({ id: "datepicker-this-week" })]: DatePeriod.ThisWeek,
    [intl.formatMessage({ id: "datepicker-this-month" })]: DatePeriod.ThisMonth,
    [intl.formatMessage({ id: "datepicker-previous-month" })]: DatePeriod.PreviousMonth,
    [intl.formatMessage({ id: "datepicker-this-year" })]: DatePeriod.ThisYear,
    [intl.formatMessage({ id: "datepicker-last-year" })]: DatePeriod.LastYear,
  };

  const callback = useCallback(() => {
    if (!init) {
      const l = new Litepicker({
        element: document.getElementById("litepicker") as any,
        singleMode: false,
        plugins: [ "ranges" ],
        lang: `${language}-${language.toUpperCase()}`,
        ranges: {
          customRanges,
        },
        numberOfColumns: 2,
        numberOfMonths: 2,
        position: "bottom center",
        maxDate: new Date(),
        setup: picker => {
          picker
          .on("selected", () => {
            // @ts-ignore
            const from = picker.getStartDate().toJSDate();
            // @ts-ignore
            const to = picker.getEndDate().toJSDate();

            from.setHours(new Date().getHours());
            to.setHours(new Date().getHours());

            dispatch(setPeriod(ReportingPeriodToString([ from, to ])));
          })
        },
      });
      init = true;

      return () => {
        l.destroy();
      }
    }
  }, []);

  useEffect(() => {
    callback();
  }, []);

  const periods = {
    from: period[0].toLocaleDateString("fr-FR", { day: "2-digit", month: "short", year: "numeric" }),
    to: period[1].toLocaleDateString("fr-FR", { day: "2-digit", month: "short", year: "numeric" })
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: margin ? 2 : 0 }}>
      <Button
        // variant={"outlined"}
        id={"litepicker"}
        startIcon={<DateRange />}
      >
        {`${periods.from} - ${periods.to}`}
      </Button>
    </Box>
  )
};

/** Functions */

const addDays = (date: Date, days: number): Date => {
  const dateOffset = new Date();
  dateOffset.setTime(date.getTime() + days * 86400 * 1000);

  return dateOffset;
}

const today = (): ReportingPeriod => ([ new Date(), new Date() ]);

const yesterday = (): ReportingPeriod => ([ addDays(new Date(), -1), addDays(new Date(), -1) ]);

const thisMonth = (): ReportingPeriod => {
  let to = new Date();
  to = new Date(to.setHours(23, 59, 59));

  let from = new Date(new Date().setDate(to.getDate() - 30));

  return [ from, to ];
};

const previousMonth = (): ReportingPeriod => {
  const period: ReportingPeriod = thisMonth();

  // Sub 1 day to go to previous month last day
  period[0] = addDays(period[0], -1);
  period[1] = new Date(period[1].setDate(1));

  // Go to beginning of previous month
  period[0].setDate(1);

  return period;
}

const thisYear = (): ReportingPeriod => {
  const period = today();
  period[0] = new Date(`${new Date().getFullYear()}-01-01`);

  return period;
}

const lastYear = (): ReportingPeriod => {
  const period = thisYear();
  const start = new Date((period[0].getFullYear() - 1) + "-01-01");
  const end = new Date((period[0].getFullYear() - 1) + "-12-31");
  return [ start, end ]
}

const thisWeek = (): ReportingPeriod => {
  let to = new Date();
  to = new Date(to.setHours(23, 59, 59));

  let from = new Date(new Date().setDate(to.getDate() - 7));

  return [ from, to ];
}

type Periods = "Today" | "Yesterday" | "ThisYear" | "ThisMonth" | "PreviousMonth" | "ThisWeek" | "LastYear"

export const DatePeriod: Record<Periods, ReportingPeriod> = {
  Today: today(),
  Yesterday: yesterday(),
  ThisYear: thisYear(),
  ThisMonth: thisMonth(),
  PreviousMonth: previousMonth(),
  ThisWeek: thisWeek(),
  LastYear: lastYear(),
};

export const ReportingPeriodToString = (period: ReportingPeriod): ReportingPeriodString => ([
  period[0].toISOString().substring(0, 10),
  period[1].toISOString().substring(0, 10),
])

export const ReportinPeriodFromString = (period: ReportingPeriodString): ReportingPeriod => ([
  new Date(period[0]),
  new Date(period[1]),
])

export default React.memo(DatePicker);
