import { useSearchParams } from "react-router-dom";
import { CompanyLogo, LoadingDiv } from "../../Blocks/Misc";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { redirect } from "../../../../app/slices/app";
import { NewDangerNotification, NewSuccessNotification } from "../../../../app/slices/notifications";
import profileApi, { getOauth, oauthRedirect, saveOauth } from "../../../../app/services/secured/profile";
import AppPublic from "../AppPublic";
import { GetCurrentFeedId } from "../../../../app/utils";
import { FormattedMessage } from "react-intl";
import Urls from "../../../../assets/js/Urls";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Company } from "../../../../app/types/campaign";

const Oauth = () => {
  const dispatch = useAppDispatch();

  const [ searchP, setSearchP ] = useSearchParams();
  const { feed, url, company } = getOauth();

  const [ setGoogle, setGoogleRes ] = profileApi.useSetGoogleLinkMutation();
  const [ setMeta, setMetaRes ] = profileApi.useSetMetaLinkMutation();
  const [ setMicrosoft, setMicrosoftRes ] = profileApi.useSetMicrosoftOauthMutation();

  const code = searchP.get("code");
  const error = searchP.get("error");

  let once = false;

  useEffect(() => {
    if (code && company && !once) {
      const callbackObj = { code, redirect_url: oauthRedirect };
      switch (company) {
        case "google":
          setGoogle(callbackObj);
          break;
        case "meta":
          setMeta(callbackObj);
          break;
        case "microsoft":
          setMicrosoft(callbackObj);
          break;
        default:
          break;
      }
    } else if (error && !once) {
      dispatch(NewDangerNotification("error-oauth"));
      dispatch(redirect(Urls.configuration(feed)));
    }

    once = true;
  }, [ code, company, once ]);

  useEffect(() => {
    if (setGoogleRes.isSuccess || setMetaRes.isSuccess || setMicrosoftRes.isSuccess) {
      dispatch(NewSuccessNotification("Succès liaison de votre compte " + company.toLocaleUpperCase()));
      url && dispatch(redirect(url));
    }
  }, [ setGoogleRes.isSuccess, setMetaRes.isSuccess, setMicrosoftRes.isSuccess ]);

  return <Box
    sx={{
      height: '100vh',
      width: '100vw',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Stack alignItems={"center"}>
      <LoadingDiv size={"lg"}/>
      <Typography variant={"h6"} mt={1}>
        <FormattedMessage id={"linking"}/>
      </Typography>
    </Stack>
  </Box>
}

type OauthProps = {
  // url: string,
  company: Company,
}

export const initOauth = (feed: number, company: Company, url: string) => {
  if (feed > 0) {
    saveOauth(feed, company);
    window.location.href = url;
  }
}

// export const OauthBtn = (company: Company) => {
//   const feed = GetCurrentFeedId();
//   const google = profileApi.useGetGoogleAccountsQuery({ redirect_url: oauthRedirect });
//   const meta = profileApi.useGetMetaAccountsQuery({ redirect_url: oauthRedirect });
//   const url = company === "google" ? google.data?.install_link : meta.data?.install_link;
//   const beginOauth = () => {
//     feed > 0 && saveOauth(feed, company);
//     window.location.href = url as string;
//   }
//   return <Button
//     color={"secondary"}
//     variant={"contained"}
//     onClick={() => beginOauth()}
//     style={{ whiteSpace: "nowrap" }}
//   >
//     <FormattedMessage id={"configure-canal"}/>
//   </Button>
// }

export const useOAuth = (company: Company) => {
  const feed = GetCurrentFeedId();
  const google = profileApi.useGetGoogleAccountsQuery({ redirect_url: oauthRedirect });
  const meta = profileApi.useGetMetaAccountsQuery({ redirect_url: oauthRedirect });
  const microsoft = profileApi.useGetMicrosoftAccountsQuery({ redirect_url: oauthRedirect });

  const url = company === "google"
    ? google.data?.install_link
    : company === "meta"
      ? meta.data?.install_link
      : microsoft.data?.install_link;

  const beginOauth = () => {
    feed > 0 && saveOauth(feed, company);
    window.location.href = url as string;
  }
  return beginOauth;
}

export default Oauth;
