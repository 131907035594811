import { AllProductStatus, Product, ProductErrorEnhanced, ProductStatus } from "../types/products";
import { Box, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Block, CheckCircle, ErrorOutline, LinkOff, PendingActions, WarningAmberRounded } from "@mui/icons-material";
import React, { ReactNode } from "react";
import { Company } from "../types/campaign";
import { ChannelsIds } from "../types/feed";
import { capitalizeFirstLetter } from "../utils";
import { MUIColor } from "../../components/App/Blocks/Misc";

//=========
// TYPES
//=========

export type CompanyStatus = {
  company: Company,
  source_id: ChannelsIds,
  status: ProductStatus,
  errors: ProductErrorEnhanced[],
  combinedStatuses: ProductStatusString[],
}

export type ProductStatusString = "PENDING" | "WARNING" | "ERROR" | "ONLINE" | "MANUALLY_CORRECTED" | "OFFLINE";

type StatusObjectRecordObj = {
  status: ProductStatusString,
  tooltip: ReactNode,
  icon: ReactNode,
  message: ReactNode,
  color: MUIColor,
}

//=========
// CONSTANTS
//=========

export const StatusObjectRecord: Record<ProductStatusString, StatusObjectRecordObj> = {
  PENDING: {
    status: 'PENDING',
    tooltip: (
      <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={'pending-validation'}/>}>
        <PendingActions color={"info"}/>
      </Tooltip>
    ),
    icon: (
      <PendingActions color={"info"} fontSize={"small"}/>
    ),
    message: (
      <FormattedMessage id={'pending-validation'}/>
    ),
    color: "info",
  },
  ONLINE: {
    status: 'ONLINE',
    tooltip: (
      <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={'product-online'}/>}>
        <CheckCircle color={"success"}/>
      </Tooltip>
    ),
    icon: (
      <CheckCircle color={"success"} fontSize={"small"}/>
    ),
    message: (
      <FormattedMessage id={'product-online'}/>
    ),
    color: "success",
  },
  MANUALLY_CORRECTED: {
    status: 'MANUALLY_CORRECTED',
    tooltip: (
      <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={"pending-new-validation"}/>}>
        <PendingActions color={"info"}/>
      </Tooltip>
    ),
    icon: (
      <PendingActions color={"info"} fontSize={"small"}/>
    ),
    message: (
      <FormattedMessage id={"pending-new-validation"}/>
    ),
    color: "info",
  },
  ERROR: {
    status: 'ERROR',
    tooltip: (
      <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={"error"}/>}>
        <Block color={"error"}/>
      </Tooltip>
    ),
    icon: (
      <ErrorOutline color={"error"} fontSize={"small"}/>
    ),
    message: (
      <FormattedMessage id={"error"}/>
    ),
    color: "error",
  },
  WARNING: {
    status: 'WARNING',
    tooltip: (
      <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={"warning"}/>}>
        <WarningAmberRounded color={"warning"}/>
      </Tooltip>
    ),
    icon: (
      <WarningAmberRounded color={"warning"} fontSize={'small'}/>
    ),
    message: (
      <FormattedMessage id={"warnings"}/>
    ),
    color: "warning",
  },
  OFFLINE: {
    status: 'OFFLINE',
    tooltip: <Box/>,
    icon: <Box/>,
    message: <Box/>,
    color: "primary",
  }
}

//=========
// FUNCTIONS
//=========

export function getProductStatus(statusCode: AllProductStatus): ProductStatusString[] {

  if (statusCode === ProductStatus.FLAG_PENDING) {
    return [ 'PENDING' ];
  }

  const statuses: ProductStatusString[] = [];

  // Check for ONLINE, but not if ERROR is present.
  if (((statusCode & ProductStatus.FLAG_ONLINE) === ProductStatus.FLAG_ONLINE) && ((statusCode & ProductStatus.FLAG_ERROR) !== ProductStatus.FLAG_ERROR)) {
    statuses.push('ONLINE');
  }

  // Check for MANUALLY_CORRECTED, but not if PENDING is present.
  if ((statusCode & ProductStatus.FLAG_PENDING_CORRECTION) === ProductStatus.FLAG_PENDING_CORRECTION) {
    statuses.push('MANUALLY_CORRECTED');
  }

  if ((statusCode & ProductStatus.FLAG_WARNING) === ProductStatus.FLAG_WARNING) {
    statuses.push('WARNING');
  }

  // Check for ERROR and WARNING.
  if ((statusCode & ProductStatus.FLAG_ERROR) === ProductStatus.FLAG_ERROR) {
    statuses.push('ERROR');
  }

  return statuses;
}

export const getProductStatusComponents = (statuses: ProductStatusString[]) => statuses.map(status => StatusObjectRecord[status]);

export const getChannelStatusDisplay = (channel: CompanyStatus) => {
  const isChannelInactive = channel.combinedStatuses.includes("OFFLINE") || !channel.combinedStatuses.length;
  const components = getProductStatusComponents(channel.combinedStatuses);

  // TODO
  const display = !isChannelInactive ? (
    channel.combinedStatuses.includes("MANUALLY_CORRECTED") ? components.filter(c => c?.status === "MANUALLY_CORRECTED") : components
  ) : [ {
    icon: <LinkOff color={"disabled"} fontSize={"small"}/>,
    message: <FormattedMessage id={"channel-offline"}/>,
    color: "text.disabled",
  } ]

  const channelName = channel.company === "google" ? (
    channel.source_id === ChannelsIds.FreeListing ? "Free Listing" : "Google Ads"
  ) : capitalizeFirstLetter(channel.company);


  const hasWarningOrError = channel.combinedStatuses.includes("ERROR") || channel.combinedStatuses.includes("WARNING");

  return ({
    isChannelInactive,
    display,
    channelName,
    hasWarningOrError,
  });
}

export const doesProductHasIssues = (product: Product): Array<Extract<ProductStatusString, "ERROR" | "WARNING">> => {
  const statuses = [
    getProductStatus(product.free_listing_status),
    getProductStatus(product.status_google),
    getProductStatus(product.status_meta),
    getProductStatus(product.status_microsoft),
  ];

  const issues: Array<Extract<ProductStatusString, "ERROR" | "WARNING">> = [];
  for (const status of statuses) {
    if (status.includes("ERROR")) {
      issues.push("ERROR");
    }
    if (status.includes("WARNING")) {
      issues.push("WARNING");
    }
  }

  return issues;
}
