import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Slider,
  TextField
} from "@mui/material";
import Cropper, { Area, Point } from "react-easy-crop";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  closeModal,
  getModalData,
  isModalOpened,
  ModalCropImageProps,
  ModalTypes
} from "../../../../../../app/slices/modal";
import DialogActions from "@mui/material/DialogActions";
import { FormattedMessage } from "react-intl";
import { Crop } from "@mui/icons-material";
import { setCroppedImage } from "../../../../../../app/slices/app";
import { ImageFormat } from "../../../../../../app/utils";
import { AspectRatio } from "./dimensions";

const getDefaultAspectRatio = (format: ImageFormat | null | undefined) => {
  if (format) {
    if (format === 'landscape') {
      return AspectRatio.Landscape;
    } else if (format === 'portrait') {
      return AspectRatio.Portrait;
    } else {
      return AspectRatio.Square;
    }
  }
  return AspectRatio.Square;
};

export default () => {

  const dispatch = useAppDispatch();
  const close = () => dispatch(closeModal());
  const open = useAppSelector(isModalOpened(ModalTypes.CropImage));
  const data = useAppSelector<ModalCropImageProps | null>(getModalData);

  const [ aspect, setAspect ] = useState<AspectRatio>(getDefaultAspectRatio(data?.format));

  const [ crop, setCrop ] = useState<Point>({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);
  const [ croppedArea, setCroppedArea ] = useState<{ area: Area, croppedAreaPixels: Area }>();
  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea({
      area: croppedArea,
      croppedAreaPixels
    })
  };

  const validateCrop = async () => {
    try {
      if (data && croppedArea) {
        const croppedImageBlob: any = await getCroppedImg(data?.url, croppedArea.croppedAreaPixels);
        if (croppedImageBlob) {
          const croppedFile = new File([ croppedImageBlob ], "croppedImage.jpeg", { type: "image/jpeg" });
          if (croppedFile) {
            dispatch(setCroppedImage(croppedFile));
            URL.revokeObjectURL(data.url);
            close();
          }
        }
      }
    } catch (e) {
      window.alert(e);
    }
  }

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth onClose={close}>
      <DialogTitle>
        <FormattedMessage id={'crop-image'}/>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ minWidth: "20%", my: 1 }}>
          <TextField
            label={<FormattedMessage id={'format'} />}
            size={'small'}
            select
            value={aspect}
            onChange={(e) => setAspect(parseFloat(e.target.value) as AspectRatio)}
          >
            <MenuItem value={AspectRatio.Landscape}><FormattedMessage id={'landscape'}/></MenuItem>
            <MenuItem value={AspectRatio.Portrait}><FormattedMessage id={'portrait'}/></MenuItem>
            <MenuItem value={AspectRatio.Square}><FormattedMessage id={'square'}/></MenuItem>
          </TextField>
        </FormControl>
        <Box sx={{ position: "relative", minHeight: '60vh' }}>
          {data && (
            <Box>
              <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: '50px' }}>
                <Cropper
                  image={data.url}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  crop={crop}
                  zoom={zoom}
                  onZoomChange={setZoom}
                  aspect={aspect}
                />
              </Box>
              <Box sx={{
                position: "absolute", bottom: 0, left: "50%", right: '50%', transform: "translate(-50%)",
                height: '40px',
                display: "flex", alignItems: "center",
                width: '70%'
              }}>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: "slider" }}
                  sx={{
                    padding: "12px 0px"
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={close}>
          <FormattedMessage id={'close'}/>
        </Button>
        <Button
          variant={"contained"}
          endIcon={<Crop/>}
          onClick={validateCrop}
        >
          <FormattedMessage id={'crop'}/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.crossOrigin = 'anonymous';
    image.src = url;
  });

const getCroppedImg = async (imageSrc: string, crop: any) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas size to match the crop area
  canvas.width = crop.width;
  canvas.height = crop.height;

  // Draw the cropped image on the canvas
  //@ts-ignore
  ctx.drawImage(
    //@ts-ignore
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height
  );

  // Convert canvas to Blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg', 1);
  });
};
