import FCard from "../../../App/Blocks/Card";
import React, { ReactNode } from "react";
import { Avatar, Box, CardHeader } from "@mui/material";
import { Globe } from "lucide-react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../app/hooks";
import { getAppMode } from "../../../../app/slices/app";

interface CampaignCardProps {
  children: any;
  icon: ReactNode;
  title: string;
  subheader?: string;
  action?: any;
  padding?: boolean;
}

const CampaignCard = ({ children, icon, title, subheader, action, padding = true }: CampaignCardProps) => {
  const mode = useAppSelector(getAppMode);
  return (
    <FCard
      variant={"no-padding"}
      sx={{
        overflow: "hidden",
      }}
    >
      <CardHeader
        action={action}
        avatar={
          <Avatar sx={{ bgcolor: "primary.main" }}>
            {icon}
          </Avatar>
        }
        title={<FormattedMessage id={title}/>}
        subheader={<FormattedMessage id={subheader ?? `${title}-desc`}/>}
        sx={{
          background: (theme) => mode === "light" ? theme.palette.primary.light : theme.palette.background.paper,
          ".MuiCardHeader-root": {
            alignItems: "center",
          }
        }}
      />
      <Box sx={{p: padding ? 2 : 0}}>
        {children}
      </Box>
    </FCard>
  )
}

export default CampaignCard;
