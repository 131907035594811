import campaignApi from "../services/secured/campaign";
import { GetCurrentFeedId, sumReducer } from "../utils";
import { useAppSelector } from "../hooks";
import { CampaignFilters, enhanceReporting, getCampaignsFilters } from "../slices/campaigns";
import { useMemo } from "react";
import { ReportingRow } from "../types/feed";
import { adsCampaigns, adsCompanies } from "../slices/campaign/constants";
import {
  AdsProvider,
  AvailableCompany,
  CampaignInterface,
  CampaignType,
  CampaignTypeIdentifier,
  Company
} from "../types/campaign";
import { useReporting } from "./useReporting";

const isCampaignTypeFiltered = (c: CampaignInterface, filters: CampaignFilters) => {
  return filters[adsCampaigns[c.type]?.identifier ?? "perf-max"] !== undefined ? filters[adsCampaigns[c.type]?.identifier ?? "perf-max"] : true;
};

const findReportingRow = (campaignId: string | null, company: Company, reporting?: ReportingRow[] | null) => {
  return reporting
  ?.filter(provider => provider.identifier === adsCompanies[company].provider)[0]?.childs
  ?.find(c => c.identifier === campaignId);
}

export const useGetCampaigns = ({ provider, reporting }: { provider: AdsProvider, reporting: ReportingRow[] }) => {
  const feed = GetCurrentFeedId();
  const filters = useAppSelector(getCampaignsFilters);

  const campaignsQuery = campaignApi.useGetCampaignsQuery({ feed, provider });
  const campaigns = campaignsQuery.data?.campaigns ?? [];

  const array = useMemo(() => {
    return [...campaigns]
    .sort((a, b) => (Number(b.id) ?? 0) - (Number(a.id) ?? 0) || (a.is_feedcast ? -1 : 1))
    .filter(c => isCampaignTypeFiltered(c, filters) && (filters.is_feedcast ? c.is_feedcast === filters.is_feedcast : true))
    .map(c => {
      const company = adsCampaigns[c.type]?.company;
      const report = company ? findReportingRow(c.id, company, reporting) : null;
      return {
        ...c,
        ...report,
      };
    }) ?? [];
  }, [ campaigns, filters, reporting ]);

  return [
    campaignsQuery,
    array
  ] as const;
}

export const useGetCampaignsData = () => {
  const reporting = useReporting();

  const initialState = { spent: 0, clicks: 0, impressions: 0, conversions: 0, conversionValue: 0 };

  const result = reporting.reduce((acc, r) => ({
    spent: acc.spent + r.spent,
    clicks: acc.clicks + r.clicks,
    impressions: acc.impressions + r.impressions,
    conversions: acc.conversions + r.conversions,
    conversionValue: acc.conversionValue + r.conversionValue
  }), initialState);

  return enhanceReporting(result);
}
