import { ProductReporting } from "../types/products";

export const genCatalogStatus = (r: Partial<ProductReporting>) => {
  const fixedTotal = 166;

  let currentErrors = Math.floor(fixedTotal * 0.1); // Start with 10% errors
  let currentWarnings = Math.floor(fixedTotal * 0.2); // Start with 20% warnings
  let currentPendings = Math.floor(fixedTotal * 0.3); // Start with 30% pendings
  let currentSuccess = fixedTotal - (currentErrors + currentWarnings + currentPendings); // Remaining

  const newHistoric = (i: number) => {
    const date = new Date();
    date.setDate(date.getDate() - i);

    const getRandomVariation = () => Math.floor(Math.random() * 11) - 5;

    currentErrors = Math.max(0, currentErrors + getRandomVariation());
    currentWarnings = Math.max(0, currentWarnings + getRandomVariation());
    currentPendings = Math.max(0, currentPendings + getRandomVariation());
    currentSuccess = fixedTotal - (currentErrors + currentWarnings + currentPendings); // Recalculate

    return {
      imported: fixedTotal,
      errors: currentErrors,
      warnings: currentWarnings,
      pendings: currentPendings,
      success: currentSuccess,
      date: date.toLocaleDateString()
    };
  };

  // const newHistoryArray = r.platforms.go?.history.map((h, i) => newHistoric(i)).reverse() ?? [];
  const defaultChannelData = {
    history: [],
    current: {
      errors: currentErrors,
      success: currentSuccess,
      total: fixedTotal,
      warnings: currentWarnings,
      pendings: currentPendings,
      last_import: fixedTotal,
    } // Initialize with default values satisfying ProductReportingChannel type
  };

  if (!r.platforms) {
    r.platforms = {};
  }

  r.platforms.go = defaultChannelData;
  r.platforms.bg = defaultChannelData;

  // Create a new array of length 10 (or whatever length you want)
  // and populate it using the newHistoric function
  const newHistoryArray = Array.from({ length: 10 }, (_, i) => newHistoric(i)).reverse();

  r.platforms.go.history = newHistoryArray;
  r.platforms.bg.history = newHistoryArray;
};
