import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getImageFormat } from "../../utils";
import { AssetType } from "../../types/feed";
import {
  AdGroup,
  AggregationsType,
  AssetGroup,
  PerfMaxPictureNode,
  AssetsTexts,
  Behaviour,
  BiddingStrategy,
  BingShopping,
  CampaignApi,
  CampaignType,
  Company,
  Interest,
  MetaCreative,
  MetaEventType,
  MetaGender,
  MetaPlatform,
  PerfMaxData, CampaignIdentifierMapper
} from "../../types/campaign";
import { adsCampaigns, MetaProspection, MetaRetargeting } from "./constants";
import { initialState } from "./initialState";
import { GetCampaignDraftNodeId } from "../../../components/Pages/Campaigns/CampaignDetail/hooks/useCampaignDraft";

const campaign = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    toggleCampaign: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
    setCampaignType: (state, action: PayloadAction<CampaignType>) => {
      state.type = action.payload;
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setCampaignBudget: (state, action: PayloadAction<number>) => {
      state.budget = action.payload;
    },
    setCampaignId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setCampaignRoas: (state, action: PayloadAction<number>) => {
      state.target_roas = action.payload;
    },
    setPerfMaxData: (state, action: PayloadAction<PerfMaxData>) => {
      state.asset_group.assets = action.payload;
    },
    setAssetsTexts: (state, action: PayloadAction<{ type: AssetsTexts, index: number, value: string }>) => {
      state.asset_group.assets[action.payload.type][action.payload.index] = action.payload.value;
    },
    resetCampaign: (state) => {
      state.id = initialState.id;
      state.type = initialState.type;
      state.name = initialState.name;
      state.budget = initialState.budget;
      state.enabled = initialState.enabled;
      state.asset_group.assets = initialState.asset_group.assets;
      state.filters = initialState.filters;
      state.bidding_strategy = initialState.bidding_strategy;
      state.target_cpc = initialState.target_cpc;
      state.target_roas = initialState.target_roas;

      state.asset_group = initialState.asset_group;
      state.adgroup = initialState.adgroup;
      state.migrate = initialState.migrate;
      state.adgroup_bing = initialState.adgroup_bing;

      state.adsets = initialState.adsets;
    },
    setLocaleFilters: (state, action: PayloadAction<{ type: 'location' | 'language', data: Array<string> }>) => {
      if (state.filters) {
        action.payload.type === 'location'
          ? state.filters.location = [ ...action.payload.data ]
          : state.filters.language = [].concat(action.payload.data as any);
      }
    },
    resetLocaleFilters: (state) => {
      state.filters = initialState.filters;
    },
    setProductGroup: (state, action: PayloadAction<{ type: AggregationsType, data: Array<string> }>) => {
      if (state.type === CampaignType.GOOGLE_PERFORMANCE_MAX) {
        state.asset_group.product_group.data = {};
        state.asset_group.product_group.data[action.payload.type] = action.payload.data;
      } else if (state.type === CampaignType.GOOGLE_SHOPPING_CLASSIC) {
        state.adgroup.product_group.data = {};
        state.adgroup.product_group.data[action.payload.type] = action.payload.data;
      } else if (state.type === CampaignType.BING_SHOPPING) {
        state.adgroup_bing.product_partition.data = {};
        state.adgroup_bing.product_partition.data[action.payload.type] = action.payload.data;
      }
    },
    setProductGroups: (state, action: PayloadAction<Partial<Record<AggregationsType, string[]>>>) => {
      if (state.type === CampaignType.GOOGLE_PERFORMANCE_MAX) {
        state.asset_group.product_group.data = action.payload;
      } else if (state.type === CampaignType.GOOGLE_SHOPPING_CLASSIC) {
        state.adgroup.product_group.data = action.payload;
      }
    },
    resetProductGroup: (state) => {
      state.adgroup.product_group.data = {};
      state.asset_group.product_group.data = {};
    },
    setProductGroupType: (state, action: PayloadAction<boolean>) => {
      if (state.type === CampaignType.GOOGLE_PERFORMANCE_MAX) {
        state.asset_group.product_group.is_included = action.payload;
      } else if (state.type === CampaignType.GOOGLE_SHOPPING_CLASSIC) {
        state.adgroup.product_group.is_included = action.payload;
      }
    },
    resetRoas: (state) => {
      delete state.target_roas;
    },
    setYoutubeIds: (state, action: PayloadAction<Array<string>>) => {
      state.asset_group.assets.youtube_ids = action.payload;
    },
    removeYoutubeVideo: (state, action: PayloadAction<number>) => {
      state.asset_group.assets.youtube_ids = (state.asset_group.assets.youtube_ids ?? []).filter((y, index) => index !== action.payload);
    },
    setBiddingStrategy: (state, action: PayloadAction<BiddingStrategy>) => {
      state.bidding_strategy = action.payload;
    },
    setTargetCpc: (state, action: PayloadAction<number>) => {
      switch (state.bidding_strategy) {
        case BiddingStrategy.manual_cpc:
          state.adgroup.target_cpc = action.payload;
          break;
        case BiddingStrategy.target_roas:
          state.target_roas = action.payload;
          break;
        case BiddingStrategy.maximize_clicks:
          state.target_cpc = action.payload;
          break;
        default:
          ;
      }
    },
    setAdGroupName: (state, action: PayloadAction<string>) => {
      state.adgroup.name = action.payload;
    },
    migrateCampaignType: (state) => {
      state.id = null;
      state.migrate = true;
    },
    setAssetGroupName: (state, action: PayloadAction<string>) => {
      state.asset_group.name = action.payload;
    },
    setPerfMaxAssets: (state, action: PayloadAction<{ type: PerfMaxPictureNode, assets: string[] }>) => {
      state.asset_group.assets[action.payload.type] = action.payload.assets;
    },
    handlePerfMaxAssets: (state, action: PayloadAction<{ targetNode?: PerfMaxPictureNode, image: AssetType }>) => {
      const { targetNode, image } = action.payload;
      const format = getImageFormat(image);

      const handleAsset = (node: Array<string>, lookup?: string[]) => {
        const newNode = [ ...node ];
        if ((newNode.includes(image.id) && !lookup) || (lookup && lookup.includes(image.id))) {
          return newNode.filter(n => n !== image.id);
        } else {
          newNode.push(image.id);
          return newNode;
        }
      }

      if (targetNode) {
        const stateNode = state.asset_group.assets[targetNode];
        state.asset_group.assets[targetNode] = handleAsset(stateNode);
      }

      // if (format === "square") {
      //   const lookup = state.asset_group.assets.marketing_images_square.concat(state.asset_group.assets.logos);
      //   state.asset_group.assets.marketing_images_square = handleAsset(state.asset_group.assets.marketing_images_square, lookup);
      //   state.asset_group.assets.logos = handleAsset(state.asset_group.assets.logos, lookup);
      // } else if (format === "landscape") {
      //   state.asset_group.assets.marketing_images = handleAsset(state.asset_group.assets.marketing_images);
      // } else if (format === "portrait") {
      //   state.asset_group.assets.marketing_images_portrait = handleAsset(state.asset_group.assets.marketing_images_portrait);
      // }
    },
    handleAssetNode: (state, action: PayloadAction<AssetType & {
      node: Extract<PerfMaxPictureNode, "logos" | "marketing_images_square">
    }>) => {
      const node = state.asset_group.assets;
      if (node[action.payload.node].includes(action.payload.id)) {
        node[action.payload.node] = node[action.payload.node].filter(n => n !== action.payload.id);
      } else {
        const newN = [ ...node[action.payload.node] ];
        newN.push(action.payload.id);
        node[action.payload.node] = newN;
      }
    },
    deleteAsset: (state, action: PayloadAction<AssetType>) => {
      state.asset_group.assets.logos = state.asset_group.assets.logos.filter(f => f !== action.payload.id);
      state.asset_group.assets.marketing_images = state.asset_group.assets.marketing_images.filter(f => f !== action.payload.id);
      state.asset_group.assets.marketing_images_square = state.asset_group.assets.marketing_images_square.filter(f => f !== action.payload.id);
      state.asset_group.assets.marketing_images_portrait = state.asset_group.assets.marketing_images_portrait.filter(f => f !== action.payload.id);
    },
    setMetaClassicType: (state, action: PayloadAction<"prospection" | "retargeting">) => {
      if (action.payload === "prospection") {
        state.adsets[0].events = MetaProspection;
      } else if (action.payload === "retargeting") {
        state.adsets[0].events = MetaRetargeting;
      }
    },
    setEventDays: (state, action: PayloadAction<{ type: MetaEventType, days: number }>) => {
      state.adsets[0].events.filter(e => e.type === action.payload.type).forEach(e => e.days = action.payload.days);
    },
    setCreative: (state, action: PayloadAction<MetaCreative>) => {
      const adsets = [ ...state.adsets ];
      adsets.forEach(ad => ad.ads[0].creative = action.payload);
    },
    setAge: (state, action: PayloadAction<{ age_min: number, age_max: number }>) => {
      const adsets = [ ...state.adsets ];
      adsets.forEach(ad => {
        ad.age_min = action.payload.age_min;
        ad.age_max = action.payload.age_max;
      });
    },
    setAdsetName: (state, action: PayloadAction<string>) => {
      const adsets = [ ...state.adsets ];
      adsets.forEach(adset => {
        adset.name = action.payload;
        adset.ads.forEach(ad => ad.name = action.payload);
      });
    },
    setMetaCountries: (state, action: PayloadAction<string[]>) => {
      state.adsets[0].target_countries = action.payload;
    },
    setAdvantageBudget: (state, action: PayloadAction<number>) => {
      state.adsets[0].daily_budget = action.payload;
    },
    setMetaPlatforms: (state, action: PayloadAction<MetaPlatform>) => {
      if (state.adsets[0].platforms.includes(action.payload)) {
        state.adsets[0].platforms = state.adsets[0].platforms.filter(p => p !== action.payload);
      } else {
        const newP = [ ...state.adsets[0].platforms ];
        newP.push(action.payload);
        state.adsets[0].platforms = newP;
      }
    },
    setMetaGenders: (state, action: PayloadAction<MetaGender[]>) => {
      state.adsets[0].genders = action.payload ?? [];
    },
    setMetaBehaviours: (state, action: PayloadAction<Behaviour[]>) => {
      state.adsets[0].behaviours = action.payload ?? [];
    },
    setMetaInterests: (state, action: PayloadAction<Interest[]>) => {
      state.adsets[0].interests = action.payload ?? [];
    },
    setMetaDsa: (state, action: PayloadAction<{ dsa: "beneficiary" | "payor", value: string }>) => {
      state.adsets[0][`dsa_${action.payload.dsa}`] = action.payload.value;
    },

    // Warmup
    setWarmupMetaPage: (state, action: PayloadAction<string>) => {
      state.page_id = action.payload;
    },
    setWarmupBudget: (state, action: PayloadAction<{ company: "google" | "facebook", budget: number }>) => {
      state[`budget_${action.payload.company}`] = action.payload.budget;
    },
    setWarmupLocation: (state, action: PayloadAction<string[]>) => {
      state.target_countries = action.payload;
    },

    // Bing Shopping Classique
    setBingAdgroupName: (state, action: PayloadAction<string>) => {
      state.adgroup_bing.name = action.payload;
    },

    setBingLanguage: (state, action: PayloadAction<string[]>) => {
      state.adgroup_bing.languages = action.payload;
    },

    initCampaign: (state, action: PayloadAction<CampaignApi & { feed: number }>) => {
      const { id, asset_groups, adgroups, feed, ...payloadProps } = action.payload;
      const [ fromNode, hasDraft ] = GetFromDraftOrPayload(id, action.payload, feed);

      const { name, type = 4, budget, enabled, target_roas, filters, bidding_strategy, target_cpc, adsets } = fromNode;

      const campaignCompany = adsCampaigns[type as CampaignType].company;

      // Assigning properties directly to state
      Object.assign(state, {
        name,
        type,
        budget,
        enabled,
        target_roas: target_roas ?? undefined,
        bidding_strategy,
        target_cpc,
        adsets: adsets ?? initialState.adsets
      });

      if ((type === CampaignType.META_SHOPPING_ADVANTAGE || type === CampaignType.META_CLASSIC) && adsets?.length) {
        state.adsets = state.adsets.map(adset => ({
          ...adset,
          ads: adset.ads.map(ad => ({
            ...ad,
            name: adsets[0].name
          }))
        }));
      }

      // Set filters with a fallback if not an array
      state.filters = Array.isArray(filters) ? { location: [], language: [] } : filters;

      // Using a function to encapsulate the defaulting logic
      if (hasDraft) {
        const draftDefaults = {
          adgroup: fromNode?.adgroup ?? initialState.adgroup,
          asset_group: fromNode?.asset_group ?? initialState.asset_group,
          adgroup_bing: fromNode?.adgroup_bing ?? initialState.adgroup_bing
        };
        initializeStateWithDefaults(state, campaignCompany, draftDefaults);
      } else {
        initializeStateWithPayload(state, adgroups, asset_groups, campaignCompany);
      }

      // Set default values for groups
      [ 'adgroup', 'adgroup_bing', 'asset_group' ].forEach(group => {
        // @ts-ignore
        state[group] = setGroupDefaults(state[group]);
      });
    }
  },
});

//==================
// Helper functions
//==================

const GetFromDraftOrPayload = (id: string | null, payload: CampaignApi, feed: number) => {
  const node = GetCampaignDraftNodeId({ feed, campaignId: id, type: CampaignIdentifierMapper[payload.type] });
  const item = window.localStorage.getItem(node);

  const hasDraft = (item?.length || 0) > 0;
  return [ (hasDraft && item) ? JSON.parse(item) : payload, hasDraft ];
};

const setGroupDefaults = (group: any) => {
  return group?.product_group === null ? { ...group, product_group: { is_included: true, data: {} } } : group;
};

const initializeStateWithDefaults = (state: any, campaignCompany: Company | null, defaults: any) => {
  state.adgroup = campaignCompany === "google" ? defaults.adgroup : state.adgroup;
  // Process asset_group for constraints
  state.asset_group = processAssetGroupForConstraints(defaults.asset_group);
  state.adgroup_bing = campaignCompany === "microsoft" ? defaults.adgroup_bing : state.adgroup_bing;
};

const initializeStateWithPayload = (state: any, adgroups: AdGroup[] | BingShopping[], asset_groups: AssetGroup[], campaignCompany: Company | null) => {
  // Process asset_group for constraints
  state.asset_group = asset_groups?.length ? processAssetGroupForConstraints(asset_groups[0]) : initialState.asset_group;
  state.adgroup = campaignCompany === "google" ? (adgroups?.length ? adgroups[0] : initialState.adgroup) : state.adgroup;
  state.adgroup_bing = campaignCompany === "microsoft" ? (adgroups?.length ? adgroups[0] : initialState.adgroup_bing) : state.adgroup_bing;
};

const processAssetGroupForConstraints = (assetGroup: AssetGroup) => {
  if (assetGroup && assetGroup.assets) {
    const maxHeadlines = 5;
    const maxLongHeadlines = 5;
    const maxDescriptions = 4;

    // Create a new object with modified properties
    return {
      ...assetGroup,
      assets: {
        business_name: assetGroup.assets.business_name,
        marketing_images: assetGroup.assets.marketing_images,
        marketing_images_square: assetGroup.assets.marketing_images_square,
        marketing_images_portrait: assetGroup.assets.marketing_images_portrait,
        youtube_ids: assetGroup.assets.youtube_ids,
        logos: assetGroup.assets.logos,

        headlines: assetGroup.assets.headlines?.slice(0, maxHeadlines),
        long_headlines: assetGroup.assets.long_headlines?.slice(0, maxLongHeadlines),
        descriptions: assetGroup.assets.descriptions?.slice().sort((a, b) => a.length - b.length).slice(0, maxDescriptions),
        warnings: assetGroup.assets.warnings ?? [],
      }
    };
  }
  return assetGroup;
};

//==================
// Exports
//==================

export const campaignReducer = campaign.reducer;

export const
  {
    setCampaignType,
    setCampaignBudget,
    setCampaignName,
    setPerfMaxData,
    setCampaignId,
    resetCampaign,
    setCampaignRoas,
    setLocaleFilters,
    setProductGroup,
    resetProductGroup,
    resetRoas,
    setAssetsTexts,
    setYoutubeIds,
    removeYoutubeVideo,
    resetLocaleFilters,
    setBiddingStrategy,
    setTargetCpc,
    setAdGroupName,
    migrateCampaignType,
    setProductGroups,
    setAssetGroupName,
    setPerfMaxAssets,
    setMetaClassicType,
    setEventDays,
    setCreative,
    setAge,
    setAdsetName,
    setMetaCountries,
    setProductGroupType,
    initCampaign,
    handlePerfMaxAssets,
    handleAssetNode,
    deleteAsset,
    toggleCampaign,
    setAdvantageBudget,
    setMetaBehaviours,
    setMetaGenders,
    setMetaInterests,
    setMetaPlatforms,
    setWarmupBudget,
    setWarmupMetaPage,
    setWarmupLocation,
    setMetaDsa,
    setBingAdgroupName,
    setBingLanguage
  } = campaign.actions;
