import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { AdsProvider } from "../../types/campaign";
import { ApiSuccess } from "../../../../types";

const channelsApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getChannelsCredentials: build.query<ChannelCredential[], void>({
      query: () => ({
        url: ApiUrls.feeds.channels.getCredentials,
      }),
      providesTags: [ "Credentials" ],
    }),
    revokeCredential: build.mutation<ApiSuccess, { id: number }>({
      query: (params) => ({
        url: ApiUrls.feeds.channels.revokeCredential(params.id),
        method: 'DELETE',
      }),
      invalidatesTags: [ "Credentials", "Feed", "UserFeed", "Campaigns_Google", "Campaigns_Meta", "AccountStatus", "Assets", "Campaigns", "MetaCatalog", "MicrosoftMerchantCenter" ],
    }),
    syncImportFeed: build.mutation<ApiSuccess, { feed: number }>({
      query: (params) => ({
        url: ApiUrls.feeds.channels.syncImportFeed(params.feed),
        method: 'POST',
        invalidatesTags: [ "Feed", "UserFeed", "AccountStatus" ],
      })
    }),
    resetChannel: build.mutation<ApiSuccess, ResetChannelParams>({
      query: (params) => ({
        url: ApiUrls.feeds.channels.resetChannel(params.feed, params.provider),
        method: 'POST',
        invalidatesTags: [ "Campaigns_Meta", "MetaCatalog", "Profile", "Feed" ],
      })
    }),
    getTracking: build.query<TrackingRes, { feed: string | number }>({
      query: ({ feed }) => ApiUrls.feeds.channels.tracking(feed),
    })
  })
});

export default channelsApi;

//===========
// Types
//===========

export type ChannelCredential = {
  id: number,
  title: string,
  provider: AdsProvider,
  last_check_valid: null | boolean,
  last_check: null | number,
}

export type ResetChannelParams = {
  feed: number,
  provider: AdsProvider,
}

export type TrackingRes = {
  go?: Pixel[],
  fb?: Pixel[],
  bg?: Pixel[],
}

export type Pixel = {
  id: string,
  name: string,
  conversion_code: string,
  global_code?: string,
  has_conversions?: boolean,
}
