import { useAccounts } from "./useAccounts";
import { AvailableCompany } from "../types/campaign";

const useChannelErrors = () => {
  const [ accounts ] = useAccounts();
  const companies: AvailableCompany[] = [ "google", "meta", "microsoft" ];
  const hasTrackingIssue = companies.filter(c => !accounts.hasTracking[c] && accounts.hasChannelActive[c]).length > 0;
  const hasPaymentIssue = companies.filter(c => !accounts.hasPayment[c] && accounts.hasChannelActive[c]).length > 0;

  return {
    hasTrackingIssue,
    hasPaymentIssue,
  }
}

export default useChannelErrors;
