import React, { ReactNode } from "react";
import FCard from "../../../App/Blocks/Card";
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link as LinkDOM } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";

interface BigDataCardProps {
  icon: ReactNode,
  primary: ReactNode,
  secondary?: ReactNode,
  action?: ReactNode,
}

export const BigDataCard = (p: BigDataCardProps) => {
  return (
    <FCard>
      <List>
        <ListItem disablePadding>
          <ListItemAvatar>
            <Avatar
              variant={"rounded"}
              sx={{ width: 56, height: 56 }}>
              {p.icon}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{ ml: 2 }}
            primary={
              <Typography variant={"h6"}>
                {p.primary}
              </Typography>
            }
            secondary={p.secondary}

          />
        </ListItem>
      </List>
    </FCard>
  )
}
