import meta from "../../../assets/img/meta.svg";
import merchant_center from "../../../assets/img/merchant_center.svg";
import google_shopping from "../../../assets/img/shopping.svg";
import gads from "../../../assets/img/google-ads.svg";
import meta_advantage from "../../../assets/img/icons/meta-advantage.svg";
import microsoft from "../../../assets/img/microsoft.svg";
import google from "../../../assets/img/icons/google.svg";
import {
  AdsCampaignType,
  AdsProvider,
  CampaignType,
  CampaignTypeIdentifier,
  Company,
  CompanyType, MetaEvent
} from "../../types/campaign";
import { ChannelsIds } from "../../types/feed";
import { Facebook, Google, Microsoft } from "@mui/icons-material";

export const adsCompanies: Record<Company, CompanyType> = {
  google: {
    available: true,
    image: google,
    provider: 'go',
    icon: Google,
  },
  meta: {
    available: true,
    image: meta,
    provider: 'fb',
    icon: Facebook,
  },
  microsoft: {
    available: true,
    image: microsoft,
    provider: 'bg',
    icon: Microsoft,
  },
}

export const providerToCompany: Record<AdsProvider, Company> = {
  "fl": "google",
  "go": "google",
  "fb": "meta",
  "bg": "microsoft",
}

export const ChannelsData: Record<ChannelsIds, CompanyType & { company: Company }> = {
  [ChannelsIds.Google]: { ...adsCompanies.google, company: "google" },
  [ChannelsIds.Meta]: { ...adsCompanies.meta, company: "meta" },
  [ChannelsIds.Microsoft]: { ...adsCompanies.microsoft, company: "microsoft" },
  [ChannelsIds.FreeListing]: { ...adsCompanies.google, company: "google" },
}

export const adsCampaigns: Record<CampaignType, AdsCampaignType> = {
  [CampaignType.UNKNOWN]: {
    logo: meta,
    title: 'null',
    identifier: null,
    company: null,
    provider: null
  },
  [CampaignType.NULL]: {
    logo: meta,
    title: 'null',
    identifier: null,
    company: null,
    provider: null,
  },
  [CampaignType.GOOGLE_SEARCH]: {
    logo: merchant_center,
    title: 'Google Search',
    identifier: null,
    company: "google",
    provider: "go",
  },
  [CampaignType.GOOGLE_SHOPPING_CLASSIC]: {
    logo: google_shopping,
    title: 'Google Shopping Classic',
    identifier: "shopping",
    company: "google",
    provider: "go",
  },
  [CampaignType.GOOGLE_SMART_SHOPPING]: {
    logo: google_shopping,
    title: 'Google Smart Shopping',
    identifier: "shopping",
    company: "google",
    provider: "go",
  },
  [CampaignType.GOOGLE_PERFORMANCE_MAX]: {
    logo: gads,
    title: 'Google Performance Max',
    identifier: "perf-max",
    company: "google",
    provider: "go",
  },
  [CampaignType.META_CLASSIC]: {
    logo: meta,
    title: "Meta Classic",
    identifier: "meta-classic",
    company: "meta",
    provider: "fb",
  },
  [CampaignType.META_SHOPPING_ADVANTAGE]: {
    logo: meta_advantage,
    title: "Meta Shopping Advantage+",
    identifier: "meta-advantage",
    company: "meta",
    provider: "fb",
  },
  [CampaignType.BING_SEARCH]: {
    logo: microsoft,
    title: 'Microsoft Search',
    identifier: null,
    company: null,
    provider: null,
  },
  [CampaignType.BING_SHOPPING]: {
    logo: microsoft,
    title: "Microsoft Shopping",
    identifier: "microsoft-shopping",
    company: "microsoft",
    provider: "bg",
  },
  [CampaignType.BING_PERF_MAX]: {
    logo: microsoft,
    title: "Microsoft Performance Max",
    identifier: null,
    company: null,
    provider: null,
  }
};

//@ts-ignore
export const campaignsType: Record<CampaignTypeIdentifier, CampaignType> = {
  "perf-max": CampaignType.GOOGLE_PERFORMANCE_MAX,
  "shopping": CampaignType.GOOGLE_SHOPPING_CLASSIC,
  "meta-classic": CampaignType.META_CLASSIC,
  "meta-advantage": CampaignType.META_SHOPPING_ADVANTAGE,
  "microsoft-shopping": CampaignType.BING_SHOPPING,
}


export const MetaProspection: MetaEvent[] = [ {
  type: "Purchase",
  is_included: false,
  days: 31,
} ];

export const MetaRetargeting: MetaEvent[] = [
  {
    type: "Purchase",
    is_included: false,
    days: 31,
  },
  {
    type: "AddToCart",
    is_included: true,
    days: 31,
  },
  {
    type: "ViewContent",
    is_included: true,
    days: 31,
  },
]
