import { SkeletonLoader } from "../../../../App/Blocks/Misc";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { GetCurrentFeedId } from "../../../../../app/utils";
import {
  resetProductGroup,
  setProductGroup,
  setProductGroups,
  setProductGroupType
} from "../../../../../app/slices/campaign/campaign";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import feedApi from "../../../../../app/services/secured/feed";
import Selector from "./Selector";
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Fade,
  FormControl,
  MenuItem,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { getCampaign } from "../../../../../app/slices/campaign/getters";
import { AggregationsType, CampaignType, ProductGroupWrapper } from "../../../../../app/types/campaign";
import { modalShowLabel } from "../../../../../app/slices/modal";
import { Delete, LabelImportant } from "@mui/icons-material";
import CampaignCard from "../CampaignCard";

const labelsNodes: Array<AggregationsType> = [ "label0", "label1", "label2", "label3", "label4" ];
const toggleButtons: Array<string> = [ "id", "brand", "product_type" ];

const Labels = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const campaign = useAppSelector(getCampaign);

  const labels = feedApi.useGetFeedLabelsQuery({ feed });
  const dynamicLabelsQuery = feedApi.useGetDynamicLabelsQuery({ feed });

  const [ listType, setListType ] = useState<AggregationsType>("brand");
  const [ diffuseAll, setDiffuseAll ] = useState(true);
  const selectedNodeIsLabel = (listType !== "id" && listType !== "brand" && listType !== "product_type");

  const [ autocompleteOpen, setAutocompleteOpen ] = useState(false);

  const list = useMemo(() => {
    if (labels.data && labels.data[listType]?.length) {
      return labels.data[listType]?.map(o => o.toUpperCase());
    } else return [];
  }, [ listType, labels ]);

  const pGroup: ProductGroupWrapper = useMemo(() => {
    return campaign.type === CampaignType.GOOGLE_PERFORMANCE_MAX
      ? campaign.asset_group?.product_group
      : campaign.type === CampaignType.BING_SHOPPING
        ? campaign.adgroup_bing.product_partition
        : campaign.adgroup?.product_group;
  }, [ campaign ]); // Where to save the labels, depends on the type

  useEffect(() => {
    const key = Object.keys(pGroup.data || {})[0];

    key && setListType(key as AggregationsType);
    // if (selectedNodeIsLabel) {
    //   setLabelGroup(listType);
    // }
  }, [ campaign.id?.length ]); // If edition & has labels, pre-select the labels' type

  useLayoutEffect(() => {
    if (pGroup.data && pGroup.data[listType]) {
      setDiffuseAll(false);
    }
  }, [ pGroup.data, listType ]);

  const changeType = useCallback((type: AggregationsType | null) => {
    if (type !== null) {
      setListType(type);
      dispatch(resetProductGroup());
      setAutocompleteOpen(true);
      openTriggeredProgrammatically.current = true; // Mark as opened programmatically
    }
  }, [ dispatch, setListType, resetProductGroup ]);

  useEffect(() => {
    if (campaign.migrate) {
      dispatch(setProductGroups(campaign.adgroup.product_group.data));
    }
  }, [ campaign.migrate ]); // If migration from Google Shopping

  const onSelect = useCallback((e: any, value: string[]) => {
    dispatch(
      setProductGroup({
        type: listType,
        data: value?.length ? value as string[] : []
      })
    );
  }, [ dispatch, listType ]); // Handles selection of labels

  const allAndReset = useCallback((val: boolean) => {
    setDiffuseAll(val);
    dispatch(resetProductGroup());
  }, [ dispatch ]);

  const editLabelFromAutocomplete = (labelName: string) => {
    const foundLabel = dynamicLabelsQuery.data?.find(d => d.label_value.toUpperCase() === labelName.toUpperCase());
    if (foundLabel) {
      dispatch(modalShowLabel({ label: foundLabel, canDelete: false, canChangeGroup: false }));
    }
  }

  const inputValue = pGroup.data && pGroup.data[listType] ? pGroup.data[listType]?.map(o => o.toUpperCase()) : [];

  const handleClickAway = () => {
    if (!openTriggeredProgrammatically.current) {
      setAutocompleteOpen(false);
    } else {
      openTriggeredProgrammatically.current = false;
    }
  };

  const openTriggeredProgrammatically = useRef(false);

  const intl = useIntl();



  return (
    <Box>
      <CampaignCard
        icon={<LabelImportant/>}
        title={"products-labels"}
        action={(
          <Selector
            all={diffuseAll}
            setAll={(v) => allAndReset(v)}
            type={"products"}
          />
        )}
        padding={false}
      >
        <Collapse in={!diffuseAll} unmountOnExit>
          <Box sx={{ p: 2 }}>
            <Box>
              <SkeletonLoader load={labels.isLoading}>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <FormControl sx={{ minWidth: '170px' }}>
                      <TextField
                        size={"small"}
                        select
                        label={"Ensemble de produits"}
                        onChange={(e) => changeType(e.target.value as AggregationsType)}
                        value={listType}
                      >
                        {
                          toggleButtons.map(t => (
                            <MenuItem
                              value={t}
                            >
                              <FormattedMessage id={`aggregation-${t}`}/>
                            </MenuItem>
                          ))
                        }
                        {
                          labelsNodes.map(l => (
                            <MenuItem
                              value={l}
                            >
                              <FormattedMessage id={`aggregation-${l}`}/>
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </FormControl>
                    <Box>
                      <Box
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"center"}
                      >
                        <Typography color={"error"}><FormattedMessage id={"exclude"}/></Typography>
                        <Switch
                          onChange={(e) => dispatch(setProductGroupType(e.target.checked))}
                          checked={pGroup.is_included}
                          className={'switch-alt'}
                          color={pGroup.is_included ? "secondary" : "error"}
                          sx={
                            !pGroup.is_included
                              ? {
                                "& .MuiSwitch-thumb": { color: (theme) => theme.palette.error.light },
                                "& .MuiSwitch-track": { backgroundColor: (theme) => theme.palette.error.light }
                              }
                              : {}
                          }
                        />
                        <Typography color={"secondary"}><FormattedMessage id={"include"}/></Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mt={2}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <FormControl fullWidth>
                      <Autocomplete
                        size={"small"}
                        key={`list-${list?.length}}`}
                        multiple
                        open={autocompleteOpen}
                        onOpen={() => {
                          openTriggeredProgrammatically.current = false;
                          setAutocompleteOpen(true);
                        }}
                        onClose={() => setAutocompleteOpen(false)}
                        value={inputValue}
                        onChange={onSelect}

                        renderInput={(params) => (
                          <TextField
                            label={<FormattedMessage id={'selection'} />}
                            placeholder={intl.formatMessage({id: `aggregation-${listType}`})}
                            {...params}
                          />
                        )}
                        options={list}
                        sx={{
                          "& .MuiChip-root": {
                            backgroundColor: (theme) => `${pGroup.is_included ? theme.palette.secondary.main : theme.palette.error.main} !important`,
                            color: '#fff',
                            '& .MuiSvgIcon-root': {
                              color: '#fff',
                            }
                          },
                        }}
                      />

                      <Typography variant={"caption"} color={"text.secondary"} mt={0.5}>
                        <FormattedMessage id={"available-options"} values={{ nb: list.length ?? 0 }}/>
                      </Typography>
                    </FormControl>
                  </ClickAwayListener>


                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Fade in={selectedNodeIsLabel} unmountOnExit>
                      <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => dispatch(modalShowLabel({ canDelete: false }))}
                      >
                        <FormattedMessage id={"create-or-edit-a-label"}/>
                      </Button>
                    </Fade>
                  </Box>
                </Box>
              </SkeletonLoader>
            </Box>
          </Box>
        </Collapse>
      </CampaignCard>
    </Box>
  )
}

export default Labels;
