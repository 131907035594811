//@ts-nocheck

import {
  PAgeObject,
  PAvailabilityObject,
  PConditionObject,
  PGenderObject,
  Product,
  ProductStatus
} from "../types/products";
import { faker } from "@faker-js/faker";

const randomElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};


const generateRandomProduct = (): Product => {
  const arr = Object.values(ProductStatus).filter(v => (typeof v === "number"));
  return {
    id: faker.datatype.number(),
    product_id: faker.datatype.uuid(),
    status: randomElement(arr),
    custom_data: faker.datatype.boolean() ? {
      // Populate with random data
    } : null,
    data: {
      id_k: faker.datatype.uuid(),
      price: `${faker.commerce.price()} EUR`,
      title: faker.commerce.productName(),
      link: faker.internet.url(),
      image_link: faker.image.imageUrl(),
      identifier_exists: randomElement(["yes", "no"]),
      google_product_category_k: faker.commerce.department(),
      google_product_category_origin_k: faker.commerce.department(),
      gender_k: randomElement(PGenderObject),
      description: faker.commerce.productDescription(),
      condition_k: randomElement(PConditionObject),
      color_k: "",
      brand_k: "Company",
      availability_k: randomElement(PAvailabilityObject),
      age_group_k: randomElement(PAgeObject),
      shipping: "Free",
      gtin: faker.datatype.boolean() ? faker.datatype.uuid() : undefined,
      mpn: faker.datatype.boolean() ? faker.datatype.uuid() : undefined,
      // Add more optional fields here if needed
    },
    product_errors: [],
    warnings: [],
  };
}

export const genProducts = (numProducts: number): Product[] => {
  const products: Product[] = [];
  for (let i = 0; i < numProducts; i++) {
    products.push(generateRandomProduct());
  }
  return products;
}

export default genProducts;
