import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { closeModal, isModalOpened, modalShowMerchantCenter, ModalTypes } from "../../../../../app/slices/modal";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { AutoAwesome, Google, Link } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { CreateAdsAccount } from "./CreateAdsAccount";
import { AdsAccountsList } from "./ModalLinkAccount";
import profileApi, { oauthRedirect } from "../../../../../app/services/secured/profile";
import { initOauth } from "../../../../App/AppPublic/Auth/Oauth";
import { GetCurrentFeedId } from "../../../../../app/utils";

enum ACCOUNT_STEPS {
  CHOOSE,
  CREATE,
  LINK
}

const ModalGoogleInitAccount = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.InitAccount));
  const feed = GetCurrentFeedId();

  const [ step, setStep ] = useState<ACCOUNT_STEPS>(ACCOUNT_STEPS.CHOOSE);

  const hide = () => {
    dispatch(closeModal());
    setTimeout(() => setStep(ACCOUNT_STEPS.CHOOSE), 500);
  }

  const onSuccess = () => {
    hide();
    dispatch(modalShowMerchantCenter());
  }

  const googleAccounts = profileApi.useGetGoogleAccountsQuery({ redirect_url: oauthRedirect });
  const hasGoogleOauth = !googleAccounts.data?.grant_required;

  const startOauth = () => {
    if (googleAccounts.data?.install_link) {
      initOauth(feed, "google", googleAccounts.data?.install_link);
    }
  }

  return (<Dialog open={open} onClose={hide} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ pb: 0 }}>
        <Stack direction={"row"} spacing={1}>
          <Google/>
          <Typography>
            <FormattedMessage id={"init-google-canal"}/>
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box>
          {step === ACCOUNT_STEPS.CHOOSE && <ChooseInitAccount onChange={(s) => setStep(s)}/>}
          {step === ACCOUNT_STEPS.CREATE && (
            <CreateAdsAccount
              company={"google"}
              onSuccess={onSuccess}
            />
          )}
          {step === ACCOUNT_STEPS.LINK && (
            <Box>
              <AdsAccountsList
                accounts={googleAccounts.data?.accounts ?? []}
                company={"google"}
                onSuccess={onSuccess}
              />
              {!hasGoogleOauth && (
                <Stack alignItems={"end"}>
                  <Button
                    variant={"outlined"}
                    onClick={startOauth}
                  >
                    <FormattedMessage id={"account-not-in-list"} />
                  </Button>
                </Stack>)}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

interface ChooseInitAccountProps {
  onChange: (step: ACCOUNT_STEPS) => void,
}

const ChooseInitAccount = (p: ChooseInitAccountProps) => {
  return (
    <List>
      <ListItem>
        <ListItemButton
          autoFocus
          onClick={() => p.onChange(ACCOUNT_STEPS.CREATE)}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <AutoAwesome/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<FormattedMessage id={"create-google-ads"}/>}
            secondary={<React.Fragment>
              <Typography>
                <b><FormattedMessage id={"google-offer"}/></b><sup>*</sup>
              </Typography>
              <Typography>
                <b><FormattedMessage id={"google-discount"}/></b> <FormattedMessage id={"on-google-shopping"}/>
              </Typography>
            </React.Fragment>}
          />
        </ListItemButton>
      </ListItem>
      <Divider><FormattedMessage id={"or"}/></Divider>
      <ListItem>
        <ListItemButton
          onClick={() => p.onChange(ACCOUNT_STEPS.LINK)}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <Link/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<FormattedMessage id={"link-existing-account"}/>}
            secondary={<React.Fragment>
              <Typography>
                <FormattedMessage id={"link-existing-account-desc"}/>
              </Typography>
            </React.Fragment>}
          />
        </ListItemButton>
      </ListItem>
    </List>
  )
}


export default ModalGoogleInitAccount;
