//@ts-nocheck
import { keyframes } from "@mui/material";

export const pulsingBadge = (color1:string, color2:string, color3:string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color1};
  },
  70% {
    box-shadow: 0 0 0 4px ${color2};
  },
  100% {
    box-shadow: 0 0 0 0 ${color3};
  }
`;
