import { Alert, AlertTitle, Box, Fade } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

interface SubscriptionAlertsProps {
  showCancel: boolean,
  showDowngrade: boolean,
  showPromotion: boolean,
}

export const SubscriptionAlerts = (p: SubscriptionAlertsProps) => {
  return (
    <Box>
      <Fade
        in={p.showCancel}
        unmountOnExit
        timeout={{ enter: 500, exit: 0 }}
      >
        <Alert severity={"error"} sx={{ boxShadow: "none" }}>
          <AlertTitle><FormattedMessage id={"cancel-sub"}/></AlertTitle>
          <FormattedMessage id={"cancel-sub-desc"}/>
        </Alert>
      </Fade>

      <Fade
        in={p.showDowngrade}
        unmountOnExit
        timeout={{
          exit: 0,
          enter: 500,
        }}
      >
        <Alert
          severity={"warning"}
          sx={{ boxShadow: "none" }}
        >
          <AlertTitle><FormattedMessage id={"downgrade"}/></AlertTitle>
          <FormattedMessage id={"downgrade-desc"}/>
        </Alert>
      </Fade>

      {/*<Fade*/}
      {/*  in={p.showPromotion}*/}
      {/*  unmountOnExit*/}
      {/*  timeout={{*/}
      {/*    exit: 0,*/}
      {/*    enter: 500,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Alert*/}
      {/*    severity={"info"}*/}
      {/*    sx={{ boxShadow: "none" }}*/}
      {/*  >*/}
      {/*    <AlertTitle><FormattedMessage id={"coupon-code"} /></AlertTitle>*/}
      {/*    <FormattedMessage id={"coupon-code-desc"} />*/}
      {/*  </Alert>*/}
      {/*</Fade>*/}
    </Box>
  )
}
