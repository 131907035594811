//======
// Types
//======
export type PageWithInfo = "product-reporting" | "custom-labels" | "optimisation" | "product-list" | "product-edit";
export type BlockWithInfo = "connect-catalog" | "connect-ads-account" | "pixel-tracking";
type langLinks = { fr: string, en: string }

//======
// Objects
//======
export const helpdeskLinks: Record<string, langLinks> = {

  // HelpLink
  "create-project": {
    fr: "https://help.feedcast.io/fr/article/creer-un-projet-hoqtmh/",
    en: "https://help.feedcast.io/en/article/create-a-project-lpal7f/",
  },
  "performances-global-view": {
    fr: "https://help.feedcast.io/fr/article/presentation-de-la-vue-densemble-des-performances-1okssbd/",
    en: "https://help.feedcast.io/en/article/presentation-of-the-performance-overview-cu1u1b/",
  },
  "analyze-performances": {
    fr: "https://help.feedcast.io/fr/article/comment-analyser-les-performances-de-ses-produits-sur-google-shopping-89yudh/",
    en: "https://help.feedcast.io/en/article/how-to-analyse-the-performance-of-your-products-on-google-shopping-j673kq/",
  },
  "connect-feed": {
    fr: "https://help.feedcast.io/fr/article/connecter-un-catalogue-produits-lxak3w/",
    en: "https://help.feedcast.io/en/article/how-to-connect-a-product-catalog-to-feedcast-yy5r9w/"
  },
  "products-global-view": {
    fr: "https://help.feedcast.io/fr/article/presentation-de-la-vue-densemble-du-catalogue-produits-1ynr0w7/",
    en: "https://help.feedcast.io/en/article/overview-of-the-product-catalog-109dbk7/",
  },
  "google-statuses": {
    fr: "https://help.feedcast.io/fr/article/explication-des-etats-dapprobation-des-produits-sur-google-4syc7r/",
    en: "https://help.feedcast.io/en/article/explanation-of-product-approval-status-on-google-1iyqquh/"
  },
  "edit-products": {
    fr: "https://help.feedcast.io/fr/article/comment-modifier-les-produits-de-mon-catalogue-qx4bun/",
    en: "https://help.feedcast.io/en/article/how-to-modify-the-products-in-my-catalog-1sgyd97/",
  },
  "feed-advices": {
    fr: "https://help.feedcast.io/fr/article/optimiser-votre-catalogue-produits-avec-feedcast-4k7pyo/",
    en: "https://help.feedcast.io/en/article/optimise-your-product-catalogue-with-feedcast-optimise-your-titles-and-descriptions-correct-rejected-products-in-your-product-catalogue-8mchvb/"
  },
  "help-ads-account": {
    fr: "https://help.feedcast.io/fr/article/connecter-ou-creer-un-compte-publicitaire-g3ohzv/",
    en: "https://help.feedcast.io/en/article/how-do-i-connect-or-create-an-advertising-account-with-feedcast-189iuri/"
  },
  "create-campaign": {
    fr: "https://help.feedcast.io/fr/article/comment-creer-une-campagne-1yhua0e/",
    en: "https://help.feedcast.io/en/article/how-to-create-a-campaign-gfrb76/",
  },
  "campaign-types": {
    fr: "https://help.feedcast.io/fr/article/les-differents-types-de-campagnes-disponibles-141nl6c/",
    en: "https://help.feedcast.io/en/article/the-different-types-of-campaign-available-13cmn1t/"
  },
  "feed-score": {
    fr: "https://help.feedcast.io/fr/article/presentation-du-feedscore-16gc8v4/?bust=1676883826316",
    en: "https://help.feedcast.io/en/article/presentation-of-the-feedscore-how-is-it-calculated-why-and-how-to-optimize-it-zawn6k/"
  },
  "google-advantages": {
    fr: "https://help.feedcast.io/fr/article/comment-profiter-des-400-de-budget-publicitaire-offerts-sur-google-ads-aooven/",
    en: "https://help.feedcast.io/fr/article/comment-profiter-des-400-de-budget-publicitaire-offerts-sur-google-ads-aooven/",
  },
  "ai-products": {
    fr: "https://help.feedcast.io/fr/article/comment-generer-des-titres-et-descriptifs-a-laide-de-lia-c8iz5c/",
    en: "https://help.feedcast.io/fr/article/comment-generer-des-titres-et-descriptifs-a-laide-de-lia-c8iz5c/"
  },
  "perf-max-best-practices": {
    fr: "https://help.feedcast.io/fr/article/les-bonnes-pratiques-pour-optimiser-les-groupes-dassets-sur-performance-max-125ocbv/",
    en: "https://help.feedcast.io/en/article/best-practices-for-optimizing-asset-groups-on-performance-max-1unw741/",
  },

  // HelpButton
  "connect-catalog": {
    fr: "https://help.feedcast.io/fr/article/comment-connecter-un-catalogue-produits-a-feedcast-lxak3w/",
    en: "https://help.feedcast.io/en/article/how-to-connect-a-product-feed-to-feedcast-yy5r9w/",
  },
  "connect-ads-account": {
    fr: "https://help.feedcast.io/fr/article/connecter-ou-creer-un-compte-publicitaire-g3ohzv/",
    en: "https://help.feedcast.io/en/article/create-or-connect-a-google-ads-account-1ye8r1d/",
  },
  "pixel-tracking": {
    fr: "https://help.feedcast.io/fr/article/comment-mettre-en-place-le-suivi-des-conversions-in6gfo/?bust=1707498662226",
    en: "https://help.feedcast.io/en/article/how-to-set-up-conversion-tracking-gjwo79/",
  },

  // PageInfo
  "product-reporting": {
    fr: "https://help.feedcast.io/fr/article/ou-mes-annonces-sont-susceptibles-detre-affichees-sur-google-1kr7ln7/",
    en: "https://help.feedcast.io/en/article/where-my-ads-are-likely-to-be-displayed-on-google-q0qdf4/",
  },
  "custom-labels": {
    fr: "https://help.feedcast.io/fr/article/labels-personnalises-comment-creer-un-label-1ko3b9s/",
    en: "https://help.feedcast.io/en/article/custom-labels-how-to-create-a-label-1bg2x6b/"
  },
  "optimisation": {
    fr: "https://help.feedcast.io/fr/article/optimiser-votre-catalogue-produits-avec-feedcast-4k7pyo/",
    en: "https://help.feedcast.io/en/article/optimise-your-product-feed-with-feedcast-8mchvb/",
  },
  "product-list": {
    fr: "https://help.feedcast.io/fr/article/optimiser-votre-catalogue-produits-avec-feedcast-4k7pyo/",
    en: "https://help.feedcast.io/en/article/optimise-your-product-feed-with-feedcast-8mchvb/",
  },
  "product-edit": {
    fr: "https://help.feedcast.io/fr/article/optimiser-votre-catalogue-produits-avec-feedcast-4k7pyo/",
    en: "https://help.feedcast.io/en/article/optimise-your-product-feed-with-feedcast-8mchvb/",
  }
}
