import React, { useEffect, useMemo, useState } from "react";
import profileApi from "../../../../app/services/secured/profile";
import { LoadingButton, MyFormGroup, SkeletonLoader } from "../../../App/Blocks/Misc";
import { FormattedMessage, useIntl } from "react-intl";
import SimpleForm, { SimpleFormData } from "../../../App/Blocks/SimpleForm";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import globalApi from "../../../../app/services/secured/global";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AccountCircle, Business, Password } from "@mui/icons-material";
import Security from "./Security";
import { ProfileType } from "../../../../app/types/profile";
import { getUser } from "../../../../app/slices/app";
import Locale from "../../../App/Blocks/Locale";

type ProfileProps = {
  onSuccess?: () => void,
}

const Profile = ({ onSuccess }: ProfileProps) => {

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const query = profileApi.useGetProfileQuery();
  const profile = query.data;

  const [ setProfile, setProfileRes ] = profileApi.useSetProfileMutation();

  const submit = (s: SimpleFormData) => setProfile({ ...s as ProfileType });

  useEffect(() => {
    if (setProfileRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "profile-edit-success" })));
      onSuccess && onSuccess();
    }
  }, [ setProfileRes.isSuccess ]);

  return (
    <Stack spacing={1} key={`profile-${profile?.first_name}`}>
      <AlertStripeLink/>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <SkeletonLoader load={query.isFetching || query.isLoading}>
            <FCard>
              <SimpleForm onSubmit={submit}>
                <ProfileForm
                  profile={profile}
                />
                <Box mt={2} display={"flex"} justifyContent={"end"}>
                  <LoadingButton
                    data={setProfileRes}
                    type={"submit"}
                    reset
                    variant={"contained"}
                  >
                    <FormattedMessage id={"save-edits"}/>
                  </LoadingButton>
                </Box>
              </SimpleForm>
            </FCard>
          </SkeletonLoader>
        </Grid2>
        <Grid2 xs>
          <FCardHeader icon={<Password/>} title={"password"}/>
          <Security/>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export const AlertStripeLink = () => {
  const user = useAppSelector(getUser);
  return (
    !user?.is_linked ? (
      <Alert severity={"info"} variant={"filled"}>
        <AlertTitle>
          <FormattedMessage id={"alert-stripe-link"}/>
        </AlertTitle>
        <FormattedMessage id={'alert-stripe-link-desc'}/>
      </Alert>
    ) : null
  )
}

interface ProfileFormProps {
  profile: ProfileType | undefined,
}

const ProfileForm = ({ profile }: ProfileFormProps) => {

  const countries = globalApi.useCountriesQuery();
  const [ country, setCountry ] = useState<string>("");

  const [ vatExempted, setVatExempted ] = useState(profile?.merchant.address.vat_exempted ?? false);

  const availableVats = useMemo(() => {
    if (countries.isSuccess) {
      const node = countries.data.find(row => row.code === country);
      if (node) {
        return node.country_vats;
      }
    }
    return [];
  }, [ country, countries.isSuccess ]);

  useEffect(() => {
    if (profile && profile.merchant.address.country && countries.isSuccess) {
      const defaultCountry = profile.merchant.address.country;
      const node = countries.data.find(row => row.code === defaultCountry);
      if (node) {
        setCountry(node.code);
      }
    }
  }, [ profile, countries.isSuccess ]);

  return (
    <Box>
      <Grid2 container spacing={2}>
        <MyFormGroup size={12}>
          <TextField
            label={<FormattedMessage id={"auth-email"}/>}
            name={"authentication[email]"}
            defaultValue={profile?.authentication.email ?? ''}
          />
        </MyFormGroup>
        <MyFormGroup size={4}>
          <TextField
            label={<FormattedMessage id={"your-first-name"}/>}
            name={"first_name"}
            defaultValue={profile?.first_name ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={4}>
          <TextField
            name={"last_name"}
            defaultValue={profile?.last_name ?? ''}
            label={<FormattedMessage id={"your-last-name"}/>}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={4}>
          <TextField
            label={<FormattedMessage id={"your-phone"}/>}
            name={"phone_number"}
            defaultValue={profile?.phone_number ?? ''}
            required
          />
        </MyFormGroup>

        <Grid2 xs={12}>
          <Divider textAlign={"left"}>
            <Business/>
          </Divider>
        </Grid2>

        <MyFormGroup size={8}>
          <TextField
            label={<FormattedMessage id={"business-name"}/>}
            name={"merchant[name]"}
            defaultValue={profile?.merchant.name ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={4}>
          <TextField
            name={"merchant[address][siret]"}
            defaultValue={profile?.merchant.address.siret}
            label={<FormattedMessage id={"siret"}/>}
            error={!((profile?.merchant?.address?.siret?.length || 0) > 0)}
          />
        </MyFormGroup>
        <MyFormGroup size={6}>
          <TextField
            label={<FormattedMessage id={"invoice-email"}/>}
            name={"merchant[address][invoice_email]"}
            defaultValue={profile?.merchant.address.invoice_email ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={6}>
          <TextField
            label={<FormattedMessage id={"business-phone"}/>}
            name={"merchant[address][phone_number]"}
            defaultValue={profile?.merchant.address.phone_number ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={12}>
          <TextField
            label={<FormattedMessage id={"business-address"}/>}
            name={"merchant[address][address]"}
            defaultValue={profile?.merchant.address.address ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={12}>
          <TextField
            // size={"small"}
            name={"merchant[address][address2]"}
            defaultValue={profile?.merchant.address.address2 ?? ''}
            label={<FormattedMessage id={"business-address-2"}/>}
          />
        </MyFormGroup>

        <MyFormGroup size={2}>
          <TextField
            name={"merchant[address][zip]"} defaultValue={profile?.merchant.address.zip ?? ''}
            required
            label={<FormattedMessage id={"zip"}/>}
          />
        </MyFormGroup>
        <MyFormGroup size={3}>
          <TextField
            label={<FormattedMessage id={"city"}/>}
            name={"merchant[address][city]"}
            defaultValue={profile?.merchant.address.city ?? ''}
            required
          />
        </MyFormGroup>
        <MyFormGroup size={2}>
          <TextField
            select
            label={<FormattedMessage id={"country"}/>}
            key={countries.data?.length}
            name={"merchant[address][country]"}
            onChange={(e) => setCountry(e.target.value)}
            defaultValue={profile?.merchant.address.country ?? ''} required
          >
            {countries.data?.map(c => <MenuItem key={c.code} value={c.code}>{c.name}</MenuItem>)}
          </TextField>
        </MyFormGroup>

        <Grid2 xs={12} sx={{ p: 0 }}/>

        {availableVats.length === 1 && (
          <React.Fragment>
            <Grid2 xs={4}>
              <Box sx={{ whiteSpace: "nowrap" }}>
                <FormControlLabel
                  control={<Checkbox
                    onChange={(e, checked) => setVatExempted(checked)}
                    defaultChecked={profile?.merchant.address.vat_exempted}
                    disabled={(profile?.merchant.address.vat_number || 0) > 0}
                    name={"merchant[address][vat_exempted]"}
                  />}
                  label={<Typography color={"text.secondary"} variant={"body2"}>
                    <FormattedMessage id={'vat-exempted'}/>
                  </Typography>}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <FormControl fullWidth>
                  <input type={"hidden"} name={"merchant[address][vat_type]"} value={availableVats[0].code ?? ""}/>
                  <TextField
                    name={"merchant[address][vat_number]"}
                    defaultValue={profile?.merchant.address.vat_number}
                    disabled={vatExempted}
                    label={<FormattedMessage id={"vat_number"}/>}
                  />
                </FormControl>

              </Box>
            </Grid2>
          </React.Fragment>
        )}
        <Grid2 xs={12}>
          <Divider/>
        </Grid2>
        <Grid2 xs={4}>
          <TextField
            select
            fullWidth
            label={<FormattedMessage id={'email-language'}/>}
            defaultValue={profile?.merchant.default_language}
            name={"merchant[default_language]"}
          >
            <MenuItem value={"FR"}>
              <FormattedMessage id={'french'}/>
            </MenuItem>
            <MenuItem value={"EN"}>
              <FormattedMessage id={'english'}/>
            </MenuItem>
          </TextField>
        </Grid2>
      </Grid2>

    </Box>
  )
}

export default Profile;
