import { useAppSelector } from "../hooks";
import { getShop } from "../slices/shop";
import campaignApi from "../services/secured/campaign";
import { useEffect } from "react";
import { GetCurrentFeedId } from "../utils";

const useGoogleAssets = () => {

  const currentFeedId = GetCurrentFeedId();
  const shop = useAppSelector(getShop);
  const [ getAssets ] = campaignApi.useLazyGetAssetsQuery();

  useEffect(() => {
    if (shop.accounts.hasLinkedAccount.google && shop.accounts.hasAccess.google) {
      getAssets({ feed: currentFeedId }, true);
    }
  }, [ shop ]);

}

export default useGoogleAssets;
