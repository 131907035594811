import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField
} from '@mui/material';
import { HighlightOff, Search } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ProductParams, ProductStatus } from "../../../../app/types/products";
import useCatalogErrors from "../../../../app/hooks/useCatalogErrors";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppMode } from "../../../../app/slices/app";
import { ChannelsIds } from "../../../../app/types/feed";
import SimpleForm, { SimpleFormData } from "../../../App/Blocks/SimpleForm";
import { LabelSelector } from "../../../App/Blocks/LabelSelector";
import { setProductsPage } from "../../../../app/slices/products";
import { ProductSearchParameters } from "../_ProductList";

type ToolbarProps = {
  search: ProductSearchParameters | undefined,
  onSearch: (s: ProductSearchParameters) => void,
  hasProducts: boolean,
  hasParams: boolean,
};

const renderErrorCodeItem = (e: any, errorIndex: any) => (
  <MenuItem key={`error-${e.id}-${errorIndex}`} value={e.id}>
    <ListItemText
      primary={<FormattedMessage id={e.header} values={{ attribute: e.attribute ?? "unknown" }}/>}
      // secondary={<FormattedMessage id={e.body ? e.body : e.description} />}
    />
  </MenuItem>
);

const renderNoErrorItem = (source_id: any, index: any) => (
  <MenuItem key={`no-error-${source_id}-${index}`} disabled value={0}>
    <FormattedMessage id='no-error'/>
  </MenuItem>
);

const ProductListFilters = (p: ToolbarProps) => {

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { search, onSearch, hasProducts, hasParams } = p;
  const [ searchValue, setSearchValue ] = useState("");

  const [ { errorFocus, globalStatuses } ] = useCatalogErrors();
  const showSearch = hasProducts || hasParams;

  // Optimisation
  const memoizedOnSearch = useCallback(onSearch, [ onSearch ]);
  const searchRef = useRef(memoizedOnSearch);

  //
  const isError = search?.status?.toString() === ProductStatus.FLAG_ERROR.toString();
  const isWarning = search?.status?.toString() === ProductStatus.FLAG_WARNING.toString();

  useEffect(() => {
    searchRef.current = memoizedOnSearch;
  }, [ memoizedOnSearch ]);

  useEffect(() => {
    if (errorFocus) {
      searchRef.current({
        ...search,
        error_code: errorFocus.id,
        status: errorFocus.is_blocking ? ProductStatus.FLAG_ERROR : ProductStatus.FLAG_WARNING,
      });
    }
  }, [ errorFocus ]);

  const handleClearSearch = () => {
    searchRef.current({ ...search, q: '' });
    setSearchValue("");
  };

  const handleStatusChange = (event: any) => {
    const { value } = event.target;
    const { error_code, ...newSearch } = { ...search, status: value >= 0 ? parseInt(value) : undefined };
    searchRef.current({...newSearch, page: 1});
  };

  const handleErrorCodeChange = (event: any) => {
    const { value } = event.target;
    searchRef.current(value !== "all" ? { ...search, error_code: value } : {});
  };

  const onSubmit = (s: SimpleFormData) => {
    const { q } = s;
    searchRef.current({ ...search, q });
  }

  const renderErrorCodes = (channel: any, index: any) => {
    const errorSource = channel[
      search?.status?.toString() === ProductStatus.FLAG_WARNING.toString() ? "warnings" : "errors"
      ] ?? [];

    return [
      <ListSubheader key={`header-${channel.source_id}-${index}`} sx={styles.listSubheader}>
        {ChannelsIds[channel.source_id as any]}
      </ListSubheader>,
      ...errorSource.length ? errorSource.map(renderErrorCodeItem) : [ renderNoErrorItem(channel.source_id, index) ]
    ];
  };

  return (
    <Box mb={2}>
      <Grid2 container spacing={2} sx={styles.gridContainer}>
        <Grid2 md={4}>
          <SimpleForm onSubmit={onSubmit}>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={'q'}
                label={intl.formatMessage({ id: 'search' })}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                disabled={!showSearch}
                placeholder={intl.formatMessage({ id: 'search-products-placeholder' })}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                  endAdornment: search?.q && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch} edge="end"><HighlightOff/></IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </SimpleForm>
        </Grid2>
        <Grid2 xs={2}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              select
              value={search?.status ?? -1}
              onChange={handleStatusChange}
              label={<FormattedMessage id="status"/>}
            >
              <MenuItem value={-1}><FormattedMessage id={"all-states"}/></MenuItem>
              <MenuItem value={ProductStatus.FLAG_PENDING}><FormattedMessage id={"pending"}/></MenuItem>
              <MenuItem value={ProductStatus.FLAG_WARNING}><FormattedMessage id={"warnings"}/></MenuItem>
              <MenuItem value={ProductStatus.FLAG_ERROR}><FormattedMessage id={"invalid"}/></MenuItem>
              <MenuItem value={ProductStatus.FLAG_ONLINE}><FormattedMessage id={"active"}/></MenuItem>
            </TextField>
          </FormControl>
        </Grid2>
        <Grid2 md={6}>
          <Fade in={(search?.status !== undefined && (isError || isWarning))}>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                select
                key={`${search?.error_code} ${search?.status}`}
                value={search?.error_code ?? "all"}
                onChange={handleErrorCodeChange}
                label={<FormattedMessage id={'warnings-errors-detail'} />}
              >
                <MenuItem value="all">
                  <FormattedMessage id="all-products" values={{ type: isError ? "error" : "warning" }}/>
                </MenuItem>
                {globalStatuses.map(renderErrorCodes)}
              </TextField>
            </FormControl>
          </Fade>
        </Grid2>
      </Grid2>
    </Box>
  );
};

const styles = {
  gridContainer: {
    // "& .MuiInputBase-root": { minHeight: "60px" },
    // "& .MuiSelect-select": { padding: "0 1rem", minHeight: "60px" },
  },
  listSubheader: {
    // height: "2rem",
    // lineHeight: '2rem',
  }
};

export default React.memo(ProductListFilters);
