import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import feedApi from "../services/secured/feed";

import { FeedSourceEnum } from "../types/feed";

export enum NewFeedSteps {
  SET_SOURCE,
  SET_CREDENTIALS,
  PREVIEW,
  CONFIG,
}

const initialState: FeedSlice = {
  step: NewFeedSteps.SET_SOURCE,
  source: null,
  project: null,
  name: '',
  locale: 'fr_FR',
  url: '',
  credentials: {},
  preview: null,
  id: null,
}

const newfeed = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setNewFeedStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
      if (state.step === NewFeedSteps.SET_SOURCE) {
        state.credentials = {};
        state.source = null;
        state.preview = null;
        state.url = "";
        state.name = "";
      } else if (state.step === NewFeedSteps.SET_CREDENTIALS) {
        state.preview = null;
      }
    },
    setNewFeedSource: (state, action: PayloadAction<FeedSourceEnum | "fictif" | null>) => {
      state.source = action.payload;
      state.step = action.payload !== "fictif" ? NewFeedSteps.SET_CREDENTIALS : NewFeedSteps.PREVIEW;
    },
    setNewFeedProject: (state, action: PayloadAction<number>) => {
      state.project = action.payload;
    },
    setNewFeedName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setNewFeedUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setNewFeedCredentials: (state, action: PayloadAction<CredentialsType>) => {
      state.credentials = action.payload;
    },
    setNewFeedLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setNewFeedId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    resetNewFeed: (state) => {
      state.id = null;
      state.source = initialState.source;
      state.project = initialState.project;
      state.name = initialState.name;
      state.locale = initialState.locale;
      state.url = initialState.url;
      state.credentials = initialState.credentials;
      state.preview = initialState.preview;
      state.step = initialState.step;
    }
  },
  extraReducers: builder => {
    builder
    //default preview
    .addMatcher(feedApi.endpoints.newFeedPreview.matchFulfilled, (state, action) => {
      state.preview = action.payload;
    })
    //prestashop
    .addMatcher(feedApi.endpoints.newPrestashopPreview.matchFulfilled, (state, action) => {
      state.preview = action.payload;
    })
    //woocommerce
    .addMatcher(feedApi.endpoints.newWoocommercePreview.matchFulfilled, (state, action) => {
      state.preview = action.payload;
    })
  }
});
export const newFeedReducer = newfeed.reducer;

/** Actions */
export const {
  setNewFeedSource,
  setNewFeedStep,
  setNewFeedProject,
  setNewFeedName,
  setNewFeedUrl,
  setNewFeedCredentials,
  setNewFeedLocale,
  setNewFeedId,
  resetNewFeed
} = newfeed.actions;

/** Getters */
export const getNewFeed = (state: RootState) => state.newFeed;
export const getNewFeedProject = (state: RootState) => state.newFeed.project;
export const getNewFeedStep = (state: RootState) => state.newFeed.step;
export const getNewFeedUrl = (state: RootState) => state.newFeed.url;
export const getNewFeedSource = (state: RootState) => state.newFeed.source;
export const getNewFeedCredentials = (state: RootState) => state.newFeed.credentials;
export const getNewFeedPreview = (state: RootState) => state.newFeed.preview;

export const getNewFeedApi = (state: RootState) => {
  const feed = state.newFeed;
  return {
    name: feed.name,
    project: feed.project as number,
    locale: feed.locale,
    url: feed.url,
    [FeedSourceEnum[feed.source as number ?? "fictif"]?.toLowerCase()]: feed.credentials,
    source: feed.source,
  };
}

/** Types */

type FeedSlice = {
  step: NewFeedSteps,
  source: FeedSourceEnum | "fictif" | null,
  project: number | null,
  name: string,
  locale: string,
  url: string,
  credentials: CredentialsType,
  preview: FeedPreviewType | null,
  id: number|null,
}

export type FeedPreviewType = {
  count: number,
  missing_cat: number,
  missing_gtin: number,
  missing_ids: number,
  missing_images: number,
  url: string,
  website: string,
}

export type PrestashopCredentials = {
  store: string,
  access_token: string,
}

export type WoocommerceCredentials = {
  store: string,
  api_token: string,
  api_key: string,
}

export type CredentialsType = WoocommerceCredentials | PrestashopCredentials | {};
