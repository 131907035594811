import { UserFeed } from "../../../app/types/feed";
import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { AutoAwesome, RocketLaunch } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

interface UpgradeButtonProps {
  currFeed: UserFeed | null;
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({currFeed}: UpgradeButtonProps) => {
  return (
    <Button
      variant={"contained"}
      component={Link}
      to={Urls.settings.subEdit(currFeed?.id ?? 0)}
      endIcon={<RocketLaunch />}
    >
      <FormattedMessage id="feedcast-trial"/>
    </Button>
  );
};
