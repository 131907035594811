import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeModal, getModalData, isModalOpened, ModalTypes } from "../../../app/slices/modal";
import projectsApi from "../../../app/services/secured/projects";
import React, { useEffect } from "react";
import { NewSuccessNotification } from "../../../app/slices/notifications";
// import { FormGroup, Modal } from "react-bootstrap";
import { LoadingButton } from "../../App/Blocks/Misc";
import SimpleForm from "../../App/Blocks/SimpleForm";
import { Box, Dialog, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { Inventory, Inventory2, Inventory2Outlined } from "@mui/icons-material";
import { HelpLink } from "../../App/Helpdesk/HelpLink";

export const ModalProject = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const show = useAppSelector(isModalOpened(ModalTypes.Project));
  const data = useAppSelector<{ project: number, name: string } | null>(getModalData);

  const [ newProject, newProjectRes ] = projectsApi.useNewProjectMutation();
  const [ editProject, editProjectRes ] = projectsApi.useEditProjectMutation();

  useEffect(() => {
    if (newProjectRes.isSuccess) {
      dispatch(NewSuccessNotification("new-project-success"));
      dispatch(closeModal());
    }
  }, [ newProjectRes.isSuccess ]);

  useEffect(() => {
    if (editProjectRes.isSuccess) {
      dispatch(NewSuccessNotification("edit-project-success"));
      dispatch(closeModal());
    }
  }, [ editProjectRes ]);

  const handleClose = () => dispatch(closeModal());

  return <Dialog
    open={show}
    onClose={handleClose}
    maxWidth={"xs"}
    fullWidth
  >
    <SimpleForm onSubmit={s => {
      data?.project
        ? editProject({ project: data.project, name: s.name as string })
        : newProject(s as { name: string })
    }}>
      <DialogTitle sx={{display:"flex", alignItems:"center", gap: 1}}>
        <FormattedMessage id={!data?.project ? "new-project" : "edit-project"}/>
        <Inventory2Outlined  />
      </DialogTitle>
      <DialogContent sx={{pb: 0}}>
        <FormControl margin={"dense"} fullWidth>
          <TextField
            name={'name'}
            defaultValue={data?.name}
            required
            label={<FormattedMessage id={"project-name"}/>}
            helperText={<HelpLink title={"know-more-about-projects"} help={"create-project"}/>}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type={"submit"}
          data={newProjectRes}
          size={"large"}
          variant={"contained"}
          reset
        >
          <FormattedMessage id={!data ? "confirm-new-project" : "edit"}/>
        </LoadingButton>
      </DialogActions>
    </SimpleForm>
    {/*<Modal*/}
    {/*  show={show}*/}
    {/*  onHide={() => dispatch(closeModal())}*/}
    {/*  centered*/}
    {/*>*/}
    {/*  <ModalHeaderPersona*/}
    {/*    title={!data?.project ? "new-project" : "edit-project"}*/}
    {/*    // className={'bg-yellow-800'}*/}
    {/*    variant={"1"}*/}
    {/*  />*/}
    {/*  <Modal.Body>*/}
    {/*    <SimpleForm onSubmit={s => {*/}
    {/*      data?.project*/}
    {/*        ? editProject({ project: data.project, name: s.name as string })*/}
    {/*        : newProject(s as { name: string })*/}
    {/*    }}>*/}
    {/*      <FormGroup>*/}
    {/*        <FormControl fullWidth>*/}
    {/*          <TextField*/}
    {/*            name={'name'}*/}
    {/*            defaultValue={data?.name}*/}
    {/*            required*/}
    {/*            label={<FormattedMessage id={"project-name"}/>}*/}
    {/*          />*/}
    {/*        </FormControl>*/}

    {/*        <Box display={"flex"} justifyContent={"end"}>*/}
    {/*          <HelpLink title={"know-more-about-projects"} help={"create-project"}/>*/}
    {/*        </Box>*/}
    {/*      </FormGroup>*/}

    {/*      <LoadingButton*/}
    {/*        type={"submit"}*/}
    {/*        data={newProjectRes}*/}
    {/*        size={"large"}*/}
    {/*        className={'mt-3 w-100'}*/}
    {/*        reset*/}
    {/*      >*/}
    {/*        <FormattedMessage id={!data ? "confirm-new-project" : "edit-project"}/>*/}
    {/*      </LoadingButton>*/}
    {/*    </SimpleForm>*/}
    {/*  </Modal.Body>*/}
    {/*</Modal>*/}
  </Dialog>
}
