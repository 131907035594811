import React, { useEffect, useState } from "react";
import { getCurrentFeed, setCurrentFeed } from "../../../../app/slices/app";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useAccounts } from "../../../../app/hooks/useAccounts";
import feedApi from "../../../../app/services/secured/feed";

const useFreeDiffusion = () => {

  const dispatch = useAppDispatch();
  const currFeed = useAppSelector(getCurrentFeed);
  const [ notifs ] = useAccounts();
  const [ freeListing, freeListingRes ] = feedApi.useActivateFreeListingMutation();
  const [ comparator, comparatorRes ] = feedApi.useActivateFeedcastComparatorMutation();

  const [ free, setFree ] = useState({
    google: {
      enabled: false,
      disabled: true,
    },
    feedcast: {
      enabled: false,
      disabled: true,
    }
  });

  useEffect(() => {
    const fr = { ...free };
    const score = currFeed?.last_score;

    if (currFeed?.feed_option?.enable_free_listing) {
      fr.google = {
        enabled: true,
        disabled: false,
      }
    } else if (notifs.canCreateCampaigns.google && (currFeed?.last_stat?.imported || 0) > 0) {
      fr.google = {
        enabled: false,
        disabled: false,
      }
    } else {
      fr.google = {
        enabled: false,
        disabled: true,
      }
    }

    if (currFeed?.feed_option?.export_comparator) {
      fr.feedcast = {
        enabled: true,
        disabled: false,
      }
    } else if ((currFeed?.last_stat?.imported || 0) > 0 && score?.feedcast !== null) {
      fr.feedcast = {
        enabled: false,
        disabled: false,
      }
    } else {
      fr.feedcast = {
        enabled: false,
        disabled: true,
      }
    }

    setFree(fr);
  }, [ currFeed?.feed_option, notifs ]);

  useEffect(() => {
    if (freeListingRes.isSuccess) {
      dispatch(setCurrentFeed(freeListingRes.data));
      setFree({ ...free, google: { enabled: !free.google.enabled, disabled: false } });
      dispatch(NewSuccessNotification(!free.google.enabled ? "success-free-listing" : "success-free-listing-inactive"));
    }
  }, [ freeListingRes.isSuccess ]);

  useEffect(() => {
    if (comparatorRes.isSuccess) {
      dispatch(setCurrentFeed(comparatorRes.data));
      setFree({ ...free, feedcast: { enabled: !free.feedcast.enabled, disabled: false } });
      dispatch(NewSuccessNotification(!free.feedcast.enabled ? "success-feedcast-comparator" : "success-feedcast-comparator-inactive"));
    }
  }, [ comparatorRes.isSuccess ]);

  const toggleFreeListing = () => {
    if (currFeed) {
      freeListing({
        feed: currFeed.id,
        data: {
          feed_option: {
            enable_free_listing: !currFeed.feed_option.enable_free_listing,
          }
        }
      })
    }
  };

  const toggleFeedcastMarketplace = () => {
    if (currFeed) {
      comparator({
        feed: currFeed?.id,
        data: {
          feed_option: {
            export_comparator: !currFeed.feed_option.export_comparator,
          }
        }
      })
    }
  };

  return { free, toggleFreeListing, toggleFeedcastMarketplace };

}

export default useFreeDiffusion;
