import { Box, Divider, FormControl, InputAdornment, Stack, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { setAdsetName, setAdvantageBudget, setCampaignBudget } from "../../../../../app/slices/campaign/campaign";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Creative from "./Creative";
import { getAdset } from "../../../../../app/slices/campaign/getters";
import CampaignComponents from "../common/components";
import { Euro } from "@mui/icons-material";
import React from "react";
import { CampaignType } from "../../../../../app/types/campaign";
import CampaignCard from "../CampaignCard";
import { Globe } from "lucide-react";

const APGlobal = () => {
  const dispatch = useAppDispatch();
  const components = CampaignComponents();

  const editAdset = (val: string) => dispatch(setAdsetName(val));
  const cType = useAppSelector(state => state.campaign.type);

  const adset = useAppSelector(getAdset);
  const campaignBudget = useAppSelector(state => state.campaign.budget);

  const budgetValue = cType === CampaignType.META_SHOPPING_ADVANTAGE ? adset.daily_budget : campaignBudget;

  const editBudget = (val: number) => {
    dispatch(
      cType === CampaignType.META_SHOPPING_ADVANTAGE
        ? setAdvantageBudget(val)
        : setCampaignBudget(val)
    )
  }

  return (
    <Box>
      <CampaignCard icon={<Globe/>} title={"global-parameters"}>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              <FormControl fullWidth>
                <TextField
                  label={<FormattedMessage id={"budget-quotidien"}/>}
                  type={"number"}
                  required
                  value={budgetValue}
                  onChange={(e) => editBudget(parseFloat(e.target.value))}
                  inputProps={{
                    step: 0.01,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position={"end"}>
                      <Euro/>
                    </InputAdornment>
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 1, }}>
              {components.name}
            </Box>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"adset-group-name"}/>}
                required
                onChange={(e) => editAdset(e.target.value)}
                value={adset.name}
                helperText={<FormattedMessage id={'meta-adset'} />}
              />
            </FormControl>
          </Box>
          <Divider />
          <Box>
            <Creative/>
          </Box>
        </Stack>
      </CampaignCard>
    </Box>
  )
}

export default APGlobal;
