import { useAppDispatch, useAppSelector } from "../hooks";
import { isLoadingData, setIsLoadingData } from "../slices/app";
import { useEffect } from "react";

const useLoading = (componentLoading: boolean|null|undefined) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingData);

  useEffect(() => {
    if (componentLoading && !isLoading) {
      dispatch(setIsLoadingData(true));
    }
    else if (!componentLoading && typeof componentLoading === "boolean" && isLoading) {
      dispatch(setIsLoadingData(false));
    }
  }, [componentLoading]);
}

export default useLoading;
