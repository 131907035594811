import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getNewFeedCredentials,
  getNewFeedSource,
  NewFeedSteps,
  setNewFeedCredentials,
  setNewFeedStep,
  setNewFeedUrl, WoocommerceCredentials
} from "../../app/slices/newfeed";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Fade, FormControl, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import { Description, Phone, RssFeed, Store, VpnKey } from "@mui/icons-material";
import { NewFeedDesign } from "./FeedImportMethod";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { feedSources } from "../../app/slices/shop";
import { FeedSourceEnum } from "../../app/types/feed";

const Credentials = (p: NewFeedDesign) => {

  const dispatch = useAppDispatch();
  const source = useAppSelector(getNewFeedSource);
  const feedCredentials = useAppSelector(getNewFeedCredentials);
  const [ credentials, setCredentials ] = useState<Array<string>>([]);
  const sourceName = (source: FeedSourceEnum) =>
    source !== FeedSourceEnum.Manual
      ? FeedSourceEnum[source]?.charAt(0).toUpperCase() + FeedSourceEnum[source]?.slice(1)
      : "XML/CSV/TXT";

  useEffect(() => {
    if (source) {
      //@ts-ignore
      setCredentials(feedSources[source].credentials);
    }
  }, [ source ]);

  const showClassicUrlInput = source !== FeedSourceEnum.Woocommerce;

  // Fired before next step
  const addHttpsIfNotPresent = () => {
    if (Object.keys(feedCredentials).length > 0 && source === FeedSourceEnum.Woocommerce) {
      const creds = feedCredentials as WoocommerceCredentials;
      if (!creds.store.startsWith("https://")) {
        dispatch(setNewFeedCredentials({
          ...creds, store: `https://${creds.store}`,
        }))
      }
    }
  }

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addHttpsIfNotPresent();
          dispatch(setNewFeedStep(NewFeedSteps.PREVIEW));
        }}
      >
        {source && (
          <Grid2 spacing={2} container>
            <Grid2 xs={p.fullWidth ? 12 : 7}>
              <Typography><FormattedMessage id={'tuto-steps'}/></Typography>
              <TutoCarousel/>
            </Grid2>
            <Grid2 xs>
              <Fade in={source !== "fictif"}>
                <Box>
                  <Typography>
                    <FormattedMessage id={"new-feed-info"}/>
                  </Typography>
                  <Typography variant={"caption"} color={"text.secondary"}>
                    {source !== FeedSourceEnum.Manual && source !== FeedSourceEnum.GoogleSheets &&
                      <FormattedMessage id={"new-feed-info-desc"}/>}
                  </Typography>
                  <Box mt={1}>
                    {showClassicUrlInput && (
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            label={(
                              <FormattedMessage
                                id={'new-feed-url'}
                                values={{ source: FeedSourceEnum[source as FeedSourceEnum] }}/>
                            )}
                            required
                            margin={"dense"}
                            onChange={(e) => dispatch(setNewFeedUrl(e.target.value))}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position={"start"}>
                                  <RssFeed/>
                                </InputAdornment>
                              )
                            }}
                          />
                        </FormControl>
                      </Box>
                    )}

                    {credentials?.map((c, i) => <Fade
                        in={credentials.length > 0}
                        unmountOnExit timeout={{ enter: 700, exit: 350 }}
                        style={{ transitionDelay: `${i * 200}ms` }}
                        key={`asi-${c}-${i}`}
                      >
                        <Box mt={1}>
                          <FormControl fullWidth>
                            <TextField
                              label={<FormattedMessage id={c}/>}
                              required
                              margin={"dense"}
                              onChange={(e) => dispatch(
                                setNewFeedCredentials(
                                  { ...feedCredentials, [`${c}`]: e.target.value }
                                ))}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position={"start"}>
                                    {c !== "store" ? <VpnKey/> : <Store/>}
                                  </InputAdornment>
                                )
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Fade>
                    )}
                  </Box>

                  <Box display={"flex"} alignItems={"center"} mt={1}>
                    <Button type={"submit"} variant={"contained"}>
                      <FormattedMessage id={"continue-with"}/>
                      <span style={{ marginLeft: "0.3rem" }}>{sourceName(source as FeedSourceEnum)}</span>
                    </Button>
                    {p.actions}
                  </Box>
                </Box>
              </Fade>

            </Grid2>
          </Grid2>
        )}
      </form>
    </Box>
  )
}

const TutoCarousel = () => {
  const source = useAppSelector(getNewFeedSource);
  const [ tutorial, setTutorial ] = useState<Array<string>>([]);

  const [ type, setType ] = useState<"pictures" | "video" | "call" | "csv" | "sheets">("pictures");

  useEffect(() => {
    if (source === FeedSourceEnum.Webflow) {
      setType("video");
    } else if (source === FeedSourceEnum.Prestashop) {
      setType("call");
    } else if (source === FeedSourceEnum.Manual) {
      setType("csv");
    } else if (source === FeedSourceEnum.GoogleSheets) {
      setType("sheets");
    }

    if (source) {
      setTutorial(feedSources[source].tutorial ?? []);
    }
  }, [ source ]);
  return <Box height={'100%'}>
    {type === "pictures" && (
      <Paper sx={{ p: 1 }}>
        <Swiper
          modules={[ Navigation, Pagination, Scrollbar ]}
          navigation
          pagination={{ clickable: true }}
          style={{ borderRadius: '8px' }}
        >
          {tutorial?.map(t => <SwiperSlide key={`slide-${t}`}>
            <Box sx={{
              width: '100%',
              display: "flex",
              justifyContent: "center",
              background: '#fff',
            }}>
              <img
                src={t}
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '300px',
                }}
              />
            </Box>
          </SwiperSlide>)}
        </Swiper>
      </Paper>
    )}
    {type === "video" && (
      <video autoPlay controls loop style={{ width: '100%' }}>
        <source src={tutorial[0]} type={"video/mp4"}/>
      </video>
    )}
    {type === "call" && (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} height={'100%'}>
        <Button
          target={'_blank'}
          href={"https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ22VxxleUQM6jd3BPpowTF5jeQ1gud0kZZpi8Foo3qcjmnvbT_EhxhVGLv9myEdslghCaqbxap5"}
          variant={"outlined"}
          sx={{
            width: '100%',
            height: '100%'
          }}
          startIcon={<Phone/>}
        >
          <FormattedMessage id={"config-call"}/>
        </Button>
      </Box>
    )}
    {type === "csv" && (
      <Paper sx={{ p: 2 }}>
        <Typography variant={"body2"}>
          <FormattedMessage id={"newfeed-csv-tuto-1"}/>
        </Typography>
        <br/>
        <Typography variant={"body2"}>
          <FormattedMessage id={"newfeed-csv-tuto-2"}/>
        </Typography>
        <br/>
        <Typography variant={"body2"}>
          <FormattedMessage id={"newfeed-csv-tuto-3"}/>
        </Typography>
        <Stack direction={"row"} spacing={0.5}>
          <Typography variant={"body2"}>
            <FormattedMessage id={"more-info"}/>
          </Typography>
          <Typography
            variant={"body2"}
            component={'a'}
            href={"https://help.feedcast.io/fr/article/connecter-un-catalogue-produits-lxak3w/"}
            target={'_blank'}
          >
            <FormattedMessage id={"help-center"}/>
          </Typography>
        </Stack>
        <Box lineHeight={1} mt={1}>
          <Typography variant={"caption"}>
            <FormattedMessage id={"newfeed-csv-tuto-4"}/>
          </Typography>
        </Box>
      </Paper>
    )}
    {type === "sheets" && (
      <Paper sx={{ p: 2 }}>
        <Typography variant={"body2"}>
          <FormattedMessage id={"newfeed-sheets-tuto-1"}/>
        </Typography>
        <br/>
        <Typography variant={"body2"}>
          <FormattedMessage id={"newfeed-sheets-tuto-2"}/>
        </Typography>
        <Button
          startIcon={<Description/>}
          variant={"outlined"}
          sx={{
            mb: 2,
            mt: 1,
          }}
          target={'_blank'}
          href={"https://docs.google.com/spreadsheets/d/1U24bwzDYAYrDr0AGzficB2EIVaSrxwJYr9xQts371d8/edit#gid=0"}
        >
          Google Sheets Template
        </Button>
        <br/>
        <Stack direction={"row"} spacing={0.5}>
          <Typography variant={"body2"}>
            <FormattedMessage id={"more-info-google"}/>
          </Typography>
          <Typography
            variant={"body2"}
            component={'a'}
            target={'_blank'}
            href={"https://support.google.com/merchants/answer/7052112?"}
          >
            <FormattedMessage id={"google-support"}/>
          </Typography>
        </Stack>
        <Box lineHeight={1} mt={1}>
          <ul>
            <li>
              <Typography variant={"caption"}>
                <FormattedMessage id={"newfeed-sheets-tuto-3"}/>
              </Typography>
            </li>
            <li>
              <Typography variant={"caption"}>
                <FormattedMessage id={"newfeed-sheets-tuto-4"}/>
              </Typography>
            </li>
          </ul>
        </Box>
      </Paper>
    )}
  </Box>
}

export default Credentials;
