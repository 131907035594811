import { useState } from "react";

export const determineIfDowngrade = (preview: any): boolean => {
  return preview?.downgrade;
}
export const useSubscriptionModal = () => {
  const [ open, setOpen ] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return { open, handleClose, handleOpen };
}
