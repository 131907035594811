import React, { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentFeed, getCurrentFeedHasLoaded, getCurrentFeedSubStatus } from "../../../app/slices/app";
import UserProjects from "./ShopSelector/ShopSelector";
import { GetCurrentFeedId } from "../../../app/utils";
import { Box, Fade, Stack } from "@mui/material";
import UserMenu from "./UserMenu/UserMenu";
import { UserFeed } from "../../../app/types/feed";
import { AppNotifications } from "./AppNotifications/AppNotifications";
import { UpgradeButton } from "./UpgradeButton";
import AI_Credits from "./AI_Credits";

type AppHeaderProps = {
  lockNav?: boolean,
};

const shouldShowFeed = (currFeed: UserFeed | null, path: string): boolean =>
  currFeed !== null && !(path.includes('settings') || path.includes('projects'));

const shouldShowUpgrade = (feedId: number, currFeed: UserFeed | null, currFeedStatus: string, showFeed: boolean): boolean =>
  feedId > 0 && currFeedStatus === 'free' && showFeed;

const AppHeader: React.FC<AppHeaderProps> = ({ lockNav }) => {

  const currFeed = useAppSelector(getCurrentFeed);
  const currFeedStatus = useAppSelector(getCurrentFeedSubStatus);
  const feedId = GetCurrentFeedId();
  const path = window.location.pathname;

  const hasFeedLoaded = useAppSelector(getCurrentFeedHasLoaded);

  const showFeed = useMemo(() => shouldShowFeed(currFeed, path), [ currFeed, path ]);
  const showUpgrade = useMemo(() => shouldShowUpgrade(feedId, currFeed, currFeedStatus, showFeed), [ feedId, currFeed, currFeedStatus, showFeed ]);

  return (
    <Box id="header-wrapper" width="100%">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={3}>
          <UserProjects showFeed={showFeed}/>
          <Fade in={hasFeedLoaded && showUpgrade && currFeed !== null}>
            <Box>
              <UpgradeButton currFeed={currFeed}/>
            </Box>
          </Fade>
        </Stack>
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <AI_Credits/>
            <AppNotifications lockNav={lockNav}/>
            <UserMenu/>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default AppHeader;
