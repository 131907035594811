import { GetCurrentFeedId } from "../../../../app/utils";
import { useAppSelector } from "../../../../app/hooks";
import { getShop } from "../../../../app/slices/shop";
import React, { useMemo } from "react";
import { BigDataCard } from "./BigDataCard";
import Urls from "../../../../assets/js/Urls";
import { AdsClick } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";
import { Button, Link } from "@mui/material";
import { Link as LinkDOM } from "react-router-dom";

export const CampaignsCard = () => {
  const feed = GetCurrentFeedId();
  const shop = useAppSelector(getShop);
  const activeChannels = useMemo(() => [
    shop.accounts.hasChannelActive.google && "Google",
    shop.accounts.hasChannelActive.meta && "Meta",
    shop.accounts.hasChannelActive.microsoft && "Microsoft",
  ].filter(Boolean), [ shop.accounts.hasChannelActive ]);
  return (
    <BigDataCard
      icon={<AdsClick fontSize={"large"}/>}
      primary={
        shop.accounts.hasCampaigns.nb ? (
          <React.Fragment>
            {shop.accounts.hasCampaigns.nb + " "} <FormattedMessage id={'campaigns'}/>
          </React.Fragment>
        ) : (
          <FormattedMessage id={'no-campaign'}/>
        )
      }
      secondary={activeChannels.length ? (
        <FormattedMessage id={'channels'} values={{ channels: activeChannels.join(", ") }}/>
      ) : (
        <Button
          size={"small"}
          variant={'contained'}
          component={LinkDOM}
          to={Urls.configuration(feed)}
        >
          <FormattedMessage id={'enable-channel'}/>
        </Button>
      )}
    />
  )
}
