import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ProductReportingChannel } from "../../../../app/types/products";
import { Box, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { CatalogStatusProps, ChannelsCatalogProps } from '../CatalogStatus';

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<"doughnut"> = {
  plugins: {
    legend: {
      display: false
    },
  }
}

interface ProductDoughnutProps extends ChannelsCatalogProps {
  channelReporting: ProductReportingChannel | undefined,
  title?: string,
}

const ProductDoughnut = (p: ProductDoughnutProps) => {
  const theme = useTheme();
  const current = p.channelReporting?.current;
  const hasData = (p.channelReporting?.current?.total || 0) > 0;

  const intl = useIntl();

  const format = (s: string) => intl.formatMessage({ id: s });

  const data: ChartData<"doughnut"> = {
    labels: [ format("pendings"), format("errors"), format("warnings"), format("success") ],
    datasets: [
      {
        label: '# of Votes',
        data: [ current?.pendings ?? 0, current?.errors ?? 0, current?.warnings ?? 0, current?.success ?? 0 ],
        backgroundColor: [
          theme.palette.info.light,
          theme.palette.error.light,
          theme.palette.warning.light,
          theme.palette.success.light,
        ],
        borderColor: [
          theme.palette.info.main,
          theme.palette.error.main,
          theme.palette.warning.main,
          theme.palette.success.main,
        ],
        borderWidth: 1,
      },
    ],
  };

  return hasData ? (
    <Box>
      {p.title && (
        <Typography>
          {p.title}
        </Typography>
      )}
      <Doughnut
        data={data}
        options={options}
        style={{ maxHeight: p.page === "full" ? '150px' : '80px' }}/>
    </Box>
  ) : (
    <Box sx={{width:'100%', height:'100%', display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
      <Typography color={"text.secondary"}>
        No data!
      </Typography>
    </Box>
  )
}

export default ProductDoughnut;
