import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface DropAreaHeaderProps {
  inputF: React.RefObject<HTMLInputElement>,
}

export const DropAreaHeader = ({ inputF }: DropAreaHeaderProps) => {
  const openWindow = () => inputF.current?.click();
  return (
    <Paper
      elevation={4}
      sx={{
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 2,
        borderRadius: "10px !important",
        overflowY: "hidden !important",
      }}
    >
      <Box textAlign={"center"}>
        <Typography variant={"h6"} m={0}>
          <FormattedMessage id={"drag-drop-or"}/>
        </Typography>

        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => openWindow()}
        >
          <FormattedMessage id={"browse-files"}/>
        </Button>
      </Box>
      <Typography variant={"caption"} mt={1}>
        <FormattedMessage id={"supported-formats"}/>
      </Typography>
    </Paper>
  )
}
