import { SubFormProps } from "../SubForm";
import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ProductLimit from "./ProductLimit";
import React from "react";
import { MinProducts, Packs, SelectedPack } from "../constants";

interface ProductsDisplayProps extends SubFormProps, SelectedPack {

}

export const ProductsDisplay = (props: ProductsDisplayProps) => {
  const { currSub, selectedPack, ...p } = props;
  return (
    <Stack spacing={1}>
      <Typography color={"text.secondary"} lineHeight={1}>
        <FormattedMessage id={"products"}/>
      </Typography>
      {selectedPack !== "premium" ? (
        <ProductLimit
          currSub={currSub}
          packMinProducts={MinProducts.Autopilot}
          {...p}
        />
      ) : (
        <Typography variant={"subtitle2"}>
          <FormattedMessage id={'unlimited'} />
        </Typography>
      )}

    </Stack>
  )
}
