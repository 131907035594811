export enum CampaignType {
  UNKNOWN = -1,
  "NULL",
  "GOOGLE_SEARCH",
  "GOOGLE_SHOPPING_CLASSIC",
  "GOOGLE_SMART_SHOPPING",
  "GOOGLE_PERFORMANCE_MAX",
  "META_CLASSIC",
  "META_SHOPPING_ADVANTAGE",
  "BING_SEARCH",
  "BING_SHOPPING",
  "BING_PERF_MAX"
}

export type CampaignInterface = {
  // Common
  id: string | null,
  is_feedcast: boolean,
  name: string,
  type: CampaignType,
  budget: number,
  enabled: boolean,
  restrictions: string[],

  // Shared - Google
  target_roas?: number, //bidding_strategy === 3

  // Google Shopping Classic
  target_cpc: number,
  bidding_strategy: BiddingStrategy,

  filters: CampaignLocaleFilters,
} & Warmup;

export type CampaignSlice = {
  migrate: boolean,
  adgroup: AdGroup, // Google Classic Shopping
  asset_group: AssetGroup, // Google Performance Max
  adsets: AdSet[], // Meta Classic (Prospection/Retargeting) + Advantage+
  adgroup_bing: BingShopping, // Bing Shopping Campaigns
} & CampaignInterface;

export type CampaignApi = {
  adgroups: AdGroup[] | BingShopping[],
  asset_groups: AssetGroup[],
  adsets: Array<{ prospection: boolean, retargeting: boolean } & AdSet>,
} & CampaignInterface;

export type CampaignsApi = {
  account: string | null,
  campaigns: Array<CampaignInterface>
}

export type SetCampaignParams = {
  feed: number,
  provider: string,
  campaign: string | number,
  data: Partial<SetCampaignData>
}

export type SetCampaignData = Partial<CampaignInterface>;

export type CampaignTypeIdentifier =
  'perf-max'
  | 'shopping'
  | 'meta-classic'
  | 'meta-advantage'
  | 'microsoft-shopping';

export const CampaignTypeMapper: Record<CampaignTypeIdentifier, CampaignType> = {
  'perf-max': CampaignType.GOOGLE_PERFORMANCE_MAX,
  'shopping': CampaignType.GOOGLE_SHOPPING_CLASSIC,
  'meta-classic': CampaignType.META_CLASSIC,
  'meta-advantage': CampaignType.META_SHOPPING_ADVANTAGE,
  // 'warmup': CampaignType.UNKNOWN,
  'microsoft-shopping': CampaignType.BING_SHOPPING,
}

//@ts-ignore
export const CampaignIdentifierMapper: Record<CampaignType, CampaignTypeIdentifier> = {
  [CampaignType.GOOGLE_PERFORMANCE_MAX]: 'perf-max',
  [CampaignType.GOOGLE_SHOPPING_CLASSIC]: 'shopping',
  [CampaignType.META_CLASSIC]: 'meta-classic',
  [CampaignType.META_SHOPPING_ADVANTAGE]: 'meta-advantage',
  [CampaignType.BING_SHOPPING]: 'microsoft-shopping',
}

export type CampaignLocaleFilters = {
  location: Array<string>, //Array of Google country ID,
  language?: Array<string>, // Array of Google language ID
}

//==============================
// AdGroup
//==============================

export type AdGroup = {
  id: string | null,
  name: string,
  target_cpc?: number | null, // required for bidding_strategy = 1
  product_group: ProductGroupWrapper,
}

export type AssetGroup = {
  id: string | null, // null only for campaign creation
  name: string,
  assets: PerfMaxData,
  product_group: ProductGroupWrapper,
}

export type BingShopping = {
  id?: string, // for update
  name: string,
  languages?: string[], // by default language of feed
  product_partition: ProductGroupWrapper,
}

export type AdSet = {
  id: string | null, // null for creation,
  name: string,
  target_countries: string[],
  ads: MetaAd[],

  dsa_beneficiary: string,
  dsa_payor: string,

  // Classic
  age_min: number,
  age_max: number,
  events: MetaEvent[],
  platforms: MetaPlatform[],
  genders: MetaGender[],
  interests: Behaviour[],
  behaviours: Interest[],

  // Advantage+
  daily_budget?: number,
}

export type MetaAd = {
  id: string | null, //null for creation
  name: string,
  creative: MetaCreative,
}

export type MetaEvent = {
  // For prospection
  type: MetaEventType,
  is_included: boolean,
  days: number, //today - days
}

export type MetaEventType = "Purchase" | "AddToCart" | "ViewContent";

export type MetaCreative = {
  name: string,
  page: string, // FB Page ID
  link: string, // Website Link
}

export type MetaPlatform = "facebook" | "audience_network" | "messenger" | "instagram";

export enum MetaGender {
  male = 1,
  female,
}

export type MetaClassicType = "prospection" | "retargeting";

//==============================
// Product & Pricing
//==============================

export type ProductGroup = Partial<Record<AggregationsType, Array<string>>>;

export type ProductGroupWrapper = {
  is_included: boolean,
  data: ProductGroup
}

export type AggregationsType = "id" | "brand" | "product_type" | "label0" | "label1" | "label2" | "label3" | "label4";

export type AggregationsData = Record<AggregationsType, Array<string>>;

//==============================
// Account & Provider
//==============================

export type Company = 'google' | 'meta' | 'microsoft';

export type AvailableCompany = Extract<Company, "google" | "meta" | "microsoft">;

export type AdsProvider = "go" | "fb" | "bg" | "fl";

export type LinkAdsAccountApi = {
  feed: number,
  provider: string,
  account_code: string,
}

export type CreateAdsAccountApi = {
  feed: number,
  provider: string,
  data: CreateAdsAccountData
}

export type CreateAdsAccountData = {
  name: string,
  email: string,
  currency?: string,
  timezone?: string,
}

export type AdsAccountRes = {
  success: boolean,
  account: {
    id: string,
    name: string,
  }
}

export type CompanyType = {
  available: boolean,
  image?: string,
  provider: AdsProvider,
  icon: any,
}

export type AdsCampaignType = {
  logo: string,
  title: string,
  identifier: CampaignTypeIdentifier | null,
  company: Company | null,
  provider: AdsProvider | "warmup" | null,
}

//==============================
// Targeting & Behavior
//==============================

export type AssetsTexts = "long_headlines" | "headlines" | "descriptions";

export type PerfMaxPictureNode = 'logos' | 'marketing_images' | 'marketing_images_square' | "marketing_images_portrait";

export type PerfMaxData = {
  business_name: string,
  descriptions: Array<string>,
  headlines: Array<string>,
  long_headlines: Array<string>,
  logos: Array<string>,
  marketing_images: Array<string>,
  marketing_images_square: Array<string>,
  marketing_images_portrait: Array<string>,
  youtube_ids?: Array<string>
  warnings?: PerfMaxAssetsWarning[],
}

export type PerfMaxAssetsWarning = {
  value: string,
  error_code: string,
  blocking: boolean,
}

export enum BiddingStrategy {
  "NULL",
  "manual_cpc",
  "target_roas",
  "maximize_clicks"
}

export type Behaviour = {
  id: string,
  name: string,
};

export type Interest = Behaviour;

//==============================
// Misc
//==============================

export type Warmup = {
  budget_google: number | null,
  budget_facebook: number | null,
  page_id: string | null,
  target_countries: string[],
}

export type MetaCatalog = {
  catalog: null | MCatalog,
  pixels: Pixel[],
  has_payment: boolean | null,
}

export type MCatalog = {
  id: string,
  name: string,
}

export type Pixel = {
  id: string,
  name: string
}




