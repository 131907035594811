import { AvailableCompany } from "../../../../../app/types/campaign";
import { useAppSelector } from "../../../../../app/hooks";
import { getShopAccounts } from "../../../../../app/slices/shop";
import { Alert, AlertTitle, Box, Button, Collapse, Divider, Stack } from "@mui/material";
import { MerchantWarnings } from "./MerchantCenter/MerchantWarnings";
import { FormattedMessage } from "react-intl";
import React from "react";
import { OpenInNew, Support } from "@mui/icons-material";
import { capitalizeFirstLetter } from "../../../../../app/utils";
import { getLanguage, isLoadingData } from "../../../../../app/slices/app";

interface ChannelBannerProps {
  company: AvailableCompany,
}

type CorrectionLinksObject = {
  payment: { helpdesk: { fr: string, en: string }, channel: string },
  tracking: { helpdesk: { fr: string, en: string }, channel: string }
}

const correctionLinks: Record<AvailableCompany, CorrectionLinksObject> = {
  "google": {
    payment: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/comment-ajouter-ses-informations-de-facturation-sur-google-ads-145625a/",
        en: "https://help.feedcast.io/en/article/how-to-add-your-billing-information-to-google-ads-1usgtxo/",
      },
      channel: "https://ads.google.com/aw/billing/signup",
    },
    tracking: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/comment-mettre-en-place-le-suivi-des-conversions-in6gfo/",
        en: "https://help.feedcast.io/en/article/how-to-set-up-conversion-tracking-gjwo79/"
      },
      channel: "https://ads.google.com/aw/conversions",
    }
  },
  "meta": {
    payment: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/comment-mettre-en-place-le-suivi-des-conversions-in6gfo/",
        en: "https://help.feedcast.io/en/article/how-to-add-a-payment-method-to-meta-facebook-ads-instagram-ads-12farnm/",
      },
      channel: "https://business.facebook.com/ads/manager/billing_history/summary",
    },
    tracking: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/comment-mettre-en-place-le-suivi-des-conversions-in6gfo/",
        en: "https://help.feedcast.io/en/article/how-to-set-up-conversion-tracking-gjwo79/"
      },
      channel: ""
    }
  },
  "microsoft": {
    payment: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/ajouter-ses-informations-de-facturation-sur-microsoft-advertising-1blm05v/",
        en: "https://help.feedcast.io/en/article/add-billing-information-to-microsoft-advertising-jipe72/"
      },
      channel: ""
    },
    tracking: {
      helpdesk: {
        fr: "https://help.feedcast.io/fr/article/comment-mettre-en-place-le-suivi-des-conversions-in6gfo/",
        en: "https://help.feedcast.io/en/article/how-to-set-up-conversion-tracking-gjwo79/"
      },
      channel: ""
    }
  }
}

export const ChannelBanner = ({ company }: ChannelBannerProps) => {
  const accounts = useAppSelector(getShopAccounts);
  const loading = useAppSelector(isLoadingData);
  const displayTrackingAlert = !loading && accounts.hasChannelActive[company] && !accounts.hasTracking[company];
  const displayPaymentAlert = !loading && accounts.hasChannelActive[company] && !accounts.hasPayment[company];
  return (
    <Box>
      {company === "google" && (
        <MerchantWarnings/>
      )}
      <Collapse in={displayTrackingAlert} unmountOnExit>
        <>
          <Alert severity={"error"} sx={{ borderRadius: 0 }}>
            <AlertTitle>
              <FormattedMessage id={"no-tracking-data"}/>
            </AlertTitle>
            <Stack spacing={1}>
              <Box>
                <FormattedMessage id={'no-tracking-data-desc'} values={{
                  b: (txt) => (<b>{txt}</b>),
                  br: <br/>,
                }}
                />
              </Box>
              <AlertActions company={company} type={"tracking"}/>
            </Stack>
          </Alert>
          <Divider/>
        </>
      </Collapse>
      <Collapse in={displayPaymentAlert} unmountOnExit>
        <>
          <Alert severity={"error"} sx={{ borderRadius: 0 }}>
            <AlertTitle>
              <FormattedMessage id={"no-channel-payment"}/>
            </AlertTitle>
            <Stack spacing={1}>
              <Box>
                <FormattedMessage id={"no-channel-payment-desc"} values={{
                  br: <br/>,
                }}
                />
              </Box>
              <AlertActions company={company} type={"payment"}/>
            </Stack>
          </Alert>
          <Divider/>
        </>
      </Collapse>
    </Box>
  )
}

interface AlertActionsProps extends ChannelBannerProps {
  type: "payment" | "tracking",
}

const AlertActions = ({ company, type }: AlertActionsProps) => {
  const lang = useAppSelector(getLanguage);
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button
        size={"small"}
        color={"inherit"}
        variant={"outlined"}
        component={'a'}
        target={'_blank'}
        endIcon={<Support/>}
        href={correctionLinks[company][type].helpdesk[lang]}
      >
        Consulter le Helpdesk
      </Button>
      <Button
        size={"small"}
        color={"inherit"}
        variant={"outlined"}
        component={'a'}
        target={'_blank'}
        endIcon={<OpenInNew/>}
        href={correctionLinks[company][type].channel}
      >
        Interface {capitalizeFirstLetter(company)}
      </Button>
    </Box>
  )
}
