import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CountryInput } from "./MCenter";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Alert,
  Box,
  Button, Fade,
  FormControl,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import Rates from "./Rates";
import { ShippingType, ShippingTypes } from "../../../../../../app/types/feed";

/**
 * Shippings.tsx
 * Merchant Center shipping configuration
 */

type ShippingsProps = {
  s?: Array<ShippingType>
}

const Shippings = ({ s }: ShippingsProps) => {
  const [ shippings, setShippings ] = useState<Array<Partial<ShippingType>>>([]);

  const addNewShipping = () => {
    const s = [ ...shippings ];
    s.push({ type: 1 });
    setShippings(s);
  }

  useEffect(() => {
    if (s && s.length > 0) {
      setShippings(s);
    }
  }, [ s ]);

  return <Box>
    <Box mb={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography variant={"subtitle2"}>
        <FormattedMessage id={"shipping-cost"} />
      </Typography>
      <Button
        size={'small'}
        variant={"contained"}
        color={"secondary"}
        onClick={() => addNewShipping()}
        startIcon={<Add/>}
      >
        <FormattedMessage id={"add-shipping"}/>
      </Button>
    </Box>

    {shippings.map((s, i) =>
      <Shipping
        key={`shipping-${s.name}`}
        s={s}
        i={i}
        onDelete={() => {
          setShippings([ ...shippings ].filter((p, j) => j !== i));
        }}
      />
    )}
    {!shippings.length && (
      <Alert variant={"outlined"} severity={"warning"}>
        <FormattedMessage id={"one-shipping-minimum"}/>
      </Alert>
    )}
  </Box>
}

type ShippingProps = {
  s: Partial<ShippingType>,
  i: number,
  onDelete: () => void,
}

const Shipping = ({ s, i, onDelete }: ShippingProps) => {
  const intl = useIntl(),
    fMsg = (s: string) => intl.formatMessage({ id: s });

  const [ type, setType ] = useState<ShippingTypes>();

  const [ name, setName ] = useState(s.name ?? "");

  useEffect(() => {
    s.type && setType(s.type === 1 && !s.fixed_price ? ShippingTypes.Free : s.type);
  }, [ s ]);

  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMore/>}>
      <Stack direction={"row"} width={'100%'} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant={"h6"} lineHeight={1}>
          {name ? name : <FormattedMessage id={"ship-value"} values={{ i: i + 1 }}/>}
        </Typography>
        <Box>
          <IconButton
            size={"small"}
            onClick={() => onDelete()}
          >
            <Delete/>
          </IconButton>
        </Box>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>

      <Grid2 container spacing={1}>
        <Grid2 xs={8}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][name]`}
              label={fMsg("name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={4}>
          <FormControl fullWidth>
            <CountryInput
              name={`shipping[${i}][country]`}
              value={s.country}
              label={fMsg("country")}
            />
          </FormControl>
        </Grid2>

        <Grid2 xs={12}>
          <Box display={'flex'} gap={1} alignItems={"center"}>
            <Typography variant={"caption"} >
              Type
            </Typography>
            <ToggleButtonGroup
              size={"small"}
              value={type}
              exclusive
              onChange={(e, value) => setType(value as any)}
            >
              <ToggleButton value={ShippingTypes.Free}>
                <FormattedMessage id={"free"}/>
              </ToggleButton>
              <ToggleButton value={ShippingTypes.Fixed}>
                <FormattedMessage id={"fixed"}/>
              </ToggleButton>
              <ToggleButton value={ShippingTypes.Ranged}>
                <FormattedMessage id={"ranged"}/>
              </ToggleButton>
              <ToggleButton value={ShippingTypes.Weight}>
                <FormattedMessage id={"ranged-weight"}/>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid2>

        <Grid2 xs={12}>
          <Typography variant={"caption"} color={"text.secondary"}>
            <FormattedMessage id={"handling-help"}/>
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][handling_min]`}
              label={fMsg("handling-min")}
              type={"number"}
              defaultValue={s.handling_min}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][handling_max]`}
              label={fMsg("handling-max")}
              type={"number"}
              defaultValue={s.handling_max}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant={"caption"} color={"text.secondary"}>
            <FormattedMessage id={"delivery-help"}/>
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][delivery_min]`}
              label={fMsg("delivery-min")}
              type={"number"}
              defaultValue={s.delivery_min}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][delivery_max]`}
              label={fMsg("delivery-max")}
              type={"number"}
              defaultValue={s.delivery_max}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant={"caption"} color={"text.secondary"} >
            <FormattedMessage id={"max-hour-help"}/>
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <FormControl fullWidth>
            <TextField
              size={"small"}
              name={`shipping[${i}][max_hour]`}
              label={fMsg("max-hour")}
              type={"number"}
              defaultValue={s.max_hour}
              required
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant={"caption"} color={"text.secondary"} >
            <FormattedMessage id={"price"}/>
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Box display={"none"}>
            <input
              type={"number"}
              name={`shipping[${i}][type]`}
              value={!type ? 1 : type}
            />
          </Box>

          {type === ShippingTypes.Free && (<Box display={"none"}>
              <input
                type={"number"}
                name={`shipping[${i}][fixed_price]`}
                step={0.01}
                value={0}/>
            </Box>
          )}

          {(type === ShippingTypes.Fixed || type === ShippingTypes.Free) && (
            <Fade in={true} unmountOnExit>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    size={"small"}
                    type={"number"}
                    disabled={type === ShippingTypes.Free}
                    name={`shipping[${i}][fixed_price]`}
                    defaultValue={s.fixed_price ?? 0}
                    label={<FormattedMessage id={"fixed-price"}/>}
                    required
                    inputProps={{
                      step: 0.01,
                    }}
                  />
                </FormControl>
              </Box>
            </Fade>
          )}
          {
            (type === ShippingTypes.Ranged || type === ShippingTypes.Weight) && (
              <Fade in={true} unmountOnExit>
                <Box mt={1}>
                  <Typography color={'text.secondary'}>
                    <FormattedMessage id={"rates"} />
                  </Typography>
                  <Rates
                    r={s.rates}
                    shippingIndex={i}
                    type={type}
                  />
                </Box>
              </Fade>
            )
          }
        </Grid2>
      </Grid2>
    </AccordionDetails>

  </Accordion>
}

export default Shippings;
