import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { SubInterface, SubscriptionState } from "../Subscription";
import { Box, Fade, FormControl, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useSubscription } from "../hooks/useSubscription";

/**
 * LimitCard.tsx
 * Selector for limit number of products available in given plan
 */

interface LimitCardProps extends SubInterface {
  min: number | null | undefined,
  onLimitChange: (limitId: number) => void,
  packMinProducts: number,
}

const ProductLimit = ({ min, onLimitChange, currSub, packMinProducts }: LimitCardProps) => {

  const [ sub ] = useSubscription(currSub.feed);
  const limit = currSub.limit ?? 0;

  const limits = sub.data?.prices.filter(p => p.type === "limits").sort((a, b) => (a?.max_products || 0) - (b?.max_products || 0)) ?? []

  const selectedLimit = limits?.find(l => l.id === limit);

  useEffect(() => {
    if (sub.isSuccess) {
      const l = sub.data.prices.find(p => p.type === "limits" && p.selected)?.id ?? 0;
      onLimitChange(l);
    }
  }, [ sub ]);

  return <Box>
    <Stack direction={"row"} spacing={2} alignItems={"end"}>
      <FormControl sx={{width:'50%', mt: 1}}>
        <TextField
          select
          size={"small"}
          sx={{
            minWidth:'75px'
          }}
          value={limit}
          disabled={!currSub.pack}
          onChange={(e) => onLimitChange(parseInt(e.target.value) === limit ? 0 : parseInt(e.target.value))}
        >
          <MenuItem value={0}>
            {packMinProducts}
          </MenuItem>
          {limits?.map(l => <MenuItem
            key={`l-${l.id}`}
            value={l.id}
            //@ts-ignore
            disabled={l.max_products && (min ?? 0) && (l?.max_products || 0) <= (min || 0)}
          >
            {l.max_products}
          </MenuItem>)}
        </TextField>
      </FormControl>
      <Fade in={(selectedLimit?.price || 0) > 0}>
        <Box textAlign={"start"}>
          <Typography variant={"h6"} lineHeight={0.8}>+{selectedLimit?.price ?? 0}€</Typography>
          <Typography variant={"caption"} color={"text.secondary"}><FormattedMessage id={"ht-month"}/></Typography>
        </Box>
      </Fade>
    </Stack>
  </Box>
}

export default ProductLimit;
