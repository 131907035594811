import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";
import { GetCurrentFeedId } from "../../../../../app/utils";
import campaignApi from "../../../../../app/services/secured/campaign";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import SimpleForm from "../../../../App/Blocks/SimpleForm";
import { FormattedMessage } from "react-intl";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from "@mui/material";

import { MetaCatalog } from "../../../../../app/types/campaign";

const ModalCatalogPixel = () => {
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch(),
    show = useAppSelector(isModalOpened(ModalTypes.CatalogPixel)),
    hide = () => dispatch(closeModal())
  ;
  const [ getMetaCatalog, metaCatalog ] = campaignApi.useLazyGetMetaCatalogQuery();
  useEffect(() => {
    if (show) {
      getMetaCatalog({ feed }, true);
    }
  }, [ show ]);
  return <Dialog open={show} onClose={hide}>
    <DialogTitle>
      <FormattedMessage id={"config-pixel-meta"}/>
      <Typography variant={"body2"}>
        <FormattedMessage id={"config-pixel-meta-desc"}/>
      </Typography>
    </DialogTitle>
    <DialogContent>
      <CatalogPixel data={metaCatalog.data}/>
    </DialogContent>
  </Dialog>
}


type CatalogPixelProps = {
  data: MetaCatalog | undefined
}

const CatalogPixel = (p: CatalogPixelProps) => {
  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const pixels = p.data?.pixels ?? [];

  const [ catalog, setCatalog ] = useState<{ pixel_id: string, name: string }>({ pixel_id: "", name: "" });
  const [ createCatalog, createCatalogRes ] = campaignApi.useSetMetaCatalogMutation();

  useEffect(() => {
    if (createCatalogRes.isSuccess) {
      dispatch(NewSuccessNotification("success-meta-catalog"));
      dispatch(closeModal());
    }
  }, [ createCatalogRes ]);

  return (<Box key={`${p.data?.pixels.length}`} mt={1}>
    {!pixels.length
      ? <Box textAlign={"center"} width={'100%'}>
        <CircularProgress size={25} sx={{ mb: 1 }}/>
        <Typography color={"text.secondary"}><FormattedMessage id={"fetching-pixels"}/></Typography>
      </Box>
      : <SimpleForm onSubmit={() => createCatalog({ feed, body: catalog })}>
        <Autocomplete
          renderInput={(params) =>
            <TextField
              {...params}
              label={<FormattedMessage id={"select-pixel"}/>}
            />}
          options={pixels.map(p => {
            return { label: p.name, id: p.id }
          })}
          onChange={(o, val) => {
            val && setCatalog({ ...catalog, pixel_id: val?.id });
          }}
        />

        <FormControl margin={"normal"} fullWidth>
          <TextField
            required
            label={<FormattedMessage id={"catalog-name"}/>}
            onChange={e => setCatalog({ ...catalog, name: e.target.value })}
          />
        </FormControl>

        <Box display={"flex"} justifyContent={"end"}>
          <LoadingButton
            data={createCatalogRes}
            type={"submit"}
            variant={"contained"}
          >
            <FormattedMessage id={"save"}/>
          </LoadingButton>
        </Box>
      </SimpleForm>}
  </Box>)
}

export default ModalCatalogPixel;
