import { SubscriptionState } from "../Subscription";
import { useSubscription } from "./useSubscription";
import { useMemo } from "react";
import { sumReducer } from "../../../../../app/utils";

export const useSubscriptionManagement = (currSub: SubscriptionState, currSubStatus: string, selectedPackCode: string | null) => {
  const { pack, limit, promotion_code, platform, ai } = currSub;
  const [ sub ] = useSubscription(currSub.feed);

  const selectedProducts = useMemo(() =>
      sub.data?.prices.filter(s => [ pack, limit, platform, ai ].includes(s.id)),
    [ sub, pack, limit, platform, ai ]
  );

  const currentPlan = useMemo(() =>
      sub.data?.prices.filter(p => p.selected).map(p => p.id) ?? [],
    [ sub ]
  );

  const isCurrentSub = useMemo(() => {
    return (
      (currentPlan.includes(pack)
        && (!limit || currentPlan.includes(limit))
        && (!platform || currentPlan.includes(platform))
        && (!ai || currentPlan.includes(ai))
      ) ||
      (currSubStatus === "free" && !pack) ||
      (currSubStatus === "trial" && selectedPackCode === "starter" && (!limit || currentPlan.includes(limit)) && (!platform || currentPlan.includes(platform)))
    );
  }, [ currentPlan, pack, limit, currSubStatus, selectedPackCode, ai, platform ]);

  const total = useMemo(() => {
    return selectedProducts?.map(s => {
      return s.code === "starter" && promotion_code?.length && promotion_code === process.env.REACT_APP_COUPON
        ? 29
        : s.price;
    }).reduce(sumReducer, 0);
  }, [ promotion_code, selectedProducts ]);

  return {
    selectedProducts,
    total,
    isCurrentSub
  };
};
