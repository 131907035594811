import { useEffect, useState } from "react";
import { AvailableCompany } from "../../../../app/types/campaign";
import { useAppSelector } from "../../../../app/hooks";
import { getShopAccounts } from "../../../../app/slices/shop";
import { getCurrentFeed } from "../../../../app/slices/app";

interface useChannelStatusProps {
  company: AvailableCompany | "feedcast",
}

export type ChannelState = "active" | "disabled" | "alerts" | "error" | "no-tracking" | "configuration" | "no-payment";

export const ChannelColor: Record<ChannelState, string> = {
  "active": "success.main",
  "disabled": "text.disabled",
  "alerts": "warning.main",
  "error": "error.main",
  "no-tracking": "error.main",
  "configuration": "text.main",
  "no-payment": "error.main",
}

const useChannelStatus = ({ company }: useChannelStatusProps) => {

  const feed = useAppSelector(getCurrentFeed);

  const [ enabled, setEnabled ] = useState(false);
  const [ channelState, setChannelState ] = useState<ChannelState>("disabled");

  const accounts = useAppSelector(getShopAccounts);
  const companySlice = company !== "feedcast" ? company : "google";
  const hasTracking = accounts.hasTracking[companySlice];
  const hasPayment = accounts.hasPayment[companySlice];

  const companySelect = useAppSelector(state => state.shop.accounts.hasAccess[companySlice]);
  const canCreateCampaigns = useAppSelector(state => state.shop.accounts.canCreateCampaigns[companySlice]);
  const hasAccountAccess = company !== "feedcast" ? companySelect : true;
  const alertsSlice = useAppSelector(state => state.shop.accounts.hasAlerts[companySlice]);
  const hasAlerts = company !== "feedcast" ? alertsSlice : false;

  useEffect(() => {
    if (company === "feedcast") {
      setChannelState(enabled ? "active" : "disabled");
      return;
    }

    if (hasAlerts) {
      setChannelState("alerts");
    } else if (!hasAccountAccess) {
      setChannelState("error");
    } else if (canCreateCampaigns) {
      if (enabled) {
        if (hasTracking) {
          setChannelState(hasPayment ? "active" : "no-payment");
        } else {
          setChannelState("no-tracking");
        }
      } else {
        setChannelState("disabled");
      }
    } else {
      setChannelState("configuration");
    }
  }, [ canCreateCampaigns, hasAlerts, hasAccountAccess, hasTracking, enabled, companySlice, hasPayment]);

  useEffect(() => {
    if (feed && feed.platforms && company !== "feedcast") {
      setEnabled(feed.platforms[`${company}_enabled`]);
    } else if (feed && company === "feedcast") {
      if ((feed?.last_stat?.imported || 0) > 0) {
        setEnabled(feed.feed_option.export_comparator);
      } else {
        setEnabled(false);
      }
    }
  }, [ feed ]);

  return {
    enabled,
    channelState
  }
}

export default useChannelStatus;
