import { useAppDispatch, useAppSelector } from "../hooks";
import campaignApi from "../services/secured/campaign";
import { getShop } from "../slices/shop";
import { useCallback, useEffect, useMemo } from "react";
import { resetNewFeed } from "../slices/newfeed";
import profileApi from "../services/secured/profile";
import { getUser, redirect, setCurrentFeed } from "../slices/app";
import { StorageManager } from "../storage";
import feedApi from "../services/secured/feed";
import { UserFeed } from "../types/feed";
import Urls from "../../assets/js/Urls";
import useJune from "./useJune";
import { GetCurrentFeedId } from "../utils";
import logsApi from "../services/secured/logs";

export const useAppInit = () => {

  // Global
  const currentFeedId = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  // Selectors
  const userState = useAppSelector(getUser);
  const shop = useAppSelector(getShop);

  // Custom hooks
  const analytics = useJune();

  // Memos
  const currentFeedIdMemo = useMemo(() => currentFeedId, [ currentFeedId ]);

  // API Queries
  const meQuery = profileApi.useGetMeQuery();
  const [ getFeedQuery, feed ] = feedApi.useLazyGetFeedQuery();
  const [ getSubscriptionQuery ] = feedApi.useLazyGetSubscriptionQuery();
  const [ getAssets ] = campaignApi.useLazyGetAssetsQuery();

  // Variables
  const wasUserLogged = (StorageManager.get()?.length || 0) > 0;
  const isUserIdentified = meQuery.isSuccess;
  const canUserSubscribe = meQuery.data?.can_subscribe;
  const isUserMerchant = meQuery.isSuccess ? meQuery.data?.is_merchant : true;
  const hasShopActive = currentFeedId > 0;
  const isPendingValidation = meQuery.data?.pending_validation ?? null;

  const initHistoricNotifications = logsApi.useGetNotificationsHistoryQuery();
  const inAppIssues = logsApi.useGetInAppIssuesQuery();

  const setCurrentFeedMemoized = useCallback(
    (data: UserFeed) => dispatch(setCurrentFeed(data)),
    [ dispatch ]
  );

  // Fetch
  useEffect(() => {
    if (isUserIdentified && hasShopActive) {
      getFeedQuery(currentFeedId, true);
      if (canUserSubscribe) {
        getSubscriptionQuery(currentFeedId, true);
      }
    } else if (meQuery.isError) {
      StorageManager.clear();
      dispatch(redirect(Urls.auth.login));
    }
  }, [ currentFeedIdMemo, isUserIdentified, canUserSubscribe, meQuery.isError ]);

  // Set current feed memo in state
  useEffect(() => {
    if (feed.isSuccess) {
      setCurrentFeedMemoized(feed.data);
    }
  }, [ feed.isSuccess, setCurrentFeedMemoized, feed.data ]);

  // If feed is error, go back to project list
  useEffect(() => {
    if (feed.isError) {
      dispatch(redirect(Urls.projects.list))
    }
  }, [ feed.isError ]);

  // Launch june analytics on each page
  useEffect(() => {
    if (analytics && userState && !userState.is_guest) {
      analytics.page()
    }
  }, [ analytics, userState ]);

  // Get alerts & perfmax assets
  useEffect(() => {
    if (shop.shop === currentFeedIdMemo) {
      dispatch(resetNewFeed);
      if (shop.accounts.hasLinkedAccount.google && shop.accounts.hasAccess.google) {
        getAssets({ feed: currentFeedIdMemo }, true);
      }
    }
  }, [ currentFeedIdMemo, shop.shop, shop.accounts.hasLinkedAccount.google ]);

  return {
    wasUserLogged,
    isUserIdentified,
    isUserMerchant,
    isPendingValidation
  }
}
