import { GetCurrentFeedId } from "../../../app/utils";
import productsApi from "../../../app/services/secured/products";
import React, { useEffect, useMemo, useState } from "react";
import FTable from "../../App/Blocks/Table";
import { FormattedMessage } from "react-intl";
import { LoadingButton, TableLoader } from "../../App/Blocks/Misc";
import FCard, { FCardHeader } from "../../App/Blocks/Card";
import { useAppDispatch } from "../../../app/hooks";
import { NewSuccessNotification } from "../../../app/slices/notifications";
import { CategoryMap } from "../../../app/types/products";
import { Alert, AlertTitle, Box, ButtonGroup, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { Category, Check, Clear, Edit, InsertLink, LinkOff } from "@mui/icons-material";
import { GoogleCategorySelect } from "../../App/Blocks/GoogleCategorySelect";
import { useProductCategory } from "../../../app/hooks/useCategories";
import Table from "../../App/Blocks/Table";

/**
 * CategoriesMapping.tsx
 * Allows user to link personal categories to Google official categories.
 */

const CategoriesMapping = () => {
  const feed = GetCurrentFeedId();
  const c = productsApi.useGetCategoriesQuery({ feed });
  const hasCategoriesToMap = (c.data?.length || 0) > 0;

  const catsTable = useMemo(() => {
    return <Box>
      {c.data && (
        <Table size={"small"} className={"no-border-last-row"} header={(
          <React.Fragment>
            <TableCell style={{ width: '30%' }}>
              <FormattedMessage id={"category"}/>
            </TableCell>
            <TableCell><FormattedMessage id={"category-google"}/></TableCell>
            <TableCell/>
          </React.Fragment>
        )}>
          <TableLoader query={c} colspan={2} hasData={c.data?.length > 0}>
            {c.data.map(c => {
              return <CatRow key={c.id} {...c} />
            })}
          </TableLoader>
        </Table>
      )}
    </Box>
  }, [ c.data ]);

  return (
    <Stack spacing={2}>
      <Alert severity={"info"}>
        <AlertTitle>
          <FormattedMessage id={"categories-desc"}/>
        </AlertTitle>
        <FormattedMessage id={'categories-explanation'} />
      </Alert>
      <FCard variant={hasCategoriesToMap ? 'no-padding' : undefined}>
        {hasCategoriesToMap
          ? catsTable
          : <Box>
            <Typography gutterBottom>
              <FormattedMessage id={"no-categories-to-map"}/>
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              <FormattedMessage id={"no-categories-to-map-desc"}/>
            </Typography>
          </Box>
        }
      </FCard>
    </Stack>
  )
}

const CatRow = (mapping: CategoryMap) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const [ isEdit, setEdit ] = useState(false);
  const [ categoryId, setCategoryId ] = useState(mapping.category?.id);
  const category = useProductCategory(mapping.category?.id);

  const [ setCat, setCatRes ] = productsApi.useSetCategoryMutation();

  const validLink = () => categoryId && setCat({ feed, mappingId: mapping.id, googleCategory: categoryId });

  useEffect(() => {
    if (setCatRes.isSuccess) {
      dispatch(NewSuccessNotification("category-map-success"));
      setEdit(false);
    }
  }, [ setCatRes ]);

  return <TableRow>
    <TableCell>
      <Box sx={{ display: "flex", gap: 1 }}>
        {mapping.is_auto && (
          <LinkOff color={"warning"}/>
        )}
        {mapping.original_value}
      </Box>
    </TableCell>
    <TableCell>
      {!isEdit
        ? category?.title
        : (
          <GoogleCategorySelect
            label={false}
            defaultSelected={category}
            onChange={(categoryId) => setCategoryId(categoryId)}
          />
        )
      }
    </TableCell>
    <TableCell align={"right"}>
      {!isEdit ? (
        <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
          {mapping.is_auto
            && (<LoadingButton
              data={setCatRes}
              onClick={(e: any) => {
                e.preventDefault();
                validLink();
              }}
              variant={"outlined"}
              color={"info"}
              size={"small"}
              endIcon={<InsertLink/>}
            >
              <FormattedMessage id={"category-auto-link"}/>
            </LoadingButton>)
          }
          <IconButton size={"small"} onClick={() => setEdit(true)}>
            <Edit fontSize={'small'}/>
          </IconButton>
        </Box>
      ) : (
        <ButtonGroup>
          <IconButton onClick={() => setEdit(false)}>
            <Clear/>
          </IconButton>
          <IconButton color={"success"} onClick={() => validLink()}>
            <Check/>
          </IconButton>
        </ButtonGroup>)}
    </TableCell>
  </TableRow>
}

export default CategoriesMapping;
