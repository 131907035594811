import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import campaignApi from "../services/secured/campaign";
import feedApi from "../services/secured/feed";

type NewNotification = {
  type: "success" | "error" | "info" | "warning" | "secondary" | "primary",
  message: string,
  loader?: boolean,
  infinite?: boolean,
};

export type Notification = NewNotification & {
  id: number
};

const initialState: Array<Notification> = [];

const SuccessNotification = (message: string, callback?: () => void): Notification => ({
  id: Math.random(),
  type: "success",
  message,
});

const DangerNotification = (message: string): Notification => ({
  id: Math.random(),
  type: "error",
  message,
});

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NewNotification>) => {
      state.push({
        id: Math.random(),
        type: action.payload.type,
        message: action.payload.message,
        infinite: action.payload.infinite,
        loader: action.payload.loader,
      });
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      return state.filter((n: Notification) => n.id !== action.payload);
    },
    clearNotifications: (state) => {
      return [];
    }
  },
  extraReducers: builder => {
    builder
    .addMatcher(campaignApi.endpoints.setMerchantCenter.matchFulfilled, (state, action) => {
      state.push({
        id: Math.random(),
        type: "success",
        message: "merchant-update-success",
      })
    })
    .addMatcher(feedApi.endpoints.getFeed.matchFulfilled, (state, action) => {
      if ((action.payload.last_stat?.imported || 0) > 0) {
        const id = state.filter(t => t.loader)[0]?.id;
        if (id) {
          return state.filter((n: Notification) => n.id !== id);
        }
      }
    })
  }
});

export const { addNotification, removeNotification, clearNotifications } = notifications.actions;
export const notificationReducer = notifications.reducer;

export const NewSuccessNotification = (message: string): AppThunk => dispatch => {
  return dispatch(addNotification(SuccessNotification(message)));
}

export const NewDangerNotification = (message: string): AppThunk => dispatch => {
  return dispatch(addNotification(DangerNotification(message)));
}


