import { useAccounts } from "../../../../../app/hooks/useAccounts";
import React, { useMemo } from "react";
import { Box, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CompanyHeaderProps } from "./ChannelHeader";

interface HeaderActionsProps extends CompanyHeaderProps {
  enabled: boolean,
  onEnable: () => void,
  disabled?: boolean,
}

export const ChannelEnable = (p: HeaderActionsProps) => {
  const { enabled, onEnable, company, disabled } = p;

  const [ accounts ] = useAccounts();

  const hasLinkedAccount = useMemo(() => company !== "feedcast" ? accounts.canCreateCampaigns[company] : true, [ company, accounts ]);
  const hasAccessToAccount = useMemo(() => company !== "feedcast" ? accounts.hasAccess[company] : true, [ company, accounts ]);

  return (
    <Box>
      {(hasLinkedAccount && hasAccessToAccount) && (
        <Tooltip
          title={<FormattedMessage id={`channel-to-be-${!enabled}`}/>}
          arrow
          placement={"top"}
        >
          <FormControlLabel
            control={(
              <Switch
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onEnable();
                }}
                checked={enabled}
              />
            )}
            label={(<FormattedMessage id={`channel-${enabled ? "active" : "disabled"}`}/>)}
          />
        </Tooltip>
      )}
    </Box>
  )
}
