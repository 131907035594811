import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { RuleWithStateProps } from "./Rules";
import { GetCurrentFeedId } from "../../../app/utils";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Chip,
  debounce,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { DynamicLabelCriterias } from "../../../app/types/feed";
import { FormattedMessage } from "react-intl";
import { Cancel, CheckCircle, Delete, EditNote } from "@mui/icons-material";
import { useAppSelector } from "../../../app/hooks";
import { getSelectedProducts } from "../../../app/slices/app";
import useLabels from "../../../app/hooks/useLabels";
import productsApi from "../../../app/services/secured/products";
import { SkeletonLoader } from "../../App/Blocks/Misc";
import { Product, ProductData } from "../../../app/types/products";
import useProductUtils from "../../../app/hooks/useProductUtils";

export const RuleValueSelector: React.FC<RuleWithStateProps> = ({ rule, setRule, r_index, view, nbRules }) => {

  const feed = GetCurrentFeedId();
  const { aggregations } = useLabels();
  const { mergeProductData, getChannelStatus } = useProductUtils();

  const [ selected, setSelected ] = useState<{ label: string }[]>([]);
  const [ customSelection, setCustomSelection ] = useState(false);

  const preSelectedProducts = useAppSelector(getSelectedProducts).map(p => p.ref);
  const [ getProduct, productData ] = productsApi.useLazyGetProductsFromReferencesQuery();

  const pDetailMemo = useMemo(() => productData.data, [ productData.data ]);
  const [ product, setProduct ] = useState<Product | null>(null);
  const [ pDetail, setPDetail ] = useState<ProductData | null>(null);

  const [ loading, setLoading ] = useState(true);
  const [ notFound, setNotFound ] = useState(false);
  const fetchProduct = (ref: string) => {
    if (ref !== product?.product_id) {
      setPDetail(null);
      setLoading(true);
      fetchProductDetails(ref);
    }
  }
  const fetchProductDetails = debounce((reference: string) => {
    getProduct({ feed, reference: [ reference ] }, true);
  }, 500);

  const ruleFieldMemo = useMemo(() => rule.field, [ rule.field ]);

  const brands = useMemo(() =>
      aggregations?.brand
      .filter(b => !(selected.map(s => s.label).includes(b)))
      .map(label => ({ label })) ?? [],
    [ aggregations?.brand ]
  );

  const refs = useMemo(() =>
      aggregations?.id
      .filter(r => !(selected.map(s => s.label).includes(r)))
      .map(label => ({ label })) ?? [],
    [ aggregations?.id ]
  );

  const options = useMemo(() => ruleFieldMemo === "brand_k" ? brands : refs, [ ruleFieldMemo, selected, refs ]);

  const isSelectedProduct = (s: string) => preSelectedProducts.includes(s);

  const handleValueChange = (e: any, selectedValues: any[]) => {
    let value = '';
    selectedValues.forEach(o => {
      const opt = o as { label: string };
      value += opt.label + "\n";
    });
    setRule(prevRule => ({ ...prevRule, value }));
    setSelected(selectedValues);
  };

  useEffect(() => {
    if (rule.value.length) {
      setSelected(rule.value.split("\n").filter(v => v).map(v => {
        return { label: v }
      }));
    }
  }, []);

  const onManualEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const values = e.target.value;
    // Determine the separator
    const separator = values.includes(";") ? ";" : /\s/;

    if (values && values.length > 0) {
      const valArr = values.split(separator).map(v => v.trim()).filter(v => v !== '');
      setSelected(valArr.map(label => ({ label })));

      let value = valArr.join("\n");
      setRule({ ...rule, value });
    }
  }

  // Initialise
  useLayoutEffect(() => {
    setSelected([]);
  }, [ ruleFieldMemo ]);

  // Effect for hover
  useEffect(() => {
    if (pDetailMemo && pDetailMemo.length) {
      setPDetail(mergeProductData(pDetailMemo[0]));
      setProduct(pDetailMemo[0]);
      setLoading(false);
      setNotFound(false);
    } else if (productData.isSuccess && !pDetailMemo?.length) {
      setPDetail(null);
      setProduct(null);
      setNotFound(true);
      setLoading(false);
    }
  }, [ pDetailMemo, productData ]);

  const renderChip = (option: { label: string }, otherProps: any) => {
    const handleMouseOver = () => fetchProduct(option.label);
    return (
      <Tooltip
        arrow
        placement={"top"}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "transparent",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
              p: 0,
            }
          }
        }}
        title={
          <SkeletonLoader load={loading}>
            <Box sx={{ width: 250 }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={pDetail?.image_link}
                />
                <CardContent sx={{
                  backgroundColor: "#121212",
                  color: "#fff",
                }}>
                  <Typography>
                    {!notFound
                      ? pDetail?.title
                      : <FormattedMessage id={'product-not-found'}/>}
                  </Typography>
                  <Typography variant={"body2"}>
                    {pDetail?.brand_k}
                  </Typography>
                  <Box mt={2}>
                    {product && (
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                          {getChannelStatus(product, "google")}
                          {getChannelStatus(product, "meta")}
                          {getChannelStatus(product, "microsoft")}
                        </Box>
                        <Typography>
                          {`${pDetail?.price}`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </SkeletonLoader>
        }>
        <Chip
          onMouseOver={handleMouseOver}
          variant={isSelectedProduct(option.label) ? "filled" : "outlined"}
          label={option.label}
          color={isSelectedProduct(option.label) ? "secondary" : undefined}
          {...otherProps}
        />
      </Tooltip>
    );
  };

  return (
    <Grid2 xs={12}>
      <Stack spacing={1}>
        <input
          type="hidden"
          name={`conditions[${r_index}].value`}
          value={rule.value}
          required
        />

        {rule.field === "condition_k" ? (
          <FormControl fullWidth>
            <TextField
              select
              disabled={view === "tooltip"}
              size="small"
              name={`conditions[${r_index}].value`}
              defaultValue={rule.value}
              required
            >
              {DynamicLabelCriterias["condition_k"].choices.map(c => (
                <MenuItem key={c} value={c}>
                  <FormattedMessage id={c}/>
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        ) : !customSelection ? (
          <Autocomplete
            multiple
            key={`selected-${selected.length}`}
            fullWidth
            disabled={view === "tooltip"}
            size="small"
            renderInput={(params) => <TextField
              {...params}
              multiline
              rows={8}
              sx={{
                "& .MuiInputBase-root": {
                  justifyContent: "start",
                  alignItems: "start",
                }
              }}
              size="small"
              helperText={(selected.length || 10) > 10 && <React.Fragment>{selected.length} valeur(s)</React.Fragment>}
            />}
            value={selected}
            options={options}
            onChange={handleValueChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <React.Fragment>
                  {renderChip(option, getTagProps({ index }))}
                </React.Fragment>
              ))
            }
          />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: '100%' }}>
            <FormControl sx={{ flexGrow: 1 }} fullWidth>
              <TextField
                size="small"
                multiline
                rows={8}
                label={<FormattedMessage id="manual-list"/>}
                placeholder="copy-paste-list"
                defaultValue={selected.map(s => s.label).join(";")}
                onChange={onManualEdit}
              />
            </FormControl>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
          {!customSelection ? (
            <React.Fragment>
              <Button
                startIcon={<EditNote/>}
                onClick={() => setCustomSelection(true)}
              >
                <FormattedMessage id={'manual-list'}/>
              </Button>
              <Button
                onClick={rule.onDelete}
                startIcon={<Delete/>}
                disabled={nbRules <= 1}
              >
                <FormattedMessage id={'delete'}/>
              </Button>
            </React.Fragment>
          ) : (
            <ButtonGroup sx={{ flexGrow: 0 }}>
              <IconButton onClick={() => setCustomSelection(false)}>
                <Cancel/>
              </IconButton>
              <IconButton onClick={() => setCustomSelection(false)}>
                <CheckCircle/>
              </IconButton>
            </ButtonGroup>
          )}
        </Box>
      </Stack>
    </Grid2>
  );
};
