import { useAppSelector } from "../../../../app/hooks";
import { getAppNotifications } from "../../../../app/slices/app_notifications";
import useSSE from "../../../../app/hooks/useSSE";
import React, { useMemo, useState } from "react";
import { Badge, Box, Drawer, IconButton, Slide, Tab, Tabs, useTheme } from "@mui/material";
import { History, Notifications } from "@mui/icons-material";
import useAnchorEl from "../../../../app/hooks/useAnchorEl";
import { HistoryTab } from "./HistoryTab";
import { CurrentTab } from "./CurrentTab";
import { FormattedMessage } from "react-intl";
import { red } from "@mui/material/colors";
import { pulsingBadge } from "../../../../app/misc";

interface AppNotificationsProps {
  lockNav?: boolean,
}

enum NotifTabs {
  CURRENT,
  HISTORY
}

export const AppNotifications = (p: AppNotificationsProps) => {

  useSSE();

  const theme = useTheme();

  const { open, handleClose, handleClick } = useAnchorEl();

  const [ activeTab, setActiveTab ] = useState<NotifTabs>(NotifTabs.CURRENT);
  const [ readyForNext, setReadyForNext ] = useState(true);

  const notifications = useAppSelector(getAppNotifications);
  const notificationsMemo = useMemo(() => [ ...notifications ].sort((a, b) => b.date - a.date), [ notifications ]);

  const hasError = notificationsMemo.filter(a => a.severity === "error").length > 0;
  const nbNotifications = (notificationsMemo.filter(n => n.showInBadge ?? false).length) ?? 0;

  const pulse = pulsingBadge(red[50], red[200], red[50]);

  const handleTabChange = (e: any, val: NotifTabs) => (val !== activeTab && setActiveTab(val));
  const handleReadyNext = (ready: boolean, value: NotifTabs) => {
    if (value !== activeTab) {
      setReadyForNext(ready);
    }
  }

  return (
    <Box sx={{ display: p.lockNav ? "none" : "block" }}>
      <IconButton onClick={handleClick} aria-label={"notifications"}>
        <Badge
          badgeContent={nbNotifications} color={hasError ? "error" : "secondary"}
          sx={{
            "& .MuiBadge-badge": {
              animation: hasError ? `${pulse} 1500ms ${theme.transitions.easing.easeOut} infinite` : undefined,
            }
          }}
        >
          <Notifications fontSize={"large"}/>
        </Badge>
      </IconButton>
      <Drawer
        open={open}
        onClose={handleClose}
        anchor={"right"}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          "& .MuiDrawer-paper": { borderRadius: '0', overflowY: "scroll", },
        }}
      >
        <Box sx={{
          width: '30vw',
          maxWidth: '600px',
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: '100%',

        }}>
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{ padding: 0 }}
            >
              <Tab
                icon={<Notifications/>}
                label={"Notifications"}
                onClick={() => handleReadyNext(false, NotifTabs.CURRENT)}
              />
              <Tab
                icon={<History/>}
                label={<FormattedMessage id={'historic'}/>}
                onClick={() => handleReadyNext(false, NotifTabs.HISTORY)}
              />
            </Tabs>
            <Slide
              in={activeTab === NotifTabs.CURRENT && readyForNext} direction={"right"} mountOnEnter unmountOnExit
              onExited={() => setReadyForNext(true)}
            >
              <Box>
                <CurrentTab notifications={notificationsMemo}/>
              </Box>
            </Slide>
            <Slide
              in={activeTab === NotifTabs.HISTORY && readyForNext}
              direction={"left"} mountOnEnter unmountOnExit
              onExited={() => setReadyForNext(true)}
            >
              <Box>
                <HistoryTab/>
              </Box>
            </Slide>
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}

