import React, { useMemo } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { modalShowDeleteCampaign } from "../../../../../app/slices/modal";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Delete, DeleteSweep, Edit, EditNote } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { RowFocus } from "../CampaignList";
import Urls from "../../../../../assets/js/Urls";
import { Link } from "react-router-dom";
import { GetCurrentFeedId } from "../../../../../app/utils";
import useCampaignDraft, { useDraftFunctions } from "../../CampaignDetail/hooks/useCampaignDraft";

const useRowMenu = () => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
  }
}

interface CampaignRowMenuProps {
  anchorEl: HTMLElement | null,
  handleClose: () => void,
  rowFocus: RowFocus | null,
}

export const CampaignRowMenu = (p: CampaignRowMenuProps) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const { anchorEl, handleClose, rowFocus } = p;
  const isFeedcast = useMemo(() => p.rowFocus?.is_feedcast ?? false, [ p.rowFocus ]);

  const open = Boolean(anchorEl);

  const { draftNodeId, clearDraft } = useDraftFunctions({campaignId: p.rowFocus?.campaign});
  const hasDraft = window.localStorage.getItem(draftNodeId);

  const onDelete = () => {
    if (rowFocus) {
      handleClose();
      dispatch(modalShowDeleteCampaign({ provider: rowFocus.provider, campaign: rowFocus.campaign }));
    }
  }

  const onDraftDelete = () => {
    if (rowFocus) {
      handleClose();
      clearDraft();
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {p.rowFocus && (
        <MenuItem
          dense
          component={Link}
          to={Urls.campaigns.edit(feed, p.rowFocus.provider, p.rowFocus.campaign)}
          disabled={false}
        >
          <ListItemIcon>
            <Edit/>
          </ListItemIcon>
          <FormattedMessage id={"edit-campaign"}/>
        </MenuItem>
      )}
      <MenuItem
        dense
        onClick={onDraftDelete}
        disabled={!hasDraft}
      >
        <ListItemIcon>
          <DeleteSweep />
        </ListItemIcon>
        <FormattedMessage id={'delete-draft'} />
      </MenuItem>
      <Divider />
      <MenuItem
        dense
        disabled={!isFeedcast}
        onClick={onDelete}
      >
        <ListItemIcon>
          <Delete fontSize={"small"}/>
        </ListItemIcon>
        <FormattedMessage id={"delete-campaign"}/>
      </MenuItem>
    </Menu>
  )
}

export default useRowMenu;
