import { Box, Link, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import authApi from '../../../app/services/public/auth';
import Urls from '../../../assets/js/Urls';
import { LoadingButton } from '../../../components/App/Blocks/Misc';
import SimpleForm, { SimpleFormData } from '../../../components/App/Blocks/SimpleForm';
import SuccessMessage from '../../../components/SuccessMessage';
import { isValidEmail } from '../../../utils';
import AuthWrapper from '../AuthWrapper';
import { containerAnimation, fieldAnimation } from '../utils';

const Refresh = () => {
   const [email, setEmail] = useState('');
   const [refresh, refreshRes] = authApi.useRefreshMutation();
   const submit = (s: SimpleFormData) => refresh(s as { email: string });
   return (
      <AuthWrapper>
         <motion.div {...containerAnimation}>
            {refreshRes.isSuccess ? (
               <SuccessMessage
                  title="reset-email-sent"
                  description="check-inbox-reset"
               />
            ) : (
               <SimpleForm onSubmit={submit}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                     <Box sx={{ width: 250, display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                        <motion.div
                           {...fieldAnimation}
                           transition={{ ...fieldAnimation.transition, delay: 0 }}
                        >
                           <Box textAlign="center">
                              <Typography variant="h4">
                                 <FormattedMessage id="refresh-password" />
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                 <FormattedMessage id="refresh-desc" />
                              </Typography>
                           </Box>
                        </motion.div>

                        <motion.div
                           {...fieldAnimation}
                           transition={{ ...fieldAnimation.transition, delay: 0.1 }}
                        >
                           <Box sx={{ width: "100%" }}>
                              <TextField
                                 fullWidth
                                 label={<FormattedMessage id="email" />}
                                 name="email"
                                 type="email"
                                 value={email}
                                 onChange={e => setEmail(e.target.value)}
                                 size="small"
                                 required
                              />
                              <LoadingButton
                                 fullWidth
                                 variant="outlined"
                                 sx={{ mt: 1 }}
                                 data={refreshRes}
                                 type="submit"
                                 disabled={!isValidEmail(email)}
                              >
                                 <FormattedMessage id="continue" />
                              </LoadingButton>
                           </Box>
                        </motion.div>

                        <motion.div
                           {...fieldAnimation}
                           transition={{ ...fieldAnimation.transition, delay: 0.2 }}
                        >
                           <Typography variant="body2" color="text.secondary">
                              <FormattedMessage id="remember-password" />{' '}
                              <Link component={RouterLink} to={Urls.auth.login}>
                                 <FormattedMessage id="login" />
                              </Link>
                           </Typography>
                        </motion.div>
                     </Box>
                  </Box>
               </SimpleForm>
            )}
         </motion.div>
      </AuthWrapper>
   )
}

export default Refresh