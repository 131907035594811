import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getProductNav, setProductsPage } from "../../../../app/slices/products";
import React, { useEffect } from "react";
import { Box, Button, Fade, IconButton, Skeleton, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Urls from "../../../../assets/js/Urls";
import { GetCurrentFeedId } from "../../../../app/utils";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { redirect } from "../../../../app/slices/app";
import { getProductView, productHasUnsavedChanges, setProductPreview } from "../../../../app/slices/product";
import { modalShowProductChanges } from "../../../../app/slices/modal";

const ProductHeader = () => {
  const { product, data } = useAppSelector(state => state.product);
  const view = useAppSelector(getProductView);

  const loading = false; //TODO

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const nav = useAppSelector(getProductNav);

  const hasUnsavedChanges = useAppSelector(productHasUnsavedChanges);

  useEffect(() => {
    nav?.current && dispatch(setProductsPage(nav.current.page));
  }, [ nav ]);

  const handleReturn = () => {
    if (hasUnsavedChanges) {
      dispatch(modalShowProductChanges());
    } else {
      dispatch(redirect(Urls.products.list(feed)));
    }
  }

  return (
    <Box>
      {view === "drawer" && (
        <Box display={"flex"} justifyContent={"end"}>
          {product && (
            <Button
              size={"small"}
              component={Link}
              to={Urls.products.edit(feed, product.id)}
              sx={{ mb: 2 }}
              onClick={() => dispatch(setProductPreview(null))}
              endIcon={<ArrowForward/>}
            >
              <FormattedMessage id={'see-full-product'}/>
            </Button>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          gap: 1,
        }}
      >
        {view === "page" && (
          // <BackButton size={"sm"} href={Urls.products.list(feed)}/>
          <IconButton
            onClick={handleReturn}
          >
            <ArrowBack fontSize={"large"} />
          </IconButton>
        )}

        <Box sx={{ flex: 1 }}>
          {loading ? (
            <Skeleton
              variant={"text"}
              animation={"wave"}
              sx={{ fontSize: '1.2rem', width: 310, transform: "none" }}
            />
          ) : (
            <Fade in={true} timeout={1000}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: '100%',
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant={"h6"} sx={{ lineHeight: 1 }}>
                    {data.title}
                  </Typography>
                </Box>
              </Box>
            </Fade>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ProductHeader;
