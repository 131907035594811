import { GetCurrentFeedId } from "../../../app/utils";
import React from "react";
import DrawerAssetManager from "../../Pages/Campaigns/CampaignDetail/Google/AssetManager/DrawerAssetManager";
import { DialogLabel } from "../../Pages/Labels/DialogLabel";
import ModalSubscription from "../ModalSubscription";
import ModalLinkAccount from "../../Pages/Configuration/Channels/Dialogs/ModalLinkAccount";
import DrawerGoogleMerchantCenter from "../../Pages/Configuration/Channels/ChannelServices/MerchantCenter/DrawerGoogleMerchantCenter";
import ModalCatalogPixel from "../../Pages/Configuration/Channels/Dialogs/ModalCatalogPixel";
import DialogMicrosoftCenter from "../../Pages/Configuration/Channels/ChannelServices/MicrosoftCenter/DialogMicrosoftCenter";
import DialogDeleteCampaign from "../../Pages/Campaigns/CampaignList/Dialogs/DialogDeleteCampaign";
import ModalGoogleInitAccount from "../../Pages/Configuration/Channels/Dialogs/ModalGoogleInitAccount";
import DialogEnableChannel from "../../Pages/Configuration/Channels/Dialogs/DialogEnableChannel";
import DialogPlatforms from "../../Global/Settings/Subscription/Components/DialogPlatforms";
import DialogConfirmSyncFeed from "../../Pages/CatalogStatus/ChannelsCatalogs/DialogConfirmSyncFeed";
import DialogResetChannel from "../../Pages/Configuration/Channels/Dialogs/DialogResetChannel";
import DialogCropper from "../../Pages/Campaigns/CampaignDetail/Google/AssetManager/DialogCropper";
import { ProductPreviewDrawer } from "../../Pages/Products/ProductPreviewDrawer";
import DialogUpsell from "../../Pages/Configuration/Channels/Dialogs/DialogUpsell";
import DialogAI from "./DialogAI";

export const ModalContainer = () => {
  const feed = GetCurrentFeedId();
  return <React.Fragment>
    {feed > 0 && (<React.Fragment>
      <DrawerAssetManager/>
      <DrawerGoogleMerchantCenter/>
      <ProductPreviewDrawer/>
      <DialogLabel/>
      <ModalSubscription/>
      <ModalLinkAccount/>
      <ModalCatalogPixel/>
      <DialogMicrosoftCenter/>
      <DialogDeleteCampaign/>
      <ModalGoogleInitAccount/>
      <DialogEnableChannel/>
      <DialogPlatforms/>
      <DialogConfirmSyncFeed />
      <DialogResetChannel />
      <DialogCropper />
      <DialogUpsell />
      <DialogAI />
    </React.Fragment>)}
  </React.Fragment>
}
