import globalApi from "../services/secured/global";
import { GetCurrentFeedId } from "../utils";
import { useEffect } from "react";

const useProductCompetitors = () => {

  const feed = GetCurrentFeedId();

  const [ getCompetitors, { data: competitors } ] = globalApi.useLazyGetCompetitorsQuery();
  const [ getPopularProducts, { data: popularProducts } ] = globalApi.useLazyGetPopularProductsQuery();

  useEffect(() => {
    if (feed > 0) {
      getCompetitors({feed}, true);
      getPopularProducts({feed}, true);
    }
  }, [feed]);

  return {
    competitors,
    popularProducts,
  }
}

export default useProductCompetitors;
