import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCurrentFeed } from "../../../../../app/slices/app";
import { useAccounts } from "../../../../../app/hooks/useAccounts";
import React, { ReactNode, useCallback, useMemo } from "react";
import { ChannelWrapperProps } from "../Channels";
import ChannelService from "./ChannelService";
import { Box } from "@mui/material";
import { MerchantCenterAlert } from "../../../../../app/types/feed";
import { getChannelsServices } from "./getChannelsServices";
import { AdsProvider } from "../../../../../app/types/campaign";
import useFreeDiffusion from "../../../Diffusion/FreeDiffusion/useFreeDiffusion";
import { modalShowEnableChannel } from "../../../../../app/slices/modal";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import Grid2 from "@mui/material/Unstable_Grid2";
import Tracking from "./Tracking";
import { ChannelOAuth } from "../ChannelOAuth";
import { ChannelBanner } from "./ChannelBanner";

export type Service = {
  id: string | number,
  name: string,
  logo: string | undefined,
  service: string,
  type: "configuration" | "free-listing" | "marketplace",
  status: "online" | "offline" | "alerts" | "configuration",
  code: string,
  alerts?: MerchantCenterAlert[] | string[],
  reset?: boolean,
  provider: AdsProvider | null,
  config?: {
    action?: () => void,
    disabled: boolean,
    cta: string | ReactNode,
    show?: boolean,
  },
};

export const ChannelServices = (p: ChannelWrapperProps) => {

  const dispatch = useAppDispatch();
  const { company } = p;

  const [ accounts, { services } ] = useAccounts();
  const { toggleFreeListing } = useFreeDiffusion();

  const currFeed = useAppSelector(getCurrentFeed);
  const feedOptions = useAppSelector(state => state.shop.options);

  const channelIsActive = company !== "feedcast"
    ? accounts.hasChannelActive[company]
    : feedOptions.export_comparator;

  const handleFreeListing = useCallback(() => {
    if (!channelIsActive && company !== "feedcast") {
      dispatch(modalShowEnableChannel({
        company,
        provider: adsCompanies[company].provider,
        toBeEnabled: true,
        source: "service",
      }));
    } else {
      toggleFreeListing();
    }
  }, [ channelIsActive, company, feedOptions.enable_free_listing ]);

  const servicesList = useMemo(() => getChannelsServices(
    company,
    currFeed,
    accounts,
    services,
    dispatch,
    feedOptions,
    handleFreeListing
  ), [ company, currFeed, accounts, services, feedOptions, channelIsActive, handleFreeListing ]);

  const hideServices = useMemo(() => (
    (company === "microsoft" && !accounts.isOauth.microsoft)
    || (company === "meta" && !accounts.isOauth.meta)
    || (company === "google" && (!accounts.hasLinkedAccount.google && !accounts.isOauth.google))
  ), [ company, accounts.isOauth, accounts.hasLinkedAccount ]);

  return (
    <Box>
      <ChannelOAuth company={company}/>

      {company !== "feedcast" && (
        <ChannelBanner company={company}/>
      )}

      <Grid2 container p={2} spacing={2} sx={{
        filter: hideServices ? "blur(8px)" : undefined
      }}>
        {servicesList.map(s => (
          <ChannelService key={`service-${s.service}`} service={s}/>
        ))}
        {company !== "feedcast" && (
          <Grid2 xs={3}>
            <Tracking company={company}/>
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
}

