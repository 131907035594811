import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "../../App/Blocks/Misc";
import { ButtonsNavProps } from "./Onboarding";
import { FormattedMessage } from "react-intl";

export const ButtonsNav = (p: ButtonsNavProps) => {
  const { currentApi, handleNext, handleBack, index, length } = p;
  const [ authorizeNextStep, setAuthoriseNextStep ] = useState(false);
  useEffect(() => {
    if (currentApi?.isLoading) {
      setAuthoriseNextStep(true);
    }
  }, [ currentApi?.isLoading, index ]);

  useEffect(() => {
    if (currentApi?.isSuccess && authorizeNextStep) {
      handleNext();
      setAuthoriseNextStep(false);
    }
  }, [ currentApi?.isSuccess, authorizeNextStep ]);
  return (
    <Box display={"flex"} gap={1}>
      {currentApi && (
        <LoadingButton
          variant="contained"
          data={currentApi}
          type={"submit"}
          reset
        >
          {index === length - 1 ? 'Finish' : 'Continue'}
        </LoadingButton>
      )}
      <Button
        disabled={index === 0}
        onClick={handleBack}
      >
        <FormattedMessage id={"back"}/>
      </Button>
    </Box>
  )
}
