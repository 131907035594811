import { Service } from "./ChannelServices";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuList,
  Stack,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { BadgeOutlined, Launch, MoreHoriz, RestartAlt, Tag } from "@mui/icons-material";
import useAnchorEl, { AnchorElReturn } from "../../../../../app/hooks/useAnchorEl";
import { ResetChannelParams } from "../../../../../app/services/secured/channels";
import { useAppDispatch } from "../../../../../app/hooks";
import { modalShowResetChannel } from "../../../../../app/slices/modal";
import FCard from "../../../../App/Blocks/Card";

const ChannelService = ({ service }: { service: Service }) => {

  const anchorElProps = useAnchorEl();
  const { handleClick } = anchorElProps;

  const displayIds = service.type !== "free-listing";
  const displayButton = service.type !== "free-listing" && service.code !== "feedcast-marketplace";
  const isFreeListing = service.type === "free-listing";

  return (
    <Grid2
      xs={service.code !== "feedcast-marketplace" ? 3 : 12}
      sx={
        service.config?.disabled && service.status === "offline"
          ? { filter: "grayscale(1)", "& *": { color: "text.disabled" } }
          : {}
      }
    >
      <FCard sx={{ position: "relative", height: '100%' }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", height: "100%", flexDirection: "column", gap: 1 }}>
          <Box>
            <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
              <img style={{ width: '15px' }} src={service.logo}/>
              <Box>
                <Typography variant={"subtitle1"} fontWeight={500} lineHeight={1}>{service.service}</Typography>
              </Box>
            </Box>
            <Typography variant={"caption"} color={"text.secondary"}>
              <FormattedMessage id={`${service.code}-desc`}/>
            </Typography>
          </Box>
          {service.reset && (
            <Box sx={{ position: "absolute", top: 10, right: 15 }}>
              <IconButton onClick={handleClick} size={"small"}>
                <MoreHoriz/>
              </IconButton>
              <Box>
                {(service.type === "configuration" && service.reset && service.provider) && (
                  <ResetChannel
                    disabled={!service.name.length}
                    provider={service.provider}
                    {...anchorElProps}
                  />
                )}
              </Box>
            </Box>
          )}
          {displayIds && (
            <>
              {((service.name.length) || 0 > 0) ? (
                <Stack spacing={0.5}>
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <BadgeOutlined sx={{ color: "text.disabled" }} fontSize={"small"}/>
                    <Typography variant={"body2"} color={"text.secondary"} lineHeight={1}>
                      {service.name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                    <Tag sx={{ color: "text.disabled" }} fontSize={"small"}/>
                    <Typography variant={"caption"} color={"text.disabled"}>
                      {service.id ?? <FormattedMessage id={'to-configure'}/>}
                    </Typography>
                  </Box>
                </Stack>
              ) : service.code !== "feedcast-marketplace" && (
                <Typography variant={"subtitle1"} color={"primary.main"}>
                  <FormattedMessage id={'service-to-configure'}/>
                </Typography>
              )}

            </>
          )}
          {isFreeListing && (
            <FormControlLabel
              sx={{ m: 0 }}
              control={(
                <Switch
                  size={"small"}
                  onChange={service.config?.action}
                  checked={service.status === "online"}
                />
              )}
              labelPlacement={"end"}
              label={(
                <Typography variant={"caption"} color={"text.secondary"}>
                  <FormattedMessage
                    id={service.status === "online" ? "free-listing-on" : "free-listing-off"}
                  />
                </Typography>
              )}
            />
          )}
          {displayButton && (
            <Button
              fullWidth
              disabled={service.config?.disabled}
              variant={"contained"}
              onClick={
                (service.type === "configuration" && service.config?.action)
                  ? service.config?.action
                  : handleClick
              }
            >
              {service.config?.cta}
            </Button>
          )}
          {service.type === "marketplace" && (
            <Tooltip title={<FormattedMessage id={'visit-feedcast-shopping'}/>}>
              <Button
                size={"small"}
                component={'a'}
                target={'_blank'}
                href={'https://feedcast.shopping'}
              >
                <Launch/>
              </Button>
            </Tooltip>
          )}
        </Box>
      </FCard>
    </Grid2>
  )
}

const ResetChannel = (p: Pick<ResetChannelParams, "provider"> & AnchorElReturn & {disabled: boolean}) => {

  const { provider, anchorEl, handleClick, handleClose, open, disabled } = p;
  const dispatch = useAppDispatch();

  const openModal = () => dispatch(modalShowResetChannel({ provider }));

  return (
    <Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <MenuList disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              disabled={disabled}
              onClick={openModal}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <RestartAlt/>
              </ListItemIcon>
              <FormattedMessage id={'init-channel'}/>
            </ListItemButton>
          </ListItem>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default ChannelService;
