import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCustomHeaders } from "../../../assets/js/ApiUrls";

export const tagTypes = [ "Profile", "Projects", "Feeds", "Products", "Feed", "Product", "CategoriesMap", "ProductReporting",
  "Campaigns_Google", "Campaigns_Meta", "Reporting", "Subscription", "AccountStatus", "Assets", "Campaign", "DynamicLabels",
  "Campaigns", "MetaCatalog", "Asset", "MicrosoftMerchantCenter", "CampaignLabels", "UserFeed", "Credentials", "ProductsErrors",
  "NotificationsHistory", "AI_Credits" ];

const securedApi = createApi({
  reducerPath: "securedApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, store) => getCustomHeaders(headers, true),
    baseUrl: process.env.REACT_APP_API_DOMAIN
  }),
  endpoints: build => ({}),
  tagTypes,
});

export default securedApi;
