import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addModalData,
  closeModal,
  getModalData,
  isAnyModalOpened,
  isModalOpened,
  ModalLabelProps,
  ModalTypes
} from "../../../app/slices/modal";
import { Label } from "./Label";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { AutoAwesome, Edit } from "@mui/icons-material";
import { getSelectedProducts } from "../../../app/slices/app";
import useLabels from "../../../app/hooks/useLabels";

enum LABEL_STEPS {
  CHOOSE,
  NEW,
  EDIT
}

export const DialogLabel = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(isAnyModalOpened);
  const show2 = useAppSelector(isModalOpened(ModalTypes.CustomLabel));

  const opt = useAppSelector<ModalLabelProps | null>(getModalData);

  const isEdit = opt !== null && ((opt?.label?.label_value.length || 0) > 0);

  const [ step, setStep ] = useState<LABEL_STEPS | null>(null);

  const close = () => {
    dispatch(closeModal());
    setTimeout(() => setStep(null), 100);
  }

  useEffect(() => {
    if (show && show2) {
      if (isEdit) {
        setStep(LABEL_STEPS.EDIT);
      } else {
        setStep(LABEL_STEPS.CHOOSE);
      }
    }
  }, [ isEdit, show, show2 ]);

  useEffect(() => {
    if (show2) {
      if (opt?.isNewLabel && step === null) {
        setStep(LABEL_STEPS.NEW);
      }
    }
  }, [ opt, show2 ]);

  return (
    show
      ? step === null
        ? <CircularProgress/>
        : <Dialog
          open={show && show2}
          onClose={close}
          maxWidth={step === LABEL_STEPS.CHOOSE ? "sm" : "md"}
          fullWidth
        >
          {step === LABEL_STEPS.CHOOSE && (
            <LabelChoose
              onChoice={(s) => setStep(s)}
            />
          )}
          {step === LABEL_STEPS.NEW && (<LabelNew/>)}
          {step === LABEL_STEPS.EDIT && (<LabelEdit/>)}
        </Dialog>
      : <React.Fragment/>
  )
}

const LabelEdit = () => {
  return (
    <React.Fragment>
      <DialogTitle>
        <FormattedMessage id={"edit-custom-label"}/>
        <Typography variant={"body2"}>
          <FormattedMessage id={"edit-custom-label-desc"}/>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Label/>
      </DialogContent>
    </React.Fragment>
  )
}

interface LabelChooseProps {
  onChoice: (s: LABEL_STEPS) => void,
}

const LabelChoose = (p: LabelChooseProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const selectedProducts = useAppSelector(getSelectedProducts);
  const { labels } = useLabels();
  return (
    <React.Fragment>
      <DialogTitle>
        <FormattedMessage id={"custom-labels"}/>
        <Typography variant={"body2"}>
          {selectedProducts.length} produit(s) sélectionné(s)
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemButton
              sx={{ padding: 0 }}
              onClick={() => p.onChoice(LABEL_STEPS.NEW)}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "secondary.main" }}>
                  <AutoAwesome/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Créer un nouveau label"}
                secondary={"Créez un nouvel label à partir de votre sélection actuelle"}
              />
            </ListItemButton>
          </ListItem>
          <Divider>ou</Divider>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "secondary.main" }}>
                <Edit/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={"Éditer un label existant"}
              secondary={<Box mt={1}>
                <Autocomplete
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder={intl.formatMessage({ id: "search-for-existing-label" })}
                    />}
                  options={labels.map(l => ({ label: l.label_value, labelData: l }))}
                  onChange={(e, value) => {
                    if (value) {
                      dispatch(addModalData({ label: value.labelData }));
                      p.onChoice(LABEL_STEPS.EDIT);
                    }
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: false
                        },
                        {
                          name: 'preventOverflow',
                          enabled: false
                        }
                      ]
                    }
                  }}
                />
              </Box>}
            />
          </ListItem>
        </List>
      </DialogContent>
    </React.Fragment>
  )
}

const LabelNew = () => {
  return (
    <React.Fragment>
      <DialogTitle>
        <FormattedMessage id={"create-custom-label"}/>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"create-custom-label-desc"}/>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Label/>
      </DialogContent>
    </React.Fragment>
  )
}
