import React from "react";
import FeedsTable from "./FeedsTable";
import { Box } from "@mui/material";

const Subs = () => {
  return (
    <Box>
      <FeedsTable/>
    </Box>
  )
}


export default Subs;
