import { Box, FormControl, Stack, TextField } from "@mui/material";
import React from "react";
import CampaignComponents from "../common/components";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setBingAdgroupName } from "../../../../../app/slices/campaign/campaign";
import CampaignCard from "../CampaignCard";
import { Globe } from "lucide-react";

const BingShoppingGeneral = () => {
  const campaignComponents = CampaignComponents();
  const dispatch = useAppDispatch();
  const setAdgroupName = (e: any) => dispatch(setBingAdgroupName(e.target.value));
  const adgroup = useAppSelector(state => state.campaign.adgroup_bing);
  return (
    <Box>
      <CampaignCard icon={<Globe/>} title={"global-parameters"}>
        <Stack spacing={2}>
          <Box sx={{
            display: "flex", gap: 2
          }}>
            <Box>
              {campaignComponents.budget}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {campaignComponents.name}
            </Box>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                required
                label={<FormattedMessage id={"ad-group-name"}/>}
                onChange={setAdgroupName}
                value={adgroup.name}
                helperText={(
                  <FormattedMessage id={'ad-group-name-desc'} />
                )}
              />
            </FormControl>
          </Box>
        </Stack>
      </CampaignCard>
    </Box>
  )
}

export default BingShoppingGeneral;
