import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  resetRoas,
  setAssetGroupName,
  setCampaignName,
  setCampaignRoas,
  setPerfMaxData
} from "../../../../../../app/slices/campaign/campaign";
import React, { useCallback, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  debounce, Divider,
  FormControl, FormLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { getCampaign, getPerfMaxData, isNewCampaign } from "../../../../../../app/slices/campaign/getters";
import CampaignComponents from "../../common/components";
import { Globe } from "lucide-react";
import CampaignCard from "../../CampaignCard";
import { Help } from "@mui/icons-material";
import { PerfMaxData } from "../../../../../../app/types/campaign";

const BUSINESS_NAME_MAX_CHAR = 25;

const GlobalPerfMax = () => {

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(getCampaign);
  const isNewC = useAppSelector(isNewCampaign);
  const data = useAppSelector(getPerfMaxData);
  const c = useAppSelector(getCampaign);

  const components = CampaignComponents();

  const [ showRoas, setShowRoas ] = useState(false);

  const toggleRoas = useCallback((checked: boolean) => {
    !checked && dispatch(resetRoas());
    setShowRoas(checked);
  }, [ dispatch ]);

  const setData = (data: PerfMaxData) => dispatch(setPerfMaxData(data));

  const debouncedDispatch = debounce((value: string) => {
    setData({ ...data, business_name: value });
  }, 0);

  useEffect(() => {
    if (!isNewC) {
      campaign.target_roas && setShowRoas(true);
    }
  }, [ campaign, isNewC ]);

  useEffect(() => {
    if (campaign.migrate) {
      dispatch(setCampaignName(campaign.name + " / Performance Max"));
    }
  }, [ campaign.migrate ]);

  return (
    <Box>
      <CampaignCard icon={<Globe/>} title={"global-parameters"}>
        <Stack spacing={2}>
          <Box sx={{
            display:"flex",
            gap: 2,
          }}>
            <Box>
              {components.budget}
            </Box>
            <Box sx={{flexGrow: 1,}}>
              {components.name}
            </Box>
          </Box>

          <Box sx={{display:"flex", gap: 2}}>
            <FormControl sx={{flexGrow: 1}} fullWidth>
              <TextField
                required
                label={<FormattedMessage id={"company-name"}/>}
                value={data?.business_name}
                error={data?.business_name?.length > BUSINESS_NAME_MAX_CHAR}
                onChange={(e) => debouncedDispatch(e.target.value)}
                helperText={(
                  <Stack direction={"row"} spacing={0.5}>
                    <Typography variant={"caption"}>{BUSINESS_NAME_MAX_CHAR}</Typography>
                    <Typography variant={"caption"}><FormattedMessage id={"chars-max"}/></Typography>
                  </Stack>
                )}
              />
            </FormControl>
            <FormControl sx={{flexGrow: 1}} fullWidth>
              <TextField
                label={<FormattedMessage id={"asset-group-name"}/>}
                onChange={(e) => dispatch(setAssetGroupName(e.target.value))}
                value={c.asset_group.name}
                required
                helperText={(
                  <FormattedMessage id={"asset-group-name-helper"}/>
                )}
              />
            </FormControl>
          </Box>

          <Divider/>
          <Stack spacing={1}>
            <Typography variant={"subtitle2"}>
              <FormattedMessage id={'roas-long-txt'} />
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              <FormattedMessage id={'roas-desc'} values={{
                br: <br/>
              }}/>
            </Typography>
            <Box>
              <FormControl>
                <TextField
                  margin={"dense"}
                  size={"small"}
                  label={<FormattedMessage id={"roas-target"}/>}
                  type={"number"}
                  value={campaign.target_roas}
                  key={`roas`}
                  onChange={(e) => dispatch(setCampaignRoas(parseFloat(e.target.value)))}
                  inputProps={{
                    step: 0.01,
                  }}
                  helperText={(
                    isNewC ? (
                      <FormattedMessage id={'target-roas-disabled-when-new'}/>
                    ) : null
                  )}
                  disabled={!showRoas}
                  InputProps={{
                    endAdornment: <InputAdornment position={"end"}>
                      <Switch
                        disabled={isNewC}
                        onChange={(e) => toggleRoas(e.target.checked)}
                        size={"small"}
                      />
                    </InputAdornment>,
                  }}
                />
              </FormControl>
            </Box>
          </Stack>
        </Stack>
      </CampaignCard>
    </Box>
  )
}

export default GlobalPerfMax;
