import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getCurrentFeed, getCurrentFeedSubStatus, getUser, redirect } from "../../../../app/slices/app";
import { FeedSourceEnum, UserFeed } from "../../../../app/types/feed";
import { GetCurrentFeedId, getFeedSourceRecord, getFeedSubStatus, niceUrl } from "../../../../app/utils";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem,
  Stack,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import { LoadingButton, MUIColor } from "../../../App/Blocks/Misc";
import Urls from "../../../../assets/js/Urls";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import Grid2 from "@mui/material/Unstable_Grid2";
import Locale from "../../../App/Blocks/Locale";
import {
  AllInclusive,
  CloudOff,
  Delete,
  DeleteForever,
  DriveFileRenameOutline,
  Edit,
  ImportExport, MoreHoriz, ShoppingBagOutlined, ShoppingCart,
  Storefront
} from "@mui/icons-material";
import { feedSources } from "../../../../app/slices/shop";
import { addNotification, NewSuccessNotification } from "../../../../app/slices/notifications";
import DialogActions from "@mui/material/DialogActions";
import feedApi from "../../../../app/services/secured/feed";
import SimpleForm from "../../../App/Blocks/SimpleForm";
import useAnchorEl from "../../../../app/hooks/useAnchorEl";
import FCard from "../../../App/Blocks/Card";
import useSubscription from "../../../../app/hooks/useSubscription";
import { BigDataCard } from "../../Dashboard/Cards/BigDataCard";
import { SubStatusCard } from "../../Dashboard/Cards/SubStatusCard";

interface ShopHeaderProps {
  toggleImport: (val?: boolean) => void,
}

export const ShopHeader = ({ toggleImport }: ShopHeaderProps) => {

  const feedId = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const me = useAppSelector(getUser);
  const feed = useAppSelector(getCurrentFeed);
  const subStatus = useAppSelector(getCurrentFeedSubStatus);
  const hasSrc = useAppSelector(state => state.shop.hasSource);
  const src = getFeedSourceRecord({ source: feed?.source ?? FeedSourceEnum.Manual, url: feed?.url ?? "" });
  const feedSubPlan = getFeedSubStatus(feed);

  const [ showDialogEdit, setShowDialogEdit ] = useState(false);
  const [ showDialogDelete, setShowDialogDelete ] = useState(false);
  const [ confirmDelete, setConfirmDelete ] = useState("");

  const { anchorEl, open, handleClose, handleClick } = useAnchorEl();

  const [ deleteFeed, deleteFeedRes ] = feedApi.useDeleteFeedMutation();

  const buttonPropsBySubStatus: Record<string, { color: string, messageId: string }> = {
    free: {
      color: 'primary',
      messageId: 'free',
    },
    trial: {
      color: 'info',
      messageId: 'trial-period',
    },
    active: {
      color: 'success',
      messageId: 'active-subscription',
    },
    premium: {
      color: 'success',
      messageId: 'active-subscription',
    },
    starter: {
      color: 'success',
      messageId: 'active-subscription',
    },
    canceled: {
      color: 'error',
      messageId: 'canceled-subscription',
    },
  };

  const SubComponent = useMemo(() => {
    const { color, messageId } = buttonPropsBySubStatus[subStatus] || {};
    return (
      color && messageId && feed && (
        <Button
          component={Link as any}
          variant={"outlined"}
          color={color as MUIColor}
          to={Urls.settings.subEdit(feed?.id)}
          disabled={me?.can_subscribe === false}
        >
          <FormattedMessage id={messageId}/>
        </Button>
      )
    );
  }, [ subStatus, feed?.id, me ]);

  useEffect(() => {
    if (deleteFeedRes.isSuccess) {
      dispatch(redirect(Urls.projects.list));
      dispatch(addNotification({ type: "primary", message: "success-delete-feed" }))
    }
  }, [ deleteFeedRes.isSuccess ]);

  const closeDeleteDialog = () => {
    setShowDialogDelete(false);
    setConfirmDelete("");
  }

  const { maxProducts } = useSubscription();

  return (
    <Box>
      <Grid2 container spacing={2}>
        {feed && (
          <React.Fragment>
            <Grid2 xs={4}>
              <FCard sx={{ height: '100%' }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: '100%' }}>
                  <ShopTitle src={src.logo} name={niceUrl(feed?.name)}/>
                  <Locale locale={feed?.locale} className={"fs-20"}/>
                </Box>
              </FCard>
            </Grid2>
            <Grid2 xs={3}>
              <BigDataCard
                primary={"Produits"}
                icon={<ShoppingBagOutlined fontSize={"large"}/>}
                secondary={(
                  <Box sx={{ display: "flex", alignItems: "end", gap: 0.5, lineHeight: 1 }}>
                    <Typography variant={"h5"}>
                      {feed?.last_stat?.imported
                        ? <FormattedNumber value={feed?.last_stat?.imported}/>
                        : <CloudOff color={"disabled"}/>}
                    </Typography>
                    <Typography variant={"overline"} color={"text.secondary"}>
                      /
                    </Typography>
                    <Typography variant={"overline"} color={"text.secondary"}>
                      {maxProducts ?? (<AllInclusive sx={{ fontSize: "10px" }}/>)}
                    </Typography>
                  </Box>
                )}
              />
            </Grid2>
            <Grid2 xs>
              <SubStatusCard/>
            </Grid2>
            <Grid2 xs={1}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height:'100%' }}>
                <IconButton
                  size={"large"}
                  onClick={handleClick}
                >
                  <MoreHoriz fontSize={"large"}/>
                </IconButton>
                <Menu
                  open={open} anchorEl={anchorEl} onClose={handleClose}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={() => {
                    setShowDialogEdit(prev => !prev);
                    handleClose();
                  }}>
                    <ListItemIcon>
                      <DriveFileRenameOutline/>
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage id={'edit-shop-name'}/>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    disabled={!hasSrc}
                    onClick={() => {
                      toggleImport();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <ImportExport/>
                    </ListItemIcon>
                    <ListItemText>
                      <FormattedMessage id={"edit-import-method"}/>
                    </ListItemText>
                  </MenuItem>
                  <Tooltip
                    placement={"left"}
                    arrow
                    title={
                      feedSubPlan !== "free"
                        ? (
                          <Typography variant={"body2"}>
                            <FormattedMessage id={"to-delete-need-free"}/>
                          </Typography>
                        )
                        : null
                    }
                  >
                    <Box>
                      <MenuItem
                        disabled={feedSubPlan !== "free"}
                        onClick={() => {
                          setShowDialogDelete(true);
                          handleClose()
                        }}>
                        <ListItemIcon>
                          <Delete/>
                        </ListItemIcon>
                        <ListItemText>
                          <FormattedMessage id={"delete-feed"}/>
                        </ListItemText>
                      </MenuItem>
                    </Box>
                  </Tooltip>
                </Menu>
              </Box>
            </Grid2>
          </React.Fragment>
        )}
      </Grid2>

      <Dialog
        maxWidth={'xs'}
        open={showDialogEdit}
        onClose={() => setShowDialogEdit(false)}
        fullWidth
      >
        <DialogTitle>
          <FormattedMessage id={"edit-shop-name"}/>
        </DialogTitle>
        <DialogContent>
          <EditFeedForm
            onSuccess={() => setShowDialogEdit(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDialogDelete}
        onClose={closeDeleteDialog}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle color={"error"}>
          <FormattedMessage id={"delete-feed"}/>
        </DialogTitle>
        <DialogContent dividers>
          <FormattedMessage
            id={"delete-feed-confirm"}
            values={{
              b: () => <b>{feed?.name}</b>
            }}
          />
          <FormControl fullWidth sx={{ mt: 1 }}>
            <TextField
              onChange={(e) => setConfirmDelete(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialogDelete(false)}>
            <FormattedMessage id={"no"}/>
          </Button>
          <LoadingButton
            variant={"contained"}
            color={"error"}
            endIcon={<DeleteForever/>}
            data={deleteFeedRes}
            onClick={() => deleteFeed({ feed: feedId })}
            disabled={confirmDelete !== feed?.name}
          >
            <FormattedMessage id={"confirm-delete"}/>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

interface IShopTitleProps {
  src: string,
  name: string,
}

const ShopTitle = (p: IShopTitleProps) => (
  <Box display={"flex"} alignItems={"center"}>
    <Stack spacing={3} direction={"row"} alignItems={"center"}>
      <img style={{ height: "50px" }} src={p.src}/>
    </Stack>
    <Box ml={5}>
      <Typography variant={"body2"} color={"text.secondary"}><FormattedMessage id={"shop"}/></Typography>
      <Typography variant={"h6"}>
        {p.name}
      </Typography>
    </Box>
  </Box>
)


interface EditFeedFormProps {
  onSuccess: () => void,
}

const EditFeedForm = (p: EditFeedFormProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const feed = useAppSelector(getCurrentFeed) as UserFeed;

  const [ disabled, setDisabled ] = useState(true);
  const [ setFeed, setFeedRes ] = feedApi.useSetFeedDataMutation();

  useEffect(() => {
    if (setFeedRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "feed-edit-success" })));
      setDisabled(true);
      p.onSuccess();
    }
  }, [ setFeedRes ]);
  return <SimpleForm onSubmit={s => setFeed(s as Pick<UserFeed, 'id' | 'name' | 'url'>)}>
    <Box mt={2}>
      <input type={'hidden'} name={'id'} value={feed.id}/>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <FormControl fullWidth>
            <TextField
              label={<FormattedMessage id={"name"}/>}
              onChange={(e) => setDisabled(e.target.value === feed.name)}
              name={"name"}
              defaultValue={feed.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <Storefront/>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid2>
      </Grid2>
    </Box>
    <Box mt={2} display={"flex"} justifyContent={"end"}>
      <LoadingButton
        type={'submit'}
        data={setFeedRes}
        disabled={disabled}
        size={'large'}
        variant={"contained"}
      >
        <FormattedMessage id={"save-edits"}/>
      </LoadingButton>
    </Box>
  </SimpleForm>
}
