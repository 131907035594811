import { BlockWithInfo, helpdeskLinks } from "./helpdesk";
import { useAppSelector } from "../../../app/hooks";
import { getLanguage } from "../../../app/slices/app";
import { Button } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import React from "react";

interface BlockInfoProps {
  block: BlockWithInfo,
}

export const HelpButton = (p: BlockInfoProps) => {
  const lang = useAppSelector(getLanguage);
  const link = helpdeskLinks[p.block][lang];
  return (
    <Button
      endIcon={<HelpOutline/>}
      component={'a'}
      target={'_blank'}
      href={link}
    >
      Help
    </Button>
  )
}
