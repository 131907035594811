import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import publicApi from "./services/public/publicApi";
import securedApi from "./services/secured/securedApi";
import { appReducer } from "./slices/app";
import { notificationReducer } from "./slices/notifications";
import { httpErrorMiddleware } from "./middlewares/errors";
import { reportingReducer } from "./slices/reporting";
import { newFeedReducer } from "./slices/newfeed";
import { modalReducer } from "./slices/modal";
import { campaignsReducer } from "./slices/campaigns";
import { campaignReducer } from "./slices/campaign/campaign";
import { shopReducer } from "./slices/shop";
import { productsReducer } from "./slices/products";
import { appNotificationsReducer } from "./slices/app_notifications";
import { loginReducer } from "./slices/login";
import { productReducer } from "./slices/product";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    app: appReducer,
    notification: notificationReducer,
    reporting: reportingReducer,
    newFeed: newFeedReducer,
    modal: modalReducer,
    campaigns: campaignsReducer,
    campaign: campaignReducer,
    shop: shopReducer,
    products: productsReducer,
    appNotifications: appNotificationsReducer,
    product: productReducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [securedApi.reducerPath]: securedApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
    httpErrorMiddleware,
    publicApi.middleware,
    securedApi.middleware,
  ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
