import React, { memo } from "react";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { EmailOutlined, Logout, Settings, Support } from "@mui/icons-material";
import { LanguageMenuItem } from "./LanguageMenuItem";
import { ModeMenuItem } from "./ModeMenuItem";
import { Link } from "react-router-dom";
import Urls from "../../../../assets/js/Urls";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../app/hooks";
import { getLanguage } from "../../../../app/slices/app";

type LanguageSetting = {
  locale: 'fr' | 'en';
  messageId: string;
};
const languageSettings: LanguageSetting[] = [
  { locale: 'fr', messageId: 'french' },
  { locale: 'en', messageId: 'english' },
];

interface AccountMenuProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  me: {
    email?: string;
    first_name?: string;
  } | null;
  logUserOut: () => void;
  setLang: (lang: 'fr' | 'en') => void;
  language: string;
  mode: string;
  toggleMode: () => void;
}

export const AccountMenu = memo(({
  open, anchorEl, handleClose, me, logUserOut, setLang, language, mode, toggleMode
}: AccountMenuProps) => {
  const lang = useAppSelector(getLanguage);
  return (
    <Menu
      id="account-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ mt: 1 }}
    >
      <MenuItem disableRipple>
        <ListItemIcon>
          <EmailOutlined/>
        </ListItemIcon>
        <ListItemText>{me?.email}</ListItemText>
      </MenuItem>
      <Divider/>
      {languageSettings.map(setting => (
        <LanguageMenuItem
          key={setting.locale}
          language={language}
          setLang={setLang}
          locale={setting.locale}
          messageId={setting.messageId}
        />
      ))}
      <ModeMenuItem mode={mode} toggleMode={toggleMode}/>
      <Divider/>
      <MenuItem
        component={'a'}
        href={`https://help.feedcast.io/${lang}`}
      >
        <ListItemIcon>
          <Support />
        </ListItemIcon>
        <ListItemText>
          Helpcenter
        </ListItemText>
      </MenuItem>
      <MenuItem
        component={Link}
        to={Urls.settings.me}
      >
        <ListItemIcon>
          <Settings/>
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="settings"/>
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={logUserOut}>
        <ListItemIcon>
          <Logout/>
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="logout"/>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
});
