import { Product, ProductStatus } from "../../../../app/types/products";
import { GoogleErrorModalData } from "../../../../app/slices/modal";
import { googleAttributesToProductData } from "../../../../assets/resources/googleErrors";
import React, { useState } from "react";
import ProductCorrection from "./ProductCorrection";
import { TabPanel } from "../../../App/Blocks/AppTabs";

interface ErrorsCorrectionProps extends Pick<GoogleErrorModalData, "field" | "company"> {
  products: Product[],
  errorId: number
}

export const ErrorsCorrection = ({ products, field, errorId, company }: ErrorsCorrectionProps) => {

  const findInitialPageIndex = () => {
    for (let i = 0; i < products.length; i++) {
      if ((products[i][`status_${company}`] & ProductStatus.FLAG_PENDING_CORRECTION) !== ProductStatus.FLAG_PENDING_CORRECTION) {
        return i;
      }
    }
    return 0; // Default to the first page if all products have the FLAG_PENDING_CORRECTION
  };

  const [page, setPage] = useState<number>(findInitialPageIndex());

  const productDataKey = field ? googleAttributesToProductData[field] : null;
  const nbProductsWithErrors = products.length;

  const currentPageProduct = products[page];

  return (
    <React.Fragment>
      {currentPageProduct && (
        <TabPanel
          index={`tab-${page}`}
          value={`tab-${page}`}
          key={`tab-${currentPageProduct.id}`}
          removePadding
        >
          <ProductCorrection
            {...currentPageProduct}
            company={company}
            field={productDataKey}
            errorId={errorId}
            currentPage={page}
            changePage={(newPage) => setPage(newPage)}
            maxPage={nbProductsWithErrors - 1}
            onSuccess={() => setPage(p => Math.min(p + 1, nbProductsWithErrors - 1))}
          />
        </TabPanel>
      )}
    </React.Fragment>
  );
};
