import React, { useEffect, useState } from "react";
import FCard, { FCardHeader } from "../../App/Blocks/Card";
import { MUIColor } from "../../App/Blocks/Misc";
import { GetCurrentFeedId, percentage } from "../../../app/utils";
import productsApi from "../../../app/services/secured/products";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { CloudOff, MoreHoriz, Storefront } from "@mui/icons-material";

/**
 * CatalogStatus.tsx
 * Summarize catalog status in performances tab
 */

type Statuses = { errors: number, warnings: number, success: number, total: number, pendings: number };

interface CatalogStatusProps {
  header?: boolean,
}

const CatalogPercentages = ({ header = true }: CatalogStatusProps) => {
  const feed = GetCurrentFeedId(),
    rep = productsApi.useGetProductReportingQuery({ feed })
  ;

  const [ percentages, setPercentages ] = useState<Statuses>();

  useEffect(() => {
    if (rep.isSuccess) {
      const defaultData = { errors: 0, warnings: 0, success: 0, pendings: 0, total: 0 };
      const goData = rep.data.platforms?.go?.current ?? defaultData;
      const bgData = rep.data.platforms?.bg?.current ?? defaultData;

      const mergedData = {
        errors: goData.errors + bgData.errors,
        warnings: goData.warnings + bgData.warnings,
        success: goData.success + bgData.success,
        pendings: goData.pendings + bgData.pendings,
        total: goData.total + bgData.total,
      };

      setPercentages({
        errors: percentage(mergedData.errors, mergedData.total),
        warnings: percentage(mergedData.warnings, mergedData.total),
        success: percentage(mergedData.success, mergedData.total),
        pendings: percentage(mergedData.pendings, mergedData.total),
        total: mergedData.total,
      });
    }
  }, [ rep.originalArgs?.feed, rep.isSuccess ]);

  return <Box className={"catalog-status"} sx={{ flex: 1 }}>
    {header && (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <FCardHeader size={"small"} icon={<Storefront/>} title={"catalog-status"} margin={false}/>
        <IconBtn/>
      </Box>
    )}
    <FCard>
      {(rep.data?.platforms?.go?.current.total || 0) > 0
        ? <Box>
          {percentages && (<Stack spacing={1}>
            <StatusLine
              variant={"error"}
              value={percentages.errors.toFixed(0)}
              label={"products-errors"}
            />
            <StatusLine
              variant={"warning"}
              value={percentages.warnings.toFixed(0)}
              label={"warnings"}
            />
            <StatusLine
              variant={"info"}
              value={percentages.pendings.toFixed(0)}
              label={"products-pending"}
            />
            <StatusLine
              variant={"success"}
              value={percentages.success.toFixed(0)}
              label={"valid-products"}
            />
          </Stack>)}
        </Box>
        : <CloudOff/>
      }
    </FCard>
  </Box>
};

const IconBtn = () => {
  const feed = GetCurrentFeedId();
  return (
    <IconButton
      component={Link}
      to={Urls.products.optimisation(feed)}
    >
      <MoreHoriz/>
    </IconButton>
  )
}

const StatusLine = ({ variant, value, label }: { variant: MUIColor, value: string, label: string }) => {
  return <Stack direction={"row"} justifyContent={"space-between"}>
    <Box display={'flex'} alignItems={"center"}>
      <Chip size={"small"} color={variant} sx={{ mr: 1, height: "12px", width: '12px' }}/>
      <FormattedMessage id={label}/>
    </Box>
    <Typography color={`${variant}.main`}>{value}%</Typography>
  </Stack>
}

export default CatalogPercentages;
