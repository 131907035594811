import { useCallback, useEffect } from "react";
import { ApiUrls } from "../../assets/js/ApiUrls";
import { StorageManager } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  addAppNotif,
  AppNotifData,
  AppNotification,
  getAppNotifications,
  LogType,
  MerchantCenterData,
  MerchantSeverityMap
} from "../slices/app_notifications";
import { getCurrentFeed } from "../slices/app";
import { GetCurrentFeedId } from "../utils";
import securedApi from "../services/secured/securedApi";
import { AppThunk } from "../store";
import {
  getSeverityByType,
  linkedToFeed,
  realtimeTrackedLogs,
  showInBadgeMapping
} from "../../components/App/Header/AppNotifications/appNotificationsUtils";

const pushNewNotification = (newNotification: AppNotification, feedId: number): AppThunk => dispatch => {
  if (newNotification.data?.feed === null || newNotification.data?.feed === feedId) {
    dispatch(addAppNotif(newNotification));
  }
};

function useSSE() {

  const dispatch = useAppDispatch();
  const appNotifications = useAppSelector(getAppNotifications);

  const currFeed = useAppSelector(getCurrentFeed);
  const feedId = GetCurrentFeedId();

  const checkExists = (id: number) => appNotifications.findIndex(n => n.id === id) >= 0;

  // TODO - check if it works properly
  const hasProducts = (currFeed?.last_stat?.imported || 0) > 0;
  const callback = useCallback((type: LogType) => {
    if (type === LogType.FEED_IMPORT_END && !hasProducts) {
      dispatch(securedApi.util.invalidateTags([ "Products", "Product", "DynamicLabels", "Feed", "UserFeed" ]));
    }
  }, [ hasProducts ]);

  const handleEvent = (type: LogType, event: any) => {

    const data: AppNotifData<any> = JSON.parse(event.data);

    if (data && currFeed?.id === data.feed) {

      const eventId = parseInt(event.lastEventId);
      const hasNotification = checkExists(eventId);
      const linkToFeed = linkedToFeed[type];

      if (!hasNotification && (linkToFeed ? (feedId === data.feed) : true)) {

        dispatch(pushNewNotification({
          id: eventId,
          type,
          data,
          date: data.timestamp,
          read: false,
          showInBadge: showInBadgeMapping[type],
          severity: type !== LogType.MERCHANT_CENTER
            ? getSeverityByType(type)
            : MerchantSeverityMap[(data as MerchantCenterData).severity],
        }, feedId));

        callback(type);
      }
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}${ApiUrls.user.messages}?access_token=${StorageManager.get()}`;
    const eventSource = new EventSource(url);

    realtimeTrackedLogs.forEach((type: LogType) =>
      eventSource.addEventListener(type.toString(), (event) => handleEvent(type, event))
    );

    return () => {
      eventSource.close();
    };
  }, [ currFeed ]);
}

export default useSSE;
