import AppSecured from "../../../App/AppSecured/AppSecured";
import Subscription from "./Subscription";
import React from "react";

const SubscriptionPage = () => {
  return <AppSecured lockNav={true}>
    <Subscription isShown={true} />
  </AppSecured>
}

export default SubscriptionPage
