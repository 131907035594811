import { FormattedMessage, useIntl } from "react-intl";
import google_countries from "../../../../../assets/resources/google_countries.json";
import {
  setBingLanguage,
  setLocaleFilters,
  setMetaCountries,
  setWarmupLocation
} from "../../../../../app/slices/campaign/campaign";
import google_languages from "../../../../../assets/resources/google_languages.json";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Selector from "./Selector";
import { Autocomplete, Box, Collapse, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { adsCampaigns } from "../../../../../app/slices/campaign/constants";
import { getCampaign } from "../../../../../app/slices/campaign/getters";
import CampaignCard from "../CampaignCard";
import { PinDrop } from "@mui/icons-material";
import useCampaignDraft from "../hooks/useCampaignDraft";

type LocationNode = { label: string, id: number, code: string };
// type LanguageNode = Pick<CountryNode, "label" | "id" | "code">

interface LocationFiltersProps {
  language?: boolean,
  country?: boolean,
}

const countriesOptions = google_countries.map(c => ({ label: c.name, id: c.id, code: c.code }));
const languagesOptions = google_languages.map(c => ({ label: c.name, id: c.id, code: c.code }));

function convertLocationsToString(locations: LocationNode[], returnCodeCondition: boolean): string[] {
  const arrRtn: string[] = [];
  (locations ?? []).forEach(option => arrRtn.push(option[`${returnCodeCondition ? 'code' : 'id'}`].toString()));
  return arrRtn;
}

const LocationFilters = ({ language = true, country = true }: LocationFiltersProps) => {

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const campaign = useAppSelector(getCampaign);
  const company = adsCampaigns[campaign.type].company;
  const { hasDraft } = useCampaignDraft();

  const [all, setAll] = useState(((campaign.filters?.location?.length || campaign.adgroup_bing.languages?.length) || 0) === 0);

  const [stateValue, setStateValue] = useState<{ countries: LocationNode[], languages: LocationNode[] }>({
    countries: [],
    languages: [],
  }); // Only for display purpose (filtering the list when items are selected)

  const countriesStateNode = useMemo(() => {
    return company === "google"
      ? campaign.filters?.location
      : company === "meta" ? campaign.adsets[0].target_countries : []
  }, [campaign.filters?.location, campaign.adsets[0].target_countries, company]);

  const languagesStateNode = useMemo(() => (company === "google" ? campaign.filters?.language : campaign.adgroup_bing.languages) ?? [],
    [campaign.filters?.language, campaign.adgroup_bing.languages, company]);

  const addCountries = (e: any, s: LocationNode[]) => {

    setStateValue(prev => ({ ...prev, countries: s }));
    const location = convertLocationsToString(s, company !== "google");

    if (company === "google") {
      dispatch(setLocaleFilters({ type: "location", data: s.length ? location : [] }))
    } else if (company === "meta") {
      dispatch(setMetaCountries(s.length ? location : ["fr"]));
    }
  }

  const addLanguage = (e: any, s: LocationNode[]) => {

    setStateValue(prev => ({ ...prev, languages: s }));

    const language = convertLocationsToString(s, company === "microsoft");

    if (company === "microsoft") {
      dispatch(setBingLanguage(language));
    } else {
      dispatch(setLocaleFilters({ type: "language", data: language }));
    }
  }

  const setAllReset = (v: boolean) => {
    setAll(v);
    addCountries(null, []);
    addLanguage(null, []);
  }

  // Initialise with existing values
  useEffect(() => {
    if (campaign.id?.length || hasDraft) {
      const filterOptions = (options: LocationNode[], stateNode: any) => options.filter(option =>
        stateNode.includes((company === "google" ? option.id.toString() : option.code).toUpperCase())
      );

      setStateValue({
        countries: filterOptions(countriesOptions, countriesStateNode),
        languages: filterOptions(languagesOptions, languagesStateNode),
      });
    }
  }, [campaign.id?.length, countriesStateNode, languagesStateNode, company, hasDraft]);

  useEffect(() => {
    company === "meta" && setAll(false);
  }, [company]);

  return (
    <Box>
      <CampaignCard
        icon={<PinDrop />}
        title={"diffusion-country"}
        action={(
          <Selector
            all={all}
            setAll={(v) => setAllReset(v)}
            type={"location"}
          />
        )}
        padding={false}
      >
        <Collapse in={!all} unmountOnExit>
          <Box p={2}>
            <Grid2 container spacing={2}>
              {country && (
                <Grid2 xs={language ? 6 : 12}>
                  <Autocomplete
                    multiple
                    id={"autocomplete-countries"}
                    options={countriesOptions}
                    filterSelectedOptions
                    onChange={addCountries}
                    value={stateValue.countries}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<FormattedMessage id={'countries'} />}
                        placeholder={intl.formatMessage({ id: "add-countries" })}
                      />
                    )}
                  />
                </Grid2>
              )}
              {language && (
                <Grid2 xs={6}>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    onChange={addLanguage}
                    options={languagesOptions}
                    value={stateValue.languages}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<FormattedMessage id={'languages'} />}
                        placeholder={intl.formatMessage({ id: "add-countries" })}
                      />
                    )}
                  />
                </Grid2>
              )}
            </Grid2>
          </Box>
        </Collapse>
      </CampaignCard>
    </Box>
  )
}

export default LocationFilters;
