export const isValidUrl = (url: string) => {
   try {
      new URL(url);
      return true;
   } catch {
      return false;
   }
};

export const isValidEmail = (email: string) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
};

