import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { EuroRounded } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { ShippingRate, ShippingTypes } from "../../../../../../app/types/feed";

type RatesProps = {
  r?: Array<ShippingRate>,
  shippingIndex: number,
  type: ShippingTypes,
}

const Rates = ({ shippingIndex, r, type }: RatesProps) => {

  const [ rates, setRates ] = useState<Array<ShippingRate>>([ {
    price: 0,
    upper_limit: null
  } ]);

  useEffect(() => {
    r && r.length && setRates(r);
  }, [ r ]);

  const onChange = (rate: ShippingRate, j: number) => {
    const r = [ ...rates ];
    r[j] = rate;

    if (r[r.length - 1] && r[r.length - 1].upper_limit) {
      r.push({ price: 0, upper_limit: null });
    }

    setRates(r);
  }

  return <>
    <Table
      size={"small"}
      className={"no-border-last-row"}
      sx={{
        "& td": { border: "none" }
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id={"from"}/>
          </TableCell>
          <TableCell>
            <FormattedMessage id={"to"}/>
          </TableCell>
          <TableCell><FormattedMessage id={"price"}/></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rates.map((r, j) =>
          <Rate
            key={`range-${j}`}
            rate={r}
            j={j}
            shippingIndex={shippingIndex}
            lastLimit={rates.length > 0 && rates[j - 1]?.upper_limit ? rates[j - 1].upper_limit : null}
            onChange={(rate) => onChange(rate, j)}
            type={type}
          />
        )}
      </TableBody>
    </Table>
  </>
}

type RateProps = {
  shippingIndex?: number,
  rate: ShippingRate,
  lastLimit: number | null,
  onChange: (rate: ShippingRate) => void,
  j: number,
  type: ShippingTypes
}

const Rate = ({ onChange, rate, lastLimit, shippingIndex, j, type }: RateProps) => {
  const intl = useIntl();
  const unity = type === ShippingTypes.Weight ? "kg" : "€";
  const lastLimitDisplay = lastLimit ? `${type === ShippingTypes.Weight ? (lastLimit + 0.001).toFixed(3) : lastLimit + 1} ${unity}` : `0 ${unity}`;

  const [ratePrice, setRetPrice] = useState(0);

  useEffect(() => {
    setRetPrice(rate.price);
  }, [rate.price]);

  return <TableRow>
    <TableCell sx={{ whiteSpace: "nowrap", width: '20%' }}>
      {lastLimitDisplay}
    </TableCell>
    <TableCell sx={{ pr: 0 }}>
      <FormControl fullWidth>
        <TextField
          size={"small"}
          type={'number'}
          name={`shipping[${shippingIndex}][rates][${j}][upper_limit]`}
          placeholder={intl.formatMessage({ id: "leave-blank-for-max" })}
          inputProps={{
            min: 0,
            step: 0.001,
          }}
          onChange={(e) => onChange({ ...rate, upper_limit: parseFloat(e.target.value) })}
          value={rate.upper_limit ?? undefined}
          onBlur={(e) => {
            const val = parseFloat(e.target.value);
            if (lastLimit && val > 0 && val < lastLimit + 0.01) {
              onChange({ ...rate, upper_limit: lastLimit + 0.01 });
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>
              {unity}
              {/*<EuroRounded />*/}
            </InputAdornment>
          }}
        />
      </FormControl>
    </TableCell>
    <TableCell>
      <FormControl fullWidth>
        <TextField
          size={"small"}
          name={`shipping[${shippingIndex}][rates][${j}][price]`}
          type={'number'}
          inputProps={{
            step: 0.01,
          }}
          value={ratePrice}
          onChange={(e) => setRetPrice(parseFloat(e.target.value))}
          required
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>
              <EuroRounded/>
            </InputAdornment>
          }}
        />
      </FormControl>
    </TableCell>
  </TableRow>
}

export default Rates;
