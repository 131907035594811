import publicApi from "./publicApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { jsonToFormData } from "../../../components/App/Blocks/SimpleForm";
import { ApiSuccess } from "../../../../types";
// import {UserSite} from "../../slices/app";

export type LoginPayload = {
  email?: string,
  password?: string,
  google_token?: string,
}

export type LoginResponse = {
  access_token: string,
  is_new?: boolean,
};

export type SignupPayload = {
  name: string,
  email: string,
  password: string,
  password_confirm: string
}

const authApi = publicApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({
        url: ApiUrls.auth.login,
        method: 'POST',
        body,
      })
    }),
    loginWithGoogle: build.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({
        url: ApiUrls.auth.loginWithGoogle,
        method: 'POST',
        body,
      })
    }),
    signup: build.mutation<any, SignupRequest>({
      query: (body) => ({
        url: ApiUrls.auth.signup,
        method: 'POST',
        body,
      })
    }),
    validate: build.mutation<{ success: boolean, email: string, company: string }, { token: string }>({
      query: (body) => ({
        url: ApiUrls.auth.validate(body.token),
        method: 'POST',
      })
    }),
    refresh: build.mutation<{ success: true }, { email: string }>({
      query: (body) => ({
        url: ApiUrls.auth.refresh,
        body,
        method: 'POST',
      })
    }),
    refreshPassword: build.mutation<ApiSuccess, { token: string, passwords: Pick<SignupPayload, 'password' | 'password_confirm'> }>({
      query: (body) => ({
        url: ApiUrls.auth.refreshPassword(body.token),
        body: body.passwords,
        method: 'POST',
      })
    }),
    resellerCookie: build.mutation<ResellerCookie, string>({
      query: (resellerCode) => ({
        url: ApiUrls.auth.resellerCookie(resellerCode),
        method: 'POST',
      })
    }),
    incrementCookie: build.mutation<ApiSuccess, string>({
      query: (hash) => ({
        url: ApiUrls.auth.incrementCookie(hash),
        method: 'GET',
      })
    })
  }),
})

export default authApi;

type ResellerCookie = {
  cookie: string
}

export type SignupRequest = {
  email: string,
  name?: string,
  registration_code?: string,
  password: string,
  password_confirm: string,
  feed: null | FeedWithLocale | FeedWithoutLocale     // Feed is optional. Can be created with locale or
}

type FeedWithoutLocale = {
  url: string,
  domain: string,
  country: string,
  language: string
}

type FeedWithLocale = {
  url: string,
  domain: string,
  locale: string
}