import { BackButton, UnavailableBlur } from "../../../../App/Blocks/Misc";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { FormattedMessage } from "react-intl";
import FCard from "../../../../App/Blocks/Card";
import { Link } from "react-router-dom";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useAccounts } from "../../../../../app/hooks/useAccounts";
import Grid2 from "@mui/material/Unstable_Grid2";
import { adsCampaigns, campaignsType } from "../../../../../app/slices/campaign/constants";
import React from "react";
import { motion } from "framer-motion";
import { CampaignNodeProps, getCampaignsTypes } from "./getCampaignsTypes";
import { GetCampaignDraftNodeId } from "../../CampaignDetail/hooks/useCampaignDraft";
import { Article } from "@mui/icons-material";

const NewCampaign = ({ toggleNew }: { toggleNew: () => void }) => {
  const feed = GetCurrentFeedId();
  const [ accounts ] = useAccounts();
  const campaigns = getCampaignsTypes(feed, accounts);
  return (
    <Box>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <BackButton size={"lg"} onClick={toggleNew}/>
          <Box ml={1.5} lineHeight={1}>
            <Typography variant={"h5"} lineHeight={1}><FormattedMessage id={"new-campaign"}/></Typography>
            <Typography color={"text.secondary"}><FormattedMessage id={"new-campaign-desc"}/></Typography>
          </Box>
        </Box>
      </Stack>
      <Grid2 container spacing={1} mt={1}>
        {campaigns.map(c => (
          <Grid2 key={`c-${c.id}`} xs={12}>
            <CampaignNode {...c} />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
}

const CampaignNode = ({ id, link, logos, status, available, company }: CampaignNodeProps) => {
  const feed = GetCurrentFeedId();
  const hasDraftCampaign = window.localStorage.getItem(GetCampaignDraftNodeId({ feed, type: id }));
  return (
    <Tooltip
      title={!available ? (
        <Typography>
          <FormattedMessage id={"missing-configuration"}/>
        </Typography>
      ) : ""}
      placement={"top"}
      arrow
    >
      <motion.div
        initial={{
          transform: "scale(1)",
        }}
        whileHover={{
          transform: "scale(1.01)",
        }}
      >
        <Box height={'100%'} position={"relative"}>
          <FCard sx={{ height: '100%' }} clickable>
            <Box
              display={'flex'}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={'100%'}
              component={Link}
              to={link}
              sx={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box mr={1.5}>
                      <img src={adsCampaigns[campaignsType[id]].logo} style={{ width: '40px' }}/>
                    </Box>
                    <Box>
                      <Typography variant={"body2"} lineHeight={1} color={"secondary"}>
                        <FormattedMessage id={status}/>
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography variant={'h6'}><FormattedMessage id={id}/></Typography>
                        {
                          hasDraftCampaign && (
                            <Chip
                              icon={<Article/>}
                              size={"small"}
                              color={"warning"}
                              label={<FormattedMessage id={'draft'} />}
                            />
                          )
                        }
                      </Box>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <Stack direction={'row'} spacing={1}>
                      {logos.map((l, i) => <Box key={`logo-${l}-${i}`}>
                          <img src={l} style={{ width: '20px' }}/>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Box>
                <Box>
                  <Typography color={"text.secondary"}>
                    <FormattedMessage id={id + "-desc"}/>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </FCard>
          {!available && <UnavailableBlur/>}
        </Box>
      </motion.div>
    </Tooltip>
  )
}

export default React.memo(NewCampaign);
