import projectsApi from "../../../app/services/secured/projects";
import SimpleForm from "../../App/Blocks/SimpleForm";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ButtonsNav } from "./ButtonsNav";
import React, { useEffect } from "react";
import { PageNavProps } from "./Onboarding";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getNewFeedProject, setNewFeedProject } from "../../../app/slices/newfeed";

export const CreateProject = (p: PageNavProps) => {

  const dispatch = useAppDispatch();

  const getProjectsQuery = projectsApi.useGetProjectsQuery();
  const [ getProject, projectRes ] = projectsApi.useLazyGetProjectQuery();

  const projects = getProjectsQuery.data ?? [];
  const firstProject = projects[0];

  const [ newP, newPRes ] = projectsApi.useNewProjectMutation();
  const [ setProject, setProjectRes ] = projectsApi.useEditProjectMutation();

  // The user has already created the project, default value
  const projectId = useAppSelector(getNewFeedProject);
  useEffect(() => {
    if (projectId !== null) {
      getProject({ id: projectId });
    }

    // The user has previously begin the onboarding. Use the first project found
    else if (projects && projects.length > 0) {
      dispatch(setNewFeedProject(firstProject?.id));
    }
  }, [ projectId !== null, projects?.length ]);

  useEffect(() => {
    if (newPRes.isSuccess) {
      dispatch(setNewFeedProject(newPRes.data.id));
    }
  }, [ newPRes ]);

  return <SimpleForm
    key={`${projectRes.data?.name}-${firstProject?.name}`}
    onSubmit={s => {
      return !projectRes.data?.name.length
        ? newP(s as { name: string })
        : setProject({ project: projectId as number, name: s.name })
    }}>
    <FormControl fullWidth>
      <TextField
        required
        name={"name"}
        defaultValue={projectRes.data?.name ?? firstProject?.name}
        label={<FormattedMessage id={"project-name"}/>}
      />
    </FormControl>
    <Typography variant={"caption"}>
      <FormattedMessage id={"project-use"}/>
    </Typography>
    <Box mt={1}>
      <ButtonsNav
        {...p}
        currentApi={
          projectId === null
            ? newPRes
            : setProjectRes
        }
      />
    </Box>
  </SimpleForm>
}
