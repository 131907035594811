import { useParams } from "react-router-dom";
import theme from '../assets/scss/variables.module.scss';
import { Product, ProductData } from "./types/products";
import { feedSources } from "./slices/shop";
import { FeedSourceEnum, UserFeed } from "./types/feed";
import { UAParser } from 'ua-parser-js';

export const sumReducer = (prev: number, row: number) => prev + row;

/** Transform feed's URL's into domain naim only */
export const niceUrl = (url: string) => {
  return url?.replace(/^https?:\/\/|\/$/g, '').replace(/^HTTPS?:\/\/|HTTP?:\/\//g, '');
}

/** Get source record from global object to access its image, logo, tutorial, etc */
type FeedSourceRecordParams = {
  source: FeedSourceEnum | undefined | null,
  url: string | undefined,
}

export const getFeedSourceRecord = ({ source, url }: FeedSourceRecordParams) => {
  const key = source && source !== FeedSourceEnum.Manual ? source : (url && url.includes("docs.google.com/spreadsheets") ? FeedSourceEnum.GoogleSheets : "fictif");
  return feedSources[key ?? FeedSourceEnum.Manual];
}

/** Get current feed ID from params, always existing when navigating through the app */
export const GetCurrentFeedId = () => {
  const { feedId } = useParams<{ feedId: string }>();
  if (!feedId) {
    return -1;
  }
  return parseInt(feedId as string);
}

/** Use feed's custom data if it exists, otherwise initial data */
export const FeedDataFeedcast = ({ data, custom_data }: Product) => {
  const custom: Partial<ProductData> = custom_data ?? {};
  return Object.keys(custom).reduce((acc, key) => {
    const value = custom[key as keyof ProductData];
    return { ...acc, [key]: typeof value === "string" && value.length ? value : data[key as keyof ProductData] }
  }, data);
}

/** display locale date */
export const getLocaleDate = (date: string, options?: boolean, time?: boolean) => {
  const locales = navigator.languages;
  let opt = {};
  if (options) {
    opt = { day: "2-digit", month: "short", year: "numeric" }
  }
  if (time) {
    opt = { ...opt, hour: "2-digit", minute: "2-digit" }
  }
  return (new Date(date).toLocaleDateString(locales[2], opt));
}

export const percentage = (value: number, total: number): number => (value * 100 / total);

export const getAppTheme = theme;

export function capitalizeFirstLetter(s: string) {
  return s?.length ? s?.charAt(0).toUpperCase() + s?.slice(1).toLowerCase() : "";
}

export const smallTitle = ({
  title, size = 50
}: {
  title: string | undefined,
  size?: number,
}) => (title?.length || 0) > size ? title?.slice(0, size) + "..." : title;

export const getFeedSubStatus = (feed: UserFeed | null) => {
  if (feed !== null) {
    const sub = feed.current_subscription;

    if (sub) {
      if (sub.is_cancelled) {
        return "canceled";
      } else {
        const dates = getFeedDates(feed);

        if (sub.plan) {
          return sub.plan.plan ?? "free";
        }

        if (dates) {
          if (dates.trial && dates.trial >= dates.today) {
            return "trial";
          } else if (dates.end && dates.end >= dates.today) {
            return "active";
          }
        }
      }
    }
    return "free";
  }
  return "free";
};

export const getFeedDates = (feed: UserFeed) => {
  const sub = feed.current_subscription;

  return {
    start: sub?.date_start_subscription ? new Date(sub.date_start_subscription * 1000) : null,
    end: sub?.date_end_subscription ? new Date(sub.date_end_subscription! * 1000) : null,
    trial: sub?.date_end_trial ? new Date(sub.date_end_trial * 1000) : null,
    today: new Date(),
  };
};

export type ImageFormat = "square" | "portrait" | "landscape" | "unknown";

export const getImageFormat = (a: { width: number, height: number }): ImageFormat => {
  const value = (a?.width / a?.height);
  return value === 1
    ? "square"
    : value < 1
      ? "portrait"
      : value > 1.3 && value <= 2
        ? "landscape"
        : "unknown";
}

export function debounce<F extends (...args: any[]) => any>(func: F, wait: number): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

interface BrowserData {
  browserName: string;
  browserVersion: string;
  userAgent: string;
  operatingSystem: string;
  screenWidth: number;
  screenHeight: number;
  currentUrl: string;
  timestamp: string;
}

export function gatherBrowserData(): BrowserData {
  const userAgent = navigator.userAgent;
  const parser = new UAParser(userAgent); // UAParser is a library to parse user agent string

  return {
    browserName: parser.getBrowser().name || "Unknown",
    browserVersion: parser.getBrowser().version || "Unknown",
    userAgent: userAgent,
    operatingSystem: parser.getOS().name || "Unknown",
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    currentUrl: window.location.href,
    timestamp: new Date().toISOString(),
  };
}

export function getFeedHasSource(f: UserFeed) {
  return ((f.url?.length || 0) > 0) ||
    (f.last_stat?.imported || 0) > 0 ||
    (f.source === FeedSourceEnum.Prestashop || f.source === FeedSourceEnum.Woocommerce);
}
