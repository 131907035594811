import React from "react";
import AppSecured from "../../App/AppSecured/AppSecured";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Alert,
  Badge,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Link as LinkMUI,
  Divider
} from "@mui/material";
import FCard from "../../App/Blocks/Card";
import ProductDoughnut from "./ChannelsCatalogs/ProductDoughnut";
import { StatusMetrics } from "./ChannelsCatalogs/StatusMetrics";
import { capitalizeFirstLetter, GetCurrentFeedId } from "../../../app/utils";
import productsApi from "../../../app/services/secured/products";
import { Company } from "../../../app/types/campaign";
import useAnchorEl from "../../../app/hooks/useAnchorEl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { modalShowSubscription, modalShowSyncFeed } from "../../../app/slices/modal";
import { adsCompanies } from "../../../app/slices/campaign/constants";
import { AutoAwesome, CloudSync, MoreHoriz, TrendingFlat } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { getShop } from "../../../app/slices/shop";
import { getCurrentFeed, getCurrentFeedSubStatus, getUser } from "../../../app/slices/app";

export interface CatalogStatusProps {
  direction?: "column" | "row",
}

const CatalogStatus = () => {
  return (
    <AppSecured>
      <ChannelsCatalog />
    </AppSecured>
  )
}

export interface ChannelsCatalogProps {
  page?: "full" | "dashboard",
}

export const ChannelsCatalog = ({ page = "full" }: ChannelsCatalogProps) => {

  const feedId = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const subStatus = useAppSelector(getCurrentFeedSubStatus);
  const user = useAppSelector(getUser);

  const feed = useAppSelector(getCurrentFeed);

  // TODO - useCatalog
  const rep = productsApi.useGetProductReportingQuery({ feed: feedId });
  const reporting = rep.data;

  const freeListing = reporting?.platforms?.fl;
  const googleAds = reporting?.platforms?.go;
  const metaAds = reporting?.platforms?.fb;
  const microsoftAds = reporting?.platforms?.bg;

  const shop = useAppSelector(getShop);
  const activeChannels = shop.platforms.enabled;
  const hasSubscription = (subStatus !== "free" || user?.is_free === true);

  const bigDisplay = page === "full";

  return (
    <Box>
      <Grid2 container>
        <Grid2 xs={12} p={bigDisplay ? 2 : 0}>
          {bigDisplay && (
            <CatalogTitle company={"google"} />
          )}
          <Box>
            <Grid2 container spacing={!bigDisplay ? 2 : 4}>
              <Grid2 xs={6}>
                <FCard sx={{ p: bigDisplay ? 2 : 0 }}>
                  <Grid2 container spacing={2} alignItems={"center"}
                    sx={!activeChannels.google ? { filter: "blur(8px)" } : {}}>
                    <Grid2 xs={page !== "dashboard" ? 5 : 12}>
                      {!bigDisplay && (
                        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                          <img src={adsCompanies["google"].image} style={{ height: "15px" }} />
                        </Box>
                      )}
                      <ProductDoughnut
                        page={page}
                        channelReporting={freeListing}
                      />
                      {page === "dashboard" && (
                        <Legend txt={"Google Free Listing"} />
                      )}
                    </Grid2>
                    {page !== "dashboard" && (
                      <Grid2 xs>
                        <StatusMetrics
                          page={page}
                          company={"google"}
                          channelReporting={freeListing}
                          title={"FREE LISTING"}
                        />
                      </Grid2>
                    )}

                  </Grid2>
                </FCard>
              </Grid2>
              <Grid2 xs={6}>
                <Badge
                  component={(!activeChannels.google || !hasSubscription) ? Button : Box}
                  color={"secondary"}
                  onClick={(!hasSubscription) ? () => dispatch(modalShowSubscription()) : undefined}
                  sx={{
                    width: '100%',
                    p: 0,
                    "& .MuiBadge-badge": {
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      p: 2,
                      display: (hasSubscription) ? "none" : undefined,
                      opacity: '0.8',
                    }
                  }}
                  badgeContent={
                    (!hasSubscription)
                      ? (
                        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                          <AutoAwesome fontSize={"small"} />
                          <Typography variant={"body2"}>
                            <FormattedMessage id={'paid-feature'} />
                          </Typography>
                        </Box>
                      )
                      : null
                  }
                >
                  <FCard sx={{ p: bigDisplay ? 2 : 0, width: '100%' }}>
                    <Grid2
                      container spacing={2} alignItems={"center"}
                      sx={(!activeChannels.google || !hasSubscription) ? { filter: "blur(8px)", opacity: '0.3', } : {}}
                    >
                      <Grid2 xs={page !== "dashboard" ? 5 : 12}>
                        {!bigDisplay && (
                          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                            <img src={adsCompanies["google"].image} style={{ height: "15px" }} />
                          </Box>
                        )}
                        <ProductDoughnut
                          page={page}
                          channelReporting={googleAds}
                        />
                        {page === "dashboard" && (
                          <Legend txt={"Google Paid Shopping"} />
                        )}
                      </Grid2>
                      {
                        page !== "dashboard" && (
                          <Grid2 xs>
                            <StatusMetrics
                              page={page}
                              company={"google"}
                              channelReporting={googleAds}
                              title={"PAID SHOPPING"}
                            />
                          </Grid2>
                        )
                      }

                    </Grid2>
                  </FCard>
                </Badge>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 xs={12} p={bigDisplay ? 2 : 0} pt={!bigDisplay ? 2 : 0}>
          <Box>
            <Grid2 container spacing={!bigDisplay ? 2 : 4}>
              <Grid2 xs={6}>
                {bigDisplay && (
                  <CatalogTitle company={"meta"} />
                )}
                <FCard sx={{ p: bigDisplay ? 2 : 0 }}>
                  <Grid2 container spacing={2} alignItems={"center"}
                    sx={!activeChannels.meta ? { filter: "blur(8px)" } : {}}>
                    <Grid2 xs={page !== "dashboard" ? 5 : 12}>
                      {!bigDisplay && (
                        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                          <img src={adsCompanies["meta"].image} style={{ height: "15px" }} />
                        </Box>
                      )}
                      <ProductDoughnut
                        page={page}
                        channelReporting={metaAds}
                      />
                      {page === "dashboard" && (
                        <Legend txt={"Meta Paid Shopping"} />
                      )}
                    </Grid2>
                    {
                      page !== "dashboard" && (
                        <Grid2 xs>
                          <StatusMetrics
                            page={page}
                            company={"meta"}
                            channelReporting={metaAds}
                          />
                        </Grid2>
                      )
                    }

                  </Grid2>
                </FCard>
              </Grid2>
              <Grid2 xs={6}>
                {bigDisplay && (
                  <CatalogTitle company={"microsoft"} />
                )}
                <FCard sx={{ p: bigDisplay ? 2 : 0, height: '100%' }}>
                  <Grid2 container spacing={2} alignItems={"center"}
                    sx={!activeChannels.microsoft ? { filter: "blur(8px)" } : {}}>
                    <Grid2 xs={page !== "dashboard" ? 5 : 12}>
                      {!bigDisplay && (
                        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                          <img src={adsCompanies["microsoft"].image} style={{ height: "15px" }} />
                        </Box>
                      )}
                      <ProductDoughnut
                        page={page}
                        channelReporting={microsoftAds}
                      />
                      {page === "dashboard" && (
                        <Legend txt={"Microsoft Paid Shopping"} />
                      )}
                    </Grid2>
                    {
                      page !== "dashboard" && (
                        <Grid2 xs>
                          <StatusMetrics
                            page={page}
                            company={"microsoft"}
                            channelReporting={microsoftAds}
                          />
                        </Grid2>
                      )
                    }
                  </Grid2>
                </FCard>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

      </Grid2>
      {feed?.last_stat?.date_end_last_import && page !== "dashboard" && (
        <Box sx={{
          display: "flex",
          alignItems: "end",
          flexDirection: "column",
        }}>
          <Divider sx={{ my: 1, width: '10%' }} />
          <Typography sx={{ fontStyle: "oblique" }} variant={"body2"} color={"text.secondary"}>
            <FormattedMessage
              id={'last-import-date'}
              values={{
                date: new Date((feed?.last_stat?.date_end_last_import || 0) * 1000).toLocaleString()
              }}
            />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const Legend = ({ txt }: { txt: string }) => {
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
    }}>
      <Typography variant={"overline"} mt={1} color={"text.secondary"}>
        {txt}
      </Typography>
    </Box>
  )
}


export default CatalogStatus;


interface CatalogTitleProps {
  company: Company,
}

const CatalogTitle = ({ company }: CatalogTitleProps) => {

  const feed = GetCurrentFeedId();

  const { open, anchorEl, handleClick, handleClose } = useAnchorEl();
  const companiesWithActions: Array<Company> = ["google"];
  const dispatch = useAppDispatch();
  const showSyncFeed = () => dispatch(modalShowSyncFeed({ company }));

  const shop = useAppSelector(state => state.shop);
  const isConfigured = shop.accounts.canCreateCampaigns[company];
  const isActive = shop.accounts.hasChannelActive[company];
  // const hasIssues = useAppSelector(state => state.app.userAlerts).filter(a => a.feed.id === feed && a.account.)

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1, }}>
      <Box sx={{
        display: "flex", gap: 2, alignItems: "center", lineHeight: 1,
        filter: !isConfigured ? "grayscale(1)" : undefined,
        color: !isConfigured ? "text.disabled" : undefined,
      }}>
        <Box>
          <Badge variant={"dot"} color={isActive ? "success" : isConfigured ? "warning" : "default"}>
            <img src={adsCompanies[company].image} style={{ height: "30px" }} />
          </Badge>
        </Box>
        <Typography variant={"h5"}>
          {capitalizeFirstLetter(company)}
        </Typography>
        {isActive ? (
          <React.Fragment>
            <IconButton
              disabled={!companiesWithActions.includes(company)}
              onClick={handleClick}
              size={"small"}
            >
              <MoreHoriz />
            </IconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
              <MenuItem onClick={showSyncFeed}>
                <ListItemIcon>
                  <CloudSync />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id={'sync-feed'} />
                </ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        ) : isConfigured && (
          <Alert severity={"warning"} sx={{ p: 0, lineHeight: 2, px: 2 }}>
            <FormattedMessage
              id={'configured-but-not-active'}
              values={{
                a: (txt) => <LinkMUI component={Link} to={Urls.configuration(feed)}>{txt}</LinkMUI>
              }}
            />
          </Alert>
        )}
      </Box>
      <Box>
        {
          !isConfigured && (
            <Button
              size={"small"}
              variant={"contained"}
              color={"secondary"}
              component={Link}
              to={Urls.configuration(feed)}
            >
              <FormattedMessage id={'setup-channel'} />
            </Button>
          )
        }
      </Box>
    </Box>
  )
}
