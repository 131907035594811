import { Box, Chip, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { DateFromTimestamp } from "../../../App/Blocks/Misc";
import { Receipt } from "@mui/icons-material";
import React from "react";
import profileApi from "../../../../app/services/secured/profile";
import { FCardHeader } from "../../../App/Blocks/Card";
import { Invoice } from "../../../../app/types/profile";

interface InvoiceTablesProps {
  invoices: Invoice[],
}

const InvoiceTables = ({invoices}: InvoiceTablesProps) => {
  // const invoices = profileApi.useGetInvoicesQuery();
  // const invoiceList = invoices.data;
  return (
    <Box>
      {/*<FCardHeader title={"invoices"} margin={false} icon={<Receipt />} />*/}
      {/*<Typography variant={"h5"} mb={1}>*/}
      {/*  <FormattedMessage id={"invoices"}/>*/}
      {/*</Typography>*/}
      <Paper>
        <Table size={"small"} className={"no-border-last-row"}>
          <TableHead>
            <TableRow>
              <TableCell sx={{width:'10%'}} ><FormattedMessage id={"reference"}/></TableCell>
              <TableCell className={"text-center"}><FormattedMessage id={"status"}/></TableCell>
              <TableCell>Date</TableCell>
              <TableCell align={"right"}><FormattedMessage id={"amount"}/></TableCell>
              <TableCell style={{ width: '5%', textAlign: "end" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!invoices?.length && (<TableRow><TableCell colSpan={5}>
              <Typography color={"text.secondary"} variant={"body2"}>
                <FormattedMessage id={'no-data'}/>
              </Typography>
            </TableCell></TableRow>)}
            {[ ...invoices ?? [] ].sort((a, b) => b.created - a.created).map(i => (
              <TableRow key={`invoice-${i.code}`}>
                <TableCell>
                  <Typography variant={"body2"} color={"text.secondary"} whiteSpace={"nowrap"}>
                    {i.code}
                  </Typography>
                  {/*<Chip*/}
                  {/*  size={"small"}*/}
                  {/*  color={"primary"}*/}
                  {/*  label={i.code}*/}
                  {/*  variant={"outlined"}*/}
                  {/*/>*/}
                </TableCell>
                <TableCell className={"text-center"}>
                  <Chip
                    size={"small"}
                    color={i.status !== "open" ? "success" : "info"}
                    label={<FormattedMessage id={i.status}/>}
                    variant={"outlined"}
                  />
                </TableCell>
                <TableCell>{DateFromTimestamp(i.created, true)}</TableCell>
                <TableCell sx={{textAlign:"end"}}>
                  <FormattedNumber value={i.amount} style={"currency"} currency={"EUR"}/>
                </TableCell>
                <TableCell>
                  <IconButton
                    href={i.url}
                    target={'_blank'}
                    edge={"end"}
                    sx={{color:"text.secondary"}}
                  >
                    <Receipt/>
                  </IconButton>
                </TableCell>
              </TableRow>))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  )
}

export default InvoiceTables;
