import { FormattedMessage } from "react-intl";
import FCard from "../../../../App/Blocks/Card";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import React from "react";
import { Price, SubStatus } from "../../../../../app/types/feed";
import { motion } from "framer-motion";
import { TRIAL_VAL_HT } from "./SubPlans";

interface SubCardProps {
  pack: Price | undefined;
  active: boolean;
  onClick: () => void;
  trial?: boolean;
}

const services = {
  free: [ "free-1", "free-2", "free-3", "free-4", "free-5" ],
  starter: [ "starter-1", "starter-2", "starter-3", "starter-4", "starter-5" ],
  premium: [ "premium-1", "premium-2", "premium-3", "premium-4", "premium-5" ]
}
export const SubCard = ({ pack, active, onClick, trial }: SubCardProps) => {
  return (
    <motion.div
      whileHover={{
        y: 7,
      }}
      transition={{ duration: 0.2 }}
    >
      <FCard
        onClick={onClick}
        style={{
          height: '100%',
          cursor: "pointer",
        }}
        sx={{
          transition: "all 0.6s",
          // border: "1px solid #cecece",
          boxShadow: "none",
          borderColor: active ? "secondary.light" : "",
          //@ts-ignore
          background: (theme) => active ? theme.palette.gradient.main : theme.palette.background.palette,
        }}
      >
        {pack && (
          <Stack
            // justifyContent={"space-between"}
            height={'100%'}
            position={"relative"}
            // spacing={1}
          >
            <Box>
              <Typography variant={"h3"} textAlign={"center"} mb={1}>
                <FormattedMessage id={pack?.code}/>
              </Typography>

              <Typography textAlign={"center"} mb={2}>
                <FormattedMessage id={pack?.code + "-desc"}/>
              </Typography>

              <Box
                display={"flex"}
                justifyContent={"center"}
                my={2}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {trial && (
                    <Stack sx={{ alignItems: "end" }}>
                      <Box sx={{ display: "flex", alignItems: "end" }}>
                        <Typography variant={"h4"} lineHeight={0.8}>
                          {TRIAL_VAL_HT}
                        </Typography>
                        <Typography>€</Typography>
                      </Box>
                      <Typography variant={"caption"} color={"text.secondary"}>
                        <FormattedMessage id={"ht-first-month"}/>
                      </Typography>
                    </Stack>
                  )}
                  <Stack alignItems={"end"}>
                    <Stack alignItems={"end"} direction={"row"}>
                      {trial && (
                        <Typography variant={"caption"} color={"text.secondary"} mr={0.5}>
                          <FormattedMessage id={'then'}/>
                        </Typography>
                      )}
                      <Typography variant={"h4"} lineHeight={0.8}>{pack?.price}</Typography>
                      <Typography>€</Typography>
                    </Stack>
                    <Typography variant={"caption"} color={"text.secondary"}>
                      <FormattedMessage id={"ht-month"}/>
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              <Box>
                {services[pack?.code as "starter" | "premium" | "free"].map((s, i) => (
                  <Accordion
                    key={`service-${pack.code}-${i}`}
                    sx={{
                      background: "transparent",
                      "& *": {
                        background: "transparent",
                      },
                      "&.Mui-expanded": { mb: 0, mt: 0 }
                    }}
                  >
                    <AccordionSummary expandIcon={<Add/>}>
                      <Typography>
                        <FormattedMessage
                          id={s}
                          values={{
                            b: (txt) => <b>{`${txt}`}</b>
                          }}
                        />
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormattedMessage id={`${s}-desc`}/>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Stack>
        )}
      </FCard>
    </motion.div>
  )
}
