/**
 * Getters
 */
import { RootState } from "../../store";
import { AssetGroup, AssetsTexts, BiddingStrategy, CampaignSlice, CampaignType } from "../../types/campaign";

export const getCampaign = (state: RootState) => state.campaign;
export const getPerfMaxData = (state: RootState) => state.campaign.asset_group.assets ?? {};
export const getPerfMaxImages = (state: RootState) => [ "" ].concat(state.campaign.asset_group?.assets?.logos).concat(state.campaign.asset_group.assets?.marketing_images).concat(state.campaign.asset_group.assets.marketing_images_square).concat(state.campaign.asset_group.assets.marketing_images_portrait);

export const getAssetsText = (type: AssetsTexts, index: number) => (state: RootState) => {
  if (state.campaign.asset_group?.assets) {
    return state.campaign.asset_group?.assets[type][index];
  }
  return "";
}
export const getBiddingStrategy = (state: RootState) => state.campaign.bidding_strategy;
export const isNewCampaign = (state: RootState): boolean => !state.campaign.id;

// TODO - only for first node
export const getMetaEvents = (state: RootState) => state.campaign.adsets[0].events;
// export const getMetaEventDays = (state: RootState) => state.campaign.adsets[0].events[0].days;
export const getMetaCreative = (state: RootState) => state.campaign.adsets[0].ads[0].creative; // TODO - ads
export const getMetaAge = (state: RootState) => {
  return { age_min: state.campaign.adsets[0].age_min, age_max: state.campaign.adsets[0].age_max }
}
export const getAdset = (state: RootState) => state.campaign.adsets[0];
export const getAdsetName = (state: RootState) => state.campaign.adsets[0]?.name;
export const getMetaClassicType = (state: RootState) => state.campaign.adsets[0]?.events.length > 1 ? "retargeting" : "prospection";
export const getMetaPlatforms = (state: RootState) => state.campaign.adsets[0]?.platforms;
export const getMetaGender = (state: RootState) => state.campaign.adsets[0]?.genders;
export const getMetaBehaviours = (state: RootState) => state.campaign.adsets[0]?.behaviours;
export const getMetaInterests = (state: RootState) => state.campaign.adsets[0]?.interests;

const getGooglePerformanceMax = (campaign: CampaignSlice) => {
  const assetGroup: Partial<AssetGroup> = {
    id: campaign.asset_group.id,
    name: campaign.asset_group.name,
    product_group: campaign.asset_group.product_group
  };

  const {warnings, ...campaignAssets} = campaign.asset_group.assets;

  assetGroup.assets = {
    ...campaignAssets,
    headlines: campaign.asset_group.assets.headlines.filter(v => v),
    long_headlines: campaign.asset_group.assets.long_headlines.filter(v => v),
    descriptions: campaign.asset_group.assets.descriptions.filter(v => v),
  };

  return {
    target_roas: campaign.target_roas,
    filters: campaign.filters,
    asset_group: assetGroup as AssetGroup
  };
};

const getGoogleShoppingClassic = (campaign: CampaignSlice) => {
  const additionalProps:Record<string, any> = {};
  switch (campaign.bidding_strategy) {
    // case BiddingStrategy.manual_cpc:
    //   additionalProps['adgroup.target_cpc'] = campaign.adgroup.target_cpc;
    //   break;
    case BiddingStrategy.target_roas:
      additionalProps['target_roas'] = campaign.target_roas;
      break;
    case BiddingStrategy.maximize_clicks:
      additionalProps['target_cpc'] = campaign.target_cpc;
      break;
  }

  return {
    target_roas: campaign.target_roas,
    filters: campaign.filters,
    bidding_strategy: campaign.bidding_strategy,
    adgroup: { ...campaign.adgroup },
    ...additionalProps
  };
};

const getMetaClassic = (campaign: CampaignSlice) => {
  const { daily_budget, ...adset } = campaign.adsets[0];
  return { adsets: [adset] };
};

const getMetaShoppingAdvantage = (campaign: CampaignSlice) => {
  const { id, name, target_countries, daily_budget, ads, dsa_payor, dsa_beneficiary, ...rest } = campaign.adsets[0];
  return { adsets: [{ id, name, target_countries, daily_budget, ads, dsa_payor, dsa_beneficiary }] };
};

const getBingShopping = (campaign: any) => ({
  budget: parseFloat((campaign.budget ?? 0).toString()),
  adgroup: campaign.adgroup_bing as any
});

export const getCampaignApi = (state: RootState): Partial<CampaignSlice> => {
  const campaign = state.campaign;
  const d: Partial<CampaignSlice> = {
    name: campaign.name,
    budget: campaign.budget,
    type: campaign.type,
    enabled: campaign.enabled
  };

  // console.log(campaign.type);

  const typeToFunctionMap: Record<string, (campaign: any) => any> = {
    [CampaignType.GOOGLE_PERFORMANCE_MAX]: getGooglePerformanceMax,
    [CampaignType.GOOGLE_SHOPPING_CLASSIC]: getGoogleShoppingClassic,
    [CampaignType.META_CLASSIC]: getMetaClassic,
    [CampaignType.META_SHOPPING_ADVANTAGE]: getMetaShoppingAdvantage,
    [CampaignType.BING_SHOPPING]: getBingShopping
  };

  return { ...d, ...typeToFunctionMap[campaign.type]?.(campaign) };
};
