import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GetCurrentFeedId } from "../../app/utils";
import { closeModal, isModalOpened, ModalTypes } from "../../app/slices/modal";
import personas from "../../assets/img/personas/personas-sub.svg";
import { Link } from "react-router-dom";
import Urls from "../../assets/js/Urls";
import React from "react";
import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const ModalSubscription = () => {
  const show = useAppSelector(isModalOpened(ModalTypes.Subscription));
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const hide = () => dispatch(closeModal());
  return <React.Fragment>
    <Dialog open={show} onClose={hide}>
      <DialogContent sx={{p: 0}}>
        <img src={personas} style={{width:'100%'}} />

        <Stack spacing={2} p={4} alignItems={"center"}>
          <Typography variant={"h5"}>
            <FormattedMessage id={"subscribe"} />
          </Typography>
          <Typography textAlign={"center"}>
            <FormattedMessage id={"subscribe-desc"} />
          </Typography>
          <Button
            variant={"contained"}
            component={Link}
            to={Urls.settings.subEdit(feed)}
            onClick={hide}
            color={"secondary"}
          >
            <FormattedMessage id={"begin-subscription"} />
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  </React.Fragment>
}

export default ModalSubscription;
