import SuccessMessage from '../../../components/SuccessMessage';
import AppPublic from '../../AppPublic2';
import AuthWrapper from '../AuthWrapper';

interface RegisterSuccessProps {
   email: string | undefined;
}

const RegisterSuccess: React.FC<RegisterSuccessProps> = ({ email }) => {
   return (
      <AuthWrapper>
         <SuccessMessage
            title="registration-success"
            description="verification-email-sent"
            highlightedText={email}
            footer="check-inbox-verify"
         />
      </AuthWrapper>
   );
};

export default RegisterSuccess;
