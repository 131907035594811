import React, { ReactNode, useState } from "react";
import { useAccounts } from "../../../../app/hooks/useAccounts";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Drawer,
  IconButton, List,
  ListItem,
  ListItemIcon, ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Campaign,
  CheckCircle, CloudOff,
  ContentCopy,
  Facebook,
  Google,
  Microsoft, Settings,
  WarningAmberRounded,
  Error, WarningRounded, Warning
} from "@mui/icons-material";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import { AvailableCompany } from "../../../../app/types/campaign";
import useLoading from "../../../../app/hooks/useLoading";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getShop, getShopAccounts } from "../../../../app/slices/shop";
import { FormattedMessage } from "react-intl";
import { ChannelWrapper } from "./ChannelWrapper";

import { HelpButton } from "../../../App/Helpdesk/HelpButton";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TabPanel } from "../../../App/Blocks/AppTabs";
import { closeModal, getModalData, isModalOpened, ModalTypes } from "../../../../app/slices/modal";
import { addNotification } from "../../../../app/slices/notifications";
import { Pixel } from "../../../../app/services/secured/channels";
import { capitalizeFirstLetter } from "../../../../app/utils";
import useChannelStatus, { ChannelState } from "./useChannelStatus";
import img_fc from "../../../../assets/img/feedcast_alt.svg";
import { getCurrentFeed } from "../../../../app/slices/app";

export type ChannelWrapperProps = {
  company: AvailableCompany | "feedcast",
}

const Channels = () => {

  const shop = useAppSelector(getShop);
  const [ , { loading } ] = useAccounts();
  const nbChannelsRemaining = shop.platforms.remaining;

  const hasNoChannelActive = shop.platforms.nb_enabled === 0 && (
    shop.accounts.canCreateCampaigns.google ||
    shop.accounts.canCreateCampaigns.meta ||
    shop.accounts.canCreateCampaigns.microsoft
  )

  useLoading(loading);

  return (
    <Box>
      <Box display={"flex"} gap={2} alignItems={"center"}>
        <FCardHeader
          margin={false}
          icon={<Campaign fontSize={"large"}/>} title={"canals"}
          desc={
            nbChannelsRemaining > 0
              ? (
                <Typography variant={"caption"}>
                  <FormattedMessage
                    id={`platform${nbChannelsRemaining > 1 ? "s" : ""}-remaining`}
                    values={{ nb: shop.platforms.remaining }}
                  />
                </Typography>
              )
              : undefined
          }
          action={(<HelpButton block={"connect-ads-account"}/>)}
        />
      </Box>
      {hasNoChannelActive && (
        <Alert severity={"warning"} sx={{ mb: 1 }}>
          <AlertTitle>
            <FormattedMessage id={'no-active-channels'}/>
          </AlertTitle>
          <FormattedMessage id={'no-active-channels-desc'}/>
        </Alert>
      )}
      <DisplayChannels/>
      <TrackingDrawer/>
    </Box>
  );
};

const TrackingDrawer = () => {

  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.DrawerTracking));
  const close = () => dispatch(closeModal());
  const pixels = useAppSelector<Pixel[] | undefined>(getModalData);

  const copyClipboard = (code?: string) => {
    if (code) {
      navigator.clipboard.writeText(code);
      dispatch(addNotification({ type: "success", message: "integration-clipboard" }));
    }
  }

  return (
    <Drawer
      open={open}
      onClose={close}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        "& .MuiPaper-root": {
          borderRadius: 0,
        }
      }}
    >
      {pixels?.length && (
        <List disablePadding>
          {pixels?.map((p, i) => (
            <ListItem
              key={`p-${p.id}`}
              secondaryAction={
                <Tooltip
                  placement={"top"}
                  arrow
                  title={<FormattedMessage id={"copy-integration-code"}/>}
                >
                  <IconButton
                    size={"small"}
                    edge={"end"}
                    onClick={() => copyClipboard(p.conversion_code)}
                  >
                    <ContentCopy fontSize={"small"}/>
                  </IconButton>
                </Tooltip>
              }
              divider={i < ((pixels?.length || 0) - 1)}
            >
              <ListItemIcon>
                <Tooltip
                  placement={"top"}
                  arrow
                  title={
                    <FormattedMessage id={p.has_conversions ? "has-conversions" : "no-conversions"}/>
                  }
                >
                  {p.has_conversions ? <CheckCircle color={"success"}/> : <WarningAmberRounded color={"warning"}/>}
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                {p.name}
              </ListItemText>

            </ListItem>
          ))}
        </List>
      )}

    </Drawer>
  )
}

enum CHANNEL_TABS {
  GOOGLE,
  META,
  MICROSOFT,
  FEEDCAST
}

const DisplayChannels = () => {
  const [ tab, setTab ] = useState<CHANNEL_TABS>(CHANNEL_TABS.GOOGLE);
  return (
    <FCard variant={"no-padding"}>
      <Grid2 container spacing={0}>
        <Grid2 xs={2}>
          <Box sx={{ display: "flex", alignItems: "stretch", height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Tabs orientation={"vertical"} value={tab} onChange={(e, val) => setTab(val)}>
                <Tab label={<ChannelLabel company={"google"}/>} value={CHANNEL_TABS.GOOGLE}/>
                <Tab label={<ChannelLabel company={"meta"}/>} value={CHANNEL_TABS.META}/>
                <Tab label={<ChannelLabel company={"microsoft"}/>} value={CHANNEL_TABS.MICROSOFT}/>
                <Tab label={<FeedcastLabel/>} value={CHANNEL_TABS.FEEDCAST}/>
              </Tabs>
            </Box>
            <Divider orientation={"vertical"} sx={{ height: '100%' }}/>
          </Box>
        </Grid2>
        <Grid2 xs={10}>
          <TabPanel removePadding index={tab} value={CHANNEL_TABS.GOOGLE}>
            <ChannelWrapper company={"google"}/>
          </TabPanel>
          <TabPanel removePadding index={tab} value={CHANNEL_TABS.META}>
            <ChannelWrapper company={"meta"}/>
          </TabPanel>
          <TabPanel removePadding index={tab} value={CHANNEL_TABS.MICROSOFT}>
            <ChannelWrapper company={"microsoft"}/>
          </TabPanel>
          <TabPanel removePadding index={tab} value={CHANNEL_TABS.FEEDCAST}>
            <ChannelWrapper company={"feedcast"}/>
          </TabPanel>
        </Grid2>
      </Grid2>
    </FCard>
  );
};

const FeedcastLabel = () => {
  const enabled = useAppSelector(getCurrentFeed)?.feed_option.export_comparator;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%' }}>
      <Box sx={{
        display: "flex", alignItems: "center", justifyContent: "start", width: '100%', position: "relative",
        gap: 1, lineHeight: 1,
      }}>
        <Box sx={{ filter: "grayscale(1)" }}>
          <img width={"15px"} src={img_fc}/>
        </Box>
        <Box>
          Marketplace
        </Box>
      </Box>
      {enabled ? <CheckCircle fontSize={"small"}/> : <CloudOff fontSize={"small"}/>}
    </Box>
  )
}

interface ChannelLabelProps {
  company: AvailableCompany,
}

const channelIcons: Record<AvailableCompany, ReactNode> = {
  "google": <Google fontSize={"small"}/>,
  "meta": <Facebook fontSize={"small"}/>,
  "microsoft": <Microsoft fontSize={"small"}/>
}

const ChannelStatusIcon: Record<ChannelState, ReactNode> = {
  "active": <CheckCircle fontSize={"small"}/>,
  "disabled": <CloudOff fontSize={"small"}/>,
  "alerts": <Warning color={"warning"} fontSize={"small"}/>,
  "error": <Error color={"error"} fontSize={"small"}/>,
  "no-tracking": <Warning color={"error"} fontSize={"small"}/>,
  "configuration": <Settings fontSize={"small"}/>,
  "no-payment": <Warning color={"error"} fontSize={"small"}/>,
}

const ChannelLabel = ({ company }: ChannelLabelProps) => {
  const accounts = useAppSelector(getShopAccounts);
  const showWarning = (
    accounts.hasAlerts[company]
    || (accounts.hasChannelActive[company] && !accounts.hasTracking[company])
  );

  const { channelState } = useChannelStatus({ company });

  return (
    <Box sx={{
      display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%', position: "relative"
    }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {channelIcons[company]}
        {capitalizeFirstLetter(company)}
      </Box>
      <Box>
        {ChannelStatusIcon[channelState]}
      </Box>
    </Box>
  )
}

export default Channels;
