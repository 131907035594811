import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../../../../app/slices/modal";
import React, { useMemo } from "react";
import MCenter from "./MCenter";
import { LoadingDiv } from "../../../../../App/Blocks/Misc";
import { useAccounts } from "../../../../../../app/hooks/useAccounts";
import { Box, Dialog, DialogContent, DialogTitle, Drawer, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Google } from "@mui/icons-material";

const DrawerGoogleMerchantCenter = () => {

  const dispatch = useAppDispatch();
  const show = useAppSelector(isModalOpened(ModalTypes.MerchantCenter));
  const hide = () => dispatch(closeModal());

  const [ n, { services, loading } ] = useAccounts();

  const condition = !services.merchantCenter.data?.merchant?.address.is_phone_validated
    ? "config-merchant-phone"
    : !n.canCreateCampaigns.google
      ? "config-merchant-info"
      : "edit-merchant-info";

  const MCenterMemo = useMemo(() => {
    return <>
      {services.merchantCenter.data?.merchant && <>
        <MCenter data={services.merchantCenter.data.merchant}/>
      </>}
    </>
  }, [ services.merchantCenter ]);

  return <Box>
    {condition === "config-merchant-phone"
      ? (<Dialog open={show} maxWidth={"xs"} onClose={hide}>
        <DialogTitle>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Google/>
            <Box>
              <FormattedMessage id={condition}/>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography variant={"body2"}>
            <FormattedMessage id={`${condition}-desc`}/>
          </Typography>
          {loading
            ? <Box mt={1}><LoadingDiv/></Box>
            : MCenterMemo}
        </DialogContent>
      </Dialog>)
      : (<Drawer
          open={show}
          anchor={"right"}
          onClose={hide}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: '0',
            },
            zIndex: 1202,
          }}
        >
          <Box
            sx={{
              maxWidth: '35vw',
              p: 3,
            }}
          >
            {loading
              ? <LoadingDiv/>
              : MCenterMemo}
          </Box>
        </Drawer>
      )}
  </Box>
}


export default DrawerGoogleMerchantCenter;
