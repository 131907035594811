import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { FormattedMessage } from "react-intl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, IconButton } from "@mui/material";
import useUpdateProduct from "./hooks/useUpdateProduct";
import { LoadingButton } from "../../../App/Blocks/Misc";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../../app/slices/modal";
import { redirect } from "../../../../app/slices/app";
import Urls from "../../../../assets/js/Urls";
import { GetCurrentFeedId } from "../../../../app/utils";
import { Close } from "@mui/icons-material";
import { setInitProduct, setProductPreview } from "../../../../app/slices/product";

const DialogConfirmChanges = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const open = useAppSelector(isModalOpened(ModalTypes.ProductChanges));
  const onClose = () => dispatch(closeModal());

  const { updateProduct, api } = useUpdateProduct();

  const back = () => {
    dispatch(setInitProduct());
    dispatch(redirect(Urls.products.list(feed)));
    dispatch(setProductPreview(null));
    onClose();
  };

  const handleSave = async () => {
    await updateProduct(back);
    onClose();
  }

  return (
    <Dialog open={open} maxWidth={'xs'} fullWidth onClose={onClose}>
      <DialogTitle
        sx={{
          display:"flex",
          justifyContent:"space-between"
        }}
      >
        <FormattedMessage id={'product-pending-changes'} />
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormattedMessage id={'product-pending-changes-desc'} />
      </DialogContent>
      <DialogActions>
        <Button onClick={back}>
          <FormattedMessage id={'cancel-changes'} />
        </Button>
        <LoadingButton
          data={api}
          reset
          variant={"contained"}
          onClick={handleSave}
        >
          <FormattedMessage id={'confirm-changes'} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmChanges;
