import { Box, Link, Tooltip, Typography } from "@mui/material";
import profileApi from "../../../app/services/secured/profile";
import { GetCurrentFeedId } from "../../../app/utils";
import { useEffect, useState } from "react";
import { AllInclusive, AutoAwesome } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Link as LinkDOM } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCurrentFeedHasLoaded } from "../../../app/slices/app";
import { SkeletonLoader } from "../Blocks/Misc";
import { modalShowFeedcastAi, ModalTypes, openModal } from "../../../app/slices/modal";

interface Ai_CreditsProps {
  size?: "sm" | "lg",
}

const AI_Credits = ({ size }: Ai_CreditsProps) => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const [ getCredits, creditsQuery ] = profileApi.useLazyGetCreditsQuery();

  const hasFeedLoaded = useAppSelector(getCurrentFeedHasLoaded);

  const [ previousNbCredits, setPreviousNbCredits ] = useState<number | null>(null);
  const nbCredits = creditsQuery.data?.limit !== null ? (creditsQuery.data?.limit ?? 0) : null;
  const [ openTooltip, setOpenTooltip ] = useState(false);

  const openAiModal = () => dispatch(modalShowFeedcastAi());

  useEffect(() => {
    if (feed > 0) {
      getCredits({ feed }, true);
      setPreviousNbCredits(0);
    }
  }, [ feed ]);

  useEffect(() => {
    if (previousNbCredits !== null && previousNbCredits !== undefined && nbCredits !== null) {
      const creditsUsed = previousNbCredits - nbCredits;

      if (creditsUsed > 0) {
        setOpenTooltip(true);
        const timer = setTimeout(() => {
          setOpenTooltip(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
    if (nbCredits !== null) {
      setPreviousNbCredits(nbCredits);
    }
  }, [ nbCredits, previousNbCredits ]);

  const content = (
    <>
      <Typography>
        {nbCredits !== null ? (
          <FormattedMessage id={'credits-nb'} values={{ nb: nbCredits }}/>
        ) : (
          <FormattedMessage id={'credits'}/>
        )}
      </Typography>
      {nbCredits === null && (
        <AllInclusive/>
      )}
      {nbCredits !== null && (
        <AutoAwesome fontSize={'small'}/>
      )}
    </>
  )

  return (
    <motion.div
      whileHover={!size ? { scale: 1.1 } : {}}
      whileTap={{
        scale: 0.9,
        rotate: -10,
        borderRadius: "100%"
      }}
    >
      <Box
        onClick={openAiModal}
        sx={{
          cursor:"pointer",
          textDecoration: "none",
        }}
      >
        {feed > 0 && (
          <Tooltip
            open={openTooltip}
            title={(
              <Typography>
                <FormattedMessage id={'one-credit-used'}/>
              </Typography>
            )}
            arrow
          >
            <SkeletonLoader load={!hasFeedLoaded}>
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  background: (theme) => theme.palette.gradient.secondary,
                  color: "#fff",
                }}
              >
                {content}
              </Box>
            </SkeletonLoader>
          </Tooltip>
        )}
      </Box>
    </motion.div>
  );
};

export default AI_Credits;
