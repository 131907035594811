import React, { useState } from "react";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import ScoreChart from "./ScoreChart";
import { useAppSelector } from "../../../../app/hooks";
import Urls from "../../../../assets/js/Urls";
import { GetCurrentFeedId } from "../../../../app/utils";
import { FormattedMessage } from "react-intl";
import { ElectricBolt, Launch } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { shopHasProducts } from "../../../../app/slices/shop";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const ScoreCard = () => {
  const feed = GetCurrentFeedId();
  const hasProducts = useAppSelector(shopHasProducts);
  const [ hover, setHover ] = useState(false);
  return (
    <Box>
      <FCardHeader margin={false} title={"feedscore"} icon={<ElectricBolt/>}/>
      <FCard sx={{ position: "relative" }}>
        <motion.div onHoverStart={() => setHover(true)} onHoverEnd={() => setHover(false)}>
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: !hasProducts && hover ? 0.5 : 1 }}
          >
            <Grid2 container spacing={2} sx={!hasProducts ? { filter: 'blur(12px)' } : {}}>
              <Grid2 xs>
                <Stack spacing={1}>
                  <Typography variant={"h5"}>
                    <FormattedMessage id={"feedscore-use"}/>
                  </Typography>
                  <Divider/>
                  <Typography>
                    <FormattedMessage id={"feedscore-use-desc"}/>
                  </Typography>
                  <Box>
                    <Button
                      size={"small"}
                      component={Link}
                      to={Urls.products.optimisation(feed)}
                      endIcon={<Launch/>}
                    >
                      <FormattedMessage id={"improve-score"}/>
                    </Button>
                  </Box>
                </Stack>
              </Grid2>
              <Grid2 xs={4}>
                <ScoreChart/>
              </Grid2>
            </Grid2>
          </motion.div>
          {!hasProducts && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: hover ? 1 : 0 }}
            >
              <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 99,
                textAlign: "center",
              }}>
                <Stack spacing={1}>
                  <Typography>
                    <FormattedMessage id={'enable-feedscore'}/>
                  </Typography>
                  <Button
                    variant={"contained"}
                    component={Link}
                    to={Urls.configuration(feed)}
                  >
                    <FormattedMessage id={'import-product-catalog'}/>
                  </Button>
                </Stack>
              </Box>
            </motion.div>
          )}
        </motion.div>
      </FCard>
    </Box>
  )
}

export default ScoreCard;
