import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Box, Checkbox, FormControl, FormControlLabel, InputAdornment, TextField, Typography } from "@mui/material";
import { setProductReportingInFeed, setTextSearch } from "../../../../app/slices/reporting";
import { Search } from "@mui/icons-material";
import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormattedMessage, useIntl } from "react-intl";
import { LabelSelector } from "../../../App/Blocks/LabelSelector";

export const GlobalFilters = () => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={8} sx={{ pl: 0 }}>
        <SearchBar/>
        <Box pl={1}>
          <FromFeed/>
        </Box>
      </Grid2>
      <Grid2 xs={4}>
        <LabelSelector/>
      </Grid2>
    </Grid2>
  )
}

const SearchBar = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const value = useAppSelector(state => state.reporting.search.text);
  return (
    <FormControl fullWidth>
      <TextField
        placeholder={intl.formatMessage({ id: "title-or-ref" })}
        onChange={(e) => dispatch(setTextSearch(e.target.value))}
        value={value}
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <Search/>
          </InputAdornment>
        }}
      />
    </FormControl>
  )
}

const FromFeed = () => {
  const dispatch = useAppDispatch();
  const inFeed = useAppSelector(state => state.reporting.search.inFeed);
  const toggle = (event:any) => dispatch(setProductReportingInFeed(event.target.checked));
  return (
    <Box>
      <FormControlLabel
        control={<Checkbox checked={inFeed} onChange={toggle}/>}
        label={(
          <Typography color={"text.secondary"}>
            <FormattedMessage id={'hide-products-not-found'}/>
          </Typography>
        )}
      />
    </Box>
  )
}
