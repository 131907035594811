import { FormControl, FormLabel, InputAdornment, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { setCampaignBudget, setCampaignName } from "../../../../../app/slices/campaign/campaign";
import { Campaign, Euro } from "@mui/icons-material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCampaign } from "../../../../../app/slices/campaign/getters";

const CampaignComponents = () => {
  const campaign = useAppSelector(getCampaign);
  const dispatch = useAppDispatch();
  return ({
    name: (
      <FormControl fullWidth>
        <TextField
          // size={"small"}
          // size={"medium"}
          required
          label={<FormattedMessage id={"campaign-name"}/>}
          onChange={(e) => dispatch(setCampaignName(e.target.value))}
          value={campaign.name}
        />
      </FormControl>
    ),
    budget: (
      <FormControl>
        <TextField
          required
          size={"medium"}
          placeholder={"x"}
          label={<FormattedMessage id={"budget-quotidien"} />}
          type={'number'}
          onChange={(e) => dispatch(setCampaignBudget(parseFloat(e.target.value)))}
          value={campaign.budget}
          inputProps={{
            step:0.01,
          }}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>
              <Euro/>
            </InputAdornment>
          }}
        />
      </FormControl>
    )
  })
}

export default CampaignComponents;
