import { LogType } from "../slices/app_notifications";

export type User = {
  id: number,
  accounts: number,
  can_subscribe: boolean,
  company: string,
  email: string,
  first_name: string,
  last_name: string,
  is_merchant: boolean,
  is_guest: boolean,
  is_free?: boolean,
  token: string,
  is_demo?: boolean,
  created: number,
  pending_validation: null | boolean,
  is_linked: boolean,
};

export type OnboardingAPI = {
  first_name: string,
  last_name: string,
  merchant: {
    hear_about_us: AppKnownSource,
    merchant_type: AppUsage,
  }
}

export type ProfileType = {
  first_name: string | null,
  last_name: string | null,
  phone_number: string | null,
  authentication: {
    email: string,
  },
  merchant: {
    hear_about_us: AppKnownSource | null,
    merchant_type: AppUsage | null,
    name: string,
    default_language: "FR"|"EN",
    address: {
      address: string | null,
      address2: string | null,
      city: string | null,
      invoice_email: string | null,
      phone_number: string | null,
      siret: string | null,
      vat_number: string | null,
      vat_type: string | null,
      zip: string | null,
      country: string | null,
      vat_exempted: boolean,
    }
  }
}

export enum AppUsage {
  FOR_MYSELF,
  FOR_CUSTOMERS,
}

export enum AppKnownSource {
  UNKNOWN,
  GOOGLE,
  FB,
  LINKEDIN,
  BLOG,
  REPUTATION
}

export type NewPasswordParams = {
  current: string,
  password: string,
  password_confirm: string,
}

export type AdsAccountsData = {
  install_link: string,
  grant_required: boolean,
  accounts: Array<CompanyAccount>
}

export type CompanyAccount = { id: string, name: string }

export type Invoice = {
  amount: number,
  code: string,
  created: number,
  currency: {
    code: string
  },
  status: "paid" | "open" | "void",
  url: string,
  feed_id: number,
}

export type Log<T = any> = {
  id: number,
  date: number,
  log_type: LogType,
  data: T,
  address_ip: string,
  feed: null | {
    id: number,
  },
  merchant_user: null | Person,
  guest: null | Person,
}
export type Person = {
  authentication: {
    email: string,
  }
}

