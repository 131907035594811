import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Box, Drawer } from "@mui/material";
import { ProductComponent } from "./Edit/ProductEdit";
import React from "react";
import { getProductPreview, productHasUnsavedChanges, setProductPreview } from "../../../app/slices/product";
import { modalShowProductChanges } from "../../../app/slices/modal";

export const ProductPreviewDrawer = () => {

  const dispatch = useAppDispatch();

  const productPreviewId = useAppSelector(getProductPreview);
  const open = productPreviewId !== null;

  // Unsaved changes handle
  const unsaved = useAppSelector(productHasUnsavedChanges);

  const onClose = () => {
    if (!unsaved) {
      dispatch(setProductPreview(null))
    } else {
      dispatch(modalShowProductChanges());
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      sx={{
        zIndex: 1202,
        "& .MuiDrawer-paper": {
          borderRadius: '0',
          overflowY: "scroll",
        },
      }}
    >
      <Box
        sx={{
          width: '40vw',
          p: 3,
          pb: 13
        }}
      >
        {productPreviewId && (
          <ProductComponent
            productPreviewId={productPreviewId}
          />
        )}
      </Box>
    </Drawer>
  )
}
