import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getNewFeed,
  getNewFeedApi,
  getNewFeedPreview,
  getNewFeedSource,
  getNewFeedStep,
  NewFeedSteps,
  PrestashopCredentials,
  resetNewFeed,
  setNewFeedLocale,
  setNewFeedName,
  WoocommerceCredentials
} from "../../app/slices/newfeed";
import React, { useEffect, useState } from "react";
import { addNotification, NewSuccessNotification } from "../../app/slices/notifications";
import { getCurrentFeed, redirect, setCurrentFeedLoaded } from "../../app/slices/app";
import Urls from "../../assets/js/Urls";
import { LoadingBlock, LoadingButton, LoadingDiv } from "../App/Blocks/Misc";
import feedApi from "../../app/services/secured/feed";
import globalApi from "../../app/services/secured/global";
import Locale from "../App/Blocks/Locale";
import {
  Alert,
  AlertTitle,
  Box,
  Fade,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { NewFeedDesign } from "./FeedImportMethod";
import { Inventory2 } from "@mui/icons-material";
import { setHasSource, setPendingImport } from "../../app/slices/shop";
import { FeedSourceEnum } from "../../app/types/feed";
import securedApi from "../../app/services/secured/securedApi";

/**
 * Used to create a new feed, or to edit a feed without URL data
 */
const Preview = (p: NewFeedDesign) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const currentFeed = useAppSelector(getCurrentFeed);
  const feed = useAppSelector(getNewFeed);
  const feedPayload = useAppSelector(getNewFeedApi);
  const locales = globalApi.useLocalesQuery();

  const [localeOptions, setLocaleOptions] = useState<any[]>([]);

  const [newFeed, newFeedRes] = feedApi.useNewFeedMutation();
  const [editFeed, editFeedRes] = feedApi.useSetFeedMutation();

  const handleNewFeed = () => {
    feed.id
      ? editFeed({ ...feedPayload, id: feed.id, name: "https://" + removeHttps(feedPayload.name) })
      : newFeed({
        ...feedPayload, name: "https://" + removeHttps(feedPayload.name),
        source: feed.source === "fictif" ? null : feed.source
      });
  }

  useEffect(() => {
    if (currentFeed) {
      dispatch(setNewFeedName(currentFeed.name));
    }
  }, [currentFeed]);

  useEffect(() => {
    if (locales.data) {
      setLocaleOptions(locales.data.map(l => {
        if (l.locale === "ar_SA") return "sa_SA";
        if (l.locale === "ar_AE") return "ae_AE";
        return l.locale;
      }));
    }
  }, [locales.isSuccess]);

  /** Notification & redirection */
  useEffect(() => {
    if (newFeedRes.isSuccess && !feed.id) {
      dispatch(redirect(Urls.dash(newFeedRes.data.id)));
      dispatch(setCurrentFeedLoaded(false));
      dispatch(resetNewFeed());
      if (!feed.url && feed.source !== FeedSourceEnum.Prestashop && feed.source !== FeedSourceEnum.Woocommerce) {
        dispatch(NewSuccessNotification(intl.formatMessage({ id: "new-feed-success" })));
      } else {
        dispatch(addNotification({ type: "primary", message: "import-feed-loading", infinite: true, loader: true }))
      }
    } else if (editFeedRes.isSuccess && feed.id) {
      dispatch(setPendingImport(true));
      dispatch(setHasSource(true));
      dispatch(securedApi.util.invalidateTags(["Feed", "UserFeed", "Products", "Product"]));
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "edit-feed-success" })));
      setTimeout(() => dispatch(resetNewFeed()), 500);
    }
  }, [newFeedRes, editFeedRes]);

  const previewApi = useGetPreviewData(); // fetch preview

  const removeHttps = (s: string) => s.replace("https://", "").replace("http://", "");

  return (
    <Box>
      <PreviewCards fullWidth={p.fullWidth} />
      <Grid2 container spacing={2} mt={2}>
        <Grid2 xs={p.fullWidth ? 12 : 9}>
          <FormControl fullWidth>
            <TextField
              label={<FormattedMessage id={"new-feed-name"} />}
              value={removeHttps(feed.name)}
              onChange={(e) => dispatch(setNewFeedName(removeHttps(e.target.value)))}
              onBlur={(e) => dispatch(setNewFeedName(removeHttps(e.target.value)))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    https://
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs>
          <LoadingBlock data={locales}>
            {(localeOptions.length > 0) && (<div>
              <FormControl fullWidth>
                <TextField
                  select
                  key={locales.data?.length}
                  label={<FormattedMessage id={"new-feed-country"} />}
                  onChange={(e) => dispatch(setNewFeedLocale(e.target.value))}
                  helperText={!p.fullWidth ? <FormattedMessage id={"how-to-multiple-locales-desc"} /> :
                    <React.Fragment />}
                  defaultValue={"fr_FR"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <div className={'me-3 fs-28'}>
                          <Locale locale={feed.locale} />
                        </div>
                      </InputAdornment>
                    )
                  }}
                >
                  {localeOptions.map(l => <MenuItem value={l}><FormattedMessage id={l} /></MenuItem>)}
                </TextField>
              </FormControl>
            </div>)
            }
          </LoadingBlock>
        </Grid2>
      </Grid2>

      <Box mt={1} display={"flex"} alignItems={"center"}>
        <LoadingButton
          data={feed.id ? editFeedRes : newFeedRes}
          size={"large"}
          className={'px-3'}
          variant={'contained'}
          disabled={!feed.name.length || (feed.id && (previewApi.isLoading || previewApi.isFetching))}
          onClick={() => handleNewFeed()}
        >
          <FormattedMessage id={!feed.id ? "add-new-feed" : "edit-feed"} />
        </LoadingButton>
        {p.actions}
      </Box>
    </Box>
  )
}

const useGetPreviewData = () => {
  const newFeed = useAppSelector(getNewFeed);
  const source = useAppSelector(getNewFeedSource);
  const step = useAppSelector(getNewFeedStep);

  const [defaultPreview, dRes] = feedApi.useNewFeedPreviewMutation();
  const [prestaPreview, pRes] = feedApi.useNewPrestashopPreviewMutation();
  const [wooPreview, wRes] = feedApi.useNewWoocommercePreviewMutation();

  let previewRtn: any = dRes;

  useEffect(() => {
    if (source && step === NewFeedSteps.PREVIEW) {
      switch (source) {
        case FeedSourceEnum.Prestashop:
          previewRtn = pRes;
          prestaPreview(newFeed.credentials as PrestashopCredentials);
          break;
        case FeedSourceEnum.Woocommerce:
          previewRtn = wRes;
          wooPreview(newFeed.credentials as WoocommerceCredentials);
          break;
        default:
          newFeed.url && defaultPreview({ test_url: newFeed.url });
          break;
      }
    }
  }, [source, step]);

  return previewRtn;
}

const PreviewCards = (p: Pick<NewFeedDesign, "fullWidth">) => {
  const preview = useAppSelector(getNewFeedPreview);
  const source = useAppSelector(getNewFeedSource);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setTimeout(() => setShowPreview(true), 1000);
    }
  }, [preview, source]);

  return (
    <Box mb={2}>
      {source !== "fictif" && (
        showPreview ? (
          <Grid2 container spacing={1}>
            {source === FeedSourceEnum.Woocommerce && (
              <Grid2 xs={12}>
                <Typography>
                  <FormattedMessage id={'woocommerce-limited-preview'} />
                </Typography>
                <Typography variant={"body2"}>
                  <FormattedMessage id={'woocommerce-limited-preview-desc'} />
                </Typography>
              </Grid2>
            )}
            <Fade in={showPreview} timeout={1000} style={{ transitionDelay: `100ms` }}>
              <Grid2 xs={12} mb={1}>
                <Stack direction={"row"} spacing={0.5} alignItems={"center"} justifyContent={"center"}>
                  <Inventory2 />
                  <Typography variant={"h5"}>
                    <FormattedNumber value={preview?.count ?? 0} />
                  </Typography>
                  <Typography>
                    <FormattedMessage id={"found-products"} />
                  </Typography>
                </Stack>
              </Grid2>
            </Fade>
            <Fade in={showPreview} timeout={1000} style={{ transitionDelay: `400ms` }}>
              <Grid2 xs={p.fullWidth ? 6 : 3}>
                <Alert severity={"info"}>
                  <AlertTitle>{preview?.missing_gtin}</AlertTitle>
                  <Typography variant={"caption"}>
                    <FormattedMessage id={"missing-gtin"} />
                  </Typography>

                </Alert>
              </Grid2>
            </Fade>
            <Fade in={showPreview} timeout={1000} style={{ transitionDelay: `700ms` }}>
              <Grid2 xs={p.fullWidth ? 6 : 3}>
                <Alert severity={"warning"}>
                  <AlertTitle>{preview?.missing_cat}</AlertTitle>
                  <Typography variant={"caption"}>
                    <FormattedMessage id={"missing-categories"} />
                  </Typography>
                </Alert>
              </Grid2>
            </Fade>
            <Fade in={showPreview} timeout={1000} style={{ transitionDelay: `1000ms` }}>
              <Grid2 xs={p.fullWidth ? 6 : 3}>
                <Alert severity={"error"}>
                  <AlertTitle>{preview?.missing_images}</AlertTitle>
                  <Typography variant={"caption"}>
                    <FormattedMessage id={"missing-images"} />
                  </Typography>

                </Alert>
              </Grid2>
            </Fade>
            <Fade in={showPreview} timeout={1000} style={{ transitionDelay: `1300ms` }}>
              <Grid2 xs={p.fullWidth ? 6 : 3}>
                <Alert severity={"error"}>
                  <AlertTitle>{preview?.missing_ids}</AlertTitle>
                  <FormattedMessage id={"missing-ids"} />
                </Alert>
              </Grid2>
            </Fade>
          </Grid2>
        )
          : <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <LoadingDiv />
          </Box>
      )}
    </Box>
  )
}

export default Preview;
