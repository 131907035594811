import { DataCard } from "../../../App/Blocks/Card";
import { FormattedMessage } from "react-intl";
import { Block, CheckCircle, CloudOff, CloudSync, PendingActions, WarningAmberRounded } from "@mui/icons-material";
import React, { ReactNode } from "react";
import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AvailableCompany } from "../../../../app/types/campaign";
import { useAppDispatch } from "../../../../app/hooks";
import { modalShowSyncFeed } from "../../../../app/slices/modal";
import { CurrentCatalogState, ProductReportingChannel } from "../../../../app/types/products";
import { CatalogStatusProps, ChannelsCatalogProps } from "../CatalogStatus";

interface StatusMetricsProps extends ChannelsCatalogProps {
  company: AvailableCompany,
  channelReporting: ProductReportingChannel | undefined,
  title?: string,
}

type CatalogMetrics = keyof Omit<CurrentCatalogState, "total" | "last_import">;

export const StatusMetrics = ({ company, channelReporting, page, title }: StatusMetricsProps) => {

  const currentData = channelReporting?.current;

  const renderDataCard = (icon: any, variant: any, labelId: any, value: any) => (
    <DataCard
      icon={icon}
      variant={variant}
      label={<FormattedMessage id={labelId}/>}
      value={value ?? <CloudOff/>}
      noCard={true}
      align={"left"}
      size={page === "full" ? undefined : "sm"}
    />
  );

  const statusIcons: Record<CatalogMetrics, ReactNode> = {
    pendings: <PendingActions fontSize={page === "full" ? "medium" : "small"} color={"info"}/>,
    errors: <Block fontSize={page === "full" ? "medium" : "small"} color={"error"}/>,
    warnings: <WarningAmberRounded fontSize={page === "full" ? "medium" : "small"} color={"warning"}/>,
    success: <CheckCircle fontSize={page === "full" ? "medium" : "small"} color={"success"}/>,
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack spacing={2} sx={{ filter: !currentData ? "blur(8px)" : undefined, }}>
        {title && (
          <Box>
            <Typography
              variant={"overline"} color={"text.secondary"}
              sx={{ fontSize: page === "full" ? "1rem" : undefined, lineHeight: 1 }}>
              {title}
            </Typography>
            <Divider sx={{ mt: 0.5 }}/>
          </Box>
        )}
        <Box>
          <Grid2
            container
            spacing={3}
            key={company}
            sx={{
              "& * ":
                !currentData
                  ? {
                    color: "text.disabled",
                    filter: "grayscale(1.0)"
                  } : {}
            }}
          >
            {Object.keys(statusIcons).map((status) => (
              <Grid2 xs={6} key={status}>
                {renderDataCard(
                  statusIcons[status as CatalogMetrics],
                  status,
                  status,
                  currentData ? currentData[status as CatalogMetrics] : 0,
                )}
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Stack>
    </Box>
  )
}


type SyncImportFeedProps = {
  company: AvailableCompany,
}

const SyncImportFeed = ({ company }: SyncImportFeedProps) => {
  const dispatch = useAppDispatch();
  const action = () => dispatch(modalShowSyncFeed({ company }));
  return (
    <Box>
      <Tooltip
        arrow
        title={company !== "google" ? <FormattedMessage id={'feature-in-dev'}/> : ""}
      >
        <Box>
          <Button
            fullWidth
            variant={"contained"}
            color={"secondary"}
            startIcon={<CloudSync/>}
            disabled={company !== "google"}
            onClick={action}
          >
            <FormattedMessage id={'sync-feed'}/>
          </Button>
        </Box>
      </Tooltip>
    </Box>
  )
}
