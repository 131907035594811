import { ImageFormat } from "../../../../../../app/utils";

const dimensions: Record<ImageFormat, string> = {
  unknown: "",
  square: "(1:1) 1 200 x 1 200 recommandé ; 300 x 300 minimum ; 5 120 Ko maximum",
  portrait: "(4:5) 960 x 1 200 recommandé ; 480 x 600 min",
  landscape: "(1,91:1) 1 200 x 628 recommandé ; 600 x 314 min ; 5 120 Ko maximum",
}

export enum AspectRatio {
  Landscape = 1.91,
  Portrait = 4 / 5,
  Square = 1,
}

export default dimensions;
