import { FormattedMessage } from "react-intl";
import DialogContent from "@mui/material/DialogContent";
import {
  Alert,
  Box,
  Button,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack, TextField,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useCallback, useState } from "react";
import { CancelData, CancelReason } from "../../../../../app/types/feed";
import { useAppSelector } from "../../../../../app/hooks";
import { getShop } from "../../../../../app/slices/shop";
import { ErrorOutline } from "@mui/icons-material";

interface DialogCancelProps {
  open: boolean,
  setCancelFeedback: (f: CancelData) => void,
  submit: () => void,
  handleClose: () => void,
}

enum CANCEL_STEPS {
  CAUTION,
  CANCEL
}

const DialogCancel = (p: DialogCancelProps) => {
  const [ type, setType ] = useState<CancelReason>();
  const [ description, setDescription ] = useState<string>("");
  const { open, submit, handleClose, setCancelFeedback } = p;
  const shop = useAppSelector(getShop);

  const confirmCancel = () => {
    setCancelFeedback({ type, description });
    submit();
  }

  const [ step, setStep ] = useState<CANCEL_STEPS>(CANCEL_STEPS.CAUTION);

  const onClick = useCallback(() => step === CANCEL_STEPS.CAUTION ? setStep(CANCEL_STEPS.CANCEL) : confirmCancel(), [ step ]);

  const closeDialog = () => {
    setType(undefined);
    handleClose();
    setTimeout(() => setStep(CANCEL_STEPS.CAUTION), 500);
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <ErrorOutline/>
          <Box>
            <FormattedMessage id={"about-to-cancel"} />
          </Box>
        </Stack>
      </DialogTitle>

      {step === CANCEL_STEPS.CAUTION && (
        <DialogContent>
          <Alert severity={"error"}>
            <Stack spacing={2}>
              <Typography>
                <FormattedMessage id={"cancel-sub-1"}/>
              </Typography>
              <Typography>
                <FormattedMessage id={"cancel-sub-2"}/>
              </Typography>
              <Typography>
                <b><FormattedMessage id={"cancel-sub-will-do"} /></b>
                <ul>
                  <li><FormattedMessage id={"logout-feedcast-feed"} /></li>
                  <li><FormattedMessage id={"campaigns-deleted"} /></li>
                  <li><FormattedMessage id={"campaigns-historic-deleted"} /></li>
                  <li><FormattedMessage id={"css-advantages-loss"} /></li>
                </ul>
              </Typography>
            </Stack>
          </Alert>

          <BtnActions onClick={() => setStep(CANCEL_STEPS.CANCEL)}/>
        </DialogContent>
      )}

      {step === CANCEL_STEPS.CANCEL && (
        <DialogContent>
          <RadioGroup
            value={type}
            onChange={(e) => setType(parseInt((e.target as HTMLInputElement).value) as CancelReason)}
          >
            {Object.keys(CancelReason).reverse().map(c =>
                CancelReason[parseInt(c) as CancelReason] && <FormControlLabel
                  control={<Radio/>}
                  label={<FormattedMessage id={"CANCEL-" + CancelReason[parseInt(c) as CancelReason]}/>}
                  value={c}
                />
            )}
          </RadioGroup>

          <FormControl sx={{ mt: 1 }} fullWidth>
            <TextField
              multiline
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              minRows={"5"}
              label={<FormattedMessage id={"please-explain"}/>}
            />
          </FormControl>

          <BtnActions onClick={onClick} disabled={type === undefined}/>
        </DialogContent>
      )}
    </Dialog>
  )
}

const BtnActions = ({ onClick, disabled }: { onClick: () => void, disabled?: boolean }) => {
  return (
    <Stack direction={"row"} mt={2} spacing={1} justifyContent={"end"}>
      <Button
        variant={"contained"}
        href={process.env.REACT_APP_BOOK_CALL ?? ""}
        target={'_blank'}
      >
        <FormattedMessage id={"talk-to-expert"}/>
      </Button>
      <Button
        variant={"contained"}
        color={"error"}
        onClick={onClick}
        disabled={disabled}
      >
        <FormattedMessage id={"confirm-cancel"}/>
      </Button>
    </Stack>
  )
}

export default DialogCancel;
