import React, { useState } from 'react';
import { Box, Button, FormControl, IconButton, MenuItem, Popover, Stack, TextField, Typography } from '@mui/material';
import { gatherBrowserData } from '../../app/utils';
import SimpleForm from './Blocks/SimpleForm';
import { useAppSelector } from "../../app/hooks";
import { getUser } from "../../app/slices/app";
import { FormattedMessage, useIntl } from 'react-intl';
import { Forum } from "@mui/icons-material";
import useAnchorEl from "../../app/hooks/useAnchorEl";

interface NotionFormProps {
  open: boolean,
}

const NotionForm = (p: NotionFormProps) => {

  const user = useAppSelector(getUser);
  const intl = useIntl();
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const browserData = gatherBrowserData();

  const { anchorEl, open: openPopover, handleClose, handleClick } = useAnchorEl();

  const submit = async (formData: any) => {
    setLoading(true);
    // Combine form data with browser data
    const payload = {
      ...formData,
      browserInfo: JSON.stringify(browserData),
      timestamp: Date.now(),
      url: browserData.currentUrl,
    };

    const result = await submitFeedback(payload);
    if (result.success) {
      setIsSubmitted(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <Box>
      {p.open
        ? (
          <Button
            size={"small"}
            sx={{ color: "text.secondary" }}
            fullWidth
            startIcon={<Forum/>}
            onClick={handleClick}
          >
            {p.open && "Feedback"}
          </Button>
        ) : (
          <IconButton edge={"start"} onClick={handleClick}>
            <Forum/>
          </IconButton>
        )}

      <Popover
        onClose={handleClose}
        anchorEl={anchorEl}
        open={openPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          p={2}
          sx={{
            maxWidth: '250px',
          }}
        >
          {isSubmitted ? (
            <Typography>
              <FormattedMessage id={'thank-you-feedback'}/>
            </Typography>
          ) : (
            <SimpleForm onSubmit={submit}>
              <Typography variant={"body2"} textAlign={"center"} mb={2}>
                <FormattedMessage id={'feedback-desc'}/>
              </Typography>
              <input type={"hidden"} name={"user"} value={user?.email}/>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <TextField
                    select
                    size={"small"}
                    label={<FormattedMessage id={'category'}/>}
                    name="category"
                    defaultValue=""
                    required
                  >
                    <MenuItem value="Bug">Bug</MenuItem>
                    <MenuItem value="Feature">Feature</MenuItem>
                  </TextField>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    size={"small"}
                    name="feedback"
                    label="Feedback"
                    multiline
                    rows={4}
                    placeholder={intl.formatMessage({ id: "f-feedback-desc" })}
                    required
                  />
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button disabled={loading} type="submit">
                    <FormattedMessage id={'submit'}/>
                  </Button>
                </Box>
              </Stack>
            </SimpleForm>
          )}
        </Box>
      </Popover>
    </Box>
  )
};

async function submitFeedback(data: any): Promise<{ success: boolean; id?: string; }> {
  try {
    const response = await fetch('https://feedcast-feedback.glitch.me/submit-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return { success: result.success, id: result.id };
  } catch (error) {
    console.error('Network error:', error);
    return { success: false };
  }
}

export default NotionForm;
