import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { capitalizeFirstLetter, GetCurrentFeedId } from "../../../app/utils";
import { useAccounts } from "../../../app/hooks/useAccounts";
import { getCurrentFeedHasLoaded, redirect } from "../../../app/slices/app";
import { AvailableCompany } from "../../../app/types/campaign";
import { Box, Button, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Urls from "../../../assets/js/Urls";
import { FormattedMessage } from "react-intl";
import { CreditCardOff, Notifications, Troubleshoot, WarningAmberRounded } from "@mui/icons-material";
import React, { useMemo } from "react";
import useChannelErrors from "../../../app/hooks/useChannelErrors";
import { getAppNotifications } from "../../../app/slices/app_notifications";

export const AlertBanner = ({ lockNav }: { lockNav: boolean }) => {

  const feed = GetCurrentFeedId();
  const hasFeedLoaded = useAppSelector(getCurrentFeedHasLoaded);
  const notifErrors = useAppSelector(getAppNotifications).filter(n => n.severity === "error").length > 0;

  const open = useMemo(() =>
      !lockNav && feed > 0 && hasFeedLoaded && notifErrors,
    [ feed, hasFeedLoaded, notifErrors, lockNav ]
  );

  return (
    <Collapse in={open} unmountOnExit>
      <Box sx={{
        width: '100%',
        background: "rgba(239,83,80,0.5)",
        left: 0,
        p: 1,
        px: 2,
      }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <WarningAmberRounded/>
            <Typography variant={"body2"}>
              <FormattedMessage id={'important-notifications'}/>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Collapse>
  )
}
