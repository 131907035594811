import { useAppSelector } from "../../../../../../../app/hooks";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FMsg } from "../../../../../../App/Blocks/Misc";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Alert, AlertTitle, Box, Button, Stack, Typography } from "@mui/material";
import { getPerfMaxData } from "../../../../../../../app/slices/campaign/getters";
import CampaignCard from "../../../CampaignCard";
import { FindInPageOutlined, TextSnippet } from "@mui/icons-material";
import { Inputs, InputsHolder } from "./InputsHolder";
import { getLanguage } from "../../../../../../../app/slices/app";
import { helpdeskLinks } from "../../../../../../App/Helpdesk/helpdesk";

const TextsAssets = () => {
  return (
    <CampaignCard icon={<TextSnippet/>} title={"perf-max-texts"}>
      <Stack spacing={1}>
        <TextsInfo/>
        <TextsWarnings/>
        <Grid2 container spacing={2}>
          <Grid2 xs>
            <Box>
              <Typography variant={"subtitle1"} mb={1}>
                {FMsg("short-titles")}
              </Typography>
              <Box>
                {Inputs(
                  InputsHolder({
                    nb: { min: 1, max: 1 },
                    type: "headlines",
                    placeholder: "headline",
                    length: { min: 15, max: 30 }
                  })
                )}
              </Box>
              <Box>
                {Inputs(
                  InputsHolder({
                    nb: { min: 3, max: 5, start: 1 },
                    type: "headlines",
                    placeholder: "headline",
                    length: { max: 30 }
                  })
                )}
              </Box>
            </Box>

          </Grid2>
          <Grid2 xs={8}>
            <Box>
              <Typography variant={"subtitle1"} mb={1}>
                {FMsg("long-titles")}
              </Typography>
              <Box>
                {Inputs(
                  InputsHolder({
                    nb: { min: 1, max: 5 },
                    type: "long_headlines",
                    placeholder: "long-headline",
                    length: { min: 15, max: 90 }
                  })
                )}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Box>
          <Box>
            <Typography variant={"subtitle1"} mb={1}>
              {FMsg("descriptions")}
            </Typography>
            <Box>
              {Inputs(
                InputsHolder({
                  nb: { min: 1, max: 1 },
                  type: "descriptions",
                  placeholder: "description-short",
                  length: { max: 60 }
                })
              )}

              {Inputs(
                InputsHolder({
                  nb: { min: 2, max: 4, start: 1 },
                  type: "descriptions",
                  placeholder: "description-long",
                  length: { max: 90 }
                })
              )}
            </Box>
          </Box>
        </Box>
      </Stack>
    </CampaignCard>
  )
}

const TextsWarnings = () => {
  const data = useAppSelector(getPerfMaxData);
  const textWarnings = (data.warnings ?? []).filter(w => !w.value.startsWith("http"));
  const hasAtLeastOneBlocking = textWarnings.filter(t => t.blocking).length > 0;
  return (
    textWarnings.length ? (
      <Box>
        <Alert severity={hasAtLeastOneBlocking ? "error" : "warning"}>
          <AlertTitle>
            <FormattedMessage id={'assets-texts-warnings'}/>
          </AlertTitle>
          <FormattedMessage id={'assets-texts-warnings-desc'}/>
        </Alert>
      </Box>
    ) : null
  )
}

const TextsInfo = () => {
  const lang = useAppSelector(getLanguage);
  return (
    <Alert severity={"info"}>
      <AlertTitle>
        <FormattedMessage id={"perf-max-texts-best-practices"}/>
      </AlertTitle>
      <Stack spacing={1}>
        <Box>
          <FormattedMessage id={"perf-max-texts-best-practices-desc"}/>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            component={'a'}
            href={helpdeskLinks["perf-max-best-practices"][lang]}
            target={'_blank'}
            startIcon={<FindInPageOutlined/>}
            size={"small"}
          >
            <FormattedMessage id={'more'} />
          </Button>
        </Box>
      </Stack>
    </Alert>
  )
}

export default TextsAssets;
