import { ListItem, ListItemIcon, ListItemText, MenuItem, Radio, styled } from "@mui/material";
import React, { memo } from "react";
import Locale from "../../Blocks/Locale";
import { FormattedMessage } from "react-intl";

interface LanguageMenuItemProps {
  language: string;
  setLang: (lang: 'fr' | 'en') => void;
  locale: 'fr' | 'en';
  messageId: string;
}

export const LanguageMenuItem = memo(({ language, setLang, locale, messageId }: LanguageMenuItemProps) => (
  <MenuItem
    onClick={() => setLang(locale)}
    component={ListItem}
    secondaryAction={language === locale && <SmallRadio checked={true} color={"secondary"}/>}
  >
    <ListItemIcon>
      <Locale locale={locale}/>
    </ListItemIcon>
    <ListItemText
      primary={<FormattedMessage id={messageId}/>}
    />
  </MenuItem>
));

const SmallRadio = styled(Radio)(({ theme }) => ({
  padding: "1px",
  "& .MuiSvgIcon-root": {
    height: 15,
    width: 15,
  }
}));
