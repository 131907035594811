import { AdsProvider, SetCampaignData, SetCampaignParams } from "../../../../../app/types/campaign";
import { useAppDispatch } from "../../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../../app/utils";
import campaignApi from "../../../../../app/services/secured/campaign";
import React, { useEffect, useMemo, useState } from "react";
import { addNotification, NewSuccessNotification } from "../../../../../app/slices/notifications";
import { useHandleBudget } from "./useHandleBudget";
import { CampaignRowType } from "../CampaignList";
import { DialogConfirmEnable } from "../Dialogs/DialogConfirmEnable";

export type ConfirmEnableDialogProps = {
  show: boolean,
  id: number | null,
  active?: boolean,
  provider: AdsProvider | null,
}

interface useCampaignMutationProps {
  list: CampaignRowType[],
  setList: any,
  setLoading: any,
}

export const useCampaignMutation = (p: useCampaignMutationProps) => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const { list, setList, setLoading } = p;

  const [ setCampaignActive, setCampaignActiveRes ] = campaignApi.useSetCampaignActiveMutation();
  const [ setCampaignBudget, setCampaignBudgetRes ] = campaignApi.useSetCampaignBudgetMutation();

  // State for enabling / disabling campaign
  const [ confirmEnable, setConfirmEnable ] = useState<ConfirmEnableDialogProps>({
    id: null, show: false, provider: null
  });

  // enable dialog logic
  const setCampaignApi = (data: SetCampaignData) => {
    if (confirmEnable.id && confirmEnable.provider) {
      const updateObj = {
        feed, provider: confirmEnable.provider, campaign: confirmEnable.id, data
      };
      setCampaignActive(updateObj)
    }
  }

  const onEnableChange = () => {
    setCampaignApi({ enabled: !confirmEnable.active });
  }

  const closeConfirmEnable = () => setConfirmEnable({ id: null, active: false, show: false, provider: null });

  useEffect(() => {
    if (setCampaignActiveRes.isSuccess) {
      const updatedCampaign = setCampaignActiveRes.data;
      const index = list.findIndex(campaign => campaign.id === updatedCampaign.id);
      if (index >= 0) {
        const newList = [ ...list ];
        newList[index].enabled = updatedCampaign.enabled;
        setList(newList);
        dispatch(NewSuccessNotification("success-edit-campaign"));
      }
      closeConfirmEnable();
    }
  }, [ setCampaignActiveRes ]);

  // budget edit logic
  const [ processRowUpdate, DConfirmBudget ] = useHandleBudget({
    setLoading,
    mutation: (data: SetCampaignParams) => setCampaignBudget(data),
  });

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    dispatch(addNotification({ type: "error", message: error.message }));
  }, []);

  const DConfirmEnable = useMemo(() => {
    return <DialogConfirmEnable
      open={confirmEnable}
      loading={setCampaignActiveRes}
      onValid={onEnableChange}
      onClose={closeConfirmEnable}
    />
  }, [ confirmEnable, setCampaignActiveRes, onEnableChange, closeConfirmEnable ]);

  return {
    processRowUpdate,
    handleProcessRowUpdateError,
    DConfirmBudget,
    DConfirmEnable,
    setConfirmEnable,
  }
}
