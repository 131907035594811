import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import campaignApi from "../services/secured/campaign";
import { ReportingRow } from "../types/feed";
import { CampaignTypeIdentifier } from "../types/campaign";

const initialState: CampaignsSlice = {
  filters: {
    "perf-max": true,
    shopping: true,
    "meta-classic": true,
    "meta-advantage": true,
    "microsoft-shopping": true,
    // warmup: true,
    all: false,
    is_feedcast: true,
  },
  loading: false,
  activeCampaigns: 0,
}

const campaigns = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    toggleCampaignsFilters: (state, action: PayloadAction<{ toggle: "all" | "is_feedcast" }>) => {
      if (action.payload.toggle === "all") {
        state.filters.all = true;
        state.filters.is_feedcast = false;
      } else {
        state.filters.all = false;
        state.filters.is_feedcast = true;
      }
    },
    setCampaignsFilters: (state, action: PayloadAction<CampaignFilters>) => {
      state.filters = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addMatcher(campaignApi.endpoints.getCampaigns.matchPending, (state) => {
      state.loading = true;
    })
    .addMatcher(campaignApi.endpoints.getCampaigns.matchFulfilled, (state) => {
      state.loading = false;
    })
  }
})

export const campaignsReducer = campaigns.reducer;
export const { setCampaignsFilters, toggleCampaignsFilters } = campaigns.actions;

/** Getters */
export const getCampaignsFilters = (state: RootState) => state.campaigns.filters;

/** Types */
export type CampaignFilters = {
  all: boolean,
  is_feedcast: boolean,
} & Record<CampaignTypeIdentifier, boolean>

type CampaignsSlice = {
  filters: CampaignFilters,
  loading: boolean,
  activeCampaigns: number,
}

export type ReportKeys = "impressions"
  | "clicks"
  | "cpc"
  | "clickRate"
  | "spent"
  | "conversions"
  | "conversionValue"
  | "conversionCost"
  | "roi";

export type ReportKeysBudget = "budget" | ReportKeys;

export type ReportHeaderKeys = Record<ReportKeysBudget, { direction: boolean, show: boolean, icon: string }>;

export type EnhancedReporting = {
  cpc: number,
  clickRate: number,
  conversionCost: number,
  roi: number,
} & Pick<ReportingRow, 'spent' | 'clicks' | 'impressions' | 'conversions' | 'conversionValue'>;

/** Functions */
export const enhanceReporting = (c: Pick<ReportingRow, 'spent' | 'clicks' | 'impressions' | 'conversions' | 'conversionValue'>): EnhancedReporting => {
  return {
    ...c,
    cpc: parseFloat(((c?.spent || 0) / (c?.clicks || 1)).toPrecision(2)),
    clickRate: parseFloat(((c?.clicks || 0) / (c?.impressions || 1)).toPrecision(2)),
    conversionCost: parseFloat(((c?.spent || 0) / (c?.conversions || 1)).toPrecision(2)),
    roi: parseFloat(((c?.conversionValue || 0) / (c?.spent || 1)).toPrecision(2))
  }
}
