import AppPublic from "../AppPublic";
import { FormattedMessage } from "react-intl";
import Urls from "../../../../assets/js/Urls";
import SimpleForm, { SimpleFormData } from "../../Blocks/SimpleForm";
import { LoadingButton } from "../../Blocks/Misc";
import { useEffect, useState } from "react";
import authApi, { SignupPayload } from "../../../../app/services/public/auth";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppMode, redirect } from "../../../../app/slices/app";
import { Box, FormControl, InputAdornment, TextField, Typography } from "@mui/material";
import { AccountCircle, ExitToApp, Handshake, LockOpen, StorefrontOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import Grid2 from "@mui/material/Unstable_Grid2";
import { pushCrispEvent, pushGAEvent } from "../../../../app/events";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type RegisterProps = {
  code?: string,
}

const Register = () => {
  const { code } = usePartnerRegister();
  return (
    <AppPublic page={"register"}>
      <Box>
        <Typography mb={3} variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"register-desc-2"} />
        </Typography>
        <RegisterForm code={code} />
      </Box>
    </AppPublic>
  )
}

export const RegisterReseller = () => {
  return <Register />
}

const usePartnerRegister = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["fc-partner", "fc-partner-code"]);
  const [getResellerCookie, resellerCookieRes] = authApi.useResellerCookieMutation();
  const [incrementCookie, incrementCookieRes] = authApi.useIncrementCookieMutation();

  const codeFromPartnerUrl: string = useParams().code || "";
  const partnerHash = cookies["fc-partner"];
  const partnerCode = cookies["fc-partner-code"];
  let once = false;

  const expires = 1000 * 60 * 60 * 24 * 60;
  const cookiesOptions = {
    path: "/",
    maxAge: expires,
    expires: new Date(new Date().valueOf() + expires),
  };

  // Register from partner
  useEffect(() => {
    if (!once) {
      if (codeFromPartnerUrl.length && !partnerHash) {
        getResellerCookie(codeFromPartnerUrl);
      } else if (partnerHash) {
        incrementCookie(partnerHash);
      }
      once = true;
    }
  }, [codeFromPartnerUrl, partnerCode, partnerHash]);

  // If first time from partner's link, setup Cookies
  useEffect(() => {
    if (resellerCookieRes.isSuccess && codeFromPartnerUrl.length) {
      const hash = resellerCookieRes.data.cookie;
      setCookie("fc-partner-code", codeFromPartnerUrl, cookiesOptions); //Cookie partner code
      setCookie("fc-partner", hash, cookiesOptions); // Cookie hash
    }
  }, [resellerCookieRes.isSuccess, codeFromPartnerUrl]);

  // for added stability
  useEffect(() => {
    if (codeFromPartnerUrl && !partnerCode) {
      setCookie("fc-partner-code", codeFromPartnerUrl, cookiesOptions); //Cookie partner code
    }
  }, [partnerCode, codeFromPartnerUrl]);

  return {
    code: codeFromPartnerUrl.length ? codeFromPartnerUrl : partnerCode, // either from URL, from cookies, or undefined (classic register)
  }
}

type PasswordRequirements = {
  length: boolean,
  lowercase: boolean,
  uppercase: boolean,
  numbers: boolean,
}

const RegisterForm = (p: RegisterProps) => {

  const dispatch = useAppDispatch();
  const appMode = useAppSelector(getAppMode);
  const emailPrefill: string = new URLSearchParams(window.location.search).get("email") as string;
  const [signup, signupRes] = authApi.useSignupMutation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [user, setUser] = useState({ company: "", email: "" });
  useEffect(() => {
    if (signupRes.isSuccess) {
      localStorage.setItem("validate", "1");
      if (user.company && user.email) {
        pushCrispEvent("user:auth:pre-signup", {
          company: user.company,
          email: user.email,
        }, "pink");

        pushGAEvent("pre_signup");
      }
      dispatch(redirect(Urls.auth.validate));
    }
  }, [signupRes.isSuccess]);

  const submit = (s: SimpleFormData) => {
    const d = s as SignupPayload;
    setUser({ company: d.name, email: d.email });
    if (executeRecaptcha) {
      const token = executeRecaptcha();
      //signup(d);
    }

  }

  return <Box id={"form-signup"}>
    <SimpleForm
      onSubmit={submit}
    >
      <FormControl fullWidth>
        <TextField
          name={"name"}
          size={"small"}
          required
          label={<FormattedMessage id={"your-name"} />}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <StorefrontOutlined />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ my: 2 }}>
        <TextField
          name={"registration_code"}
          size={"small"}
          label={<FormattedMessage id={"affiliate-code"} />}
          defaultValue={p.code}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <Handshake />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <TextField
          name={'email'}
          label={"Email"}
          size={"small"}
          defaultValue={emailPrefill}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <Passwords large />

      <LoadingButton
        data={signupRes}
        type={"submit"}
        size={'large'}
        endIcon={<ExitToApp />}
        variant={"contained"}
        color={appMode === "dark" ? "secondary" : undefined}
        sx={{
          width: '100%',
          my: 2,
        }}
      >
        <FormattedMessage id={"continue"} />
      </LoadingButton>

      <Box>
        <Typography variant={"caption"} color={"text.secondary"}>
          <FormattedMessage
            id={'register-conditions'}
            values={{
              a: (txt) => <a href={`https://app.feedcast.io/files/feedcast-cga.pdf`} target={'_blank'}>{txt}</a>
            }}
          />
        </Typography>
      </Box>
    </SimpleForm>
  </Box>
}

interface PasswordsProps {
  large?: boolean,
}

export const Passwords = ({ large }: PasswordsProps) => {
  const [password, setPassword] = useState("");
  const [passVerification, setPassverification] = useState<PasswordRequirements>();
  const [passwordConfirm, setPasswordConfirm] = useState<boolean>(false);
  const [showVerif, setShowVerif] = useState(false);

  useEffect(() => {
    setPassverification({
      length: password.length > 5,
      lowercase: password.toUpperCase() != password,
      uppercase: password.toLowerCase() != password,
      numbers: /\d/.test(password)
    });
    setPasswordConfirm(false);
  }, [password]);

  const handlePasswordConfirm = (passwordConfirm: string) => {
    if (password.length <= passwordConfirm.length) {
      setPasswordConfirm(password !== passwordConfirm);
    }
  }
  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={!large ? 6 : 12}>
          <FormControl fullWidth>
            <TextField
              name={"password"}
              size={"small"}
              type={"password"}
              required
              onFocus={() => setShowVerif(true)}
              onBlur={() => setShowVerif(false)}
              label={<FormattedMessage id={"password"} />}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <LockOpen />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={!large ? 6 : 12}>
          <FormControl fullWidth>
            <TextField
              name={"password_confirm"}
              type={"password"}
              size={"small"}
              required
              onFocus={() => setShowVerif(true)}
              onBlur={() => setShowVerif(false)}
              // margin={"dense"}
              error={passwordConfirm}
              label={<FormattedMessage id={"confirmation"} />}
              onChange={(e) => handlePasswordConfirm(e.target.value)}
              helperText={passwordConfirm ? <FormattedMessage id={"invalid-password-confirm"} /> : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <LockOpen />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid2>

        {/*<Collapse in={showVerif}>*/}
        {/*  <Box>*/}
        {/*    <Typography><FormattedMessage id={"password-requirements"}/></Typography>*/}
        {/*    <Box mt={1} color={"text.secondary"}>*/}

        {/*      <FormControlLabel*/}
        {/*        control={<Checkbox checked={passVerification?.length}/>}*/}
        {/*        label={<FormattedMessage id={"password-minimum-length"}/>}*/}
        {/*      />*/}

        {/*      <FormControlLabel*/}
        {/*        control={<Checkbox checked={passVerification?.lowercase}/>}*/}
        {/*        label={<FormattedMessage id={"password-lowercase"}/>}*/}
        {/*      />*/}

        {/*      <FormControlLabel*/}
        {/*        control={<Checkbox checked={passVerification?.uppercase}/>}*/}
        {/*        label={<FormattedMessage id={"password-uppercase"}/>}*/}
        {/*      />*/}

        {/*      <FormControlLabel*/}
        {/*        control={<Checkbox checked={passVerification?.numbers}/>}*/}
        {/*        label={<FormattedMessage id={"password-numbers"}/>}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Collapse>*/}
      </Grid2>
    </Box>)
}

export default Register;
