import useValueWithUnitManager from "../hooks/useValueWithUnitManager";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PAvailability, PCondition } from "../../../../../app/types/products";
import React from "react";
import { ProductAttributesGroupProps } from "./ProductAttributes";
import { setCustomData } from "../../../../../app/slices/product";
import { useAppDispatch } from "../../../../../app/hooks";

export const Information = ({
  productData, viewPage, disableInputs, customData
}: ProductAttributesGroupProps) => {

  const dispatch = useAppDispatch();

  const updateSalePrice = (price: string) => {
    dispatch(
      setCustomData({
        ...customData,
        sale_price: price
      })
    );
  };

  const {
    valueRef: salePriceRef,
    currentUnit: saleCurrency,
    setValueWithUnit: setSalePrice,
    defaultValue: defaultSalePrice
  } = useValueWithUnitManager(productData?.sale_price, "EUR", updateSalePrice);

  return (
    <>
      <Grid2 xs={12}>
        <Typography>
          <FormattedMessage id={'product-information'}/>
        </Typography>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            size={'small'}
            label={<FormattedMessage id={"availability"}/>}
            select
            disabled={disableInputs}
            defaultValue={productData?.availability_k}
            onChange={(e) => dispatch(setCustomData({
              ...customData,
              availability_k: e.target.value as PAvailability
            }))}
          >
            <MenuItem value={"in_stock"}><FormattedMessage id={"in_stock"}/></MenuItem>
            <MenuItem value={"out_of_stock"}><FormattedMessage id={"out_of_stock"}/></MenuItem>
            <MenuItem value={"backorder"}><FormattedMessage id={"backorder"}/></MenuItem>
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl
          fullWidth>
          <TextField
            select
            size={'small'}
            label={"Condition"}
            disabled={disableInputs}
            defaultValue={productData?.condition_k}
            key={productData?.condition_k}
            onChange={(e) => dispatch(setCustomData({
              ...customData,
              condition_k: e.target.value as PCondition
            }))}
          >
            <MenuItem value={"new"}><FormattedMessage id={"new"}/></MenuItem>
            <MenuItem value={"refurbished"}><FormattedMessage id={"refurbished"}/></MenuItem>
            <MenuItem value={"used"}><FormattedMessage id={"used"}/></MenuItem>
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"price"}/>}
            size={'small'}
            disabled
            defaultValue={productData.price}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={viewPage ? 3 : 6}>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"discount-price"}/>}
            size={'small'}
            type={"number"}
            disabled={disableInputs}
            inputRef={salePriceRef}
            defaultValue={defaultSalePrice}
            inputProps={{
              step: 0.01,
            }}
            onChange={() => setSalePrice()}
            sx={{
              "& .MuiInputBase-root": { paddingRight: '0' }
            }}
            InputProps={{
              endAdornment: <Select
                disabled={disableInputs}
                size={"small"}
                defaultValue={saleCurrency}
                onChange={(e) => setSalePrice(e.target.value)}
                sx={{
                  "& *": { border: "none" }
                }}
              >
                <MenuItem value={'EUR'}>€</MenuItem>
                <MenuItem value={'USD'}>$</MenuItem>
              </Select>
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        {/*<Divider/>*/}
      </Grid2>
    </>
  )
}
