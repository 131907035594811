import { GetCurrentFeedId } from "../utils";
import { useAppSelector } from "../hooks";
import { getProductErrorId } from "../slices/app";
import { AllProductStatus, Product, ProductErrorEnhanced, ProductErrors } from "../types/products";
import { FormattedMessage } from "react-intl";
import React, { useMemo } from "react";
import { Badge, Box, Chip, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { Block, CheckCircle, CloudOff, LinkOff, PendingActions, WarningAmberRounded } from "@mui/icons-material";
import { ChannelsIds } from "../types/feed";
import { ChannelsData } from "../slices/campaign/constants";
import productsApi from "../services/secured/products";
import { CompanyStatus, getProductStatus } from "../utils/productStatusUtils";

const useCatalogErrors = (product?: Product | null | undefined) => {

  const feed = GetCurrentFeedId();
  const errorFocus = useAppSelector(getProductErrorId);

  const getErrorNode = (p: Product, channelId: ChannelsIds) => {
    return (p.product_errors.find(channel => channel.source_id === channelId)?.errors ?? []) as ProductErrorEnhanced[];
  };

  const getErrorNode2 = (productE: ProductErrors[], channelId: ChannelsIds, type: "warnings" | "errors") => {
    return (productE.find(e => e.source_id === channelId)?.errors ?? []).filter(e => type === "errors" ? e.is_blocking : !e.is_blocking) as ProductErrorEnhanced[]
  }

  const activeChannels = useAppSelector(state => state.shop.accounts.hasChannelActive);

  const productChips: any[] = [];
  let globalStatuses: any[] = [];
  let statuses: CompanyStatus[] = [];

  if (product) {
    statuses = [
      {
        company: "google",
        source_id: ChannelsIds.FreeListing,
        status: product.free_listing_status,
        errors: getErrorNode(product, ChannelsIds.FreeListing),
        combinedStatuses: activeChannels.google ? getProductStatus(product.free_listing_status) : [ "OFFLINE" ]
      },
      {
        company: "google",
        source_id: ChannelsIds.Google,
        status: product.status_google,
        errors: getErrorNode(product, ChannelsIds.Google),
        combinedStatuses: activeChannels.google ? getProductStatus(product.status_google) : [ "OFFLINE" ]
      },
      {
        company: "meta",
        source_id: ChannelsIds.Meta,
        status: product.status_meta,
        errors: getErrorNode(product, ChannelsIds.Meta),
        combinedStatuses: activeChannels.meta ? getProductStatus(product.status_meta) : [ "OFFLINE" ]
      },
      {
        company: "microsoft",
        source_id: ChannelsIds.Microsoft,
        status: product.status_microsoft,
        errors: getErrorNode(product, ChannelsIds.Microsoft),
        combinedStatuses: activeChannels.microsoft ? getProductStatus(product.status_microsoft) : [ "OFFLINE" ]
      }
    ];

    statuses.forEach(channel => {
      productChips.push(
        StatusChip(channel)
      )
    });
  } else {
    const rep = productsApi.useGetProductReportingQuery({ feed });
    const node = rep.data?.errors; //type : ProductErrorsEnchanced[]
    if (node) {
      globalStatuses = [
        {
          source_id: ChannelsIds.Google,
          errors: getErrorNode2(node, ChannelsIds.Google, "errors"),
          warnings: getErrorNode2(node, ChannelsIds.Google, "warnings"),
        },
        {
          source_id: ChannelsIds.Meta,
          errors: getErrorNode2(node, ChannelsIds.Meta, "errors"),
          warnings: getErrorNode2(node, ChannelsIds.Meta, "warnings"),
        },
        {
          source_id: ChannelsIds.Microsoft,
          errors: getErrorNode2(node, ChannelsIds.Microsoft, "errors"),
          warnings: getErrorNode2(node, ChannelsIds.Microsoft, "warnings"),
        }
      ];
    }
  }

  return [ {
    errorFocus,
    statuses: productChips,
    globalStatuses,
    allStatuses: statuses,
  } ];
}

interface StatusChipProps {
  source_id: ChannelsIds,
  status: AllProductStatus,
  errors: ProductErrorEnhanced[],
}

const StatusChip = (p: StatusChipProps) => {
  const channelData = ChannelsData[p.source_id];
  const Icon = channelData.icon;
  const company = channelData.company;

  const hasChannelActive = useAppSelector(state => state.shop.accounts.hasChannelActive[company]);

  const component = useMemo(() => {
    // If the channel is not active, show the LinkOff icon.
    if (!hasChannelActive) {
      return <LinkOff color={"disabled"}/>;
    }

    // If the status is null, show the CloudOff icon.
    if (p.status === null) {
      return <CloudOff fontSize={"small"} sx={{ color: "text.secondary" }}/>;
    }

    // Get the array of statuses.
    const combinedStatuses = getProductStatus(p.status);

    // Create an array to hold the JSX elements for each status.
    const statusIcons = combinedStatuses.map(status => {
      switch (status) {
        case 'PENDING':
          return (
            <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={'pending-validation'}/>}>
              <PendingActions color={"info"}/>
            </Tooltip>
          );
        case 'ONLINE':
          return (
            <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={'product-online'}/>}>
              <CheckCircle color={"success"}/>
            </Tooltip>
          );
        case 'MANUALLY_CORRECTED':
          // Assuming you have a specific icon for MANUALLY_CORRECTED
          return (
            <Tooltip arrow placement={"bottom"} title={<FormattedMessage id={"pending-new-validation"}/>}>
              <PendingActions color={"info"}/>
            </Tooltip>
          );
        case 'ERROR':
        case 'WARNING':
          return makeErrorComponent(p.errors, status.toLowerCase() as any);
        default:
          return null; // Or a default icon if necessary
      }
    });

    // Return a React Fragment containing all the status icons.
    return <React.Fragment>{statusIcons}</React.Fragment>;
  }, [ hasChannelActive, channelData, p.status, p.errors ]);

  return (
    <Chip
      size={"small"}
      icon={<Icon/>}
      sx={{ '& .MuiChip-label': { overflow: 'visible', }, }}
      label={(
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {component}
        </Box>
      )}
    />
  )
}

const makeErrorComponent = (e: ProductErrorEnhanced[], type: "warning" | "error") => {
  const arr = e.filter(x => type === "error" ? x.is_blocking : !x.is_blocking)
  return <React.Fragment>
    {arr.length > 0 && (
      <Box>
        <StatusWrapper array={arr} type={type}>
          <Badge
            overlap="circular"
            badgeContent={arr.length}
            color={type}
            sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}
          >
            {type === "warning"
              ? <WarningAmberRounded color={"warning"}/>
              : <Block color={"error"}/>}
          </Badge>
        </StatusWrapper>
      </Box>
    )}
  </React.Fragment>
}

type StatusWrapperProps = {
  array: ProductErrorEnhanced[],
  type: "warning" | "error",
  children: any,
}

export function StatusWrapper(p: StatusWrapperProps) {
  return <Tooltip
    placement={"top"}
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          '& *': {
            color: (theme) => theme.palette.mode === "light" ? '#fff' : undefined,
          },
        },
      },
    }}
    title={
      <Box>
        <List>
          {p.array.map(e => <ListItem key={`e-${e.id}`}>
            <ListItemText
              primary={e.header ? (
                <FormattedMessage
                  id={e.header}
                  values={{ attribute: <FormattedMessage id={e.attribute ? e.attribute : "unknown"}/> }}
                />
              ) : <React.Fragment/>}
              secondary={<FormattedMessage id={e.body ? e.body : e.description}/>}
            />
          </ListItem>)}
        </List>
      </Box>
    }
  >
    {p.children}
  </Tooltip>
}

export default useCatalogErrors;
