import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CampaignFilters, getCampaignsFilters } from "../../../../../app/slices/campaigns";
import { useGetCampaigns } from "../../../../../app/hooks/useGetCampaigns";
import { ReportingData } from "../../../../../app/types/feed";
import { CampaignRowType } from "../CampaignList";
import { isLoadingData, setIsLoadingData } from "../../../../../app/slices/app";
import { useReporting } from "../../../../../app/hooks/useReporting";
import logsApi from "../../../../../app/services/secured/logs";
import { GetCurrentFeedId } from "../../../../../app/utils";

const initTotal = {
  impressions: 0,
  clicks: 0,
  spent: 0,
  conversions: 0,
  conversionValue: 0
};

export const useCampaignData = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const load = useAppSelector(isLoadingData);
  const filters = useAppSelector<CampaignFilters>(getCampaignsFilters);

  const [ list, setList ] = useState<CampaignRowType[]>([]);


  const rep = useReporting();
  const reporting = useMemo(() => rep, [ rep ]);

  // Fetch campaigns
  const [ getGoogle, go ] = useGetCampaigns({ provider: "go", reporting });
  const [ getMeta, fb ] = useGetCampaigns({ provider: "fb", reporting });
  const [ getMicrosoft, microsoft ] = useGetCampaigns({ provider: "bg", reporting });

  // Campaigns warnings
  const campaignsWarningsQuery = logsApi.useGetCampaignsLogsQuery({ feed });

  useEffect(() => {
    if (getGoogle.isSuccess || getMeta.isSuccess && getMicrosoft.isSuccess) {
      const merge = [ ...go, ...fb, ...microsoft ];
      setList(merge);
    }
  }, [ reporting, getGoogle, getMeta, filters, getMicrosoft ]);

  useEffect(() => {
    const isLoading = (getGoogle.isLoading || getMeta.isLoading || getMicrosoft.isLoading || getGoogle.isFetching || getMeta.isFetching || getMicrosoft.isFetching)
    if (isLoading && !load) {
      dispatch(setIsLoadingData(true));
    } else if (!isLoading && load) {
      dispatch(setIsLoadingData(false));
    }
  }, [ getGoogle, getMeta, getMicrosoft ]);

  return {
    list,
    setList,
    reporting,
    fetchLoading: false,
    warnings: campaignsWarningsQuery.data ?? []
  };
}
