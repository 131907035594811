import { GetCurrentFeedId } from "../../../app/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getProductReportingData,
  getProductReportingFilters,
  getReportingPeriod,
  setProductReportingData
} from "../../../app/slices/reporting";
import { getAppMode } from "../../../app/slices/app";
import { useIntl } from "react-intl";
import productsApi from "../../../app/services/secured/products";
import { ReportingPeriodToString } from "../../App/Blocks/Datepicker";
import React, { useEffect, useMemo } from "react";
import useLoading from "../../../app/hooks/useLoading";
import useLabels from "../../../app/hooks/useLabels";
import { Box, LinearProgress } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { CustomToolbar } from "./Filters/CustomToolbar";
import { useCustomPanel } from "./Filters/ProductFilter";
import { filterRows } from "./data/filterRows";
import { CreateReportingColumns } from "./data/createReportingColumns";

export const ReportingTable = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { findProductLabels } = useLabels();

  // State and selectors
  const feed = GetCurrentFeedId();
  const period = useAppSelector(getReportingPeriod);
  const mode = useAppSelector(getAppMode);
  const data = useAppSelector(getProductReportingData);
  const filters = useAppSelector(getProductReportingFilters);

  const searchFilter = useAppSelector(state => state.reporting.search);
  const { text: textFilter, label: labelFilter, inFeed } = searchFilter;

  // API query
  const productReportingQuery = productsApi.useGetProductReportingListQuery({
    feed, period: ReportingPeriodToString(period)
  });

  // Data preparation
  const productReportingData = useMemo(() => productReportingQuery.data ?? [], [ productReportingQuery.data ]);
  useEffect(() => {
    dispatch(setProductReportingData(productReportingData));
  }, [ dispatch, productReportingData ]);

  useLoading(productReportingQuery.isLoading || productReportingQuery.isFetching);

  // Columns and rows setup
  const columns = useMemo(() => CreateReportingColumns(intl, dispatch), [ intl, dispatch ]);
  const rows = useMemo(() =>
    filterRows({
      data,
      filters,
      textFilter,
      labelFilter,
      inFeed,
      findProductLabels,
    }), [ data, filters, textFilter, labelFilter, inFeed, findProductLabels ]);

  return (
    <Box
      sx={{
        ".MuiDataGrid-virtualScroller": {
          minHeight: '100px',
          overflow: "hidden",
        }
      }}
    >
      <DataGridPremium
        className={"product-reporting-data"}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          },
        }}
        pagination
        pageSizeOptions={[ 5, 10, 20, 50, 100, 500 ]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
          filterPanel: useCustomPanel,
          loadingOverlay: LinearProgress,
        }}
        sx={{
          background: mode === "light" ? "#fff" : "",
          "& .MuiDataGrid-main>div:last-child": {
            display: "none"
          },
        }}
      />
    </Box>
  )
}
