import { PerfMaxPictureNode } from "../../../../../../../app/types/campaign";
import { modalShowAssetsManager, PictureType } from "../../../../../../../app/slices/modal";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { getUserAssets } from "../../../../../../../app/slices/app";
import { getPerfMaxData } from "../../../../../../../app/slices/campaign/getters";
import { Box, Button } from "@mui/material";
import { PlusIcon } from "lucide-react";
import React from "react";
import { Picture } from "./Picture";
import { AssetType } from "../../../../../../../app/types/feed";
import Grid2 from "@mui/material/Unstable_Grid2";

export interface PicturesHolderProps {
  type: PerfMaxPictureNode,
}

const MapAssetToType: Record<PerfMaxPictureNode, PictureType> = {
  "logos": "square",
  "marketing_images": "landscape",
  "marketing_images_square": "square",
  "marketing_images_portrait": "portrait",
}
export const PicturesHolder = ({ type }: PicturesHolderProps) => {

  const dispatch = useAppDispatch();
  const assets = useAppSelector(getUserAssets) ?? [];

  const selectedIds = useAppSelector(getPerfMaxData)[type] ?? [];
  const selectedPictures = assets.filter(a => selectedIds.findIndex(i => i === a.id) >= 0);

  const openAssetsDrawer = () => dispatch(modalShowAssetsManager({
    ratio: MapAssetToType[type],
    node: type,
  }));

  const AddBtn = (
    <Grid2 xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Button
        onClick={openAssetsDrawer}
        variant={"outlined"}
        sx={{
          width: "100%",
          height: '85px',
        }}
      >
        <PlusIcon/>
      </Button>
    </Grid2>
  )

  return (
    <Grid2 container spacing={1}>
      {!selectedIds.length ? (
        <>
          {AddBtn}
          {
            type !== "marketing_images_portrait" && (
              <input
                type="text" // Not hidden, but less visible
                required
                style={{
                  opacity: 0.01, position: "absolute", zIndex: -1, top: 0, right: 0,
                }} // Making it practically invisible but still focusable
                aria-hidden="true" // Hide from screen readers to prevent confusion
                value=""
              />
            )
          }
        </>
      ) : (
        <>
          {selectedPictures.map((i, index) => (
            <Picture
              key={`${type}-${index}`}
              type={type}
              i={i}
            />
          ))}
          {AddBtn}
        </>
      )}
    </Grid2>
  )
}
