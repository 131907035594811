//@ts-nocheck
import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { FeedPreviewType, PrestashopCredentials, WoocommerceCredentials } from "../../slices/newfeed";
import { ApiSuccess } from "../../../../types";
import {
  CancelData,
  DynamicLabel, FeedSubscriptionData,
  InitFeedApi,
  InitFeedResponse, NewFeedApi,
  PreviewSubscription,
  SiteReportingRequest,
  SiteReportingResponse,
  UpdateSubscriptionApi, UserFeed
} from "../../types/feed";
import { AggregationsData } from "../../types/campaign";
import { StorageManager } from "../../storage";
import genProductReporting from "../../demo/genProductReporting";
import { enhanceReporting } from "../../slices/campaigns";

const feedApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getFeed: build.query<UserFeed, string | number>({
      query: (param) => ApiUrls.feeds.info(param),
      providesTags: [ "Feed", "UserFeed" ],
      transformResponse(r: UserFeed) {
        if (StorageManager.getIsDemo()) {
          return {
            ...r,
            last_score: {
              feedcast: 88,
              google: 82,
              bing_score: 71,
            },
            last_stat: {
              imported: 166,
            },
          }
        }
        return r;
      }
    }),
    setFeed: build.mutation<UserFeed, Pick<UserFeed, 'id' | 'name' | 'url'>>({
      query: (body) => ({
        url: ApiUrls.feeds.info(body.id),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Feed", "Products" ],
    }),
    setFeedData: build.mutation<UserFeed, Pick<UserFeed, 'id' | 'name' | 'url'>>({
      query: (body) => ({
        url: ApiUrls.feeds.info(body.id),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Feed", "Products", "Feeds" ]
    }),
    getFeeds: build.query<UserFeed[], void>({
      query: () => ApiUrls.feeds.list,
      providesTags: [ "Feeds" ],
    }),
    newFeed: build.mutation<UserFeed, NewFeedApi>({
      query: (body) => ({
        url: ApiUrls.feeds.list,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Feeds" ]
    }),
    getSubscription: build.query<FeedSubscriptionData, number | string>({
      query: (feed) => ApiUrls.subscription.get(feed),
      providesTags: [ "Subscription" ],
    }),
    initSubscription: build.mutation<InitFeedResponse, InitFeedApi>({
      query: (body) => ({
        url: ApiUrls.subscription.init(body.feed),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Subscription", "Feeds", "Feed", "UserFeed" ]
    }),
    syncSubscription: build.mutation<UserFeed, { feed: number, session_id: string }>({
      query: (params) => ({
        url: ApiUrls.subscription.sync(params.feed),
        method: 'GET',
        params: { session_id: params.session_id }
      }),
      invalidatesTags: [ "Subscription", "Feeds" ]
    }),
    updateSubscription: build.mutation<UserFeed, UpdateSubscriptionApi>({
      query: (body) => ({
        url: ApiUrls.subscription.get(body.feed),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Subscription", "Feeds", "Feed", "UserFeed" ]
    }),
    previewSubscription: build.query<PreviewSubscription, UpdateSubscriptionApi>({
      query: (body) => ({
        url: ApiUrls.subscription.get(body.feed),
        method: 'POST',
        body,
      })
    }),
    cancelSubscription: build.mutation<ApiSuccess, { feed: number } & CancelData>({
      query: ({ feed, ...body }) => ({
        url: ApiUrls.subscription.cancel(feed),
        method: 'POST',
        body
      }),
      invalidatesTags: [ "Feeds", "Subscription", "UserFeed", "Feed" ],
    }),
    clearSubscriptionUpdates: build.mutation<FeedSubscriptionData, { feed: number }>({
      query: (body) => ({
        url: ApiUrls.subscription.clear(body.feed),
        method: 'POST',
      }),
      invalidatesTags: [ "Feeds" ],
    }),
    newFeedPreview: build.mutation<FeedPreviewType, { test_url: string }>({
      query: (body) => ({
        url: ApiUrls.feeds.preview,
        method: 'POST',
        body,
      })
    }),
    newPrestashopPreview: build.mutation<FeedPreviewType, PrestashopCredentials>({
      query: (prestashop) => ({
        url: ApiUrls.feeds.preview,
        method: 'POST',
        body: {
          prestashop
        },
      })
    }),
    newWoocommercePreview: build.mutation<FeedPreviewType, WoocommerceCredentials>({
      query: (woocommerce) => ({
        url: ApiUrls.feeds.preview,
        method: 'POST',
        body: {
          woocommerce,
        },
      })
    }),
    getFeedLabels: build.query<AggregationsData, { feed: number }>({
      query: (params) => ({
        url: ApiUrls.feeds.labels.aggs(params.feed),
      }),
      providesTags: [ "CampaignLabels" ],
    }),
    getDynamicLabels: build.query<Array<DynamicLabel>, { feed: number }>({
      query: (params) => ApiUrls.feeds.labels.list(params.feed),
      providesTags: [ "DynamicLabels" ],
    }),
    setDynamicLabels: build.mutation<any, { feed: number, labels: Array<DynamicLabel> }>({
      query: (params) => ({
        url: ApiUrls.feeds.labels.list(params.feed),
        method: 'POST',
        body: params.labels
      }),
      invalidatesTags: [ "DynamicLabels", "CampaignLabels" ],
    }),
    getReporting: build.query<SiteReportingResponse, SiteReportingRequest>({
      query: (params) => ({
        url: ApiUrls.reporting.feed(params.feed),
        params: {
          from: params.period[0],
          to: params.period[1],
        }
      }),
      providesTags: [ "Reporting" ],
      transformResponse(r: SiteReportingResponse) {
        let base = r;
        if (StorageManager.getIsDemo()) {
          base = genProductReporting({ isFlat: false });
        }
        return base.map(row => enhanceReporting(row));
      }
    }),
    activateFreeListing: build.mutation<UserFeed, {
      feed: number,
      data: { feed_option: { enable_free_listing: boolean } }
    }>({
      query: (params) => ({
        url: ApiUrls.feeds.info(params.feed),
        body: params.data,
        method: 'POST',
      })
    }),
    activateFeedcastComparator: build.mutation<UserFeed, {
      feed: number,
      data: { feed_option: { export_comparator: boolean } }
    }>({
      query: (params) => ({
        url: ApiUrls.feeds.info(params.feed),
        body: params.data,
        method: 'POST',
      })
    }),
    deleteFeed: build.mutation<any, { feed: number }>({
      query: (params) => ({
        url: ApiUrls.feeds.info(params.feed),
        method: 'DELETE',
      }),
      invalidatesTags: [ "Feeds" ],
    }),
  })
});

/** Const */
export const feedStatusClassesMUI = {
  "active": "success",
  "free": "warning",
  "canceled": "error",
  "trial": "info",
  "starter": "success",
  "premium": "success"
}

export default feedApi;
