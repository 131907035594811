import { useAppDispatch } from "../../../../../../../app/hooks";
import React, { useCallback } from "react";
import { ImageFormat, smallTitle } from "../../../../../../../app/utils";
import { modalShowCropImage } from "../../../../../../../app/slices/modal";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Slide,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { CheckCircleRounded, Crop, ExpandCircleDown, ExpandLess, WarningAmberRounded } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormattedMessage } from "react-intl";

interface UploadSliderProps {
  allUploads: any[];
  allCalls: any[];
  showUploads: boolean;
  setShowUploads: any;
}

export const UploadSlider = (p: UploadSliderProps) => {
  const dispatch = useAppDispatch();
  const { allUploads, allCalls, showUploads, setShowUploads } = p;

  const showModal = useCallback((url: string, format: ImageFormat) => {
    dispatch(modalShowCropImage({ url, format }));
  }, [ allUploads ]);

  return (
    <Slide
      in={allUploads.length > 0}
      direction={"up"}
      unmountOnExit
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: '100%',
        }}
      >
        <Box display={"flex"}>
          <IconButton
            disableRipple
            onClick={() => setShowUploads((v: boolean) => !v)}
          >
            {showUploads
              ? <ExpandCircleDown color={"primary"}/>
              : <ExpandLess color={"primary"}/>
            }
          </IconButton>
        </Box>
        <Slide in={showUploads} direction={"up"} unmountOnExit>
          <Paper
            elevation={4}
            sx={{
            p: 2,
          }}>
            {[ ...allUploads ].reverse().map((i, index) => {
              const callNode = allCalls.find(a => a.requestId === i.requestId);
              const status = callNode?.status;

              const isError = status === "rejected";

              return (
                <Box
                  key={`upload-${i}`}
                  sx={index !== (allUploads.length - 1) ? {
                    borderBottom: '1px solid #cecece',
                    py: 1,
                  } : { pt: 1 }}
                >
                  <Grid2
                    container
                    alignItems={"center"}

                    lineHeight={0.7}
                  >
                    <Grid2 xs={1} textAlign={"center"}>
                      {status === "pending" && <CircularProgress size={"1rem"}/>}
                      {status === "rejected" && <WarningAmberRounded color={"error"}/>}
                      {status === "fulfilled" && <CheckCircleRounded color={"success"}/>}
                    </Grid2>
                    <Grid2 xs={11}>
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Tooltip
                          title={<FormattedMessage id={"image-format"}/>}
                          placement={"top"}
                          arrow
                        >
                          <Chip variant={"outlined"} size={"small"} label={<FormattedMessage id={i.format}/>}/>
                        </Tooltip>
                        {isError && (
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            onClick={() => showModal(i.url, i.format)}
                            endIcon={<Crop />}
                          >
                            <FormattedMessage id={'crop'}/>
                          </Button>
                        )}
                        <Typography variant={"body2"}>
                          {smallTitle({ title: i.name })}
                        </Typography>
                      </Stack>
                    </Grid2>
                    <Grid2 xs={1}/>
                    <Grid2 xs={10}>
                      <Typography variant={"caption"}>
                        {callNode?.data}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Box>
              )
            })}
          </Paper>
        </Slide>
      </Box>
    </Slide>
  )
}
