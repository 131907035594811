import { SubscriptionState } from "../Subscription";
import { useSubscription } from "./useSubscription";
import { getFeedSubStatus } from "../../../../../app/utils";
import { Packs } from "../constants";

/**
 * Custom hook to handle subscription data.
 */
export const useSubscriptionData = (currSub: SubscriptionState) => {
  const [ sub, feedData ] = useSubscription(currSub.feed);
  const currFeed = feedData.data;
  const currSubStatus = currFeed ? getFeedSubStatus(currFeed) : 'free';
  const selectedProducts = sub.data?.prices.filter(s => s.id === currSub.pack || s.id === currSub.limit);
  const selectedPackCode = selectedProducts?.find(p => p.type === "pack")?.code as Packs ?? null;

  return { sub, currFeed, currSubStatus, selectedProducts, selectedPackCode };
}
