import ProductHeader from "./ProductHeader";
import ProductTabs from "./ProductTabs";
import { Box } from "@mui/material";
import AppSecured from "../../../App/AppSecured/AppSecured";
import { unstable_useBlocker, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getProduct, productHasUnsavedChanges, setProductView } from "../../../../app/slices/product";
import useInitProduct from "./hooks/useInitProduct";
import DialogConfirmChanges from "./DialogConfirmChanges";
import { useEffect } from "react";
import { modalShowProductChanges } from "../../../../app/slices/modal";

const ProductEdit = () => {

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setProductView("page"));
  }, []);

  return (
    <AppSecured lockNav={true}>
      <ProductComponent/>
    </AppSecured>
  )
}

interface ProductComponentProps {
  productPreviewId?: number;
}

export const ProductComponent = ({ productPreviewId }: ProductComponentProps) => {

  const dispatch = useAppDispatch();

  const { pId } = useParams<{ pId: string }>();
  const productId = productPreviewId ?? parseInt(pId ?? "0");
  useInitProduct({ pId: productId, productPreviewId });

  const product = useAppSelector(getProduct);

  // Unsaved changes handle
  const unsaved = useAppSelector(productHasUnsavedChanges);

  let blocker = unstable_useBlocker(
    ({ currentLocation, nextLocation }) => unsaved
  )

  useEffect(() => {
    if (blocker.state === "blocked") {
      dispatch(modalShowProductChanges());
    }
  }, [blocker]);

  return (
    <Box key={`product-${product?.id}`} mt={1}>
      <ProductHeader/>
      <ProductTabs/>
      <DialogConfirmChanges/>
    </Box>
  )
}

export default ProductEdit;
