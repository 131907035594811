import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  initCampaign,
  setCampaignId,
  setCampaignType
} from "../../../../../app/slices/campaign/campaign";
import { GetCurrentFeedId } from "../../../../../app/utils";
import React, { ReactNode, useEffect, useState } from "react";
import campaignApi from "../../../../../app/services/secured/campaign";
import { PageParams } from "../_Campaign";
import Labels from "../common/Labels";
import LocationFilters from "../common/LocationFilters";
import PerfMaxAssets from "../Google/PerformanceMax/PerfMaxAssets";
import GlobalPerfMax from "../Google/PerformanceMax/GlobalPerfMax";
import GlobalShopping from "../Google/GlobalShopping";
import MetaShoppingClassic from "../Meta/MetaShoppingClassic";
import MetaGlobal from "../Meta/MetaGlobal";
import APGlobal from "../Meta/APGlobal";
import Warmup from "../Warmup/Warmup";
import BingShoppingGeneral from "../Microsoft/BingShoppingGeneral";
import { campaignsType } from "../../../../../app/slices/campaign/constants";
import { getCampaign } from "../../../../../app/slices/campaign/getters";
import {
  AdsProvider,
  CampaignType,
  CampaignTypeIdentifier,
  CampaignTypeMapper
} from "../../../../../app/types/campaign";
import PicturesAssets from "../Google/PerformanceMax/Pictures/PicturesAssets";
import TextsAssets from "../Google/PerformanceMax/Texts/TextsAssets";
import Videos from "../Google/PerformanceMax/Videos";
import { DocumentScanner, Flag, ImageSearch, RssFeed } from "@mui/icons-material";
import { VideoIcon } from "lucide-react";

import { GetCampaignDraftNodeId } from "./useCampaignDraft";

//@ts-ignore
const Workflow: Record<CampaignType, ReactNode[]> = {
  [CampaignType.GOOGLE_PERFORMANCE_MAX]: [
    <GlobalPerfMax/>, <Labels/>, <LocationFilters/>, <PicturesAssets/>, <TextsAssets/>, <Videos/>
  ],
  [CampaignType.GOOGLE_SHOPPING_CLASSIC]: [
    <GlobalShopping/>, <Labels/>, <LocationFilters language={false}/>
  ],
  [CampaignType.META_CLASSIC]: [
    <APGlobal/>, <MetaShoppingClassic/>, <LocationFilters language={false}/>
  ],
  [CampaignType.META_SHOPPING_ADVANTAGE]: [
    <APGlobal/>, <LocationFilters language={false}/>
  ],
  [CampaignType.BING_SHOPPING]: [
    <BingShoppingGeneral/>, <Labels/>
  ]
}

export const useInitCampaign = () => {

  const { campaignId, provider, type } = useParams<PageParams>();

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const campaign = useAppSelector(getCampaign);

  const [ workflow, setWorkflow ] = useState<Array<ReactNode>>();
  const [ fetchCampaign, fetchCampaignRes ] = campaignApi.useLazyGetCampaignQuery();

  const draftNode = GetCampaignDraftNodeId({ feed, type, campaignId });
  const draft = window.localStorage.getItem(draftNode);

  // If new campaign, set type from params
  useEffect(() => {
    if (type) {
      dispatch(setCampaignType(campaignsType[type as CampaignTypeIdentifier]));
    }
  }, [ type, campaign ]);

  // If edition, set campaign ID from URL
  useEffect(() => {
    if (campaignId && campaignId !== "new") {
      dispatch(setCampaignId(campaignId));
    }
  }, [ campaignId ]);

  // If ID in state, fetch campaign data
  useEffect(() => {
    if (campaign.id) {
      fetchCampaign({ feed, campaign: campaign.id, provider: provider as AdsProvider }, true);
    }
  }, [ campaign.id ]);

  // Initialise data
  useEffect(() => {
    if (fetchCampaignRes.isSuccess) {
      // Edition : from API
      dispatch(initCampaign({ ...fetchCampaignRes.data, feed }));
    } else if (draft && (!campaignId || campaignId === "new")) {
      // New : only if there's a draft saved
      const draftJson = JSON.parse(draft);
      if (type) {
        const enumType = CampaignTypeMapper[type as CampaignTypeIdentifier];

        if (draftJson.type === enumType) {
          dispatch(initCampaign({ ...draftJson, feed }));
        }
      }
    }
  }, [ fetchCampaignRes, draft, campaignId ]);

  useEffect(() => {
    setWorkflow(Workflow[campaign.type]);
  }, [ campaign ]);

  return {
    api: campaignId ? fetchCampaignRes : true,
    workflow,
  }
}
