import feedApi from "../services/secured/feed";
import { GetCurrentFeedId } from "../utils";
import { useEffect, useMemo } from "react";

const useSubscription = () => {
  const feed = GetCurrentFeedId();
  const [getSub, sub] = feedApi.useLazyGetSubscriptionQuery();
  const data = sub.data;

  useEffect(() => {
    if (feed > 0) {
      getSub(feed, true);
    }
  }, [feed]);

  const maxProducts = useMemo(() => {
    const selectedPrices = data?.prices.filter(p => p.selected) ?? [];
    const plan = selectedPrices.find(p => p.type === "pack");

    if (!plan) return 1000;
    if (plan.code === "premium") return null;

    const limits = selectedPrices.find(p => p.type === "limits");
    return limits ? limits.max_products : 1000;
  }, [data]);

  return { data, maxProducts };
}

export default useSubscription;
