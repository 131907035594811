import { ReportingPeriodString } from "../slices/reporting";
import { PrestashopCredentials, WoocommerceCredentials } from "../slices/newfeed";

import { AdsProvider, AvailableCompany, Company } from "./campaign";
import { OnboardingStepsI } from "../slices/shop";

/********************************************************************** Types */

export enum FeedSourceEnum {
  Manual = 1,
  Shopify,
  Prestashop,
  Woocommerce,
  GoogleSheets,
  Webflow,
  Wizishop,
  AutoGen
}

export type SourceData = {
  image: string,
  logo: string,
  credentials?: Array<keyof PrestashopCredentials | keyof WoocommerceCredentials | 'url'>,
  available: boolean,
  tutorial?: Array<string>,
}

export type ShopPlatforms = {
  nb_enabled: number,
  enabled: Record<AvailableCompany, boolean>,
  remaining: number,
}

export type SubStatus = "trial" | "active" | "canceled" | "free" | "starter" | "premium";
export type ShopSub = {
  type: SubStatus,
  limit: number,
  start: null | Date,
  end: null | Date,
}

export type CompaniesAuth = Record<Extract<Company, "google" | "meta" | "microsoft">, boolean>;

export type ShopAccounts = {
  isOauth: Record<Extract<Company, "google" | "meta" | "microsoft">, boolean | null>,
  hasLinkedAccount: CompaniesAuth,
  hasAlerts: CompaniesAuth,
  hasAccess: CompaniesAuth,
  canCreateCampaigns: CompaniesAuth,
  hasCampaigns: CompaniesAuth & { nb: number },
  hasChannelActive: CompaniesAuth,
  hasErrorFetching: CompaniesAuth,
  hasTracking: CompaniesAuth,
  hasPayment: CompaniesAuth,
}

export type UserFeed = {
  id: number,
  locale: string,
  name: string,
  current_subscription: FeedSubscription | null,
  url: string | null,
  max_platforms: number,
  last_score: null | {
    feedcast: number | null,
    google: number | null,
    bing_score: number | null,
  },
  last_stat: null | {
    imported: number,
    date_end_last_import: number,
  },
  source: FeedSourceEnum | null,
  project: {
    id: number,
    name: string
  } | null,

  feed_option: FeedOptions,

  platforms: null | {
    microsoft: null | UserAdsAccount
    microsoft_merchant: null | UserAdsAccount,
    ads: UserAdsAccount | null,
    merchant: UserAdsAccount | null,
    meta: UserAdsAccount | null,
    facebook_catalog_id: string | null,
    google_enabled: boolean,
    meta_enabled: boolean,
    microsoft_enabled: boolean,
  }
  country: string | null,
  language: string | null,
}

export type FeedOptions = {
  export_comparator: boolean,
  enable_free_listing: boolean,
}

export type Price = {
  id: number,
  type: "pack" | "limits" | "options" | "platforms",
  price: number,
  selected: boolean,
  title?: string,
  code: string,
  max_products: number | null,
  additional_platforms: number | null,
}
export type InitFeedApi = {
  feed: number,
  prices: Array<number>,
  redirect_error: string,
  redirect_success: string,
  promotion_code?: string,
}
export type InitFeedResponse = {
  session: string,
  key: string,
}
export type PreviewSubscription = {
  downgrade: boolean,
  lines: SubLines[],
}
export type SubLines = {
  id: number,
  title: string,
  current_period: boolean,
  quantity: number,
  amount_monthly: number,
  amount_proration: number,
  tax_proration: number,
  period: {
    start: number,
    end: number,
  }
}
export type AccountStatusData = {
  merchant?: MerchantStatusData,
  ads?: {
    has_payment: boolean | null,
  }
}
export type MerchantStatusData = {
  id: string,
  name: string,
  address: {
    address: string | null,
    city: string | null,
    zip: string | null,
    country: string | null,
    phone: string | null,
    is_phone_validated: boolean,
  },
  contact: {
    email: string | null,
    phone: string | null,
    url: string | null,
  },
  shipping: Array<ShippingType>,
  child: boolean,
  warnings: Array<MerchantCenterAlert>
};
export type MerchantCenterAlert = {
  title: string,
  detail: string,
  link: string,
  country: string | null,
  severity: GoogleMerchantAlertSeverity,
}

export enum GoogleMerchantAlertSeverity {
  WARNNING = 1,
  ERROR = 2,
  CRITICAL = 3,
}

export type ShippingType = {
  name: string,
  country: string,
  delivery_max: number,
  delivery_min: number,
  handling_max: number,
  handling_min: number,
  max_hour: number,
  // (1 = fixed price, 2 = ranged price)
  type: ShippingTypes,
  // (null for type = 2)
  fixed_price: number | null,
  // (not empty for type = 2)
  rates: Array<ShippingRate>,
}

export enum ShippingTypes {
  "Free",
  "Fixed",
  "Ranged",
  "Weight",
}

export type ShippingRate = {
  price: number,
  upper_limit: number | null,
}
export type AssetType = {
  id: string,
  url: string,
  width: number,
  height: number,
}
export type DynamicLabel = {
  uuid: string,
  conditions: Array<DynamicLabelRule>,
  label_value: string,
  condition_any: boolean,
  overwrite: boolean,
  label_key: CustomDynamicLabelKey,
}
export type CustomDynamicLabelKey =
  "custom_label_0_k"
  | "custom_label_1_k"
  | "custom_label_2_k"
  | "custom_label_3_k"
  | "custom_label_4_k";

export type DynamicLabelRule = {
  uuid: string,
  value: string,
  field: DynamicLabelField,
  operator: DynamicLabelOperators,
}

export type DynamicLabelField = "brand_k" | "condition_k" | "id_k" | string;
type DynamicLabelOperators = "eq" | "in" | string;
export const DynamicLabelCriterias: Record<DynamicLabelField, {
  operators: Array<DynamicLabelOperators>,
  choices: Array<string>
}> = {
  "brand_k": {
    // operators: [ "eq", "in" ],
    operators: ["in"],
    choices: [],
  },
  "condition_k": {
    operators: ["eq"],
    choices: ["new", "refurbished", "used"],
  },
  "id_k": {
    operators: ["in"],
    choices: [],
  },
};
export type SiteReportingResponse = Array<FullReportingRow>;

export type ReportingRow = {
  identifier: Providers | DevicesTypes | string,
  impressions: number,
  clicks: number,
  conversions: number,
  spent: number,
  conversionValue: number,
  childs: Array<ReportingRow>,
}
export type ReportingData = Pick<ReportingRow, "impressions" | "clicks" | "conversions" | "spent" | "conversionValue">
export type FullReportingRow =
  ReportingRow
  & {
    cpc: number,
    clickRate: number,
    conversionCost: number,
    roi: number,
  }

enum Providers {
  Google = "go",
  Facebook = "fb",
  Microsoft = "bg",
}

export enum ChannelsIds {
  Google = 1,
  Meta = 2,
  Microsoft = 3,
  FreeListing
}

export enum DevicesTypes {
  Unknown,
  Desktop,
  Mobile,
  Tablet
}

export type SiteFetchRequest = {
  feed: number,
};

export type SiteReportingRequest = SiteFetchRequest & {
  period: ReportingPeriodString,
}
export type UpdateSubscriptionApi = {
  feed: number,
  confirm?: boolean,
  platforms_to_remove?: Array<AdsProvider>,
} & Pick<InitFeedApi, 'prices'>

export type CancelData = {
  type?: CancelReason
  description?: string
}

export enum CancelReason {
  UNKNOWN,
  HARD_TO_USE,
  NOT_ENOUGH_TIME,
  INVALID_CATALOG,
  PERF_ISSUES
}

export const CancelReasonQuestion: Record<CancelReason, string> = {
  0: "precise",
  1: "where-do-you-fail",
  2: "consider-premium",
  3: "",
  4: "do-you-diffuse",
}
export type ProjectType = {
  name: string,
  id: number,
}
export type FeedSubscription = {
  date_end_subscription: number,
  date_end_trial: number,
  date_start_subscription: number,
  is_cancelled: boolean,
  plan: {
    plan: null | "starter" | "premium",
    limit: number,
  }
}

export type UserAdsAccount = {
  id: number,
  code: string,
  name: string,
  is_child: boolean,
}

export type NewFeedApi = Pick<UserFeed, 'name' | 'locale' | 'url' | 'source'> & { project: number }

export type FeedSubscriptionData = {
  is_linked: boolean,
  is_new: boolean,
  prices: Array<Price>,
  next_periods?: Array<string[]>,
  next_removed_platforms?: AdsProvider[],
  discounts?: SubscriptionDiscount[],
  has_current_sub?: boolean,
}

export type SubscriptionDiscount = {
  amount: number,
  product: number,
}
