import { useAppSelector } from "../hooks";
import { getFeedIssues } from "../slices/app";

export const useFeedDownloadError = () => {
  const feedIssues = useAppSelector(getFeedIssues);
  const hasError = (feedIssues.length || 0) > 0;
  return {
    hasError,
    message: hasError ? feedIssues[0].data.message : "",
  };
}
