import { Box } from "@mui/material";
import { LoadingButton } from "../../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import React from "react";
import useUpdateProduct from "./hooks/useUpdateProduct";

export const ProductFooter = () => {
  const { updateProduct, api } = useUpdateProduct();
  return (
    <Box mt={2} display={"flex"} justifyContent={"end"}>
      <LoadingButton
        data={api}
        variant="contained"
        size={"large"}
        reset={true}
        onClick={updateProduct}
      >
        <FormattedMessage id={"save"}/>
      </LoadingButton>
    </Box>
  )
}
