import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setAdGroupName,
  setBiddingStrategy,
  setCampaignRoas,
  setTargetCpc
} from "../../../../../app/slices/campaign/campaign";
import { FormattedMessage } from "react-intl";
import React from "react";
import { Euro, Percent } from "@mui/icons-material";
import { Box, Divider, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { getBiddingStrategy, getCampaign, isNewCampaign } from "../../../../../app/slices/campaign/getters";
import CampaignComponents from "../common/components";
import { BiddingStrategy } from "../../../../../app/types/campaign";
import { Globe } from "lucide-react";
import CampaignCard from "../CampaignCard";

const GlobalShopping = () => {
  const campaign = useAppSelector(getCampaign);
  const dispatch = useAppDispatch();

  const components = CampaignComponents();

  return (
    <Box>
      <CampaignCard icon={<Globe/>} title={"global-parameters"}>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              {components.budget}
            </Box>
            <Box sx={{ flexGrow: 1, }}>
              {components.name}
            </Box>
          </Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"ad-group-name"}/>}
                onChange={(e) => dispatch(setAdGroupName(e.target.value))}
                value={campaign.adgroup.name}
                required
                helperText={
                <FormattedMessage id={'ad-group-name-desc'} />
                }
              />
            </FormControl>
          </Box>
          <Divider/>
          <Stack spacing={2}>
            <Typography variant={"subtitle2"}>
              <FormattedMessage id={'bidding-strategy'}/>
            </Typography>
            <Strategy/>
            <Typography variant={"body2"} color={"text.secondary"}>
              <FormattedMessage id={'bidding-strategy-desc'} values={{
                br: <br/>,
                ul: (txt) => <ul style={{ listStyleType: "decimal", paddingLeft: "1rem", marginBottom: 0 }}>{txt}</ul>,
                li: (txt) => <li>{txt}</li>,
                b: (txt) => <b>{txt}</b>,
              }}/>
            </Typography>
          </Stack>

        </Stack>
      </CampaignCard>
    </Box>
  )
}

const Strategy = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(getBiddingStrategy);
  const campaign = useAppSelector(getCampaign);
  const isNewC = useAppSelector(isNewCampaign);

  const setStrategy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStrat = parseInt(e.target.value) as BiddingStrategy;
    dispatch(setBiddingStrategy(newStrat));
  }

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box sx={{flexGrow: 4}}>
        <FormControl fullWidth>
          <TextField
            size={"small"}
            select
            label={<FormattedMessage id={"select-your-strategy"}/>}
            onChange={setStrategy}
            value={strategy}
            required
          >
            <MenuItem value={BiddingStrategy.manual_cpc}>
              <FormattedMessage id={"manual_cpc"}/>
            </MenuItem>
            <MenuItem value={BiddingStrategy.maximize_clicks}>
              <FormattedMessage id={"maximise-clicks"}/>
            </MenuItem>
            <MenuItem
              value={BiddingStrategy.target_roas}
              disabled={isNewC}
            >
              <FormattedMessage id={"target_roas"}/>
            </MenuItem>
          </TextField>
        </FormControl>
      </Box>
      <Box sx={{flexGrow: 1}}>
        {strategy === BiddingStrategy.target_roas && (
          <FormControl fullWidth>
            <TextField
              required
              size={"small"}
              type={"number"}
              // placeholder={FMsg("roas-value")}
              onChange={(e) => dispatch(setCampaignRoas(parseFloat(e.target.value)))}
              value={campaign.target_roas}
              InputProps={{
                endAdornment: <InputAdornment position={"end"}>
                  <Percent/>
                </InputAdornment>
              }}
            />
          </FormControl>)}

        {(strategy === BiddingStrategy.maximize_clicks || strategy === BiddingStrategy.manual_cpc) &&
          (<FormControl fullWidth>
            <TextField
              required
              size={"small"}
              label={<FormattedMessage id={"maximal-cpc"}/>}
              type={"number"}
              // placeholder={FMsg("bid-limit")}
              onChange={(e) => dispatch(setTargetCpc(parseFloat(e.target.value)))}
              value={(strategy === BiddingStrategy.maximize_clicks ? campaign.target_cpc : campaign.adgroup.target_cpc)}
              InputProps={{
                endAdornment: <InputAdornment position={"end"}>
                  <Euro/>
                </InputAdornment>
              }}
            />
          </FormControl>)}
      </Box>
    </Box>
  )
}

export default GlobalShopping;
