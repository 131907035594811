import { UserFeed } from "../../../../app/types/feed";
import { MUIColor } from "../../Blocks/Misc";
import React from "react";
import { Badge, Box } from "@mui/material";
import app_logo from "../../../../assets/img/icons/app-logo.svg";

interface FeedBadgeProps {
  currentFeed: UserFeed | null;
  source: { logo: string };
  statusClass: MUIColor;
}

export const FeedBadge: React.FC<FeedBadgeProps> = ({ currentFeed, source, statusClass }) => {

  return (
    currentFeed ? (
      <Badge
        variant="dot"
        overlap="circular"
        color={statusClass}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box>
          <img src={currentFeed ? source?.logo : app_logo} alt="Feed Logo" style={{ width: '20px' }} />
        </Box>
      </Badge>
    ) : null
  )
}
