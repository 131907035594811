import { Box, FormControl, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { ImageFormat } from "../../../../../../../app/utils";
import { AssetsFilters } from "./DropArea";

interface DropAreaFiltersProps extends AssetsFilters {
  setFormatFilter: any;
  setNbColumns: any;
  disabled: boolean;
}

export default (p: DropAreaFiltersProps) => {

  const { formatFilter, setFormatFilter, nbColumns, setNbColumns, disabled } = p;

  const handleFormatFilterChange = (e: React.MouseEvent<HTMLElement>, newValue: ImageFormat | "all" | null) => (
    newValue !== null && setFormatFilter(newValue)
  )

  const handleNbColumnsChange = (e: React.ChangeEvent<HTMLInputElement>) => setNbColumns(parseInt(e.target.value));

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={"overline"}>
          <FormattedMessage id={'filters'} />
        </Typography>
        <ToggleButtonGroup
          disabled={disabled}
          value={formatFilter}
          exclusive
          onChange={handleFormatFilterChange}
        >
          <ToggleButton value={"all"}><FormattedMessage id={'all'}/></ToggleButton>
          <ToggleButton value={"landscape"}><FormattedMessage id={'landscape'}/></ToggleButton>
          <ToggleButton value={"portrait"}><FormattedMessage id={'portrait'}/></ToggleButton>
          <ToggleButton value={"square"}><FormattedMessage id={'square'}/></ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={"overline"}>
          <FormattedMessage id={'columns'} />
        </Typography>
        <Box display={"flex"}>
          <FormControl>
            <TextField
              size={"small"}
              type={"number"}
              value={nbColumns}
              onChange={handleNbColumnsChange}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}
