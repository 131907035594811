import { useAppSelector } from "../../../app/hooks";
import { getAppMode } from "../../../app/slices/app";
import fc from "../../../assets/img/logo/fc_light.svg";
import fc_dark from "../../../assets/img/logo/fc_dark.svg";
import { Box, CircularProgress } from "@mui/material";
import { LoadingAnimation } from "../Blocks/Misc";
import React from "react";

export const AppLoader = () => {
  const mode = useAppSelector(getAppMode);
  const img = mode === "light" ? fc : fc_dark;
  return <Box
    sx={{
      position: "absolute",
      top: 0,
      zIndex: (theme) => theme.zIndex.drawer,
      width: '100%',
      backgroundColor: (theme) => theme.palette.background.default,
    }}
  >
    <LoadingAnimation data={true} slide>
      <Box
        sx={{
          width: '100%',
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        style={{ height: '100vh' }}
      >
        <img src={img} style={{ width: '300px' }}/>
        <CircularProgress size={"1.2rem"} sx={{ mt: 2 }}/>
      </Box>
    </LoadingAnimation>
  </Box>
}
