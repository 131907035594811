import { FullReportingRow, ReportingRow } from "../types/feed";
import { ReportingPeriodToString } from "../../components/App/Blocks/Datepicker";
import { useAppSelector } from "../hooks";
import { getReportingPeriod } from "../slices/reporting";
import feedApi from "../services/secured/feed";
import useLoading from "./useLoading";
import { GetCurrentFeedId } from "../utils";

export const useReporting = (): FullReportingRow[] => {

  const feed = GetCurrentFeedId();
  const period = ReportingPeriodToString(useAppSelector(getReportingPeriod));

  const reporting = feedApi.useGetReportingQuery({ feed, period });

  useLoading(reporting.isFetching);

  return reporting.data ?? [];
}
