import { getLocaleDate } from "../../../app/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getReportingMetric, Kpi, setMetric } from "../../../app/slices/reporting";
import React, { ReactNode, useMemo } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, FormControl, ListItemIcon, ListItemText, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import FCard from "../../App/Blocks/Card";
import { FormattedMessage, useIntl } from "react-intl";
import { AdsClick, SellOutlined, TrendingUp, Visibility, Wallet } from "@mui/icons-material";
import DatePicker from "../../App/Blocks/Datepicker";
import { useReporting } from "../../../app/hooks/useReporting";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type PerfData = Record<string, {
  kpi_1: number,
  kpi_2: number,
}>

const GetTooltipCallback = (metric: [ Kpi, Kpi ], intl: any) => (context: any) => {
  const value = Number(context.parsed.y);
  return `${intl.formatMessage({ id: context.dataset.label })}: ${intl.formatNumber(value)}`;
}

interface PerfChart2Props {
  datePicker?: boolean
}

const fakelabels = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ];

export const fakeData = {
  labels: fakelabels,
  datasets: [
    {
      label: 'Dataset 1',
      data: fakelabels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: '#704bf7',
      backgroundColor: '#704bf7',
    },
    {
      label: 'Dataset 2',
      data: fakelabels.map(() => faker.number.int({ min: -1000, max: 1000 })),
      borderColor: '#BAAAFB',
      backgroundColor: '#BAAAFB',
    },
  ],
};

const PerChart2 = ({ datePicker = false }: PerfChart2Props) => {

  const intl = useIntl();
  const reporting = useReporting();
  const selected = useAppSelector(getReportingMetric);

  const dataObj = useMemo(() => {
    const dataObj: PerfData = {};
    reporting.map(providerChild => {
      providerChild.childs.map(campaignChild => {
        campaignChild.childs.map(dateChild => {
          const date = dateChild.identifier;
          dataObj[date] = {
            kpi_1: (dataObj[date]?.kpi_1 ?? 0) + dateChild[selected[0]],
            kpi_2: (dataObj[date]?.kpi_2 ?? 0) + dateChild[selected[1]],
          }
        })
      });
    });
    return dataObj;
  }, [ reporting, selected ])

  const sortedDates = useMemo(() => {
      const s = Object.keys(dataObj).sort((a, b) => (new Date(a).getTime() - new Date(b).getTime()));
      return s.length > 0 ? s.slice(0, s.length - 1) : s;
    }, [ dataObj ])
  ;

  const hasData = (reporting?.length || 0 > 0 && (
    reporting[0]?.clicks > 0
    || reporting[0]?.conversionValue > 0
    || reporting[0]?.conversions > 0
    || reporting[0]?.impressions > 0
    || reporting[0]?.spent > 0
  ));

  const options: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 1,
        backgroundColor: "#1E1E1E",
        hitRadius: 1,
      },
      line: {
        tension: 0.4
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        callbacks: {
          label: GetTooltipCallback(selected, intl),
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          display: false,
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          display: false,
        }
      },
      x: {
        grid: {
          display: false,
        }
      }
    },
  };

  const data: ChartData<"line"> = {
    labels: sortedDates.map(s => getLocaleDate(s, true)),
    datasets: [
      {
        label: selected[0],
        data: sortedDates.map(date => dataObj[date].kpi_1),
        borderColor: "#704bf7",
        backgroundColor: "#704bf7",
        borderCapStyle: "round" as any,
        borderJoinStyle: "round" as any,
        pointHoverRadius: 8,
        yAxisID: 'y',
      },
      {
        label: selected[1],
        data: sortedDates.map(date => dataObj[date].kpi_2),
        borderColor: "#BAAAFB",
        backgroundColor: "#BAAAFB",
        borderCapStyle: "round" as any,
        borderJoinStyle: "round" as any,
        pointHoverRadius: 8,
        yAxisID: 'y1',
      },
    ],
  };

  return (
    <Box height={'100%'} className={"perf-chart"}>
      <FCard
        variant={"no-padding"}
        sx={{ overflow: "hidden", height: '100%' }}
      >
        <Box p={1} mb={2}>
          {datePicker && (
            <Box p={1}>
              <DatePicker margin={false}/>
            </Box>
          )}
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Selector
              kpiSelector={0}
              selected={selected[0]}
            />
            <Selector kpiSelector={1} selected={selected[1]}/>
          </Stack>
        </Box>
        <Box sx={{
          px: 3,
          pb: 2,
          filter: !hasData ? 'blur(12px)' : null,
          position: "relative",
        }}>
          <Line
            options={options}
            data={hasData ? data : fakeData}
          />
        </Box>
      </FCard>
    </Box>
  )
}

type SelectorProps = {
  kpiSelector: 0 | 1,
  selected: Kpi,
}

const Selector = (p: SelectorProps) => {

  const dispatch = useAppDispatch();
  const onChange = (e: any) => dispatch(setMetric({ kpi: e.target.value, index: p.kpiSelector }));

  const items: Record<string, ReactNode> = {
    "impressions": <Visibility/>,
    "clicks": <AdsClick/>,
    "spent": <Wallet/>,
    "conversions": <SellOutlined/>,
    "conversionValue": <TrendingUp/>
  };

  const borderColor = p.kpiSelector === 0 ? "#704bf7" : "#BAAAFB";

  return <Box>
    <FormControl size={"small"} fullWidth>
      <TextField
        select
        value={p.selected}
        size={"small"}
        onChange={onChange}
        sx={{
          border: "none",
          '& fieldset': {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
            // color: borderColor,
            "&:focus fieldset, &:active fieldset, &:hover fieldset": {
              borderColor: borderColor,
            }
          },
          "& .MuiOutlinedInput-root": {
            "&:focus fieldset, &:active fieldset, &:hover fieldset": {
              borderColor: borderColor,
            }
          },
          "& .MuiInputBase-input, & svg": {
            color: borderColor,
            "&:focus, &:active": {
              borderColor: "#fff",
            }
          },
          "& .MuiInputBase-input": {
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
          },
          "& .MuiListItemIcon-root": {
            minWidth: '30px',
          }
        }}
      >
        {Object.keys(items).map(i => <MenuItem
          key={`${p.kpiSelector}-${i}`}
          value={i}
        >
          <ListItemIcon>{items[i]}</ListItemIcon>
          <ListItemText><FormattedMessage id={i}/></ListItemText>
        </MenuItem>)}
      </TextField>
    </FormControl>
  </Box>
}


export default PerChart2;
