import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Locale from "../../Blocks/Locale";
import { niceUrl } from "../../../../app/utils";
import { UserFeed } from "../../../../app/types/feed";

interface FeedDetailsProps {
  showFeed: boolean;
  currentFeed: UserFeed | null;
}

export const FeedDetails: React.FC<FeedDetailsProps> = ({ showFeed, currentFeed }) => {
  if (!showFeed || !currentFeed) {
    return <Typography><FormattedMessage id="all-your-projects"/></Typography>;
  }
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" lineHeight={1}>
        {currentFeed?.project?.name}
      </Typography>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Locale locale={currentFeed.locale} style={{ fontSize: "0.54rem" }}/>
        <Typography variant="body2">{niceUrl(currentFeed.name ?? "")}</Typography>
      </Stack>
    </Box>
  );
};
