import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import FCard from "../../../../App/Blocks/Card";
import { ProductImage } from "./ProductImage";
import { SourceToggle } from "./SourceToggle";
import { CardStatuses } from "./CardStatuses";
import { useAppSelector } from "../../../../../app/hooks";
import { getProduct, getProductData, getProductIsLoading } from "../../../../../app/slices/product";
import { SkeletonLoader } from "../../../../App/Blocks/Misc";

export const ProductCard = () => {
  const product = useAppSelector(getProduct);
  const data = useAppSelector(getProductData);
  const loading = useAppSelector(getProductIsLoading);
  return (
    <FCard variant={"no-padding"}>
      <SkeletonLoader load={loading}>
        <Stack spacing={2} minHeight={300}>
          <ProductImage />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 2 }}>
            <Box>
              <Typography color={"text.secondary"}>
                Ref.
              </Typography>
              <Typography color={"text.secondary"} variant={"caption"}>
                {product?.product_id}
              </Typography>
            </Box>
            <Typography variant={"h5"} lineHeight={1}>
              {formatCurrency(((data.sale_price?.length || 0) > 3 ? data.sale_price : data.price) ?? "")}
            </Typography>
          </Box>
          <CardStatuses />
          <SourceToggle />
        </Stack>
      </SkeletonLoader>
    </FCard>
  )
}

const formatCurrency = (price:string): string => {
  const CurrencyDictionary:Record<string, string> = {
    "EUR": "€",
    "USD": "$",
  };
  const [value, currency] = price.split(' ');
  return `${value}${CurrencyDictionary[currency] || currency}`;
};
