import { Product, ProductData } from "../../../../app/types/products";
import React, { useRef } from "react";
import { FeedDataFeedcast } from "../../../../app/utils";
import { attributeOptions, productDataTypes } from "../../../../assets/resources/googleErrors";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem, Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers";
import { CSSTransition } from "react-transition-group";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ImageLoader } from "../../../App/Blocks/Misc";
import { Link } from "@mui/icons-material";
import { WeightUnit } from "../../Products/Edit/TabAttributes/ProductAttributes";
import useValueWithUnitManager from "../../Products/Edit/hooks/useValueWithUnitManager";
import { GoogleCategorySelect } from "../../../App/Blocks/GoogleCategorySelect";
import { useProductCategory } from "../../../../app/hooks/useCategories";

type ErrorRowProps = Product & {
  onChange: (val: string | boolean | number) => void
  field: keyof ProductData | null,
  isCorrected: boolean,
}

const ErrorRow = (p: ErrorRowProps) => {

  const nodeRef = useRef(null);

  const { onChange, field, ...product } = p;

  const productData = FeedDataFeedcast(product);

  let attributeInputType = p.field ? productDataTypes[p.field] : null;

  const defaultValue = p.custom_data && p.field ? p.custom_data[p.field] ?? "" : "";
  const onInputChange = (e: any) => p.onChange(typeof e === "string" ? e : e.target?.value);

  const category = useProductCategory(parseInt(defaultValue.toString()));

  // Shipping Weight
  const {
    valueRef: shippingWeightRef,
    currentUnit: shippingUnit,
    setValueWithUnit: setShippingWeight,
    defaultValue: defaultValueShippingWeight
  } = useValueWithUnitManager(productData?.shipping_weight, "kg", onInputChange);

  let input = <></>;
  switch (attributeInputType) {
    case "boolean":
      //TODO
      // input = <FormCheck
      //   defaultValue={defaultValue}
      //   type={"switch"}
      //   className={'ms-2'}
      //   onChange={(e) => p.onChange(e.target.checked)}/>
      break;
    case "text":
      input = <FormControl fullWidth>
        <TextField
          name={p.field ?? ""}
          defaultValue={defaultValue}
          onChange={onInputChange}
          label={<FormattedMessage id={p.field ?? "inconnu"}/>}
        />
      </FormControl>
      break;
    case 'text-unit':
      if (p.field === "shipping_weight") {
        input = (
          <FormControl fullWidth>
            <TextField
              inputRef={shippingWeightRef}
              label={<FormattedMessage id={"shipping-weight"}/>}
              onChange={() => setShippingWeight()}
              defaultValue={defaultValueShippingWeight}
              type={"number"}
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                endAdornment: <Select
                  size={"small"}
                  sx={{ border: "none", "& *": { border: "none" } }}
                  onChange={(e) => setShippingWeight(e.target.value as WeightUnit)}
                  defaultValue={shippingUnit}
                >
                  <MenuItem value={'kg'}>kg</MenuItem>
                  <MenuItem value={'g'}>g</MenuItem>
                  <MenuItem value={'lb'}>lb</MenuItem>
                  <MenuItem value={'oz'}>oz</MenuItem>
                </Select>
              }}
            />
          </FormControl>
        );
      }
      break;
    case "long-text":
      input = <FormControl fullWidth>
        <TextField
          name={p.field ?? ""}
          defaultValue={defaultValue}
          onChange={onInputChange}
        />
      </FormControl>
      break;
    case "select":
      //@ts-ignore
      const options: any[] = attributeOptions[p.field];
      input = <FormControl fullWidth>
        <TextField
          select
          defaultValue={defaultValue}
          name={p.field ?? ""}
          onChange={onInputChange}
        >
          {options.map(o => <MenuItem value={o}><FormattedMessage id={o}/></MenuItem>)}
        </TextField>
      </FormControl>
      break;
    case "select-multi":
      if (p.field) {
        if (typeof defaultValue === "string") {
          //@ts-ignore
          const options: any[] = attributeOptions[p.field];
          input = <Autocomplete
            renderInput={(params) => <TextField {...params} label={<FormattedMessage id={p.field ?? "inconnu"}/>}/>}
            options={options.map(o => {
              return { label: o }
            })}
            defaultValue={defaultValue.split("/").map(v => {
              return { label: v }
            })}
            multiple={attributeInputType === "select-multi"}
            onChange={(e, val) => {
              if (val) {
                if (Array.isArray(val) && val) {
                  p.onChange(val.map(v => v.label).join("/"));
                }
              }
            }}
          />
        }
      }
      break;
    case "date":
      // input = <DatePicker
      //   defaultValue={defaultValue}
      //   onChange={onInputChange}
      // />
      attributeInputType = null;
      break;
    case "number":
      // Only case of number for now, but added for stability
      if (p.field === "google_product_category_k") {
        input = (
          <GoogleCategorySelect
            label={false}
            size={"medium"}
            defaultSelected={category}
            onChange={(categoryId) => p.onChange(categoryId)}
          />
        )
      }
      break;
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      classNames={"item"}
      timeout={500}
      in={true}
      unmountOnExit
    >
      <Box ref={nodeRef}>
        <Grid2 container spacing={3}>
          <Grid2
            xs={3}
            height={'180px'}
          >
            {p.isCorrected && (
              <Chip
                variant={"outlined"}
                sx={{ width: '100%' }}
                size={"small"}
                label={<FormattedMessage id={"pending-validation"}/>}
                color={"info"}
              />
            )}
            <Box
              mt={1}
              height={'100%'}
              display={"flex"}
              justifyContent={"center"}
            >
              {ImageLoader({ src: productData.image_link, height: "140px" })}
            </Box>
          </Grid2>
          <Grid2 xs>
            <Box mb={2}>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell><FormattedMessage id={"title-from-feed"}/></TableCell>
                    <TableCell align={"right"}><FormattedMessage id={"link"}/></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{productData.id_k}</TableCell>
                    <TableCell>{p.data.title}</TableCell>
                    <TableCell align={"right"}>
                      <IconButton
                        edge={"end"}
                        href={productData.link}
                        target={'_blank'}
                      >
                        <Link/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            {input}

            {!attributeInputType && (
              <Alert severity={"warning"}>
                <AlertTitle><FormattedMessage id={"batch-attribute-not-handled"}/></AlertTitle>
                <FormattedMessage id={"batch-attribute-not-handled-desc"}/>
              </Alert>
            )}
          </Grid2>
        </Grid2>
      </Box>
    </CSSTransition>
  )
}

export default ErrorRow;
