import React, { useEffect, useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAppMode, getNavOpen, isLoadingData, setNavOpen, setNavWasDisabled } from "../../../app/slices/app";
import logo_2 from "../../../assets/img/logo/fc_light.svg";
import logo_2_alt from "../../../assets/img/logo/fc_dark.svg";
import {
  Box,
  Button,
  Collapse,
  Fade,
  IconButton,
  LinearProgress, List, ListItem, ListItemIcon, ListItemText,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { ChevronLeft, ChevronRight, CreditCardOff, Menu, Troubleshoot } from "@mui/icons-material";
import AppHeader from "../Header/AppHeader";
import { AppBar, Drawer, DrawerHeader } from "../NavComponents";
import { AppDispatch } from "../../../app/store";
import { AppSecuredProps } from "../AppSecured/AppSecured";
import NavList from "./NavList";
import NotionForm from "../NotionForm";
import { useAccounts } from "../../../app/hooks/useAccounts";
import { AvailableCompany } from "../../../app/types/campaign";
import { capitalizeFirstLetter } from "../../../app/utils";
import { FormattedMessage } from "react-intl";

const MyAppBar = styled(AppBar)(({ theme }) => ({
  borderRadius: 0,
  border: "none",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px -3px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px",
  position: "fixed",
  // background: theme.palette.background.default,
}));

const Navigation = ({ lockNav }: Pick<AppSecuredProps, "lockNav">) => {

  const isLoading = useAppSelector(isLoadingData);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const open = useAppSelector(getNavOpen);
  const mode = useAppSelector(getAppMode);
  const setOpen = () => dispatch(setNavOpen(true));
  const setClose = () => dispatch(setNavOpen(false));

  const navWasDisabled = useAppSelector(state => state.app.navWasDisabled);
  useLayoutEffect(() => {
    if (!lockNav && !open && navWasDisabled) {
      dispatch(setNavWasDisabled(false));
    }
  }, [lockNav, open, navWasDisabled]);

  return (
    <Box>
      <MyAppBar open={open}>
        <Toolbar
          sx={{ backgroundColor: mode === "light" ? "#fff" : theme.palette.background.default, position: "relative" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={setOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
              color: theme.palette.primary.main,
            }}
          >
            <Menu />
          </IconButton>
          <AppHeader lockNav={lockNav} />
        </Toolbar>
        <Fade in={isLoading} timeout={600} unmountOnExit>
          <LinearProgress />
        </Fade>
      </MyAppBar>

      <Box style={{ position: "relative" }}>
        <Drawer
          variant={"permanent"}
          open={open}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 0,
              backgroundColor: theme.palette.background.default,
            },

          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: '100%'
            }}
          >
            <Box>
              <DrawerHeader>
                {open
                  ? <img src={mode === "light" ? logo_2 : logo_2_alt} width={'120px'} />
                  : <div />
                }
                <IconButton onClick={setClose}>
                  {!open ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
              </DrawerHeader>
              <NavList lockNav={lockNav} />
            </Box>
            <Stack sx={{
              spacing: 0.5,
              p: 1,
              px: 3,
            }}>
              <Box>
                <NotionForm open={open} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant={"overline"} color={"text.disabled"}>
                  v{process.env.REACT_APP_VERSION}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Drawer>
      </Box>
    </Box>
  )
};




export default Navigation;
