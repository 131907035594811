import {
  Bolt,
  Campaign,
  Error,
  ErrorOutline,
  ImportExport,
  RemoveShoppingCart,
  Sync, Troubleshoot,
  WarningAmberRounded
} from "@mui/icons-material";
import React from "react";
import { Alert, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import {
  AppNotifData,
  AppNotification,
  AppNotificationType,
  EventCompanySource,
  FeedDropData,
  FeedImportEndData,
  FeedSyncData,
  LimitedCampaigns,
  LogType,
  MerchantCenterData,
  MerchantSeverityMap,
  NoActiveCampaigns,
  PaymentMissing
} from "../../../../../app/slices/app_notifications";
import { CompanyLogo } from "../../../Blocks/Misc";
import { Company } from "../../../../../app/types/campaign";
import Urls from "../../../../../assets/js/Urls";
import { getSeverityByType } from "../appNotificationsUtils";

export const getNotificationDetail = (p: AppNotification) => {
  const { type, data: baseData } = p;

  let data: AppNotifData<any>;

  let detail, redirection = "";

  let severity = p.severity ?? (
    type !== LogType.MERCHANT_CENTER
      ? getSeverityByType(type)
      : MerchantSeverityMap[(baseData as MerchantCenterData)?.severity ?? 2]
  );

  let icon = IconMapping[type];

  switch (type) {
    case LogType.FEED_IMPORT_END:
      data = baseData as AppNotifData<FeedImportEndData>;

      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"notif-imported-products"} values={{ nb: data.imported }}/> <br/>
        </Typography>
      )
      break;

    case LogType.FEED_SYNC_END:
      data = baseData as AppNotifData<FeedSyncData>;
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"feed-import-sync"}/>
        </Typography>
      )
      break;

    case LogType.FEED_IMPORT_DROP:
      data = baseData as AppNotifData<FeedDropData>;
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"feed_import_drop-desc"} values={{ previous: data.previous, current: data.current }}/>
        </Typography>
      );
      break;

    case LogType.ACCOUNT_PAYMENT_MISSING:
      data = baseData as AppNotifData<PaymentMissing>;
      icon = <CompanyLogo size={"icon"} company={EventCompanySource[data?.source] as Company}/>;
      redirection = Urls.configuration(baseData?.feed as number);
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"account_payment_missing-desc"} values={{b: (txt) => <b>{txt}</b>}}/>
        </Typography>
      );
      break;

    case LogType.NO_ACTIVE_CAMPAIGNS:
      data = baseData as AppNotifData<NoActiveCampaigns>;
      detail = <React.Fragment>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"no_active_campaigns-desc"}/>
        </Typography>
      </React.Fragment>;
      break;

    case LogType.LIMITED_CAMPAIGNS:
      data = baseData as AppNotifData<LimitedCampaigns>;
      detail = <React.Fragment>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"limited-campaigns-desc"}/>
        </Typography>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: 0.5, ml: 1.5 }}>
          <CompanyLogo size={"icon"} company={EventCompanySource[data?.source] as Company}/>
          <Stack>
            {data.campaigns.map((c: string) => <Typography ml={1}>{c}</Typography>)}
          </Stack>
        </Stack>
      </React.Fragment>;
      break;

    case LogType.FEED_MISSING_URL:
      redirection = Urls.configuration(baseData?.feed as number);
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={'missing-import-method'}/>
        </Typography>
      )
      break;

    case LogType.FEED_DOWNLOAD_ERROR:
      data = baseData as AppNotifData<{}>;
      detail = (
        <React.Fragment>
          <Typography variant={"body2"} color={"text.secondary"} mb={1}>
            <FormattedMessage id={'feed-download-error'}/>
          </Typography>
          <Alert severity={"error"} sx={{ wordWrap: "break-word", lineHeight: 2 }}>
            {data?.message}
          </Alert>
        </React.Fragment>
      )
      break;

    case LogType.MERCHANT_CENTER:
      data = baseData as MerchantCenterData;
      if (data.title === "Pending initial review") {
        severity = "info";
      }
      icon = severity === "error"
        ? <ErrorOutline fontSize={"large"} color={"error"}/>
        : severity === "warning" ?
          <WarningAmberRounded fontSize={"large"} color={"warning"}/>
          : <Sync fontSize="large" color="info"/>
      detail = (
        <React.Fragment>
          <Typography variant={"body2"}>
            {data.title}
          </Typography>
          <Typography variant={"body2"} color={"text.secondary"}>
            {data.detail}
          </Typography>
        </React.Fragment>
      )
      break;

    case LogType.CAMPAIGN_NO_CONVERSIONS:
    case LogType.CAMPAIGN_LOW_ROI:
      redirection = Urls.campaigns.list2(baseData?.feed as number);
      icon = <WarningAmberRounded fontSize={"large"} color={"warning"}/>;
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={`logtype-${LogType.CAMPAIGN_NO_CONVERSIONS}-desc`} />
        </Typography>
      )
      break;

    case LogType.NO_TRACKING:
      Urls.configuration(baseData?.feed as number);
      icon = <Troubleshoot fontSize={"large"} color={"error"} />
      detail = (
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={`logtype-${LogType.NO_TRACKING}-desc`} />
        </Typography>
      )
      break;
  }

  return { icon, detail, redirection, severity, type };
};


//@ts-ignore
const IconMapping: Record<AppNotificationType, JSX.Element | undefined> = {
  [LogType.FEED_IMPORT_END]: <Bolt color="info"/>,
  [LogType.FEED_SYNC_END]: <Sync color="info"/>,
  [LogType.FEED_IMPORT_DROP]: <Error color="error"/>,
  [LogType.ACCOUNT_PAYMENT_MISSING]: undefined, // Assigned dynamically
  [LogType.NO_ACTIVE_CAMPAIGNS]: <Campaign color="warning"/>,
  [LogType.LIMITED_CAMPAIGNS]: <WarningAmberRounded color="warning"/>,
  [LogType.FEED_DOWNLOAD_ERROR]: <ImportExport color="error"/>,
  [LogType.FEED_MISSING_URL]: <RemoveShoppingCart color={"warning"}/>
};
