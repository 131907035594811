import { useAppDispatch } from "../../../app/hooks";
import feedApi from "../../../app/services/secured/feed";
import { useDrop } from "react-dnd";
import { Box, Button, Fade, ListItemIcon, ListItemText, MenuItem, Paper, Typography } from "@mui/material";
import { BasicMenu, Icon } from "../../App/Blocks/Misc";
import { AddBusiness, AddCircle, EditOutlined, MoreHoriz } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { FormattedMessage } from "react-intl";
import { modalShowProject } from "../../../app/slices/modal";
import React, { useEffect } from "react";
import { FeedBlock } from "./FeedBlock";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ProjectType } from "../../../app/types/feed";

export const Project = ({ name, id }: ProjectType) => {

  const dispatch = useAppDispatch();
  const feeds = feedApi.useGetFeedsQuery();
  const projectFeeds = feeds.data?.filter(f => f.project?.id === id);

  //@ts-ignore
  const [ { canDrop, isOver, getItem }, drop ] = useDrop(() => ({
    accept: "feed",
    drop: () => ({
      project: id
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      getItem: monitor.getItem(),
    })
  }));

  const showHover = isOver && (getItem as any).currProject !== id;
  const isDragging = getItem !== null;

  return (
    <Paper
      sx={{
        mb: 3,
        py: 2,
        px: 3,
        position: "relative",
        //@ts-ignore
        background: (theme) => showHover ? theme.palette.gradient.main : undefined,
        zIndex: (theme) => isDragging ? theme.zIndex.drawer + 2 : undefined,
      }}
      ref={drop}
      className={"project"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Typography variant={"h5"}>
          {name}
        </Typography>

        <BasicMenu
          label={<MoreHoriz fontSize={"large"}/>}
        >
          <MenuItem
            component={Link}
            to={Urls.projects.newFeed(id)}
          >
            <ListItemIcon>
              <AddCircle/>
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id={"add-feed"}/>
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => dispatch(modalShowProject({ project: id, name }))}
          >
            <ListItemIcon>
              <EditOutlined/>
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id={"edit-project"}/>
            </ListItemText>
          </MenuItem>
        </BasicMenu>
      </Box>
      <Grid2 container spacing={2}>
        {projectFeeds?.length || 0 > 0
          ? projectFeeds?.map(f => (
            <FeedBlock
              key={`f-${f.id}`}
              {...f}
            />
          )) : null
        }
        <Fade in={showHover} unmountOnExit>
          <Grid2 xs={6}>
            <Box sx={{ height: '90px' }} className={"feed-block-project-wrapper project-shop rounded border-light"}/>
          </Grid2>
        </Fade>
        <Grid2 xs={12}>
          <Button
            component={Link as any}
            to={Urls.projects.newFeed(id)}
            startIcon={<AddBusiness/>}
            size={"large"}
            variant={"outlined"}
            // sx={{
            //   borderColor: "rgb(224, 224, 244)",
            // }}
          >
            <FormattedMessage id={"create-first-feed"}/>
          </Button>
        </Grid2>
      </Grid2>
    </Paper>
  )
}
