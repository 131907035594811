import React, { ElementType, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BootstrapVariants, Icon, LoadingDiv } from "./Misc";

import lvl1 from '../../../assets/img/personas/avatar-1-round.svg';
import lvl2 from '../../../assets/img/personas/avatar-2-round.svg';
import lvl3 from '../../../assets/img/personas/avatar-3-round.svg';
import lvl4 from '../../../assets/img/personas/avatar-4-round.svg';

import { FormattedMessage } from "react-intl";
import { Box, Paper, SxProps, Theme, Typography } from "@mui/material";

type FCardProps = {
  children: ReactNode | ReactNode[],
  className?: string,
  onClick?: () => void,
  variant?: 'classic' | 'no-padding',
  style?: object,
  ref?: any,
  sx?: SxProps<Theme>,
  clickable?: boolean,
}

const FCard = ({
  children,
  className,
  onClick,
  variant,
  style,
  ref,
  sx,
  clickable,
}: FCardProps) => {
  const isDataCard = className?.includes("data-card");
  return <Paper
    ref={ref}
    className={`f-card ${clickable && 'box-shadow-blue'} ` + className}
    sx={
      {
        ...sx,
        "&:hover": clickable ? {
          borderColor: "info.light",
          transition: "all 1s",
        } : {}
      }
    }
    onClick={onClick}
    style={style}
  >
    <Box
      height={'100%'}
      p={variant === "no-padding" ? 0 : isDataCard ? 1 : 2.5}
      px={isDataCard ? 2 : undefined}
      py={isDataCard ? 1.5 : undefined}
    >
      {children}
    </Box>
  </Paper>
}

type FCardHeaderProps = {
  icon?: string | ReactNode,
  title: string,
  desc?: string | ReactNode,
  className?: string,
  size?: "small",
  margin?: boolean,
  action?: ReactNode,
  alt?: boolean,
  sx?: SxProps,
}

export const FCardHeader = ({
  icon, title, desc, className, size, margin = false, action, alt = false, sx
}: FCardHeaderProps) => {
  return (
    <Box
      mb={0.5}
      mt={margin ? 3 : 0}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      className={`${className}`}
      width={'100%'}
      sx={sx}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
      >
        {!alt && icon}
        <Box ml={!alt ? 1 : 0}>
          <Typography
            variant={!size ? 'h6' : 'h6'}
            lineHeight={1}
          >
            <FormattedMessage id={title}/>
          </Typography>
          {desc && (
            typeof desc === "string"
              ? (
                <Typography variant={"caption"} color={"text.secondary"}>
                  <FormattedMessage id={desc}/>
                </Typography>
              )
              : desc
          )}
        </Box>
      </Box>
      <Box>
        {alt && icon}
      </Box>
      {action}
    </Box>
  )
}

type DataCardProps = {
  icon: string | ReactNode,
  label: string | JSX.Element,
  value: number | string | undefined | JSX.Element,
  className?: string,
  selected?: boolean,
  clickable?: boolean,
  onClick?: () => void,
  variant?: BootstrapVariants,
  size?: "sm",
  loading?: boolean,
  noCard?: boolean,
  align?: "left",
}

export const DataCard = ({
  icon, label, value, className, selected, clickable, onClick, variant, size, noCard, align
}: DataCardProps) => {

  let c = 'data-card ' + className;
  selected && (c += ' selected');
  clickable && (c += ' cursor-pointer');

  const content = (
    <Box display={'flex'} justifyContent={align ? "start" : "space-between"} alignItems={"center"} gap={2}>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        {typeof icon === "string" ? <Icon icon={icon} className={`text-${variant}`}/> : icon}
      </Box>
      <Box
        display={"flex"}
        alignItems={align ? "start" : "end"}
        justifyContent={"end"}
        flexDirection={"column"}
      >
        <Typography variant={size !== "sm" ? "body2" : "caption"} lineHeight={1}
                    color={"text.secondary"}>{label}</Typography>
        <Typography variant={size !== "sm" ? "h5" : "body1"} m={0}>{value}</Typography>
      </Box>
    </Box>
  );

  return noCard
    ? (
      <React.Fragment>
        {content}
      </React.Fragment>
    )
    : (
      <FCard
        className={c}
        onClick={onClick}
        sx={{
          height: '100%',
        }}
      >
        {content}
      </FCard>
    )
}

export default FCard;
