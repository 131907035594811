import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import React, { useEffect } from "react";
import channelsApi from "../../../../app/services/secured/channels";
import { FormattedMessage } from "react-intl";
import FCard from "../../../App/Blocks/Card";
import { adsCompanies, providerToCompany } from "../../../../app/slices/campaign/constants";
import { capitalizeFirstLetter, getLocaleDate } from "../../../../app/utils";
import { CheckCircle, NoAccountsOutlined, NotInterested } from "@mui/icons-material";
import { AvailableCompany } from "../../../../app/types/campaign";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  closeModal,
  getModalData,
  isModalOpened,
  modalShowRevokeChannel,
  ModalTypes,
  RevokeChannelProps
} from "../../../../app/slices/modal";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "../../../App/Blocks/Misc";

export default () => {
  return (
    <Box>
      <FCard variant={"no-padding"}>
        <AccountsTable/>
      </FCard>
      <ConfirmRevokeChannel />
    </Box>
  )
}

const AccountsTable = () => {
  const dispatch = useAppDispatch();
  const channelCredentialsQuery = channelsApi.useGetChannelsCredentialsQuery();
  const credentials = channelCredentialsQuery.data ?? [];
  const companiesCanInit: Array<AvailableCompany> = [ 'google', 'meta' ];
  return (
    <Table className={"no-border-last-row"}>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id={'channel'}/>
          </TableCell>
          <TableCell>
            <FormattedMessage id={'active'}/>
          </TableCell>
          <TableCell>
            <FormattedMessage id={'last-check'}/>
          </TableCell>
          <TableCell sx={{ width: '10%' }}/>
        </TableRow>
      </TableHead>
      <TableBody>
        {credentials.slice().sort((a, b) => a.id - b.id).map(credential => {
          const company = providerToCompany[credential.provider];
          const companyData = adsCompanies[company];
          return (
            <TableRow key={`credential-${credential.id}`}>
              <TableCell>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <img src={companyData.image} height={'25px'}/>
                  {capitalizeFirstLetter(company)}
                </Box>
              </TableCell>
              <TableCell>
                {credential.last_check_valid ? <CheckCircle color={"success"}/> : <NoAccountsOutlined color={"error"}/>}
              </TableCell>
              <TableCell>
                {credential.last_check ? getLocaleDate(new Date(credential.last_check * 1000).toDateString(), true) : null}
              </TableCell>
              <TableCell align={"right"}>
                {companiesCanInit.includes(company) && (
                  <Button
                    variant={"outlined"}
                    fullWidth
                    size={"small"}
                    startIcon={<NotInterested />}
                    onClick={() => dispatch(modalShowRevokeChannel({id: credential.id}))}
                  >
                    <FormattedMessage id={'uninstall-app'}/>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const ConfirmRevokeChannel = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.RevokeChannel));
  const data = useAppSelector<RevokeChannelProps | null>(getModalData);
  const close = () => dispatch(closeModal());

  const [ revoke, revokeRes ] = channelsApi.useRevokeCredentialMutation();

  const confirmRevoke = () => {
    if (data) {
      revoke({ id: data.id })
    }
  }

  useEffect(() => {
    if (revokeRes.isSuccess) {
      close();
      dispatch(NewSuccessNotification("success-revoke-channel"));
    }
  }, [ revokeRes.isSuccess ]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        <FormattedMessage id={'sure-revoke'} />
      </DialogTitle>
      <DialogContent dividers>
        <FormattedMessage id={'sure-revoke-desc'} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <FormattedMessage id={'close'} />
        </Button>
        <LoadingButton
          variant={"contained"}
          color={"error"}
          onClick={confirmRevoke}
          endIcon={<NotInterested />}
          data={revokeRes}
        >
          <FormattedMessage id={'confirm-revoke'} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
