import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../app/utils";
import {
  closeModal,
  getModalData,
  GoogleErrorModalData,
  isModalOpened,
  ModalTypes
} from "../../../../app/slices/modal";
import productsApi from "../../../../app/services/secured/products";
import React, { useEffect, useMemo } from "react";
import { LoadingBlock, SkeletonLoader } from "../../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import { Box, Dialog, DialogContent, DialogTitle, Fade, Typography } from "@mui/material";
import { ErrorsCorrection } from "./ErrorsCorrection";

export default () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const show = useAppSelector(isModalOpened(ModalTypes.ProductCorrection));
  const data = useAppSelector<GoogleErrorModalData>(getModalData);

  const [ getProducts, products ] = productsApi.useLazyGetProductsFromErrorQuery();
  const productsMemo = useMemo(() => products.data ?? [], [ products.data ]);

  useEffect(() => {
    if (show && data?.errorId) {
      getProducts({ feed, error_code: data.errorId }, true);
    }
  }, [ show, data ]);

  const hide = () => dispatch(closeModal());

  return (
    <Dialog open={show} onClose={hide} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        <FormattedMessage id={data?.header ?? "unknown"}/>
        <Typography variant={"body2"}>
          <FormattedMessage id={data?.desc ?? "unknown"}/>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Fade timeout={2000} in={true} unmountOnExit={true} appear={true}>
          <Box style={!show ? { filter: 'blur(4px)' } : {}}>
            <SkeletonLoader load={products.isLoading || products.isFetching}>
              <ErrorsCorrection
                products={productsMemo}
                field={data?.field}
                errorId={data?.errorId}
                company={data?.company}
              />
            </SkeletonLoader>
          </Box>
        </Fade>
      </DialogContent>
    </Dialog>
  )
}
