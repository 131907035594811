import React from "react";
import { Box, Paper, Stack, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { getProduct, getProductData } from "../../../../app/slices/product";

const Market = () => {

  const product = useAppSelector(getProduct);
  const data = useAppSelector(getProductData);

  const price_report = product?.price_report ?? null;

  return (
    <Box>
      <Stack spacing={3}>
        <Box>
          <Typography variant={"h5"}>
            Reporting - Google
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Box>
        <Box>
          <Typography variant={"h5"} mb={1}>
            Benchmark
          </Typography>
          <Box sx={{
            display:"flex",
            gap: 1
          }}>
            <Paper sx={{
              p: 2,
            }}>
              <Typography variant={"h6"}>
                {price_report?.benchmark_price} €
              </Typography>
              <Typography>
                Prix du marché
              </Typography>
            </Paper>
            <Paper sx={{
              p: 2,
            }}>
              <Typography variant={"h6"}>
                {data?.price}
              </Typography>
              <Typography>
                Votre prix
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default Market;
