import { CampaignSlice, CampaignType } from "../../types/campaign";

export const initialState: CampaignSlice = {
  id: null,
  migrate: false,
  is_feedcast: true,
  type: CampaignType.NULL,
  name: "",
  budget: 0,
  enabled: false,
  restrictions: [],
  filters: {
    location: [],
    language: [],
  },
  target_cpc: 0,
  bidding_strategy: 0,

  // Shopping Classic
  adgroup: {
    id: null,
    name: '',
    target_cpc: null,
    product_group: {
      is_included: true,
      data: {}
    }
  },

  // Performance Max
  asset_group: {
    id: null,
    name: '',
    product_group: {
      is_included: true,
      data: {}
    },
    assets: {
      business_name: '',
      headlines: [],
      long_headlines: [],
      descriptions: [],
      marketing_images: [],
      marketing_images_portrait: [],
      marketing_images_square: [],
      logos: [],
      youtube_ids: [],
      warnings: [],
    },
  },

  //Meta Classic
  adsets: [ {
    id: null,
    name: "",

    dsa_payor: "",
    dsa_beneficiary: "",

    target_countries: [ "FR" ],
    age_min: 18,
    age_max: 65,
    platforms: [ "facebook", "audience_network", "messenger", "instagram" ],
    genders: [ 1, 2 ], // male & female by default,
    behaviours: [],
    interests: [],

    daily_budget: 0,

    // default : Prospection
    events: [ {
      type: "Purchase",
      is_included: false,
      days: 31,
    } ],

    ads: [ {
      id: null,
      name: "",
      creative: {
        name: "",
        page: "",
        link: "",
      },
    } ]
  } ],

  // Warmup
  budget_google: null,
  budget_facebook: null,
  page_id: null,
  target_countries: [],

  // Bing Shopping Classique
  adgroup_bing: {
    name: "",
    product_partition: {
      is_included: true,
      data: {}
    },
  }
};
