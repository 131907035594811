import { HasLoading } from "./PayToday";
import { Box, CircularProgress, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { SubscriptionDiscount } from "../../../../../app/types/feed";

interface PayNextProps extends HasLoading {
  total: number | undefined,
  dateNext: string | null,
  toPayNext: number,
  discounts?: SubscriptionDiscount[],
  packId: number,
}

export const PayNext = ({ dateNext, isLoading, total, toPayNext, discounts, packId }: PayNextProps) => {
  const hasDiscount = (discounts && (discounts?.length > 0) && (discounts.findIndex(d => d.product === packId) > -1));
  const totalPrice = ((toPayNext ?? total) ?? 0);
  const finalPrice = hasDiscount
    ? discounts.reduce((acc, discount) => {
      if (discount.product === packId) {
        acc -= discount.amount;
      }
      return acc;
    }, totalPrice)
    : totalPrice
  ;
  return (
    <Box>
      <Typography color={"text.secondary"} lineHeight={1}>
        <FormattedMessage id={"amount-next-invoice"}/>
      </Typography>
      <Typography variant={"caption"}>
        {dateNext}
      </Typography>
      <Box mt={1}>
        {!isLoading
          ? (
            <Box>
              <Typography
                variant={hasDiscount ? "h4" : "h3"}
                lineHeight={1}
                sx={{
                  textDecoration: hasDiscount ? "line-through" : undefined,
                }}
                color={hasDiscount ? "text.secondary" : undefined}
              >
                {totalPrice.toFixed(2)} €
              </Typography>
              {hasDiscount && (
                <Typography
                  variant={"h3"}
                >
                  {finalPrice.toFixed(2)} €
                </Typography>
              )}
            </Box>
          ) : (
            <Box><CircularProgress size={"1.5rem"}/></Box>
          )}
        <Typography color={"text.secondary"} variant={"caption"}><FormattedMessage id={"ht-month"}/></Typography>
      </Box>
    </Box>
  )
}
