import { Stack } from "@mui/material";
import AppSecured from "../../App/AppSecured/AppSecured";
import ProductFeed from "./Feed/ProductFeed";
import Channels from "./Channels/Channels";
import React from "react";

const Configuration = () => {
  return (
    <AppSecured>
      <Stack spacing={2}>
        <ProductFeed/>
        <Channels/>
      </Stack>
    </AppSecured>
  )
}

export default Configuration;
