import React from "react";
import { Input } from "./Input";

export type InputsProps = {
  type: "long_headlines" | "headlines" | "descriptions",
  placeholder: string,
  onChange?: (newData: Array<string>) => void,
  data?: Array<string>,
  size?: 'lg',
  length: {
    min?: number,
    max: number,
  }
  nb: {
    min: number,
    max: number,
    start?: number,
  }
}

export function InputsHolder(props: InputsProps) {
  const components: Array<any> = [];
  for (let i = props.nb.start ?? 0; i < props.nb.max; i++) {
    components.push(
      <Input
        i={i}
        key={`input-${props.type}-${i}`}
        size={props.size}
        length={props.length}
        placeholder={props.placeholder}
        type={props.type}
        isRequired={i < props.nb.min}
      />
    )
  }
  return components;
}

export const Inputs = (props: Array<any>) => {
  return <>{props}</>
}

