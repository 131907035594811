import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { ReduxRtkApiResult } from "../../../app/types";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactChildren } from "../../../../types";
import { CSSTransition } from "react-transition-group";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAppLoading, getAppMode, setShowLoader } from "../../../app/slices/app";
import { Link as LinkRouter } from "react-router-dom";
import persona_computer from "../../../assets/img/personas/persona_computer.svg";
import { FullKpi } from "../../../app/slices/reporting";
import { ReportHeaderKeys, ReportKeysBudget } from "../../../app/slices/campaigns";
import FCard from "./Card";
import persona_bg from "../../../assets/img/personas/persona-campaigns-2.svg";
import persona_perf from "../../../assets/img/personas/persona-performances.svg";
import persona_coffee from "../../../assets/img/personas/persona_coffee.svg";
import persona_woman from "../../../assets/img/personas/persona_woman.svg";
import img_fc from "../../../assets/img/feedcast_alt.svg";
import {
  Alert as AlertMUI,
  AlertColor,
  AlertTitle,
  Box,
  Button, ButtonProps,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ArrowBack, CheckCircle, CloudOff, HideImageOutlined, RemoveRedEye } from "@mui/icons-material";
import AppTour, { AppTourPage } from "../AppTour";
import { adsCampaigns, adsCompanies, campaignsType } from "../../../app/slices/campaign/constants";
import { CampaignTypeIdentifier, Company } from "../../../app/types/campaign";
import { useProductCategory } from "../../../app/hooks/useCategories";

export type BootstrapVariants =
  "primary"
  | "secondary"
  | "warning"
  | "danger"
  | "info"
  | "success"
  | "light"
  | "outline-danger"
  | "outline-warning"
  | "outline-success"
  | "blue"
  | "yellow-800"
  | "green-800"
  ;

export const Icon = ({ icon, className }: { icon: string, className?: string }) => {
  return <i className={`icon bi bi-${icon} ` + className}/>
}

type InputGroupIconProps = {
  children: ReactElement<any, any> | ReactElement<any, any>[],
  icon: string,
  className?: string,
  isInvalid?: boolean,
  size?: "lg" | "sm",
}

export const InputGroupIcon = ({ children, icon, className, isInvalid, size }: InputGroupIconProps) => {

  const [ focus, setFocus ] = useState(false);

  let c = 'input-group-icon ' + className;
  isInvalid && (c += ' is-invalid');
  focus && (c += " focus");

  return <>
    {/*<InputGroup*/}
    {/*    size={size}*/}
    {/*    hasValidation*/}
    {/*    className={c}*/}
    {/*    onFocus={(e) => setFocus(true)}*/}
    {/*    onBlur={() => setFocus(false)}*/}
    {/*>*/}
    {/*    <InputGroup.Text><Icon icon={icon}/></InputGroup.Text>*/}
    {/*    {children}*/}
    {/*</InputGroup>*/}

    <FormControl fullWidth>
      {children}
    </FormControl>

    {/*<Box sx={{ display: 'flex', alignItems: 'center' }} className={'mb-2'}>*/}
    {/*    <Icon icon={icon} className={'me-2 fs-16'} />*/}
    {/*    <TextField fullWidth />*/}
    {/*</Box>*/}
  </>
}

export type MUIColor = "primary" | "secondary" | "warning" | "info" | "success" | "error";

interface DataBlockButtonProps extends ButtonProps {
  data: ReduxRtkApiResult,
  children?: any,
  type?: "submit" | "button",
  // variant?: BootstrapVariants,
  variant?: "outlined" | "contained",
  className?: string,
  onClick?: any,
  icon?: string | ReactNode
  disabled?: boolean,
  size?: "small" | "large",
  redirect?: string
  style?: object,
  reset?: boolean,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  color?: MUIColor,
  sx?: SxProps<Theme>,
}

// LoadingButton : Button qui affiche un loader tant que le data load
export const LoadingButton: React.FC<DataBlockButtonProps> = (props) => {
  const load = props.data.isLoading || props.data.isFetching;
  return <Button
    {...props}
    type={props.type}
    variant={props.variant}
    className={props.className}
    onClick={props.onClick}
    disabled={props.disabled || (props.data.isLoading || props.data.isFetching)}
    size={props.size}
    style={props.style ? props.style : {}}
    startIcon={load ? <CircularProgress size={"1rem"}/> : props.startIcon}
    endIcon={props.endIcon}
    color={props.color}
    sx={props.sx}
  >
    {
      props.data.isLoading || props.data.isFetching
        ? <FormattedMessage id="pleaseWait"/>
        : props.data.isSuccess && !props.reset
          ? <>
            <CheckCircle fontSize={"small"}/>
          </>
          : props.children
    }
  </Button>
}

type DataBlockProps = {
  data: ReduxRtkApiResult | boolean,
  children: any,
  variant?: BootstrapVariants,
}

export const LoadingBlock: React.FC<DataBlockProps> = ({ data, children, variant }) => {
  const minTime = 0;
  const [ isMin, setIsMin ] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsMin(true), minTime);
  });

  return <>
    {typeof data === "boolean"
      ? !data && isMin
        ? <LoadingDiv variant={variant}/>
        : children
      : (data.isLoading || data.isFetching || !isMin)
        //@ts-ignore
        ? <LoadingDiv variant={variant}/>
        : data.isSuccess
          ? children
          : data.isError && "Erreur"
    }
  </>
};

type LoadingDivProps = {
  variant?: BootstrapVariants,
  message?: string,
  center?: boolean,
  className?: string,
  size?: "sm" | "lg",
  sx?: SxProps,
}

export const LoadingDiv = ({ variant, message, center = false, className, size = "sm", sx }: LoadingDivProps) => {
  return <Box
    sx={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      ...sx
    }}
    className={`${className}`}
    style={center ? { height: "75vh" } : {}}>
    <CircularProgress size={25}/>
  </Box>
}

type LoadingRowProps = {
  query?: ReduxRtkApiResult,
  colspan: number,
  hasData: boolean,
  children: ReactNode | ReactNode[],
  message?: string,
}

export const TableLoader = ({ query, colspan, hasData, children, message }: LoadingRowProps) => {
  return <>
    {query?.isLoading || query?.isFetching
      ? <tr>
        <td colSpan={colspan} className={"text-center"}>
          <div className={'mb-4 lt-4 text-center w-100'}>
            <LoadingDiv/>
            {message && <small><FormattedMessage id={message}/></small>}
          </div>
        </td>
      </tr>
      : query?.isSuccess && hasData ? children : null
    }
    {!hasData && <tr>
      <td colSpan={colspan} className={"text-center fs-24 no-data-cell"}><Icon icon={"cloud-slash"}/></td>
    </tr>}
  </>
}

export const UnavailableBlur = (p: { variant?: "dark" }) => {
  const appMode = useAppSelector(getAppMode);

  return <div className={`unavailable ${p.variant} ${appMode === "dark" && "dark"}`}/>
};

type BackButtonProps = {
  href?: string,
  onClick?: () => void,
  size?: 'lg' | 'sm',
  disabled?: boolean,
}

export const BackButton = ({ href, onClick, size, disabled = false }: BackButtonProps) => {
  return (
    <IconButton
      component={LinkRouter}
      to={(!disabled && href) ? href : ''}
      onClick={(!disabled && onClick) ? () => onClick() : () => {
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ArrowBack sx={{ fontSize: (!size || size === "lg") ? "2.5rem" : "2rem" }}/>
    </IconButton>
  )
}

export const Subtitle = ({ children, hr = true, size }: ReactChildren & { hr?: boolean, size?: "sm" }) => {
  return <Box>
    <Typography variant={"body2"} color={"text.secondary"}>{children}</Typography>
  </Box>
}

export const MyFormGroup = ({
  label,
  size,
  children,
  icon,
  className,
}: { label?: string | ReactNode, size: number, icon?: string, className?: string } & ReactChildren) => {
  return <Grid2 xs={size}>
    <FormControl fullWidth>
      {children}
    </FormControl>
  </Grid2>
}

export const ColoredBadge = ({ children, display }: ReactChildren & { display: boolean }) => {
  return <div className={'position-relative'}>
    {children}
    {display && <>
            <span
              style={{
                top: '3px',
                right: '-16px',
                zIndex: '9',
              }}
              className="my-badge position-absolute translate-middle p-1 bg-danger rounded-circle"/>
    </>}
  </div>
}

export const DateFromTimestamp = (ts: number, options?: boolean, time?: boolean) => {
  let opt = {};
  if (options) {
    opt = { day: "2-digit", month: "short", year: "numeric" }
  }
  if (time) {
    opt = { ...opt, hour: "2-digit", minute: "2-digit" }
  }
  return new Date(ts * 1000).toLocaleDateString("fr-FR", opt);
}

type LoadingAnimationProps = {
  data: ReduxRtkApiResult | boolean,
  slide?: boolean,
} & ReactChildren;

export const LoadingAnimation = (props: LoadingAnimationProps) => {
  const node = useRef<HTMLDivElement>(null);
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    if (typeof props.data === "boolean") {
      props.data && setShow(true);
    } else {
      if (props.data.isLoading || props.data.isFetching) {
        setShow(false);
      } else if (props.data.isSuccess || props.data.isError) {
        setShow(true);
      }
    }
  }, [ props.data ])

  return <CSSTransition
    in={show}
    timeout={1000}
    classNames={props.slide ? "app-content-transition" : "app-transition"}
    nodeRef={node}
    unmountOnExit
  >
    <div ref={node}>
      {props.children}
    </div>
  </CSSTransition>
}

export const CompanyLogo = ({
  company, size, campaignType
}: { company?: Company | "feedcast", size?: 'lg' | 'sm' | 'icon', campaignType?: CampaignTypeIdentifier }) => {

  let width = '45px';
  switch (size) {
    case "icon":
      width = '15px';
      break;
    case "sm":
      width = "30px";
      break;
    case "lg":
      width = '100px';
      break;
  }

  const img =
    company !== "feedcast"
      ? campaignType
        ? adsCampaigns[campaignsType[campaignType]].logo
        : adsCompanies[company ?? "google"].image
      : img_fc;

  return <img src={img} style={{ width: width }}/>
}

export const useLoadingScreen = (data: ReduxRtkApiResult) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data.isLoading || data.isFetching) {
      dispatch(setShowLoader(true));
    } else if (data.isSuccess || data.isError) {
      dispatch(setShowLoader(false));
    }
  }, [ data ]);
}

export const LoadingScreen = () => {
  const load = useAppSelector(getAppLoading);
  return <>
    {load &&
      <div
        className={'d-flex align-items-center justify-content-center'}
        style={{
          position: 'absolute',
          top: '0',
          width: '100vw',
          left: '0',
          height: '100vh',
          background: 'rgba(255, 255, 255, 0.9)',
          zIndex: '9',
        }}
      >
        <LoadingAnimation data={load}>
          <LoadingDiv/>
        </LoadingAnimation>
      </div>}
  </>
}

export const BlurContent = ({ condition, children }: { condition: boolean } & ReactChildren) => {
  return <>
    <div style={condition ? { filter: 'blur(8px)', pointerEvents: 'none' } : {}}>
      {children}
    </div>
  </>
}

export const FMsg = (id: string) => {
  const intl = useIntl();
  return intl.formatMessage({ id });
}

type AlertPersonaProps = {
  title: string,
  desc: string,
  type?: BootstrapVariants,
  action?: ReactNode,
  variant?: "1" | "2",
  severity?: AlertColor,
}

export const AlertPersona = ({ title, desc, type, action, variant, severity }: AlertPersonaProps) => {

  let bg = persona_computer;
  switch (variant) {
    case "2":
      bg = persona_woman;
      break;
  }

  return <>
    <AlertMUI
      // variant={type}
      severity={severity ?? "info"}
      // className={`p-3 py-4 border bg-${type} ${type === "danger" && "text-white"}`}
      // style={{
      //   backgroundImage: `url(${bg})`,
      //   backgroundPosition: "top 40% right",
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "150px",
      //   minHeight: '130px',
      //   boxShadow: "none",
      // }}
      sx={{
        p: 2
      }}
    >
      {/*<div*/}
      {/*  style={{ width: '80%' }}*/}
      {/*>*/}
      {/*  <Alert.Heading*/}
      {/*    className={"fw-bold"}*/}
      {/*  ><FormattedMessage id={title}/></Alert.Heading>*/}
      {/*  <FormattedMessage id={desc}/>*/}
      {/*  <div>*/}
      {/*    {action}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <AlertTitle>
        <FormattedMessage id={title}/>
      </AlertTitle>
      <FormattedMessage id={desc}/>
    </AlertMUI>
  </>
}

type ReportingDataDropdownProps = {
  keys: ReportHeaderKeys,
  onToggle: (key: FullKpi) => void,
}

export const ReportingDataDropdown = ({ keys, onToggle }: ReportingDataDropdownProps) => {

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <Tooltip title={<FormattedMessage id={"show-columns"}/>}>
      <Box display={'flex'} alignItems={"center"}>
        <Box>
          <IconButton onClick={handleClick}>
            <RemoveRedEye/>
          </IconButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <ColumnsDisplayMenu keys={keys} onToggle={onToggle}/>
          </Menu>
        </Box>
      </Box>
    </Tooltip>
  </>
}

export function ColumnsDisplayMenu({ keys, onToggle }: ReportingDataDropdownProps) {
  return (
    <MenuList dense>
      {Object.keys(keys).map(k =>
        <MenuItem key={`kpi-${k}`}>
          <ListItemIcon>
            <Icon icon={keys[k as ReportKeysBudget].icon} className={"me-2"}/>
          </ListItemIcon>
          <FormControlLabel
            control={<Checkbox
              sx={{ padding: 0, px: 2 }}
              checked={keys[k as ReportKeysBudget].show}
              onChange={() => onToggle(k as FullKpi)}
            />}
            label={<FormattedMessage id={k}/>}
            labelPlacement={"start"}
            sx={{
              width: '100%',
              display: "flex",
              justifyContent: "space-between"
            }}
          />
        </MenuItem>)}
    </MenuList>
  )
}

export const Pastille = ({ type }: { type: BootstrapVariants }) => {
  return <>
        <span
          className={`bg-${type} rounded-circle badge text-white`}
          // style={{ margin: '0.35rem 0.5rem', zIndex: '9' }}
        >
            <span style={{ visibility: "hidden" }}>x</span>
        </span>
  </>
}

export const BadgeStatus = ({ fStatus, position }: { fStatus: string, position?: "top" }) => {
  let className = "my-badge position-absolute translate-middle rounded-circle ";
  let status;
  switch (fStatus) {
    case "free":
      status = { type: 'light', message: "free" };
      break;
    case "active":
      status = { type: 'success', message: "active" };
      break;
    case "trial":
      status = { type: 'warning', message: "trial" };
      break;
    case "canceled":
      status = { type: 'danger', message: "canceled" };
      break;
  }
  className += `bg-${status?.type}`;

  return <span
    style={{
      right: '-7px',
      bottom: !position ? '-7px' : '',
      top: position === "top" ? "" : "",
      padding: "0.5rem"
    }}
    className={className}/>
}

type FCardPersonaProps = {
  variant?: BootstrapVariants,
  children: any,
  minHeight?: string,
  version?: "1" | "2",
  className?: string,
}

export const FCardPersona = ({
  variant, children, minHeight = '40vh', version = "1", className
}: FCardPersonaProps) => {
  const bg = version === "1" ? persona_computer : persona_coffee;
  return <>
    <FCard
      style={{
        height: minHeight,
        background: `url('${bg}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "bottom right",
        // backgroundColor: '#fff',
      }}
      className={`bg-${variant} ${className}`}
    >
      {children}
    </FCard>
  </>
}

type NoDataBgProps = {
  variant: "performances" | "campaigns",
  children: any,
  position: "center" | "bottom-start",
  className?: string,
}

export const NoDataBg = ({ children, variant, position, className }: NoDataBgProps) => {
  let img = "";
  switch (variant) {
    case "performances":
      img = persona_perf;
      break;
    case "campaigns":
      img = persona_bg;
      break;
  }

  let flex = "";
  switch (position) {
    case "center":
      flex = "justify-content-center align-items-center";
      break;
    case "bottom-start":
      flex = "justify-content-end align-items-start";
      break;
  }

  return <Box
    sx={{
      display: "flex",
      alignItems: position === "center" ? "center" : "start",
      flexDirection: "column",
      justifyContent: position === "center" ? "center" : "end",
    }}
    style={{
      height: '40vh',
      // backgroundImage: `url("${img}")`,
      backgroundPosition: "bottom right",
      backgroundRepeat: "no-repeat",
      backgroundSize: variant === "performances" ? "80%" : "100%",
      borderRadius: "8px",
      // backgroundColor: '#FAFAFA'
    }}
  >
    {children}
  </Box>
}

type PageHeaderProps = {
  title: string,
  desc: string
}

export const PageHeader = ({ title, desc }: PageHeaderProps) => {
  return <React.Fragment/>
}

export const BasicMenu = ({ children, label }: { children: any, label: any }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Button
        size={"small"}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        ref={menuRef}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </div>
  );
}


type ImageLoaderProps = {
  src: string | undefined,
  height?: string,
}

export const ImageLoader = (p: ImageLoaderProps) => {
  const [ loaded, setLoaded ] = useState(false);
  const [ error, setError ] = useState(false);

  const handleLoaded = () => {
    setLoaded(true);
  }

  const onError = () => {
    setLoaded(true);
    setError(true);
  }

  useEffect(() => {
    if (!p.src) {
      setError(true);
    }
  }, [ p, p.src ]);

  return (
    <Box
      sx={{
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%',
        flex: 1,
      }}
    >
      {!loaded && !error && <Skeleton width={'100%'} height={300} sx={{ transform: "none" }} animation={"pulse"}/>}
      {error && (
        <Box>
          <HideImageOutlined color={"disabled"}/>
        </Box>)}
      <Fade in={loaded && !error} timeout={600}>
        <img
          src={p.src}
          alt={""}
          onLoad={handleLoaded}
          onError={onError}
          style={!loaded ? { display: "none" } : {
            width: p.height ? "auto" : "100%",
            height: p.height ? p.height : "auto",
            borderRadius: "6px",
          }}
        />
      </Fade>
    </Box>
  )
}

export function MakeCategoryName(category: number | undefined) {
  const cat = useProductCategory(category);
  const catSplit = cat?.title.split(">");
  return catSplit && catSplit?.filter(v => v).length ? <Box>
      <Typography variant={"caption"} color={'text.secondary'}>{catSplit[catSplit.length - 2]}</Typography>
      <Typography variant={"body2"}>{catSplit[catSplit.length - 1]}</Typography>
    </Box>
    : <CloudOff fontSize={"small"} color={"disabled"}/>;
}

export const useAppTour = (page: AppTourPage) => {
  return <React.Fragment>
    <AppTour/>
  </React.Fragment>
}

interface SkeletonLoaderProps {
  load: boolean,
  children: any,
  width?: string,
}

export const SkeletonLoader = (p: SkeletonLoaderProps) => {
  const [ showChildren, setShowChildren ] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!p.load) {
      timer = setTimeout(() => {
        setShowChildren(true);
      }, 50);
    } else {
      setShowChildren(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [ p.load ]);

  return (
    p.load
      ? (
        <Skeleton width={p.width ?? "100%"} sx={{ transformOrigin: "0 0", transform: "none" }}>
          {p.children}
        </Skeleton>
      )
      : (
        <Fade in={showChildren} timeout={500}>
          <Box width={'100%'}>
            {p.children}
          </Box>
        </Fade>
      )
  );
};

