import { useRef, useState } from 'react';

const useValueWithUnitManager = (
  initialValueWithUnit?: string,
  defaultUnit: string = '',
  updateData?: (valueWithUnit: string) => void
) => {
  const valueRef = useRef<HTMLInputElement>(null);

  let value = "", unit = defaultUnit;
  const valueSplit = initialValueWithUnit?.split(" ");
  if (valueSplit) {
    value = valueSplit[0];
    unit = valueSplit[1] ?? defaultUnit;
  }

  const [currentUnit, setCurrentUnit] = useState<string>(unit);

  const setValueWithUnit = (unit?: string) => {
    setCurrentUnit(unit ?? defaultUnit);
    const currentValue = valueRef.current ? valueRef.current?.value : '';
    const valueWithUnit = `${currentValue} ${unit ?? defaultUnit}`;
    updateData?.(valueWithUnit);
  };

  return {
    valueRef,
    currentUnit,
    setValueWithUnit,
    defaultValue: value
  };
};

export default useValueWithUnitManager;
