import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Alert, AlertTitle, Box, Button, Fade, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getCurrentFeed, getFeedIssues } from "../../../../app/slices/app";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import { Close, Feed, HelpOutline, ImportExport } from "@mui/icons-material";
import { ShopHeader } from "./ShopHeader";
import FeedImportMethod from "../../../Import/FeedImportMethod";
import { resetNewFeed } from "../../../../app/slices/newfeed";
import { useFeedDownloadError } from "../../../../app/hooks/customHooks";

import { HelpButton } from "../../../App/Helpdesk/HelpButton";

const ProductFeed = () => {

  const dispatch = useAppDispatch();

  const feed = useAppSelector(getCurrentFeed);
  const isFetchingNewProducts = useAppSelector(state => state.shop.pendingImport);
  const hasSrc = useAppSelector(state => state.shop.hasSource);

  const [ editImport, setEditImport ] = useState(false);

  const feedDownloadError = useFeedDownloadError();

  const toggleEditImport = (value?: boolean) => {
    const toggle = value ?? !editImport;
    setEditImport(toggle);
    if (!toggle) {
      setTimeout(() => dispatch(resetNewFeed()), 599);
    }
  }

  useEffect(() => {
    if (isFetchingNewProducts) {
      toggleEditImport(false);
    }
  }, [ isFetchingNewProducts ]);


  const memoSrc = useMemo(() => Boolean(hasSrc), [ hasSrc ]);
  useEffect(() => {
    setEditImport(!memoSrc)
  }, [ memoSrc ]);

  return (
    <Box>
      <Stack spacing={2}>
        <Box>
          <ShopHeader
            toggleImport={toggleEditImport}
          />
        </Box>
        <Box>
          {feedDownloadError.hasError && (
            <Alert severity={"error"}>
              <AlertTitle>
                <FormattedMessage id={'feed-download-error'}/>
              </AlertTitle>
              {feedDownloadError.message}
            </Alert>
          )}
        </Box>
        <Fade in={editImport} unmountOnExit>
          <Box>
            <FCardHeader
              margin={false}
              title={"import-method"}
              icon={<ImportExport/>}
              action={
                hasSrc
                  ? (
                    <Button
                      onClick={() => toggleEditImport()}
                      startIcon={<Close/>}>
                      <FormattedMessage id={'close'}/>
                    </Button>
                  )
                  : null
              }
            />
            <FCard>
              {feed && (
                <FeedImportMethod
                  newFeedWithinApp={true}
                  feedId={feed?.id}
                  project={feed?.project?.id}
                />
              )}
            </FCard>
          </Box>
        </Fade>
      </Stack>
    </Box>
  )
}


export default ProductFeed;

