import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";

export default function useJune() {
  const [ analytics, setAnalytics ] = useState<any>(undefined);
  const writeKey = process.env.REACT_APP_JUNE ?? "";

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  // useEffect(() => {
  //   if (analytics) {
  //     analytics.page();
  //   }
  // }, [ analytics ]);

  return analytics;
}
