import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { getAppMode } from "./slices/app";
import logo from "../assets/img/logo/fc_light.svg";
import logo_dark from "../assets/img/logo/fc_dark.svg";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppLogo = () => {
  const mode = useAppSelector(getAppMode);
  return mode === "light" ? logo : logo_dark;
}
