import React, { useEffect, useState } from "react";
import {
  Box,
  keyframes,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText, Paper,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-20px, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

interface StyledListItemProps extends ListItemProps {
  $shouldAnimate: boolean;
}

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== '$shouldAnimate',
})<StyledListItemProps>(({ theme, $shouldAnimate }) => ({
  opacity: 0,
  transform: 'translateY(-20px)',
  animation: $shouldAnimate ? `${fadeIn} 0.5s ease-out forwards` : 'none',
}));

export const InformationBlock = () => {

  const listItems = [
    "diffuse-easily",
    "optimise-catalog",
    "20-percent-cpc",
    "google-ads-credit",
    "free-trial-7days"
  ]

  const [ loadedIndexes, setLoadedIndexes ] = useState<number[]>([]);

  useEffect(() => {
    listItems.forEach((item, index) => {
      setTimeout(() => {
        setLoadedIndexes((prev) => [ ...prev, index ]);
      }, index * 100); // Adjust time here for each item's appearance delay
    });
  }, [ listItems ]);

  return (
    <Box sx={{ mt: 4, px: 6 }}>
      <Stack spacing={3}>
        <Box sx={{maxWidth:'300px'}}>
          <Typography variant={"h4"} fontWeight={400}>
            <FormattedMessage id={'handle-campaigns-everywhere'} />
          </Typography>
        </Box>
        <List disablePadding>
          {listItems.map((item, index) => (
            <StyledListItem
              disablePadding
              key={`item-${item}`}
              $shouldAnimate={loadedIndexes.includes(index)}
              sx={{
                mb: "0.5rem",
              }}
            >
              <ListItemIcon sx={{minWidth:'30px'}}>
                <Check/>
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  <FormattedMessage id={item}/>
                </Typography>
              </ListItemText>
            </StyledListItem>
          ))}
        </List>
      </Stack>
    </Box>
  )
}
