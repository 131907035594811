import { Box, Fade } from "@mui/material";
import React, { ReactNode } from "react";
import { AppTourPage } from "../AppTour";

export type TabComponent<T extends string> = Record<T, {
  title: ReactNode,
  tab: any,
  tour_page: AppTourPage | null,
  disabled?: boolean,
  link?: string,
  notification?: boolean,
  removePadding?: boolean,
}>

interface TabPanelProps {
  children?: React.ReactNode;
  index: string | number;
  value: string | number;
  removePadding?: boolean,
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, removePadding, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box pt={removePadding ? 0 : 2} style={value === index ? { display: "block" } : { display: "none" }}>
        <Fade in={value === index} appear>
          <Box>
            {children}
          </Box>
        </Fade>
      </Box>
    </div>
  );
}
