import { Box, Button, Link, Paper, Theme, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../app/hooks";
import { getLanguage } from "../../../app/slices/app";
import persona_1 from "../../../assets/personas/persona_1.svg";
import { helpdeskLinks, PageWithInfo } from "./helpdesk";
import React from "react";
import { FindInPageOutlined } from "@mui/icons-material";

interface PageInfoProps {
  page: PageWithInfo,
  small?: boolean,
}

const PageInfo = (p: PageInfoProps) => {
  const lang = useAppSelector(getLanguage);
  const background = (theme: Theme) => `${theme.palette.gradient?.main} ${!p.small ? `, url(${persona_1})` : ''}`;
  return (
    <Box>
      <Paper
        sx={{
          background,
          p: 3,
          py: !p.small ? 4 : 2,
          backgroundPosition: "right 0 top 20%",
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box sx={{ maxWidth: '75%' }}>
          {!p.small && (
            <Typography variant={"h6"}>
              <FormattedMessage id={`your-${p.page}`}/>
            </Typography>
          )}
          <Typography>
            <FormattedMessage
              id={`page-info-${p.page}`}
              values={{
                a: (txt) => (
                  <Box mt={1}>
                    <Button
                      ml={0.5}
                      size={"small"} variant={"contained"} component={Link} target={'_blank'}
                      href={helpdeskLinks[p.page][lang]}
                      endIcon={<FindInPageOutlined/>}
                    >
                      {txt}
                    </Button>
                  </Box>
                ),
              }}
            />
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default PageInfo;
