import { ReactChildren } from "../../../../types";
import React, { useRef } from "react";
import { Box, Button, Container, Fade, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Urls from "../../../assets/js/Urls";
import fc_light from "../../../assets/img/logo/fc_light.svg";
import fc_dark from "../../../assets/img/logo/fc_dark.svg";
import { Link as LinkDOM } from "react-router-dom";
import { InformationBlock } from "./InformationBlock";
import { useAppPublic } from "./UseAppPublic";
import { AppPublicActions } from "./AppPublicActions";
import Grid2 from "@mui/material/Unstable_Grid2";
import persona_bg from "../../../assets/personas/persona_1.svg";
import { GoogleLogin } from "@react-oauth/google";
import { useAppSelector } from "../../../app/hooks";
import { getLanguage } from "../../../app/slices/app";
import ChatBot from "../Chatbot";

export type AppPublicProps = {
  type?: 'large' | 'small',
  page: "login" | "register" | "refresh" | "password" | "oauth" | "validate";
} & ReactChildren;

export interface AppPublicActionsProps {
  params: URLSearchParams;
}

const FADE_TIMEOUT = 1300;

const AppPublic = ({ children, type = 'small', page }: AppPublicProps) => {

  const params = new URLSearchParams(window.location.search);
  const lang = useAppSelector(getLanguage);
  const { mode, onGoogleOAuth, onOAuthError, lessThanSmall } = useAppPublic({ page, params });
  const fullPage = page === "login" || page === "refresh" || page === "validate";

  return (
    <Box sx={{ height: '100vh' }}>
      <div id={'recaptcha'} />
      <Container
        maxWidth={"md"}
        sx={{
          height: '100%',
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: lessThanSmall ? 1 : 5,
        }}
      >
        <Box sx={{
          py: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: lessThanSmall ? "column" : "row",
          gap: 2,
        }}>
          <LinkDOM to={Urls.auth.login}>
            <img src={mode === "light" ? fc_light : fc_dark} height={"60px"}/>
          </LinkDOM>
          <AppPublicActions params={params}/>
        </Box>
        <Grid2 container sx={{ height: '100%', alignItems: "center" }}>
          {
            (page === "register" && !lessThanSmall) && (
              <Grid2 xs={7}>
                <Box
                  sx={{
                    minHeight: '50vh',
                    height: "100%",
                    position: 'relative',
                    background: (theme) => `${theme.palette.gradient.main}`, // Apply gradient to the main element
                    borderRadius: 2,
                    overflow: "hidden",
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${persona_bg})`,
                      // backgroundPosition: "bottom right -25px",
                      backgroundPosition: "bottom right -50px",
                      backgroundRepeat: "no-repeat",
                      opacity: 0, // Start transparent for the animation
                      animation: 'fade-in-bg 1s ease-in-out forwards', // Customize the animation duration and timing as needed
                    },
                    '@keyframes fade-in-bg': {
                      from: { opacity: 0, backgroundPosition: "bottom right -50px", },
                      to: { opacity: 1, backgroundPosition: "bottom right -30px", },
                    },
                  }}
                >
                  <Fade in={true} timeout={FADE_TIMEOUT} mountOnEnter>
                    <Box>
                      <Box>
                        <video autoPlay={true} width={'100%'} muted playsInline={true} loop={true}>
                          <source src={"./video_fc.mp4"} type={"video/mp4"}/>
                        </video>
                        <InformationBlock/>
                      </Box>
                    </Box>
                  </Fade>
                </Box>
              </Grid2>
            )
          }
          <Grid2 xs={(fullPage || lessThanSmall) ? 12 : 5}>
            <Fade in={true} timeout={FADE_TIMEOUT} mountOnEnter>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: fullPage ? "center" : "start",
                    flexDirection: "column",
                    px: 4,
                    py: lessThanSmall ? 0 : 4,
                    maxWidth: '400px',
                    textAlign: fullPage ? "center" : undefined
                  }}
                >

                  {page !== "validate" && (
                    <Box mb={3} mt={3}>
                      <Typography variant={"h4"} fontWeight={500} mb={1}>
                        <FormattedMessage id={page}/>
                      </Typography>
                      <Typography variant={"body2"} color={"text.secondary"}>
                        <FormattedMessage id={`${page}-desc`}/>
                      </Typography>
                      {page !== "refresh" && (
                        <Box mt={2} mb={1}>
                          {/*<Button*/}
                          {/*  fullWidth*/}
                          {/*  variant={"outlined"}*/}
                          {/*  sx={{*/}
                          {/*    "& .MuiSvgIcon-root": {}*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  <FormattedMessage id={'sign-in-google'}/>*/}
                          {/*</Button>*/}
                          <GoogleLogin
                            locale={lang}
                            size={"large"}
                            useOneTap
                            onSuccess={onGoogleOAuth}
                            onError={onOAuthError}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  <Stack spacing={2}>
                    {children}

                    {
                      page !== "validate" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <Button
                            component={LinkDOM}
                            to={
                              page === "login"
                                ? Urls.auth.register
                                : Urls.auth.login
                            }>
                            <FormattedMessage
                              id={page === "login" ? "register" : page === "register" ? "already-have-account" : "remember-password"}/>
                          </Button>
                          {page === "login" && (
                            <Button
                              component={LinkDOM} to={Urls.auth.refresh}
                            >
                              <FormattedMessage id={'forgotten-password'}/>
                            </Button>
                          )}
                        </Box>
                      )
                    }

                  </Stack>
                </Box>
              </Box>
            </Fade>
          </Grid2>
        </Grid2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 3
          }}
        >
          <Typography color={"text.secondary"}>
            Feedcast / 2024
          </Typography>
          <Typography color={"text.secondary"}>
            All rights reserved
          </Typography>
        </Box>

        <Box sx={{position:"absolute"}}>
          <ChatBot loadWithoutData={true}/>
        </Box>
      </Container>
    </Box>
  )
}

export default AppPublic;
