import React from 'react';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';
import appRouter from "./appRouter";
import { createRoot } from "react-dom/client";
import './assets/scss/index.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

/** GA4 */
const isDev = process.env.REACT_APP_IS_DEV === "1";
ReactGA.initialize("G-6E58S849ZH", {
  gaOptions: {
    debug_mode: isDev,
  },
  gtagOptions: {
    debug_mode: isDev,
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={appRouter}/>
    </Provider>
  </React.StrictMode>
);

