import { SubFormProps } from "../SubForm";
import { Fade, FormControl, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

type CouponCodeProps = {
  show: boolean,
  coupon: string | undefined,
  isAutopilot: boolean,
} & Pick<SubFormProps, "onCodeChange">
export const CouponCode = (p: CouponCodeProps) => {
  return p.show ? (
    <Stack spacing={1}>
      <Typography color={"text.secondary"} lineHeight={1}>
        <FormattedMessage id={"coupon-code"}/>
      </Typography>
      <FormControl sx={{ width: '50%' }}>
        <TextField
          size={"small"}
          value={p.coupon}
          sx={{
            minWidth: '75px'
          }}
          onChange={(e) => p.onCodeChange(e.target.value)}
        />
      </FormControl>
      <Fade unmountOnExit in={p.coupon === process.env.REACT_APP_COUPON && p.isAutopilot}>
        <Typography variant={"caption"} color={"text.secondary"}>
          <FormattedMessage id={"promotion-desc"}/>
        </Typography>
      </Fade>
    </Stack>
  ) : <React.Fragment/>;
}
