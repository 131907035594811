import { MutableRefObject, useState } from 'react';

interface AnchorElProps {
  customAnchorEl?: MutableRefObject<any>,
}

export interface AnchorElReturn {
  anchorEl: any,
  setAnchorEl: any,
  handleClick: (event: any) => void,
  handleClose: () => void,
  open: boolean,
}

// Custom hook to manage anchor element state
const useAnchorEl = ({ customAnchorEl }: AnchorElProps = {}): AnchorElReturn => {
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(customAnchorEl?.current ?? event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return { anchorEl, setAnchorEl, handleClick, handleClose, open };
};

export default useAnchorEl;
