import FCard, { FCardHeader } from "../../App/Blocks/Card";
import { CompanyLogo } from "../../App/Blocks/Misc";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { GetCurrentFeedId } from "../../../app/utils";
import Urls from "../../../assets/js/Urls";
import { Link } from "react-router-dom";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Campaign, CloudOff, MoreHoriz } from "@mui/icons-material";
import { useReporting } from "../../../app/hooks/useReporting";
import { useGetCampaignsData } from "../../../app/hooks/useGetCampaigns";

interface AccountMetricsProps {
  header?: boolean,
  view?: "sm" | "lg",
}


const AccountsMetrics = ({ header = true, view = "sm" }: AccountMetricsProps) => {
  return (
    <Box>
      {header && (
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <FCardHeader
            size={"small"}
            icon={<Campaign/>}
            title={"connected-leverage"}
            margin={false}
            action={<IconBtn/>}
          />

        </Box>
      )}
      <FCard variant={"no-padding"} sx={{ overflow: "hidden" }}>
        <AcccountsMetricsTab view={view}/>
      </FCard>
    </Box>
  )
}

const IconBtn = () => {
  const feed = GetCurrentFeedId();
  return (
    <IconButton
      component={Link}
      to={Urls.campaigns.list2(feed)}
      size={"small"}
      edge={"start"}
    >
      <MoreHoriz/>
    </IconButton>
  )
}

interface AccountsMetricsTabProps extends Pick<AccountMetricsProps, "view"> {
}

export const AcccountsMetricsTab = (p: AccountsMetricsTabProps) => {
  const reporting = useReporting();
  const hasReporting = reporting.length;
  return (
    <Box sx={{
      filter: !hasReporting ? "blur(8px)" : null,
      p: 2,
    }}>
      <Table size={"small"} className={"no-border-last-row"}>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id={"canal"}/></TableCell>
            {p.view === "lg" && (<TableCell align={"right"}><FormattedMessage id={'conversions'}/></TableCell>)}
            <TableCell align={"right"}><FormattedMessage id={"spent"}/></TableCell>
            <TableCell align={"right"}><FormattedMessage id={"conversionValue"}/></TableCell>
            {p.view === "lg" && (<TableCell align={"right"}><FormattedMessage id={"roi"}/></TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {reporting.map(provider => (
            <TableRow key={`p-${provider.identifier}`}>
              <TableCell>
                <CompanyLogo
                  size={"icon"}
                  company={provider.identifier === "go" ? "google" : provider.identifier === "bg" ? "microsoft" : "meta"}/>
              </TableCell>
              {p.view === "lg" && (
                <TableCell align={"right"}>
                  <FormattedNumber value={provider.conversions ?? 0} maximumSignificantDigits={2}/>
                </TableCell>
              )}
              <TableCell align={"right"}>
                <FormattedNumber value={provider.spent} style={"currency"} currency={"EUR"}/>
              </TableCell>
              <TableCell align={"right"}>
                <FormattedNumber value={provider.conversionValue} style={"currency"} currency={"EUR"}/>
              </TableCell>
              {p.view === "lg" && (
                <TableCell align={"right"}>
                  <FormattedNumber value={provider.roi}/>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default AccountsMetrics;
