import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";

const DomainDisplay = ({ domain, onBack }: { domain: string; onBack: () => void }) => {
   return (
      <motion.div
         initial={{ opacity: 0, y: 20 }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 0.4 }}
         style={{ width: '100%' }}
      >
         <Box
            onClick={onBack}
            sx={{
               textAlign: 'center',
               p: 3,
               mb: 3,
               borderRadius: 2,
               background: 'linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0))',
               backdropFilter: 'blur(8px)',
               cursor: 'pointer',
               transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
               '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
               },
            }}
         >
            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
               <FormattedMessage id="selected-domain" />
            </Typography>
            <Typography
               variant="h6"
               sx={{
                  fontWeight: 'medium',
                  p: 1.5,
                  borderRadius: 1,
                  bgcolor: 'background.paper',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
               }}
            >
               {domain}
            </Typography>
         </Box>
      </motion.div>
   );
};

export default DomainDisplay;