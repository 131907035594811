import { GetCurrentFeedId } from "../../../../../../app/utils";
import React, { useEffect, useState } from "react";
import campaignApi from "../../../../../../app/services/secured/campaign";
import { FMsg, InputGroupIcon, LoadingButton } from "../../../../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../../../../App/Blocks/SimpleForm";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment, Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Refresh, Sms } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

/**
 * Phone.tsx
 * Phone verification for a newly created Google Ads account.
 */

type PhoneProps = {
  isValid: boolean,
  phone: string | undefined | null
}

enum PhoneSteps {
  ASK,
  VERIFY
}

const Phone = ({ isValid, phone }: PhoneProps) => {

  const feed = GetCurrentFeedId();
  const [ code, setCode ] = useState("");
  const [ phoneVal, setPhoneVal ] = useState(phone ?? "");
  const [ rId, setRID ] = useState<string>();

  const [ step, setStep ] = useState<PhoneSteps>(PhoneSteps.ASK);
  const [ counter, setCounter ] = useState(20);

  const [ getPhoneValid, getPhoneValidRes ] = campaignApi.useGetMerchantCenterPhoneValidationMutation();
  const [ setPhoneValid, setPhoneValidRes ] = campaignApi.useSetMerchantCenterPhoneValidationMutation();

  const getValidPhone = () => {
    if (phoneVal) {
      getPhoneValid({
        feed,
        country: 'FR',
        phone_number: phoneVal,
      });
      setCounter(20);
      if (code.length) {
        setCode("");
      }
    }
  }

  const setValidPhone = () => {
    if (code && rId) {
      setPhoneValid({
        feed,
        request_id: rId,
        code: code,
      });
    }
  }

  useEffect(() => {
    getPhoneValidRes.isSuccess && setRID(getPhoneValidRes.data?.request_id);
  }, [ getPhoneValidRes.isSuccess ]);

  useEffect(() => {
    step === PhoneSteps.VERIFY && counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [ counter, step ]);

  useEffect(() => {
    if (getPhoneValidRes.isSuccess) {
      setStep(PhoneSteps.VERIFY);
    }
  }, [ getPhoneValidRes ]);

  return <Box>
    {step === PhoneSteps.ASK && (
      <SimpleForm onSubmit={() => {
      }}>
        <FormControl margin={"normal"} fullWidth>
          <MuiTelInput
            name={"phone"}
            defaultCountry={"FR"}
            value={phoneVal ?? ''}
            onChange={(val) => setPhoneVal(val)}
            />
        </FormControl>
        <Stack alignItems={"end"} mt={1}>
          {!isValid && <LoadingButton
            data={getPhoneValidRes}
            onClick={() => getValidPhone()}
            variant={"contained"}
            reset
          >
            <FormattedMessage id={"begin-phone-verification"}/>
          </LoadingButton>}
        </Stack>
      </SimpleForm>
    )}

    {step === PhoneSteps.VERIFY && (
      <SimpleForm onSubmit={() => {}}>
        <FormControl margin={"normal"} fullWidth>
          <TextField
            required
            label={"Code"}
            placeholder={FMsg("received-code")}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position={"start"}>
                <Sms />
              </InputAdornment>,
              endAdornment: <InputAdornment position={"end"}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography variant={"caption"} color={"text.secondary"}>
                    {counter ? "(" + counter + ")" : <FormattedMessage id={"new-code"} />}
                  </Typography>
                  <IconButton
                    disableRipple
                    disabled={counter > 0}
                    onClick={() => setStep(PhoneSteps.ASK)}
                  >
                    <Refresh />
                  </IconButton>
                </Stack>
              </InputAdornment>
            }}
          />
        </FormControl>

        <Stack alignItems={"end"}>
          <LoadingButton
            variant={"contained"}
            data={setPhoneValidRes}
            onClick={() => setValidPhone()}
            sx={{
              mt: 1,
            }}
          >
            <FormattedMessage id={"valid-code"}/>
          </LoadingButton>
        </Stack>
      </SimpleForm>
    )}
  </Box>
}

export default Phone;
