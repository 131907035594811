import React from "react";
import { Box, Button, CircularProgress, FormControlLabel, ListItemIcon, Menu, MenuItem, Switch } from "@mui/material";
import { DeleteSweep, EditNote, Public, SaveAlt } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import useCampaignDraft from "./useCampaignDraft";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import useAnchorEl from "../../../../../app/hooks/useAnchorEl";
import { getCampaign, isNewCampaign } from "../../../../../app/slices/campaign/getters";
import { toggleCampaign } from "../../../../../app/slices/campaign/campaign";

interface useCampaignActionsProps {
  isSaving: boolean,
}

const useCampaignActions = (p: useCampaignActionsProps) => {
  const { hasDraft, draft, deleteDraft, saveDraft, isLoading, isNew } = useCampaignDraft();
  return (
    <CampaignActions
      draftLoad={isLoading}
      fakeLoading={saveDraft}
      hasDraft={hasDraft}
      handleDraftDelete={deleteDraft}
      isApiLoading={p.isSaving}
      isNew={isNew}
    />
  )
}

interface ICampaignActionsProps {
  draftLoad: boolean,
  fakeLoading: () => void,
  hasDraft: boolean,
  handleDraftDelete: () => void,
  isApiLoading: boolean,
  isNew: boolean,
}

const CampaignActions: React.FC<ICampaignActionsProps> = (p) => {

  const { draftLoad, fakeLoading, hasDraft, handleDraftDelete, isApiLoading } = p;
  const { anchorEl, open, handleClose, handleClick, setAnchorEl } = useAnchorEl();

  const dispatch = useAppDispatch();

  const isNew = useAppSelector(isNewCampaign);

  const saveDraft = () => {
    handleClose();
    fakeLoading();
  }

  const enabled = useAppSelector(getCampaign).enabled;
  const toggleEnabled = () => dispatch(toggleCampaign(!enabled));

  return (
    <Box sx={{
      display: 'flex',
      alignItems: "center",
      gap: 2,
    }}>
      <Box>
        {
          !isNew && (
            <Button
              component={'div'}
              variant={enabled ? "contained" : "outlined"}
              color={enabled ? "success" : "primary"}
            >
              <FormControlLabel
                sx={{ m: 0 }}
                control={(
                  <Switch
                    size={"small"}
                    checked={enabled}
                    color={"secondary"}
                    onChange={toggleEnabled}
                  />
                )}
                label={(
                  <Box>
                    <Public/>
                  </Box>
                )}
              />
            </Button>
          )
        }
      </Box>
      <Button
        color={"secondary"}
        endIcon={draftLoad ? <CircularProgress size={"1rem"}/> : <EditNote/>}
        variant={"contained"}
        size={"large"}
        onClick={handleClick}
      >
        <FormattedMessage id={'draft'}/>
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 1
        }}
      >
        <MenuItem
          onClick={saveDraft}
          dense
        >
          <ListItemIcon>
            <EditNote/>
          </ListItemIcon>
          <FormattedMessage id={"save-draft"}/>
        </MenuItem>
        <MenuItem
          dense
          disabled={!hasDraft}
          onClick={() => handleDraftDelete()}
        >
          <ListItemIcon>
            <DeleteSweep/>
          </ListItemIcon>
          <FormattedMessage id={"cancel-edits"}/>
        </MenuItem>
      </Menu>
      <Button
        type={"submit"}
        variant={"contained"}
        endIcon={<SaveAlt/>}
        disabled={isApiLoading}
        size={"large"}
      >
        <FormattedMessage id={"save"}/>
      </Button>
    </Box>

  )
}

export default useCampaignActions;
