import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setEventDays } from "../../../../../app/slices/campaign/campaign";
import { Box, Collapse, Fade, FormControl, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getMetaEvents } from "../../../../../app/slices/campaign/getters";
import { MetaEvent } from "../../../../../app/types/campaign";

export const MetaClassicEvents = () => {
  const events = useAppSelector(getMetaEvents);
  const included = !!events.filter(e => e.is_included).length;
  const excluded = !!events.filter(e => !e.is_included).length;

  return (
    <Box mt={2}>
      <Fade in={excluded} unmountOnExit>
        <Box>
          <Typography variant={'subtitle2'}><FormattedMessage id={"all-that-have-not"}/></Typography>
          {events.filter(e => !e.is_included).map(e => <Event e={e}/>)}
        </Box>
      </Fade>
      <Collapse in={included} unmountOnExit>
        <Box>
          <Typography mt={2} variant={'subtitle2'}><FormattedMessage id={"all-that-have"}/></Typography>
          {events.filter(e => e.is_included).map(e => <Event e={e}/>)}
        </Box>
      </Collapse>
    </Box>
  )
}
const Event = ({ e }: { e: MetaEvent }) => {
  const dispatch = useAppDispatch();
  const editDays = (d: number) => dispatch(setEventDays({ type: e.type, days: d }));
  return (
    <Box mt={2}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Typography variant={"overline"} m={0}><FormattedMessage id={e.type}/></Typography>
        <Typography><FormattedMessage id={"from-the"}/></Typography>
        <FormControl>
          <TextField
            type={"number"}
            size={'small'}
            value={e.days}
            onChange={(e) => editDays(parseInt(e.target.value))}
          />
        </FormControl>
        <Typography ml={1}><FormattedMessage id={"last-days"}/></Typography>
      </Stack>
    </Box>
  )
}
