import { Tooltip } from "@mui/material";

export const NoPaddingTooltip = (props: any) => (
  <Tooltip
    placement={"top"}
    componentsProps={{
      tooltip: {
        sx: {
          p: 0,
        },
      },
    }}
    {...props} // Make sure to pass through any props to support customization and event handling
  />
);
