import { useAppSelector } from "../../../app/hooks";
import { getModalData, ModalLabelProps } from "../../../app/slices/modal";
import React, { useEffect, useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getSelectedProducts, SelectedProduct } from "../../../app/slices/app";
import { Add, CallMerge } from "@mui/icons-material";
import { DynamicLabel, DynamicLabelRule } from "../../../app/types/feed";
import Grid2 from "@mui/material/Unstable_Grid2";
import { RuleFieldSelector, RuleOperatorSelector } from "./RulesComponents";
import { SkeletonLoader } from "../../App/Blocks/Misc";
import { RuleValueSelector } from "./RuleValueSelector";

const NewLabelRule = (): DynamicLabelRule => ({
  uuid: Math.random().toString(),
  value: "",
  field: "id_k",
  operator: "in",
});

interface RulesView {
  view?: "tooltip" | "modal";
}

interface RulesProps extends RulesView {
  label?: DynamicLabel;

}

export interface RuleProps extends DynamicLabelRule, RulesView {
  r_index: number;
  onDelete: () => void;
  selectProducts?: SelectedProduct[];
  nbRules: number,
  setRule: (index: number, rule: DynamicLabelRule) => void,
}

export interface RuleCommonProps extends RulesView {
  rule: RuleProps,
  r_index: number,
}

export interface RuleWithStateProps extends RuleCommonProps {
  setRule: React.Dispatch<React.SetStateAction<RuleProps>>,
  nbRules: number,
}

const Rules: React.FC<RulesProps> = (p) => {

  const opt = useAppSelector<ModalLabelProps>(getModalData);
  const label = p.label ? p.label : opt?.label;

  const [ rules, setRules ] = useState<Array<DynamicLabelRule>>([]);
  const [ loading, setLoading ] = useState(true);
  const selectedProducts = useAppSelector(getSelectedProducts);

  useEffect(() => {
    let initR = label ? [ ...label.conditions ] : [ NewLabelRule() ];

    if (selectedProducts.length) {
      const existingRuleIndex = initR.findIndex(r => r.field === "id_k");

      if (existingRuleIndex !== -1) {
        const existingValues = initR[existingRuleIndex].value.split("\n");
        const productRefs = selectedProducts.map(s => s.ref);

        const combinedSet = new Set([ ...existingValues, ...productRefs ]);
        const combinedValuesString = Array.from(combinedSet).join("\n");

        initR[existingRuleIndex] = { ...initR[existingRuleIndex], value: combinedValuesString };
      } else {
        initR.push({
          uuid: Math.random().toString(),
          value: selectedProducts.map(s => s.ref).join("\n"),
          field: "id_k",
          operator: "in",
        });
      }
    }

    setRules(initR);

  }, [ label, selectedProducts ]);

  const addRule = (r?: RuleProps) => setRules(prev => [ ...prev, NewLabelRule() ]);
  const delRule = (index: number) => setRules(prev => prev.filter((_, i) => i !== index));
  useEffect(() => {
    setTimeout(() => setLoading(false), 200);
  }, []);

  const hasDuplicates = hasDuplicateRuleTypes(rules);
  const merge = () => {
    const mergedRules = mergeDuplicateRules(rules);
    setRules(mergedRules);
  };

  const setRule = (index: number, rule: DynamicLabelRule) => {
    const updatedRules = [ ...rules ];
    updatedRules[index] = rule;
    setRules(updatedRules);
  };

  return (
    <React.Fragment>
      {hasDuplicates && (
        <Box mt={2}>
          <Button
            onClick={merge}
            variant={"contained"}
            startIcon={<CallMerge/>}
          >
            <FormattedMessage id={'merge-duplicates'}/>
          </Button>
        </Box>
      )}
      <SkeletonLoader load={loading}>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Box>
            <Grid2 container spacing={1} alignItems={"center"}>
              {rules.map((rule, index) => (
                <RuleComponent
                  nbRules={rules.length ?? 1}
                  key={`rule-${rule.uuid}`}
                  {...rule}
                  setRule={setRule}
                  r_index={index}
                  onDelete={() => delRule(index)}
                  view={p.view}
                />
              ))}
            </Grid2>
          </Box>
        </Paper>
      </SkeletonLoader>
      <AddRuleButton onAdd={addRule}/>
    </React.Fragment>
  );
}

const RuleComponent: React.FC<RuleProps> = (rule) => {
  const [ ruleState, setRule ] = useState<RuleProps>(rule);
  useEffect(() => {
    rule.setRule(rule.r_index, ruleState);
  }, [ ruleState ]);
  return (
    <React.Fragment>
      <RuleFieldSelector
        nbRules={rule.nbRules} view={rule.view} r_index={rule.r_index} rule={ruleState}
        setRule={setRule}/>
      <RuleOperatorSelector view={rule.view} r_index={rule.r_index} rule={ruleState}/>
      <RuleValueSelector
        nbRules={rule.nbRules} view={rule.view} r_index={rule.r_index} rule={ruleState}
        setRule={setRule}/>
    </React.Fragment>
  );
}

const AddRuleButton: React.FC<{ onAdd: () => void }> = ({ onAdd }) => (
  <Box display="flex" justifyContent="start" mt={1}>
    <Button variant={"outlined"} size='small' onClick={onAdd} startIcon={<Add/>}>
      <FormattedMessage id="add-filter"/>
    </Button>
  </Box>
);

const hasDuplicateRuleTypes = (rulesArray: DynamicLabelRule[]) => {
  const ruleTypeCounts = new Map();

  for (const rule of rulesArray) {
    const ruleType = rule.field; // Assuming 'field' is what determines the rule type
    ruleTypeCounts.set(ruleType, (ruleTypeCounts.get(ruleType) || 0) + 1);
  }

  let hasDuplicates = false;
  ruleTypeCounts.forEach((count) => {
    if (count > 1) {
      hasDuplicates = true;
    }
  });

  return hasDuplicates;
};

function mergeDuplicateRules(rulesArray: DynamicLabelRule[]): DynamicLabelRule[] {
  const merged: any = {};

  rulesArray.forEach(({ field, value }) => {
    if (!merged[field]) {
      merged[field] = new Set();
    }
    value.split("\n").forEach(val => merged[field].add(val));
  });

  return Object.keys(merged).map(field => ({
    field,
    value: Array.from(merged[field]).join("\n")
  })) as DynamicLabelRule[];
}


export default Rules;
