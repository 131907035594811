import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DatePeriod, ReportingPeriodToString, ReportinPeriodFromString } from "../../components/App/Blocks/Datepicker";
import { ProductReportingList } from "../types/products";
import { DynamicLabel } from "../types/feed";

const initialState: ReportingType = {

  //Global View
  period: ReportingPeriodToString(DatePeriod.ThisWeek),
  selectedMetric: [ "impressions", "spent" ],

  // Detail
  data: [],
  search: {
    text: "",
    label: null,
    inFeed: true,
  },

  filters: {
    impressions: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    clicks: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    spent: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    conversions: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    conversionValue: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    cpc: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    clickRate: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    conversionCost: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
    roi: {
      min: 0,
      max: 100,
      valMin: 0,
      valMax: 100,
    },
  }
};

const reporting = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<ReportingPeriodString>) => {
      state.period = action.payload;
    },
    setMetric: (state, action: PayloadAction<{ kpi: Kpi, index: 0 | 1 }>) => {
      state.selectedMetric[action.payload.index] = action.payload.kpi;
    },
    setProductReportingData: (state, action: PayloadAction<ProductReportingList>) => {
      state.data = action.payload;

      const getMaxValue = (key: FullKpi, data: ProductReportingList) => {
        const values = data.map((d) => d[key]);
        const max = Math.max(...values);
        return max % 1 !== 0 ? parseFloat(max.toFixed(2)) : max;
      };

      Object.keys(state.filters).forEach(k => {
        const maxRange = getMaxValue(k as FullKpi, state.data);
        state.filters[k as FullKpi].max = maxRange;
        state.filters[k as FullKpi].valMax = maxRange;
      });
    },
    setFiltersRanges: (state, action: PayloadAction<{ key: FullKpi, data: FullKpiFiltersData }>) => {
      state.filters[action.payload.key] = action.payload.data;
    },
    resetFiltersRanges: (state) => {
      Object.keys(state.filters).forEach(k => {
        state.filters[k as FullKpi].valMin = state.filters[k as FullKpi].min;
        state.filters[k as FullKpi].valMax = state.filters[k as FullKpi].max;
      });
    },
    setTextSearch: (state, action: PayloadAction<string>) => {
      state.search.text = action.payload;
    },
    setLabelSearch: (state, action: PayloadAction<DynamicLabel | null>) => {
      state.search.label = action.payload;
    },
    setProductReportingInFeed: (state, action:PayloadAction<boolean>) => {
      state.search.inFeed = action.payload;
    }
  },
});

/**
 * ACTIONS
 */
export const {
  setPeriod,
  setMetric,
  setProductReportingData,
  setFiltersRanges,
  resetFiltersRanges,
  setTextSearch,
  setLabelSearch,
  setProductReportingInFeed
} = reporting.actions;

/**
 * GETTERS
 */
export const reportingReducer = reporting.reducer;
export const getReportingPeriod = (state: RootState): ReportingPeriod => ReportinPeriodFromString(state.reporting.period);
export const getReportingMetric = (state: RootState) => state.reporting.selectedMetric;
export const getProductReportingData = (state: RootState) => state.reporting.data;
export const getProductReportingFilters = (state: RootState) => state.reporting.filters;

/**
 * TYPES
 */
export type ReportingPeriod = [ Date, Date ];
export type ReportingPeriodString = [ string, string ];

type ReportingType = {
  // Global View
  period: ReportingPeriodString,
  selectedMetric: [ Kpi, Kpi ],

  //Detail
  search: {
    text: string,
    label: DynamicLabel | null,
    inFeed: boolean,
  }

  data: ProductReportingList,
  filters: FullKpiFilters
};

export type Kpi = "impressions" | "clicks" | "spent" | "conversions" | "conversionValue";
export type CalculatedKpi = "cpc" | "clickRate" | "conversionCost" | "roi";
export type FullKpi = Kpi | CalculatedKpi;
export type FullKpiFilters = Record<FullKpi, FullKpiFiltersData>;
export type FullKpiFiltersData = {
  min: number,
  max: number,
  valMin: number,
  valMax: number,
};
