import React, { useState } from "react";
import FCard from "../../../App/Blocks/Card";
import ProductDatas from "./TabData/ProductData";
import { Box, Divider, Fade, Skeleton, Stack, Tab, Tabs } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../app/hooks";
import { ProductAttributes } from "./TabAttributes/ProductAttributes";
import { TitleDesc } from "./TabTitleDesc/TitleDesc";
import { TabPanel } from "../../../App/Blocks/AppTabs";
import { ProductFooter } from "./ProductFooter";
import { ProductCard } from "./Card/ProductCard";
import { FormattedMessage } from "react-intl";
import Statuses from "./TabStatuses/Statuses";
import { doesProductHasIssues, StatusObjectRecord } from "../../../../app/utils/productStatusUtils";
import PageInfo from "../../../App/Helpdesk/PageInfo";
import Market from "./Market";
import {
  getProduct,
  getProductData,
  getProductIsLoading,
  getProductView,
  ProductEditView
} from "../../../../app/slices/product";
import { SkeletonLoader } from "../../../App/Blocks/Misc";

export interface ProductComponentProps {
  pId: number | null;
  view: ProductEditView;
  loading: boolean;
}

enum PRODUCT_TABS {
  TITLE_DESC,
  LINKS,
  ATTRIBUTES,
  STATUSES,
  MARKET
}

const ProductTabs = () => {

  const product = useAppSelector(getProduct);
  const view = useAppSelector(getProductView);
  const viewPage = view === "page";
  const loading = useAppSelector(getProductIsLoading);

  const productData = useAppSelector(getProductData);

  const [ productTab, setProductTab ] = useState<PRODUCT_TABS>(PRODUCT_TABS.TITLE_DESC)

  const handleChange = (event: React.SyntheticEvent, newValue: PRODUCT_TABS) => {
    setProductTab(newValue);
  };

  const productIssues = product ? doesProductHasIssues(product) : [];
  const tabStatusesLabel = (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center", lineHeight: 1 }}>
      <span>
        <FormattedMessage id={'channel-statuses'}/>
      </span>
      {productIssues.map(issue => (
        <Box>
          {StatusObjectRecord[issue].icon}
        </Box>
      ))}
    </Box>
  )

  return (
    <Box>
      <Grid2 container spacing={2} key={productData.title}>
        <Grid2 xs={viewPage ? 3 : 12}>
          <ProductCard/>
        </Grid2>
        <Grid2 xs={viewPage ? 9 : 12}>
          <Stack spacing={1}>
            <PageInfo page={"product-edit"}/>
            <FCard variant={"no-padding"}>
              <SkeletonLoader load={loading}>
                <Box>
                  <Tabs scrollButtons={"auto"} variant={"scrollable"} value={productTab} onChange={handleChange}>

                    <Tab label={(
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <FormattedMessage id={'title-and-desc'}/>
                      </Box>
                    )} value={PRODUCT_TABS.TITLE_DESC}/>

                    <Tab label={<FormattedMessage id={'attributes'}/>} value={PRODUCT_TABS.ATTRIBUTES}/>

                    <Tab label={<FormattedMessage id={'ids-and-data'}/>} value={PRODUCT_TABS.LINKS}/>

                    <Tab label={tabStatusesLabel} value={PRODUCT_TABS.STATUSES}/>

                    <Tab value={PRODUCT_TABS.MARKET} disabled label={(
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center", lineHeight: 1 }}>
                        Marketing
                      </Box>
                    )}/>
                  </Tabs>

                  <Divider/>

                  <Box px={2} pb={2}>
                    <TabPanel index={productTab} value={PRODUCT_TABS.TITLE_DESC}>
                      <TitleDesc/>
                    </TabPanel>
                    <TabPanel index={productTab} value={PRODUCT_TABS.ATTRIBUTES}>
                      <ProductAttributes/>
                    </TabPanel>
                    <TabPanel index={productTab} value={PRODUCT_TABS.LINKS}>
                      <ProductDatas/>
                    </TabPanel>
                    <TabPanel index={productTab} value={PRODUCT_TABS.STATUSES}>
                      <Statuses/>
                    </TabPanel>
                    <TabPanel index={productTab} value={PRODUCT_TABS.MARKET}>
                      <Market/>
                    </TabPanel>
                  </Box>
                </Box>
              </SkeletonLoader>

            </FCard>
            <Fade in={productTab !== PRODUCT_TABS.STATUSES}>
              <Box>
                <ProductFooter/>
              </Box>
            </Fade>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  )
}


export default ProductTabs;
