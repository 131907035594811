import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getReportingMetric, Kpi } from "../../../app/slices/reporting";
import { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { DataCard } from "../../App/Blocks/Card";
import { sumReducer } from "../../../app/utils";
import { AdsClick, SellOutlined, TrendingUp, Visibility, Wallet } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ReportingRow } from "../../../app/types/feed";
import { useReporting } from "../../../app/hooks/useReporting";

const MetricCards = () => {

  const reporting = useReporting() as ReportingRow[];
  const selected = useAppSelector(getReportingMetric);

  const summary = {
    impressions: reporting?.map(row => row.impressions).reduce(sumReducer, 0),
    clicks: reporting?.map(row => row.clicks).reduce(sumReducer, 0),
    conversions: reporting?.map(row => row.conversions).reduce(sumReducer, 0),
    conversionValue: reporting?.map(row => row.conversionValue).reduce(sumReducer, 0),
    spent: reporting?.map(row => row.spent).reduce(sumReducer, 0),
  }

  const metrics = useMemo(() => {
    return [
      {
        id: 'impressions',
        icon: <Visibility fontSize={"large"}/>,
        label: <FormattedMessage id={"impressions"}/>,
        value: <FormattedNumber value={summary.impressions ?? 0}/>,
      },
      {
        id: 'clicks',
        icon: <AdsClick fontSize={"large"}/>,
        label: <FormattedMessage id={"clicks"}/>,
        value: <FormattedNumber value={summary.clicks ?? 0}/>,
      },
      {
        id: 'spent',
        icon: <Wallet fontSize={"large"}/>,
        label: <FormattedMessage id={'spent'}/>,
        value: <FormattedNumber value={summary.spent ?? 0} style={"currency"} currency={'EUR'}/>,
      },
      {
        id: 'conversions',
        icon: <SellOutlined fontSize={"large"}/>,
        label: <FormattedMessage id={'conversions'}/>,
        value: <FormattedNumber value={summary.conversions ?? 0}/>,
      },
      {
        id: 'conversionValue',
        icon: <TrendingUp fontSize={"large"}/>,
        label: <FormattedMessage id={'conversionValue'}/>,
        value: <FormattedNumber value={summary.conversionValue ?? 0} style={"currency"}
                                currency={"EUR"}/>,
      }
    ]
  }, [ reporting, selected ]);

  return <Grid2 container spacing={3}>
    {metrics && metrics.map((m, i) => <Grid2 key={`metric-${i}`} xs>
      <DataCard
        icon={m.icon}
        label={m.label}
        value={m.value}
        clickable={true}
      />
    </Grid2>)}
  </Grid2>
}

export default MetricCards;
