import React, { memo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { capitalizeFirstLetter } from "../../../../app/utils";

interface ModeMenuItemProps {
  mode: string;
  toggleMode: () => void;
}

export const ModeMenuItem = memo(({ mode, toggleMode }: ModeMenuItemProps) => (
  <MenuItem onClick={toggleMode}>
    <ListItemIcon>
      {mode === 'dark' ? <Brightness7/> : <Brightness4/>}
    </ListItemIcon>
    <ListItemText>
      {capitalizeFirstLetter(mode)} mode
    </ListItemText>
  </MenuItem>
));
