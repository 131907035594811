import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getNewFeedProject,
  getNewFeedSource,
  getNewFeedStep,
  NewFeedSteps, setNewFeedId, setNewFeedProject,
  setNewFeedStep
} from "../../app/slices/newfeed";
import { ReactNode, useEffect } from "react";
import Credentials from "./NewFeedCredentials";
import Preview from "./NewFeedPreview";
import Source from "./NewFeedSource";
import { BackButton, LoadingAnimation } from "../App/Blocks/Misc";
import Urls from "../../assets/js/Urls";
import projectsApi from "../../app/services/secured/projects";
import { redirect } from "../../app/slices/app";
import { Box, Button, Fade, Step, StepButton, Stepper, Typography } from "@mui/material";
import { feedSources } from "../../app/slices/shop";
import { FCardHeader } from "../App/Blocks/Card";
import { ImportExport } from "@mui/icons-material";

type NewFeedProps = {
  project?: number,
  newFeedWithinApp: boolean,
  feedId?: number,
}

export type NewFeedDesign = {
  fullWidth?: boolean,
  actions?: ReactNode,
}

const FeedImportMethod = ({ project, newFeedWithinApp, feedId }: NewFeedProps) => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(getNewFeedStep);
  const source = useAppSelector(getNewFeedSource);
  const newFeedProject = useAppSelector(getNewFeedProject);

  const [ getProject, projectQuery ] = projectsApi.useLazyGetProjectQuery();

  useEffect(() => {
    if (project) {
      getProject({ id: project as number }, true);
      if (!newFeedProject) {
        dispatch(setNewFeedProject(project));
      }
    }
  }, [ project, newFeedProject ]);

  // Edition
  useEffect(() => {
    if (feedId) {
      dispatch(setNewFeedId(feedId));
    }
  }, [ feedId ]);

  const back = () => {
    if (step === NewFeedSteps.SET_SOURCE) {
      dispatch(redirect(Urls.projects.list));
    } else if (step === NewFeedSteps.SET_CREDENTIALS) {
      dispatch(setNewFeedStep(NewFeedSteps.SET_SOURCE));
    } else if (step === NewFeedSteps.PREVIEW) {
      if (source !== "fictif") {
        dispatch(setNewFeedStep(NewFeedSteps.SET_CREDENTIALS));
      }
      dispatch(setNewFeedStep(
        source !== "fictif"
          ? NewFeedSteps.SET_CREDENTIALS
          : NewFeedSteps.SET_SOURCE
      ));
    }
  }

  const steps = [
    { label: "select-source", },
    { label: "get-feed", },
    { label: "preview", }
  ];

  return <Box>
    <LoadingAnimation data={true}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            width: '100%'
          }}
        >
          {!newFeedWithinApp && (
            <Box>
              <Box display={"flex"} alignItems={"center"}>
                {(project || step !== NewFeedSteps.SET_SOURCE) && (
                  <BackButton
                    size={"lg"}
                    onClick={back}
                    disabled={(!project && step === NewFeedSteps.SET_SOURCE)}
                  />
                )}
                {project && <Box display={"flex"} alignItems={"center"} ml={2}>
                  <Typography variant={"h4"}>{projectQuery.data?.name}</Typography>
                  <Typography variant={"caption"} mx={2}>|</Typography>
                  <Typography><FormattedMessage id={'new-feed'}/></Typography>
                </Box>}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{
            width: "40vw",
            marginTop: 3,
            mb: 2,
          }}>
            <Stepper activeStep={step}>
              {steps.map((s, i) => {
                return (
                  <Step key={s.label}>
                    <StepButton
                      onClick={() => dispatch(setNewFeedStep(i as any))}
                      disabled={(i === NewFeedSteps.SET_CREDENTIALS && source === "fictif") || i > step}
                    >
                      <FormattedMessage id={s.label}/>
                    </StepButton>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        </Box>
        <TabPanel index={step} value={NewFeedSteps.SET_SOURCE}>
          <Source
            type={!newFeedWithinApp ? "from-project" : "from-app"}
          />
        </TabPanel>
        <TabPanel index={step} value={NewFeedSteps.SET_CREDENTIALS}>
          <Credentials
            actions={<Box ml={1}>
              <Button onClick={back}>
                <FormattedMessage id={"back"}/>
              </Button>
            </Box>}
          />
        </TabPanel>
        <TabPanel index={step} value={NewFeedSteps.PREVIEW}>
          <Preview
            actions={<Box ml={1}>
              <Button onClick={back}>
                <FormattedMessage id={"back"}/>
              </Button>
            </Box>}
          />
        </TabPanel>
      </Box>
    </LoadingAnimation>
  </Box>
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // sx={{
      //   height:'45vh'
      // }}
      {...other}
    >
      <Fade in={value === index}>
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      </Fade>
    </Box>
  );
}

export default FeedImportMethod;
