import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import profileApi from "../services/secured/profile";
import { RootState } from "../store";
import { getFeedDates, getFeedSubStatus } from "../utils";
import campaignApi from "../services/secured/campaign";
import feedApi from "../services/secured/feed";
import { FeedPreviewType } from "./newfeed";
import { ProductError } from "../types/products";
import amazon from '../../assets/img/icons/amazon.svg';
import analytics from '../../assets/img/icons/analytics.svg';
import discover from '../../assets/img/icons/discover.svg';
import display from '../../assets/img/icons/display.svg';
import facebook from '../../assets/img/icons/facebook.svg';
import gads from '../../assets/img/icons/gads.svg';
import gmail from '../../assets/img/icons/gmail.svg';
import google from '../../assets/img/icons/google.svg';
import instagram from '../../assets/img/icons/instagram.svg';
import maps from '../../assets/img/icons/maps.svg';
import merchant from '../../assets/img/icons/merchant.svg';
import messenger from '../../assets/img/icons/messenger.svg';
import meta from '../../assets/img/icons/meta.svg';
import microsoft from '../../assets/img/icons/microsoft.svg';
import pinterest from '../../assets/img/icons/pinterest.svg';
import shopping from '../../assets/img/icons/shopping.svg';
import snapchat from '../../assets/img/icons/snapchat.svg';
import tiktok from '../../assets/img/icons/tiktok.svg';
import whatsapp from '../../assets/img/icons/whatsapp.svg';
import youtube from '../../assets/img/icons/youtube.svg';
import meta_advantage from "../../assets/img/icons/meta-advantage.svg";
import { StorageManager } from "../storage";
import { AppTourPage } from "../../components/App/AppTour";
import productsApi from "../services/secured/products";
import { AssetType, SubStatus, UserFeed } from "../types/feed";
import { Log, ProfileType, User } from "../types/profile";
import { CampaignSlice, CampaignType } from "../types/campaign";
import authApi from "../services/public/auth";
import { addDatalayerEvent, CrispEvent, pushCrispEvent, pushGAEvent, pushJuneEvent } from "../events";
import logsApi from "../services/secured/logs";
import { LogType } from "./app_notifications";

const initialState: AppState = {
  navOpen: true,
  navWasDisabled: false,
  issues: [],

  isDev: process.env.REACT_APP_IS_DEV === "1",
  user: null,
  profile: null,

  // userAlerts: [],

  isLoadingData: false,

  lang: window.localStorage.getItem("lang") as "en" | "fr" ?? "fr",
  mode: window.localStorage.getItem("mode") as "light" | "dark" ?? "light",
  redirect: null,
  currentFeed: null,
  hasLoadedCurrentFeed: false,
  showLoader: false,
  lastEditedCampaign: null,
  selectedProducts: [],
  feedPreview: null,
  isFirstMerchantConfig: null,

  userAssets: [],
  croppedImage: null,

  productError: null,
  // productPreview: null,
  currentPage: null,
  showBackdrop: false,

  hasDoneTour: {
    projects: StorageManager.getAppTour("projects") === "1",
    "dashboard_global_view": StorageManager.getAppTour("dashboard_global_view") === "1",
    "performances_global_view": StorageManager.getAppTour("performances_global_view") === "1",
    "performances_product_reporting": StorageManager.getAppTour("performances_product_reporting") === "1",
    "products_global_view": StorageManager.getAppTour("products_global_view") === "1",
    "products_product_list": StorageManager.getAppTour("products_product_list") === "1",
    "products_custom_labels": StorageManager.getAppTour("products_custom_labels") === "1",
    "products_product_feed": StorageManager.getAppTour("products_product_feed") === "1",
    "campaigns_global_view": StorageManager.getAppTour("campaigns_global_view") === "1",
    "campaigns_campaign_list": StorageManager.getAppTour("campaigns_campaign_list") === "1",
    "campaigns_accounts": StorageManager.getAppTour("campaigns_accounts") === "1",
  },
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoadingData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingData = action.payload;
    },
    setLanguage: (state, action: PayloadAction<"fr" | "en">) => {
      state.lang = action.payload;
      state.hasLoadedCurrentFeed = false;
      StorageManager.setLang(action.payload);
    },
    redirect: (state, action: PayloadAction<string>) => {
      state.redirect = action.payload;
    },
    clearRedirect: (state) => {
      state.redirect = null;
    },
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setLastEditedCampaign: (state, action: PayloadAction<CampaignSlice>) => {
      state.lastEditedCampaign = action.payload;
    },
    resetCurrentFeed: (state) => {
      // setNavWasDisabled
      state.navWasDisabled = true;
      state.currentFeed = initialState.currentFeed;
    },
    setSelectedProducts: (state, action: PayloadAction<SelectedProduct[]>) => {
      state.selectedProducts = action.payload;
    },
    setCurrentFeed: (state, action: PayloadAction<UserFeed>) => {
      state.currentFeed = action.payload;
    },
    setCurrentFeedLoaded: (state, action: PayloadAction<boolean>) => {
      state.hasLoadedCurrentFeed = action.payload;
    },
    setIsFirstMerchantConfig: (state, action: PayloadAction<boolean>) => {
      state.isFirstMerchantConfig = action.payload;
    },
    setUserAssets: (state, action: PayloadAction<AssetType[]>) => {
      state.userAssets = action.payload;
    },
    setCroppedImage: (state, action: PayloadAction<File | null>) => {
      state.croppedImage = action.payload;
    },
    setProductErrorId: (state, action: PayloadAction<StateProductError | null>) => {
      state.productError = action.payload;
    },
    initAppTour: (state) => {
      state.hasDoneTour[state.currentPage ?? "dashboard_global_view"] = false;
    },
    setHasDoneTour: (state, action: PayloadAction<AppTourPage>) => {
      state.hasDoneTour[action.payload] = true;
    },
    setCurrentPage: (state, action: PayloadAction<AppTourPage | null>) => {
      state.currentPage = action.payload;
    },
    setAppMode: (state, action: PayloadAction<"light" | "dark">) => {
      state.mode = action.payload;
      StorageManager.setMode(action.payload);
    },
    toggleOpen: (state) => {
      state.navOpen = !state.navOpen;
    },
    setNavOpen: (state, action: PayloadAction<boolean>) => {
      state.navOpen = action.payload;
    },
    setNavWasDisabled: (state, action: PayloadAction<boolean>) => {
      state.navWasDisabled = action.payload;
    },
    setShowBackdrop: (state, action: PayloadAction<boolean>) => {
      state.showBackdrop = action.payload;
    },

  },
  extraReducers: builder => {
    builder

    // Profile
    .addMatcher(profileApi.endpoints.getMe.matchFulfilled, (state, action) => {
      state.user = action.payload;

      // App Tour
      const START_DATE = new Date("06/12/2023");
      const dateCreation = new Date(action.payload.created);
      if (dateCreation < START_DATE) {
        state.hasDoneTour.projects = true;
        state.hasDoneTour.dashboard_global_view = true;
        state.hasDoneTour.performances_global_view = true;
        state.hasDoneTour.performances_product_reporting = true;
        state.hasDoneTour.products_global_view = true;
        state.hasDoneTour.products_product_list = true;
        state.hasDoneTour.products_custom_labels = true;
        state.hasDoneTour.products_product_feed = true;
        state.hasDoneTour.campaigns_campaign_list = true;
        state.hasDoneTour.campaigns_accounts = true;
      }
    })

    // .addMatcher(profileApi.endpoints.getUserAlerts.matchFulfilled, (state, action) => {
    //   state.userAlerts = action.payload;
    // })

    .addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, action) => {
      state.profile = action.payload;
    })

    .addMatcher(feedApi.endpoints.newFeedPreview.matchFulfilled, (state, action) => {
      state.feedPreview = action.payload;
    })

    .addMatcher(campaignApi.endpoints.getAssets.matchFulfilled, (state, action) => {
      state.userAssets = action.payload;
    })

    .addMatcher(campaignApi.endpoints.setAssets.matchFulfilled, (state, action) => {
      state.userAssets = action.payload;
    })

    .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        if (action.payload.is_new) {
          pushCrispEvent("user:auth:signup", {}, "pink");
          pushGAEvent("signup");
        }
      }
    })

    .addMatcher(logsApi.endpoints.getInAppIssues.matchFulfilled, (state, action) => {
      state.issues = action.payload;
    })

    /** Crisp Events */
    // New shop
    .addMatcher(feedApi.endpoints.newFeed.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        const event = state.feedPreview !== null ? "shop_success" : "shop_skip";
        const data = {
          shop_id: action.payload.id,
          shop_url: action.payload.name,
          language: action.payload.locale,
          products: state.feedPreview?.count ?? "unknown",
        };

        pushCrispEvent("user:shop:new", data, "green");
        pushGAEvent(event);
        pushJuneEvent(event, data);

        state.feedPreview = null;
      }
    })

    // Add cms to shop
    .addMatcher(feedApi.endpoints.setFeed.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const subStatus = getFeedSubStatus(action.payload);
        const data = {
          shop_id: action.payload.id,
          shop_url: action.payload.name,
          language: action.payload.locale,
          products: state.feedPreview?.count ?? "unknown",
          plan: subStatus,
        };

        pushCrispEvent("user:shop:cms", data, "green");
        pushJuneEvent("user_shop_cms", data);

        state.feedPreview = null;
      }
    })

    // Edit feed
    .addMatcher(feedApi.endpoints.setFeedData.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const subStatus = getFeedSubStatus(action.payload);
        const data = {
          shop_id: action.payload.id,
          shop_url: action.payload.name,
          language: action.payload.locale,
          new_source: action.payload.url ?? "unknown",
          plan: subStatus,
        };
        pushCrispEvent("user:shop:edit", data, "blue");
        pushJuneEvent("user_shop_edit", data);
      }
    })

    // Oauth Google
    .addMatcher(profileApi.endpoints.setGoogleLink.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const d = getCrispData(state);
        pushCrispEvent("user:accounts:google:oauth", { ...d }, "blue");
        pushJuneEvent("user_accounts_google_oauth", { ...d });
      }
    })

    // Oauth Meta
    .addMatcher(profileApi.endpoints.setMetaLink.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const d = getCrispData(state);
        pushCrispEvent("user:accounts:meta:oauth", { ...d }, "blue");
        pushJuneEvent("user_accounts_meta_oauth", { ...d });
      }
    })

    // Create Google Ads
    .addMatcher(campaignApi.endpoints.createAdsAccount.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const d = getCrispData(state);
        const data = {
          ...d,
          account_id: action.payload.account.id,
          account_name: action.payload.account.name,
          email_link: action.meta.arg.originalArgs.data.email,
        };
        pushCrispEvent("user:accounts:google:ads:new", data, "blue");
        pushJuneEvent("user_accounts_google_ads_new", data);
      }
    })

    // Link ads Google / Meta
    .addMatcher(campaignApi.endpoints.linkAdsAccount.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        let company = action.meta.arg.originalArgs.provider === "go" ? "google" : "meta";
        const event = `user:accounts:${company}:ads:link`;
        const d = getCrispData(state);
        const data = {
          ...d,
          account_id: action.payload.account.id,
          account_name: action.payload.account.name,
        };
        pushCrispEvent(event as CrispEvent, data, "blue");
        addDatalayerEvent("account_success");
        pushJuneEvent(`user_accounts_${company}_ads_link`, data);
      }
    })

    // Meta Catalog
    .addMatcher(campaignApi.endpoints.setMetaCatalog.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        const d = getCrispData(state);
        const data = {
          ...d,
          pixel_id: action.meta.arg.originalArgs.body.pixel_id,
          catalog_name: action.meta.arg.originalArgs.body.name,
        };

        pushCrispEvent("user:accounts:meta:catalog", data, "green");
        pushGAEvent("account_success_meta");
        pushJuneEvent("user_accounts_meta_catalog", data);
      }
    })

    // Merchant Center Phone
    .addMatcher(campaignApi.endpoints.setMerchantCenterPhoneValidation.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        const d = getCrispData(state);
        pushCrispEvent("user:accounts:google:merchant:phone", { ...d }, EventsColors.Blue);
        pushJuneEvent("user_accounts_google_merchant_phone", { ...d });
      }
    })

    // Merchant Center Config
    .addMatcher(campaignApi.endpoints.setMerchantCenter.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest && state.isFirstMerchantConfig) {
        const d = getCrispData(state);
        const data = {
          ...d,
          merchant_id: action.payload.id,
        };
        pushCrispEvent("user:accounts:google:merchant:config", data, "green");
        pushGAEvent("account_success_gads");
        pushJuneEvent("user_accounts_google_merchant_config", data);
      }
    })

    // New subscription
    .addMatcher(feedApi.endpoints.syncSubscription.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        const d = getCrispData(state);
        // const tmp = action.payload.current_subscription.
        const plan = action.payload.current_subscription?.plan;
        const data = {
          ...d,
          plan: plan?.plan ?? "unknown",
          limit: plan?.limit ?? "unknown",
        };

        pushCrispEvent("user:shop:subscription:new", data, "green");
        pushJuneEvent("user_shop_subscription_new", data);

        if (plan?.plan && (plan.plan === "starter" || plan.plan === "premium")) {
          const event = plan.plan === "starter" ? "subscribe_success_autopilot" : "subscribe_success_premium";
          pushGAEvent(event);
        }
      }
    })

    // Update subscription
    .addMatcher(feedApi.endpoints.updateSubscription.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        const d = getCrispData(state);
        const plan = action.payload.current_subscription?.plan;
        const data = {
          ...d,
          plan: plan?.plan ?? "unknown",
          limit: plan?.limit ?? "unknown",
        };

        pushCrispEvent("user:shop:subscription:edit", data, "blue");
        pushJuneEvent("user_shop_subscription_edit", data);

        if (plan?.plan && (plan.plan === "starter" || plan.plan === "premium")) {
          const event = plan.plan === "starter" ? "subscribe_success_autopilot" : "subscribe_success_premium";
          pushGAEvent(event);
        }
      }
    })

    // Cancel subscription
    .addMatcher(feedApi.endpoints.cancelSubscription.matchFulfilled, (state, action) => {
      if (!state.isDev && state.user && !state.user?.is_guest) {
        const d = getCrispData(state);
        pushCrispEvent("user:shop:subscription:cancel", {
          ...d,
        }, "red");
        pushGAEvent("unsubscribe_success");
        pushJuneEvent("user_shop_subscription_cancel", { ...d });
      }
    })

    // Create campaign
    .addMatcher(campaignApi.endpoints.createCampaign.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        let company = action.meta.arg.originalArgs.provider === "go" ? "google" : "meta";
        const event = `user:campaigns:${company}:new`;
        const d = getCrispData(state);
        const data = {
          ...d,
          type: CampaignType[action.payload.type],
          name: action.payload.name,
          budget: action.payload.budget,
        };
        pushCrispEvent(event as CrispEvent, data, "green");
        pushJuneEvent(`user_campaigns_${company}_new`, data);
      }
    })

    // Campaign Edit
    .addMatcher(campaignApi.endpoints.setCampaign.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        let company = action.meta.arg.originalArgs.provider === "go" ? "google" : "meta";
        const event = `user:campaigns:${company}:edit`;
        const d = getCrispData(state);
        const data = {
          ...d,
          type: CampaignType[action.payload.type],
          name: action.payload.name,
          budget: action.payload.budget,
          active: action.payload.enabled,
        };
        pushCrispEvent(event as CrispEvent, data, "blue");
        pushJuneEvent(`user_campaigns_${company}_edit`, data);
      }
    })

    // Campaign Budget
    .addMatcher(campaignApi.endpoints.setCampaignBudget.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        let company = action.meta.arg.originalArgs.provider === "go" ? "google" : "meta";
        const event = `user:campaigns:${company}:budget`;
        const d = getCrispData(state);
        const data = {
          ...d,
          type: CampaignType[action.payload.type],
          name: action.payload.name,
          budget: action.payload.budget,
          active: action.payload.enabled,
        };
        pushCrispEvent(event as CrispEvent, data, "blue");
        pushJuneEvent(`user_campaigns_${company}_budget`, data);
      }
    })

    // Campaign Active
    .addMatcher(campaignApi.endpoints.setCampaignActive.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        let company = action.meta.arg.originalArgs.provider === "go" ? "google" : "meta";
        const event = `user:campaigns:${company}:activate`;
        const d = getCrispData(state);
        const data = {
          ...d,
          type: CampaignType[action.payload.type],
          name: action.payload.name,
          budget: action.payload.budget,
          active: action.payload.enabled,
        };
        pushCrispEvent(event as CrispEvent, data, "blue");
        pushJuneEvent(`user_campaigns_${company}_activate`, data);
      }
    })

    // Set profile
    .addMatcher(profileApi.endpoints.setProfile.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        pushCrispEvent("user:settings:profile", {}, "green");
        pushJuneEvent("user_settings_profile", {});
      }
    })

    // Updates password
    .addMatcher(profileApi.endpoints.setNewPassword.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        pushCrispEvent("user:settings:password", {}, "yellow");
        pushJuneEvent("user_settings_password", {});
      }
    })

    // Free listing
    .addMatcher(feedApi.endpoints.activateFreeListing.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        pushCrispEvent("user:campaigns:free:googlefreelisting", {}, "green");
        pushJuneEvent("user_campaigns_free_googlefreelisting", {});
      }
    })

    // Feedcast Comparator
    .addMatcher(feedApi.endpoints.activateFeedcastComparator.matchFulfilled, (state, action) => {
      if (!state.isDev && !state.user?.is_guest) {
        pushCrispEvent("user:campaigns:free:feedcastcomparator", {}, "green");
        pushJuneEvent("user_campaigns_free_feedcastcomparator", {});
      }
    })

    // Edit product
    .addMatcher(productsApi.endpoints.setProduct.matchFulfilled, (state, action) => {
      if (state.user && !state.isDev && !state.user?.is_guest) {
        const d = getCrispData(state);
        const data = { ...d, product: action.payload.product_id };
        pushJuneEvent("user_shop_products_edit", data);
      }
    })
  }
});

const getCrispData = (state: AppState) => {
  return state.currentFeed ? {
    shop_url: state.currentFeed.name,
    shop_id: state.currentFeed.id,
    shop_sub: getFeedSubStatus(state.currentFeed),
  } : {}
}

export const
  {
    setIsLoadingData,
    setLanguage,
    redirect,
    clearRedirect,
    setShowLoader,
    setLastEditedCampaign,
    resetCurrentFeed,
    setSelectedProducts,
    setCurrentFeed,
    setCurrentFeedLoaded,
    setIsFirstMerchantConfig,
    setProductErrorId,
    initAppTour,
    setCurrentPage,
    setHasDoneTour,
    setAppMode,
    setNavOpen,
    setNavWasDisabled,
    setShowBackdrop,
    setCroppedImage,
  } = app.actions;

export const appReducer = app.reducer;

/********************************************************************************************************** Types */
interface AppState {
  // Utils
  isDev: boolean,
  lang: AppLanguages,
  mode: "light" | "dark",

  isLoadingData: boolean,

  // userAlerts: AlertType[],

  // Data
  currentFeed: UserFeed | null, // Current feed loaded
  user: User | null, // Current user logged in
  profile: ProfileType | null,
  lastEditedCampaign: CampaignSlice | null, // TODO - unused anymore ?
  selectedProducts: SelectedProduct[], // Selected products to add to a custom label
  feedPreview: FeedPreviewType | null, // Feed preview when new - TODO why is it there ?

  userAssets: AssetType[], // Google pictures assets
  croppedImage: File | null,

  productError: StateProductError | null, // Focus on a specific Google error


  // Navigation & Display
  navOpen: boolean, // Sidenav bar
  navWasDisabled: boolean,
  redirect: string | null, // Redirects to another page
  hasLoadedCurrentFeed: boolean, // Displays loader
  showLoader: boolean, // Displays loader

  // Tour & Events
  isFirstMerchantConfig: null | boolean, // Used for external events - fires when it's user's first merchant config completed
  currentPage: AppTourPage | null,
  hasDoneTour: Record<AppTourPage, boolean>,

  showBackdrop: boolean,

  issues: Log[],
}

export type AppLanguages = "fr" | "en";

type StateProductError = Pick<ProductError, 'id' | 'is_blocking'>;

export type SelectedProduct = { id: number, ref: string };

export enum EventsColors {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Pink = "pink",
  Brown = "brown",
  Grey = "grey",
  Black = "black"
}

/********************************************************************************************************** Getters */
export const getRedirectUrl = (state: RootState): string | null => state.app.redirect;
export const getUser = (state: RootState) => state.app.user;
export const getCurrentFeed = (state: RootState): UserFeed | null => state.app.currentFeed;
export const getAppLoading = (state: RootState): boolean => state.app.showLoader;
export const getCurrentSubscriptionDates = (state: RootState) => state.app.currentFeed ? getFeedDates(state.app.currentFeed) : null;
export const getSelectedProducts = (state: RootState) => state.app.selectedProducts;
export const getCurrentFeedHasLoaded = (state: RootState) => state.app.hasLoadedCurrentFeed;
export const getUserAssets = (state: RootState) => state.app.userAssets;
export const getProductErrorId = (state: RootState) => state.app.productError;
export const getLanguage = (state: RootState) => state.app.lang;
export const getAppTour = (state: RootState) => state.app.hasDoneTour;
export const getAppTourPage = (state: RootState) => state.app.currentPage;
export const getAppMode = (state: RootState) => state.app.mode;
export const getNavOpen = (state: RootState) => state.app.navOpen;
export const isLoadingData = (state: RootState) => state.app.isLoadingData;
export const getCurrentFeedSubStatus = (state: RootState): SubStatus => getFeedSubStatus(state.app.currentFeed);
export const getShopIssues = (state: RootState) => state.app.issues.filter(i => i.feed?.id === state.app.currentFeed?.id);
export const getFeedIssues = (state: RootState) => getShopIssues(state).filter(log => log.log_type === LogType.FEED_DOWNLOAD_ERROR);

/********************************************************************************************************** Constants */
export const C_Icons = {
  "amazon": amazon,
  "youtube": youtube,
  "analytics": analytics,
  "discover": discover,
  "display": display,
  "facebook": facebook,
  "gads": gads,
  "gmail": gmail,
  "google": google,
  "instagram": instagram,
  "maps": maps,
  "merchant": merchant,
  "messenger": messenger,
  "meta": meta,
  "meta-advantage": meta_advantage,
  "microsoft": microsoft,
  "pinterest": pinterest,
  "shopping": shopping,
  "snapchat": snapchat,
  "tiktok": tiktok,
  "whatsapp": whatsapp,
}
