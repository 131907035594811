import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";

const globalApi = securedApi.injectEndpoints({
  endpoints: build => ({
    countries: build.query<Array<Country>, void>({
      query: () => ApiUrls.global.countries,
    }),
    locales: build.query<Array<Locale>, void>({
      query: () => ApiUrls.global.locales,
    }),
    categories: build.query<GoogleCategory[], { language: string }>({
      query: ({ language }) => ApiUrls.global.categories(language),
    }),
    getCompetitors: build.query<any, { feed: string | number }>({
      query: ({ feed }) => ApiUrls.reporting.competitors(feed),
    }),
    getPopularProducts: build.query<PopularProduct[], { feed: string | number }>({
      query: ({ feed }) => ApiUrls.reporting.popularProducts(feed),
    })
  })
});

type Country = {
  code: string,
  name: string,
  country_vats: Array<{ code: string }>,
  timezones: Array<string>
}

type Locale = {
  locale: string,
}

export type GoogleCategory = {
  id: number;
  title: string;
}

export type PopularProduct = {
  brand: string;
  category: string;
  gtin: string[];
  in_inventory: boolean;
  relative_demand: number;
  relative_demand_change: number;
  title: string;
}

export default globalApi;
