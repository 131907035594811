import { useCallback, useEffect, useLayoutEffect } from "react";
import profileApi from "../../app/services/secured/profile";

import { Crisp } from "crisp-sdk-web";
import { useBoolean } from "../../app/custom_hooks";
import { useAppSelector } from "../../app/hooks";
import { getUser } from "../../app/slices/app";

const key = process.env.REACT_APP_CRISP_KEY ?? "d312213b-ab21-4c9f-8924-1b6833fe161f";

const ChatBot = (p: { loadWithoutData: boolean }) => {
  const data = useAppSelector(getUser);
  const [getProfile, profile] = profileApi.useLazyGetProfileQuery();
  const [hasFired, setHasFired] = useBoolean(false);

  Crisp.configure(key as string, {
    autoload: false,
  });

  useEffect(() => {
    if (data !== null) {
      getProfile(undefined, true);
    }
  }, [data]);

  console.log("has fired", hasFired, key);

  const crispInit = useCallback(() => {
    if (p.loadWithoutData) {
      Crisp.load();
    }
    else if (data && !data.is_guest && profile.isSuccess && !hasFired) {

      Crisp.load();

      const d = data;
      const p = profile.data;
      const company = p.merchant?.address;

      if (d && p) {
        const nickname = (d.first_name && d.last_name ? d.first_name + " " + d.last_name : "") || d.email;

        Crisp.setTokenId(d.token);
        Crisp.user.setEmail(d.email);

        if (nickname.length) {
          Crisp.user.setNickname(nickname);
        }

        if (p.phone_number) {
          Crisp.user.setPhone(p.phone_number);
        }

        if (p.merchant?.hear_about_us) {
          // Crisp.session.setData({
          //   hear_about_us: AppKnownSource[p.merchant?.hear_about_us ?? 0],
          // });
        }

        if (p.merchant?.name && company?.country && company?.city) {
          // Crisp.user.setCompany(p.merchant?.name, {
          //   geolocation: {
          //     country: company?.country ?? "",
          //     city: company?.city ?? "",
          //   }
          // })
        }

        Crisp.session.setSegments(["app"], false);
        setHasFired.setTrue();
      }
    }
  }, [data, profile, hasFired])

  useLayoutEffect(() => {
    crispInit();
  });

  return (
    <div></div>
  );
}

export default ChatBot;
