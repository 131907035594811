import { PReporting } from "../../../../app/types/products";
import { FullKpiFilters } from "../../../../app/slices/reporting";
import { DynamicLabel } from "../../../../app/types/feed";

type FilterRowsProps = {
  data: PReporting[],
  filters: FullKpiFilters,
  textFilter: string,
  labelFilter: DynamicLabel | null, // replace with actual type
  inFeed: boolean,
  findProductLabels: (id: string) => { productLabels: DynamicLabel[], component: JSX.Element }// replace with actual return type
}

export function filterRows({
  data, filters, textFilter, labelFilter, findProductLabels, inFeed
}: FilterRowsProps): any[] {

  const labelValues = preprocessLabelFilter(labelFilter);

  const filtered = data
  .filter((row) => checkRangeFilters(row, filters))
  .filter((row) => textFilter.length === 0 || applyTextFilter(row, textFilter))
  .filter((row) => !labelFilter || applyLabelFilter(row, labelValues))
  .filter((row) => inFeed ? row.id !== null : true)
  .map((row, index) => ({
    id: index,
    product_id: row.id,
    reference: row.identifier,
    labels: findProductLabels(row.identifier.toString()),
    image: row.image,
    title: row.title,
    impressions: row.impressions,
    clicks: row.clicks,
    cpc: row.cpc,
    clickRate: row.clickRate,
    conversions: row.conversions,
    conversionValue: row.conversionValue,
    conversionCost: row.conversionCost,
    roi: row.roi,
    spent: row.spent,
    price: row.price ? row.price + " " + row.currency : "",
  }));

  return filtered;
}

function checkRangeFilters(row: PReporting, filters: FullKpiFilters): boolean {
  return Object.keys(filters).every((key) => {
    const filter = filters[key as keyof FullKpiFilters];
    const value = row[key as keyof PReporting];
    return value ? (parseInt(value.toString()) >= parseInt(filter.valMin.toString()) && parseInt(value.toString()) <= parseInt(filter.valMax.toString())) : true;
  });
}

function applyTextFilter(row: PReporting, textFilter: string): boolean {
  const text = textFilter.toUpperCase();
  return (row.identifier?.toString().toUpperCase().includes(text) || row.title?.toUpperCase().includes(text)) ?? false;
}

function preprocessLabelFilter(labelFilter: DynamicLabel | null): Set<string> {
  const values = new Set<string>();
  if (labelFilter) {
    labelFilter.conditions.forEach((rule) => {
      if (rule.field === 'id_k') {
        rule.value.toUpperCase().split('\n').forEach(val => values.add(val.trim()));
      }
    });
  }
  return values;
}

function applyLabelFilter(row: PReporting, labelValues: Set<string>): boolean {
  if (labelValues.size === 0) return false;
  return labelValues.has(row.identifier.toString().toUpperCase());
}
