import {
  Backdrop,
  Box,
  Button, CircularProgress,
  Container,
  Fade,
  Grow,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ChatBot from "../../App/Chatbot";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReduxRtkApiResult } from "../../../app/types";
import { WhoAreYou } from "./WhoAreYou";
import { CreateProject } from "./CreateProject";
import { useAppDispatch, useAppLogo, useAppSelector } from "../../../app/hooks";
import { getNewFeedSource, getNewFeedStep, NewFeedSteps, setNewFeedStep } from "../../../app/slices/newfeed";
import { ButtonsNav } from "./ButtonsNav";
import gradient from "../../../assets/img/gradient/gradient_2.png";
import profileApi from "../../../app/services/secured/profile";
import Urls from "../../../assets/js/Urls";
import Credentials from "../../Import/NewFeedCredentials";
import Preview from "../../Import/NewFeedPreview";
import Source from "../../Import/NewFeedSource";
import { getAppMode, getLanguage, setAppMode, setLanguage } from "../../../app/slices/app";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import Locale from "../../App/Blocks/Locale";

export type ButtonsNavProps = {
  currentApi?: ReduxRtkApiResult,
  handleNext: () => void,
  handleBack: () => void,
  index: number,
  length: number,
}

export type PageNavProps = Pick<ButtonsNavProps, "handleNext" | "handleBack" | "index" | "length">

const NB_STEPS = 5;
const INTRO_STEPS = 2;

const Onboarding = () => {

  const dispatch = useAppDispatch();
  const mode = useAppSelector(getAppMode);
  const currentLang = useAppSelector(getLanguage);

  const me = profileApi.useGetMeQuery();
  const isPendingValidation = me.data?.pending_validation ?? null;

  const [ logout, logoutRes ] = profileApi.useLogoutMutation();
  const [ showChildren, setShowChildren ] = useState(true);
  const logo = useAppLogo();

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const changeMode = () => dispatch(setAppMode(mode === "light" ? "dark" : "light"));

  const toggleLang = useCallback(() => {
    setShowChildren(false);
    setTimeout(() => {
      dispatch(setLanguage(currentLang === "fr" ? "en" : "fr"));
      setShowChildren(true);
    }, 1000);
  }, [ currentLang, dispatch ]);

  useEffect(() => {
    if (logoutRes.isSuccess) {
      window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.login;
    }
  }, [ logoutRes ]);

  return (
    <Box
      sx={{
        height: '100vh',
        background: `url(${gradient})`,
        backgroundRepeat: "no-repeat",
        overflowY: "scroll",
        overflowX: "show",
        position: "relative",
      }}
    >
      {isPendingValidation ? (
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Box>
            <Typography variant={"h3"}>
              <FormattedMessage id={'pending-user-validation'}/>
            </Typography>
            <Typography>
              <FormattedMessage id={'pending-user-validation-desc'}/>
            </Typography>
          </Box>
        </Backdrop>
      ) : (
        <Box>
          <Box sx={{
            position: !isMobile ? "absolute" : "block",
            top: 10,
            left: 10,
            display: isMobile ? "flex" : "block",
            justifyContent: isMobile ? "center" : "start",
            mt: !isMobile ? 0 : 2,
          }}>
            <img src={logo} style={{ height: '60px' }}/>
          </Box>
          <Box
            sx={{
              position: !isMobile ? "absolute" : "block",
              display: isMobile ? "flex" : "block",
              justifyContent: isMobile ? "center" : "start",
              mt: !isMobile ? 0 : 2,
              top: 10,
              right: 10,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <IconButton onClick={changeMode}>
                {mode === 'dark' ? <Brightness7/> : <Brightness4/>}
              </IconButton>
              <IconButton
                onClick={toggleLang}
              >
                {!showChildren
                  ? <CircularProgress size={25}/>
                  : <Locale locale={currentLang}/>}
              </IconButton>
            </Box>
          </Box>
          <Container
            maxWidth={'lg'}
            sx={{
              mt: !isMobile ? '10vh' : 3,
            }}
          >
            <Fade in={showChildren}>
              <Stack spacing={3}>
                <Grow timeout={1300} in={true}>
                  <Box textAlign={"center"}>
                    {!isMobile && (
                      <Typography variant={"h3"}>
                        <FormattedMessage id={"welcome-feedcast"}/>
                      </Typography>
                    )}
                    <Typography variant={"body1"} color={"text.secondary"}>
                      <FormattedMessage id={"welcome-feedcast-desc"}/>
                    </Typography>
                  </Box>
                </Grow>
                <Fade in timeout={2000}>
                  <Box>
                    <OnboardingSteps/>
                  </Box>
                </Fade>
              </Stack>
            </Fade>
          </Container>

          <Box sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
          }}>
            <Button
              onClick={() => logout()}
            >
              <FormattedMessage id={"logout"}/>
            </Button>
          </Box>
          <ChatBot loadWithoutData={true}/>
        </Box>
      )}

    </Box>
  )
}

const OnboardingSteps = () => {

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const dispatch = useAppDispatch();
  const [ activeStep, setActiveStep ] = React.useState(0);

  const newFeedStep = useAppSelector(getNewFeedStep);
  const newFeedSource = useAppSelector(getNewFeedSource);

  useEffect(() => {
    if (newFeedStep === NewFeedSteps.SET_CREDENTIALS && newFeedSource === "fictif") {
      setActiveStep(INTRO_STEPS + NewFeedSteps.PREVIEW);
    } else if (activeStep < NB_STEPS && activeStep > 1) {
      setActiveStep(INTRO_STEPS + newFeedStep);
    }
  }, [ newFeedStep, newFeedSource ]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (newFeedStep === NewFeedSteps.SET_CREDENTIALS) {
      dispatch(setNewFeedStep(NewFeedSteps.SET_SOURCE));
    } else if (newFeedStep === NewFeedSteps.PREVIEW) {
      dispatch(setNewFeedStep(
        newFeedSource === "fictif"
          ? NewFeedSteps.SET_SOURCE
          : NewFeedSteps.SET_CREDENTIALS
      ));
    }
  };

  const options = {
    handleNext,
    handleBack,
    index: activeStep,
    length: NB_STEPS,
  }

  // Prevents re-render, redirects user on success
  const PreviewMemo = useMemo(() => {
    return <Box>
      <Preview actions={<ButtonsNav {...options}/>} fullWidth/>
    </Box>
  }, [ activeStep, handleBack ]);

  const intl = useIntl();

  const steps = [
    {
      label: intl.formatMessage({ id: "who-are-you" }),
      component: WhoAreYou(options),
    },
    {
      label: intl.formatMessage({ id: "create-first-project" }),
      component: CreateProject(options)
    },
    {
      label: intl.formatMessage({ id: "select-source" }),
      component: <Source type={"from-project"} actions={<ButtonsNav {...options} />} fullWidth/>
    },
    {
      label: intl.formatMessage({ id: "setup-credentials" }),
      component: <Credentials actions={<ButtonsNav {...options} />} fullWidth/>
    },
    {
      label: intl.formatMessage({ id: "preview" }),
      component: PreviewMemo
    }
  ]

  const stepsMemo = useMemo(() => steps.map((step, index) => (
    <Step key={step.label}>
      <StepLabel>
        {step.label}
      </StepLabel>
    </Step>
  )), [ steps ]);

  return <Box mt={isMobile ? 0 : 3} width={'100%'}>
    <Stepper
      activeStep={activeStep}
      sx={{
        display: isMobile ? "none" : undefined
      }}
    >
      {stepsMemo}
    </Stepper>
    <Box p={isMobile ? 1 : 3}>
      <Fade in={true} timeout={500}>
        <Box p={isMobile ? 0 : 5}>
          {steps[activeStep].component}
        </Box>
      </Fade>
    </Box>
  </Box>
}

export default Onboarding;

