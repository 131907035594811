import { StorageManager } from "../../../../app/storage";
import Urls from "../../../../assets/js/Urls";

const Autologin = () => {
    const token: string = new URLSearchParams(window.location.search).get("token") as string;
    StorageManager.set(token);
    setTimeout(() => {
        window.location.href = Urls.projects.list;
    }, 250);
    return <></>;
};

export default Autologin;
