import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, DeleteCampaignProps, getModalData, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "../../../../App/Blocks/Misc";
import { GetCurrentFeedId } from "../../../../../app/utils";
import campaignApi from "../../../../../app/services/secured/campaign";
import { addNotification } from "../../../../../app/slices/notifications";
import { redirect } from "../../../../../app/slices/app";
import Urls from "../../../../../assets/js/Urls";

const DialogDeleteCampaign = () => {

  const dispatch = useAppDispatch();

  const feed = GetCurrentFeedId();

  const open = useAppSelector(isModalOpened(ModalTypes.DeleteCampaign));
  const data = useAppSelector<DeleteCampaignProps | null>(getModalData);
  const [ confirmDelete, setConfirmDelete ] = useState("");

  const close = () => dispatch(closeModal());

  const [ deleteCampaign, deleteCampaignRes ] = campaignApi.useDeleteCampaignMutation();

  const delCampaign = () => {
    if (data) {
      deleteCampaign({ feed, provider: data.provider, campaign: data.campaign });
    }
  }

  useLayoutEffect(() => {
    if (deleteCampaignRes.isSuccess) {
      dispatch(closeModal());
      dispatch(addNotification({ type: "success", message: "success-delete-campaign" }));
      dispatch(redirect(Urls.campaigns.list2(feed)));
    }
  }, [ deleteCampaignRes.isSuccess ]);

  const confirmString = (data?.campaign || "").slice(0, 5);
  const authDelelete = confirmDelete !== confirmString;


  return (
    <Box>
      <Dialog open={open} maxWidth={"xs"} fullWidth onClose={close}>
        <DialogTitle display={"flex"} alignItems={"center"} gap={1} lineHeight={1} color={"error"}>
          <FormattedMessage id={"delete-campaign"}/>
        </DialogTitle>
        <DialogContent dividers>
          <FormattedMessage
            id={"delete-campaign-confirm"}
            values={{
              b: () => <b>{confirmString}</b>
            }}
          />
          <FormControl fullWidth sx={{mt: 1}}>
            <TextField
              onChange={(e) => setConfirmDelete(e.target.value)}
              onPaste={(e) => e.preventDefault()}
              />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            <FormattedMessage id={"no"}/>
          </Button>
          <LoadingButton
            variant={"contained"}
            color={"error"}
            onClick={delCampaign}
            endIcon={<Delete/>}
            data={deleteCampaignRes}
            reset
            disabled={authDelelete}
          >
            <FormattedMessage id={"yes-delete-campaign"}/>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DialogDeleteCampaign;
