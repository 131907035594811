import { closeModal, isAnyModalOpened, isModalOpened, ModalTypes } from "../../../../../../app/slices/modal";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import DropArea from "./DropArea/DropArea";
import { Box, Drawer } from "@mui/material";
import React from "react";

const DrawerAssetManager = () => {

  const dispatch = useAppDispatch();
  const show = useAppSelector<boolean>(isAnyModalOpened);
  const showMe = useAppSelector<boolean>(isModalOpened(ModalTypes.AssetsManager));
  const close = () => dispatch(closeModal());

  return <Drawer
    open={show && showMe}
    onClose={close}
    anchor={"right"}
    sx={{
      zIndex:1202,
      "& .MuiPaper-root": {
        borderRadius: '0',
        overflowY: "scroll",
      },
    }}
  >
    <AssetManager/>
  </Drawer>
}

export const AssetManager = () => {
  return <Box
    height={'100%'}
    width={"40vw"}
  >
    <DropArea/>
  </Box>
}

export default DrawerAssetManager;
