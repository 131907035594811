import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";

interface SuccessMessageProps {
   title: string;
   description?: string;
   highlightedText?: string;
   footer?: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
   title,
   description,
   highlightedText,
   footer,
}) => {
   return (
      <motion.div
         initial={{ opacity: 0, scale: 0.95 }}
         animate={{ opacity: 1, scale: 1 }}
         transition={{ duration: 0.6, ease: "easeOut" }}
      >
         <Box
            sx={{
               textAlign: 'center',
               p: 4,
               maxWidth: '440px',
               margin: '0 auto',
               borderRadius: 2,
               background: 'linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0))',
               backdropFilter: 'blur(8px)',
            }}
         >
            <Typography
               variant="h4"
               gutterBottom
               sx={{
                  fontWeight: 600,
               }}
            >
               <FormattedMessage id={title} />
            </Typography>
            {description && (
               <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
                  <FormattedMessage id={description} />
               </Typography>
            )}
            {highlightedText && (
               <Typography
                  variant="body1"
                  sx={{
                     fontWeight: 'medium',
                     my: 2,
                     p: 1.5,
                     borderRadius: 1,
                     bgcolor: 'background.paper',
                     boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  }}
               >
                  {highlightedText}
               </Typography>
            )}
            {footer && (
               <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
                  <FormattedMessage id={footer} />
               </Typography>
            )}
         </Box>
      </motion.div>
   );
};

export default SuccessMessage;