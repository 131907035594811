import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { ProductComponentProps } from "../ProductTabs";
import useCatalogErrors from "../../../../../app/hooks/useCatalogErrors";
import { CompanyLogo } from "../../../../App/Blocks/Misc";
import { ChannelsIds } from "../../../../../app/types/feed";
import { capitalizeFirstLetter } from "../../../../../app/utils";
import { FormattedMessage } from "react-intl";
import React, { ReactNode } from "react";
import { ProductErrorEnhanced } from "../../../../../app/types/products";
import { ExpandMore, LinkOff } from "@mui/icons-material";
import {
  CompanyStatus,
  getChannelStatusDisplay,
  getProductStatusComponents
} from "../../../../../app/utils/productStatusUtils";
import google_shopping_icon from "../../../../../assets/img/icons/google-shopping.svg";
import { useAppSelector } from "../../../../../app/hooks";
import { getProduct } from "../../../../../app/slices/product";

const Statuses = () => {
  const product = useAppSelector(getProduct);
  const [ { allStatuses } ] = useCatalogErrors(product);
  return (
    <Box>
      {allStatuses.map(channelStatus => {
        const {
          isChannelInactive, channelName, display, hasWarningOrError: defaultExpanded
        } = getChannelStatusDisplay(channelStatus);
        return (
          <Accordion
            defaultExpanded={defaultExpanded}
            key={`company-${channelStatus.source_id}`}
            sx={
              isChannelInactive ? {
                filter: "grayscale(1)",
              } : {}
            }
          >
            <AccordionSummary expandIcon={<ExpandMore fontSize={"large"}/>}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '98%' }}>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  {channelStatus.source_id !== ChannelsIds.FreeListing ? (
                    <CompanyLogo company={channelStatus.company} size={"sm"}/>) : (
                    <img src={google_shopping_icon} width={"30px"}/>
                  )}
                  <Typography variant={"h6"}>
                    {channelName}
                  </Typography>
                </Box>
                <Stack spacing={0.5}>
                  {display.map(d => (
                    <Box key={`status-${d?.message}`} sx={{ display: "flex", gap: 0.5 }}>
                      <Typography
                        color={!isChannelInactive ? `${d?.color}.main` : 'text.disabled'}
                        variant={"caption"}
                      >
                        {d?.message}
                      </Typography>
                      {d?.icon}
                    </Box>
                  ))}
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <StatusesDetail channel={channelStatus} inactive={isChannelInactive}/>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

interface StatusesDetailProps {
  channel: CompanyStatus,
  inactive: boolean;
}

// TODO - Formatted Messages

const StatusesDetail = ({ channel, inactive }: StatusesDetailProps) => {
  const renderAlert = (severity: AlertColor, title: string, message: ReactNode) => (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );

  const renderErrorList = (errors: ProductErrorEnhanced[], isBlocking: boolean) => (
    <List>
      {errors
      .filter(e => e.is_blocking === isBlocking)
      .map(e => (
        <ListItem key={e.id}>
          <ListItemText
            primary={
              e.header ? (
                <FormattedMessage
                  id={e.header}
                  values={{ attribute: <FormattedMessage id={e.attribute || "unknown"}/> }}
                />
              ) : null
            }
            secondary={<FormattedMessage id={e.body || e.description}/>}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Stack spacing={1}>
      {inactive && renderAlert("warning", "Canal inactif", "Le canal publicitaire est inactif.")}

      {channel.combinedStatuses.includes("PENDING") &&
        renderAlert(
          "info",
          "Produit en attente de synchronisation",
          "Le produit est actuellement en attente de synchronisation."
        )}

      {channel.combinedStatuses.includes("ONLINE") &&
        renderAlert(
          "success",
          "Produit synchronisé",
          "Le produit est actuellement synchronisé avec le canal publicitaire."
        )}

      {channel.combinedStatuses.includes("WARNING") &&
        renderAlert(
          "warning",
          "Avertissement(s)",
          <>
            Le produit présente le(s) avertissement(s) suivants:
            {renderErrorList(channel.errors, false)}
          </>
        )}

      {channel.combinedStatuses.includes("ERROR") &&
        renderAlert(
          "error",
          "Erreur(s)",
          <>
            Le produit présente la/les erreur(s) suivante(s):
            {renderErrorList(channel.errors, true)}
          </>
        )}
    </Stack>
  );
}

export default Statuses;
