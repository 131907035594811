import React from "react";
import { Box } from "@mui/material";
import { MetaClassicData } from "./MetaClassicData";
import { MetaClassicTargets } from "./MetaClassicTargets";
import { MetaClassicPlatforms } from "./MetaClassicPlatforms";
import { MetaClassicEvents } from "./MetaClassicEvents";
import { MetaClassicType } from "./MetaClassicType";
import Grid2 from "@mui/material/Unstable_Grid2";
import CampaignCard from "../CampaignCard";
import { Facebook } from "@mui/icons-material";

/**
 * MetaClassicTargeting.tsx
 * Card for Meta classic targeting
 */

const MetaClassicTargeting = () => {
  return (
    <Box>
      <CampaignCard icon={<Facebook/>} title={"meta-campaign-type"}>
        <Grid2 container spacing={3}>
          <Grid2 xs={12}>
            <MetaClassicPlatforms/>
          </Grid2>
          <Grid2 xs={12}>
            <MetaClassicData/>
          </Grid2>
          <Grid2 xs={12}>
            <MetaClassicTargets/>
          </Grid2>
          <Grid2 xs={12}>
            <MetaClassicType/>
            <MetaClassicEvents/>
          </Grid2>
        </Grid2>
      </CampaignCard>
    </Box>
  )
}

export default MetaClassicTargeting;
