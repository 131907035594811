import { Box, Fade, FormControl, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useEffect, useMemo } from "react";
import { SubFormProps } from "../SubForm";
import { useSubscription } from "../hooks/useSubscription";
import { Packs, SelectedPack } from "../constants";

const PLATFORMS_MIN = 2;

type SubPlatforms = {} & Pick<SubFormProps, "currSub" | "onPlatformChange"> & SelectedPack;

export const SubPlatforms = (p: SubPlatforms) => {
  const { currSub, onPlatformChange, selectedPack } = p;
  const platform = currSub.platform;
  const [ sub ] = useSubscription(currSub.feed);

  const platforms = sub.data?.prices.filter(p => p.type === "platforms") ?? [];
  const selectedPlatform = platforms.find(p => p.id === platform);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onPlatformChange(parseInt(e.target.value));
  }

  const defaultOption = useMemo(() => {
    if (selectedPack === Packs.Premium) {
      const maxPlatformsNode = platforms.reduce((prev, curr) => ((prev.additional_platforms ?? 0) > (curr.additional_platforms ?? 0)) ? prev : curr, platforms[0]);
      if (maxPlatformsNode && maxPlatformsNode.additional_platforms) {
        return <FormattedMessage
          id={'nb-platforms'}
          values={{ nb: PLATFORMS_MIN + maxPlatformsNode.additional_platforms }}
        />
      }
    }
    return <FormattedMessage id={'nb-platforms'} values={{ nb: PLATFORMS_MIN }}/>
  }, [ selectedPack ]);

  return (
    <Stack spacing={1}>
      <Typography color={"text.secondary"} lineHeight={1}>
        <FormattedMessage id={"additional-platforms"}/>
      </Typography>
      <Stack direction={"row"} spacing={2} alignItems={"end"}>
        <FormControl sx={{ width: '50%' }}>
          <TextField
            size={"small"}
            select
            sx={{
              minWidth: '75px'
            }}
            value={currSub.platform ?? 0}
            onChange={onChange}
            disabled={!currSub.pack || selectedPack === Packs.Premium}
          >
            <MenuItem value={0}>
              {defaultOption}
            </MenuItem>
            {platforms.map(p => (
              <MenuItem key={`p-${p.id}`} value={p.id}>
                <FormattedMessage id={'nb-platforms'} values={{ nb: PLATFORMS_MIN + (p.additional_platforms ?? 0) }}/>
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <Fade in={(selectedPlatform?.price || 0) > 0}>
          <Box textAlign={"start"}>
            <Typography variant={"h6"} lineHeight={0.8}>+{selectedPlatform?.price ?? 0}€</Typography>
            <Typography variant={"caption"} color={"text.secondary"}><FormattedMessage id={"ht-month"}/></Typography>
          </Box>
        </Fade>
      </Stack>
    </Stack>
  )
}
