import { Box, CircularProgress, Container, IconButton, Typography } from "@mui/material"
import React, { ReactNode, useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getLanguage, setLanguage, getAppMode, setAppMode } from "../app/slices/app";
import Locale from "../components/App/Blocks/Locale";
import LogoDark from "../assets/img/logo/fc_dark.svg";
import LogoWhite from "../assets/img/logo/fc_light.svg";
import { motion } from "framer-motion";
import { Link, Navigate } from "react-router-dom";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { StorageManager } from "../app/storage";
import Urls from "../assets/js/Urls";

interface AppPublicProps {
   children?: ReactNode;
}

const AppPublic: React.FC<AppPublicProps> = ({ children }) => {
   const isLogged = StorageManager.get() !== null;
   return (
      <Box id="app-public" sx={{ height: '100vh' }}>
         {isLogged && (<Navigate to={Urls.projects.list} />)}
         <Container maxWidth="md" sx={{ position: 'relative' }}>
            <AppHeader />
            {children}
            <AppFooter />
         </Container>
      </Box>
   )
}

const AppHeader = () => {
   const [showChildren, setShowChildren] = useState(true);
   const dispatch = useAppDispatch();
   const currentLang = useAppSelector(getLanguage);
   const mode = useAppSelector(getAppMode);

   const toggleLang = useCallback(() => {
      setShowChildren(false);
      setTimeout(() => {
         dispatch(setLanguage(currentLang === "fr" ? "en" : "fr"));
         setShowChildren(true);
      }, 1000);
   }, [currentLang, dispatch]);

   const toggleMode = useCallback(() =>
      dispatch(setAppMode(mode === 'light' ? 'dark' : 'light')),
      [dispatch, mode]
   );

   return (
      <>
         <Box
            component={motion.div}
            whileHover={{ scale: 1.1 }}
            sx={{ position: "absolute", top: 10, left: 10, p: 1 }}
         >
            <Link to="/">
               <img
                  src={mode === 'dark' ? LogoDark : LogoWhite}
                  alt="logo"
                  width={150}
                  height={"auto"}
               />
            </Link>
         </Box>
         <Box sx={{ position: "absolute", top: 25, right: 25, display: 'flex', gap: 2, alignItems: 'center' }}>
            <IconButton
               onClick={toggleMode}
               component={motion.div}
               whileHover={{ scale: 1.1 }}
            >
               {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            <Box
               sx={{ fontSize: 14, cursor: "pointer" }}
               onClick={toggleLang}
               component={motion.div}
               whileHover={{ scale: 1.1, opacity: 0.8 }}
               transition={{ type: "spring", stiffness: 400, damping: 17 }}
               initial={{ opacity: 1 }}
            >
               {!showChildren
                  ? <CircularProgress size={20} />
                  : <Locale locale={currentLang} />}
            </Box>
         </Box>
      </>
   )
}

const AppFooter = () => {
   return (
      <Box sx={{ position: "absolute", bottom: 10, left: 10, display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 20px)' }}>
         <Typography color="text.secondary">Feedcast / 2024</Typography>
         <Typography color="text.secondary">All rights reserved</Typography>
      </Box>
   )
}

export default AppPublic