import {
  Alert,
  AlertTitle,
  Avatar, Box, Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fade, Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText, Stack,
  Tooltip
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../../../../app/slices/modal";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import { ArrowBack, ArrowForward, AutoAwesome, Link as LinkIcon, Microsoft } from "@mui/icons-material";
import { useAccounts } from "../../../../../../app/hooks/useAccounts";
import { addNotification } from "../../../../../../app/slices/notifications";
import { CreateMicrosoftCenter } from "./CreateMicrosoftCenter";
import { LinkMicrosoftCenter } from "./LinkMicrosoftCenter";
import { UserAdsAccount } from "../../../../../../app/types/feed";

enum MicrosoftCenterSteps {
  SOURCE,
  CREATE,
  CREATE_FORM,
  LINK
}

const DialogMicrosoftCenter = () => {

  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.MicrosoftCenter));
  const [ step, setStep ] = useState<MicrosoftCenterSteps>(MicrosoftCenterSteps.SOURCE);

  const [ , { services } ] = useAccounts();
  const accounts = services.microsoftCenter.data ?? [];

  const hide = () => {
    dispatch(closeModal());
    setTimeout(() => setStep(MicrosoftCenterSteps.SOURCE), 300);
  }

  const onSuccess = () => {
    dispatch(addNotification({ type: "success", message: "success-creation-microsoft-center" }));
    hide();
  }

  return (
    <Dialog open={open} maxWidth={"sm"} onClose={hide} fullWidth>
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Microsoft/>
        Microsoft Merchant Center
      </DialogTitle>
      <DialogContent>
        {step === MicrosoftCenterSteps.SOURCE && (
          <MicrosoftCenterSource
            setStep={setStep}
            accounts={accounts}
          />
        )}
        {step === MicrosoftCenterSteps.CREATE && (
          <MicrosoftCenterCreate
            setStep={setStep}
          />
        )}
        {step === MicrosoftCenterSteps.CREATE_FORM && (
          <CreateMicrosoftCenter
            onSuccess={onSuccess}
            onBack={() => setStep(MicrosoftCenterSteps.CREATE)}
          />)}
        {step === MicrosoftCenterSteps.LINK && (
          <LinkMicrosoftCenter
            accounts={accounts}
            onSuccess={onSuccess}
            onBack={() => setStep(MicrosoftCenterSteps.SOURCE)}
          />)}
      </DialogContent>
    </Dialog>
  )
}

interface MicrosoftCenterSourceProps {
  setStep: any,
  accounts: (Pick<UserAdsAccount, "id" | "name"> & { url: string })[],
}

const MicrosoftCenterSource = ({ setStep, accounts }: MicrosoftCenterSourceProps) => {
  const intl = useIntl();
  return (
    <Fade in={true}>
      <List>
        <ListItem>
          <ListItemButton onClick={() => setStep(MicrosoftCenterSteps.CREATE)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "secondary.main" }}>
                <AutoAwesome/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({ id: "create-microsoft-center" })}
              secondary={intl.formatMessage({ id: "microsoft-center-requirement" })}
            />
          </ListItemButton>
        </ListItem>
        <Divider><FormattedMessage id={"or"}/></Divider>
        <Tooltip
          arrow
          placement={"left"}
          title={
            !accounts.length ? <FormattedMessage id={"no-microsoft-accounts"}/> : ""
          }>
          <ListItem>
            <ListItemButton
              disabled={!accounts.length}
              onClick={() => setStep(MicrosoftCenterSteps.LINK)}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "secondary.main" }}>
                  <LinkIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={intl.formatMessage({ id: "link-existing-merchant" })}
                secondary={intl.formatMessage({ id: "link-existing-merchant-desc" })}
              />
            </ListItemButton>

          </ListItem>
        </Tooltip>
      </List>
    </Fade>
  )
}

interface MicrosoftCenterCreateProps {
  setStep: any,
}

const MicrosoftCenterCreate = ({ setStep }: MicrosoftCenterCreateProps) => {
  return (
    <Fade in={true}>
      <Stack spacing={2}>
        <Alert severity={"warning"}>
          <AlertTitle><FormattedMessage id={'create-microsoft-center-alert'}/></AlertTitle>
          <FormattedMessage
            id={'create-microsoft-center-alert-desc'}
            values={{
              b: (txt) => <b>{txt}</b>
            }}
          />
        </Alert>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
          <Button
            onClick={() => setStep(MicrosoftCenterSteps.SOURCE)}
            variant={"contained"} startIcon={<ArrowBack/>}
          >
            <FormattedMessage id={'already-have-m-merchant'}/>
          </Button>
          <Button
            component={'a'}
            variant={"contained"}
            target={'_blank'}
            href={"https://www.bing.com/webmasters/about"}
            sx={{ textAlign: "center" }}
          >
            <FormattedMessage id={'need-to-check-domain'}/>
          </Button>
          <Button
            onClick={() => setStep(MicrosoftCenterSteps.CREATE_FORM)}
            variant={"contained"} endIcon={<ArrowForward/>}
          >
            <FormattedMessage id={'have-checked-domain'}/>
          </Button>
        </Box>
      </Stack>
    </Fade>
  )
}

export default DialogMicrosoftCenter;
