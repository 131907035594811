import { ChannelWrapperProps } from "./Channels";
import { useAppDispatch } from "../../../../app/hooks";
import { useAccounts } from "../../../../app/hooks/useAccounts";
import React from "react";
import { modalShowInitAccount } from "../../../../app/slices/modal";
import { Facebook, Google, Microsoft } from "@mui/icons-material";
import { useOAuth } from "../../../App/AppPublic/Auth/Oauth";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Grid2 from "@mui/material/Unstable_Grid2";

export const ChannelOAuth = (p: ChannelWrapperProps) => {
  const dispatch = useAppDispatch();
  const { company } = p;
  const [ accounts ] = useAccounts();

  const commonProps = {
    variant: "contained" as any,
    color: "secondary" as any,
    sx: { whiteSpace: "nowrap" },
  };

  const stopPropagationAndCall = (action: () => void) => (e: React.MouseEvent) => {
    e.stopPropagation();
    action();
  };

  const companyConfig = {
    google: {
      show: company === "google" && ((!accounts.hasLinkedAccount.google && !accounts.isOauth.google) || !accounts.hasAccess.google),
      action: stopPropagationAndCall(() => dispatch(modalShowInitAccount())),
      icon: <Google/>,
    },
    meta: {
      show: company === "meta" && (!accounts.isOauth.meta),
      action: stopPropagationAndCall(useOAuth("meta")),
      icon: <Facebook/>,
    },
    microsoft: {
      show: company === "microsoft" && (!accounts.isOauth.microsoft),
      action: stopPropagationAndCall(useOAuth("microsoft")),
      icon: <Microsoft/>,
    },
    feedcast: {
      show: false,
      action: () => {

      },
      icon: <React.Fragment/>
    }
  };

  const currentCompanyConfig = companyConfig[company];

  return (
    <React.Fragment>
      {currentCompanyConfig?.show && (
        <Box>
          <Box
            sx={{
              background: (theme) => theme.palette.gradient.main,
              p: 2
            }}
          >
            <Stack spacing={1}>
              <Typography variant={"h6"}>
                <FormattedMessage id={'begin-channel-config'} />
              </Typography>
              <Box>
                <Button
                  {...commonProps}
                  onClick={currentCompanyConfig.action}
                  startIcon={currentCompanyConfig.icon}
                >
                  <FormattedMessage id={"configure-canal"}/>
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
