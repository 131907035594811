import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  CampaignFilters,
  getCampaignsFilters,
  setCampaignsFilters,
  toggleCampaignsFilters
} from "../../../../../app/slices/campaigns";
import { CompanyLogo } from "../../../../App/Blocks/Misc";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getCurrentFeedSubStatus, getUser } from "../../../../../app/slices/app";
import { modalShowSubscription } from "../../../../../app/slices/modal";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

type FiltersHeaderProps = {
  toggleNew: () => void,
}

const FiltersHeader = ({ toggleNew }: FiltersHeaderProps) => {
  const user = useAppSelector(getUser);
  const currSubStatus = useAppSelector(getCurrentFeedSubStatus);
  const canCreateCampaign = user?.is_free || !(currSubStatus === "free" && !user?.is_demo);
  const dispatch = useAppDispatch();
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexGrow={1}>
      <Filters/>
      <Box display={'flex'} alignItems={"center"}>
        <Button
          variant={"contained"}
          onClick={() => canCreateCampaign
            ? toggleNew()
            : dispatch(modalShowSubscription())}
        >
          <FormattedMessage id={"new-campaign"}/>
        </Button>
      </Box>
    </Box>
  )
}

const Filters = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector<CampaignFilters>(getCampaignsFilters);
  const setFilters = (f: CampaignFilters) => dispatch(setCampaignsFilters(f));

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      {/*<FilterButton*/}
      {/*  hideDelete*/}
      {/*  active={filters.is_feedcast}*/}
      {/*  label={<FormattedMessage id={"campaigns-feedcast"}/>}*/}
      {/*  onClick={() => dispatch(toggleCampaignsFilters({ toggle: "is_feedcast" }))}*/}
      {/*/>*/}

      {/*<FilterButton*/}
      {/*  hideDelete*/}
      {/*  active={filters.all}*/}
      {/*  label={<FormattedMessage id={"all-campaigns"}/>}*/}
      {/*  onClick={() => dispatch(toggleCampaignsFilters({ toggle: "all" }))}*/}
      {/*/>*/}

      <ToggleButtonGroup
        exclusive
        size={"small"}
        onChange={(e, value) => dispatch(toggleCampaignsFilters({toggle: value}))}
        value={filters.all ? "all" : "is_feedcast"}
      >
        <ToggleButton value={"is_feedcast"}>
          <FormattedMessage id={"campaigns-feedcast"}/>
        </ToggleButton>
        <ToggleButton value={"all"}>
          <FormattedMessage id={"all-campaigns"}/>
        </ToggleButton>
      </ToggleButtonGroup>

      {/*<Box color={"text.disabled"}>|</Box>*/}

      {/*<FilterButton*/}
      {/*  active={filters["perf-max"]}*/}
      {/*  label={<Box display={"flex"} alignItems={"center"}>*/}
      {/*    <CompanyLogo company={"google"} size={'icon'}/>*/}
      {/*    <Box ml={0.5}>Google Perf Max</Box>*/}
      {/*  </Box>}*/}
      {/*  onClick={() => setFilters({ ...filters, "perf-max": !filters["perf-max"] })}*/}
      {/*/>*/}

      {/*<FilterButton*/}
      {/*  label={<Box display={"flex"} alignItems={"center"}>*/}
      {/*    <CompanyLogo campaignType={"shopping"} size={'icon'}/>*/}
      {/*    <Box ml={0.5}>Google Shopping</Box>*/}
      {/*  </Box>}*/}
      {/*  onClick={() => setFilters({ ...filters, shopping: !filters.shopping })}*/}
      {/*  active={filters.shopping}*/}
      {/*/>*/}

      {/*<FilterButton*/}
      {/*  active={filters["meta-classic"]}*/}
      {/*  onClick={() => setFilters({ ...filters, "meta-classic": !filters["meta-classic"] })}*/}
      {/*  label={<Box display={"flex"} alignItems={"center"}>*/}
      {/*    <CompanyLogo company={"meta"} size={'icon'}/>*/}
      {/*    <Box ml={0.5}>Meta Classic</Box>*/}
      {/*  </Box>}*/}
      {/*/>*/}

      {/*<FilterButton*/}
      {/*  label={<Box display={"flex"} alignItems={"center"}>*/}
      {/*    <CompanyLogo campaignType={"meta-advantage"} size={'icon'}/>*/}
      {/*    <Box ml={0.5}>Shopping Advantage +</Box>*/}
      {/*  </Box>}*/}
      {/*  onClick={() => setFilters({ ...filters, "meta-advantage": !filters["meta-advantage"] })}*/}
      {/*  active={filters["meta-advantage"]}*/}
      {/*/>*/}

      {/*<FilterButton*/}
      {/*  label={<Box display={"flex"} alignItems={"center"}>*/}
      {/*    <CompanyLogo campaignType={"microsoft-shopping"} size={'icon'}/>*/}
      {/*    <Box ml={0.5}>Bing Shopping</Box>*/}
      {/*  </Box>}*/}
      {/*  onClick={() => setFilters({ ...filters, "microsoft-shopping": !filters["microsoft-shopping"] })}*/}
      {/*  active={filters["microsoft-shopping"]}*/}
      {/*/>*/}
    </Stack>
  )
}

type FilterButtonProps = {
  label: any,
  onClick: () => void,
  active: boolean,
  className?: string,
  avatar?: any,
  hideDelete?: boolean,
}

const FilterButton = ({ onClick, active, className, label, hideDelete, avatar }: FilterButtonProps) => {
  const theme = useTheme();
  return <Chip
    avatar={avatar}
    size={'small'}
    color={"default"}
    label={label}
    onClick={onClick}
    variant={active ? "filled" : "outlined"}
    onDelete={onClick}
    className={`${className} ${active && "active"} ${hideDelete && "hideDelete"}`}
  />
}

export default FiltersHeader;
