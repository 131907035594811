import { useAppDispatch } from "../../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../../app/utils";
import React from "react";
import { GridRowModel } from "@mui/x-data-grid";
import { NewDangerNotification, NewSuccessNotification } from "../../../../../app/slices/notifications";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { adsCampaigns } from "../../../../../app/slices/campaign/constants";
import { CampaignType } from "../../../../../app/types/campaign";

/**
 * useHandleBudget.tsx
 * Displays a confirmation box when the users edits the campaign's budget
 * uses Promises
 */

type HandleBudgetEditProps = {
  setLoading: (loading: boolean) => void,
  mutation: any,
}
export const useHandleBudget = (p: HandleBudgetEditProps) => {

  const { setLoading, mutation } = p;

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);

  const [ promiseArguments, setPromiseArguments ] = React.useState<any>(null);
  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow);
        }
      }),
    [],
  )

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    try {
      setLoading(true);
      await mutation({
        feed,
        provider: adsCampaigns[newRow.type as CampaignType].provider as any,
        campaign: newRow.id,
        data: {
          budget: newRow.budget
        }
      });
      setLoading(false);
      dispatch(NewSuccessNotification("success-edit-campaign"));
      resolve(newRow);
      setPromiseArguments(null);
    } catch {
      dispatch(NewDangerNotification("error-edit-campaign"));
      reject(oldRow);
      setPromiseArguments(null);
    }
  }

  const DConfirmBudget = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        // TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {/*{`Pressing 'Yes' will change ${mutation}.`}*/}
          <FormattedMessage id={"pressing-yes-will"} />
          {mutation}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return [
    processRowUpdate,
    DConfirmBudget
  ] as const;
}

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  if (newRow.budget !== oldRow.budget) {
    return <React.Fragment>
      budget <FormattedMessage id={"from"} /> <b>{oldRow.budget || ''}€</b> <FormattedMessage id={"to"} /> <b>{newRow.budget || ''}€</b>
    </React.Fragment>
  }
  return null;
}
