import MetricCards from "./MetricsCards";
import DevicesChart from "./DevicesChart";
import CatalogPercentages from "./CatalogPercentages";
import AccountsMetrics from "./AccountsMetrics";
import React from "react";
import { LoadingAnimation } from "../../App/Blocks/Misc";
import PerfChart2 from "./PerfChart2";
import { Box, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import AppSecured from "../../App/AppSecured/AppSecured";
import DatePicker from "../../App/Blocks/Datepicker";

const GlobalViewPerformances = () => {
  return <AppSecured>
    <DatePicker />
    <LoadingAnimation data={true}>
      <MetricCards/>
      <Box mt={1}>
        <Grid2 container spacing={3}>
          <Grid2 xs={9}>
            <PerfChart2/>
          </Grid2>
          <Grid2 xs={3}>
            <Stack spacing={2}>
              <CatalogPercentages/>
              <DevicesChart/>
              <AccountsMetrics/>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </LoadingAnimation>
  </AppSecured>
}

export default GlobalViewPerformances;
