import { faker } from "@faker-js/faker";
import { CampaignType } from "../types/campaign";

const generateFloat = (min: number, max: number): number => parseFloat((Math.random() * (max - min) + min).toFixed(2));

const getCampaign = (): any => {
  const type = Object.values(CampaignType).filter(value => typeof value === 'number')[Math.floor(Math.random() * Object.values(CampaignType).length)];

  return {
    id: faker.datatype.number({ min: 10000000000, max: 99999999999 }).toString(),
    name: faker.company.catchPhrase(),
    enabled: faker.datatype.boolean(),
    budget: generateFloat(1, 20),
    type: type && type > 0 ? type : 3,
    is_feedcast: faker.datatype.boolean(),
    target_roas: faker.datatype.boolean() ? null : generateFloat(1, 1000),
    target_cpc: faker.datatype.boolean() ? null : generateFloat(0.1, 1),
    bidding_strategy: faker.datatype.number({ min: 0, max: 3 }),
    restrictions: faker.datatype.boolean() ? ['BUDGET_CONSTRAINED'] : []
  };
};

const genCampaigns = (n: number): any => {
  const account = faker.datatype.number({ min: 1000000000, max: 9999999999 }).toString();
  const campaigns = Array.from({ length: n }, () => getCampaign());

  return {
    account,
    campaigns
  };
};

export default genCampaigns;

