import React from "react";
import { Box, Fade, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ArrowRightAltRounded } from "@mui/icons-material";

interface PlanDisplayProps {
  currSubStatus: string | null;
  selectedPackCode: string | null;
}

export const PlanDisplay: React.FC<PlanDisplayProps> = ({ currSubStatus, selectedPackCode }) => {
  return <Box>
    <Typography color={"text.secondary"} lineHeight={1}>
      Plan
    </Typography>
    <Stack direction={"row"} spacing={1}>
      <Typography variant={"h4"}>
        <FormattedMessage id={(currSubStatus ?? selectedPackCode) ?? "unknown"}/>
      </Typography>
      <Fade in={currSubStatus !== (selectedPackCode ?? "free")}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <ArrowRightAltRounded sx={{ color: "text.secondary" }}/>
          <Typography variant={"h4"}>
            <FormattedMessage id={selectedPackCode ?? "free"}/>
          </Typography>
        </Stack>
      </Fade>
    </Stack>
  </Box>
};
