import { FormControl, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { AiFieldProps } from './TitleDesc';

function getRandomRoundNumber() {
  return Math.round(Math.random() * (50 - 25 + 1)) + 25;
}

export const Title = ({ value, disabled, onChange }: AiFieldProps) => {
  return (
    <FormControl fullWidth>
      <TextField
        label={<FormattedMessage id={"title"} />}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </FormControl>
  )
}
