import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import authApi from "../../../../app/services/public/auth";
import { useAppDispatch } from "../../../../app/hooks";
import { NewSuccessNotification } from "../../../../app/slices/notifications";
import { redirect } from "../../../../app/slices/app";
import Urls from "../../../../assets/js/Urls";
import AppPublic from "../AppPublic";
import { Box, CircularProgress, Typography } from "@mui/material";
import { MarkEmailUnread } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { addDatalayerEvent, pushCrispEvent, pushGAEvent } from "../../../../app/events";

/**
 * Validate.tsx
 * Validates a user account from the link in his email
 */

const Validate = () => {

  const token = useParams().token as string;
  const dispatch = useAppDispatch();
  const [ verif, verifRes ] = authApi.useValidateMutation();
  const [ verifLoading, setVerifLoading ] = useState(false);
  const intl = useIntl();

  const [ cookies, setCookie, removeCookie ] = useCookies([ "fc-partner", "fc-partner-code" ]);
  useEffect(() => {
    if (!token && (cookies["fc-partner-code"] || cookies["fc-partner"])) {
      removeCookie("fc-partner");
      removeCookie("fc-partner-code");
    }
  }, [ cookies, token ]);

  useEffect(() => {
    if (token && !verifLoading) {
      verif({ token });
      setVerifLoading(true);
    }
  }, [ token, verifLoading ]);

  useEffect(() => {
    if (verifRes.isSuccess) {
      localStorage.removeItem("validate");
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "account-validate-success" })));

      const d = verifRes.data;
      pushCrispEvent("user:auth:signup", {
        email: d.email,
        company: d.company,
      }, "pink");
      pushGAEvent("signup");
      addDatalayerEvent("register_success");
      dispatch(redirect(Urls.auth.login));
    }
  }, [ verifRes.isSuccess ]);

  return <AppPublic type={"large"} page={"validate"}>
    <Box>
      {!token
        ? (<Box>
          <MarkEmailUnread fontSize={"large"}/>
          <Typography variant={'h3'} mt={3}><FormattedMessage id={"nearly-there"}/></Typography>
          <Typography><FormattedMessage id={"validate-email"}/></Typography>
        </Box>)
        : (<Box>
          <CircularProgress/>
          <Typography>
            <FormattedMessage id={'token-verification'}/>
          </Typography>
        </Box>)
      }
    </Box>
  </AppPublic>
}

export default Validate;
