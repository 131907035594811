import { ProductReportingBase } from "../types/products";
import { faker } from "@faker-js/faker";

const IDENTIFIER_MIN = 100;
const IDENTIFIER_MAX = 999;
const IMPRESSIONS_MIN = 400;
const IMPRESSIONS_MAX = 15000;
const CLICKS_MIN = 5;
const CLICKS_MAX = 250;
const SPENT_MIN = 0.5;
const SPENT_MAX = 25.0;
const CONVERSIONS_MIN = 0.9;
const CONVERSIONS_MAX = 3.0;
const CONVERSION_VALUE_MIN = 70;
const CONVERSION_VALUE_MAX = 400;

type IdentifierType = "go" | "fb" | null | string;

const generateReportData = (identifierType: IdentifierType = null): ProductReportingBase => {
  const identifier = identifierType || (Math.floor(Math.random() * (IDENTIFIER_MAX - IDENTIFIER_MIN) + IDENTIFIER_MIN)).toString();
  const impressions = Math.floor(Math.random() * (IMPRESSIONS_MAX - IMPRESSIONS_MIN) + IMPRESSIONS_MIN);
  const clicks = Math.floor(Math.random() * (CLICKS_MAX - CLICKS_MIN) + CLICKS_MIN);
  const spent = parseFloat((Math.random() * (SPENT_MAX - SPENT_MIN) + SPENT_MIN).toFixed(2));
  const conversions = parseFloat((Math.random() * (CONVERSIONS_MAX - CONVERSIONS_MIN) + CONVERSIONS_MIN).toFixed(6));
  const conversionValue = parseFloat((Math.random() * (CONVERSION_VALUE_MAX - CONVERSION_VALUE_MIN) + CONVERSION_VALUE_MIN).toFixed(8));
  const image = faker.image.imageUrl();
  const title = faker.lorem.sentence(6);

  return {
    identifier,
    impressions,
    clicks,
    spent,
    conversions,
    conversionValue,
    childs: [],
    image,
    title,
  };
};

interface GenProductReportingOptions {
  isFlat: boolean;
  numChannels?: number;
  numCampaigns?: number;
  numDates?: number;
  numProducts?: number;
}

const genProductReporting = (options: GenProductReportingOptions): ProductReportingBase[] => {
  const { isFlat, numChannels, numCampaigns, numDates, numProducts } = options;

  if (isFlat) {
    return Array.from({ length: numProducts || 1 }, () => generateReportData(null));
  }

  const data: ProductReportingBase[] = [];
  const channels = ['go', 'fb', 'bg'];

  const nChannels = numChannels || 2;
  const nCampaigns = numCampaigns || 3;
  const nDates = numDates || 7;
  const nProducts = numProducts || 5;

  channels.forEach((channel) => {
    const channelData = generateReportData(channel);

    for (let j = 0; j < nCampaigns; j++) {
      const campaignIdentifier = Math.random().toString();
      const campaignData = generateReportData(campaignIdentifier);

      for (let k = 0; k < nDates; k++) {
        const date = new Date();
        date.setDate(date.getDate() - k);
        const dateData = generateReportData(date.toLocaleDateString());

        for (let m = 0; m < nProducts; m++) {
          const productIdentifier = Math.random().toString();
          const productData = generateReportData(productIdentifier);
          dateData.childs.push(productData);
        }

        campaignData.childs.push(dateData);
      }

      channelData.childs.push(campaignData);
    }

    data.push(channelData);
  });

  return data;
};

export default genProductReporting;
