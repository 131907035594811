import fr from "./assets/langs/fr";
import en from "./assets/langs/en";
import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { useAppSelector } from "./app/hooks";
import { getAppMode, getLanguage } from "./app/slices/app";
import { makeAppTheme } from "./appTheme";
import { FormattedMessage, IntlProvider } from "react-intl";
import { useRouteError } from "react-router-dom";
import { AppsOutage } from "@mui/icons-material";
import ChatBot from "./components/App/Chatbot";

const ErrorElement = () => {
  const language = useAppSelector(getLanguage);
  const mode = useAppSelector(getAppMode);
  const theme = makeAppTheme(language, mode);
  const error = useRouteError();
  const isDev = useAppSelector(state => state.app.isDev);

  const serializeError = (err:any) => {
    const errorString = JSON.stringify(err, Object.getOwnPropertyNames(err));
    return errorString.replace("\n", '<br />');
  }

  const formattedErrorString = serializeError(error);

  return (
    <Box>
      <IntlProvider locale={language} messages={language === "fr" ? fr : en}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Box sx={{
            width: '100vw',
            height: '100vh',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}>
            <AppsOutage sx={{ fontSize: "3rem" }}/>
            <Typography variant="h3">
              Oops...
            </Typography>
            <Typography>
              <FormattedMessage id={'feedcast-issue'} />
            </Typography>
            <Box sx={{ width: '30vw' }}>
              {isDev && (
                <code style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: formattedErrorString }} />
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </IntlProvider>
      <ChatBot loadWithoutData={true}/>
    </Box>
  );
};

export default ErrorElement;
