import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: AppNotificationsSlice = {
  notifications: [],
  shopId: null,
};

const app_notifications = createSlice({
  name: "app_notifications",
  initialState,
  reducers: {
    addAppNotif: (state, action: PayloadAction<AppNotification>) => {
      state.notifications.push(action.payload);
    },
    removeAppNotif: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(a => a.id !== action.payload);
    },
    resetAppNotif: (state) => {
      state.notifications = [];
    }
  },
})

export const {
  addAppNotif,
  removeAppNotif,
  resetAppNotif
} = app_notifications.actions;
export const appNotificationsReducer = app_notifications.reducer;

//==================
// TYPES
//==================

export enum LogType {
  LOGIN = 100,
  SUBSCRIPTION_CREATION = 103,
  SUBSCRIPTION_UPDATE = 104,
  FEED_UPDATE = 105,
  CAMPAIGN_CREATE = 106,
  CAMPAIGN_UPDATE = 107,
  SUBSCRIPTION_CANCEL = 108,
  FEED_SYNC_END = 120,
  FEED_IMPORT_END = 121,
  FEED_IMPORT_DROP = 122,
  ACCOUNT_PAYMENT_MISSING = 123,
  NO_ACTIVE_CAMPAIGNS = 124,
  LIMITED_CAMPAIGNS = 125,
  FEED_DISABLE_PLATFORM = 127,
  FEED_ENABLE_PLATFORM = 128,
  FEED_MISSING_URL = 129,
  FEED_DOWNLOAD_ERROR = 130,
  MERCHANT_CENTER = 131,
  CAMPAIGN_NO_CONVERSIONS = 132,
  CAMPAIGN_LOW_ROI = 133,
  NO_TRACKING = 136,
  PLATFORM_RESET = 137,
}

export type AppNotificationType = LogType;

export type AppNotification = {
  id: number,
  type: AppNotificationType,
  data?: AppNotifData<FeedImportEndData | FeedSyncData | WarmupData | FeedDropData | PaymentMissing | NoActiveCampaigns | LimitedCampaigns | {}> | MerchantCenterData,
  date: number,
  read: boolean,
  showInBadge?: boolean,
  severity?: "info" | "warning" | "error",
  isViewed?: boolean,
}

export type MerchantCenterData = {
  title: string,
  detail: string,
  severity: MerchantCenterSeverity,
  link: string,
  feed: null,
}

export enum MerchantCenterSeverity {
  WARNING = 1,
  ERROR,
  CRITICAL
}

export const MerchantSeverityMap: Record<MerchantCenterSeverity, "info" | "warning" | "error"> = {
  [MerchantCenterSeverity.WARNING]: "warning",
  [MerchantCenterSeverity.ERROR]: "error",
  [MerchantCenterSeverity.CRITICAL]: "error",
}

export enum EventCompanySource {
  "google" = 1,
  "meta"
}

type AppNotificationsSlice = {
  notifications: Array<AppNotification>,
  shopId: number | null,
}

export type AppNotifData<T> = {
  timestamp: number,
  feed: number,
  message?: string,
  link?: string,
} & T;

export type FeedImportEndData = {
  imported: number,
}

export type FeedSyncData = {
  source: EventCompanySource,
  errors?: number,
  warnings?: number,
}

export type WarmupData = {
  source: EventCompanySource,
  error: string | null,
  campaign?: string,
  success: boolean,
}

type AccountSource = {
  account: string,
  source: "1" | "2" | "3"
}

export type FeedDropData = {
  previous: number,
  current: number,
}

export type CampaignsNotificationsData = {
  campaigns: string[],
}

export type PaymentMissing = AccountSource;
export type NoActiveCampaigns = AccountSource;
export type LimitedCampaigns = AccountSource & { campaigns: string[] };

//==================
// GETTERS
//==================
export const getAppNotifications = (state: RootState) => state.appNotifications.notifications;

