import { Avatar, Box, Button, CardHeader, Divider, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getFeedSubStatus, niceUrl } from "../../../../app/utils";
import { Link } from "react-router-dom";
import Urls from "../../../../assets/js/Urls";
import Locale from "../../../App/Blocks/Locale";
import feedApi, { feedStatusClassesMUI } from "../../../../app/services/secured/feed";
import { DateFromTimestamp } from "../../../App/Blocks/Misc";
import React from "react";
import { Close, Launch, Payment, Storefront } from "@mui/icons-material";
import FCard, { FCardHeader } from "../../../App/Blocks/Card";
import profileApi from "../../../../app/services/secured/profile";
import { Invoice } from "../../../../app/types/profile";
import { UserFeed } from "../../../../app/types/feed";
import InvoiceTables from "./InvoiceTables";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link as LinkMUI } from "@mui/material";

const FeedsTable = () => {
  const feeds = feedApi.useGetFeedsQuery();
  const feedList = feeds.data ?? [];
  const invoices = profileApi.useGetInvoicesQuery();
  const invoiceList = invoices.data ?? [];
  const getFeedInvoices = (f_id: number) => invoiceList.filter(i => i.feed_id === f_id);
  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <StripeEdit />
      </Box>
      {feedList.map(f => (
        <FeedCard
          f={f}
          key={`f-${f.id}`}
          feedInvoices={getFeedInvoices(f.id)}
        />
      ))}
    </Stack>
  )
}

const StripeEdit = () => {
  return (
    <Box>
      <Button
        color={"secondary"}
        component={LinkMUI}
        href={process.env.REACT_APP_STRIPE_LINK}
        variant={"contained"}
        endIcon={<Launch />}
      >
        <FormattedMessage id={'stripe-edit-card'} />
      </Button>
    </Box>
  )
}


interface FeedCardProps {
  f: UserFeed,
  feedInvoices: Invoice[],
}

const FeedCard = ({ feedInvoices, f }: FeedCardProps) => {
  const subStatus = getFeedSubStatus(f);
  return (
    <FCard variant={"no-padding"}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pr: 3 }}>
        <CardHeader
          avatar={(
            <Avatar sx={{ backgroundColor: `${feedStatusClassesMUI[subStatus]}.main` }}>
              <Storefront />
            </Avatar>
          )}
          title={(
            <Typography>
              {niceUrl(f.name)}
            </Typography>
          )}
          subheader={(
            <Typography variant={"body2"} color={`${feedStatusClassesMUI[subStatus]}.main`}>
              <FormattedMessage id={subStatus ?? 'unknown'} />
            </Typography>
          )}
        />
        <Box>
          <Button
            variant={"contained"}
            component={Link}
            to={Urls.settings.subEdit(f.id)}
          >
            <FormattedMessage id={"edit"} />
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box p={4} px={6}>
        <Grid2 container alignItems={"start"} spacing={2}>
          <Grid2 container spacing={2} xs={4}>
            <Grid2 xs={6}>
              <Typography variant={"body2"}>
                Abonnement
              </Typography>
              <Typography color={`${feedStatusClassesMUI[subStatus]}.main`}>
                <FormattedMessage id={subStatus} />
              </Typography>
            </Grid2>
            <Grid2 xs={6}>
              <Box>
                <Typography variant={"body2"}>
                  <FormattedMessage
                    id={!f.current_subscription?.is_cancelled ?
                      'renew-date' : 'end-sub-date'
                    }
                  />
                </Typography>
                <Typography>
                  {f.current_subscription
                    ? DateFromTimestamp(f.current_subscription.date_end_subscription, true)
                    : <Close sx={{ color: "text.disabled" }} />}
                </Typography>
              </Box>
            </Grid2>
            <Grid2 xs={6}>
              <Typography variant={"body2"}>
                Langue de diffusion
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Locale locale={f.locale} />
                <Typography>
                  <FormattedMessage id={f.locale ?? 'unknown'} />
                </Typography>
              </Box>
            </Grid2>
            <Grid2 xs={6}>
              <Box>
                <Typography variant={"body2"}>
                  Produits
                </Typography>
                <Box sx={{ display: "flex", alignItems: "end", gap: 0.5 }}>
                  <Typography>
                    {f.last_stat?.imported ?? 0}
                  </Typography>
                  <Typography variant={"caption"} color={"text.secondary"}>
                    / {f.current_subscription?.plan.limit ?? 1000}
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
          <Grid2 xs>
            <Typography variant={"subtitle2"}>
              Factures
            </Typography>
            <InvoiceTables invoices={feedInvoices} />
          </Grid2>
        </Grid2>
      </Box>
    </FCard>
  )
}

export default FeedsTable;
