import productsApi from "../../../../../app/services/secured/products";
import { useEffect } from "react";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import {
  getProduct,
  getProductCustomData,
  initProductVersion,
  setInitProduct
} from "../../../../../app/slices/product";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { GetCurrentFeedId } from "../../../../../app/utils";

const useUpdateProduct = () => {

  const pId = useAppSelector(getProduct)?.id ?? 0;
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const customData = useAppSelector(getProductCustomData);

  const [ setProduct, setProductRes ] = productsApi.useSetProductMutation();

  const updateProduct = async (callback?: () => void) => {
    if (pId) {
      await setProduct({
        feed,
        product: pId as number,
        data: customData
      }).unwrap();

      if (callback) {
        callback();
      }
    }
  }

  useEffect(() => {
    if (setProductRes.isSuccess) {
      dispatch(NewSuccessNotification("success-edit-product"));
      dispatch(productsApi.util.invalidateTags([ "Product", "Products" ]));

      // Initialise AI versions in the state
      dispatch(initProductVersion({ field: "title" }));
      dispatch(initProductVersion({ field: "description" }));
    }
  }, [ setProductRes ]);

  return {
    updateProduct,
    api: setProductRes,
  }
}

export default useUpdateProduct;
