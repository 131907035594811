import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { GetCurrentFeedId } from "../../../../../app/utils";
import Urls from "../../../../../assets/js/Urls";
import { Subscriptions } from "@mui/icons-material";
import DialogActions from "@mui/material/DialogActions";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";

const DialogUpsell = () => {
  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const open = useAppSelector(isModalOpened(ModalTypes.UpsellChannel));
  const close = () => dispatch(closeModal());
  return (
    <Dialog open={open} maxWidth={"xs"} fullWidth onClose={close}>
      <DialogTitle>
        <FormattedMessage id={'channels-limit'} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            <FormattedMessage id={'channels-limit-desc'} />
          </Typography>
          <Typography>
            <FormattedMessage id={'channels-limit-desc-2'} />
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <FormattedMessage id={'close'} />
        </Button>
        <Button
          component={Link}
          to={Urls.settings.subEdit(feed)}
          variant={"contained"}
          endIcon={<Subscriptions />}
          color={"secondary"}
          onClick={close}
        >
          <FormattedMessage id={'subscriptions'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogUpsell;
