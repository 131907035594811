import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Collections } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { PerfMaxPictureNode } from "../../../../../../../app/types/campaign";
import CampaignCard from "../../../CampaignCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PicturesWarnings } from "./PicturesWarnings";
import { PicturesHolder } from "./PicturesHolder";

export const picturesRequirements: Record<PerfMaxPictureNode, {
  recommendation: {
    nb: number,
    sizes: number[],
  },
  min: number[],
}> = {
  "logos": {
    recommendation: {
      nb: 1,
      sizes: [ 1200, 1200 ]
    },
    min: [ 128, 128 ]
  },
  "marketing_images_square": {
    recommendation: {
      nb: 4,
      sizes: [ 1200, 1200 ]
    },
    min: [ 300, 300 ]
  },
  "marketing_images": {
    recommendation: {
      nb: 4,
      sizes: [ 1200, 628 ]
    },
    min: [ 600, 314 ]
  },
  "marketing_images_portrait": {
    recommendation: {
      nb: 2,
      sizes: [ 960, 1200 ]
    },
    min: [ 480, 600 ]
  },
}

const PicturesAssets = () => {
  const picturesNodes = Object.keys(picturesRequirements) as PerfMaxPictureNode[];
  return (
    <CampaignCard icon={<Collections/>} title={"perf-max-photos"}>
      <Stack spacing={1}>
        <PicturesWarnings/>
        <Grid2 container spacing={1}>
          {picturesNodes.map(pictureNode => {
            const { recommendation, min } = picturesRequirements[pictureNode];
            return (
              <Grid2 key={pictureNode} xs>
                <Stack spacing={1}>
                  <Box>
                    <Typography variant={"subtitle2"}>
                      <FormattedMessage id={pictureNode}/>
                    </Typography>
                    <Box sx={{ lineHeight: 1 }}>
                      <Typography variant={"caption"} component={'div'} color={"text.secondary"}>
                        <FormattedMessage
                          id={'pictures-perf-max-recommendations'}
                          values={{
                            nb: recommendation.nb,
                            min: recommendation.sizes[0],
                            max: recommendation.sizes[1],
                          }}
                        />
                      </Typography>
                      <Typography variant={"caption"} color={"text.secondary"}>
                        {`min. ${min[0]} x ${min[1]} px`}
                      </Typography>
                    </Box>
                  </Box>
                  <PicturesHolder type={pictureNode}/>
                </Stack>
              </Grid2>
            )
          })}
        </Grid2>
      </Stack>
    </CampaignCard>
  )
}

export default PicturesAssets;
