import { Box, Card, CardContent, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import feedApi from '../../../../app/services/secured/feed';


const fadeVariants = {
   enter: { opacity: 1, transition: { duration: 0.3 } },
   exit: { opacity: 0, transition: { duration: 0.3 } }
};

const contentVariants = {
   enter: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
   exit: { opacity: 0, scale: 0.9, transition: { duration: 0.3 } }
};

const FeedPreview: React.FC<{
   formValues: any;
   handleChange: (name: string, value: any) => void;
   errors: any;
   feedUrl: string;
}> = ({ formValues, handleChange, errors, feedUrl }) => {
   const [preview, previewRes] = feedApi.useNewFeedPreviewMutation();
   const isLoading = previewRes.isLoading;

   const productCount = previewRes.data?.count ?? 0;

   useEffect(() => {
      if (feedUrl) {
         preview({ test_url: feedUrl });
      }
   }, [feedUrl]);

   return (
      <Card
         elevation={0}
         sx={{
            background: 'transparent',
            border: 'none',
            backdropFilter: 'blur(8px)',
            '& .MuiCardContent-root': {
               background: 'transparent'
            }
         }}
      >
         <CardContent>
            <Box sx={{ position: 'relative', minHeight: 120, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <AnimatePresence mode="wait">
                  {isLoading || !previewRes.data ? (
                     <motion.div
                        key="loader"
                        variants={fadeVariants}
                        initial={{ opacity: 0 }}
                        animate="enter"
                        exit="exit"
                     >
                        <CircularProgress size={48} />
                     </motion.div>
                  ) :
                     <motion.div
                        key="content"
                        variants={contentVariants}
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate="enter"
                        exit="exit"
                        style={{ textAlign: 'center' }}
                     >
                        <Typography
                           variant="h2"
                           color="primary"
                           sx={{
                              fontWeight: 700,
                              mb: 1
                           }}
                        >
                           {productCount}
                        </Typography>
                        <Typography
                           variant="subtitle1"
                           color="text.secondary"
                           sx={{
                              letterSpacing: 1,
                              textTransform: 'uppercase',
                              fontWeight: 500
                           }}
                        >
                           <FormattedMessage id="products-found" />
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                           <Divider sx={{ my: 2, width: '1rem' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                           <FormattedMessage id="shop-autogeneration-desc" />
                        </Typography>
                     </motion.div>
                  }
               </AnimatePresence>
            </Box>

            <Grid container spacing={2}>
               <Grid item xs={12} sx={{ display: "none" }}>
                  <TextField
                     fullWidth
                     label="Feed URL"
                     name="feed[url]"
                     value={feedUrl || ''}
                     onChange={(e) => handleChange('feed[url]', e.target.value)}
                     error={!!errors['feed[url]']}
                     helperText={errors['feed[url]']}
                  />
               </Grid>
            </Grid>
         </CardContent>
      </Card>
   );
};

export default FeedPreview;