import { Box, List } from "@mui/material";
import React, { useMemo } from "react";
import { AppNotification } from "../../../../app/slices/app_notifications";
import { Log } from "../../../../app/types/profile";
import { GetCurrentFeedId } from "../../../../app/utils";
import logsApi from "../../../../app/services/secured/logs";
import { linkedToFeed, useRenderNotifications } from "./appNotificationsUtils";

export const HistoryTab = () => {

  const feedId = GetCurrentFeedId();
  const historic = logsApi.useGetNotificationsHistoryQuery();

  const baseHistoric = useMemo(() =>
      (historic.data ?? [])
      .filter((log: Log) => linkedToFeed[log.log_type] ? feedId === log.feed?.id : true)
      .map(log => castLogToNotification(log))
    , [ historic, historic.data ]
  );

  return (
    <Box>
      <List disablePadding>
        {useRenderNotifications(baseHistoric)}
      </List>
    </Box>
  )
}

const castLogToNotification = (l: Log): AppNotification => ({
  id: l.id,
  type: l.log_type,
  data: l.data,
  date: l.date,
  read: false,
  showInBadge: false,
  isViewed: true,
});

