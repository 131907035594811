import React, { useEffect, useMemo } from "react";
import productsApi from "../../../app/services/secured/products";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ProductListFilters from "./Filters/ProductListFilters";
import {
  getProductsList,
  getProductsParams,
  handleProductList,
  PParams,
  setProductsParams
} from "../../../app/slices/products";
import { ProductParams } from "../../../app/types/products";
import ModalMultipleEdit from "./ModalMultipleEdit";
import { Box } from "@mui/material";
import { ProductsTable } from "./ProductsTable";
import AppSecured from "../../App/AppSecured/AppSecured";
import { setInitProduct } from "../../../app/slices/product";

export type ProductSearchParameters = Pick<ProductParams, 'q' | 'status' | 'error_code'> & Partial<Pick<ProductParams, "page">>;

const _ProductList = () => {

  const dispatch = useAppDispatch();
  const params = useAppSelector(getProductsParams);
  const paramsMemo = useMemo(() => params, [ params ]);

  const [ pQuery, productsQuery ] = productsApi.useLazyGetProductsQuery();
  const products = useAppSelector(getProductsList);

  useEffect(() => {
    if (paramsMemo.feed > 0) {
      pQuery(paramsMemo, true);
    }
  }, [ paramsMemo ]);

  const hasParams = (params.q?.length || 0) > 0 || (params.status || -1) > -1;
  const hasProducts = ((products.products.length || 0) > 0);

  const setParams = (p: PParams) => dispatch(setProductsParams(p));

  const setSearch = (s: ProductSearchParameters) => {
    setParams({ ...params, q: s.q, status: s.status, error_code: s.error_code, page: s.page ? s.page : params.page });
  };

  useEffect(() => {
    if (paramsMemo.feed > 0) {
      pQuery(paramsMemo);
    }
  }, [ paramsMemo ]);

  useEffect(() => {
    if (productsQuery.isSuccess && productsQuery.data) {
      dispatch(handleProductList(productsQuery.data));
    }
  }, [ productsQuery ]);

  useEffect(() => {
    dispatch(setInitProduct())
  }, []);

  return (
    <AppSecured>
      <Box>
        <ProductListFilters
          search={params}
          onSearch={setSearch}
          hasProducts={hasProducts}
          hasParams={hasParams}
        />
        <Box>
          <ProductsTable
            products={products.products}
            found={products.total ?? 0}
            loading={(productsQuery.isLoading || productsQuery.isFetching)}
            serverPage={params.page ?? 1}
            displayHelper={hasProducts || hasParams}
          />
        </Box>
      </Box>
      <ModalMultipleEdit/>
    </AppSecured>
  )
}

export default _ProductList;
