import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCurrentFeedHasLoaded, setSelectedProducts } from "../../../app/slices/app";
import { Fade, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import React, { ReactNode, useMemo } from "react";
import { PriorityHigh, SyncProblem } from "@mui/icons-material";

type NavigationItemProps = {
  icon: string | ReactNode,
  title: string,
  href: string | undefined,
  disabled?: boolean,
  isOpen: boolean,
  alert?: boolean,
}

const NavigationItem = ({ icon, title, href, isOpen, disabled, alert }: NavigationItemProps) => {

  const dispatch = useAppDispatch();
  const hasLoaded = useAppSelector(getCurrentFeedHasLoaded);
  const active = window.location.pathname === href;

  const onMenuChange = () => dispatch(setSelectedProducts([]));

  const showAlert = useMemo(() => {
    return (isOpen && alert && hasLoaded) ? <SyncProblem color={"error"}/> : null;
  }, [ alert, hasLoaded, isOpen ]);

  return (
    <Tooltip
      placement={"right"}
      arrow
      title={!isOpen ? (
        <Typography>
          <FormattedMessage id={title} />
        </Typography>
      ) : null}
    >
      <ListItemButton
        disabled={disabled || !hasLoaded}
        component={Link}
        to={href as string}
        onClick={onMenuChange}
        sx={{
          justifyContent: isOpen ? 'initial' : 'center',
          py: 1,
          "& *": { lineHeight: 1, }
        }}
        selected={active}
      >
        <ListItemIcon sx={{
          minWidth: '48px',
          justifyContent: "center",
          color: !isOpen && alert && hasLoaded ? "error.main" : undefined,
        }}>
          {icon}
        </ListItemIcon>
        <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
          <FormattedMessage id={title}/>
        </ListItemText>
        {showAlert}
      </ListItemButton>
    </Tooltip>
  )
};
export const MemoizedNavigationItem = React.memo(NavigationItem);
