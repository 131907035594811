import { useAppDispatch } from "../../../../app/hooks";
import { AppNotification, removeAppNotif } from "../../../../app/slices/app_notifications";
import React, { useEffect } from "react";
import { Box, List, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NotificationsOff } from "@mui/icons-material";
import logsApi from "../../../../app/services/secured/logs";
import { filterUniqueNotificationsType, useRenderNotifications } from "./appNotificationsUtils";

interface CurrentNotificationsProps {
  notifications: AppNotification[],
}

export const CurrentTab = ({ notifications }: CurrentNotificationsProps) => {
  const dispatch = useAppDispatch();

  const [ read, readRes ] = logsApi.useClearMessageMutation();

  // Show only unique types in Currents
  useEffect(() => {
    filterUniqueNotificationsType(notifications).forEach(n => {
      dispatch(removeAppNotif(n.id));
      read(n.id);
    });
  }, [ notifications.length ]);

  const hasNoNotifications = !notifications.length;

  return (
    <Box>
      <List disablePadding>
        {useRenderNotifications(notifications)}
        {hasNoNotifications && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection:"column",
              gap: 1,
              backgroundColor: (theme) => theme.palette.background.paper,
              minHeight: '10vh',
            }}
          >
            <NotificationsOff />
            <Typography variant={"subtitle2"}>
              <FormattedMessage id={'no-notifications'}/>
            </Typography>
          </Box>
        )}
      </List>
    </Box>
  )
}
