import { Product } from "../../../app/types/products";
import { CloudOff, Edit, Launch, Preview } from "@mui/icons-material";
import { Box, ButtonGroup, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { ProductListToolbar } from "./Filters/ProductListToolbar";
import { ImageLoader, MakeCategoryName } from "../../App/Blocks/Misc";
import { FeedDataFeedcast, GetCurrentFeedId } from "../../../app/utils";
import NoProductsList from "./NoProductsList";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAppMode } from "../../../app/slices/app";
import { Link } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import useLabels from "../../../app/hooks/useLabels";
import { getProductsParams, PRODUCTS_PER_PAGE, setProductsParams } from "../../../app/slices/products";
import useProductUtils from "../../../app/hooks/useProductUtils";
import { HelpLink } from "../../App/Helpdesk/HelpLink";
import { setProductPreview } from "../../../app/slices/product";

type ProductsTableProps = {
  found: number,
  products: Product[],
  loading: boolean,
  serverPage: number,
  displayHelper: boolean,
}

export const ProductsTable = ({
  found, products, loading, serverPage,
}: ProductsTableProps) => {

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const feed = GetCurrentFeedId();
  const mode = useAppSelector(getAppMode);
  const params = useAppSelector(getProductsParams);

  const [ paginationModel, setPaginationModel ] = useState({
    page: (serverPage - 1) ?? 0,
    pageSize: PRODUCTS_PER_PAGE,
  });

  const handlePage = (model: GridPaginationModel) => {
    setPaginationModel(model);
    dispatch(setProductsParams({
      ...params,
      page: model.page + 1,
      page_per: model.pageSize,
    }));
  }

  const sPageMemo = useMemo(() => serverPage, [ serverPage ]);

  useEffect(() => {
    if (sPageMemo > 0) {
      setPaginationModel(model => ({ ...model, page: (sPageMemo - 1) }));
    }
  }, [ sPageMemo ]);

  // Custom hooks
  // useLoading(loading);
  const { findProductLabels } = useLabels();
  const { getChannelStatus } = useProductUtils();

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <ImageLoader src={params.value}/>
        )
      }
    },
    { field: "id", headerName: "#", flex: 1 },
    {
      field: "product",
      headerName: intl.formatMessage({ id: "product" }),
      flex: 3,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const labels = params.row.labels;
        return <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between", width: '100%' }}>
          {params.row.productTitle}
          <Box sx={{ position: "absolute", right: 0 }}>
            {labels.component}
          </Box>
        </Box>
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <Box sx={{ display: "flex", gap: 1, justifyContent: "center", width: '100%' }}>
          {getChannelStatus(row.product, "google")}
          {getChannelStatus(row.product, "meta")}
          {getChannelStatus(row.product, "microsoft")}
        </Box>
      },
    },
    {
      field: "category",
      sortable: false,
      filterable: false,
      headerName: intl.formatMessage({ id: "category" }),
      flex: 2,
      renderCell: (params) => MakeCategoryName(params.value)
    },
    {
      field: "brand",
      headerName: intl.formatMessage({ id: "brand" }),
      flex: 2,
      sortable: false,
      filterable: false,
      renderCell: (params) => params.value ?? <CloudOff fontSize={"small"} color={"disabled"}/>,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Box display={"flex"} justifyContent={"end"} width={'100%'}>
          <ButtonGroup>
            <Tooltip arrow placement={"top"} title={<FormattedMessage id={'quick-edit'}/>}>
              <IconButton
                onClick={() => dispatch(setProductPreview(params.value.id))}
              >
                <Preview/>
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement={"top"} title={<FormattedMessage id={'product-edit'}/>}>
              <IconButton
                className={"product-edit-button"}
                component={Link}
                to={Urls.products.edit(feed, params.value.id)}
              >
                <Edit/>
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement={"top"} title={<FormattedMessage id={'see-product'}/>}>
              <IconButton
                component={'a'}
                href={params.value.link}
                target={'_blank'}
                onClick={(e) => e.stopPropagation()}
              >
                <Launch/>
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Box>
      }
    }
  ];

  const rows = products.map(p => {
    const productData = FeedDataFeedcast(p);
    return {
      id: p.product_id,
      f_id: p.id,
      image: productData.image_link,
      product: p,
      productTitle: productData.title,
      category: productData.google_product_category_k,
      brand: productData.brand_k,
      labels: findProductLabels(productData.id_k),
      actions: {
        id: p.id,
        link: productData.link,
      },
    }
  });

  return (
    <Box sx={{ ".MuiDataGrid-virtualScroller": { minHeight: '40vh' } }}>
      <DataGridPremium
        loading={loading}
        unstable_cellSelection
        disableRowSelectionOnClick
        columns={columns}
        rows={rows}
        rowCount={found}
        pagination
        paginationMode={"server"}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePage}
        keepNonExistentRowsSelected
        pageSizeOptions={[ 5, 10, 20, 50, 100, 500 ]}
        checkboxSelection
        slots={{
          // loadingOverlay: LinearProgress,
          toolbar: ProductListToolbar,
          noRowsOverlay: NoProductsList,
        }}
        sx={{
          backgroundColor: mode === "light" ? "#fff" : "",
          "& .MuiDataGrid-main>div:last-child": {
            display: "none"
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          }
        }}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={0.5}
        justifyContent={"end"}
        color={"text.secondary"}
        mt={1}
      >
        <Typography variant={"caption"}>
          <FormattedMessage id={"find-how"}/>
        </Typography>
        <HelpLink title={"edit-products"} help={"edit-products"}/>
        <Typography variant={"caption"}>
          <FormattedMessage id={"and-our"}/>
        </Typography>
        <HelpLink title={"feed-advices"} help={"feed-advices"}/>
      </Stack>
    </Box>
  )
}

