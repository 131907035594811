import { AvailableCompany } from "../../../../app/types/campaign";
import { ShopAccounts } from "../../../../app/types/feed";
import React, { useMemo, useState } from "react";
import { useAccounts } from "../../../../app/hooks/useAccounts";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ChannelHeader } from "./ChannelHeader/ChannelHeader";
import { ChannelServices } from "./ChannelServices/ChannelServices";
import { ChannelWrapperProps } from "./Channels";
import FCard from "../../../App/Blocks/Card";
import Tracking from "./ChannelServices/Tracking";

export const ChannelWrapper: React.FC<ChannelWrapperProps> = ({ company }) => {
  return (
    <Box>
      <ChannelHeader company={company}/>
      <Divider/>
      <ChannelServices company={company}/>
    </Box>
  )
}
