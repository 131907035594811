import ReactGA from "react-ga4";
import { Crisp, EventsColors } from "crisp-sdk-web";

export type CrispEvent =
  "user:auth:pre-signup"
  | "user:auth:signup"
  | "user:shop:new"
  | "user:shop:cms"
  | "user:shop:edit"
  | "user:shop:products:edit"
  | "user:shop:subscription:new"
  | "user:shop:subscription:edit"
  | "user:shop:subscription:cancel"
  | "user:accounts:google:oauth"
  | "user:accounts:google:ads:new"
  | "user:accounts:google:ads:link"
  | "user:accounts:google:merchant:phone"
  | "user:accounts:google:merchant:config"
  | "user:accounts:meta:oauth"
  | "user:accounts:meta:ads"
  | "user:accounts:meta:catalog"
  | "user:campaigns:free:googlefreelisting"
  | "user:campaigns:free:feedcastcomparator"
  | "user:campaigns:google:new"
  | "user:campaigns:meta:new"
  | "user:settings:profile"
  | "user:settings:password"
  ;

export type GAEvent =
  "pre_signup"
  | "signup"
  | "shop_skip"
  | "shop_success"
  | "account_success_gads"
  | "account_success_meta"
  | "subscribe_success_autopilot"
  | "subscribe_success_premium"
  | "unsubscribe_success"
  | "product_edit"
  ;


// Crisp
export const pushCrispEvent = (
  event: CrispEvent,
  data: Record<string, string | number | boolean | object | undefined>,
  color: string
) => {
  return Crisp.session.pushEvent(event, data, color as EventsColors);
}

// Google Analytics
export const pushGAEvent = (event: GAEvent) => ReactGA.event(event);

// June
export const pushJuneEvent = (event: string, data: object) => window.analytics.track(event, data);

// GTM
export const addDatalayerEvent = (event: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event,
  });
}
