import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { FormattedMessage } from "react-intl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Alert, Button, Typography } from "@mui/material";
import campaignApi from "../../../../../app/services/secured/campaign";
import { useEffect } from "react";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, EnableChannelProps, getModalData, isModalOpened, ModalTypes } from "../../../../../app/slices/modal";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { LoadingButton } from "../../../../App/Blocks/Misc";

const DialogEnableChannel = () => {

  const dispatch = useAppDispatch();

  const feed = GetCurrentFeedId();
  const show = useAppSelector(isModalOpened(ModalTypes.EnableChannel));
  const data = useAppSelector<EnableChannelProps|null>(getModalData);

  const mId = `toggle-channel-${data?.toBeEnabled ?? "true"}`;
  const toBeEnabled = data?.toBeEnabled;
  const provider = data?.provider;
  const company = (data?.company ?? "").toUpperCase();

  const close = () => {
    dispatch(closeModal());
  }

  const [ enableChannel, enableChannelRes ] = campaignApi.useEnableChannelMutation();
  useEffect(() => {
    if (enableChannelRes.isSuccess) {
      dispatch(NewSuccessNotification(`success-channel-toggle-${toBeEnabled}`));
      close();
    }
    else if (enableChannelRes.isError) {
      close();
    }
  }, [enableChannelRes]);

  const onEnable = () => {
    if (data && provider) {
      enableChannel({feed, provider, enable: data.toBeEnabled});
    }
  }

  return (
    <Dialog
      open={show}
      maxWidth={'xs'}
      fullWidth
      onClose={close}
    >
      <DialogTitle>
        <FormattedMessage id={mId} values={{company: company}} />
      </DialogTitle>
      <DialogContent dividers>
        {data?.source === "service" && (
          <Alert severity={"info"} sx={{mb: 1}}>
            <FormattedMessage id={'activate-channel-from-service'} />
          </Alert>

        )}
        <Typography>
          <FormattedMessage id={`${mId}-desc`} values={{company: company}}  />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <FormattedMessage id={"no"} />
        </Button>
        <LoadingButton
          data={enableChannelRes}
          variant={"contained"}
          color={ toBeEnabled ? "secondary" : "error"}
          onClick={onEnable}
          reset
        >
          <FormattedMessage id={"yes"} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogEnableChannel;
