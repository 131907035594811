import { useAppSelector } from "../../../app/hooks";
import { getLanguage } from "../../../app/slices/app";
import { Link, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Help } from "@mui/icons-material";
import React from "react";
import { helpdeskLinks } from "./helpdesk";

type HelpLinkProps = {
  title: string,
  help: string,
}

export const HelpLink = ({ title, help }: HelpLinkProps) => {
  const lang = useAppSelector(getLanguage);
  return (
    <Link
      target={'_blank'}
      href={helpdeskLinks[help][lang]}
      style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
    >
      <Typography variant={"caption"}><FormattedMessage id={title}/></Typography>
      <Help sx={{ mx: 0.5, fontSize: "1rem" }}/>
    </Link>
  )
}
