import { GetCurrentFeedId } from "../../../../../../app/utils";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import profileApi from "../../../../../../app/services/secured/profile";
import { getCurrentFeed, setIsFirstMerchantConfig } from "../../../../../../app/slices/app";
import campaignApi from "../../../../../../app/services/secured/campaign";
import React, { useEffect, useState } from "react";
import { FMsg, LoadingAnimation, LoadingButton } from "../../../../../App/Blocks/Misc";
import SimpleForm from "../../../../../App/Blocks/SimpleForm";
import { FormattedMessage } from "react-intl";
import Shippings from "./Shippings";
import globalApi from "../../../../../../app/services/secured/global";
import Phone from "./Phone";
import { getShopAccounts } from "../../../../../../app/slices/shop";
import { Box, Divider, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import merchant_logo from '../../../../../../assets/img/icons/merchant.svg';
import { AccountStatusData, MerchantStatusData } from "../../../../../../app/types/feed";
import { EmailRounded, PinDrop, Storefront } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

/**
 * MCenter.tsx
 * Either phone verification, or invoice information + shipment prices
 */

const MCenter = ({ data }: { data: MerchantStatusData }) => {
  const hide = !data?.address.is_phone_validated, merchant = data;
  return <Box>
    {hide
      ? <Phone isValid={!hide} phone={merchant?.address.phone}/>
      : <MCenterForm merchant={data}/>}
  </Box>
}

const MCenterForm = (p: { merchant: MerchantStatusData }) => {

  const { merchant } = p;

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(getShopAccounts);

  const [ setMerchant, setMerchantRes ] = campaignApi.useSetMerchantCenterMutation();

  const submit = (s: Pick<AccountStatusData, 'merchant'>) => {
    setMerchant({
      feed,
      data: s
    })
  }

  useEffect(() => {
    dispatch(setIsFirstMerchantConfig(!accounts.canCreateCampaigns.google));
  }, [ accounts ]);

  useEffect(() => {
    if (setMerchantRes.isSuccess) {
      dispatch(campaignApi.util.invalidateTags([ "AccountStatus" ]));
    }
  }, [ setMerchantRes ]);

  return <Box>
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={3} spacing={2}>
      <Box>
        <Typography variant={"h4"}>Merchant Center</Typography>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"m-center-desc"}/>
        </Typography>
      </Box>
      <img src={merchant_logo} width={'50px'}/>
    </Stack>

    <SimpleForm onSubmit={(s) => submit(s)}>
      <Stack spacing={2}>
        <InvoiceForm merchant={merchant}/>
        <Divider />
        <Shippings s={merchant?.shipping}/>
      </Stack>

      <Box mt={2} display={"flex"} justifyContent={"end"}>
        <LoadingButton
          data={setMerchantRes}
          type={'submit'}
          sx={{ px: 5 }}
          variant={"contained"}
        >
          <FormattedMessage id={"save"}/>
        </LoadingButton>
      </Box>
    </SimpleForm>
  </Box>
}

interface InvoiceFormProps {
  merchant: MerchantStatusData,
}

const InvoiceForm = (p: InvoiceFormProps) => {
  const { merchant } = p;
  const profile = profileApi.useGetProfileQuery();
  const currFeed = useAppSelector(getCurrentFeed);
  const profileData = profile.data;

  const defaultValues = merchant.address?.address ? merchant.address : profile.data?.merchant.address;
  const [ phoneValue, setPhoneValue ] = useState<string>((merchant?.contact.phone ?? profileData?.phone_number) ?? "");

  return (
    <LoadingAnimation data={profile}>
      <Box key={defaultValues?.address}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={"contact[email]"}
                label={FMsg("email")}
                defaultValue={merchant?.contact.email ?? profileData?.authentication.email}
                InputProps={{
                  startAdornment: <InputAdornment position={"start"}>
                    <EmailRounded/>
                  </InputAdornment>
                }}
                required
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={12}>
            <FormControl fullWidth>
              <MuiTelInput
                size={"small"}
                name={"contact[phone]"}
                label={FMsg("phone")}
                defaultCountry={"FR"}
                value={phoneValue}
                onChange={(val) => setPhoneValue(val)}
                required
              />

            </FormControl>
          </Grid2>
          <Grid2 xs={12}>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={"contact[url]"}
                label={<FormattedMessage id={"shop-url"}/>}
                defaultValue={merchant?.contact.url ?? currFeed?.name}
                required
                InputProps={{
                  startAdornment: <InputAdornment position={"start"}>
                    <Storefront/>
                  </InputAdornment>
                }}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={12}>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={"address[address]"}
                label={FMsg("address-full")}
                defaultValue={defaultValues?.address ?? defaultValues?.address}
                required
                InputProps={{
                  startAdornment: <InputAdornment position={"start"}>
                    <PinDrop/>
                  </InputAdornment>
                }}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={"address[zip]"}
                label={FMsg("zip")}
                defaultValue={merchant?.address.zip ?? defaultValues?.zip}
                required
              />
            </FormControl>
          </Grid2>
          <Grid2 xs>
            <FormControl fullWidth>
              <TextField
                size={"small"}
                name={"address[city]"}
                label={FMsg("city")}
                defaultValue={merchant?.address.city ?? defaultValues?.city}
                required
              />
            </FormControl>
          </Grid2>
          <Grid2 xs>
            <FormControl fullWidth>
              <CountryInput
                name={`address[country]`}
                value={merchant?.address.country ?? defaultValues?.country}
                label={FMsg("country")}
              />
            </FormControl>
          </Grid2>
        </Grid2>

      </Box>
    </LoadingAnimation>
  )
}

type CountryInput = {
  name: string,
  value?: string | null,
  label?: string;
}

export const CountryInput = ({ name, value, label }: CountryInput) => {
  const countries = globalApi.useCountriesQuery();
  return <TextField
    select
    key={countries.data?.length} name={name}
    defaultValue={value ?? ''}
    required
    label={label}
    size={"small"}
  >
    <MenuItem value={''}>...</MenuItem>
    {countries.data?.map(c => <MenuItem key={c.code} value={c.code}>{c.name}</MenuItem>)}
  </TextField>
}

export default MCenter;
