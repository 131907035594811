import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl, FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField, Typography
} from "@mui/material";
import { ImageLoader } from "../../../../App/Blocks/Misc";
import React, { useEffect, useState } from "react";
import { Clear, ContentCopy, ModeEdit } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import { ProductImageType } from "../hooks/useInitProduct";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getProduct, getProductCustomData, setCustomData } from "../../../../../app/slices/product";

export const ProductImage = () => {

  const product = useAppSelector(getProduct);
  const [ image, setImage ] = useState<ProductImageType>({ original: "", newImage: "", });

  useEffect(() => {
    if (product) {
      setImage({
        original: product.data.image_link,
        newImage: product.custom_data?.image_link ?? "",
      })
    }
  }, [ product ]);

  const [ hover, setHover ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);

  const img: string | undefined = image.newImage?.length ? image.newImage : image.original;

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "360px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
          pt: 2,
          cursor: "pointer",
          position: "relative",
          overflowX: "hidden",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          sx={{
            opacity: hover ? 0.5 : 1,
            transition: 'opacity 200ms ease-in-out',
          }}
        >
          <ImageLoader
            key={`image-${img?.length}`}
            height={'340px'}
            src={img}
          />
        </Box>
        {hover && (
          <Button
            size={"small"}
            variant={"contained"}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            endIcon={<ModeEdit/>}
            onClick={() => setOpenModal(true)}
          >
            <FormattedMessage id={'edit'}/>
          </Button>
        )}
      </Box>

      <EditImageDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        image={image}
        setImage={setImage}
      />
    </React.Fragment>
  )
}

interface EditImageDialogProps {
  image: ProductImageType;
  setImage: React.Dispatch<React.SetStateAction<ProductImageType>>;
  open: boolean;
  onClose: () => void;
}

const EditImageDialog = ({
  open, onClose, image, setImage,
}: EditImageDialogProps) => {

  const dispatch = useAppDispatch();
  const customData = useAppSelector(getProductCustomData);

  const hasNewImage = image.newImage !== image.original;
  const img = image.newImage.length ? image.newImage : image.original;

  const [ newImage, setNewImage ] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (newImage?.length) {
        setImage(prev => ({ ...prev, newImage }));
        dispatch(setCustomData({ ...customData, image_link: newImage }));
      } else {
        setImage(prev => ({ ...prev, newImage: "" }));
        dispatch(setCustomData({ ...customData, image_link: "" }));
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [ newImage?.length ]);

  useEffect(() => {
    if (hasNewImage) {
      setNewImage(image.newImage);
    }
  }, [ hasNewImage ]);

  const handleSave = () => {
    onClose();
    // updateProduct();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogContent>
        <Stack spacing={2} sx={{ position: "relative" }}>
          <Box sx={{
            height: '500px',
            overflowX: "hidden",
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transition: "opacity 0.8s ease-in-out",
          }}>
            {/*<ImageLoader*/}
            {/*  height={"500px"}*/}
            {/*  key={`image-${img?.length}`}*/}
            {/*  src={img}*/}
            {/*/>*/}
            <img src={img} height={"500px"} />
          </Box>

          <Box>
            <FormLabel>
              <FormattedMessage id={'original-image-url'} />
            </FormLabel>
            <Typography variant={"body2"} color={"text.secondary"}>
              {image.original?.length ? image.original : <FormattedMessage id={'no-original-image'} />}
            </Typography>
          </Box>


          <FormControl fullWidth>
            <FormLabel>
              <FormattedMessage id={'new-image-url'}/>
            </FormLabel>
            <TextField
              size={"small"}
              // label={}
              helperText={<FormattedMessage id={'leave-blank-for-original'} />}
              value={newImage}
              onChange={(e) => setNewImage(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton onClick={() => setNewImage("")} edge={"end"} size={"small"}>
                      <Clear fontSize={"small"}/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id={'close'}/>
        </Button>
        <Button
          onClick={handleSave}
          variant={"contained"}>
          <FormattedMessage id={'save'}/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
