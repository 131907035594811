import profileApi from "../../../../app/services/secured/profile";
import { DateFromTimestamp, Icon } from "../../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import FCard from "../../../App/Blocks/Card";
import feedApi from "../../../../app/services/secured/feed";
import { niceUrl } from "../../../../app/utils";
import { useState } from "react";
import Locale from "../../../App/Blocks/Locale";
import {
  Box,
  Button,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { DataObject, Storage } from "@mui/icons-material";

import { LogType } from "../../../../app/slices/app_notifications";
import logsApi from "../../../../app/services/secured/logs";

/**
 * History.tsx
 * Displays the history of the user
 * Mainly used to show campaign creates and edits, in order to prevent bad user behaviour
 * Now displays also app notifications
 */

const PER_PAGE = 5;

const History = () => {
  const logs = logsApi.useGetLogsQuery();
  const f = feedApi.useGetFeedsQuery();
  const feeds = f.data;
  const [ displayLimit, setDisplayLimit ] = useState(5);
  const historyArray = logs.data?.filter((l, i) => l.merchant_user?.authentication.email && l.log_type !== LogType.LOGIN).slice(0, displayLimit * PER_PAGE) ?? [];

  const [ dataToShow, setDataToShow ] = useState("");
  const [ showDialog, setShowDialog ] = useState(false);

  const handleShowData = (data: string) => {
    setDataToShow(data);
    setShowDialog(true);
  }

  return <Box>
    <Typography variant={"h5"} mb={1}><FormattedMessage id={"history"}/></Typography>
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '10%' }}>Date</TableCell>
          <TableCell><FormattedMessage id={"shop"}/></TableCell>
          <TableCell>Action</TableCell>
          <TableCell><FormattedMessage id={"data"}/></TableCell>
          <TableCell style={{ width: '10%' }} className={"text-end"}><FormattedMessage id={"ip-address"}/></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {historyArray.map((l, i) => {
        const fNode = feeds?.find(f => f.id === l.feed?.id);
        return <TableRow key={`l-${i}`}>
          <TableCell>{DateFromTimestamp(l.date, true)}</TableCell>
          <TableCell>
            {fNode && (<Stack direction={"row"} spacing={2}>
              <Locale locale={fNode?.locale}/>
              <Typography>{niceUrl(fNode?.name ?? "")}</Typography>
            </Stack>)}
          </TableCell>
          <TableCell>
            <FormattedMessage id={LogType[l.log_type]?.toLowerCase() ?? "unknown"}/>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => handleShowData(JSON.stringify(l.data, null, 2))}
              size={"small"}
              endIcon={<DataObject />}
            >
              <FormattedMessage id={"show-data"} />
            </Button>
          </TableCell>
          <TableCell className={"text-end"}>{l.address_ip}</TableCell>
        </TableRow>
      })}
      </TableBody>
    </Table>

    {(displayLimit * PER_PAGE) < (logs.data?.length ?? 0) && (
      <Box
        display={"flex"}
        justifyContent={"center"}
        mt={3}
      >
        <Button
          size={"small"}
          variant={"contained"}
          onClick={() => setDisplayLimit(displayLimit + 1)}
          endIcon={<Storage/>}
        >
          <FormattedMessage id={"load-more"}/>
        </Button>
      </Box>
    )}

    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
    >
      <Box p={4}>
        <pre>
          {dataToShow}
        </pre>
      </Box>
    </Dialog>
  </Box>
}

export default History;
