import React, { useEffect, useState } from "react";
import { getFeedSubStatus, niceUrl } from "../../../../app/utils";
import { Link as LinkDom, useParams } from "react-router-dom";
import Urls from "../../../../assets/js/Urls";
import { redirect } from "../../../../app/slices/app";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Alert, AlertTitle, Box, Button, Fade, Link, Paper, Stack, Typography } from "@mui/material";
import { BackButton, SkeletonLoader } from "../../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import Locale from "../../../App/Blocks/Locale";
import Grid2 from "@mui/material/Unstable_Grid2";
import SubPlans from "./Components/SubPlans";
import SubForm from "./SubForm";
import FCard from "../../../App/Blocks/Card";
import { ErrorOutline } from "@mui/icons-material";
import { Crisp } from "crisp-sdk-web";
import DialogCancelUpdates from "./Components/DialogCancelUpdates";
import { FeedSubscriptionData } from "../../../../app/types/feed";
import { useSubscription } from "./hooks/useSubscription";
import { AlertStripeLink } from "../Pages/Profile";

export type SubscriptionState = {
  pack: number,
  limit: number, //Stripe IDs
  feed: number,
  promotion_code?: string,
  platform?: number,
  ai?: number,
}

export interface SubInterface {
  currSub: SubscriptionState,
}

type SubscriptionProps = {
  isShown: boolean,
}

const Subscription: React.FC<SubscriptionProps> = ({ isShown }) => {

  const { feedId } = useParams<{ feedId: string }>();
  const feed = parseInt(feedId as string);

  const canSubscribe = useAppSelector(state => state.app.user?.can_subscribe);

  const [ sub, feedData ] = useSubscription(feed);

  const [ currSub, setCurrSub ] = useState<SubscriptionState>({ pack: 0, limit: 0, feed, ai: 0 });

  const [ loadPage, setLoadPage ] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoadPage(false), 1500);
  }, []);

  const getPackMinLimit = (packId: number) => sub.data?.prices.find(p => p.id === packId)?.max_products;

  useEffect(() => {
    if (sub.isSuccess && sub.data) {
      setCurrSub({
        pack: sub.data.prices.find(p => p.type === "pack" && p.selected)?.id ?? 0,
        limit: sub.data.prices.find(p => p.type === "limits" && p.selected)?.id ?? 0,
        platform: sub.data.prices.find(p => p.type === "platforms" && p.selected)?.id ?? 0,
        ai: sub.data.prices.find(p => p.code === "opt_ai" && p.selected)?.id ?? 0,
        feed,
      })
    }
  }, [ sub ]);

  return (
    <SkeletonLoader load={loadPage}>
      <Box>
        {canSubscribe ? (
          <SubscriptionContent
            sub={sub.data}
            feedData={feedData}
            currSub={currSub}
            setCurrSub={setCurrSub}
            getPackMinLimit={getPackMinLimit}
          />
        ) : (
          <CantSubscribe/>
        )}
      </Box>
    </SkeletonLoader>
  );
}

const CantSubscribe = () => {
  const dispatch = useAppDispatch();
  return (
    <Stack spacing={2}>
      <Box display={"flex"}>
        <BackButton
          onClick={() => dispatch(redirect(Urls.settings.subList))}
        />
      </Box>
      <Paper sx={{
        p: 5,
        background: (theme) => theme.palette.warning.main,
      }}>
        <Typography variant={"h4"}>
          <FormattedMessage id={'cant-subscribe'}/>
        </Typography>
        <Typography>
          <FormattedMessage
            id={'cant-subscribe-desc'}
            values={{
              br: <br/>,
            }}
          />
        </Typography>
      </Paper>
    </Stack>

  )
}

interface SubscriptionContentProps extends SubInterface {
  sub: FeedSubscriptionData | undefined,
  feedData: any,
  setCurrSub: React.Dispatch<React.SetStateAction<SubscriptionState>>,
  getPackMinLimit: (packId: number) => number | undefined | null,
}

const SubscriptionContent: React.FC<SubscriptionContentProps> = (p) => {

  const dispatch = useAppDispatch();
  const { sub, feedData, currSub, setCurrSub, getPackMinLimit } = p;

  const currSubStatus = feedData.data ? getFeedSubStatus(feedData.data) : "free";
  const hasChangesNextPeriod = ((sub?.next_periods?.length || 0) > 0 || ((sub?.next_removed_platforms?.length || 0) > 0));

  const profile = useAppSelector(state => state.app.profile);
  const [ showSubscriptionAlert, setShowSubscriptionAlert ] = useState(false);
  useEffect(() => {
    if (profile && profile.merchant.address.address === null) {
      setShowSubscriptionAlert(true);
    }
  }, [ profile ]);

  const packProductLimit = getPackMinLimit(currSub.pack);

  const activeSub = sub?.prices.filter(p => p.type === "pack" && p.id === currSub.pack)[0]?.code as any ?? "free";
  const onPackChange = (pack: number) => setCurrSub({
    ...currSub, pack, limit: 0, feed: currSub.feed, platform: undefined
  });
  const onLimitChange = (limit: number) => setCurrSub({ ...currSub, limit });
  const onCodeChange = (promotion_code: string) => setCurrSub({ ...currSub, promotion_code });
  const onAiChange = (ai: number) => setCurrSub({ ...currSub, ai });

  const onPlatformChange = (platform: number) => platform ? setCurrSub({
    ...currSub, platform
  }) : setCurrSub({ ...currSub, platform: undefined });

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <BackButton
            onClick={() => dispatch(redirect(Urls.settings.subList))}
          />
          <Box lineHeight={1}>
            <Typography variant={"h6"}>
              <FormattedMessage id={"update-subscription"}/>
            </Typography>
            {feedData.data && <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Locale locale={feedData.data?.locale}/>
              <Typography variant={"body2"}>{niceUrl(feedData.data?.name)}</Typography>
            </Stack>}
          </Box>
        </Stack>
      </Stack>

      <AlertStripeLink/>

      <Fade in={showSubscriptionAlert} unmountOnExit>
        <Box>
          <Alert severity={"warning"}>
            <AlertTitle>
              <FormattedMessage id={"cant-sub"}/>
            </AlertTitle>
            <FormattedMessage
              id={"cant-sub-desc"}
              values={{
                a: (txt) => <Link component={LinkDom} to={Urls.settings.me}>{txt}</Link>
              }}
            />
          </Alert>
        </Box>
      </Fade>
      <Box>
        {currSubStatus && currSubStatus !== "canceled"
          ? <Grid2 container spacing={2}>
            <Grid2 xs={9}>
              <SubPlans
                currSub={currSub}
                active={activeSub}
                onPackChange={onPackChange}
              />
            </Grid2>
            <Grid2 xs={3}>
              <SubForm
                currSub={currSub}
                discounts={sub?.discounts}
                min={packProductLimit}
                onLimitChange={onLimitChange}
                onCodeChange={onCodeChange}
                onPlatformChange={onPlatformChange}
                onAiChange={onAiChange}
              />
            </Grid2>
          </Grid2>
          : <CanceledBlock/>}
      </Box>
      <DialogCancelUpdates
        isShown={hasChangesNextPeriod}
        next_periods={sub?.next_periods ?? []}
        next_removed_platforms={sub?.next_removed_platforms ?? []}
        prices={sub?.prices ?? []}
      />
    </Stack>
  );
}

const CanceledBlock = () => {
  const openChat = () => Crisp.chat.open();
  return (
    <FCard>
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant={"h4"}>
            <FormattedMessage id={"canceled-subscription"}/>
          </Typography>
          <ErrorOutline fontSize={"large"}/>
        </Stack>
        <Box>
          <Typography>
            <FormattedMessage id={"sub-canceled-desc"}/>
          </Typography>
        </Box>
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={openChat}
        >
          <FormattedMessage id={"talk-to-expert"}/>
        </Button>
      </Stack>
    </FCard>
  )
}

export default Subscription;
