import { FormattedMessage, useIntl } from "react-intl";
import profileApi from "../../../app/services/secured/profile";
import SimpleForm from "../../App/Blocks/SimpleForm";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, FormControl, MenuItem, TextField } from "@mui/material";
import { ButtonsNav } from "./ButtonsNav";
import React from "react";
import { PageNavProps } from "./Onboarding";
import { AppKnownSource, AppUsage } from "../../../app/types/profile";


export const WhoAreYou = (p: PageNavProps) => {
  const intl = useIntl();
  const profile = profileApi.useGetProfileQuery();
  const [ onboard, onboardRes ] = profileApi.useSetOnboardingMutation();

  return <SimpleForm
    key={profile?.data?.first_name ?? "key"}
    onSubmit={s => {
      const merchant = {
        ...s.merchant,
        hear_about_us: parseInt(s.merchant?.hear_about_us),
        merchant_type: parseInt(s.merchant?.merchant_type)
      };

      onboard({
        first_name: s.first_name,
        last_name: s.last_name,
        merchant,
      });
    }}>
    <Grid2 container spacing={2}>
      <Grid2 xs>
        <FormControl margin={"normal"} fullWidth>
          <TextField
            name={"first_name"}
            required
            label={intl.formatMessage({ id: 'firstName' })}
            defaultValue={profile?.data?.first_name}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs>
        <FormControl margin={"normal"} fullWidth>
          <TextField
            name={"last_name"}
            required
            label={intl.formatMessage({ id: 'lastName' })}
            defaultValue={profile?.data?.last_name}
          />
        </FormControl>
      </Grid2>
    </Grid2>

    <FormControl margin={"normal"} fullWidth>
      <TextField
        select
        name={"merchant[merchant_type]"}
        required
        label={<FormattedMessage id={'what-situation'}/>}
        defaultValue={profile?.data?.merchant?.merchant_type}
      >
        <MenuItem value={AppUsage.FOR_MYSELF}><FormattedMessage id={"for-myself"}/></MenuItem>
        <MenuItem value={AppUsage.FOR_CUSTOMERS}><FormattedMessage id={"for-customers"}/></MenuItem>
      </TextField>
    </FormControl>

    <FormControl margin={"normal"} fullWidth>
      <TextField
        select
        required
        name={"merchant[hear_about_us]"}
        label={<FormattedMessage id={'how-do-you-know-us'}/>}
        defaultValue={profile?.data?.merchant?.hear_about_us}
      >
        <MenuItem value={AppKnownSource.GOOGLE}><FormattedMessage id={"on-google"}/></MenuItem>
        <MenuItem value={AppKnownSource.FB}><FormattedMessage id={"on-facebook"}/></MenuItem>
        <MenuItem value={AppKnownSource.LINKEDIN}><FormattedMessage id={"on-linkedin"}/></MenuItem>
        <MenuItem value={AppKnownSource.BLOG}><FormattedMessage id={"on-blog"}/></MenuItem>
        <MenuItem value={AppKnownSource.REPUTATION}><FormattedMessage id={"on-people"}/></MenuItem>
        <MenuItem value={AppKnownSource.UNKNOWN}><FormattedMessage id={"on-other"}/></MenuItem>
      </TextField>
    </FormControl>
    <Box mt={1}>
      <ButtonsNav
        {...p}
        currentApi={onboardRes}
      />
    </Box>
  </SimpleForm>
}
