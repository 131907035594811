import { useEffect, useMemo } from "react";
import Chart, { ChartDataset } from "chart.js/auto";
import FCard, { FCardHeader } from "../../App/Blocks/Card";
import { useAppSelector } from "../../../app/hooks";
import { getReportingMetric, Kpi } from "../../../app/slices/reporting";
import { percentage, sumReducer } from "../../../app/utils";
import style from "../../../assets/scss/variables.module.scss";
import { CloudOff, Tv } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DevicesTypes, ReportingRow } from "../../../app/types/feed";
import { useReporting } from "../../../app/hooks/useReporting";

interface DevicesChartProps {
  header?: boolean,
}

const DevicesChart = ({ header = true }: DevicesChartProps) => {
  const identifier = `chart-devices`;
  const reporting = useReporting();
  const selected = useAppSelector(getReportingMetric);

  const data = useMemo(() => getData({ reporting, selected: "impressions" }), [ reporting, selected ]);
  const hasData = data.filter(d => d > 0).length > 0;

  useEffect(() => {
    if (hasData) {
      const cht = getDeviceChart(identifier, getDeviceData(data));
      return () => {
        cht.destroy();
      };
    }

  }, [ data ]);

  return <Box mt={header ? 2 : 0} className={"devices-distribution"} sx={{ flex: 1 }}>
    {header && (
      <FCardHeader size={"small"} icon={<Tv/>} title={"devices-repartition"} margin={false}/>
    )}

    <FCard sx={{ mt: header ? 1 : 0, height:'100%' }}>
      <Box sx={{ height: '100%', display: "flex", flexDirection: "column", justifyContent: "center" }}>
        {hasData
          ? <canvas id={identifier} style={{ maxHeight: "80px" }}/>
          : <CloudOff/>}
      </Box>
    </FCard>
  </Box>
}

const getData = ({ reporting, selected }: { reporting: ReportingRow[], selected: Kpi }) => {
  const data: Array<number> = [ 0, 0, 0, 0 ];
  reporting?.forEach(provider => {
    provider.childs.forEach(campaign => {
      campaign.childs.forEach(date => {
        date.childs.forEach(device => {
          data[parseInt(device.identifier as string) as DevicesTypes] += device[selected];
        })
      })
    })
  });
  return data;
}

const getDeviceData = (data: number[]) => {
  const total = data.reduce(sumReducer, 0);
  const percentages = data.map(d => percentage(d, total).toPrecision(2));

  const labels = [
    `Desktop | ${percentages[1]}%`,
    `Mobile | ${percentages[2]}%`,
    `Tablet | ${percentages[3]}%`,
  ];

  const datasets: ChartDataset<"doughnut", string[]>[] = [
    {
      label: '%',
      data: [ percentages[1], percentages[2], percentages[3] ],
      backgroundColor: [
        style["red"],
        style["blue"],
        style["yellow"]
      ],
      borderWidth: 0,
    },
  ];


  return { labels, datasets }
}

const getDeviceChart = (identifier: string, data: any) => {
  return new Chart(identifier, {
    type: "doughnut",
    data: data,
    options: {
      cutout: 30,
      plugins: {
        legend: {
          position: "left",
          labels: {
            boxWidth: 10,
            boxHeight: 5,
            font: {
              size: 11,
              family:"Rubik",
            }
          }
        },
        tooltip: {
          callbacks: {
            label: getTooltipCallback(),
          }
        }
      }
    }
  });
}

const getTooltipCallback = () => (context: any) => {
  const label = context.label.split("|")[0];
  const value = context.formattedValue + "%";
  return `${label}: ${value}`;
}

export default DevicesChart;
