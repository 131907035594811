import { GetCurrentFeedId, getFeedSourceRecord } from "../../../../app/utils";
import { useAppSelector } from "../../../../app/hooks";
import { getCurrentFeed } from "../../../../app/slices/app";
import { FeedSourceEnum } from "../../../../app/types/feed";
import { BigDataCard } from "./BigDataCard";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import Urls from "../../../../assets/js/Urls";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Link, Typography } from "@mui/material";
import { Link as LinkDOM } from "react-router-dom";
import app_logo from "../../../../assets/img/icons/app-logo.svg";

export const ProductFeedCard = () => {
  const feed = GetCurrentFeedId();
  const currFeed = useAppSelector(getCurrentFeed);
  const hasProducts = currFeed?.last_stat?.imported ?? 0;
  const feedSource = currFeed?.source ? FeedSourceEnum[currFeed?.source] : null;

  const source = useMemo(() => getFeedSourceRecord({
    source: currFeed?.source ?? FeedSourceEnum.Manual,
    url: currFeed?.url ?? ""
  }), [ currFeed ]);

  return (
    <BigDataCard
      icon={<ShoppingBagOutlined fontSize={"large"}/>}
      primary={
        hasProducts ? (
          <React.Fragment>
            {hasProducts} <FormattedMessage id={'products'}/>
          </React.Fragment>
          ) : (
          <FormattedMessage id={'no-products'} />
         )
      }
      secondary={
        feedSource
          ? (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <img src={currFeed ? source.logo : app_logo} alt="Feed Logo" style={{ width: '20px' }}/>
              <Typography>
                {feedSource === "Manual" ? "CSV/XML/TXT" : feedSource}
              </Typography>
            </Box>

          )
          : (
            <Button
              size={"small"}

              variant={"contained"}
              component={LinkDOM}
              to={Urls.configuration(feed)}>
              <FormattedMessage id={'import-product-catalog'}/>
            </Button>
          )
      }
    />
  )
}
