import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { ApiSuccess, Paginated } from "../../../../types";
import { ReportingPeriodString } from "../../slices/reporting";
import {
  CategoryMap,
  Product,
  ProductData,
  ProductParams,
  ProductReporting,
  ProductReportingBase,
  ProductReportingList
} from "../../types/products";
import { enhanceReporting } from "../../slices/campaigns";
import { genCatalogStatus } from "../../demo/genCatalogStatus";
import genProductReporting from "../../demo/genProductReporting";
import { StorageManager } from "../../storage";
import genProducts from "../../demo/genProducts";
import { enhanceProductErrors } from "../../utils/enhanceProductsErrors";

const productsApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getProducts: build.query<Paginated<Product>, ProductParams>({
      query: (params) => ({
        url: ApiUrls.products.list(params.feed),
        params,
      }),
      providesTags: ["Products"],
      transformResponse(r: Paginated<Product>) {

        if (StorageManager.getIsDemo()) {
          const list = genProducts(20)
          return {
            page: 1,
            page_max: 10,
            list,
            found: 166
          };
        }

        const products = [...r.list];
        products.forEach(p => {
          p.product_errors = enhanceProductErrors(p.product_errors);
        });
        return { ...r, list: products };
      }
    }),
    getProductsFromReferences: build.query<Product[], { feed: number, reference: string[] }>({
      query: ({ feed, ...params }) => ({
        url: ApiUrls.products.list(feed),
        params,
      }),
      providesTags: (result, error, arg) =>
        result && result.length ? [{ type: 'Product', id: result[0].id }] : [],
    }),
    getProduct: build.query<Product, GetProductParams>({
      query: (params) => ApiUrls.products.edit(params.feed, params.product),
      providesTags: ["Product"],
      transformResponse(r: Product) {
        if (StorageManager.getIsDemo()) {
          return genProducts(1)[0];
        }
        const errors = enhanceProductErrors([...r.product_errors]);
        return { ...r, product_errors: errors };
      }
    }),
    setProduct: build.mutation<Product, SetProductData>({
      query: (body) => ({
        url: ApiUrls.products.edit(body.feed, body.product),
        method: 'POST',
        body: body.data,
      }),
      invalidatesTags: ["Products"],
    }),
    getProductsFromError: build.query<Product[], { feed: number, error_code: number }>({
      query: (params) => ({
        url: ApiUrls.products.list(params.feed),
        params,
      }),
      providesTags: ["ProductsErrors"],
      transformResponse(r: Product[]) {
        const list = [...r];
        list.forEach(p => {
          p.product_errors = enhanceProductErrors(p.product_errors);
        });
        return list;
      }
    }),
    getCategories: build.query<Array<CategoryMap>, { feed: number }>({
      query: (params) => ApiUrls.products.categories(params.feed),
      providesTags: ["CategoriesMap"],
    }),
    setCategory: build.mutation<CategoryMap, { feed: number, mappingId: number, googleCategory: number }>({
      query: ({ feed, mappingId, googleCategory }) => ({
        url: ApiUrls.products.category(feed, mappingId),
        method: 'POST',
        body: {
          category: googleCategory,
        },
      }),
      invalidatesTags: ["CategoriesMap"]
    }),
    getProductReporting: build.query<ProductReporting, { feed: number }>({
      query: (params) => ({
        url: ApiUrls.reporting.products(params.feed),
      }),
      providesTags: ["ProductReporting"],

      transformResponse(r: ProductReporting) {

        let errors = r.errors;

        if (StorageManager.getIsDemo()) {
          genCatalogStatus(r);
        }

        const uniqueErrorIds = new Set();

        // Iterate over each source, filtering unique errors
        const filteredErrors = errors.map(source => {
          const filteredSourceErrors = source.errors.filter(error => {
            if (uniqueErrorIds.has(error.id)) {
              return false;
            }
            uniqueErrorIds.add(error.id);
            return true;
          });
          return { ...source, errors: filteredSourceErrors };
        });

        r.errors = filteredErrors;
        const enhanced = enhanceProductErrors(filteredErrors);
        return { ...r, errors: enhanced };
      }
    }),

    getProductReportingList: build.query<ProductReportingList, { feed: number, period: ReportingPeriodString }>({
      query: (params) => ({
        url: ApiUrls.reporting.productList(params.feed),
        params: {
          from: params.period[0],
          to: params.period[1],
        }
      }),

      //@ts-ignore
      transformResponse(res: Array<ProductReportingBase>) {
        let base = res;
        if (StorageManager.getIsDemo()) {
          base = genProductReporting({ isFlat: true, numProducts: 50 });
        }
        return base.map(r => enhanceReporting(r));
      }
    }),
    batchUpdate: build.mutation<ApiSuccess, {
      feed: number,
      data: Array<{ id: number, custom_data: Partial<ProductData> }>
    }>({
      query: (params) => ({
        url: ApiUrls.products.batch(params.feed),
        method: 'POST',
        body: params.data,
      }),
      invalidatesTags: ["ProductsErrors"]
    }),
    productAi: build.mutation<any, {
      feed: string | number,
      feedProduct: string | number,
    }>({
      query: ({ feed, feedProduct }) => ({
        url: ApiUrls.ai['productTitle'](feed, feedProduct),
        method: 'POST',
      }),
      invalidatesTags: ["AI_Credits"]
    })
  })
})

type GetProductParams = {
  feed: number;
  product: number;
}

type SetProductData = GetProductParams & {
  data: Partial<ProductData>
};

export default productsApi;
