import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { modalShowLabel } from "../../../app/slices/modal";
import { SkeletonLoader } from "../../App/Blocks/Misc";
import React from "react";
import { Alert, AlertTitle, Box, List, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import FCard from "../../App/Blocks/Card";
import { CustomDynamicLabelKey, DynamicLabel } from "../../../app/types/feed";
import AppSecured from "../../App/AppSecured/AppSecured";
import Grid2 from "@mui/material/Unstable_Grid2";
import useLabels from "../../../app/hooks/useLabels";
import PageInfo from "../../App/Helpdesk/PageInfo";

const Labels = () => {
  const hasSource = useAppSelector(state => state.shop.hasSource);
  return <AppSecured>
    <Stack spacing={2}>
      <PageInfo page={"custom-labels"}/>
      {!hasSource && (
        <Alert variant={"outlined"} severity={"info"}>
          <AlertTitle>
            <FormattedMessage id={"no-source"}/>
          </AlertTitle>
          <FormattedMessage id={'no-source-desc'}/>
        </Alert>
      )}
      <LabelsCard/>
    </Stack>
  </AppSecured>
}

const LabelsCard = () => {

  const dispatch = useAppDispatch();
  const { labels: dynLabels, loading } = useLabels();

  const customLabels: Array<CustomDynamicLabelKey> = [ "custom_label_0_k", "custom_label_1_k", "custom_label_2_k", "custom_label_3_k", "custom_label_4_k" ];

  const newLabelFromGroup = (labelGroup: CustomDynamicLabelKey) => {
    dispatch(modalShowLabel({ isNewLabel: true, labelGroup: labelGroup }));
  }

  const hasSource = useAppSelector(state => state.shop.hasSource);

  return (
    <Box>
      <SkeletonLoader load={loading}>
        <Grid2 container spacing={2}>
          {customLabels.map((l, j) => (
            <Grid2 xs key={`custom-label-${j}`}>
              <Typography variant={"overline"}>
                <FormattedMessage id={l}/>
              </Typography>
              <FCard variant={"no-padding"}>
                <List disablePadding sx={{ overflow: "hidden" }}>
                  {(dynLabels.findIndex(d => d.label_key === l) || 0) >= 0 && (
                    <React.Fragment>
                      {dynLabels.filter(d => d.label_key === l).map(dynLabel => (
                        <LabelNode
                          key={`label-${dynLabel.uuid}`}
                          {...dynLabel}
                        />
                      ))}
                    </React.Fragment>
                  )}
                  <ListItem
                    sx={{ overflow: "hidden" }}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => newLabelFromGroup(l)}
                      disabled={!hasSource}
                      sx={{ padding: "6px 16px", justifyContent: "center" }}
                    >
                      <AddCircleOutline fontSize={"small"} color={"disabled"}/>
                    </ListItemButton>
                  </ListItem>
                </List>
              </FCard>
            </Grid2>
          ))}
        </Grid2>
      </SkeletonLoader>
    </Box>
  )
}

const LabelNode = (dynLabel: DynamicLabel) => {
  const dispatch = useAppDispatch();
  return (
    <ListItem
      divider
      disablePadding
      onClick={() => dispatch(modalShowLabel({ label: dynLabel }))}
    >
      <ListItemButton>
        {dynLabel.label_value}
      </ListItemButton>
    </ListItem>
  )
}
export default Labels;
