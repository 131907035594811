import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getAppMode, getLanguage, getUser, setAppMode, setLanguage } from "../../../../app/slices/app";
import profileApi from "../../../../app/services/secured/profile";
import { googleLogout } from "@react-oauth/google";
import React, { useCallback, useEffect } from "react";
import { StorageManager } from "../../../../app/storage";
import Urls from "../../../../assets/js/Urls";
import { Avatar, Box, IconButton, Tooltip as TooltipMU } from "@mui/material";
import useAnchorEl from "../../../../app/hooks/useAnchorEl";
import { AccountMenu } from "./AccountMenu";

const UserMenu = () => {

  const dispatch = useAppDispatch();
  const me = useAppSelector(getUser);
  const language = useAppSelector(getLanguage);
  const mode = useAppSelector(getAppMode);
  const [ logout, logoutRes ] = profileApi.useLogoutMutation();

  const { anchorEl, open, handleClick, handleClose } = useAnchorEl();

  const setLang = useCallback((lang: 'fr' | 'en') => dispatch(setLanguage(lang)), [ dispatch ]);
  const toggleMode = useCallback(() => dispatch(setAppMode(mode === 'light' ? 'dark' : 'light')), [ dispatch, mode ]);

  const logUserOut = useCallback(() => {
    googleLogout();
    logout();
  }, [ logout ]);

  useEffect(() => {
    if (logoutRes.isSuccess) {
      StorageManager.clear();
      window.location.href = Urls.auth.login;
    }
  }, [ logoutRes.isSuccess ]);

  return (
    <React.Fragment>
      <Box className={"app-settings"}>
        <TooltipMU title="Account">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{me?.first_name?.slice(0, 1) ?? "A"}</Avatar>
          </IconButton>
        </TooltipMU>
      </Box>
      <AccountMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        me={me}
        logUserOut={logUserOut}
        setLang={setLang}
        language={language}
        mode={mode}
        toggleMode={toggleMode}
      />
    </React.Fragment>
  )
}

export default React.memo(UserMenu);
