import FeedImportMethod from "../../Import/FeedImportMethod";
import AppSecured from "../../App/AppSecured/AppSecured";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { resetNewFeed, setNewFeedProject } from "../../../app/slices/newfeed";
import { Box } from "@mui/material";

const NewFeedInProject = () => {
  const dispatch = useAppDispatch();
  const { project } = useParams<{ project: string }>();

  useEffect(() => {
    if (project) {
      dispatch(resetNewFeed());
      dispatch(setNewFeedProject(parseInt(project)));
    }
  }, [ project ]);

  return <AppSecured lockNav={true}>
    <Box mt={2}>
      {project && <FeedImportMethod project={parseInt(project)} newFeedWithinApp={false}/>}
    </Box>
  </AppSecured>
};

export default NewFeedInProject;
