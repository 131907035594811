import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GetCurrentFeedId } from "../../../app/utils";
import { closeModal, isModalOpened, ModalTypes } from "../../../app/slices/modal";
import { getSelectedProducts, setSelectedProducts } from "../../../app/slices/app";
import React, { useEffect, useMemo, useState } from "react";
import productsApi from "../../../app/services/secured/products";
import { NewSuccessNotification } from "../../../app/slices/notifications";
import { LoadingButton } from "../../App/Blocks/Misc";
import { FormattedMessage } from "react-intl";
import { ProductData } from "../../../app/types/products";
import { attributeOptions, productDataTypes } from "../../../assets/resources/googleErrors";
import SimpleForm, { SimpleFormData } from "../../App/Blocks/SimpleForm";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { GoogleCategorySelect } from "../../App/Blocks/GoogleCategorySelect";

type EditableFields = keyof Pick<ProductData, 'brand_k' | 'google_product_category_k' | 'availability_k' | 'condition_k' | 'gender_k' | 'age_group_k'>

export type ErrorCorrection = {
  id: number,
  custom_data: Partial<ProductData>
}

export const ModalMultipleEdit = () => {

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const show = useAppSelector(isModalOpened(ModalTypes.MultipleProductEdit));
  const selected = useAppSelector(getSelectedProducts);

  const [ field, setField ] = useState<EditableFields>("brand_k");
  const [ controlledValue, setControlledValue ] = useState<number|string>("");
  const options: EditableFields[] = [ "brand_k", "google_product_category_k", "availability_k", "condition_k", "gender_k", "age_group_k" ];
  const fieldType = productDataTypes[field];

  const hide = () => dispatch(closeModal());
  const [ update, updateRes ] = productsApi.useBatchUpdateMutation();

  const input = useMemo(() => {
    switch (fieldType) {
      case "select":
        // @ts-ignore
        const values: Array<any> = attributeOptions[field] ?? [];
        if (values.length) {
          const options = values.map(v => <MenuItem key={v} value={v}><FormattedMessage id={v}/></MenuItem>);
          if (options.length) {
            return <TextField name={field} select label={<FormattedMessage id={field}/>}>{options}</TextField>
          }
        }
        break;
      case "text":
        return <TextField name={field} label={<FormattedMessage id={field}/>}/>;
      case "number":
        return <GoogleCategorySelect defaultSelected={null} onChange={(categoryId) => setControlledValue(categoryId)}/>;
    }
  }, [ field, fieldType ]);

  const save = (s: SimpleFormData) => {
    const value = s[`${field}`];
    const updArr: ErrorCorrection[] = [];
    selected.forEach(s => updArr.push({
      id: s.id,
      custom_data: {
        [`${field}`]: value ?? controlledValue
      }
    }));
    update({ feed, data: updArr });
  }

  useEffect(() => {
    if (updateRes.isSuccess) {
      dispatch(productsApi.util.invalidateTags([ "Products", "Product" ]));
      dispatch(NewSuccessNotification("success-edit-multiple-products"));
      hide();
    }
  }, [ updateRes.isSuccess ]);

  return <Dialog open={show} onClose={hide} maxWidth={"xs"} fullWidth>
    <DialogTitle>
      <FormattedMessage id={"edit-multiple-products"} />
      <Typography variant={"body2"}>
        <FormattedMessage id={"edit-multiple-products-desc"} />
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Alert severity={"info"} sx={{ mb: 2 }}>
        <FormattedMessage id={"will-be-affected"} values={{ products: selected.length }}/>
      </Alert>
      <SimpleForm onSubmit={save}>
        <FormControl margin={"dense"} fullWidth>
          <TextField
            select
            label={<FormattedMessage id={"select-field-to-edit"}/>}
            value={field}
            onChange={(e) => setField(e.target.value as EditableFields)}
          >
            {options.map(o => <MenuItem key={o} value={o}><FormattedMessage id={o}/></MenuItem>)}
          </TextField>
        </FormControl>

        <Box>
          <FormControl margin={"dense"} fullWidth>
            {input}
          </FormControl>
        </Box>

        <Box display={"flex"} justifyContent={"end"} mt={2}>
          <LoadingButton
            data={updateRes}
            type={"submit"}
            variant={"contained"}
            reset
          >
            <FormattedMessage id={"save"}/>
          </LoadingButton>
        </Box>
      </SimpleForm>
    </DialogContent>
  </Dialog>
}

export default ModalMultipleEdit;
