import { ReactNode } from "react";
import { Table, TableHead, TableRow } from "@mui/material";
// import { Table } from "react-bootstrap";

type FTableProps = {
  header?: ReactNode,
  children: any,
  className?: string,
  size?: "small",
}

const FTable = ({ header, children, className, size }: FTableProps) => {
  return <>
    <Table
      className={"f-table " + className}
      size={size}
    >
      <TableHead>
        <TableRow>
          {header}
        </TableRow>
      </TableHead>
      {children}
    </Table>
  </>
}

export default FTable;
