import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  closeModal,
  getModalData,
  isModalOpened,
  modalShowCatalogPixel,
  modalShowMerchantCenter,
  ModalTypes
} from "../../../../../app/slices/modal";
import profileApi, { oauthRedirect } from "../../../../../app/services/secured/profile";
import React, { useEffect, useMemo, useState } from "react";
import { GetCurrentFeedId } from "../../../../../app/utils";
import { FormattedMessage, useIntl } from "react-intl";
import campaignApi from "../../../../../app/services/secured/campaign";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import { CompanyLogo, LoadingBlock } from "../../../../App/Blocks/Misc";
import { initOauth } from "../../../../App/AppPublic/Auth/Oauth";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListSubheader,
  Typography
} from "@mui/material";
import { AddLink } from "@mui/icons-material";
import { CompanyAccount } from "../../../../../app/types/profile";
import { getAppMode } from "../../../../../app/slices/app";
import { adsCompanies } from "../../../../../app/slices/campaign/constants";
import { Company } from "../../../../../app/types/campaign";

/**
 * ModalLinkAccount
 * Link account to feed
 * Not used for Google : specific Dialog for Google (can create account)
 */

const ModalLinkAccount = () => {

  const dispatch = useAppDispatch();
  const show = useAppSelector(isModalOpened(ModalTypes.LinkAccount));
  const data = useAppSelector(getModalData);
  const company = data?.company;

  const meta = profileApi.useGetMetaAccountsQuery({ redirect_url: oauthRedirect });
  const microsoft = profileApi.useGetMicrosoftAccountsQuery({ redirect_url: oauthRedirect });

  const [ accounts, setAccounts ] = useState<CompanyAccount[]>([]);

  const hide = () => dispatch(closeModal());

  const onSuccess = () => {
    hide();
    if (company === "meta") {
      dispatch(modalShowCatalogPixel());
    }
  }

  useEffect(() => {
    if (meta.isSuccess && !meta.data.grant_required && company === "meta") {
      setAccounts(meta.data.accounts);
    }
    if (microsoft.isSuccess && !microsoft.data.grant_required && company === "microsoft") {
      setAccounts(microsoft.data.accounts);
    }
  }, [ meta.isSuccess, company, microsoft.isSuccess ]);

  const accountsMemo = useMemo(() => {
    return <AdsAccountsList accounts={accounts} company={company} onSuccess={onSuccess}/>
  }, [ accounts, company ]);

  return <Dialog open={show} onClose={hide}>
    <DialogTitle>
      <FormattedMessage id={"link-ads-account"}/>
      <Typography variant={"body2"}>
        <FormattedMessage id={"link-ads-account-desc"}/>
      </Typography>
    </DialogTitle>
    <DialogContent>
      <LoadingBlock data={company === "meta" ? meta : microsoft}>
        {accountsMemo}
      </LoadingBlock>
    </DialogContent>
  </Dialog>
}

export type LinkCompanyProps = {
  company: Company,
  onSuccess: () => void,
  onBack?: () => void,
  onCreate?: () => void,
  onLoading?: () => void,
  disabled?: boolean,
}

export const AdsAccountsList = (props: { accounts: Array<CompanyAccount> } & LinkCompanyProps) => {
  const [ load, setLoad ] = useState(false);
  const mode = useAppSelector(getAppMode);
  const background = mode === "light" ? "rgba(255, 255, 255, 0.5)" : "";
  return <List sx={{ position: "relative" }}>
    <ListSubheader>
      <FormattedMessage id={"select-account-to-link"}/>
    </ListSubheader>
    <Backdrop
      open={load}
      sx={{
        position: "absolute",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: background,
      }}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
    {props.accounts.map((a, i) => <AdsAccount
      key={`ads-${a.id}`}
      account={a}
      company={props.company}
      onSuccess={props.onSuccess}
      loading={load}
      onLoad={(l) => setLoad(l)}
      isLast={i === props.accounts.length - 1}
    />)}
  </List>
}

type AdsAccountProps = {
  account: CompanyAccount,
  onLoad: (load: boolean) => void,
  loading: boolean,
  isLast: boolean,
} & LinkCompanyProps

const AdsAccount = ({ account, company, onSuccess, isLast, loading, onLoad }: AdsAccountProps) => {
  const dispatch = useAppDispatch(),
    feed = GetCurrentFeedId(),
    intl = useIntl();

  const [ setLink, setLinkRes ] = campaignApi.useLinkAdsAccountMutation();
  useEffect(() => {
    if (setLinkRes.isSuccess) {
      dispatch(NewSuccessNotification(intl.formatMessage({ id: "success-ads-account-link" })));
      onSuccess();
    }
  }, [ setLinkRes ]);

  useEffect(() => {
    if (setLinkRes.isLoading) {
      onLoad(true);
    } else if (setLinkRes.isError) {
      onLoad(false);
    }
  }, [ setLinkRes ]);

  return <ListItem divider={!isLast} disablePadding>
    <ListItemButton
      disabled={loading}
      onClick={() => setLink({ feed, provider: adsCompanies[company].provider, account_code: account.id })}
    >
      <ListItemAvatar sx={{ display: "flex", alignItems: "center" }}>
        <CompanyLogo size={'sm'} company={company}/>
      </ListItemAvatar>

      <Box
        sx={{
          width: '100%',
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box textAlign={"start"}>
          <Typography variant={"body2"} lineHeight={1} color={"text.secondary"}>
            <FormattedMessage id={"name"}/>
          </Typography>
          <Typography variant={"h6"}>{account.name}</Typography>
        </Box>
        <Box>
          <Typography variant={"caption"}><FormattedMessage id={"identifier"}/></Typography>
          <Typography>{account.id}</Typography>
        </Box>
      </Box>
    </ListItemButton>
  </ListItem>
}

export default ModalLinkAccount;
