import { getCountryForTimezone } from 'countries-and-timezones';
import { useEffect, useState } from 'react';
import authApi from '../../../app/services/public/auth';
import { useUrlOrCookieParams } from './useUrlOrCookieParams';
import { countries, formatRegistrationData, languages } from '../utils';
import { FormValues } from '../utils';
import { isValidEmail, isValidUrl } from '../../../utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRegisterForm = () => {
   const { email: emailParam, domain: domainParam, feed_url, country: countryParam, language: languageParam } = useUrlOrCookieParams();

   const [formValues, setFormValues] = useState<Partial<FormValues>>({});
   const [register, registerRes] = authApi.useSignupMutation();
   const [errors, setErrors] = useState<{ [key: string]: string }>({});

   const { executeRecaptcha } = useGoogleReCaptcha();

   useEffect(() => {
      const browserLanguage = navigator.language.substring(0, 2).toUpperCase();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const browserCountry = getCountryForTimezone(timezone)?.id || 'US';

      setFormValues({
         'feed[domain]': domainParam || '',
         email: emailParam || '',
         'feed[url]': feed_url || '',
         'feed[country]': countryParam || browserCountry,
         'feed[language]': languageParam || browserLanguage || 'EN',
      });
   }, [emailParam, domainParam, feed_url, countryParam, languageParam]);

   const validateForm = () => {
      const newErrors: { [key: string]: string } = {};

      // Validate all fields
      if (!formValues['feed[domain]']) {
         newErrors['feed[domain]'] = 'Domain is required';
      }

      if (formValues['feed[url]'] && !isValidUrl(formValues['feed[url]'])) {
         newErrors['feed[url]'] = 'Please enter a valid URL';
      }

      if (!formValues.email) {
         newErrors.email = 'Email is required';
      } else if (!isValidEmail(formValues.email)) {
         newErrors.email = 'Please enter a valid email address';
      }

      if (!formValues.password) {
         newErrors.password = 'Password is required';
      } else if (formValues.password.length < 8) {
         newErrors.password = 'Password must be at least 8 characters long';
      }

      if (formValues.password !== formValues.password_confirm) {
         newErrors.password_confirm = 'Passwords do not match';
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
   };

   const handleSubmit = async () => {
      try {
         const formattedData = formatRegistrationData(formValues);
         console.log(formattedData);
         register(formattedData);
      } catch (error) {
         // Handle error
      }
   };

   return { formValues, setFormValues, errors, handleSubmit, validateForm, registerRes };
};

export default useRegisterForm;
